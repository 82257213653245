import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { OverlayTrigger } from 'react-bootstrap'
import EventPopover from './EventPopover'

const BaseEvent = styled.span`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 10px;
  padding: 5px;
`

const DraftTrainingEvent = styled(BaseEvent)`
  border: var(--light-grey) dashed 1px;
  color: var(--light-grey);
`

const PublishedTrainingEvent = styled(BaseEvent)`
  background: var(--dark-green);
  color: white;
`

const OnMatchTrainingEvent = styled(BaseEvent)`
  border: var(--dark-green) solid 1px;
  color: var(--dark-green);
`

const DraftMatchEvent = styled(BaseEvent)`
  border: #aaa dashed 1px;
  color: #aaa;
`

const PublishedMatchEvent = styled(BaseEvent)`
  background: var(--dark-green);
  color: white;
`

const OnTrainingMatchEvent = styled(BaseEvent)`
  border: var(--light-grey) solid 1px;
  color: var(--light-grey);
`

const EVENT_TYPE_AND_STATUS_TO_COMPONENT_MAPPER = {
  training: {
    draft: DraftTrainingEvent,
    published: PublishedTrainingEvent,
    onMatch: OnMatchTrainingEvent,
  },
  match: {
    draft: DraftMatchEvent,
    published: PublishedMatchEvent,
    onTraining: OnTrainingMatchEvent,
  },
}

export default ({
  resetPopover,
  changeSelectable,
  activePopoverId,
  changeActivePopoverId,
  entity,
}) => {
  const EventWrapper = ({
    className,
    event: { id, title, relation, status },
    event,
  }) => {
    const onEnter = () => {
      changeSelectable(false)
      changeActivePopoverId(relation + id)
    }

    const onExit = resetPopover

    const Component =
      EVENT_TYPE_AND_STATUS_TO_COMPONENT_MAPPER[relation][status]

    return (
      <OverlayTrigger
        rootClose
        trigger="click"
        placement="auto"
        overlay={EventPopover(event, entity, onExit)}
        defaultShow={activePopoverId === relation + id}
        onEnter={onEnter}
        onExit={onExit}
      >
        <div className={className}>
          <Component>{title}</Component>
        </div>
      </OverlayTrigger>
    )
  }

  EventWrapper.propTypes = {
    className: PropTypes.string.isRequired,
    event: PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.number.isRequired,
      ]).isRequired,
      status: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      relation: PropTypes.string.isRequired,
      start: PropTypes.number.isRequired,
      isNew: PropTypes.bool,
    }).isRequired,
  }

  return styled(EventWrapper)`
    padding: 2px;
    text-align: center;
    background: 0;
    font-size: 10px;
  `
}
