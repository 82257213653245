import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withLocalize, Translate } from 'react-localize-redux'
import { DrillCreateEditForm } from 'containers'
import { drills } from 'redux/modules'
import { createSubUrl } from 'utils/urlConstructor'
import filterObject from 'utils/filterObject'
import getTargetTranslationFields from 'utils/getTargetTranslationFields'
import { DRILLS_DASHBOARD_ROUTE } from 'constants/routes'
import PROP_TYPES from 'constants/propTypes'

const FIELDS_WITH_TRANSLATION = [
  'title',
  'description',
  'drill_flow',
  'organization',
  'coaching_points',
  'variations',
]

const DrillEditContainer = ({
  history,
  match: {
    params: { teamId, drillId },
  },
  match,
  location: { fromUrl, fromPathname },
  drill: { translations, drillsCategories, ...drill },
  updateDrill,
  deleteDrill,
  activeLanguage: { code: activeLocale },
}) => {
  const linkBack =
    fromUrl ||
    createSubUrl(match, DRILLS_DASHBOARD_ROUTE.populateURLParams({ drillId }))

  const goBack = () => history.push(linkBack)

  const onSubmit = ({
    duration,
    min_players: minPlayers,
    max_players: maxPlayers,
    images,
    ...values
  }) => {
    const data = {
      ...values,
      duration: +duration,
      min_players: +minPlayers,
      max_players: +maxPlayers,
      team_id: +teamId,
    }

    if (images && images.length) {
      images.forEach((image, index) => {
        data[`images[${index}]`] = image.id || image
      })
    }

    updateDrill(drillId, data).then(goBack)
  }

  const initialValues = {
    global: 0,
    ...filterObject(drill, Boolean),
    ...getTargetTranslationFields(
      translations,
      activeLocale,
      FIELDS_WITH_TRANSLATION
    ),
    locale: activeLocale,
  }
  const handleDelete = () =>
    deleteDrill(+drillId).then(() => history.push(linkBack))

  return (
    <Translate>
      {({ translate }) => (
        <DrillCreateEditForm
          form="drillEdit"
          initialValues={initialValues}
          caption={translate('drills.edit-drill')}
          fromPath={{
            value: linkBack,
            title: fromPathname || translate('drills.own-drills'),
          }}
          onSubmit={onSubmit}
          drillsCategories={drillsCategories}
          isEditForm
          showDeleteButton
          handleDelete={handleDelete}
        />
      )}
    </Translate>
  )
}

DrillEditContainer.defaultProps = {}

DrillEditContainer.propTypes = {
  history: PROP_TYPES.history.isRequired,
  match: PROP_TYPES.match.isRequired,
  location: PROP_TYPES.location.isRequired,
  drill: PROP_TYPES.shape().isRequired,
  updateDrill: PROP_TYPES.func.isRequired,
  deleteDrill: PROP_TYPES.func.isRequired,
  activeLanguage: PROP_TYPES.shape().isRequired,
}

export default compose(
  connect(
    ({ drills: { current: drill } }) => ({
      drill,
    }),
    { updateDrill: drills.updateDrill, deleteDrill: drills.deleteDrill }
  ),
  withLocalize
)(DrillEditContainer)
