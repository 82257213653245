import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import {
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
  Tabs,
  Tab,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import SportsSoccerIcon from '@material-ui/icons/SportsSoccer'
import SwapHorizIcon from '@material-ui/icons/SwapHoriz'
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel'
import NoteIcon from '@material-ui/icons/Note'
import { Translate } from 'react-localize-redux'
import TabPanel from '../../../../components/material/TabPanel'
import AddEditGoal from './AddEditGoal'
import AddEditCard from './AddEditCard'
import AddEditReplacement from './AddEditReplacement'
import AddEditSimpleNote from './AddEditSimpleNote'
import TimeSection from './TimeSection'

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(0.4),
    color: theme.palette.grey[500],
  },
  tabs: {
    marginTop: '15px',
    overflow: 'unset',
    '& .MuiTabs-indicator': {
      display: 'none',
    },
    '& .MuiTabs-fixed': {
      overflow: 'unset !important',
    },
  },
  tab: {
    minWidth: '65px',
    color: '#dcdcdc',
    border: '1px solid #dcdcdc',
    borderRadius: '7px',
    padding: '15px 10px',
    margin: '10px',
    transition: 'box-shadow 400ms',
    '&.MuiTab-textColorInherit.Mui-selected': {
      color: '#ffffff',
      backgroundColor: '#255f64',
      border: '1px solid #255f64',
      boxShadow: '0px 0px 20px rgb(0 0 0 / 30%);',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '10px 10px',
      minWidth: '50px',
    },
  },
  tabPanel: {
    '& .MuiBox-root': {
      padding: '0px',
      paddingTop: '15px',
    },
  },
}))
const MatchHighlightsDialog = ({
  onClose,
  handleSave,
  handleUpdate,
  existingData,
  playersSuggestions,
  matchState,
  teamName,
}) => {
  const classes = useStyles()
  const [value, setValue] = React.useState(0)
  const [minute, setMinute] = React.useState(
    existingData && existingData.minute != null ? existingData.minute : 0
  )
  const [second, setSecond] = React.useState(
    existingData && existingData.second != null ? existingData.second : 0
  )
  const [errorMinute, setErrorMinute] = React.useState(false)
  const [errorSecond, setErrorSecond] = React.useState(false)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const  resetCommonFields= () => {
    document.getElementById('textbox-minute').value = 0
    document.getElementById('textbox-second').value = 0
    setMinute(0)
    setSecond(0)
  }
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }
  useEffect(() => {
    if (existingData) {
      switch (existingData.type) {
        case 'card':
          setValue(1)
          break
        case 'substitution':
          setValue(2)
          break
        case 'note':
          setValue(3)
          break
        default:
          setValue(0)
          break
      }
    }
  }, [existingData])

  return (
    <Translate>
      {({ translate }) => (
        <>
          <DialogTitle>
            <Typography variant="h4" component="h4">
              {translate('matches.highlights.dialog-box-header-create')}
            </Typography>
            {onClose ? (
              <IconButton
                aria-label="close"
                size="medium"
                className={classes.closeButton}
                onClick={onClose}
              >
                <CloseIcon />
              </IconButton>
            ) : null}
          </DialogTitle>
          <DialogContent>
            <TimeSection
              existingData={existingData}
              minute={minute}
              second={second}
              setMinute={setMinute}
              setSecond={setSecond}
              errorMinute={errorMinute}
              errorSecond={errorSecond}
              setErrorMinute={setErrorMinute}
              setErrorSecond={setErrorSecond}
            />

            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              className={classes.tabs}
              centered
            >
              <Tab
                icon={<SportsSoccerIcon />}
                {...a11yProps(0)}
                className={classes.tab}
                hidden={existingData && existingData.type !== 'goal'}
              />
              <Tab
                icon={<ViewCarouselIcon />}
                {...a11yProps(1)}
                className={classes.tab}
                hidden={existingData && existingData.type !== 'card'}
              />
              <Tab
                icon={<SwapHorizIcon />}
                {...a11yProps(2)}
                className={classes.tab}
                hidden={existingData && existingData.type !== 'substitution'}
              />
              <Tab
                icon={<NoteIcon />}
                {...a11yProps(3)}
                className={classes.tab}
                hidden={existingData && existingData.type !== 'note'}
              />
            </Tabs>

            <TabPanel value={value} index={0} className={classes.tabPanel}>
              <AddEditGoal
                onClose={onClose}
                matchState={matchState}
                existingData={existingData}
                playersSuggestions={playersSuggestions}
                handleUpdate={handleUpdate}
                handleSave={handleSave}
                teamName={teamName}
                minute={minute}
                second={second}
                errorMinute={errorMinute}
                errorSecond={errorSecond}
                setErrorMinute={setErrorMinute}
                resetCommonFields={resetCommonFields}
              />
            </TabPanel>
            <TabPanel value={value} index={1} className={classes.tabPanel}>
              <AddEditCard
                onClose={onClose}
                matchState={matchState}
                existingData={existingData}
                playersSuggestions={playersSuggestions}
                handleUpdate={handleUpdate}
                handleSave={handleSave}
                teamName={teamName}
                minute={minute}
                second={second}
                errorMinute={errorMinute}
                errorSecond={errorSecond}
                setErrorMinute={setErrorMinute}
                resetCommonFields={resetCommonFields}
              />
            </TabPanel>
            <TabPanel value={value} index={2} className={classes.tabPanel}>
              <AddEditReplacement
                onClose={onClose}
                matchState={matchState}
                existingData={existingData}
                playersSuggestions={playersSuggestions}
                handleUpdate={handleUpdate}
                handleSave={handleSave}
                teamName={teamName}
                minute={minute}
                second={second}
                errorMinute={errorMinute}
                errorSecond={errorSecond}
                setErrorMinute={setErrorMinute}
                resetCommonFields={resetCommonFields}
              />
            </TabPanel>
            <TabPanel value={value} index={3} className={classes.tabPanel}>
              <AddEditSimpleNote
                onClose={onClose}
                matchState={matchState}
                existingData={existingData}
                handleUpdate={handleUpdate}
                handleSave={handleSave}
                minute={minute}
                second={second}
                resetCommonFields={resetCommonFields}
                errorMinute={errorMinute}
                errorSecond={errorSecond}
                setErrorMinute={setErrorMinute}
              />
            </TabPanel>
          </DialogContent>
        </>
      )}
    </Translate>
  )
}
MatchHighlightsDialog.defaultProps = {
  onClose: () => 0,
  handleSave: () => 0,
  matchState: null,
  teamName: '',
  existingData: null,
}

MatchHighlightsDialog.propTypes = {
  onClose: PropTypes.func,
  handleSave: PropTypes.func,
  handleUpdate: PropTypes.func.isRequired,
  playersSuggestions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  existingData: PropTypes.shape(),
  matchState: PropTypes.shape(),
  teamName: PropTypes.string,
}
export default MatchHighlightsDialog
