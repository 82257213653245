import React from 'react'
import { connect } from 'react-redux'
import { Card, LinkBack } from 'components'
import { createSubUrl } from '../../utils/urlConstructor'
import { FoldersList, FilesList } from './components'
import { TEAM_MENU_ROUTE, DOCUMENTS_ROUTE } from 'constants/routes'
import PROP_TYPES from 'constants/propTypes'

const DocumentsContainer = ({
  match: {
    params: { teamId: currentTeamId },
  },
  match,
  location: { fromUrl, fromPathname },
  currentFolder,
}) => {
  const {
    id: currentFolderId,
    title,
    parent_id: currentFolderParentId,
    files = [],
    children: folders = [],
  } = currentFolder

  if (!currentFolderId) {
    return <div />
  }

  const linkBack =
    (fromPathname && fromUrl) || createSubUrl(match, DOCUMENTS_ROUTE)

  return (
    <Card>
      <Card.Title className="d-flex justify-content-between">
        {!currentFolderParentId ? (
          <LinkBack to={TEAM_MENU_ROUTE} title="Team" />
        ) : (
          <LinkBack
            to={linkBack}
            title={fromPathname || 'Documents'}
            isFullPath
          />
        )}
        <span>{currentFolderParentId ? title : 'Documents'}</span>
        <div />
      </Card.Title>
      <FoldersList
        folders={folders}
        currentTeamId={currentTeamId}
        parentIdForNewFolder={currentFolderParentId && currentFolderId}
        pathname={currentFolderParentId && title}
      />
      <FilesList
        files={files}
        currentTeamId={currentTeamId}
        currentFolderParentId={currentFolderParentId}
        currentFolderId={currentFolderId}
      />
    </Card>
  )
}

DocumentsContainer.propTypes = {
  match: PROP_TYPES.match.isRequired,
  location: PROP_TYPES.location.isRequired,
  currentFolder: PROP_TYPES.shape().isRequired,
}

export default connect(({ documents: { currentFolder } }) => ({
  currentFolder,
}))(DocumentsContainer)
