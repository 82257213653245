import React from 'react'
import { connect } from 'react-redux'
import { ActionWithPrompt } from 'components'
import { SubLink } from 'containers'
import { documents as documentsModule } from 'redux/modules'
import { ItemsContainerStyled, ItemStyled } from '../styledComponents'
import AddNewFolder from './AddNewFolder'
import WrappedRemoveButton from './WrappedRemoveButton'
import { DOCUMENTS_FOLDER_ROUTE } from 'constants/routes'
import PROP_TYPES from 'constants/propTypes'

const FoldersList = ({
  folders,
  currentTeamId,
  parentIdForNewFolder,
  pathname,
  removeFolder,
}) => (
  <>
    <p>Folders</p>
    <ItemsContainerStyled>
      {folders.map(item => (
        <SubLink
          key={item.id}
          to={DOCUMENTS_FOLDER_ROUTE.populateURLParams({ folderId: item.id })}
          fromPathname={pathname}
        >
          <ItemStyled>
            <span>{item.title}</span>
            <div
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
              }}
              role="presentation"
            >
              <ActionWithPrompt
                onSuccess={() => removeFolder(currentTeamId, item.id)}
                message={`Are you sure you want to delete directory ${
                  item.title
                }?`}
              >
                <WrappedRemoveButton />
              </ActionWithPrompt>
            </div>
          </ItemStyled>
        </SubLink>
      ))}
      <ItemStyled>
        <AddNewFolder
          currentTeamId={currentTeamId}
          parentIdForNewFolder={parentIdForNewFolder}
        />
      </ItemStyled>
    </ItemsContainerStyled>
  </>
)

FoldersList.defaultProps = {
  folders: [],
  parentIdForNewFolder: null,
  pathname: null,
}

FoldersList.propTypes = {
  folders: PROP_TYPES.arrayOfObjects,
  currentTeamId: PROP_TYPES.id.isRequired,
  parentIdForNewFolder: PROP_TYPES.id,
  pathname: PROP_TYPES.string,
  removeFolder: PROP_TYPES.func.isRequired,
}

export default connect(
  null,
  {
    removeFolder: documentsModule.removeFolder,
  }
)(FoldersList)
