import React from 'react'
import { TrainingPackagesLoader, FavoritesLoader } from 'loaders'
import TrainingPackagesDashboardContainer from './TrainingPackagesDashboardContainer'

const TrainingPackagesDashboard = () => (
  <TrainingPackagesLoader alwaysRenderChildren>
    <FavoritesLoader entity="trainingPackages">
      <TrainingPackagesDashboardContainer />
    </FavoritesLoader>
  </TrainingPackagesLoader>
)

export default TrainingPackagesDashboard
