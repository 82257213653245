import React, { useEffect, useMemo, useState } from 'react'
import { compose } from 'redux'
import { auth as authModule, clubs as clubsModule } from 'redux/modules'
import { withLocalize, Translate } from "react-localize-redux";
import { useLocation, useParams, withRouter } from 'react-router-dom'
import {
  Button,
  Grid,
  Box,
  Hidden,
  Typography,
  Avatar,
} from "@material-ui/core";
import Link from "@material-ui/core/Link";
import { LanguageDropdown, Snackbars as Snackbar } from 'components'
import MDInput from "components/material/MDInput";
import { getLocale, getSignupURL, setLocale, setUserId } from 'utils/localstorage'
import { identify } from "utils/tracking";
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Logo from "../assets/images/logo-black.svg";
import useStyles from "./styleComponents";
import SocialIcons from "./SocialLogin";
import PROP_TYPES from "constants/propTypes";
import languages from "constants/languages";

window.REACT_APP_API_URL = process.env.REACT_APP_API_URL;
const ResetLink = `${window.REACT_APP_API_URL}/password/reset`;

const Login = ({ auth, dispatchLogin, dispatchResetAuthState, fetchClub, theme, history, setActiveLanguage }) => {
  const classes = useStyles(theme)

  const { locale } = useParams()    // locale from URL (optional)
  const { search } = useLocation()  // URL query parameters

  const query = useMemo(() => {
    return new URLSearchParams(search)
  }, [search])

  const [snackbar, setSnackbar] = useState(false)
  const [email, setEmail] = useState('')
  const [emailValidation, setEmailValidation] = useState({ valid: true, message: '' })
  const [password, setPassword] = useState('')
  const [passwordValidation, setPasswordValidation] = useState({ valid: true, message: '' })

  // Get desired club ID from query parameters or use default
  const clubId = query.get('club') ?? 1

  /**
   * Get preferred language from URL :locale parameter or set it to default (EN)
   * and store value in local storage.
   */
  useEffect(() => {
    if (languages.some(lang => lang.code === locale)) {
      setLocale(locale)
      setActiveLanguage(locale)
    } else {
      setLocale('en')
      setActiveLanguage('en')
    }
  }, [ locale ])

  useEffect(() => {
    const { token, signupTeamId } = auth

    dispatchResetAuthState()

    if (token && signupTeamId) {
      history.push(`${ getSignupURL() }/step2`)
    } else {
      // setActiveLanguage(language)

      // Fetch default or specified club details and set theme
      fetchClub(clubId)
    }
  }, [])

  const handleEmail = (event) => {
    const pattern =
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    setEmail(event.target.value)

    if (event.target.value.length === 0) {
      setEmailValidation({
        valid: false,
        message: <Translate id="login.required.email" />
      })
    } else if (!event.target.value.match(pattern)) {
      setEmailValidation({
        valid: false,
        message: <Translate id="login.valid.email" />
      })
    } else {
      setEmailValidation({ valid: true, message: '' })
    }
  }

  const handlePassword = (event) => {
    setPassword(event.target.value)

    if (event.target.value.length === 0) {
      setPasswordValidation({
        valid: false,
        message: <Translate id="login.required.password" />
      })
    } else if (event.target.value.length < 4) {
      setPasswordValidation({
        valid: false,
        message: <Translate id="signup.valid.password-length" />
      })
    } else {
      setPasswordValidation({ valid: true, message: '' })
    }
  }

  const handleSubmit = () => {
    setSnackbar(false)

    if (!email || !emailValidation.valid || !password || !passwordValidation.valid) {
      return
    }

    dispatchLogin(email, password, locale)
      .then(({ response }) => {
        // for backward compatibility
        setUserId(response.user_id)

        identify()

        if (response.signup_completed) {
          history.push('/')
        } else {
          history.push(`${getSignupURL()}/step2`)
        }
      })
      .catch(() => {
        setSnackbar({
          message: <Translate id="login.wrong" />,
          error: true,
        })
      })
  }

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit()
    }
  }

  const handleLanguage = event => {
    setLocale(event.target.value)
    setActiveLanguage(event.target.value)

    // history.push(getLoginURL())
  }

  return (
    <>
      <Grid
        container
        spacing={0}
        className={classes.root}
      >
        <Hidden only={["xs", "sm"]}>
          <Grid md={5} item>
            <Box className={classes.background} />
          </Grid>
        </Hidden>
        <Hidden only={["md", "lg", "xl"]}>
          <Box
            className={classes.SignupTopText}
           >
            For full experience use Coachbetter
            <br /> on your desktop computer or tablet
          </Box>
        </Hidden>
        <Grid md={7} item container spacing={0}>
          <Grid item xs={12} className={classes.centeredGridContent}>
            <Box className={classes.loginBox}>
              <Box align="center">
                <Avatar
                  variant="square"
                  src={ theme ? theme.logo : Logo}
                  alt="coachbetter"
                  className={classes.signupLogo}
                />
                <Box pt={2} pb={3}>
                  <Typography
                    variant="body1"
                    component="p"
                    className={classes.paragraph}
                  >
                    {theme ? theme.subtitle : <Translate id="login.welcome" />}
                  </Typography>
                </Box>
              </Box>
              <Box mb={1}>
                {clubId === 1 && <SocialIcons history={history} classes={classes} page="login" />}
              </Box>
              <Grid md={12} item>
                <Box mb={1}>
                  <MDInput
                    disabled={auth.isLoading}
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    type="email"
                    name="email"
                    className={classes.formControl}
                    variant="outlined"
                    autoComplete="off"
                    error={!emailValidation.valid}
                    value={email}
                    onChange={handleEmail}
                    helperText={emailValidation.message}
                    onKeyPress={handleKeyPress}
                  />
                </Box>
                <Box mb={1}>
                  <MDInput
                    disabled={auth.isLoading}
                    required
                    fullWidth
                    error={!passwordValidation.valid}
                    id="password"
                    label={<Translate id="login.password" />}
                    type="password"
                    name="password"
                    className={classes.formControl}
                    variant="outlined"
                    autoComplete="off"
                    value={password}
                    onChange={handlePassword}
                    helperText={passwordValidation.message}
                    onKeyPress={handleKeyPress}
                  />
                </Box>
                <Box mb={1}>
                  {clubId === 1 &&
                    <LanguageDropdown
                      classes={classes}
                      language={ getLocale() }
                      handleChange={handleLanguage}
                    />
                  }
                </Box>
                <Box align="right" mb={ .8 }>
                  <Link
                    href={ ResetLink }
                    className={ classes.link }
                  >
                    <Translate id="login.forgot-password"/>
                  </Link>
                </Box>
                <Box align="center">
                  <Button
                    fullWidth
                    variant="contained"
                    type="button"
                    className={classes.button}
                    size="large"
                    disabled={auth.isLoading}
                    onClick={handleSubmit}
                  >
                    <Translate id="login.login-btn" />
                  </Button>
                  <Box mt={.8}>
                    {clubId === 1 &&
                      <Typography
                        variant="body1"
                        component="p"
                        className={classes.typography}
                      >
                        <Translate id="login.dont-have-acount" /> &nbsp;
                        <Link
                          href={getSignupURL()}
                          className={classes.link}
                        >
                          <Translate id="login.signup" />
                        </Link>
                      </Typography>
                    }
                  </Box>
                </Box>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      {snackbar && <Snackbar {...snackbar} />}
    </>
  );
};

Login.propTypes = {
  auth: PROP_TYPES.shape().isRequired,
  dispatchLogin: PROP_TYPES.func.isRequired,
  dispatchResetAuthState: PROP_TYPES.func.isRequired,
  fetchClub: PROP_TYPES.func.isRequired,
  theme: PropTypes.oneOfType([
    PropTypes.shape().isRequired,
    PropTypes.oneOf([null]).isRequired,
  ]).isRequired,
  history: PROP_TYPES.shape().isRequired,
  setActiveLanguage: PROP_TYPES.func.isRequired,
}

export default compose(
  withRouter,
  withLocalize,
  connect(
    ({ auth, clubs }) => ({
      auth,
      theme: clubs.current ? clubs.current.theme : {}
    }),
    {
      dispatchLogin: authModule.login,
      dispatchResetAuthState: authModule.reset,
      fetchClub: clubsModule.fetchClub
    }
  )
)(Login)
