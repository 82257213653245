import React from "react";
import PropTypes from "prop-types";
import { Translate } from "react-localize-redux";
import { Typography, Link, Box } from "@material-ui/core";
import useStyles from '../../styleComponents'

const LegalText = ({ theme }) => {
  const classes = useStyles(theme)

  return (
    <Translate>
      {({ translate }) => (
        <Box mt={3}>
          <Typography
            variant="body1"
            component="p"
            align="center"
            className={classes.typography}
          >
            {translate("signup.agreement")}
            <br />
            <Link
              href={translate("signup.link-terms-of-service")}
              target="_blank"
              className={classes.link}
            >
              {translate("signup.terms-of-service")}
            </Link>{" "}
            &amp;{" "}
            <Link
              href={translate("signup.link-privacy-policy")}
              target="_blank"
              className={classes.link}
            >
              {translate("signup.privacy-policy")}
            </Link>
            .
          </Typography>
        </Box>
      )}
    </Translate>
  );
};

export default LegalText;
