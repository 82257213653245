// import React from 'react'
import styled from 'styled-components'
import Snackbar from '@material-ui/core/Snackbar'

const DrillsSnackbar = styled(Snackbar)`
  .drillImg {
    width: 100%;
    height: 6.25rem;
    position: inherit;
    margin: 0;
    padding: 0;
    border-radius: 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background: white;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
    @media only screen and (max-width: 769px) {
      width: 5rem;
      margin-right: 0.8rem;
      height: 5rem;
    }
  }

  .rec-carousel-wrapper {
    width: 100% !important;
  }

  @media only screen and (max-width: 769px) {
    .MuiAlert-root {
      width: 100%;
      height: 7rem;
      background-color: #fff;
      align-items: flex-start;
      border: #ededed solid 1px;
      box-shadow: 0 0px 36px 0 rgba(0, 0, 0, 0.05);
      .MuiAlert-message {
        width: calc(100% - 34px);

        .extraAction {
          display: flex;

          .time-content {
            display: flex;
            margin-right: 0.6rem;
            align-items: center;
            .MuiCardMedia-img {
              width: 1rem;
              height: 1rem;
              margin-right: 0.3rem;
            }
          }
        }
      }
      .MuiAlert-icon {
        display: none;
      }
    }
  }
`

export default DrillsSnackbar
