import React from 'react'
import PropTypes from 'prop-types'
import { TextField, InputAdornment } from '@material-ui/core'

const Input = ({
  input,
  type = 'text',
  meta = {},
  label,
  placeholder = '',
  size = '',
  variant = '',
  margin = '',
  id = '',
  name='',
  endadornment,
  startadornment,
  defaultValue,
  onChange,
  ...rest
}) => {
  const inputError = meta.touched && meta.error

  return (
    <TextField
      type={type}
      label={label}
      variant={variant}
      error={inputError}
      margin={margin}
      helperText={inputError}
      placeholder={placeholder}
      defaultValue={defaultValue}
      onChange={onChange}
      InputProps={
        startadornment || endadornment && 
          {
          startAdornment: (
            <InputAdornment position="start">{startadornment}</InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">{endadornment}</InputAdornment>
          ),
        }
      }
      // InputLabelProps={{ shrink: true}}
      size={size}
      fullWidth
      id={id}
      name={name}
      disabled={rest.disabled}
      {...input}
      {...rest}
    />
  )
}

Input.defaultProps = {
  type: 'text',
  meta: {},
  label: '',
  placeholder: '',
  size: 'small',
  variant: 'outlined',
  margin: 'none',
  id: '',
  name:'',
  defaultValue:'',
  onChange:()=>0
}

Input.propTypes = {
  input: PropTypes.shape().isRequired,
  type: PropTypes.string,
  meta: PropTypes.shape(),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  size: PropTypes.string,
  variant: PropTypes.string,
  margin: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  defaultValue:PropTypes.string,
  endadornment: PropTypes.string.isRequired,
  startadornment: PropTypes.string.isRequired,
  onChange:PropTypes.func,
}

export default Input
