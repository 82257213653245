import { handleActions, combineActions } from 'redux-actions'
import {
  actionsTypesGenerator,
  onRequest,
  onSuccess,
  onFailure,
} from 'utils/reduxHelpers'
import initialState from '../initialState'

const namespace = 'LINEUP'
const entity = 'Lineup'

const FETCH_LINEUPS = actionsTypesGenerator(`FETCH_${namespace}S`)
const FETCH_LINEUP = actionsTypesGenerator(`FETCH_${namespace}`)
const CREATE_LINEUP = actionsTypesGenerator(`CREATE_${namespace}`)
const UPDATE_LINEUP = actionsTypesGenerator(`UPDATE_${namespace}`)
const DELETE_LINEUP = actionsTypesGenerator(`DELETE_${namespace}`)

export const fetchLineups = () => ({
  types: FETCH_LINEUPS,
  callAPI: {
    method: 'GET',
    entity: `${entity}s`,
    path: '/lineups',
  },
})

export const fetchLineup = id => ({
  types: FETCH_LINEUP,
  callAPI: {
    method: 'GET',
    entity,
    path: `/lineups/${id}`,
  },
})

export const createLineup = data => ({
  types: CREATE_LINEUP,
  callAPI: {
    method: 'POST',
    entity,
    path: '/lineups',
    data,
  },
})

export const updateLineup = (id, data) => ({
  types: UPDATE_LINEUP,
  callAPI: {
    method: 'PUT',
    entity,
    path: `/lineups/${id}`,
    data,
  },
})

export const deleteLineup = id => ({
  types: DELETE_LINEUP,
  callAPI: {
    method: 'DELETE',
    entity,
    path: `/lineups/${id}`,
  },
  payload: { id },
})

export default handleActions(
  {
    [combineActions(
      FETCH_LINEUPS.request,
      FETCH_LINEUP.request,
      CREATE_LINEUP.request,
      UPDATE_LINEUP.request,
      DELETE_LINEUP.request
    )]: onRequest,
    [FETCH_LINEUPS.success]: (state, { response: { data: items = [] } }) =>
      onSuccess({ ...state, items }),
    [combineActions(FETCH_LINEUP.success, UPDATE_LINEUP.success)]: (
      state,
      { response: { data: current = {} } }
    ) => onSuccess({ ...state, current: { ...state.current, ...current } }),
    [CREATE_LINEUP.success]: (state, { response: { data = {} } }) =>
      onSuccess({
        ...state,
        items: [data, ...state.items],
      }),
    [DELETE_LINEUP.success]: (state, { id }) =>
      onSuccess({ ...state, items: state.items.filterById(id) }),
    [combineActions(
      FETCH_LINEUPS.failure,
      FETCH_LINEUP.failure,
      CREATE_LINEUP.failure,
      UPDATE_LINEUP.failure,
      DELETE_LINEUP.failure
    )]: onFailure,
  },
  initialState.lineups
)
