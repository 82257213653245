import React from 'react'
import styled from 'styled-components'
import PROP_TYPES from 'constants/propTypes'
import 'react-big-calendar/lib/css/react-big-calendar.css'

const CalendarStyled = ({ className, children }) => (
  <div className={className}>{children}</div>
)

CalendarStyled.propTypes = {
  className: PROP_TYPES.className.isRequired,
  children: PROP_TYPES.children.isRequired,
}

export default styled(CalendarStyled)`
  height: 100vh;
  min-height: 800px;
  padding: 15px 12px 20px;

  .rbc-month-view {
    border: 0;
    height: auto;

    .rbc-month-header {
      border-bottom: var(--grey-solid) 1px;

      .rbc-header {
        border: 0;
        padding: 15px 7px;
        text-align: left;
        font-weight: normal;
      }

      .rbc-header:last-child,
      .rbc-header:nth-last-child(2) {
        color: var(--dark-green);
      }
    }

    .rbc-month-row {
      overflow: visible;
      border: 0;
      border-bottom: var(--grey-solid) 1px;
      line-height: 1;
      font-size: 14px;

      .rbc-row-bg {
        margin: 10px 10px 10px 0;
        border-left: var(--grey-solid) 1px;
        border-right: var(--grey-solid) 1px;

        .rbc-off-range-bg,
        .rbc-today {
          ${({ theme }) => theme.resetBackground}
        }
      }

      .rbc-row-content {
        padding: 10px 17px 10px 1px;
        height: 100%;

        .rbc-date-cell {
          text-align: left;
          padding-left: 10px;

          &:not(.rbc-off-range):before {
            display: block;
            content: '+';
            height: 20px;
            width: 20px;
            float: right;
            margin: 0 0 0 2px;
            padding-top: 1px;
            border-radius: 50%;
            border: 1px solid var(--primary-link);
            text-align: center;
            font-size: 16px;
            color: var(--primary-link);
            cursor: pointer;
          }
        }

        .rbc-now {
          font-weight: normal;
          background: var(--dark-green);
          color: white;
        }

        .rbc-row-segment {
          padding: 2px 10px 0;
        }
      }
    }
  }
`
