import React from 'react'
import { Translate } from 'react-localize-redux'
import { Field } from 'redux-form'
import { Input, DateInput } from 'components'

const MatchFormFields = () => (
  <>
    <Translate>
      {({ translate }) => (
        <Field
          name="dateDay"
          label={translate('matches.match')}
          component={DateInput}
        />
      )}
    </Translate>
    <Translate>
      {({ translate }) => (
        <Field
          name="meeting_location"
          label={translate('matches.team-meeting-location')}
          component={Input}
        />
      )}
    </Translate>
    <Translate>
      {({ translate }) => (
        <Field
          name="meeting_time"
          label={translate('matches.team-meeting-time')}
          component={Input}
        />
      )}
    </Translate>
    <Translate>
      {({ translate }) => (
        <Field
          name="time"
          type="time"
          label={translate('matches.kickoff-time')}
          component={Input}
        />
      )}
    </Translate>
    <Translate>
      {({ translate }) => (
        <Field
          name="location"
          label={translate('matches.match-location')}
          component={Input}
        />
      )}
    </Translate>
    <Translate>
      {({ translate }) => (
        <Field
          name="opponent"
          label={translate('matches.opponent')}
          component={Input}
        />
      )}
    </Translate>
  </>
)

export default MatchFormFields
