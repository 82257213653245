import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import { CSVLink } from "react-csv"
import { Paper, Tabs, Tab, Toolbar, Button, Box } from '@material-ui/core'
import { players as playersModule } from 'redux/modules'
import SaveAltIcon from '@material-ui/icons/SaveAlt'
import hasSubscription from 'utils/hasSubscription'
import { useStyles } from './components/styled'

import {
  SkillTable,
  PlayerLists,
  PlayerPerformance,
  AttendanceLists
} from './components'

const TeamInsightsContainer = ({ team, team: { players }, playersData, updatePlayer, theme }) => {
  const classes = useStyles(theme)
  const tabs = ['stats', 'personal', 'skills']
  const [value, setValue] = React.useState(tabs[0])
  const [rows, setRows] = useState([...players])

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  useEffect(() => {
    setRows([...players])
  }, [players])


  function sortCsvDataByPlayer(object1, object2) {
    if (object1.Player < object2.Player)
      return -1;
    if (object1.Player > object2.Player)
      return 1;

    return 0;
  }
  const csvData = rows.map((datum) => {
    return (hasSubscription() !== 'basic') ? {
      'Player': `${datum.first_name} ${datum.last_name}`,
      'Uniform#': datum.number,
      'Position': datum.position,
      'Total Team Trainings': datum.trainings_total,
      'Player Training Participation': `${datum.trainings_attended} (${Math.round(
        (datum.trainings_attended * 100) / datum.trainings_total
      )}%)`,
      'Total Matches': datum.total_matches,
      'Match Particiaption': `${datum.match_participation} (${Math.round(
        (datum.match_participation * 100) / datum.total_matches
      )}%)`,
      'Total Match Time (Min)': datum.total_minute_played,
      'Goal Scored': datum.goals_scored,
      'Goal Assisted': datum.goals_assisted,
      'Yellow Card': datum.ycard,
      'Yellow+Red Card': datum.yrcard,
      'Red Card': datum.rcard,
    } : {
      'Player': `${datum.first_name} ${datum.last_name}`,
      'Uniform#': datum.number,
      'Position': datum.position,
      'Total Team Trainings': datum.trainings_total,
      'Player Training Participation': `${datum.trainings_attended} (${Math.round(
        (datum.trainings_attended * 100) / datum.trainings_total
      )}%)`,
    }
  });

  return (
    <Paper className={classes.paper}>
      <Toolbar className={classes.root}>
        <Tabs
          value={value}
          className={classes.title}
          indicatorColor="primary"
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label={<Translate id="tab.team-players-statistics" />} value='stats' />
          <Tab label={<Translate id="tab.team-players-attendance" />} value='attendance' />
          <Tab label={<Translate id="tab.team-players-player-data" />} value='personal' />
          <Tab label={<Translate id="tab.team-players-skills" />} value='skills' />
          <Box ml='auto'>
            {value === 'stats' &&

              <CSVLink data={csvData ? csvData.sort(sortCsvDataByPlayer) : null} className={classes.csvLink}>
                <Button variant="contained" className={classes.csvButton} size="samll" startIcon={<SaveAltIcon fontSize="small" />} color="primary">
                  <Translate id="button.export" />
                </Button>
              </CSVLink>

            }
          </Box>
        </Tabs>
      </Toolbar>
      {value === 'stats' && <PlayerPerformance changeTab={setValue} rows={rows} />}
      {value === 'attendance' && <AttendanceLists changeTab={setValue} rows={rows} />}
      {value === 'personal' && <PlayerLists changeTab={setValue} players={playersData} updatePlayer={updatePlayer} />}
      {value === 'skills' && <SkillTable changeTab={setValue} team={team} players={players} updatePlayer={updatePlayer} />}
    </Paper>
  )
}

TeamInsightsContainer.defaultProps = {
  team: {},
  playersData: {},
  updatePlayer: {},
}

TeamInsightsContainer.propTypes = {
  team: PropTypes.shape(),
  playersData: PropTypes.shape(),
  updatePlayer: PropTypes.shape(),
  theme: PropTypes.oneOfType([
    PropTypes.shape().isRequired,
    PropTypes.oneOf([null]).isRequired,
  ]).isRequired,
}

export default connect(({ teams, players }) => ({
  team: teams.current,
  playersData: players.items,
}), {
  updatePlayer: playersModule.updatePlayer,

})(TeamInsightsContainer)
