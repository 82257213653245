import React from 'react'
import { withLocalize } from 'react-localize-redux'
import { FavoritesLoader, ArticlesCategoriesLoader } from 'loaders'
import ArticlesDashboardContainer from './ArticlesDashboardContainer'
import ArticlesAllDashboardContainer from './ArticlesAllDashboardContainer'
import ArticlesNewDashboardContainer from './ArticlesNewDashboardContainer'
import PROP_TYPES from 'constants/propTypes'

const TYPE_TO_COMPONENT_MAPPER = {
  all: ArticlesAllDashboardContainer,
  new: ArticlesNewDashboardContainer,
}

const ArticlesDashboard = ({
  match: {
    params: { type: articlesType },
  },
  activeLanguage: { code: activeLocale },
}) => {
  const Component =
    TYPE_TO_COMPONENT_MAPPER[articlesType] || ArticlesDashboardContainer

  return (
    <FavoritesLoader entity="articles">
      <ArticlesCategoriesLoader>
        <Component activeLocale={activeLocale} />
      </ArticlesCategoriesLoader>
    </FavoritesLoader>
  )
}

ArticlesDashboard.propTypes = {
  match: PROP_TYPES.match.isRequired,
  activeLanguage: PROP_TYPES.shape().isRequired,
}

export default withLocalize(ArticlesDashboard)
