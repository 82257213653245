import React from 'react'
import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'
import {
  Typography,
  Button,
  Box,
  Grid,
  CardContent,
  Card,
  Paper,
  ButtonGroup,
  Divider,
  List,
  ListItem,
  ListItemText

} from '@material-ui/core'
import { Translate } from 'react-localize-redux'
import PROP_TYPES from 'constants/propTypes'
import { plans, billingCycle } from 'constants/plans'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  list: {
    '& .MuiListItemText-root': {
    textAlign: "center"
  }
  },
  label: {
    padding: theme.spacing(1.5, 0),
 fontFamily: "Chakra Petch, sans-serif",
 fontSize: theme.spacing(3.2),
  justifyContent: "center",
    alignItems: "center",
    display:"flex",
  '& .MuiBox-root': {
    marginLeft: theme.spacing(.8),
    fontSize: theme.spacing(4.8),
    lineHeight:'20px',
    color: theme.palette.primary.main
  },
  },
  FormControl: {
    width: '100%',
    margin: '.5rem',
    '& .MuiInputLabel-formControl': {
      fontSize: '1.2rem',
    },
  },
  DialogSolutionContact: {
    '& .MuiDialog-paper': {
      maxWidth: '383px',
      textAlign: 'center',
    },
  },

  PlanCycleSelected: {
    background: 'rgba(41, 163, 115, 1)',
    color: '#fff',
  },
  toggleButtonGroup: {
    borderRadius: '4rem',
    backgroundColor: '#ebf5f0',

    '& .MuiButton-root:first-child': {
      borderRadius: '1.5rem',
      paddingLeft: '1rem',
      paddingRight: '1rem',
      minWidth: '100px',
    },
    '& .MuiButton-root:last-child': {
      borderRadius: '1.5rem',
      paddingLeft: '1rem',
      paddingRight: '1rem',
      minWidth: '100px',
    },
    '& .MuiButton-root': {
      border: '0',
    },
  },
  billingPlanGrid: {
    '& .MuiCard-root:hover': {
      boxShadow: '14px 5px 35px 0 rgba(0,0,0,0.08)',
      borderColor: '#fff',
    },
  },
  SelectedPlan: {
    '& .MuiCard-root': {
      border: '2px solid rgba(41, 163, 115, 1)',
    },
    '& .MuiCard-root:hover': {
      border: '2px solid rgba(41, 163, 115, 1)',
      boxShadow: '14px 5px 35px 0 rgba(0,0,0,0.08)',
    },
  },
}))

const PlanPriceGroupBox = styled.div`
  padding: 1rem 0 0;
  text-align: center;
  div {
    font-size: 3rem;
    line-height: 3rem;
    font-weight: normal;
  }

`
const SavePlan = styled.div`
  display: inline-block;
  padding-left: 0.5rem;
  color: var(--primary-green);
`

const PricingBillingContainters = ({
  plan: activePlan,
  changebillingCycle,
  changeSubscriptionPlan,
}) => {
  const classes = useStyles()

  // const [billingPlan, setBillingPlan] = React.useState('monthly');

  // const handleChange = (event, newAlignment) => {
  //   setBillingPlan(newAlignment);
  // };

  // transliteration

  plans[0].label = <Translate id="plans.coachbetter" />
  plans[0].benefitsDesc = <Translate id="plans.basic.benefitsDesc" />
  plans[0].benefits = [
    <Translate key={0} id="plans.basic-benefits.1" />,
    <Translate key={1} id="plans.basic-benefits.2" />,
    <Translate key={2} id="plans.basic-benefits.3" />,
    <Translate key={3} id="plans.basic-benefits.4" />,
    <Translate key={3} id="plans.basic-benefits.5" />,
    <Translate key={3} id="plans.basic-benefits.6" />,
    <Translate key={3} id="plans.basic-benefits.7" />,
    <Translate key={3} id="plans.basic-benefits.8" />
  ]

  plans[1].label = <Translate id="plans.coachbetter" />
  plans[1].sub_label = <Translate id="plans.pro.plus" />
  plans[1].benefitsDesc = <Translate id="plans.pro.benefitsDesc" />
  plans[1].benefits = [
    <Translate key={0} id="plans.pro-benefits.1" />,
    <Translate key={1} id="plans.pro-benefits.2" />,
    <Translate key={2} id="plans.pro-benefits.3" />,
    <Translate key={3} id="plans.pro-benefits.4" />,
    <Translate key={3} id="plans.pro-benefits.5" />,
    <Translate key={3} id="plans.pro-benefits.6" />,
    <Translate key={3} id="plans.pro-benefits.7" />,
    <Translate key={3} id="plans.pro-benefits.8" />,
    <Translate key={3} id="plans.pro-benefits.9" />,
    <Translate key={3} id="plans.pro-benefits.10" />,
    <Translate key={3} id="plans.pro-benefits.11" />
  ]

  plans[2].label = <Translate id="plans.coachbetter" />
  plans[2].sub_label = <Translate id="plans.elite.pro" />
  plans[2].benefitsDesc = <Translate id="plans.elite.benefitsDesc" />
  plans[2].benefits = [
    <Translate key={0} id="plans.elite-benefits.1" />,
    <Translate key={1} id="plans.elite-benefits.2" />,
    <Translate key={2} id="plans.elite-benefits.3" />,
    <Translate key={3} id="plans.elite-benefits.4" />,
    <Translate key={3} id="plans.elite-benefits.5" />,
    <Translate key={3} id="plans.elite-benefits.6" />,
    <Translate key={3} id="plans.elite-benefits.7" />,
    <Translate key={3} id="plans.elite-benefits.8" />,
    <Translate key={3} id="plans.elite-benefits.9" />,
    <Translate key={3} id="plans.elite-benefits.10" />,
    <Translate key={3} id="plans.elite-benefits.11" />,
    <Translate key={3} id="plans.elite-benefits.12" />,
    <Translate key={3} id="plans.elite-benefits.13" />,
    <Translate key={3} id="plans.pro-benefits.11" />,
    <Translate key={3} id="plans.elite-benefits.14" />
  ]

  billingCycle[0].label = <Translate key={4} id="plans.monthly" />
  billingCycle[1].label = <Translate key={4} id="plans.annual" />

  return (
    <>
        <Paper className={classes.root}
            style={{position: 'relative'}}
        >
        <Box my={4}>
          <Typography variant="h5" component="h5" align="center">
            <Translate key={4} id="plans.header1" />{' '}
            <Translate key={4} id="plans.header2" />
          </Typography>
        </Box>
        <Box my={4} align="center">

          <ButtonGroup
            className={classes.toggleButtonGroup}
            color="primary"
            size="large"
          >
            {billingCycle.map(cycle => (
              <>
                <Button
                  key={cycle.value}
                  disabled={
                    activePlan.plan.value === 'champion' &&
                    cycle.value === 'monthly'
                  }
                  onClick={() => changebillingCycle(cycle.value)}
                  className={
                    cycle.value === activePlan.billingCycle.value
                      ? classes.PlanCycleSelected
                      : ''
                  }
                >
                  {cycle.label}
                </Button>
              </>
            ))}
          </ButtonGroup>
          <SavePlan>
            <Translate key={4} id="plans.save-percentage" />
          </SavePlan>
        </Box>
        <Grid container spacing={3}>
          {plans.map(plan => {
            const monthlyByYearlyPlan =
              plan !== 'champion' ? (plan.annual / 12).toFixed(2) : 0
            /* let calulatePercentage = 0
            if (plan !== 'champion') {
              const totalMonthly = plan.monthly * 12
              const totalYearly = plan.annual

              calulatePercentage = (
                ((totalMonthly - totalYearly) /
                  ((totalMonthly + totalYearly) / 2.0)) *
                100
              ).toFixed(2)
            } */

            return (
              <Grid
                key={plan.value}
                item
                lg={4}
                sm={12}
                xs={12}
                className={classes.billingPlanGrid}
              >
                <div
                  className={
                    plan.value === activePlan.plan.value
                      ? classes.SelectedPlan
                      : ''
                  }
                >
                  <Card variant="outlined">
                    <CardContent>
                      <Typography variant="h3" component="h3" className={classes.label}>
                        {plan.label} <Box variant="span" component="span">{plan.sub_label}</Box>
                      </Typography>
                      <Divider/>
                      <Box py={3}>
                      <Typography variant="h6" component="h6" align="center">
                        {plan.benefitsDesc}
                      </Typography>
                     
                      {plan.monthly ? (
                        <PlanPriceGroupBox>
                          {activePlan.billingCycle.value === 'monthly' && (
                            <>
                              <div>
                                {`${activePlan.currency} ${plan.monthly.toFixed(2)}`}{' '}
                               
                              </div>
                              <Typography variant="p" component="p">
                                  <Translate id="checkout.monthly" />
                                </Typography>
                              <Button
                                onClick={() => changebillingCycle('annual')}
                              >
                                <span>
                                  <Translate id="checkout.switch" />
                                  {` ${activePlan.currency}${(
                                    plan.monthly * 12 -
                                    plan.annual
                                  ).toFixed(2)}`}
                                </span>
                              </Button>
                            </>
                          )}
                          {activePlan.billingCycle.value === 'annual' && (
                            <>
                              <div>
                                {`${activePlan.currency} ${monthlyByYearlyPlan}`}{' '}
                              </div>
                              <Typography variant="p" component="p">
                                  <Translate id="checkout.monthly" />
                                </Typography>{' '}
                            </>
                          )}
                        </PlanPriceGroupBox>
                      ) : (
                        ''
                      )}
                     
                    
                      {!plan.monthly && (
                        <PlanPriceGroupBox>
                          <div>
                          <Translate key={4} id="plans.free" />
                          </div>
                         
                        </PlanPriceGroupBox>
                      )}
                      </Box> 
                    <Divider/>
                     <List className={classes.list}>
                          {plan.benefits.map((benefit) => (
                            <ListItem key={benefit}>
                              <ListItemText primary={benefit}/>
                           </ListItem>
                          ))}
                        </List>
                      {plan.value !== "basic" && 
                        <Button
                          fullWidth
                          onClick={() => changeSubscriptionPlan(plan.value)}
                          variant={
                            plan.value === activePlan.plan.value
                              ? 'contained'
                              : 'outlined'
                          }
                          size="large"
                          color="primary"
                        >
                          <Translate key={4} id="plans.selectplan" />
                        </Button>
                      }
                    </CardContent>
                  </Card>
                </div>
              </Grid>
            )
          })}
        </Grid>
      </Paper>
    </>
  )
}

PricingBillingContainters.propTypes = {
  plan: PROP_TYPES.shape().isRequired,
  changebillingCycle: PROP_TYPES.func.isRequired,
  changeSubscriptionPlan: PROP_TYPES.func.isRequired,
}
export default PricingBillingContainters
