import React, { useState, useMemo } from 'react'
import { connect } from 'react-redux'
import { Translate } from 'react-localize-redux'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { SubLink, HeartIcon, HasClubPermission } from 'containers'
import { TrainingPackageModal } from 'components'
import {
  Grid,
  Card,
  Box,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  Typography,
} from '@material-ui/core'
import { getClub, getUserId } from 'utils/localstorage'
import PROP_TYPES from 'constants/propTypes'
import {
  TRAINING_PACKAGE_EDIT_ROUTE,
} from 'constants/routes'
import PERMISSIONS from 'constants/permissions'

const MuiBox = withStyles(theme => ({
  root: {
    display: 'flex',

    '& .MuiBox-root': {
      margin: theme.spacing(0, 1.5, 0, 0),
      '& .MuiTypography-root': {
        fontWeight: 500,
        color: theme.palette.action,
        marginRight: theme.spacing(0.5),
      },
    },
  },
}))(Box)
const MuiCard = withStyles(theme => ({
  root: {
    border: '1px solid #e8e8e8',
    '& .MuiCardContent-root': {
      padding: theme.spacing(2, 2, 0),
    },
  },
  indicator: {
    backgroundColor: '#1890ff',
  },
}))(Card)

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  descriptionCell: {
    whiteSpace: 'nowrap',
    maxWidth: 'calc(100vw - 20px)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  button: { margin: theme.spacing(0, 1) },
  media: {
    height: 140,
  },
  title: {
    flex: '1 1 0',
  },
  fab: {
    position: 'absolute',
    top: theme.spacing(-3),
    right: theme.spacing(2),
  },
}))

const TrainingPackagesDashboardContainer = ({
  trainingPackages,
  favoritesTrainingPackages,
  auth,
}) => {
  const [openedPackageId, changeOpenedPackageId] = useState(null)
  const classes = useStyles()
  const openedPackage = useMemo(
    () => trainingPackages.findById(openedPackageId),
    [openedPackageId, trainingPackages]
  )

  const closeOpenedPackage = () => changeOpenedPackageId(null)

  const handleOpenPackage = id => e => {
    e.preventDefault()

    changeOpenedPackageId(id)
  }

  const getIsFavorite = id => Boolean(favoritesTrainingPackages.findById(id))

  const clubPermissionFound = auth.permissions.clubs.find(club => {
    return club.id === getClub();
  });

  return (
    <>
      {openedPackageId && (
        <TrainingPackageModal
          trainingPackage={openedPackage}
          onHide={closeOpenedPackage}
          open={openedPackageId}
        />
      )}

      <Grid container spacing={3}>
        {trainingPackages.map(
          ({
            id: trainingPackageId,
            title: titleValue,
            duration: durationValue,
            number_of_players: playersNum,
            images: imagesUrl,
            user_id: userId,
            club_id: trainingPackageClubId,
          }) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              xl={2}
              key={trainingPackageId}
            >
              <MuiCard>
                <CardMedia
                  className={classes.media}
                  image={imagesUrl.findEndGetProp(Boolean, 'full_url')}
                />

                <CardContent>
                  <Typography
                    gutterBottom
                    className={classes.descriptionCell}
                    variant="h4"
                    component="h4"
                  >
                    {titleValue}
                  </Typography>
                  <MuiBox>
                    <Box>
                      {' '}
                      <Typography variant="span" component="span">
                        <Translate id="training.duration" />{' '}
                      </Typography>{' '}
                      {durationValue} min{' '}
                    </Box>

                    <Box>
                      {' '}
                      <Typography variant="span" component="span">
                        {' '}
                        <Translate id="drills.players" /> :
                      </Typography>{' '}
                      {playersNum}
                    </Box>
                  </MuiBox>
                </CardContent>

                <CardActions>
                  <div className={classes.title}>
                    <HasClubPermission ability={PERMISSIONS.UPDATE_TRAINING_PACKAGE} or={getUserId() === userId || (clubPermissionFound?.id === trainingPackageClubId)} >
                      <Button
                        size="small"
                        color="primary"
                        className={classes.button}
                        variant="contained"
                        component={SubLink}
                        to={TRAINING_PACKAGE_EDIT_ROUTE.populateURLParams({
                          trainingPackageId,
                        })}
                      >
                        <Translate id="button.edit" />
                      </Button>
                    </HasClubPermission>
                    <Button variant="contained" size="small" onClick={handleOpenPackage(trainingPackageId)}>
                      <Translate id="button.view" />
                    </Button>
                  </div>
                  <HeartIcon
                    isActive={getIsFavorite(trainingPackageId)}
                    entity="trainingPackages"
                    entityId={trainingPackageId}
                    isPositionRelative
                  />
                </CardActions>
              </MuiCard>
            </Grid>
          )
        )}
      </Grid>

    </>
  )
}

TrainingPackagesDashboardContainer.defaultProps = {
  trainingPackages: [],
  favoritesTrainingPackages: [],
  auth: [],
}

TrainingPackagesDashboardContainer.propTypes = {
  trainingPackages: PROP_TYPES.arrayOfObjects,
  favoritesTrainingPackages: PROP_TYPES.arrayOfObjects,
  auth: PROP_TYPES.arrayOfObjects,
}

export default connect(({ trainingPackages, favorites, auth}) => ({
  trainingPackages: trainingPackages.items,
  favoritesTrainingPackages: favorites.items.trainingPackages,
  auth: auth,
}))(TrainingPackagesDashboardContainer)
