import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import {
  Avatar,
  Box,
  Hidden,
  Typography,
  Step,
  Stepper,
  StepLabel,
} from '@material-ui/core'
import Logo from '../../../assets/images/logo-black.svg'
import useStyles from '../../styleComponents'

const Header = ({ signedUp, theme }) => {
  const classes = useStyles()

  return (
    <Box>
      <Box align="center">
        <Avatar
          variant="square"
          src={ theme ? theme.logo : Logo }
          alt="Coachbetter"
          className={ classes.signupLogo }
        />
      </Box>
      <Stepper activeStep={ signedUp ? 1 : 0 } className={ classes.step } alternativeLabel>
        <Step key={ 0 }>
          <StepLabel/>
        </Step>
        <Step key={ 1 }>
          <StepLabel/>
        </Step>
      </Stepper>
      { !signedUp && (
        <Box align="center" mt={ 2 } mb={ 3 }>
          <Typography variant="h1" component="h1" className={ classes.heading }>
            <Translate id="signup.signup" />
          </Typography>
          <Hidden only={ [ 'xs', 'sm' ] }>
            <Typography
              variant="body1"
              component="p"
              className={ classes.paragraph }
            >
              <Translate id="signup.welcome" />
            </Typography>
          </Hidden>
        </Box>
      ) }
    </Box>
  )
}

Header.propTypes = {
  signedUp: PropTypes.bool.isRequired,
}

export default Header
