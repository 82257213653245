import React, {useState} from 'react'
import styled from 'styled-components'
import { withStyles } from '@material-ui/core/styles'
import { Card } from 'components'
import EditIcon from '@material-ui/icons/Edit'
import Rating from '@material-ui/lab/Rating'
import IconButton from '@material-ui/core/IconButton'
import filterObject from 'utils/filterObject'
import BallIcon from 'assets/images/ballicon.svg'
import hasSubscription from 'utils/hasSubscription'
import TeamEditDialog from './TeamEditDialog'
import PROP_TYPES from 'constants/propTypes'

const EditButton = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
`
const ClubInfo = styled(Card)`
  box-shadow: none !important;
  background: #fff;
  height: 100%;
  .clubName {
    margin: 1rem 0;
    font-weight: 600;
    font-size: 16px;
    color: #062221;
  }
`

const ImageContainer = styled.div`
  width: 100%;
  height: 120px;
  margin: auto;
  margin-top: 2rem;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`

const StyledRating = withStyles(theme => ({
  root: {
    alignItems: 'center',
    color: theme.palette.primary.main,
  },
}))(Rating)

const TeamSummary = ({ team }) => {
  const [open, setOpen] = useState(false)

  const handleClick = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const [initialValues, setInitialValues] = useState({
    club_id: 1,
    gender: 'm',
    ...filterObject({ ...team }, Boolean),
  })

  const {
    name,
    // league,
    // gender,
    logo,
    statistics: { rating },
  } = initialValues

  React.useEffect(() => {}, [initialValues])

  return (
    <ClubInfo style={{padding: '0.5rem', paddingBottom: '1rem', marginBottom: 0}}>
      <EditButton>
        <IconButton onClick={handleClick}>
          <EditIcon fontSize="small" />
        </IconButton>
      </EditButton>
      {/* edit team modal */}
      <TeamEditDialog
        open={open}
        handleClick={handleClick}
        handleClose={handleClose}
        initialValues={{ ...initialValues, logo }}
        setInitialValues={setInitialValues}
      />

      <ImageContainer>
        <img
          alt="logo"
          src={logo && logo.full_url ? logo.full_url : BallIcon}
        />
      </ImageContainer>
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <span className="clubName">{name}</span>
        {!(hasSubscription() === 'basic') &&
          <StyledRating name="read-only" value={rating} readOnly />
        }
      </div>
    </ClubInfo>
  )
}
TeamSummary.defaultProps = {}
TeamSummary.propTypes = {
  team: PROP_TYPES.shape().isRequired,
}

export default TeamSummary
