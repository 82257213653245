import React, { useState, useEffect, useMemo, useCallback } from 'react'
import moment from 'moment'
import IconLeft from 'assets/images/16px/chevron-left@1x.svg'
import IconRight from 'assets/images/16px/chevron-right@1x.svg'
import {
  Wrapper,
  Navigation,
  NavPrev,
  NavNext,
  Items,
  Item,
} from './styledComponents'
import PROP_TYPES from 'constants/propTypes'

const EVENTS_SHOWN_COUNT = 5

const MiniCalendar = ({ currentEventId, events, onEventSelected }) => {
  const [startFromIndex, changeStartFromIndex] = useState(0)

  useEffect(() => {
    const currentEventIndex = events.findIndex(
      ({ id }) => id === +currentEventId
    )

    if (currentEventIndex > 0) {
      changeStartFromIndex(currentEventIndex)
    }
  }, [])

  const firstEventMomentDate = useMemo(
    () => moment(events[startFromIndex] && events[startFromIndex].date),
    [events, startFromIndex]
  )

  const visibleEvents = useMemo(
    () => [...events].splice(startFromIndex, EVENTS_SHOWN_COUNT),
    [events, startFromIndex]
  )

  const todayDate = useMemo(() => moment().format('YYYY-MM-DD'), [])

  const handlePrev = useCallback(() => {
    const newStartFromIndex =
      startFromIndex - EVENTS_SHOWN_COUNT >= 0
        ? startFromIndex - EVENTS_SHOWN_COUNT
        : 0

    changeStartFromIndex(newStartFromIndex)
  }, [startFromIndex])

  const handleNext = useCallback(() => {
    let newStartFromIndex =
      startFromIndex + EVENTS_SHOWN_COUNT < events.length
        ? startFromIndex + EVENTS_SHOWN_COUNT
        : events.length - EVENTS_SHOWN_COUNT

    if (newStartFromIndex < 0) {
      newStartFromIndex = 0
    }

    changeStartFromIndex(newStartFromIndex)
  }, [startFromIndex])

  const isPrevShown = useMemo(() => startFromIndex > 0, [startFromIndex])

  const isNextShown = useMemo(
    () => startFromIndex + EVENTS_SHOWN_COUNT < events.length,
    [startFromIndex, events]
  )

  return (
    <Wrapper>
      <Navigation>
        {isPrevShown && (
          <NavPrev onClick={handlePrev} role="presentation">
            <img src={IconLeft} alt="Previous" />{' '}
            {/* <Translate id="training.previous" /> */}
          </NavPrev>
        )}
      </Navigation>
      <Items>
        {visibleEvents.map(({ id, date, status }) => {
          const momentDate = moment(date)

          const dateDay = momentDate.format('D.MMM')
          const isToday = momentDate.format('YYYY-MM-DD') === todayDate
          const isAnotherMonth =
            momentDate.format('MM') !== firstEventMomentDate.format('MM')

          return (
            <Item
              key={id}
              isCurrent={id === +currentEventId}
              status={status}
              isToday={isToday}
              isAnotherMonth={isAnotherMonth}
              onClick={() => onEventSelected(id)}
            >
              {dateDay}
            </Item>
          )
        })}
      </Items>
      <Navigation>
        {isNextShown && (
          <NavNext onClick={handleNext} role="presentation">
            {/* <Translate id="training.next" />  */}
            <img src={IconRight} alt="Next" />
          </NavNext>
        )}
      </Navigation>
    </Wrapper>
  )
}

MiniCalendar.defaultProps = {
  currentEventId: null,
  events: [],
}

MiniCalendar.propTypes = {
  currentEventId: PROP_TYPES.id,
  events: PROP_TYPES.arrayOfObjects,
  onEventSelected: PROP_TYPES.func.isRequired,
}

export default MiniCalendar
