import React from 'react'
import { Avatar, Box, Divider, Hidden, TableCell, TableRow, Typography } from '@material-ui/core'
import SportsSoccerIcon from '@material-ui/icons/SportsSoccer'
import { SubLink } from 'containers'
import { Translate } from 'react-localize-redux'
import { display24HrTime, displayDateForEventPage, getDayName } from 'utils/formatDate'
import TeaserTagOverlay from 'components/Teaser/TeaserTagOverlay'
import Actions from './Actions'
import PROP_TYPES from 'constants/propTypes'
import { PLUS_IN_CAPS } from 'constants/plans'

const CommonTableRow = ({
  borderClassMobile,
  eventBorder,
  generateURL,
  handleOpen,
  openAlertBox,
  openDuplicateBox,
  event
}) => {

  const displayAddress = () => {
    if (event.shortAddress) {
      if (event.relation === 'training' || event.relation === 'birthday') {
        return event.shortAddress
      }

      if (event.relation === 'event') {
        return `${event.shortAddress} | ${event.description}`
      }

      if (event.home === 1) {
        return <Translate id="matches.home-game" />
      }

      return <Translate id="matches.away-game" />
    }

    if (event.relation === 'training' || event.relation === 'birthday') {
      return ''
    }

    if (event.relation === 'event') {
      return event.description
    }

    if (event.home === 1) {
      return <Translate id="matches.home-game" />
    }

    return <Translate id="matches.away-game" />
  }

  const displayAvailableAndTotalPlayers = () => {

    return `${event.availablePlayers || 0}/${event.totalPlayers || 0}`
  }

  return (
    <>
      <TableRow className={borderClassMobile}>
        <TableCell>
          
            <Box display="flex">
              <Box component="div">

                {event.relation === 'match' ? (
                  <TeaserTagOverlay tagLabel={PLUS_IN_CAPS}>
                    {event.logo && event.logo.full_url ? (
                      <Avatar src={event.logo.full_url} />
                    ) : (
                      <Avatar>
                        <SportsSoccerIcon />
                      </Avatar>
                    )}

                  </TeaserTagOverlay>) : <>
                  {event.logo && event.logo.full_url ? (
                    <Avatar src={event.logo.full_url} />
                  ) : (
                    <Avatar>
                      <SportsSoccerIcon />
                    </Avatar>
                  )}

                </>}
                </Box>
            </Box>
      
        </TableCell>
        <TableCell component="th" scope="row">
          <SubLink
            to={generateURL(event.id, event.teamId, event.seasonId, event.relation)}
            isFullPath
          >
            <Hidden only={['xs', 'sm']}>
              <Typography variant="h4" component="h4">
                {displayDateForEventPage(event.date)}
              </Typography>
              <Typography variant="h6" fontSize={14} component="h6">
                <Translate id={`day-name.${getDayName(event.date).toLowerCase()}`} />
              </Typography>
            </Hidden>
            <Hidden only={['md', 'lg', 'xl']}>
              <Typography variant="h4" component="h4">
                <SubLink
                  to={generateURL(event.id, event.teamId, event.seasonId, event.relation)}
                  isFullPath
                >
                  {event.title}
                </SubLink>
              </Typography>
              <Typography variant="h6" fontSize={14} component="h6">
                <Translate id={`day-name.${getDayName(event.date).toLowerCase()}`} />
                &nbsp;{displayDateForEventPage(event.date)}
              </Typography>
            </Hidden>
          </SubLink>
        </TableCell>
        <TableCell align="center">
          <Divider className={eventBorder} />
        </TableCell>
        <TableCell align="left">
          {event.relation !== 'birthday' && (
            <Typography variant="h4" component="h4">
              {display24HrTime(event.date)}
            </Typography>
          )}
        </TableCell>
        <TableCell align="left">
          <Hidden only={['xs', 'sm']}>
            <Typography variant="h4" component="h4" className='pb-1'>
              <SubLink
                to={generateURL(event.id, event.teamId, event.seasonId, event.relation)}
                isFullPath
              >
                {event.relation === 'event' ? (
                  <Translate id="home.general-event" />
                ) : (
                  event.title
                )}
              </SubLink>
            </Typography>
          </Hidden>
          <Typography variant="caption" component="h6">
            {displayAddress()}
          </Typography>
          {event.trainingFocus &&
            <Typography variant="caption" component="h6">
              <Translate id="form.training-focus" />: {event.trainingFocus}
            </Typography>
          }
        </TableCell>
        <TableCell align="left">
          <Hidden only={['xs', 'sm']}>
            {event.relation !== 'birthday' && (
              <>
                <Typography variant="h4" component="h4">
                  {displayAvailableAndTotalPlayers()}
                </Typography>
                <Typography variant="h6" fontSize={14} component="h6">
                  <Translate id="events.player-available" />
                </Typography>
              </>
            )}
          </Hidden>

          {event.relation === 'birthday' && (
            <SubLink
              to={generateURL(event.id, event.teamId, event.seasonId, event.relation)}
              isFullPath
            >
              {`${event.firstName} ${event.lastName}`}
            </SubLink>
          )}

          <Hidden only={['md', 'lg', 'xl']}>
            {event.relation !== 'birthday' && (
              <Typography variant="h6" fontSize={14} component="h6">
                {displayAvailableAndTotalPlayers()}
                &nbsp; <Translate id="events.player-available" />
              </Typography>
            )}
          </Hidden>
        </TableCell>

        <TableCell align="right">
          <>
            {event.relation !== 'birthday' && (
              <Actions event={event} onOpen={handleOpen} onDuplicateBox={openDuplicateBox} onDeleteEvent={openAlertBox} />
            )}
          </>
        </TableCell>
      </TableRow>
    </>
  )
}

CommonTableRow.propTypes = {
  generateURL: PROP_TYPES.func.isRequired,
  openAlertBox: PROP_TYPES.func.isRequired,
  handleOpen: PROP_TYPES.func.isRequired,
  openDuplicateBox: PROP_TYPES.func.isRequired,
  borderClassMobile: PROP_TYPES.shape().isRequired,
  eventBorder: PROP_TYPES.shape().isRequired,
  event: PROP_TYPES.shape().isRequired
}

export default CommonTableRow