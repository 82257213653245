import React from 'react'
import { connect } from 'react-redux'
import { useDropzone } from 'react-dropzone'
import styled from 'styled-components'
import { documents as documentsModule } from 'redux/modules'
import UploadImage from 'assets/images/icon-upload.svg'
import PROP_TYPES from 'constants/propTypes'

const FILE_MAX_SIZE = 1024 * 1024 * 256

const StyledArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border: var(--dark-grey) dashed 1.5px;
  outline: none;
  cursor: copy;
  text-align: center;
  font-size: 14px;
  color: #000;

  width: 345px;
  height: 125px;
  margin: 0 25px 25px 0;
`

const UploadIcon = styled.div`
  display: block;
  width: 35px;
  height: 35px;
  background: url(${UploadImage}) no-repeat center;
  background-size: contain;
`

const AddNewFile = ({ currentTeamId, currentFolderId, uploadFileToFolder }) => {
  const onDrop = acceptedFiles => {
    acceptedFiles.forEach(file => {
      uploadFileToFolder(currentTeamId, currentFolderId, { attachment: file })
    })
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxSize: FILE_MAX_SIZE,
    multiple: false,
  })

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <StyledArea>
        {isDragActive ? (
          <span>Drop the files here ...</span>
        ) : (
          <>
            <div className="pb-2">
              Choose a Document <strong>or drag it here</strong>
            </div>
            <UploadIcon />
          </>
        )}
      </StyledArea>
    </div>
  )
}

AddNewFile.propTypes = {
  currentTeamId: PROP_TYPES.id.isRequired,
  currentFolderId: PROP_TYPES.id.isRequired,
  uploadFileToFolder: PROP_TYPES.func.isRequired,
}

export default connect(
  null,
  {
    uploadFileToFolder: documentsModule.uploadFileToFolder,
  }
)(AddNewFile)
