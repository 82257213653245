import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Dropdown } from 'components'

const DropdownContainer = styled.div`
  position: absolute;
  right: 0;
`

const ActiveViewSelector = ({ activeView, changeActiveView }) => (
  <DropdownContainer>
    <Dropdown
      options={[
        {
          value: 'month',
          title: 'Month',
        },
        {
          value: 'overview',
          title: 'Overview',
        },
      ]}
      value={activeView}
      onSelect={changeActiveView}
    />
  </DropdownContainer>
)

ActiveViewSelector.propTypes = {
  activeView: PropTypes.string.isRequired,
  changeActiveView: PropTypes.func.isRequired,
}

export default ActiveViewSelector
