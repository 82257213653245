import React from 'react'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import { withStyles } from '@material-ui/core/styles'
import { Dialog, DialogContent, Typography, Box } from '@material-ui/core'
import Slide from '@material-ui/core/Slide'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import PROP_TYPES from 'constants/propTypes'


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(.1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  
return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />
})

// eslint-disable-next-line react/no-multi-comp
const OwnModal = ({
  title,
  children,
  onHide,
  boldTitle,
  titleRight,
  open,
}) => {
  
  const Title = boldTitle ? <Typography variant='h4' component='h4'>{title}</Typography> : title

 return (
    <Dialog
      open={open}
      onClose={onHide}
      TransitionComponent={Transition}
      centered
      onHide={onHide}
      fullWidth
      maxWidth="xl"
    >
      <DialogTitle id="dialog-title" onClose={onHide}>
      <Typography variant='h4' component='h4'> {Title}</Typography>
      <Box>{titleRight}</Box>
        </DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
    </Dialog>
  )
}

OwnModal.defaultProps = {
  title: '',
  children: <div />,
  boldTitle: false,
  titleRight: '',
  open: false,
}

OwnModal.propTypes = {
  title: PROP_TYPES.string,
  children: PROP_TYPES.children,
  onHide: PROP_TYPES.func.isRequired,
  boldTitle: PROP_TYPES.string,
  open: PROP_TYPES.bool,
  titleRight: PROP_TYPES.string,
}

export default OwnModal
