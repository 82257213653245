import React, { useState } from 'react'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import { DroppableArea } from 'components'
import GROUPS from '../../../groupConstant'
import PlayersList from './PlayersList'

const EmptyGroup = styled(Grid)`
  padding-left: 5px;
  padding-right: 5px;

  > div {
    margin-bottom: 5px;
    min-height: 80px;
    line-height: 17px;
    letter-spacing: 0.1px;
    font-weight: 300;
  }

  > div > div:first-child {
    margin-bottom: 5px;
    color: ${props => props.theme.palette.primary.main};
  }
`

const Group = styled(Grid)`
  display: flex;
  margin-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;

  > div {
    flex-grow: 1;
    padding: 5px;
    border: 1px solid #ededed;
    background: #fff;
    border-radius: 8px;
    box-shadow: 14px 5px 35px 0 rgba(0, 0, 0, 0.08);
    max-width: 100%;
  }
`
const GroupTitle = styled.div`
background:  ${props => props.theme.palette.primary.main};
width: 100% !important;
min-height: 40px !important;
padding: 10px;
border-radius: 8px !important;
font-size: 0.9rem !important;
font-weight: 500 !important;
text-align: center;
color: ${props => props.theme.palette.primary.contrastText};
margin-bottom: 9px !important;
}
`

const Summary = styled.div`
  ${({ theme }) => theme.flexCenter};
  flex-direction: column;
  margin: 5px 0;
  cursor: pointer;

  > span {
    font-size: 22px;
    line-height: 25px;
    letter-spacing: 0.16px;
    color: ${props => props.theme.palette.secondary.main};
  }

  > div {
    ${({ theme }) => theme.flexCenter};

    height: 40px;
    width: 40px;
    border: var(--text-dark) 1px solid;
    border-radius: 50%;
    font-size: 19px;
    line-height: 17px;
    letter-spacing: 0.13px;
    color: var(--text-dark);
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const GroupsList = ({
  groupsCount,
  players,
  changeIsSelectedById,
  isAnyPlayerSelected,
}) => {
  const [isOpen, changeIsOpen] = useState(true)

  const toggleOpen = () => changeIsOpen(!isOpen)

  const filterPlayersByGroup = group =>
    players.filter(player => player.relation === group)

  return (
    <Grid container spacing={2}>
      {GROUPS.slice(0, groupsCount).map(group => {
        const groupPlayers = filterPlayersByGroup(group.value)

        if (!groupPlayers.length) {
          return (
            <>
              <EmptyGroup
                item
                xs={12}
                sm={6}
                md={12 / groupsCount}
                key={group.value}
              >
                <GroupTitle>{group.title}</GroupTitle>
                <DroppableArea relation={group.value}>
                  <>
                    <span>
                      <Translate id="training.choose-player" />
                    </span>
                  </>
                </DroppableArea>
              </EmptyGroup>
            </>
          )
        }

        return (
          <Group item xs={12} sm={6} md={12 / groupsCount} key={group.value}>
            <PlayersList
              relation={group.value}
              players={isOpen ? groupPlayers : []}
              changeIsSelectedById={changeIsSelectedById}
              isAnyPlayerSelected={isAnyPlayerSelected}
            >
              <Summary onClick={toggleOpen} role="presentation">
                <GroupTitle>{group.title}</GroupTitle>
                <div>{groupPlayers.length}</div>
              </Summary>
            </PlayersList>
          </Group>
        )
      })}
    </Grid>
  )
}

GroupsList.defaultProps = {
  players: [],
  isAnyPlayerSelected: false,
}

GroupsList.propTypes = {
  groupsCount: PropTypes.oneOf([2, 3, 4]).isRequired,
  players: PropTypes.arrayOf(PropTypes.object.isRequired),
  changeIsSelectedById: PropTypes.func.isRequired,
  isAnyPlayerSelected: PropTypes.bool,
}

export default GroupsList
