import React, { useState, useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { SequenceModal, SubLink, VideoPlayer } from 'containers'
import {
  Card,
  InputLikeButton,
  OwnGrid,
  Button,
  ActionWithPrompt,
  CloseButton,
  TextArea,
} from 'components'
import { videos as videosModule } from 'redux/modules'
import filterObject from 'utils/filterObject'
import Sequences from './components'
import { VIDEO_ANALYSIS_ROUTE } from 'constants/routes'
import { Grid } from '@material-ui/core'

const VideoContainer = styled.div`
  padding: 15px 0;
  position: relative;
`

const LeftPart = styled(OwnGrid.Col)`
  display: table;
`

const RightPart = styled(OwnGrid.Col)`
  overflow-y: auto;
  padding-right: 0;
`

const VideoLayout = ({
  video,
  historySubPush,
  updateVideo,
  deleteVideo,
  updateVideoSequence,
  deleteVideoSequence,
}) => {
  const [videoState, changeVideoState] = useState(video)
  useEffect(() => {
    changeVideoState({ ...videoState, sequences: video.sequences })
  }, [video.sequences])

  const [selectedSequenceId, changeSelectedSequenceId] = useState(null)

  const leftPart =
    document.getElementById('video-left-part') &&
    document.getElementById('video-left-part').getBoundingClientRect()

  useEffect(() => {
    document.getElementById(
      'video-right-part'
    ).style.height = `${document.getElementById('video-left-part')
      .clientHeight - 15}px`
  }, [leftPart])

  const {
    id: videoId,
    title,
    notes,
    video_url: videoUrl,
    sequences,
    players,
    matches,
    training_id: trainingId,
    match_id: matchId,
  } = videoState

  const onHide = useCallback(() => changeSelectedSequenceId(null), [])

  const selectedSequence = useMemo(
    () => sequences.findById(selectedSequenceId),
    [selectedSequenceId]
  )

  const onChangeTitle = ({ target: { value } }) =>
    changeVideoState({ ...videoState, title: value })
  const onChangeNotes = ({ target: { value } }) =>
    changeVideoState({ ...videoState, notes: value })

  const handleSave = () => {
    updateVideo(
      videoId,
      filterObject(
        { title, notes, training_id: trainingId, match_id: matchId },
        Boolean
      )
    ).then(() => historySubPush(VIDEO_ANALYSIS_ROUTE))
  }

  const handleDelete = () => {
    historySubPush(VIDEO_ANALYSIS_ROUTE)

    deleteVideo(videoId)
  }

  return (
    <>
      {selectedSequenceId && (
        <SequenceModal
          onHide={onHide}
          sequence={selectedSequence}
          updateSequence={updateVideoSequence}
          deleteSequence={deleteVideoSequence}
          players={players}
          matches={matches}
          open={Boolean(selectedSequenceId)}
        />
      )}
      <Card>
        <Card.Title style={{display: 'flex', justifyContent:'space-between'}}>
          Videos
          <SubLink to={VIDEO_ANALYSIS_ROUTE}>
            <CloseButton />
          </SubLink>
        </Card.Title>
        <Grid container spacing={2}>
          <Grid item md={10}> 
          <LeftPart id="video-left-part">
            <InputLikeButton value={title} onChange={onChangeTitle} style={{padding: ".7rem"}}/>
            <VideoContainer>
              <VideoPlayer
                video={{ id: videoId, url: videoUrl }}
                changeSelectedSequenceId={changeSelectedSequenceId}
                sequences={sequences}
              />
            </VideoContainer>
          </LeftPart>
          </Grid>
          <Grid item md={2}>
          <RightPart id="video-right-part">
            <Sequences
              sequences={sequences}
              changeSelectedSequenceId={changeSelectedSequenceId}
            />
          </RightPart>
          </Grid>
          <Grid item md={10}>
            <TextArea
              value={notes || ''}
              onChange={onChangeNotes}
              placeholder="Notes"
            />
          </Grid>
          <Grid item md={2}>
            <ActionWithPrompt
              onSuccess={handleDelete}
              className="flex-grow-1 text-danger border-danger"
              message="Are you sure you want to delete the Video?"
              title="Delete"
            />
            <Button block success onClick={handleSave}>
              Save
            </Button>
          </Grid>
      </Grid>
      </Card>
    </>
  )
}

VideoLayout.propTypes = {
  video: PropTypes.shape().isRequired,
  historySubPush: PropTypes.func.isRequired,
  updateVideo: PropTypes.func.isRequired,
  deleteVideo: PropTypes.func.isRequired,
  updateVideoSequence: PropTypes.func.isRequired,
  deleteVideoSequence: PropTypes.func.isRequired,
}

export default connect(
  ({ videos }) => ({
    video: videos.current,
  }),
  {
    updateVideo: videosModule.updateVideo,
    deleteVideo: videosModule.deleteVideo,
    updateVideoSequence: videosModule.updateVideoSequence,
    deleteVideoSequence: videosModule.deleteVideoSequence,
  }
)(VideoLayout)
