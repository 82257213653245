import React, { useEffect } from "react";
import PropTypes from 'prop-types'
import {
  Tabs, Tab, Toolbar, Box
} from '@material-ui/core'
import { Translate } from 'react-localize-redux'
import { useStyles } from '../styled'
import StatisticsFilter from '../StatisticsFilter/index'
import Lists from './Lists'

const AttendanceLists = ({
  theme,
}) => {

  const tabs = ['TRAINING', 'MATCH', 'EVENT']
  const [value, setValue] = React.useState(tabs[0])
  const [rows, setRows] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const classes = useStyles(theme)

  useEffect(() => {

  }, [rows])

  return (
    <>
      <Toolbar className={classes.root}>
        <Tabs
          value={value}
          className={classes.title}
          indicatorColor="primary"
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label={<Translate id="tab.tab-attendance.trainings" />} value="TRAINING" />
          <Tab label={<Translate id="tab.tab-attendance.matches" />} value="MATCH" />
          <Tab label={<Translate id="tab.tab-attendance.events" />} value="EVENT" />
        </Tabs>
        <Box ml="auto">
          <StatisticsFilter muiSM1={8} muiMD1={12} muiSM2={3} tab={value} isAttendance setRows={setRows} setLoading={setLoading} />
        </Box>
      </Toolbar>
      {value === "TRAINING" && <Lists changeTab={setValue} rows={rows} loading={loading} />}
      {value === "MATCH" && <Lists changeTab={setValue} rows={rows} loading={loading} />}
      {value === "EVENT" && <Lists changeTab={setValue} rows={rows} loading={loading} />}

    </>
  )
}

AttendanceLists.defaultProps = {}

AttendanceLists.propTypes = {

  theme: PropTypes.oneOfType([
    PropTypes.shape().isRequired,
    PropTypes.oneOf([null]).isRequired,
  ]).isRequired,
}

export default AttendanceLists
