/* eslint-disable import/no-duplicates */
/* eslint-disable no-nested-ternary */
import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { favorites } from 'redux/modules'
import heartIcon from 'assets/images/heart-outline@1x.svg'
import heartIconRed from 'assets/images/heart-outline-red@1x.svg'
import bookmarkIcon from 'assets/images/bookmark@1x.svg'
import bookmarkIconActive from 'assets/images/bookmark-active@1x.svg'
import PROP_TYPES from 'constants/propTypes'

const ENTITY_TO_MODULE_MAPPER = {
  articles: isActive =>
    isActive
      ? favorites.deleteArticleFromFavorites
      : favorites.addArticleToFavorites,
  drills: isActive =>
    isActive
      ? favorites.deleteDrillFromFavorites
      : favorites.addDrillToFavorites,
  trainingPackages: isActive =>
    isActive
      ? favorites.deleteTrainingPackageFromFavorites
      : favorites.addTrainingPackageToFavorites,
  videos: isActive =>
    isActive
      ? favorites.deleteVideoFromFavorites
      : favorites.addVideoToFavorites,
  sequences: isActive =>
    isActive
      ? favorites.deleteSequenceFromFavorites
      : favorites.addSequenceToFavorites,
}

const Container = styled.div`
  width: 18px;
  height: 18px;

  cursor: pointer;
  background: ${props => `url(${props.icon}) no-repeat center`};
  background-size: contain;

  ${props =>
    props.isPositionRelative &&
    'position: relative; float: left; margin-right: 10px'};
`

const HeartIcon = ({ isActive, isPositionRelative, action, isLike }) => (
  <Container
    onClick={e => {
      e.preventDefault()
      e.stopPropagation()

      action()
    }}
    icon={
      isActive
        ? isLike
          ? bookmarkIconActive
          : heartIcon
        : isLike
        ? bookmarkIcon
        : heartIconRed
    }
    isPositionRelative={isPositionRelative}
  />
)

HeartIcon.defaultProps = {
  isActive: false,
  isPositionRelative: false,
  isLike: false,
}

HeartIcon.propTypes = {
  isActive: PROP_TYPES.bool,
  entity: PROP_TYPES.string.isRequired, // eslint-disable-line
  entityId: PROP_TYPES.id.isRequired, // eslint-disable-line
  isPositionRelative: PROP_TYPES.bool,
  isLike: PROP_TYPES.bool,
  action: PROP_TYPES.func.isRequired,
}

export default connect(
  null,
  (dispatch, { isActive, entity, entityId }) => ({
    action: () => dispatch(ENTITY_TO_MODULE_MAPPER[entity](isActive)(entityId)),
  })
)(HeartIcon)
