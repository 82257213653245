import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Tooltip
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
// import binIcon from 'assets/images/16px/bin@1x.svg'
import editIcon from 'assets/images/16px/edit@1x.svg'
import { compose } from 'redux'
import { withRouter, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { SubLink } from 'containers'
import TeaserHeader from 'components/Teaser/TeaserHeader'
import AddTaskGroupDialog from 'containers/TaskManagement/AddTaskGroupDialog'
import { formatCostumDateMonthNoForUI } from 'utils/formatDate'
import { Translate } from 'react-localize-redux'
import hasSubscription from 'utils/hasSubscription'
import { Collapsible } from 'components'
import { taskGroups as tasksModule } from '../../../redux/modules'
import TasksTable from './TasksTable'
import { TEAM_PLAYER_DASHBOARD_ROUTE } from 'constants/routes'
import PROP_TYPES from 'constants/propTypes'
import { TASK_SERIES_DUE_DATES } from "../../../constants/taskGroups";

const TaskGroupContainer = ({ taskGroup, team, fetchTasks, dateFormat }) => {
  const [assignees, setAssignees] = useState([])
  const [open, setOpen] = useState(false)
  const [taskGroupState, setTaskGroupState] = useState(taskGroup)

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const dataValue = useLocation();


  useEffect(() => {
    if (taskGroup) {
      const tempAssignees = team?.players?.filter((player) =>
        taskGroup?.model_ids?.includes(`${player.id}`)
      )
      fetchTasks(taskGroup.id).then((res) => {
        const { data } = res.response
        setTaskGroupState({
          ...taskGroup,
          events: taskGroup?.events?.map((event) => {

            let tasks = data
            if (taskGroup.event_type != null){
              tasks = data.filter((d) => d.event_id === event.id)
            }
            else if(taskGroup.event_type == null && taskGroup.start_date) {
              tasks = data.filter(p=>p.due_date === event.due_date)
            }

            return {
              ...event,
              tasks
            }
          }),
        })
        setAssignees(
          tempAssignees.map((player) => {
            return {
              ...player,
              tasks: data.find((d) => d.model_id === player.id),
            }
          })
        )
      })
    }
  }, [taskGroup.model_ids])

  return (
    <>
    <Grid item xs={12} md={12} lg={12}>
      {hasSubscription() !== 'elite' ? (
    <TeaserHeader/>): ''}
    </Grid>
   
    <Grid container spacing={2}>
      {open && (
        <AddTaskGroupDialog
          open={open}
          onClose={handleClose}
          selectedTaskGroup={taskGroupState}
        />
      )}
      <Grid item xs={12} sm={8}>
        <Card>
          <CardHeader
            title={
              <Typography variant="h4" component="h4">
                <Translate id="task-management.feedbacks-title" />
              </Typography>
            }
          />
          <CardContent>
            {!taskGroupState?.event_type ? (
              taskGroupState?.events.map((event) => (
                <Collapsible
                  title={
                    <>
                      <Translate id="task-management.task-date" />
                      {` ${formatCostumDateMonthNoForUI(
                        event.created_at,
                        dateFormat
                      )}`}
                    </>
                  }
                  defaultIsActive={`#${event.id}` === dataValue.hash}
                  key={event.id}
                  eventId={event.id}
                >
                  <TasksTable
                    taskGroup={taskGroupState}
                    event={event}
                    dateFormat={dateFormat}
                  />
                </Collapsible>
              ))

            ) : (
              taskGroupState?.events.map((event) => (
                <Collapsible
                  title={
                    event.event_type === 'training' ? (
                      <>
                        <Translate id="menu.training" />{' '}
                        <Translate id="task-management.from-small" />
                        {` ${formatCostumDateMonthNoForUI(
                          event.date,
                          dateFormat
                        )}. `}
                        <Translate id="task-management.task-due-date" />
                        {` ${formatCostumDateMonthNoForUI(
                          event.due_date,
                          dateFormat
                        )}`}
                      </>
                    ) : (
                      <>
                        <Translate id="menu.match-day" />
                        {` vs ${event.opponent} `}
                        <Translate id="task-management.from-small" />
                        {`  ${formatCostumDateMonthNoForUI(
                          event.date,
                          dateFormat
                        )}. `}
                        <Translate id="task-management.task-due-date" />
                        {` ${formatCostumDateMonthNoForUI(
                          event.due_date,
                          dateFormat
                        )}`}
                      </>
                    )
                  }
                  defaultIsActive={`#${event.id}` === dataValue.hash}
                  key={event.id}
                  eventId={event.id}
                >
                  <TasksTable
                    taskGroup={taskGroupState}
                    event={event}
                    dateFormat={dateFormat}
                  />
                </Collapsible>
              ))
            )}
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Card>
          <CardHeader
            title={
              <Typography variant="h4">
                <Translate id="task-management.task-details" />
              </Typography>
            }
            action={
              !taskGroup.completed && (<Tooltip title="Edit" placement="top" arrow>
                <IconButton onClick={handleOpen}>
                  <img src={editIcon} alt={editIcon} />
                </IconButton>
              </Tooltip>)
            }
          />
          <CardContent>
            <TableContainer>
              <Table aria-label="tasks table">
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Translate id="articles.title" />
                    </TableCell>
                    <TableCell align="right">{taskGroup.title}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Translate id="task-management.assignees" />
                    </TableCell>
                    <TableCell align="right">
                      {assignees.map((pl) => (
                        <SubLink
                          to={TEAM_PLAYER_DASHBOARD_ROUTE.populateURLParams({
                            playerId: pl.id,
                          })}
                          key={pl.first_name}
                        >
                          @{pl.first_name} &nbsp;
                        </SubLink>
                      ))}
                    </TableCell>
                  </TableRow>
                  {!taskGroup.event_type && (
                    <TableRow>
                      <TableCell>
                        <Translate id="task-management.due-date" />
                      </TableCell>
                      <TableCell align="right">
                        {taskGroup.start_date && taskGroup.end_date ? TASK_SERIES_DUE_DATES.find(p=>p.value === taskGroup.due_date_offset.toString())?.title : formatCostumDateMonthNoForUI(
                          taskGroup?.events[0]?.due_date,
                          dateFormat
                        )}
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell>
                      <Translate id="drills.description" />
                    </TableCell>
                    <TableCell align="right">{taskGroup.description}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
        {hasSubscription() === 'elite' && taskGroup.questions.length > 0 && (
          <Card style={{marginTop: '0.20rem'}}>
            <CardHeader
              title={
                <Typography variant="h4">
                  <Translate id="task-management.questions-title" />
                </Typography>
              }
            />
            <CardContent>
              {taskGroup.questions.map((element) => (
                <Typography key={element.id} variant="h4" className="mb-3">
                  {element.question}
                </Typography>
              ))}
            </CardContent>
          </Card>
        )}
      </Grid>
    </Grid>
    </>
  )
}
TaskGroupContainer.propTypes = {
  team: PROP_TYPES.shape().isRequired,
  fetchTasks: PROP_TYPES.func.isRequired,
  taskGroup: PROP_TYPES.shape().isRequired,
  dateFormat: PROP_TYPES.string.isRequired,
}
export default compose(
  withRouter,
  connect(
    ({ taskGroups, teams, auth }) => ({
      taskGroup: taskGroups.current,
      team: teams.current,
      dateFormat: auth.user.datetime_format,
    }),
    {
      fetchTasks: tasksModule.fetchTasks,
    }
  )
)(TaskGroupContainer)
