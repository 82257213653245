import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withLocalize, Translate } from 'react-localize-redux'
import { ArticleCreateEditForm } from 'containers'
import { articles } from 'redux/modules'
import { createSubUrl } from 'utils/urlConstructor'
import getTargetTranslationFields from 'utils/getTargetTranslationFields'
import { ARTICLES_DASHBOARD_ROUTE } from 'constants/routes'
import PROP_TYPES from 'constants/propTypes'

const FIELDS_WITH_TRANSLATION = ['title', 'body']

const ArticleEditContainer = ({
  history,
  match,
  location: { fromUrl, fromPathname = 'Articles' },
  article: { translations, image_url: image, ...article },
  articlesCategories,
  updateArticle,
  activeLanguage: { code: activeLocale },
}) => {
  const linkBack = fromUrl || createSubUrl(match, ARTICLES_DASHBOARD_ROUTE)

  const goBack = () => history.push(linkBack)

  const onSubmit = values =>
    updateArticle(match.params.articleId, values).then(goBack)

  return (
    <ArticleCreateEditForm
      form="articleEdit"
      caption={<Translate id="articles.edit-article"/>}
      initialValues={{
        ...article,
        image,
        ...getTargetTranslationFields(
          translations,
          activeLocale,
          FIELDS_WITH_TRANSLATION
        ),
        locale: activeLocale,
      }}
      fromPath={{
        value: linkBack,
        title: fromPathname,
      }}
      onSubmit={onSubmit}
      articlesCategories={articlesCategories}
      isEditForm
    />
  )
}

ArticleEditContainer.defaultProps = {
  articlesCategories: [],
}

ArticleEditContainer.propTypes = {
  history: PROP_TYPES.history.isRequired,
  match: PROP_TYPES.match.isRequired,
  location: PROP_TYPES.location.isRequired,
  article: PROP_TYPES.shape().isRequired,
  articlesCategories: PROP_TYPES.arrayOfObjects,
  updateArticle: PROP_TYPES.func.isRequired,
  activeLanguage: PROP_TYPES.shape().isRequired,
}

export default compose(
  connect(
    ({
      articles: {
        current: { articlesCategories, ...article },
      },
    }) => ({ article, articlesCategories }),
    {
      updateArticle: articles.updateArticle,
    }
  ),
  withLocalize
)(ArticleEditContainer)
