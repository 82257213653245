import { useSelector } from 'react-redux'

/**
 * Check current user permission on the specified scope
 *
 * @param  {String}   ability   Ability to check
 * @param  {String}   scope     Scope of ability. Can be: any (default), club or team
 * @return {Boolean}            Current user permission check result
 */
const usePermission = (ability, scope = 'any') => {
  const club = useSelector(state => state.clubs.current)
  const team = useSelector(state => state.teams.current)

  const clubPermissions = useSelector(state => {
    return scope === 'any' || scope === 'club'
      ? state.auth.permissions.clubs.find(item => item.id === club.id)?.permissions ?? []
      : []
  })

  const teamPermissions = useSelector(state => {
    return scope === 'any' || scope === 'team'
      ? state.auth.permissions.teams.find(item => item.id === team.id)?.permissions ?? []
      : []
  })

  const allPermissions = [].concat(clubPermissions, teamPermissions)

  return allPermissions.includes(ability)
}

export default usePermission
