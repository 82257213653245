import { handleActions, combineActions, createAction } from 'redux-actions'
import {
  actionsTypesGenerator,
  onRequest,
  onSuccess,
  onFailure,
} from 'utils/reduxHelpers'
import initialState from '../initialState'

const namespace = 'MATCH'

const FETCH_SEASON_MATCHES = actionsTypesGenerator(
  `FETCH_SEASON_${namespace}ES`
)
const FETCH_MATCH = actionsTypesGenerator(`FETCH_${namespace}`)
const CREATE_MATCH = actionsTypesGenerator(`CREATE_${namespace}`)
const UPDATE_MATCH = actionsTypesGenerator(`UPDATE_${namespace}`)
const SHARE_MATCH = actionsTypesGenerator(`SHARE_${namespace}`)
const DELETE_MATCH = actionsTypesGenerator(`DELETE_${namespace}`)
const DELETE_MATCH_NOTE = actionsTypesGenerator(`DELETE_${namespace}_NOTE`)

const ADD_NEW_MATCH = `ADD_NEW_${namespace}`
const REMOVE_NEW_MATCHES = `REMOVE_NEW_${namespace}S`
const CREATE_MATCH_HIGHLIGHT = actionsTypesGenerator(`CREATE_${namespace}_HIGHLIGHT`)
const UPDATE_MATCH_HIGHLIGHT = actionsTypesGenerator(`UPDATE_${namespace}_HIGHLIGHT`)
const DELETE_MATCH_HIGHLIGHT = actionsTypesGenerator(`DELETE_${namespace}_HIGHLIGHT`)

export const fetchSeasonMatches = seasonId => ({
  types: FETCH_SEASON_MATCHES,
  callAPI: {
    method: 'GET',
    entity: 'Matches',
    path: `/seasons/${seasonId}/matches`,
  },
})

export const fetchMatch = (id, params) => ({
  types: FETCH_MATCH,
  callAPI: {
    method: 'GET',
    entity: 'Match',
    path: `/matches/${id}`,
    params,
  },
})

export const createMatch = (id, data) => ({
  types: CREATE_MATCH,
  callAPI: {
    method: 'POST',
    entity: 'Match',
    path: '/matches',
    data,
  },
  payload: { id },
})

export const createMatchHighlight = (data) => ({
  types: CREATE_MATCH_HIGHLIGHT,
  callAPI: {
    method: 'POST',
    entity: 'Match highlight',
    path: '/matches/create_match_highlight',
    data,
  },
})
export const updateMatchHighlight = (id, data) => ({
  types: UPDATE_MATCH_HIGHLIGHT,
  callAPI: {
    method: 'PUT',
    entity: 'Match highlight',
    path: `/matches/update_match_highlight/${id}`,
    data,
  },
})
export const deleteMatchHighlight = id => ({
  types: DELETE_MATCH_HIGHLIGHT,
  callAPI: {
    method: 'DELETE',
    entity: 'Match highlight',
    path: `/matches/delete_match_highlight/${id}`,
  },
  payload: { id },
})

export const updateMatch = (id, data) => ({
  types: UPDATE_MATCH,
  callAPI: {
    method: 'PUT',
    entity: 'Match',
    path: `/matches/${id}`,
    data,
  },
  payload: { id },
})

export const shareMatch = (id, paramsString, data = {}) => ({
  types: SHARE_MATCH,
  callAPI: {
    method: 'SHARE',
    entity: 'Match',
    path: `/matches/${id}/share`,
    paramsString,
    data,
  },
})

export const deleteMatch = id => ({
  types: DELETE_MATCH,
  callAPI: {
    method: 'DELETE',
    entity: 'Match',
    path: `/matches/${id}`,
  },
  payload: { id },
})

export const deleteMatchNote = (mid, nid) => ({
  types: DELETE_MATCH_NOTE,
  callAPI: {
    method: 'DELETE',
    entity: 'Match Note',
    path: `/matches/${mid}/notes/${nid}`,
  },
  payload: { nid },
})

export const addNewMatch = createAction(ADD_NEW_MATCH)
export const removeNewMatches = createAction(REMOVE_NEW_MATCHES)

export default handleActions(
  {
    [combineActions(
      FETCH_SEASON_MATCHES.request,
      FETCH_MATCH.request,
      CREATE_MATCH.request,
      CREATE_MATCH_HIGHLIGHT.request,
      UPDATE_MATCH_HIGHLIGHT.request,
      DELETE_MATCH_HIGHLIGHT.request,
      UPDATE_MATCH.request,
      DELETE_MATCH.request,
      DELETE_MATCH_NOTE.request,
    )]: onRequest,
    [FETCH_SEASON_MATCHES.success]: (
      state,
      { response: { data: items = [] } }
    ) => onSuccess({ ...state, items }),
    [combineActions(FETCH_MATCH.success)]: (state, { response: { data: current = {} } }) =>
      onSuccess({ ...state, current }),
    [combineActions(CREATE_MATCH.success, UPDATE_MATCH.success)]: (
      state,
      { response: { data = {} }, id }
    ) => {
      const items = state.items.updateById(id, { ...data, isNew: false })

      return onSuccess({
        ...state,
        items,
        current: { ...state.current, ...data },
      })
    },
    [combineActions(CREATE_MATCH_HIGHLIGHT.success, UPDATE_MATCH_HIGHLIGHT.success)]: (
      state,
      { response: { data = {} }, id }
    ) => {
      const items = state.items.updateById(id, { ...data, isNew: false })

      return onSuccess({
        ...state,
        items,
        current: { ...state.current, ...data },
      })
    },
    [DELETE_MATCH.success]: (state, { id }) =>
      onSuccess({ ...state, items: state.items.filterById(id) }),
    [addNewMatch]: (state, { payload }) => {
      const { id, teamId, seasonId, date, status, isNew } = payload

      const newMatch = {
        id,
        team_id: +teamId,
        season_id: +seasonId,
        date,
        status,
        isNew,
      }

      return {
        ...state,
        items: [...state.items, newMatch],
      }
    },
    [removeNewMatches]: state => {
      const items = state.items.filter(({ isNew }) => !isNew)

      return {
        ...state,
        items,
      }
    },
    [combineActions(
      FETCH_SEASON_MATCHES.failure,
      FETCH_MATCH.failure,
      CREATE_MATCH.failure,
      CREATE_MATCH_HIGHLIGHT.failure,
      UPDATE_MATCH_HIGHLIGHT.failure,
      DELETE_MATCH_HIGHLIGHT.failure,
      UPDATE_MATCH.failure,
      DELETE_MATCH.failure,
    )]: onFailure,
  },
  initialState.matches
)
