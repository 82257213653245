import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { teams } from 'redux/modules'
import Loader from './Loader'
import PROP_TYPES from 'constants/propTypes'

const TeamLoader = ({
  additionalParams,
  children,
  teamId,
  seasonId,
  fetchTeam,
  ...restProps
}) => {
  return (
  <Loader
    fetchMethod={params => {
      return fetchTeam(teamId, {
        ...params,
        ...additionalParams,
        season_id: seasonId
      }).then(res=>{
        const team = res.response.data
        if (team) {
          localStorage.setItem('team_id', team.id)
          localStorage.setItem('paid', team.subscription.paid)
        }
      })
    }}
    triggers={[teamId]}
    {...restProps}
  >
    {children}
  </Loader>
  )
}

TeamLoader.defaultProps = {
  additionalParams: {},
}

TeamLoader.propTypes = {
  children: PROP_TYPES.children.isRequired,
  teamId: PROP_TYPES.id.isRequired,
  fetchTeam: PropTypes.func.isRequired,
  additionalParams: PropTypes.shape(),
}

export default connect(
  null,
  { fetchTeam: teams.fetchTeam }
)(TeamLoader)
