import React from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {
  Menu,
  MenuItem,
  FormControl as MuiFormControl,
  IconButton,
  Button,
} from '@material-ui/core'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import PROP_TYPES from 'constants/propTypes'

const useStyles = makeStyles(theme => ({
  AddButton: {
    color: theme.palette.primary.main,
    fontSize: '1.8rem',
  },
  FormControl: {
    marginBottom: 0,
    textAlign: 'right',
  },
  [theme.breakpoints.down('sm')]: {
    FormControl: {
      position:'absolute',
      right: 8,
      bottom: 8
    },
  }
}))

const FormControl = withStyles({
  root: {
    '& .MuiInputLabel-formControl': {
      fontSize: '0.8rem',
    },
  },
})(MuiFormControl)

const AddDrillMenu = ({
  drillId,
  handleMenuClose,
  categoryType,
  shortAddButton,
}) => {
  const classes = useStyles()
  const [selectSessionPart, setSelectSessionPart] = React.useState(null)

  const handleClose = () => {
    setSelectSessionPart(null)
  }

  const openMenu = event => {
    event.stopPropagation()
    setSelectSessionPart(event.currentTarget)
  }

  const selectedMenuItem = event => {
    const { value } = event.currentTarget.dataset

    setSelectSessionPart(value)
    handleMenuClose(event, drillId, value)
    handleClose()
  }

  let addIconButton
  if (shortAddButton === 'true') {
    addIconButton = (
      <>
        <IconButton onClick={openMenu}>
          <AddCircleIcon className={classes.AddButton} />
        </IconButton>
      </>
    )
  } else {
    addIconButton = (
      <>
        <Button
          variant="contained"
          color="primary"
          onClick={openMenu}
          startIcon={<AddCircleIcon />}
        >
          <Translate id="button.add-activity" />
        </Button>
      </>
    )
  }

  return (
    <FormControl variant="outlined" className={classes.FormControl}>
      <div>{addIconButton}</div>
      <Menu
        labelId="0"
        id={`select-session-part-${drillId}`}
        anchorEl={selectSessionPart}
        keepMounted
        open={Boolean(selectSessionPart)}
        onClose={handleClose}
      >
        {categoryType.map(sessionPart => {
          return (
            <MenuItem
              key={sessionPart + drillId}
              data-value={sessionPart}
              onClick={selectedMenuItem}
            >
              {' '}
              <Translate id={`training.${sessionPart}`} />{' '}
            </MenuItem>
          )
        })}
      </Menu>
    </FormControl>
  )
}

AddDrillMenu.defaultProps = {
  drillId: [],
  categoryType: [],
  shortAddButton: [],
}

AddDrillMenu.propTypes = {
  drillId: PROP_TYPES.number,
  handleMenuClose: PropTypes.func.isRequired,
  categoryType: PROP_TYPES.arrayOfObjects,
  shortAddButton: PROP_TYPES.string,
}

export default AddDrillMenu
