import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { auth as authModule } from 'redux/modules'
import MDInput from 'components/material/MDInput'
import {
  Typography,
  Button,
  Grid,
  Box,
  Paper,
  InputAdornment,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Fab,
  Avatar,
} from '@material-ui/core'
import {
  handleTextField,
  checkError,
  filterDataWithoutError,
  checkTextFieldError,
} from 'utils/validate'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { SubLink, InnrHead } from 'containers'
import { getName, setName, setLocale } from 'utils/localstorage'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import { Translate } from 'react-localize-redux'
import {
  // Close as CloseIcon,
  PhotoLibraryOutlined as PhotoLibraryOutlinedIcon,
  DeleteOutlineOutlined as DeleteOutlineOutlinedIcon,
} from '@material-ui/icons'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone'
import PersonIcon from '@material-ui/icons/Person'
import ReactCountryFlag from 'react-country-flag'
import ImageUploading from 'react-images-uploading'

import EditIcon from '@material-ui/icons/Edit'
import AvatarIcon from 'assets/images/avatar.png'
import { currentDateFormat, DATE_FORMAT } from 'utils/formatDate'
import timezonelist from 'constants/timezonelist'

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(0, 2),
  },
  Avatar: {
    height: 200,
    width: 200,
    border: '1px dashed rgba(0, 0, 0, 0.12)',
    backgroundColor: '#F4F6F8',
    padding: theme.spacing(2),
  },
  BoxCard: {
    position: 'relative',
    height: 200,
    width: 200,
    margin: theme.spacing(3, 'auto'),
  },
  uploadImg: {
    position: 'absolute',
    bottom: 15,
    right: 15,
    margin: theme.spacing(0, 'auto'),
  },
  newImgUpload: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

const UserDashboard = ({ users, match, updateUser }) => {
  const {
    params: { teamId, seasonId },
  } = match

  const [userData, setUserData] = React.useState({
    id: null,
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
    language: '',
    avatar: '',
    time_zone:'',
    datetime_format:''
  })

  const classes = useStyles()

  const [images, setImages] = React.useState([])
  const maxNumber = 1
  const maxMbFileSize = 5 * 1024 * 1024 // 5Mb
  // eslint-disable-next-line
  const [Snackbar, changeSnackbar] = React.useState({
    show: false,
    message: '',
    error: false,
    info: false,
    warning: false,
  })
  const submit = () => {
    const isError = checkError(userData)
    const isErrorForFields = checkTextFieldError(userData, [
      'first_name',
      'last_name',
    ])

    if (isError) {
      setUserData({ ...userData, [isError]: true })

      return
    }

    if (isErrorForFields) {
      setUserData({ ...userData, [isErrorForFields]: true })

      return
    }
    userData.avatar = images.length > 0 ? images[0].file : null
    // eslint-disable-next-line
    updateUser(filterDataWithoutError(userData)).then(res => {
      changeSnackbar({
        show: true,
        message: <Translate id="player.player-update" />,
      })

      setName(userData.first_name)
    })
  }

  const onChange = imageList => setImages(imageList)

  React.useEffect(() => {
    setUserData(users)
  }, [users])

  const handleLanguageChange = event => {
    setLocale(event.target.value)
    setUserData({...userData,language:event.target.value})
  }
  const handleTZChange = event => {
    setUserData({...userData,'time_zone':event.target.value})
  }
  const handleDateFormatChange = event => {
    setUserData({...userData,'datetime_format':event.target.value})
    currentDateFormat()
  }

  return (
    <>
      <InnrHead title={<Translate id="home.hello" />} label={getName(true)}>
        <Button
          component={SubLink}
          to={`/team/${+teamId}/season/${+seasonId}`}
          isFullPath
          className={classes.button}
          variant="outlined"
          color="primary"
        >
          <Translate id="button.cancel" />
        </Button>
        <Button variant="contained" color="primary" onClick={submit}>
          {' '}
          <Translate id="button.save" />
        </Button>
      </InnrHead>
      <Paper component={Box} p={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <Box className={classes.BoxCard}>
              {images.length === 0 && (
                <Avatar
                  className={classes.Avatar}
                  alt={userData.first_name}
                  src={userData.avatar ? userData.avatar.full_url : AvatarIcon}
                />
              )}
              <ImageUploading
                value={images}
                onChange={onChange}
                maxNumber={maxNumber}
                // multiple
                maxFileSize={maxMbFileSize}
                acceptType={['jpg', 'gif', 'png']}
                // onError={onError}
                dataURLKey="data_url"
              >
                {({
                  imageList,
                  onImageUpload,
                  onImageUpdate,
                  onImageRemove,
                }) => (
                  // write your building UI
                  <>
                    {imageList.length === 0 && (
                      <Fab
                        size="small"
                        onClick={onImageUpload}
                        className={classes.uploadImg}
                        color="primary"
                      >
                        <EditIcon size="small" />
                      </Fab>
                    )}

                    {imageList.map((image, index) => (
                      // eslint-disable-next-line
                      <Box key={index}>
                        <Avatar
                          className={classes.Avatar}
                          src={image.data_url}
                        />
                        <Box className={classes.newImgUpload}>
                          <Fab
                            size="small"
                            color="default"
                            onClick={() => onImageUpdate(index)}
                            className={classes.button}
                          >
                            <PhotoLibraryOutlinedIcon size="small" />
                          </Fab>

                          <Fab
                            size="small"
                            color="primary"
                            onClick={() => onImageRemove(index)}
                            className={classes.button}
                          >
                            <DeleteOutlineOutlinedIcon size="small" />
                          </Fab>
                        </Box>
                      </Box>
                    ))}
                  </>
                )}
              </ImageUploading>
            </Box>
          </Grid>{' '}
          <Grid item xs={12} md={4}>
            <Typography variant="h4" component={Box} pb={2}>
              <Translate id="player.general-info" />
            </Typography>

            <MDInput
              fullWidth
              required
              id="first_name"
              label={<Translate id="signup.first_name" />}
              type="text"
              name="first_name"
              variant="outlined"
              value={userData.first_name}
              onChange={e => handleTextField(e, userData, setUserData)}
              error={userData.first_name_error}
              helperText={
                userData.first_name_error ? 'First name required' : ''
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <PersonIcon />
                  </InputAdornment>
                ),
              }}
            />

            <MDInput
              fullWidth
              required
              id="last_name"
              label={<Translate id="signup.last_name" />}
              type="text"
              name="last_name"
              variant="outlined"
              value={userData.last_name}
              error={userData.last_name_error}
              onChange={e => handleTextField(e, userData, setUserData)}
              helperText={userData.last_name_error ? 'Last name required' : ''}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <PersonIcon />
                  </InputAdornment>
                ),
              }}
            />

            <MDInput
              fullWidth
              required
              id="email"
              label={<Translate id="player.email" />}
              type="email"
              name="email"
              variant="outlined"
              value={userData.email}
              onChange={e => handleTextField(e, userData, setUserData)}
              disabled
              error={userData.email_error}
              helperText={userData.email_error ? 'Email is required' : ''}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <MailOutlineIcon />
                  </InputAdornment>
                ),
              }}
            />

            <MDInput
              fullWidth
              id="phone"
              label={<Translate id="player.phone" />}
              type="tel"
              name="phone"
              variant="outlined"
              value={userData.phone}
              onChange={e => handleTextField(e, userData, setUserData)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <PhoneIphoneIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h4" component={Box} pb={2}>
              <Translate id="player.settings" />
            </Typography>

            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">
                <Translate id="player.language" />
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={userData.language}
                onChange={handleLanguageChange}
                label="Language"
                name="language"
              >
                <MenuItem value="en">
                  <ReactCountryFlag countryCode="US" svg /> &nbsp; English
                </MenuItem>
                <MenuItem value="de">
                  <ReactCountryFlag countryCode="DE" svg /> &nbsp; German
                </MenuItem>
                <MenuItem value="es">
                  <ReactCountryFlag countryCode="ES" svg /> &nbsp; Spanish
                </MenuItem>
                <MenuItem value="it">
                  <ReactCountryFlag countryCode="IT" svg /> &nbsp; Italian
                </MenuItem>
                <MenuItem value="pl">
                  <ReactCountryFlag countryCode="PL" svg /> &nbsp; Polish
                </MenuItem>
                <MenuItem value="jp">
                  <ReactCountryFlag countryCode="JP" svg /> &nbsp; Japan
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="time_zone">
                <Translate id="player.timezone" />
              </InputLabel>
              <Select
                labelId="time_zone"
                id="time_zone"
                value={userData.time_zone}
                onChange={handleTZChange}
                label="Timezone"
                name="time_zone"
              >
                {timezonelist.map(zone=>( <MenuItem value={zone} key={zone}>
                  {zone}
                </MenuItem>))}
              </Select>
            </FormControl>

            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="dateformat">
                <Translate id="player.dateformat" />
              </InputLabel>
              <Select
                labelId="dateformat"
                id="dateformat"
                value={userData.datetime_format}
                onChange={handleDateFormatChange}
                label="Date Format"
                name="datetime_format"
              >
                {DATE_FORMAT.map(format=>( <MenuItem value={format} key={format}>
                  {format}
                </MenuItem>))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}

UserDashboard.propTypes = {
  users: PropTypes.shape().isRequired,
  match: PropTypes.shape().isRequired,
  updateUser: PropTypes.func.isRequired,
}

export default compose(
  withRouter,
  connect(
    ({ auth }) => ({
      users:auth.user,
    }),
    {
      updateUser: authModule.updateUser,
    }
  )
)(UserDashboard)
