import React, { useState, useMemo, useContext } from 'react'
import PropTypes from 'prop-types'
import { Draggable } from 'react-beautiful-dnd'
import { Translate } from 'react-localize-redux'
import { DrillModal, DroppableArea } from 'components'
import { drills as drillsModule } from 'redux/modules'
import { connect } from 'react-redux'
import { Typography, Box, IconButton, TextField} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'
import InfoIcon from '@material-ui/icons/Info'
import RemoveIcon from '@material-ui/icons/Remove'
import { Context } from '../../../TrainingStateContext'
import { DrillsList, DrillsListItem } from '../styledComponents'
import { SessionCategoryStyled, SessionCategoryBox } from './sessionStyled'

/* eslint-disable react/no-array-index-key */

const SessionCategory = ({ relation, items, removeItem, fetchDrill, calculateDuration}) => {
  const { isDragging } = useContext(Context)
  let empty
  if (!items.length) {
    empty = true
  } else {
    empty = false
  }
  const [openedDrillId, changeOpenedDrillId] = useState(null)

  const openedDrill = useMemo(() => items.findById(openedDrillId), [
    openedDrillId,
    items,
  ])

  const closeOpenedDrill = () => changeOpenedDrillId(null)

  const increaseDuration = (index) => {
    items[index].customized_duration += 1 // eslint-disable-line
    calculateDuration()
  }

  const decreaseDuration = (index) => {
    items[index].customized_duration -= 1 // eslint-disable-line
    calculateDuration()
  }

  return (
    <>
      {openedDrillId && (
        <DrillModal
          drill={openedDrill}
          onHide={closeOpenedDrill}
          open={Boolean(openedDrillId)}
        />
      )}
      <DroppableArea
        relation={relation}
        isPlaceholderShown
        isDragging={isDragging.dragging}
        isListEmpty={empty}
      >
        {!items.length ? (
          <>
            <span>
              <Translate id="training.drag-drills" />
            </span>
          </>
        ) : (
          <div />
        )}
       
        <DrillsList>
          {items.map(({ id, title, duration, customized_duration:customizedDuration}, index) => (
              <DrillsListItem key={`${id}-${index}`}>
                <Draggable
                  draggableId={`${id}$${relation}-${index}`}
                  index={index}
                >
                  {provided => (
                    <SessionCategoryBox
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <Box>
                      <Typography
                        variant="caption"
                        color="textPrimary"
                        component="a"
                        align="left"
                      
                        style={{ flexGrow: '1', width:180, cursor:'pointer'}}
                        onClick={() => changeOpenedDrillId(id)}
                      >
                    
                        <InfoIcon/>&nbsp;{title}
                      </Typography>
                      <SessionCategoryStyled>
                        <IconButton variant="contained"  size="small" color="secondary" onClick={() => decreaseDuration(index)}>
                          <RemoveIcon fontSize="small" />
                        </IconButton>
                        <Box className="mx-1">
                        <TextField value={`${customizedDuration}'`} defaultValue={`${duration}'`}/>
                        </Box>
                        <IconButton variant="contained" size="small" color="primary" onClick={() => increaseDuration(index)}>
                          <AddIcon fontSize="small" />
                        </IconButton>
                       
                      </SessionCategoryStyled>
                      </Box>
                    
                     <Box>
                     <DeleteIcon
                          fontSize="small"
                          style={{cursor: 'pointer'}}
                          onClick={() => {
                            fetchDrill(id, {
                              removed: true,
                              track: 'training_session',
                            })
                            removeItem(id)
                          }}
                        />
                     </Box>
                    </SessionCategoryBox>
                  )}
                </Draggable>
              </DrillsListItem>
          ))}
        </DrillsList>
      </DroppableArea>
    </>
  )
}

SessionCategory.defaultProps = {
  items: [],
  calculateDuration:null
}

SessionCategory.propTypes = {
  relation: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.object.isRequired),
  removeItem: PropTypes.func.isRequired,
  fetchDrill: PropTypes.func.isRequired,
  calculateDuration:PropTypes.func
}

export default connect(
  null,
  {
    fetchDrill: drillsModule.fetchDrill,
  }
)(SessionCategory)
