import React from 'react'
import styled from 'styled-components'
import { PlayIcon, ProcessingIcon, BorderedGreenButton } from 'components'
import PROP_TYPES from 'constants/propTypes'
import SEQUENCE_TAGS from 'constants/sequenceTags'

const Video = styled.div`
  height: 65px;
  border-radius: 12px;
  margin: 0 5px 2px;

  border: var(--grey-solid) 1px;
  background: url(${({ image }) => image}) no-repeat center;
  background-size: cover;

  ${({ theme }) => theme.flexCenter};
`

const Tag = styled(BorderedGreenButton)`
  height: 18px;
  font-size: 70%;
`

const VideoContainer = styled.div`
  margin-bottom: 15px;
`

const Sequences = ({ sequences, changeSelectedSequenceId }) => (
  <>
    {sequences
      .filterById('new')
      .map(
        ({
          id,
          thumbnail_url: thumbnail,
          processed,
          error_details: errorDetails,
          tag,
        }) => {
          const tagData =
            SEQUENCE_TAGS.find(({ value }) => value === tag) ||
            SEQUENCE_TAGS.find(({ value }) => value === 'others')

          return (
            <VideoContainer key={id}>
              <Video image={processed === 1 && thumbnail}>
                {processed === 1 ? (
                  <PlayIcon
                    color="primary"
                    onClick={() => changeSelectedSequenceId(id)}
                  />
                ) : (
                  <ProcessingIcon
                    placeholder={processed === -1 && errorDetails}
                  />
                )}
              </Video>
              <Tag background={tagData.background} color={tagData.color}>
                {tagData.title}
              </Tag>
            </VideoContainer>
          )
        }
      )}
  </>
)

Sequences.defaultProps = {
  sequences: [],
}

Sequences.propTypes = {
  sequences: PROP_TYPES.arrayOfObjects,
  changeSelectedSequenceId: PROP_TYPES.func.isRequired,
}

export default Sequences
