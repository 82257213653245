import React from 'react'
import {
  TrainingLoader,
  TrainingPackagesLoader,
  FavoritesLoader,
} from 'loaders'
import TrainingStateContext from './TrainingStateContext'
import TrainingDashboardContainer from './TrainingDashboardContainer'
import PROP_TYPES from 'constants/propTypes'

const TrainingDashboard = ({
  match: {
    params: { trainingId },
  },
}) => (
  <TrainingLoader
    trainingId={trainingId}
    additionalFields={[
      'players',
      'session_drills',
      'drill_categories',
      'trainings',
    ]}
    alwaysRenderChildren
    passIsLoading
  >
    <TrainingPackagesLoader
      params={{ filter_by_age_class: 1, order_by: 'random' }}
      alwaysRenderChildren
      passIsLoading
    >
      <FavoritesLoader
        entity="trainingPackages"
        alwaysRenderChildren
        passIsLoading
      >
        <FavoritesLoader entity="drills">
          <TrainingStateContext>
            <TrainingDashboardContainer trainingId={trainingId} />
          </TrainingStateContext>
        </FavoritesLoader>
      </FavoritesLoader>
    </TrainingPackagesLoader>
  </TrainingLoader>
)

TrainingDashboard.propTypes = {
  match: PROP_TYPES.match.isRequired,
}

export default TrainingDashboard
