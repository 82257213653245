import React from 'react'
import { Translate } from 'react-localize-redux'
import { withTheme } from 'styled-components/macro'
import { styled, makeStyles } from '@material-ui/core/styles'
import { Line as LineChart } from 'react-chartjs-2'
import { rgba } from 'polished'
import {
  Paper,
  Divider,
  Toolbar,
  Tab,
  Tabs,
  Grid,
  Box,
  ButtonGroup as MuiButtonGroup,
  Button,
} from '@material-ui/core'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import BallIcon from 'assets/images/ballicon.svg'
import PROP_TYPES from 'constants/propTypes'

const useStyles = makeStyles(theme => ({
  toolbar: {
    minHeight: 56,
    '& .MuiTab-root': {
      minHeight: 56,
      minWidth: 200,
    },
  },
  button: {
    margin: theme.spacing(0, 1.6),
  },
  title: {
    flex: '1 1 0',
    [theme.breakpoints.down('md')]: { width: '30%' },
  },
}))

const StyledChart = styled(Box)(({ theme }) => ({
  height: 258,
  '& canvas': {
    height: '100% !important',
    width: '100% !important',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}))

const NoData = styled(Box)(({ theme }) => ({
  minHeight: 258,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  div: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: '#d0d0d0',
    p: {
      margin: theme.spacing(0),
      marginTop: theme.spacing(1.2),
    },
  },
}))

const LegendStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: 200,
  padding: theme.spacing(0.5),
  margin: theme.spacing(2, 'auto'),
  [theme.breakpoints.down('sm')]: {},
  '& .legend-info': {
    display: 'flex',
    textAlign: 'center',
    lineHeight: '32px',
    alignItems: 'center',
    margin: theme.spacing(0.5, 0),
    fontWeight: 600,
  },
  '& .legend-number': {
    minWidth: 30,
    margin: theme.spacing(0),
    textAlign: 'right',
  },
  '& .legend-soccer': {
    minWidth: 30,
    width: '100%',
    margin: theme.spacing(0),
    textAlign: 'left',
  },
  span: {
    margin: theme.spacing(0),
  },
  '& .legend-green': {
    background: rgba(theme.Dataset.color_1, 1),
    borderRadius: theme.shape,
    height: 35,
    width: 35,
    margin: theme.spacing(0, 1.2),
  },
  '& .legend-red': {
    background: rgba(theme.Dataset.color_2, 1),
    borderRadius: theme.shape,
    height: 35,
    width: 35,
    margin: theme.spacing(0, 1.2),
  },
  '& .legend-yellow': {
    background: rgba(theme.Dataset.color_3, 1),
    borderRadius: theme.shape,
    height: 35,
    width: 35,
    margin: theme.spacing(0, 1.2),
  },
}))

const ButtonGroup = styled(MuiButtonGroup)(({ theme }) => ({
  margin: theme.spacing(0.9, 2),
  '& .MuiSvgIcon-root': {
    fontSize: theme.typography.fontSize,
  },
  [theme.breakpoints.down('sm')]: {},
}))
const PerformanceWidget = ({ title, data, match, isTeam, theme }) => {
  const firstDatasetColor = rgba(theme.Dataset.color_1, 0.3)
  const firstDatasetBorderColor = rgba(theme.Dataset.color_1, 1)

  const chartData = {
    labels: [],
   
    datasets: [
      {
        label: 'Rating',
        backgroundColor: firstDatasetColor,
        borderColor: firstDatasetBorderColor,
        pointBorderColor: firstDatasetBorderColor,
        pointBackgrounColor: firstDatasetColor,
        pointRadius: 3,
        borderWidth: 3,
        data: [],
      }
    ],
  }

  const options = {
    // spanGaps: true,
    maintainAspectRatio: false,
    tooltips: {
      mode: 'label',
    },
    plugins:{
      legend: {
       display: false
      }
     },
    hover: {
      mode: 'dataset',
    },
    scaleStepWidth :1,
    scales: {
      x:{
      },
      y: {
        max: 5,
            min: 0,
            ticks: {
                stepSize: 1
            }
      }
    },
  }
  // chart end
  const [value, setValue] = React.useState(0)
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  // get data for chart
  // eslint-disable-next-line
  const { matchesStats } = match
  const playerStat = match.matches_performance

  function compare(a, b) {
    const numberPattern = /\d+/g
    const aDate = a.date.match(numberPattern).join('')
    const bDate = b.date.match(numberPattern).join('')
    if (aDate > bDate) return 1
    if (bDate > aDate) return -1

    return 0
  }

  const formatedData = [...data].sort(compare)

  const lastPage = Math.ceil(formatedData.length / 10)
  const dataModular = formatedData.length % 10
  const page = lastPage - 1
  let temp = (page - 1) * 10 + (dataModular === 0 ? 10 : dataModular)
  if (temp < 0) {
    temp = 0
  }
  let lastIndex = temp + 10
  if (lastIndex > formatedData.length) {
    lastIndex = formatedData.length
    temp = 0
  }

  const getMatchPerfStartData = formatedData.slice(temp, lastIndex)
  const [matchPerformance, setMatchPagination] = React.useState({
    data: getMatchPerfStartData,
    page,
  })

  const getMatchPerformancData = directionPage => {
    let currentPage = matchPerformance.page
    if (
      (directionPage === 'prev' && currentPage === 0) ||
      (directionPage === 'next' &&
        formatedData.length - (currentPage + 1) * 10 <= 0)
    )
      return

    switch (directionPage) {
      case 'next':
        currentPage += 1
        break
      case 'prev':
        currentPage -= 1
        break
      default:
    }

    temp = (currentPage - 1) * 10 + (dataModular === 0 ? 10 : dataModular)
    if (temp < 0) {
      temp = 0
    }
    lastIndex = temp + 10
    if (lastIndex > formatedData.length) {
      lastIndex = formatedData.length
      temp = 0
    }
    const currentData = formatedData.slice(temp, lastIndex)

    setMatchPagination({
      data: currentData,
      page: currentPage,
    })
  }

  const chartRating = []
  const chartDate = []
  let formatedDate = ''
  let datef = ''
  // this one is for team container
  if (matchPerformance.data.length > 0) {
    matchPerformance.data.forEach(item => {
      // formatting date
      if (item) {
        formatedDate = item.date.split('-')
        datef = formatedDate[2].concat('.', formatedDate[1])
        chartDate.push(datef)
        if (item.rating == null) {
          // eslint-disable-next-line
          item.rating = 0
        }
        chartRating.push(item.rating)
      }
    })
  } else if (playerStat) {
    playerStat.forEach(item => {
      // formatting date
      if (item) {
        formatedDate = item.date.split('-')
        datef = formatedDate[2].concat('.', formatedDate[1])
        chartDate.push(datef)
        if (item.rating == null) {
          // eslint-disable-next-line
          item.rating = 0
        }
        chartRating.push(item.rating)
      }
    })
  }
  // adding values to chart
  chartData.labels = chartDate
  chartData.datasets[0].data = chartRating || []

  React.useEffect(() => {}, [])
  const classes = useStyles()

  return (
    <Paper component={Box}>
      <Toolbar disableGutters="false" className={classes.toolbar}>
        <Box className={classes.title}>
          <Tabs
            value={value}
            indicatorColor="primary"
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label={title} />
          </Tabs>
        </Box>
        <ButtonGroup size="small">
          <Button size="small" onClick={() => getMatchPerformancData('prev')}>
            <ArrowBackIosIcon />
          </Button>
          <Button size="small" onClick={() => getMatchPerformancData('next')}>
            <ArrowForwardIosIcon />
          </Button>
        </ButtonGroup>
      </Toolbar>
      <Divider />
      <Box mx={2} mt={2} pb={2}>
        {value === 0 && (
          <>
            {matchPerformance.data.length > 0 ? (
              <Grid container>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                {/* <Grid item lg={10} md={11} sm={12} xs={12}> */}
                  <StyledChart>
                    <LineChart data={chartData} options={options} />
                  </StyledChart>
                </Grid>
                {/* <Grid item lg={2} sm={12} xs={12}>
                  {matchPerformance.data.length > 0 && isTeam && (
                    <LegendStyled
                      border={1}
                      borderColor="grey.500"
                      borderRadius={8}
                    >
                      <div className="legend-info">
                        <p className="legend-number">
                          {isTeam && matchesStats.wins
                            ? matchesStats.wins
                            : '0'}
                        </p>
                        <span className="legend-green" />
                        <p className="legend-soccer">
                          <Translate id="home.match-win" />
                        </p>
                      </div>
                      <div className="legend-info">
                        <p className="legend-number">
                          {matchesStats.losses ? matchesStats.losses : '0'}
                        </p>
                        <span className="legend-red" />
                        <p className="legend-soccer">
                          <Translate id="home.match-lost" />
                        </p>
                      </div>
                      <div className="legend-info">
                        <p className="legend-number">
                          {matchesStats.draws ? matchesStats.draws : '0'}
                        </p>
                        <span className="legend-yellow" />
                        <p className="legend-soccer">
                          <Translate id="home.match-draw" />
                        </p>
                      </div>
                    </LegendStyled>
                  )}
                </Grid> */}
              </Grid>
            ) : (
              <NoData>
                <div>
                  <img alt="icon" src={BallIcon} />
                  <p>
                    <Translate id="home.empty-performance" />
                  </p>
                </div>
              </NoData>
            )}
          </>
        )}
      </Box>
    </Paper>
  )
}

PerformanceWidget.defaultProps = {
  data: [],
  isTeam: false,
}

PerformanceWidget.propTypes = {
  theme: PROP_TYPES.string.isRequired,
  title: PROP_TYPES.string.isRequired,
  data: PROP_TYPES.arrayOfObjects,
  isTeam: PROP_TYPES.bool,
  match: PROP_TYPES.shape().isRequired,
}

export default withTheme(PerformanceWidget)
