import styled from 'styled-components'

export default styled.div`
  padding: 0 15px;

  background: var(--dark-green);
  border-radius: 15px;
  color: white;

  text-align: center;
  cursor: pointer;
  max-height: 25px;
  margin: 0 5px 10px 5px;
  font-size: 16px;

  &:hover {
    background: var(--primary-green);
  }
`
