import React from 'react'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import { Grid, Typography, Box, Breadcrumbs } from '@material-ui/core'
import { Collapsible, ImagesSlider } from 'components'
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline'
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined'
import AccessTimeIcon from '@material-ui/icons/AccessTime'

const Drill = ({ drills, category, categoryIndex, classes }) => (
  <>
    {drills.map(
      (
        {
          id,
          title,
          description,
          images,
          age_class: ageClass,
          min_players: minPlayers,
          max_players: maxPlayers,
          duration,
          drill_flow: drillFlow,
          organization,
          coaching_points: coachingPoints,
          variations,
        },
        index
      ) => (
        <Collapsible
          key={`${category}.${id}`}
          title={`${categoryIndex}. ${title} (${index + 1})`}
          titleRight={category}
        >

            <Grid container spacing={3}>
            <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb"  className={classes.breadcrumb}>
      <Typography color="inherit" className={classes.link}>
      <PersonAddOutlinedIcon className={classes.icon} />
        {ageClass}
      </Typography>
      <Typography
        color="inherit"
        className={classes.link}
      >
       <PeopleOutlineIcon className={classes.icon} />
       {maxPlayers > '0'
                    ? `${minPlayers} - ${maxPlayers}`
                    : `min ${minPlayers}`}
      </Typography>
      <Typography color="inherit">
      <AccessTimeIcon className={classes.icon} />
      {duration} min
      </Typography>
    </Breadcrumbs>
            <Typography variant='p' component='p'>{description}</Typography>
            </Grid>
            <Grid item md={6}>
                {images.length > 0 && <ImagesSlider images={images} />}
              </Grid>
              <Grid item md={6}>
                <Box>
                <Typography variant='h3' component='h3' color='primary'>
                    <Translate id="drills.drill-flow" />
                  </Typography>
                  <Box mt={1}>
                    <p dangerouslySetInnerHTML={{ __html: drillFlow }} />
                  </Box>
                </Box>
                <Box>
                <Typography variant='h3' component='h3' color='primary'>
                    <Translate id="drills.organization" />
                  </Typography>
                  <Box mt={1}>
                    <p dangerouslySetInnerHTML={{ __html: organization }} />
                  </Box>
                </Box>
                <Box>
                <Typography variant='h3' component='h3' color='primary'>
                    <Translate id="drills.coaching-points" />
                  </Typography>
                  <Box mt={1}>
                    <p dangerouslySetInnerHTML={{ __html: coachingPoints }} />
                  </Box>
                </Box>
                <Box>
                <Typography variant='h3' component='h3' color='primary'>
                    <Translate id="drills.variations" />
                  </Typography>
                  <Box mt={1}>
                    <p dangerouslySetInnerHTML={{ __html: variations }} />
                  </Box>
                </Box>
              </Grid>
            </Grid>
        </Collapsible>
      )
    )}
  </>
)

Drill.defaultProps = {
  drills: [],
}

Drill.propTypes = {
  drills: PropTypes.arrayOf(PropTypes.shape().isRequired),
  category: PropTypes.string.isRequired,
  classes: PropTypes.func.isRequired,
  categoryIndex: PropTypes.number.isRequired,
}

export default Drill
