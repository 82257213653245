import React from 'react'
import { TeamLoader } from 'loaders'
import { withRouter } from 'react-router-dom'
import TeamInsightsContainer from './TeamInsightsContainer'
import PROP_TYPES from 'constants/propTypes'

const TeamInsights = ({
  match: {
    params: { teamId },
  },
  changeTab,
}) => (
  <TeamLoader
    teamId={+teamId}
    additionalFields={['statistics', 'players', 'logo', 'last_match']}
  >
    <TeamInsightsContainer changeTab={changeTab} />
  </TeamLoader>
)

TeamInsights.propTypes = {
  match: PROP_TYPES.match.isRequired,
  changeTab: PROP_TYPES.func.isRequired,
}

export default withRouter(TeamInsights)
