import React from 'react'
import { DrillsLoader, FavoritesLoader } from 'loaders'
import DrillDashboard from './DrillDashboard'

const DrillsDashboard = () => (
  <DrillsLoader alwaysRenderChildren>
    <FavoritesLoader entity="drills">
      <DrillDashboard />
    </FavoritesLoader>
  </DrillsLoader>
)

export default DrillsDashboard
