import styled from 'styled-components'
import SequencesImage from 'assets/images/video-sequences.svg'

export default styled.div`
  display: inline-block;
  width: 35px;
  height: 35px;
  margin-right: 10px;
  background: url(${SequencesImage}) no-repeat center;
  background-size: contain;
`
