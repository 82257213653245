import styled from 'styled-components'

export default styled.div`
  position: absolute;
  right: 10px;
  bottom: 10px;
  cursor: pointer;

  height: 30px;
  width: 30px;
  ${({ theme }) => theme.flexCenter};
  background: white;
  border-radius: 15px;
  font-size: 20px;
  color: var(--secondary-green);
  box-shadow: 0 0 3px 1px rgba(128, 128, 128, 0.5);

  :before {
    content: '\\2715';
  }
`
