// eslint-disable-next-line
import 'froala-editor/js/plugins/image.min.js'


// eslint-disable-next-line 
import 'froala-editor/js/languages/de.js'
// eslint-disable-next-line 
import 'froala-editor/js/languages/es.js'
// eslint-disable-next-line 
import 'froala-editor/js/languages/it.js'
// eslint-disable-next-line 
import 'froala-editor/js/languages/pl.js'
// eslint-disable-next-line 
import 'froala-editor/js/languages/ja.js'

// eslint-disable-next-line
import 'froala-editor/js/plugins/video.min.js'
// eslint-disable-next-line
import 'froala-editor/js/plugins/file.min.js'

import { getLocale } from 'utils/localstorage'

window.REACT_APP_API_URL = process.env.REACT_APP_API_ENDPOINT

const lang = getLocale();

let froalaLanguage = ""

switch (lang) {
  case 'de':
    froalaLanguage = 'de'
    break;
  case 'es':
    froalaLanguage = 'es'
    break;
  case 'it':
    froalaLanguage = 'it'
    break;
  case 'pl':
    froalaLanguage = 'pl'
    break;
    case 'jp':
      froalaLanguage = 'ja'
      break;
  default:
    froalaLanguage = 'en'
    break;
}

const config = {
  // Set Activation Key
  key: 'Kb3A3pC3B2E1C4D4B3oCd2ZSb1XHi1Cb2a1KIWCWMJHXCLSwG1G1B2A1B1D7B5E1D4H3==',
  // eslint-disable-next-line react/react-in-jsx-scope
  language: froalaLanguage,

  spellcheck: true, // Enables browser spellcheck for the text inside the editing box.

  attribution: false, // Remove the Powered By Froala message.
  // useClasses: false,  // When this options is disabled the edited content will have the external CSS properties converted to inline style.

  pasteAllowLocalImages: true, // Removes images that have local path (file://) on paste. Enabling this option might result in having mixed content on HTTPS websites.

  // // pastePlain option is enabled
  pastePlain: false,
  pasteDeniedAttrs: ['class', 'id', 'style'], // Attributes that are removed when pasting something into the rich text editor.
  wordDeniedAttrs: ['class', 'id', 'style'], // Attributes that are removed when pasting something into the rich text editor from Word.

  requestWithCORS: false, // Make AJAX requests using CORS. For more information refer to Cross-origin resource sharing.

  htmlSimpleAmpersand: false,
  // height set min and max
  heightMin: 200,
  heightMax: 550,
  htmlAllowedTags: [
    'p',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'blockquote',
    'ul',
    'ol',
    'li',
    'img',
    'iframe',
    'a',
    'strong',
    'em',
    'u',
    's',
    'pre',
    'table',
    'tbody',
    'tr',
    'td',
    'br',
    'hr',
    'video',
  ],

  // Set the image upload parameter.
  imageUploadParam: 'image_param',

  // Set the image upload URL.
  imageUploadURL: `${window.REACT_APP_API_URL}/froala/image_upload`,

  // Additional upload params.
  imageUploadParams: { id: 'my_editor' },

  // Set request type.
  imageUploadMethod: 'POST',

  // Set max image size to 5MB.
  imageMaxSize: 5 * 1024 * 1024,

  // Allow to upload PNG and JPG.
  imageAllowedTypes: ['jpeg', 'jpg', 'png', 'gif'],

  // Set the video upload parameter.
  videoUploadParam: 'video_param',

  // Set the video upload URL.
  videoUploadURL: `${window.REACT_APP_API_URL}/froala/video_upload`,

  // Additional upload params.
  videoUploadParams: { id: 'my_editor' },

  // Set request type.
  videoUploadMethod: 'POST',

  // Set max video size to 1024MB or 1GB.
  videoMaxSize: 1024 * 1024 * 1024,

  videoResponsive: true,
  videoResize: true,

  // Allow to upload MP4, WEBM and OGG
  videoAllowedTypes: ['webm', 'mp4', 'mov', 'wmv', 'flv', 'avi', 'mkv', '3gp'],

  // Set the file upload parameter.
  fileUploadParam: 'file_param',

  // Set the file upload URL.
  fileUploadURL: `${window.REACT_APP_API_URL}/froala/file_upload`,

  // Additional upload params.
  fileUploadParams: { id: 'my_editor' },

  // Set request type.
  fileUploadMethod: 'POST',

  // Set max file size to 20MB.
  fileMaxSize: 20 * 1024 * 1024,

  // Allow to upload any file.
  fileAllowedTypes: ['*'],

}

export default config
