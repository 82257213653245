import axios from 'axios'
import normalizeDataForServer from 'utils/normalizeDataForServer'
import { setLocale, getLocale } from 'utils/localstorage'
import { store } from '../redux/store'
import { LOGIN_ROUTE, WRONG_PERMISSION_ROUTE } from 'constants/routes'

const REQUEST_METHOD_TO_AXIOS_METHOD = {
  POST: axios.post,
}

const checkResponse = response => {
  return Promise.resolve().then(() => {
    if (
      response.status === 401 &&
      !window.location.pathname.includes(LOGIN_ROUTE)
    ) {
      const locale = getLocale()
      localStorage.clear()
      setLocale(locale)
      window.location.href = LOGIN_ROUTE
    }

    if (response.status === 403) {
      window.location.href = WRONG_PERMISSION_ROUTE
    }
    // TODO: return shown no match or change it
    // if (response.status === 404 || response.status === 500) {
    //   window.location.href = NO_MATCH_ROUTE
    // }

    if (!(response.status === 200 || response.status === 201)) {
      throw response.data
    }

    return response.data
  })
}

const uploadWithProgress = (endPoint, headers, method, data, onProgress) => {
  const axiosMethod = REQUEST_METHOD_TO_AXIOS_METHOD[method]

  if (!axiosMethod) {
    return Promise.reject('error') // eslint-disable-line
  }

  return axiosMethod(endPoint, data, {
    headers,
    onUploadProgress: onProgress,
  })
    .then(checkResponse)
    .catch(error => {
      throw error.response.data
    })
}

const request = (path, method, data, onProgress) => {
  const endPoint = window.REACT_APP_API_ENDPOINT + path

  const state = store.getState()

  const headers = {
    Authorization: `Bearer ${state.auth.token}`,
    Accept: 'application/json',
  }

  if (onProgress) {
    return uploadWithProgress(endPoint, headers, method, data, onProgress)
  }

  // eslint-disable-next-line no-undef
  return fetch(endPoint, {
    headers,
    method,
    body: data,
  }).then(response =>
    response
      .json()
      .then(json => checkResponse({ status: response.status, data: json }))
  )
}

const createFormData = data => {
  const formData = new FormData()

  Object.entries(normalizeDataForServer(data)).forEach(([key, value]) => {
    const fieldValue = value !== null ? value : ''

    formData.append(key, fieldValue)
  })

  return formData
}

export const getRequest = path => request(path, 'GET')

export const postRequest = (path, data = {}, onProgress) =>
  request(path, 'POST', createFormData(data), onProgress)

export const putRequest = (path, data = {}) =>
  request(path, 'POST', createFormData({ _method: 'put', ...data }))

export const deleteRequest = path => request(path, 'DELETE')

export const patchRequest = (path, data = {}) =>
  request(path, 'POST', createFormData({ _method: 'patch', ...data }))
