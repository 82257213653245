import React from 'react'
import {
  Avatar,
  Box,
  IconButton,
  InputAdornment,
  TableCell,
  TableRow,
  Tooltip,
} from '@material-ui/core'
import { Rating } from '@material-ui/lab'
import { InputNumber } from 'components'
import RotateLeftIcon from '@material-ui/icons/RotateLeft'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import { Translate } from 'react-localize-redux'
import RatingNotePlayerPopover from './RatingNotePlayerPopover'
import PROP_TYPES from 'constants/propTypes'

const RatePlayerTableRow = ({
  matchPlayers,
  onChangeRating,
  onChangePlayerTime,
  handleSave,
}) => {
  return (
    <>
      {matchPlayers.map((player) => (
        <TableRow key={player.name}>
          <TableCell component="th" scope="row">
            <Avatar alt={player.first_name} src={player.avatar?.full_url} />
          </TableCell>
          <TableCell style={{ minWidth: '200px' }}>
            {player.first_name} {player.last_name}
          </TableCell>
          <TableCell>
            <Box component="span" style={{ display: 'flex' }}>
              <Rating
                size="small"
                precision={0.5}
                value={player.rating || 0}
                onChange={(e, v) => onChangeRating(v, player.uuid)}
              />
              {player.rating > 0 && (
                <Tooltip
                  title={<Translate id="button.reset" />}
                  placement="top"
                  arrow
                >
                  <IconButton
                    size="small"
                    aria-label="delete"
                    onClick={() => onChangeRating(0, player.uuid)}
                  >
                    <RotateLeftIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </TableCell>
          <TableCell>
            <RatingNotePlayerPopover
              rating={player.rating}
              player={player}
              notes={player.notes}
              handleMatchSave={handleSave}
              playerUuid={player.uuid}
              tag={player.tag}
            />
          </TableCell>
          <TableCell>
            <InputNumber
              minLimit={0}
              label={<Translate id="matches.minutes-played" />}
              value={player.playing_minutes}
              onChange={(e) => onChangePlayerTime(e, player.id)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <AccessTimeIcon fontSize="small" />
                  </InputAdornment>
                ),
              }}
            />
          </TableCell>
        </TableRow>
      ))}
    </>
  )
}
RatePlayerTableRow.defaultProps = {
  matchPlayers: [],
}
RatePlayerTableRow.propTypes = {
  matchPlayers: PROP_TYPES.arrayOf(PROP_TYPES.shape().isRequired),
  onChangeRating: PROP_TYPES.func.isRequired,
  onChangePlayerTime: PROP_TYPES.func.isRequired,
  handleSave: PROP_TYPES.func.isRequired,
}
export default RatePlayerTableRow
