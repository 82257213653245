import React from 'react'
import { connect } from 'react-redux'
import { ArticleCreateEditForm } from 'containers'
import { articles } from 'redux/modules'
import { createSubUrl } from 'utils/urlConstructor'
import { Translate } from 'react-localize-redux'
import { ARTICLES_DASHBOARD_ROUTE } from 'constants/routes'
import PROP_TYPES from 'constants/propTypes'

const ArticleCreateContainer = ({
  history,
  match,
  location: { fromUrl, fromPathname = 'Articles' },
  articlesCategories,
  createArticle,
}) => {
  const linkBack = fromUrl || createSubUrl(match, ARTICLES_DASHBOARD_ROUTE)

  const goBack = () => history.push(linkBack)

  const onSubmit = values => createArticle(values).then(goBack)

  return (
    <ArticleCreateEditForm
      form="articleCreate"
      caption={<Translate id="articles.create-article"/>}
      fromPath={{
        value: linkBack,
        title: fromPathname,
      }}
      initialValues={{
        categories: [],
      }}
      onSubmit={onSubmit}
      articlesCategories={articlesCategories}
    />
  )
}

ArticleCreateContainer.defaultProps = {
  articlesCategories: [],
}

ArticleCreateContainer.propTypes = {
  history: PROP_TYPES.history.isRequired,
  match: PROP_TYPES.match.isRequired,
  location: PROP_TYPES.location.isRequired,
  articlesCategories: PROP_TYPES.arrayOfObjects,
  createArticle: PROP_TYPES.func.isRequired,
}

export default connect(
  ({
    articles: {
      current: { articlesCategories },
    },
  }) => ({ articlesCategories }),
  {
    createArticle: articles.createArticle,
  }
)(ArticleCreateContainer)
