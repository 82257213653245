import React from 'react'
import { PlayerProfilePrivacy, PlayerAttendanceResponse } from 'containers'
import { Grid } from '@material-ui/core'

const showPanel = process.env.REACT_APP_SHOW_PLAYER_PRIVACY_PANEL === 'true' || false;

function TeamSettings() {
  return (
    <Grid container spacing={2}>
      {showPanel && (
        <Grid item xs={4}>
          <PlayerProfilePrivacy />
        </Grid>
      )}
      <Grid item xs={8}>
        <PlayerAttendanceResponse />
      </Grid>
    </Grid>
  );
}

export default TeamSettings;

