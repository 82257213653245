import React from 'react'
import { TeamLoader } from 'loaders'
import { withRouter } from 'react-router-dom'
import PricingDashboardContainer from './PricingDashboardContainer'
import PROP_TYPES from 'constants/propTypes'

const PricingDashboardOld = ({
  match: {
    params: { teamId },
  },
  changeTab,
}) => (
  <TeamLoader
    teamId={+teamId}
    additionalFields={['statistics', 'players', 'logo', 'last_match']}
    additionalParams={{ page: 'Team', tabTitle: 'Pricing  Billing' }}
  >
    <PricingDashboardContainer changeTab={changeTab} />
  </TeamLoader>
)
PricingDashboardOld.propTypes = {
  match: PROP_TYPES.match.isRequired,
  changeTab: PROP_TYPES.func.isRequired,
}
export default withRouter(PricingDashboardOld)
