import React from 'react';
import { withRouter } from 'react-router-dom';
import { getPolarErrorCode, getPolarToken, removePolar } from 'utils/localstorage';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Paper,
} from '@material-ui/core';

import { Translate } from 'react-localize-redux';
import ConnectParty from './ConnectItem';
import { ALL_ITEMS } from 'constants/thirdParty';


const styles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    margin: theme.spacing(0, 0, 0),
    padding: theme.spacing(3, 3, 3)
  },
  loginContainer: {
    height: 386,
    width: 313
  },
  loginItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1rem',
    height: '100%',
  },
  tableItem: { padding: '10px !important' },
  media: { height: 140, width: '100%' },
  contentCell: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem',
  },
  button: { marginBottom: '0.5rem', width: '100%' },
}))

const MuiCard = withStyles(theme => ({
  root: {
    border: '1px solid #e8e8e8',
    '& .MuiCardContent-root': {
      padding: theme.spacing(2, 2, 0),
    },
  },
}))(Card)



function ConnectDashboard({ history, location }) {


  const classes = styles()

  const thirdPartyArray=[...ALL_ITEMS]

  const [polarToken,changePolarToken]=React.useState(getPolarToken())
  const [error,setErrorCode]=React.useState(getPolarErrorCode())

  const [ConnectForm, setIsOpen] = ConnectParty()

  const disconnect=()=>{
    removePolar()
    changePolarToken(undefined)
    setErrorCode(undefined)
  }

  React.useEffect(()=>{
  },[])
  
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Paper className={classes.root}>
          <Grid container spacing={3}>
            {thirdPartyArray.length > 0 && thirdPartyArray.map((el, idx) => {

              return (
                <Grid item className={classes.tableItem}
                  xs={12} sm={6} md={4} lg={3} xl={2}
                  key={`connectParty-${idx}`}
                >

                  <MuiCard>
                    <CardMedia
                      className={classes.media}
                      image={el.img}
                    />

                    <CardContent className={classes.contentCell}>

                      {error &&
                        <Button
                          size="small"
                          variant="contained"
                          color="red"
                          className={classes.button}
                          onClick={() => {
                            window.location.href = el.url
                          }}
                        >Try Again</Button>
                      }

                      {(polarToken && polarToken.id) &&
                        <Button
                          size="small"
                          variant="contained"
                          color="primary"
                          className={classes.button}
                          onClick={() => {
                            disconnect()
                          }}><Translate id="polar.button.disconnect-btn"/></Button>
                      }

                      {!error && !(polarToken && polarToken.id) &&
                        <Button
                          size="small"
                          variant="contained"
                          color="primary"
                          className={classes.button}
                          onClick={() => {
                            window.location.href = el.url
                          }}><Translate id="polar.button.connect-btn"/></Button>
                      }

                      {/* {(polarToken && polarToken.id) && <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={() => {}}
                      >Profile</Button>} */}
                    </CardContent>
                  </MuiCard>

                </Grid>
              )
            })}
          </Grid>
        </Paper>
      </Grid>
      <ConnectForm />
    </Grid>
  )
}

export default withRouter(ConnectDashboard)
