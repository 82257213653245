import styled from 'styled-components'
import { Form } from 'react-bootstrap'

export default styled(Form.Control)`
  padding: 0 5px;
  background: transparent;
  color: #888;
  width: fit-content;
  max-width: 100%;

  border: #888 solid 1px;
  border-radius: 12px;
  margin: 0 5px;

  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ::placeholder {
    font-size: 80%;
  }
`
