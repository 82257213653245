import React, { createContext, useState, useEffect, useCallback } from 'react'
import sortBy from 'lodash.sortby'
import { connect } from 'react-redux'
import filterObject from 'utils/filterObject'
import GROUPS from './groupConstant'
import PROP_TYPES from 'constants/propTypes'

const STATE_FIELDS = ['date', 'location','street','city','country','postal_code','lat','lng','short_address','state','polar_event_id','training_focus','end_time','intensity','additional_info']

export const Context = createContext({})

const createNormalizedPlayers = players => {
  const normalizedPlayers = players.map(item => ({
    id: item.id,
    name: `${item.first_name} ${item.last_name ? item.last_name : ''}`,
    relation:
      (GROUPS[item.group_id] && GROUPS[item.group_id].value) ||
      (item.available ? item.position : 'notAvailable'),
    position: item.position,
    avatar: item.avatar && item.avatar.full_url,
    available: item.available,
    notAvailableReason: item.not_available_reason,
    isSelected: false,
  }))

  return sortBy(normalizedPlayers, ({ relation }) => relation)
}

const TrainingStateContext = ({ children, currentTraining }) => {
  const [trainingState, changeTrainingState] = useState({})
  const [anyChange, setAnyChange] = useState(false)
  const [isDragging, setDragging] = useState({
    dragging: false,
    destination: null,
  })

  const resetState = () => {
    const newMatchState = {
      ...filterObject(
        currentTraining,
        () => true,
        key => STATE_FIELDS.includes(key)
      ),
      sessionDrills: currentTraining.session_drills,
      normalizedPlayers: createNormalizedPlayers(currentTraining.players),
      notes: currentTraining.notes,
      status: 'published',
      id: currentTraining.id,
      training_players: currentTraining.training_players,
      tickers: currentTraining.tickers,
    }

    changeTrainingState(newMatchState)
    setAnyChange(false)
  }

  useEffect(resetState, [
    currentTraining.id,
    currentTraining.session_drills,
    currentTraining.players,
  ])

  const onFieldChange = useCallback(
    (fieldName, newValue) => {
      changeTrainingState({ ...trainingState, [fieldName]: newValue })
      setAnyChange(true)
    },
    [trainingState]
  )
  const allFieldChange = useCallback(
    (data) => {
      changeTrainingState({ ...trainingState, ...data })
      setAnyChange(true)
    },
    [trainingState]
  )
  const onTwoFieldChange = useCallback(
    (field1,field2,value1,value2) => {
      changeTrainingState({ ...trainingState, [field1]: value1,[field2]: value2 })
      setAnyChange(true)
    },
    [trainingState]
  )

  return (
    <Context.Provider
      value={{
        trainingState,
        onFieldChange,
        resetState,
        setAnyChange,
        anyChange,
        isDragging,
        setDragging,
        allFieldChange,
        onTwoFieldChange
      }}
    >
      {children}
    </Context.Provider>
  )
}

TrainingStateContext.propTypes = {
  children: PROP_TYPES.children.isRequired,
  currentTraining: PROP_TYPES.shape().isRequired,
}

export default connect(({ trainings: { current } }) => ({
  currentTraining: current,
}))(TrainingStateContext)
