import React, { useMemo } from 'react'
import moment from 'moment'
import styled from 'styled-components'
import ActiveViewSelector from '../../ActiveViewSelector'
import PROP_TYPES from 'constants/propTypes'

const PrevNextButton = styled.span`
  padding: 0 10px 0;
  cursor: pointer;

  visibility: ${({ isShown }) => (isShown ? 'visible' : 'hidden')};
`

const OverviewToolbar = ({
  changeActiveView,
  currentSeason,
  activeYear,
  changeActiveYear,
  className,
}) => {
  const isPrevShown = useMemo(
    () => activeYear > moment(currentSeason.start_date).format('YYYY'),
    [activeYear, currentSeason.start_date]
  )

  const isNextShown = useMemo(
    () => activeYear < moment(currentSeason.end_date).format('YYYY'),
    [activeYear, currentSeason.end_date]
  )

  return (
    <div className={className}>
      <PrevNextButton
        isShown={isPrevShown}
        onClick={() => changeActiveYear(+activeYear - 1)}
      >
        &#10094;
      </PrevNextButton>
      <div>{activeYear}</div>
      <PrevNextButton
        isShown={isNextShown}
        onClick={() => changeActiveYear(+activeYear + 1)}
      >
        &#10095;
      </PrevNextButton>
      <ActiveViewSelector
        activeView="overview"
        changeActiveView={changeActiveView}
      />
    </div>
  )
}

OverviewToolbar.defaultProps = {
  currentSeason: {},
}

OverviewToolbar.propTypes = {
  changeActiveView: PROP_TYPES.func.isRequired,
  currentSeason: PROP_TYPES.shape(),
  activeYear: PROP_TYPES.string.isRequired,
  changeActiveYear: PROP_TYPES.func.isRequired,
  className: PROP_TYPES.string.isRequired,
}

export default styled(OverviewToolbar)`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  position: relative;
`
