import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import MatchesCalendarLayout from './MatchesCalendarLayout'
import PROP_TYPES from 'constants/propTypes'

const MatchesCalendarContainer = ({ matches, trainings }) => {
  const events = [
    ...matches.map(
      ({
        id,
        team_id: teamId,
        season_id: seasonId,
        date,
        status,
        location,
        opponent,
        isNew,
      }) => ({
        id,
        team_id: teamId,
        season_id: seasonId,
        status,
        location,
        opponent,
        title: opponent
          ? `${opponent}, ${moment(date).format('hh:mm a')}`
          : `Matchday, ${moment(date).format('hh:mm a')}`,
        start: +moment(date).format('x'),
        end: +moment(date).format('x'),
        relation: 'match',
        isNew,
      })
    ),
    ...trainings.map(({ id, date }) => ({
      id,
      status: 'onMatch',
      title: `Training, ${moment(date).format('hh:mm a')}`,
      start: +moment(date).format('x'),
      end: +moment(date).format('x'),
      relation: 'training',
    })),
  ]

  return <MatchesCalendarLayout events={events} />
}

MatchesCalendarContainer.defaultProps = {
  matches: [],
  trainings: [],
}

MatchesCalendarContainer.propTypes = {
  matches: PROP_TYPES.arrayOfObjects,
  trainings: PROP_TYPES.arrayOfObjects,
}

export default connect(({ matches, trainings }) => ({
  matches: matches.items,
  trainings: trainings.items,
}))(MatchesCalendarContainer)
