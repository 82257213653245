import React from 'react'
import { connect } from 'react-redux'
import { lineups } from 'redux/modules'
import Loader from './Loader'
import PROP_TYPES from 'constants/propTypes'

const LineupsLoader = ({ children, fetchLineups, ...restProps }) => (
  <Loader fetchMethod={fetchLineups} {...restProps}>
    {children}
  </Loader>
)

LineupsLoader.propTypes = {
  children: PROP_TYPES.children.isRequired,
  fetchLineups: PROP_TYPES.func.isRequired,
}

export default connect(
  null,
  { fetchLineups: lineups.fetchLineups }
)(LineupsLoader)
