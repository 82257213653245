import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withLocalize } from 'react-localize-redux'
import { TrainingPackageCreateEditForm } from 'containers'
import { trainingPackages } from 'redux/modules'
import { createSubUrl } from 'utils/urlConstructor'
import filterObject from 'utils/filterObject'
import getTargetTranslationFields from 'utils/getTargetTranslationFields'
import { TRAINING_PACKAGES_DASHBOARD_ROUTE } from 'constants/routes'
import PROP_TYPES from 'constants/propTypes'

const FIELDS_WITH_TRANSLATION = ['title', 'description']

const TrainingPackageEditContainer = ({
  history,
  match: {
    params: { trainingPackageId },
  },
  match,
  location: { fromUrl, fromPathname = 'Training Packages' },
  trainingPackage: {
    translations,
    drills: availableDrills,
    ...trainingPackage
  },
  updateTrainingPackage,
  deleteTrainingPackage,
  activeLanguage: { code: activeLocale },
}) => {
  const linkBack =
    fromUrl ||
    createSubUrl(
      match,
      TRAINING_PACKAGES_DASHBOARD_ROUTE.populateURLParams({ trainingPackageId })
    )

  const goBack = () => history.push(linkBack)

  const handleSubmit = ({
    number_of_players: numberOfPlayers,
    warmup_drills: warmupDrills,
    main_part_drills: mainPartDrills,
    final_part_drills: finalPartDrills,
    images,
    ...values
  }) => {
    const data = {
      ...values,
      number_of_players: +numberOfPlayers,
    }
   
    if (warmupDrills && warmupDrills.length) {
      data.warmup_drills = warmupDrills
    }

    if (mainPartDrills && mainPartDrills.length) {
      data.main_part_drills = mainPartDrills
    }

    if (finalPartDrills && finalPartDrills.length) {
      data.final_part_drills = finalPartDrills
    }

    if (images && images.length) {
      images.forEach((image, index) => {
        data[`images[${index}]`] = image.id || image
      })
    }

    updateTrainingPackage(trainingPackageId, data).then(goBack)
  }

  const initialValues = {
    global: 0,
    ...filterObject(
      {
        ...trainingPackage,
        warmup_drills: trainingPackage.warmup_drills.map(({ id }) => id),
        main_part_drills: trainingPackage.main_part_drills.map(({ id }) => id),
        final_part_drills: trainingPackage.final_part_drills.map(
          ({ id }) => id
        ),
        ...getTargetTranslationFields(
          translations,
          activeLocale,
          FIELDS_WITH_TRANSLATION
        ),
        locale: activeLocale,
      },
      Boolean
    ),
  }

  const handleDelete = () =>
    deleteTrainingPackage(+trainingPackageId).then(() => history.push(linkBack))

  return (
    <TrainingPackageCreateEditForm
      form="trainingPackageEdit"
      initialValues={initialValues}
      caption="Edit Training Package"
      fromPath={{
        value: linkBack,
        title: fromPathname,
      }}
      onSubmit={handleSubmit}
      availableDrills={availableDrills}
      isEditForm
      showDeleteButton
      handleDelete={handleDelete}
    />
  )
}

TrainingPackageEditContainer.propTypes = {
  history: PROP_TYPES.history.isRequired,
  match: PROP_TYPES.match.isRequired,
  location: PROP_TYPES.location.isRequired,
  trainingPackage: PROP_TYPES.shape().isRequired,
  updateTrainingPackage: PROP_TYPES.func.isRequired,
  deleteTrainingPackage: PROP_TYPES.func.isRequired,
  activeLanguage: PROP_TYPES.shape().isRequired,
}

export default compose(
  connect(
    ({ trainingPackages: { current: trainingPackage } }) => ({
      trainingPackage,
    }),
    {
      updateTrainingPackage: trainingPackages.updateTrainingPackage,
      deleteTrainingPackage: trainingPackages.deleteTrainingPackage,
    }
  ),
  withLocalize
)(TrainingPackageEditContainer)
