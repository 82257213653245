import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { NavDropdown } from 'react-bootstrap'
import { SubLink } from 'containers'
import { teams } from 'redux/modules'
import { connect } from 'react-redux'
import { setLocale } from 'utils/localstorage'
import PROP_TYPES from 'constants/propTypes'
// TODO: refactor
const StyledDropdown = styled(NavDropdown)`
  cursor: pointer;
  display: inline-block;

  > a {
    border-radius: 10px;
    padding: 5px 10px;
    background: var(--light-grey);
    color: var(--dark-green);
    margin: 0 5px;
    font-size: 14px;

    :disabled {
      background: #eeeeee;
    }
  }
`

const Item = styled.div`
  ${({ active }) => (active ? 'font-weight: bold' : '')};
`

const Dropdown = ({
  options,
  value,
  title: dropdownTitle,
  onSelect,
  disabled,
  alwaysShowTitle,
  updateLocale,
}) => {
  const { title = dropdownTitle } = !alwaysShowTitle
    ? options.find(option => option.value === value) || {}
    : {}

  return (
    <StyledDropdown title={title} alignRight disabled={disabled}>
      {options.map(option => (
        <Fragment key={option.value}>
          {option.isLink ? (
            <SubLink to={option.value}>
              <NavDropdown.Item as="div">
                <Item active={option.value === value}>{option.title}</Item>
              </NavDropdown.Item>
            </SubLink>
          ) : (
            <NavDropdown.Item
              as="div"
              disabled={option.disabled}
              onClick={() => {
                onSelect(option.value)
                setLocale(option.value)
                updateLocale()
              }}
            >
              <Item active={option.value === value}>{option.title}</Item>
            </NavDropdown.Item>
          )}
        </Fragment>
      ))}
    </StyledDropdown>
  )
}

Dropdown.defaultProps = {
  value: null,
  title: '',
  onSelect: () => 0,
  disabled: false,
  alwaysShowTitle: false,
}

Dropdown.propTypes = {
  options: PROP_TYPES.options.isRequired,
  value: PROP_TYPES.stringOrNumber,
  title: PropTypes.string,
  onSelect: PropTypes.func,
  updateLocale: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  alwaysShowTitle: PropTypes.bool,
}

export default connect(
  null,
  {
    updateLocale: teams.updateLocale,
  }
)(Dropdown)
