import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { Box, Button, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import MDInput from 'components/material/MDInput'
import { Translate } from 'react-localize-redux'
import MuiPhoneNumber from 'material-ui-phone-number'
import useStyles from '../../styleComponents'

const UpdateForm = ({ ageClasses, country, onUpdate, inProgress, theme }) => {
  const classes = useStyles(theme)
  const phoneFields = useRef()

  const [teamName, setTeamName] = useState('')
  const [teamNameValidation, setTeamNameValidation] = useState({ valid: true, message: '' })
  const [ageClass, setAgeClass] = useState(ageClasses.length ? ageClasses[0] : false)
  const [phone, setPhone] = useState('')
  const [phoneValidation, setPhoneValidation] = useState({ valid: true, message: '' })

  const handleTeamName = (event) => {
    setTeamName(event.target.value)

    if (event.target.value.length === 0) {
      setTeamNameValidation({
        valid: false,
        message: <Translate id="team.required.team-name" />
      })
    } else {
      setTeamNameValidation({ valid: true, message: '' })
    }
  }

  const handleAgeClass = (event) => {
    setAgeClass(ageClasses.find(ac => ac.id === event.target.value))
  }
  
  const handlePhone = (value) => {
    setPhone(value)

    if (value.length === 0) {
      setPhoneValidation({
        valid: false,
        message: <Translate id="team.required.phone" />
      })
    } else {
      setPhoneValidation({ valid: true, message: '' })
    }
  }
  
  const handleSubmit = (event) => {
    event.preventDefault()

    if (teamName && teamNameValidation.valid && phone && phoneValidation.valid) {
      onUpdate(teamName, ageClass.id, phone)
    }
  }

  return (
    <Translate>
      {({ translate }) => (
        <Box>
          <Box mb={1}>
            <MDInput
              value={teamName}
              onChange={handleTeamName}
              disabled={inProgress}
              error={!teamNameValidation.valid}
              helperText={teamNameValidation.message}
              required
              fullWidth
              id="team_name"
              type="text"
              label={<Translate id="signup.team_name" />}
              variant="outlined"
              className={classes.formControl}
            />
          </Box>
          <Box mb={1}>
            <FormControl
              variant="outlined"
              size="small"
              className={classes.formControl}
            >
              <InputLabel id="team_age_class_label" size="small">
                {translate("signup.age_group")}{" "}
              </InputLabel>
              <Select
                label={translate("signup.age_group")}
                labelId="team_age_class_label"
                id="team_age_class"
                name="age_class"
                size="small"
                disabled={inProgress}
                value={ageClass && ageClass.id}
                onChange={handleAgeClass}
              >
                {ageClasses && ageClasses.map(ac =>
                  <MenuItem key={ac.id} value={ac.id}>{ac.name}</MenuItem>
                )}
              </Select>
            </FormControl>
          </Box>
          <Box mb={1}>
            <MuiPhoneNumber
              ref={phoneFields}
              value={phone}
              onChange={handlePhone}
              error={!phoneValidation.valid}
              helperText={phoneValidation.message}
              required
              disabled={inProgress}
              id="phone"
              label={translate("team.phone-number")}
              type="tel"
              name="phone"
              variant="outlined"
              className={classes.formControl}
              defaultCountry={country ? country.toLowerCase() : 'ch'}
              preferredCountries={["de", "at", "ch", "us", "gb"]}
              disableAreaCodes
              localization={{
                Germany: translate("signup.tel-country-Germany"),
                Switzerland: translate("signup.tel-country-Switzerland"),
                Austria: translate("signup.tel-country-Austria"),
                "United States": translate("signup.tel-country-US"),
                "United Kingdom": translate("signup.tel-country-UK"),
              }}
            />
          </Box>
          <Button
            onClick={handleSubmit}
            disabled={inProgress}
            fullWidth
            variant="contained"
            type="button"
            size="large"
            className={classes.button}
            mb={.5}
          >
            <Translate id="login.signup" />
          </Button>
        </Box>
      )}
    </Translate>
  )
}

UpdateForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  ageClasses: PropTypes.array.isRequired,
  country: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
  inProgress: PropTypes.bool.isRequired,
}

export default UpdateForm
