import React, { useState, useContext } from 'react'
import { Translate } from 'react-localize-redux'
import { makeStyles } from '@material-ui/core/styles'
import PersonIcon from '@material-ui/icons/Person'
import {
  Button,
  Paper,
  Popover,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Avatar,
  InputLabel,
} from '@material-ui/core'
import { Context } from 'routes/MatchDashboard/MatchStateContext'
import { PLAYER_TAGS } from 'constants/player'
import PROP_TYPES from 'constants/propTypes'
import './style.css'

const useStyles = makeStyles((theme) => ({
  CustomPopper: {
    borderRadius: '8px',
    '& .MuiPopover-paper': {
      padding: theme.spacing(2),
    },
  },
}))

const PlayerAvailabiltyAndChangePlayerPopover = ({
  rating,
  notes,
  playingMinutes,
  handleMatchSave,
  playerId,
  tag,
  avatar,
  name,
  number,
  hidePlayerChangePopover,
}) => {
  const [state, changeState] = useState({
    rating,
    notes,
    playingMinutes,
    playerId,
    tag,
  })
  const { matchState, changeMatchState, setAnyChange } = useContext(Context)

  const [anchorEl, setAnchorEl] = useState(null)
  const [open, setOpen] = useState(false)
  const [player, setPlayer] = useState(state.playerId)

  const onChangePlayer = ({ target: { value } }) => {
    if (!value) return

    const playerUuidValue = matchState.players.findById(value).uuid
    const {playerUuid} = matchState.matchPlayers.findById(playerId)
    setPlayer(value)
    const tempPlayers = [...matchState.matchPlayers]
    const fromPlayerIndex = tempPlayers.findIndex((p) => p.playerUuid === playerUuidValue)
    const replacePlayerIndex = tempPlayers.findIndex((p) => p.playerUuid === playerUuid)
    const fromPlayer = tempPlayers.find((p) => p.playerUuid === playerUuidValue)
    const replacePlayer = tempPlayers.find((p) => p.playerUuid === playerUuid)
    if (!fromPlayer.participant) {
      fromPlayer.participant = true
      replacePlayer.participant = false
      fromPlayer.position_x = replacePlayer.position_x
      fromPlayer.position_y = replacePlayer.position_y

      replacePlayer.position_x = 0
      replacePlayer.position_y = 0
    } else {
      const x = fromPlayer.position_x
      const y = fromPlayer.position_y

      fromPlayer.position_x = replacePlayer.position_x
      fromPlayer.position_y = replacePlayer.position_y

      replacePlayer.position_x = x
      replacePlayer.position_y = y
      if (fromPlayer.participant === true && replacePlayer.participant === false) {
        fromPlayer.participant = false
        replacePlayer.participant = true

      }
    }
    tempPlayers[fromPlayerIndex] = fromPlayer
    tempPlayers[replacePlayerIndex] = replacePlayer
    changeMatchState({ ...matchState, matchPlayers: tempPlayers })
    setOpen(false)
    setAnchorEl(null)
    handleMatchSave()
  }
  const classes = useStyles()

  const handlePlayerTag = (e) => {
    const selectedTag = e.target.value

    changeState({ ...state, tag: selectedTag })
    changeMatchState({
      ...matchState,
      matchPlayers: matchState.matchPlayers.updateByUuid(playerId, {
        ...matchState.matchPlayers.findByUuid(playerId),
        tag: selectedTag,
        in_roster: selectedTag === 'available' ? 'AVAILABLE' : 'NOT_AVAILABLE',
        participant: selectedTag === 'available',
      }),
    })
    setAnyChange(true)
  }

  const handleClick = (event) => {
    if (!hidePlayerChangePopover) {
      setAnchorEl(event.currentTarget)
      setOpen(true)
      setPlayer(null)
      setPlayer(state.playerId)
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
    setOpen(false)
  }

  return (
    <>
      <Popover
        id={state.playerId}
        open={open}
        anchorEl={anchorEl}
        className={classes.CustomPopper}
        transition
        onClose={handleClose}
        disablePortal={false}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
      >
        <Paper>
          <FormControl variant="outlined">
            <InputLabel id="availability-tag">
              <Translate id="matches.change-player" />
            </InputLabel>
            <Select
              value={player}
              onChange={onChangePlayer}
              label={<Translate id="matches.change-player" />}
            >
              {matchState.players
                .filter((p) => p.in_roster)
                .sort((a, b) =>
                  a.first_name?.toLowerCase() < b.first_name?.toLowerCase()
                    ? 1
                    : -1
                )
                .map((p) => {
                  return (
                    <MenuItem value={p.id} key={p.id}>
                      {`${p.first_name} ${p.last_name}`}
                    </MenuItem>
                  )
                })}
            </Select>
          </FormControl>
          <Grid item>
            <FormControl variant="outlined">
              <InputLabel id="availability-tag">
                <Translate id="team.tag" />
              </InputLabel>
              <Select
                value={state.tag}
                onChange={handlePlayerTag}
                label={<Translate id="team.tag" />}
              >
                {PLAYER_TAGS.map((p) => {
                  return (
                    <MenuItem value={p.value} key={p.value}>
                      {p.title}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>
        </Paper>
      </Popover>

      <Button aria-describedby={playerId} onClick={handleClick}>
        {avatar !== null ? (
          <Avatar src={avatar}>
            <PersonIcon />
          </Avatar>
        ) : (
          <Avatar alt={name}>{number}</Avatar>
        )}
      </Button>
    </>
  )
}

PlayerAvailabiltyAndChangePlayerPopover.defaultProps = {
  notes: '',
  handleMatchSave: null,
  hidePlayerChangePopover: false,
}

PlayerAvailabiltyAndChangePlayerPopover.propTypes = {
  rating: PROP_TYPES.number.isRequired,
  tag: PROP_TYPES.number.isRequired,
  playerId: PROP_TYPES.number.isRequired,
  notes: PROP_TYPES.string,
  playingMinutes: PROP_TYPES.number.isRequired,
  handleMatchSave: PROP_TYPES.func,
  avatar: PROP_TYPES.string.isRequired,
  name: PROP_TYPES.string.isRequired,
  number: PROP_TYPES.string.isRequired,
  hidePlayerChangePopover: PROP_TYPES.bool,
}

export default PlayerAvailabiltyAndChangePlayerPopover
