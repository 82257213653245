import React, { useState } from 'react'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Button } from '@material-ui/core'
import { TeamSelector, InnrHead } from 'containers'
import { auth as authModule, teams as teamsModule } from 'redux/modules'
import { LoadingSpinner } from '../../components'
import TeamsList from './components/TeamsList'
import TeamsHeader from './TeamsHeader'
import TeamCreateForm from './components/TeamCreateForm'

const TeamsLayout = ({ syncPermissions, fetchTeams, teams, permissionsIsLoading, teamsIsLoading }) => {
  const [ open, setOpen ] = useState(false)

  useState(() => {
    syncPermissions().then(
      fetchTeams()
    )
  }, [])

  const handleTeamModalOpen = () => {
    setOpen(true)
  }

  const handleTeamModalClose = () => {
    setOpen(false)
  }

  return (
    <TeamsHeader>
      <TeamCreateForm handleClose={ handleTeamModalClose } open={ open }/>
      <InnrHead title={ <Translate id="myteams.title"/> }>
        <Button variant="outlined" color="primary" onClick={ handleTeamModalOpen }>
          <Translate id="myteams.add"/>
        </Button>
      </InnrHead>
      {permissionsIsLoading || teamsIsLoading ? (
        <LoadingSpinner />
      ) : (
        <TeamSelector redirectTo="/">
          <TeamsList teams={ teams }/>
        </TeamSelector>
      )}
    </TeamsHeader>
  )
}

TeamsLayout.defaultProps = {
  teams: []
}

TeamsLayout.propTypes = {
  syncPermissions: PropTypes.func.isRequired,
  fetchTeams: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  teams: PropTypes.array,
  permissionsIsLoading: PropTypes.bool.isRequired,
  teamsIsLoading: PropTypes.bool.isRequired,
}

export default connect(
  ({ auth, teams }) => ({
    permissionsIsLoading: auth.isLoading,
    teams: teams.items,
    teamsIsLoading: teams.isLoading
  }), {
    syncPermissions: authModule.syncPermissions,
    fetchTeams: teamsModule.fetchTeams,
  }
)(TeamsLayout)
