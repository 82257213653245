import React from 'react'
import { Translate } from 'react-localize-redux'
import { DragLayer, DropTarget } from 'react-dnd'
import { DraggablePlayer } from 'containers'
import { Paper, Box } from '@material-ui/core'
import { compose } from 'redux'
import { Heading, DraggableArea } from './styledComponents'
import PlayersList from './PlayersList'
import PROP_TYPES from 'constants/propTypes'

const NotRespondedPlayers = ({
  eventPlayers,
  fromPathname,
  connectDropTarget,
}) => {

  return (
    <Paper>
      <Heading>
      <Translate id="training.not-responded" />
      </Heading>
      <Box>
        {connectDropTarget(
          <div>
           <DraggableArea>
              {eventPlayers.length === 0 ? (
                <Translate id="matches.drag-players" />
              ) : (
                <PlayersList
                  players={eventPlayers}
                  WrapperComponent={DraggablePlayer}
                  fromPathname={fromPathname}
                />
              )}
            </DraggableArea>
          </div>
        )}
      </Box>
    </Paper>
  )
}

NotRespondedPlayers.defaultProps = {
  eventPlayers: [],
}
NotRespondedPlayers.propTypes = {
  eventPlayers: PROP_TYPES.arrayOfObjects,
  fromPathname: PROP_TYPES.string.isRequired,
  connectDropTarget: PROP_TYPES.func.isRequired,
}

const fieldTarget = {
  drop({ item: { playerId }, onDrop }) {
    onDrop(playerId, 'notRespondedPlayers')
  },
}

export default compose(
  DragLayer(monitor => ({
    item: monitor.getItem(),
  })),
  DropTarget('player', fieldTarget, connect => ({
    connectDropTarget: connect.dropTarget(),
  }))
)(NotRespondedPlayers)
