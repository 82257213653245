import React from 'react'
import { Translate } from 'react-localize-redux'
import { makeStyles } from '@material-ui/core/styles'
import { SubLink } from 'containers'
import { connect } from 'react-redux'
import { players as playersModule } from 'redux/modules'
import {
  Card,
  CardHeader,
  CardContent,
  List,
  ListItem,
  ListItemText,
  Typography,
  Chip,
  Box,
  Tooltip,
  Divider
} from '@material-ui/core'
import { timeSince,formatCostumDateTimeForUI } from 'utils/formatDate'
import hasSubscription from 'utils/hasSubscription'
import PROP_TYPES from 'constants/propTypes'
import { EVENT_ROUTE, MATCH_DASHBOARD_ROUTE,TRAINING_DASHBOARD_ROUTE } from 'constants/routes'
import { TRAININGS_GAME } from 'constants/calender'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    height: 204,
    paddingTop: theme.spacing(0),
    '& .MuiListItem-root:hover': {
      background: '#f3f3f3'
  },
    '& .MuiListItem-root': {
      padding: theme.spacing(0),
      '& .MuiListItemText-root': {
        padding: theme.spacing(1),
      },
      '& .MuiListItemText-primary': {
        '& .MuiBox-root': {
          display:'flex',
          alignItems:'start',
          fontWeight: 500,
        },
        '& .MuiChip-root': {
          fontSize: '0.875rem',
          padding: theme.spacing(0,1)
        },
        '& .MuiTypography-root': {
          color: theme.palette.card.color,
          [theme.breakpoints.only('md')]: {
            fontSize: theme.typography.pxToRem(13),
          },
          [theme.breakpoints.down('sm')]: {
            fontSize: theme.typography.pxToRem(12),
          },
        },
      },
      '& .MuiListItemText-secondary': {
        fontSize: theme.typography.pxToRem(13),
        [theme.breakpoints.down('sm')]: {
          fontSize: theme.typography.pxToRem(12),
        },
      },
    },
    '& .MuiListItemSecondaryAction-root': {
      fontWeight: 600,
      display: 'flex',
      alignItems: 'center',
      '& .MuiSvgIcon-root': {
        marginRight: theme.spacing(0.8),
      },
    },
  },
}))

const Ticker = ({ tickers,timeZone }) => {
  const classes = useStyles()
  const fromPathname = ''
  

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" component="h4">
            <Translate id="ticker.ticker" />
          </Typography>
        }
      />

      <CardContent className={classes.root}>
        {tickers &&
          tickers.map((item) => (
            <List key={item.id} disablePadding>
              <ListItem key={item.id}>
                <ListItemText
                  id={item.id}
                  primary={
                    <>
                      {hasSubscription() === 'basic' ? (
                        <Box key={item.id}>
                          <Tooltip
                            title={formatCostumDateTimeForUI(item.created_at)}
                            arrow
                          >
                            <Typography style={{ marginRight: 10 }}>
                              <Chip
                                label={timeSince(
                                  new Date(item.created_at),
                                  timeZone
                                )}
                                size="small"
                                disabled
                                color="primary"
                              />
                            </Typography>
                          </Tooltip>
                          <Typography variant="body1" component="p">
                            {item.msg}
                          </Typography>
                        </Box>
                      ) : (
                        <Box
                          component={SubLink}
                          to={
                            item.type.startsWith(TRAININGS_GAME.match)
                              ? MATCH_DASHBOARD_ROUTE.populateURLParams({
                                  matchId: item.match_id,
                                })
                              : TRAINING_DASHBOARD_ROUTE.populateURLParams({
                                  trainingId: item.training_id,
                                })
                          }
                          fromPathname={fromPathname}
                          key={item.id}
                        >
                          <Tooltip
                            title={formatCostumDateTimeForUI(item.created_at)}
                            arrow
                          >
                            <Typography style={{ marginRight: 10 }}>
                              <Chip
                                label={timeSince(
                                  new Date(item.created_at),
                                  timeZone
                                )}
                                size="small"
                                disabled
                                color="primary"
                              />
                            </Typography>
                          </Tooltip>
                          <Typography variant="body1" component="p">
                            {item.msg}
                          </Typography>
                        </Box>
                      )}
                    </>
                  }
                />
              </ListItem>
              <Divider />
            </List>
          ))}
      </CardContent>
    </Card>
  )
}

Ticker.propTypes = {
  tickers: PROP_TYPES.arrayOfObjects.isRequired,
  timeZone: PROP_TYPES.string.isRequired
}

export default connect(
  ({ auth }) => ({
    timeZone: auth.user.time_zone,
  }),
  {
    updatePlayer: playersModule.updatePlayer,
  }
)(Ticker)
