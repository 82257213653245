import React, { useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { DragLayer, DropTarget } from 'react-dnd'
import { Card } from 'components'
import { compose } from 'redux'
import Grid from '@material-ui/core/Grid'
import { Context } from '../MatchStateContext'
import Field from './Field'

// import MiniCalendarWithActions from './MiniCalendarWithActions'
// import Lineups from './Lineups'
import Roster from './Roster'
import NotInRoster from './NotInRoster'
import ConnectedSequences from './ConnectedSequences'
import PROP_TYPES from 'constants/propTypes'

const LeftPartOLd = ({
  onDrop,
  connectDropTarget,
  players,
  // lineups,
  sequences,
  handleSave,
}) => {
  const { matchState, onFieldChange } = useContext(Context)

  const inRoster = useMemo(
    () =>
      players.filter(player =>
        matchState.players
          .filter(
            p =>
              (p.in_roster && !p.participant) ||
              (p.in_roster && (p.position_x === 0 && p.position_y === 0))
          )
          .findById(player.id)
      ),
    [players, matchState.players]
  )

  const notInRoster = useMemo(
    () =>
      players.filter(player =>
        matchState.players.filter(p => !p.in_roster).findById(player.id)
      ),
    [players, matchState.players]
  )

  const fromPathname = 'Match'

  const onPlayerChange = playerId => fields => {
    onFieldChange('players', matchState.players.updateById(playerId, fields))
  }

  return (
    <>
      {/* <MiniCalendarWithActions lineups={lineups} handleSave={handleSave} /> */}
      <Card>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12} sm={12} lg={6}>
            {connectDropTarget(
              <div>
                {/* <Lineups lineups={lineups} /> */}
                <Roster
                  players={inRoster}
                  ratingEditable
                  fromPathname={fromPathname}
                  onPlayerChange={onPlayerChange}
                  handleSave={handleSave}
                  matchStatPlayers={matchState.players}
                />
              </div>
            )}
          </Grid>
          <Grid item md={6} xs={12} sm={12} lg={6}>
            <Field onDrop={onDrop} handleSave={handleSave} />
          </Grid>
        </Grid>
      </Card>
      <NotInRoster
        players={notInRoster}
        ratingEditable
        onDrop={onDrop}
        fromPathname={fromPathname}
        onPlayerChange={onPlayerChange}
        handleSave={handleSave}
      />
      <ConnectedSequences sequences={sequences} players={players} />
    </>
  )
}

LeftPartOLd.defaultProps = {
  players: [],
  // lineups: [],
  sequences: [],
  handleSave: null,
}

LeftPartOLd.propTypes = {
  onDrop: PropTypes.func.isRequired,
  connectDropTarget: PROP_TYPES.func.isRequired,
  players: PROP_TYPES.arrayOfObjects,
  // lineups: PROP_TYPES.arrayOfObjects,
  sequences: PROP_TYPES.arrayOfObjects,
  handleSave: PROP_TYPES.func,
}

const fieldTarget = {
  drop({ item: { playerId }, onDrop }) {
    onDrop(playerId)
  },
}

export default compose(
  DragLayer(monitor => ({
    item: monitor.getItem(),
  })),
  DropTarget('player', fieldTarget, targetConnect => ({
    connectDropTarget: targetConnect.dropTarget(),
  })),
  connect(({ matches: { current: { players, lineups, sequences } } }) => ({
    players,
    lineups,
    sequences,
  }))
)(LeftPartOLd)
