import React from 'react'
import { Translate } from 'react-localize-redux'
import moment from 'moment'
import styled from 'styled-components'
import { SubLink } from 'containers'
import { Card, NextCalendarEventWidget } from 'components'
import PROP_TYPES from 'constants/propTypes'
import {
  TRAININGS_CALENDAR_ROUTE,
  TRAINING_DASHBOARD_ROUTE,
} from 'constants/routes'

const CartGreen = styled(Card)`
  background: var(--secondary-green);
`

const Title = styled(NextCalendarEventWidget.Title)`
  color: white;
`

const CalendarTop = styled(NextCalendarEventWidget.CalendarTop)`
  border: 5px solid var(--dark-green);
`

const CalendarBottom = styled(NextCalendarEventWidget.CalendarBottom)`
  background: #92d0bd;
  font-size: 24px;
  color: #fff;
`

const NextSessionWidget = ({ data }) => {
  if (data) {
    const momentDate = moment(data.date)

    const trainingDateDay = momentDate && momentDate.format('DD')
    const trainingDateMonth = momentDate && momentDate.format('MMM')
    const trainingDateTime = momentDate && momentDate.format('HH.mm')

    return (
      <CartGreen>
        <NextCalendarEventWidget>
          <Title>
            <Translate id="home.next-session" />
          </Title>
          <NextCalendarEventWidget.Calendar
            to={TRAINING_DASHBOARD_ROUTE.populateURLParams({
              trainingId: data.id,
            })}
          >
            <div>
              <CalendarTop>
                <NextCalendarEventWidget.CircleNumber color="white">
                  {trainingDateDay}
                </NextCalendarEventWidget.CircleNumber>
                <NextCalendarEventWidget.CircleTitle color="white">
                  {trainingDateMonth}
                </NextCalendarEventWidget.CircleTitle>
              </CalendarTop>
              <CalendarBottom>{trainingDateTime}</CalendarBottom>
            </div>
          </NextCalendarEventWidget.Calendar>
          <SubLink to={TRAININGS_CALENDAR_ROUTE}>
            <NextCalendarEventWidget.AddButton>
              +
            </NextCalendarEventWidget.AddButton>
          </SubLink>
        </NextCalendarEventWidget>
      </CartGreen>
    )
  }

  return (
    <CartGreen className="sessionWidget">
      <NextCalendarEventWidget>
        <Title>
          <Translate id="home.next-session" />
        </Title>
        <NextCalendarEventWidget.Placeholder>
          <p className="text-white">
            <Translate id="home.plan-next-training" />
          </p>
        </NextCalendarEventWidget.Placeholder>
        <SubLink to={TRAININGS_CALENDAR_ROUTE}>
          <NextCalendarEventWidget.AddButton>
            +
          </NextCalendarEventWidget.AddButton>
        </SubLink>
      </NextCalendarEventWidget>
    </CartGreen>
  )
}

NextSessionWidget.propTypes = {
  data: PROP_TYPES.shape().isRequired,
}

export default NextSessionWidget
