import { combineActions, handleActions } from 'redux-actions'
import {
  actionsTypesGenerator,
  onRequest,
  onSuccess,
  onFailure,
} from 'utils/reduxHelpers'
import initialState from '../initialState'

const namespace = 'ARTICLE'

const FETCH_ARTICLES = actionsTypesGenerator(`FETCH_${namespace}S`)
const FETCH_ARTICLE = actionsTypesGenerator(`FETCH_${namespace}`)
const CREATE_ARTICLE = actionsTypesGenerator(`CREATE_${namespace}`)
const UPDATE_ARTICLE = actionsTypesGenerator(`UPDATE_${namespace}`)
const SHARE_ARTICLE = actionsTypesGenerator(`SHARE_${namespace}`)
const DELETE_ARTICLE = actionsTypesGenerator(`DELETE_${namespace}`)
const FETCH_ARTICLES_CATEGORIES = actionsTypesGenerator(
  `FETCH_${namespace}S_CATEGORIES`
)
const CREATE_ARTICLES_CATEGORY = actionsTypesGenerator(
  `CREATE_${namespace}S_CATEGORY`
)
const UPDATE_ARTICLES_CATEGORY = actionsTypesGenerator(
  `UPDATE_${namespace}S_CATEGORY`
)
const DELETE_ARTICLES_CATEGORY = actionsTypesGenerator(
  `DELETE_${namespace}S_CATEGORY`
)

export const fetchArticles = params => ({
  types: FETCH_ARTICLES,
  callAPI: {
    method: 'GET',
    entity: 'Articles',
    path: '/articles',
    params,
  },
})

export const fetchArticle = (id, params) => ({
  types: FETCH_ARTICLE,
  callAPI: {
    method: 'GET',
    entity: 'Article',
    path: `/articles/${id}`,
    params,
  },
})

export const createArticle = data => ({
  types: CREATE_ARTICLE,
  callAPI: {
    method: 'POST',
    entity: 'Article',
    path: `/articles`,
    data,
  },
})

export const updateArticle = (id, data) => ({
  types: UPDATE_ARTICLE,
  callAPI: {
    method: 'PUT',
    entity: 'Article',
    path: `/articles/${id}`,
    data,
  },
})

export const shareArticle = (id, paramsString) => ({
  types: SHARE_ARTICLE,
  callAPI: {
    method: 'SHARE',
    entity: 'Article',
    path: `/articles/${id}/share`,
    paramsString,
  },
})

export const deleteArticle = id => ({
  types: DELETE_ARTICLE,
  callAPI: {
    method: 'DELETE',
    entity: 'Article',
    path: `/articles/${id}`,
  },
  payload: { id },
})

export const fetchArticlesCategories = params => ({
  types: FETCH_ARTICLES_CATEGORIES,
  callAPI: {
    method: 'GET',
    entity: 'Articles Categories',
    path: '/article_categories',
    params,
  },
})

export const createArticlesCategory = data => ({
  types: CREATE_ARTICLES_CATEGORY,
  callAPI: {
    method: 'POST',
    entity: 'Articles Category',
    path: '/article_categories',
    data,
  },
})

export const updateArticlesCategory = (id, data) => ({
  types: UPDATE_ARTICLES_CATEGORY,
  callAPI: {
    method: 'PUT',
    entity: 'Articles Category',
    path: `/article_categories/${id}`,
    data,
  },
  payload: { id },
})

export const deleteArticlesCategory = id => ({
  types: DELETE_ARTICLES_CATEGORY,
  callAPI: {
    method: 'DELETE',
    entity: 'Articles Category',
    path: `/article_categories/${id}`,
  },
  payload: { id },
})

export default handleActions(
  {
    [combineActions(
      FETCH_ARTICLES.request,
      FETCH_ARTICLE.request,
      CREATE_ARTICLE.request,
      UPDATE_ARTICLE.request,
      DELETE_ARTICLE.request,
      FETCH_ARTICLES_CATEGORIES.request,
      CREATE_ARTICLES_CATEGORY.request,
      UPDATE_ARTICLES_CATEGORY.request,
      DELETE_ARTICLES_CATEGORY.request
    )]: onRequest,
    [FETCH_ARTICLES.success]: (state, { response: { data = [] } }) =>
      onSuccess({
        ...state,
        items: data,
      }),
    [combineActions(FETCH_ARTICLE.success, UPDATE_ARTICLE.success)]: (
      state,
      { response: { data = {} } }
    ) => onSuccess({ ...state, current: { ...state.current, ...data } }),
    [CREATE_ARTICLE.success]: (state, { response: { data = {} } }) =>
      onSuccess({ ...state, items: [data, ...state.items] }),
    [DELETE_ARTICLE.success]: (state, { id }) =>
      onSuccess({ ...state, items: state.items.filterById(id) }),
    [FETCH_ARTICLES_CATEGORIES.success]: (
      state,
      { response: { data: articlesCategories = [] } }
    ) =>
      onSuccess({
        ...state,
        current: { ...state.current, articlesCategories },
      }),
    [CREATE_ARTICLES_CATEGORY.success]: (
      state,
      { response: { data: articlesCategory = {} } }
    ) =>
      onSuccess({
        ...state,
        current: {
          ...state.current,
          articlesCategories: [
            ...state.current.articlesCategories,
            articlesCategory,
          ],
        },
      }),
    [UPDATE_ARTICLES_CATEGORY.success]: (
      state,
      { response: { data: articlesCategory = {} }, id }
    ) =>
      onSuccess({
        ...state,
        current: {
          ...state.current,
          articlesCategories: state.current.articlesCategories.updateById(
            +id,
            articlesCategory
          ),
        },
      }),
    [DELETE_ARTICLES_CATEGORY.success]: (state, { id }) =>
      onSuccess({
        ...state,
        current: {
          ...state.current,
          articlesCategories: state.current.articlesCategories.filterById(+id),
        },
      }),
    [combineActions(
      FETCH_ARTICLES.failure,
      FETCH_ARTICLE.failure,
      CREATE_ARTICLE.failure,
      UPDATE_ARTICLE.failure,
      DELETE_ARTICLE.failure,
      FETCH_ARTICLES_CATEGORIES.failure,
      CREATE_ARTICLES_CATEGORY.failure,
      UPDATE_ARTICLES_CATEGORY.failure,
      DELETE_ARTICLES_CATEGORY.failure
    )]: onFailure,
  },
  initialState.articles
)
