import React, { useEffect, useState } from "react"
import { Translate } from "react-localize-redux"
import {
  Dialog,
  Button,
  DialogContent,
  TextField,
  DialogActions,
  DialogTitle,
  MenuItem,
  DialogContentText,
} from "@material-ui/core"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { players as playersModule } from "../../../../redux/modules"

const TransferDestinationDialog = ({
  playerUuid,
  open,
  onClose,
  onSubmit,
  fetchTransferDestinations,
}) => {
  const [destinationTeams, setDestinationTeams] = useState([])
  const [teamUuid, setTeamUuid] = useState(null)

  useEffect(() => {
    if (!open) {
      return
    }

    // reset
    setDestinationTeams([])
    setTeamUuid(null)

    fetchTransferDestinations(playerUuid)
      .then(({ response: { data } }) => {
        setDestinationTeams(data)
      })
  }, [open])

  const handleTeamChange = (e) => {
    setTeamUuid(e.target.value)
  }

  const handleSubmit = () => {
    onSubmit(teamUuid)
  }

  // @todo: #transfer add translations
  return (
    <Dialog open={ open } onClose={ onClose }>
        <DialogTitle>
          <Translate id="movePlayers.transfer-player"/>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            <Translate id="movePlayers.choose-the-team-you-want-to-transfer-the-player-to"/>
          </DialogContentText>
          <TextField
            value={ teamUuid }
            onChange={ handleTeamChange }
            select
            label="Team"
            margin="normal"
            required
          >
            { destinationTeams.map((t) => (
              <MenuItem key={ t.uuid } value={ t.uuid }>
                { t.name }
              </MenuItem>
            )) }
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={ onClose } variant="contained">
            <Translate id="button.close"/>
          </Button>
          <Button
            disabled={ !teamUuid }
            onClick={ handleSubmit }
            color="primary"
            variant="contained"
          >
            <Translate id="button.save"/>
          </Button>
        </DialogActions>
      </Dialog>
  )
}

TransferDestinationDialog.propTypes = {
  playerUuid: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  fetchTransferDestinations: PropTypes.func.isRequired,
}

export default connect(
  null,
  {
    fetchTransferDestinations: playersModule.fetchPlayerTransferDestinations
  },
)(TransferDestinationDialog)
