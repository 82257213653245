import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Joi from 'joi'
import { Translate } from 'react-localize-redux'
import { reduxForm, Field } from 'redux-form'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import { OwnGrid, Card, LinkBack, Input, ReactSelect } from 'components'
import createJoiValidator from 'utils/createJoiValidator'
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
} from 'utils/formatCard'
import { setCountry as setCountryLocal, getCountry } from 'utils/localstorage'
import countries from 'constants/countries'

const useStyles = makeStyles(theme => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
    },
    li: {
      listStyle: 'none',
    },
  },
  heroContent: {
    padding: theme.spacing(0, 0, 4),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === 'dark'
        ? theme.palette.grey[700]
        : theme.palette.grey[200],
    margin: theme.spacing(0, 0, 2, 0),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    width: '100%',
    margin: theme.spacing(0, 0, 2, 0),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  backButton: {
    float: 'right',
  },
  textRight: {
    textAlign: 'right',
    width: '100%',
  },
}))

const validationSchema = Joi.object().keys({
  cardnumber: Joi.string()
    .max(24)
    .required(),
  expiry: Joi.string()
    .max(10)
    .required(),
  cvc: Joi.number().required(),
  quantity: Joi.number()
    .min(1)
    .required(),
  plan: Joi.string().required(),
  city: Joi.string().required(),
  country: Joi.string().required(),
  address_1: Joi.string().required(),
  first_name: Joi.string().required(),
  last_name: Joi.string().required(),
})

const loadScript = (src, apiKey) => {
  if (!window.document.getElementById('stripe-script')) {
    const s = window.document.createElement('script')
    s.id = 'stripe-script'
    s.type = 'text/javascript'
    s.src = src
    s.onload = () => {
      window.Stripe.setPublishableKey(apiKey)
    }
    window.document.body.appendChild(s)
  }
}

const ensureCcNumberFormatting = value => formatCreditCardNumber(value)
const ensureCcExpiryFormatting = value => formatExpirationDate(value)
const ensureCcCvcFormatting = value => formatCVC(value)

const UpgradeTeamForm = ({
  planName,
  fromPath,
  edit,
  handleSubmit,
  apiKey,
  teamPlan,
  change,
  isCountryValid,
  changeCountyValid,
}) => {
  const [stripeLoaded, setStripeLoaded] = useState(false)
  const [quantityAdded, setQuantity] = useState(1)

  useEffect(() => {
    const fetchData = () => {
      loadScript('https://js.stripe.com/v2/', apiKey)
      setStripeLoaded(true)
    }
    fetchData()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const onChangeQty = qty => {
    if (qty.target.valueAsNumber) {
      setQuantity(qty.target.valueAsNumber)
    } else {
      setQuantity(1)
    }
  }
  const [country, setCountry] = React.useState(countries[0])
  setCountryLocal(country.value)
  const changeCountry = value => {
    setCountry(value)
    setCountryLocal(value.value)
    if (!getCountry() || getCountry() === '') {
      changeCountyValid(true)
    } else {
      changeCountyValid(false)
    }
  }
  const selectedPlanName =
    planName.selected === 'annual' ? `${planName.value}_annual` : planName.value
  change('plan', selectedPlanName)

  const classes = useStyles()

  return (
    <Translate>
      {({ translate }) => (
        <Card>
          <Card.Title>
            {fromPath && (
              <LinkBack to={fromPath.value} title={fromPath.title} />
            )}
          </Card.Title>

          <Container
            maxWidth="md"
            component="main"
            className={classes.heroContent}
          >
            <Typography
              component="h1"
              variant="h3"
              align="center"
              color="textPrimary"
              gutterBottom
            >
              {/* <Translate id="checkout.headline_checkout" /> */}
            </Typography>
            <Typography
              variant="h6"
              align="center"
              color="textSecondary"
              component="p"
            >
              <Translate id="checkout.subheadline_checkout" />
            </Typography>
          </Container>

          <Container maxWidth="lg">
            <Grid container spacing={2}>
              <Grid container item md={8}>
                <Paper className={classes.paper}>
                  <p>
                    <strong>
                      <Translate id="checkout.payment-details" />
                    </strong>
                  </p>
                  {stripeLoaded && (
                    <form>
                      <OwnGrid.Row>
                        <OwnGrid.Col xs={12} md={6}>
                          <Field
                            label={translate('checkout.creditcard')}
                            component={Input}
                            name="cardnumber"
                            normalize={ensureCcNumberFormatting}
                            placeholder="xxxx xxxx xxxx xxxx"
                            type="tel"
                          />
                        </OwnGrid.Col>
                        <OwnGrid.Col xs={12} md={3}>
                          <Field
                            label={translate('checkout.month-year')}
                            name="expiry"
                            normalize={ensureCcExpiryFormatting}
                            placeholder={translate('checkout.mm-yy')}
                            type="tel"
                            component={Input}
                          />
                        </OwnGrid.Col>
                        <OwnGrid.Col xs={12} md={3}>
                          <Field
                            label="CVV"
                            name="cvc"
                            normalize={ensureCcCvcFormatting}
                            type="tel"
                            placeholder="xxx"
                            component={Input}
                          />
                        </OwnGrid.Col>
                        <Field
                          name="plan"
                          value={planName.value}
                          component={Input}
                          type="hidden"
                        />
                      </OwnGrid.Row>
                    </form>
                  )}
                  <br />
                  <p>
                    <strong>
                      <Translate id="checkout.billing-details" />
                    </strong>
                  </p>

                  <Field
                    name="first_name"
                    placeholder={translate('form.first-name')}
                    component={Input}
                  />
                  <Field
                    name="last_name"
                    placeholder={translate('form.last-name')}
                    component={Input}
                  />
                  <Field
                    name="address_1"
                    placeholder={translate('form.address-name')}
                    component={Input}
                  />
                  <Field
                    name="city"
                    placeholder={translate('form.city')}
                    component={Input}
                  />
                  <Field
                    class="form-group"
                    placeholder={translate('form.country')}
                    component={ReactSelect}
                    suggestions={countries}
                    isMulti={false}
                    isEveryOne={false}
                    props={{
                      value: country,
                      onChange: changeCountry,
                    }}
                  />
                  {isCountryValid ? (
                    <React.Fragment>
                      <div
                        style={{
                          color: '#dc3545',
                          fontSize: '80%',
                          width: '100%',
                          marginTop: '.25rem',
                        }}
                      >
                        {'"country" is required'}
                      </div>
                      <br />
                    </React.Fragment>
                  ) : (
                    <br />
                  )}
                </Paper>
              </Grid>
              <Grid container item xs={4}>
                <Paper className={classes.paper}>
                  {/* eslint-disable  */}
                  <Link
                    href="#"
                    className={classes.backButton}
                    color="primary"
                    variant="body2"
                    onClick={edit}
                  >
                    <Translate id="button.change" />
                  </Link>
                  {/* eslint-disable  */}
                  <p>
                    <strong>
                      <Translate id="checkout.your-order" />
                    </strong>
                  </p>

                  <Grid container spacing={0}>
                    <Grid container item xs={6}>
                      {planName.label} {planName.selected}
                    </Grid>
                    <Grid container item xs={6}>
                      <p className={classes.textRight}>
                        {planName[planName.selected]} {planName.currency}
                      </p>
                    </Grid>
                  </Grid>

                  {teamPlan && (
                    <Grid container spacing={0}>
                      <Grid container item xs={8}>
                        <Translate id="checkout.amount" />:
                      </Grid>
                      <Grid container item xs={4}>
                        <Field
                          name="quantity"
                          onChange={e => onChangeQty(e)}
                          type="number"
                          component={Input}
                          size="sm"
                        />
                      </Grid>
                    </Grid>
                  )}

                  {/* <Grid container spacing={0}>
                    <Grid container item xs={6}>
                      <Translate id="checkout.14-days" />:
                    </Grid>
                    <Grid container item xs={6}>
                      <p className={classes.textRight}>
                        {parseFloat(
                          quantityAdded * planName[planName.selected]
                        ).toFixed(2)}{' '}
                        {planName.currency}
                      </p>
                    </Grid>
                  </Grid> */}
                  <hr />
                  {/* <Grid container spacing={0}>
                    <Grid container item xs={6}>
                      <strong>
                        <Translate id="checkout.today-billed" />:
                      </strong>
                    </Grid>
                    <Grid container item xs={6}>
                      <p className={classes.textRight}>
                        <strong>0.00 {planName.currency}</strong>
                      </p>
                    </Grid>
                  </Grid> */}

                  <Grid container spacing={0}>
                    <Grid container item xs={6}>
                      <strong>
                        <Translate id="checkout.today-billed" />:
                      </strong>
                    </Grid>
                    <Grid container item xs={6}>
                      <p className={classes.textRight}>
                        {parseFloat(
                          quantityAdded * planName[planName.selected]
                        ).toFixed(2)}{' '}
                        {planName.currency}
                      </p>
                    </Grid>
                  </Grid>

                  <Button
                    fullWidth
                    onClick={handleSubmit}
                    variant="contained"
                    color="primary"
                  >
                    {`PAY NOW`}
                    {/* <Translate id="button.start-now-pay-later" /> */}
                  </Button>
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </Card>
      )}
    </Translate>
  )
}

UpgradeTeamForm.propTypes = {
  form: PropTypes.string.isRequired, // eslint-disable-line
  apiKey: PropTypes.string.isRequired,
  planName: PropTypes.object.isRequired, // eslint-disable-line
  onSubmit: PropTypes.func.isRequired, // eslint-disable-line
  edit: PropTypes.func.isRequired, // eslint-disable-line
  handleSubmit: PropTypes.func.isRequired,
  fromPath: PropTypes.shape({
    value: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  change: PropTypes.func.isRequired,
  teamPlan: PropTypes.bool,
  isCountryValid: PropTypes.bool.isRequired,
  changeCountyValid: PropTypes.func.isRequired,
}

UpgradeTeamForm.defaultProps = {
  teamPlan: true,
}

export default reduxForm({
  enableReinitialize: true,
  validate: createJoiValidator(validationSchema),
  initialValues: { quantity: '1' },
})(UpgradeTeamForm)
