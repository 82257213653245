import React from 'react'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import moment from 'moment'
import { connect } from 'react-redux'
import { PlayerCreateEditForm } from 'containers'
import { players } from 'redux/modules'
import filterObject from 'utils/filterObject'
import { createSubUrl } from 'utils/urlConstructor'
import PROP_TYPES from 'constants/propTypes'
import { TEAM_PLAYER_DASHBOARD_ROUTE } from 'constants/routes'
import { PLAYER_SUB_POSITIONS } from 'constants/player'

const PlayerEditContainer = ({
  match: {
    params: { playerId },
  },
  match,
  history,
  player,
  updatePlayer,
}) => {
  const linkBack = createSubUrl(
    match,
    TEAM_PLAYER_DASHBOARD_ROUTE.populateURLParams({ playerId })
  )

  const fromPath = {
    value: linkBack,
    title: `${player.first_name || ''} ${player.last_name || ''}`,
  }

  const goBack = () => history.push(fromPath.value)

  const onSubmit = ({ number, ...val }) => {
    const values = val
    values.position = localStorage.getItem('position')
    values.sub_position = localStorage.getItem('subposition')
    updatePlayer(playerId, { ...values, number: +number }).then(goBack)
  }

  const position = 'midfield'

  const birthday =
    player.birthday && moment(player.birthday).format('YYYY-MM-DD')

  const avatar = player.avatar && player.avatar.full_url

  const initialValues = {
    position,
    sub_position: PLAYER_SUB_POSITIONS[position].find(Boolean).value,
    foot: 'r',
    gender: 'm',
    ...filterObject({ ...player, birthday, avatar }, Boolean),
  }
  React.useEffect(() => {
    return function cleanup() {
      localStorage.removeItem('position')
      localStorage.removeItem('subposition')
    }
  }, [])

  return (
    <Translate>
      {({ translate }) => (
        <PlayerCreateEditForm
          form="playerEdit"
          initialValues={initialValues}
          caption={translate('team.edit-player')}
          fromPath={fromPath}
          onSubmit={onSubmit}
        />
      )}
    </Translate>
  )
}

PlayerEditContainer.propTypes = {
  match: PROP_TYPES.match.isRequired,
  history: PROP_TYPES.history.isRequired,
  player: PropTypes.shape().isRequired,
  updatePlayer: PropTypes.func.isRequired,
}

export default connect(
  ({ players: { current: player } }) => ({ player }),
  { updatePlayer: players.updatePlayer }
)(PlayerEditContainer)
