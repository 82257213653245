import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import {
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  Checkbox,
  Grid,
  Box
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { AvatarMuiEditor, DatePicker } from 'components'

import {
  handleTextField,
  handleCheckBox,
  handleDateChange,
  checkError,
  checkTextFieldError,
} from 'utils/validate'
import { formatDateFromServer } from 'utils/formatDate'
import CircularSpinner from 'components/loader/CircularSpinner'
import {
  PLAYER_TAGS,
  PLAYER_POSITIONS,
  PLAYER_SUB_POSITIONS,
  FOOT_OPTIONS,
  GENDER_OPTIONS,
} from 'constants/player'

const PlayerEditDialog = ({
  initialValues,
  open,
  handleClose,
  updatePlayer,
  updatePlayerAvatar,
  isPlayerDashboard,
  dateFormat,
  isLoading
}) => {

  const tempPosition = initialValues.position || 'goalkeeper'
  const [playerData, setPlayerData] = React.useState({
    ...initialValues,
    captain:initialValues?.captain ? 1 : 0,
    position: tempPosition,
    sub_position: initialValues.sub_position
      ? initialValues.sub_position
      : PLAYER_SUB_POSITIONS[tempPosition][0].value,
  })

  const SUP_POSITIONS = PLAYER_SUB_POSITIONS[playerData.position]

  const handleSave = () => {
    if (!playerData.email_1 || playerData.email_1.length === 0)
      delete playerData.email_1_error

    if (!playerData.phone || playerData.phone.length === 0)
      delete playerData.phone_error

      if (!playerData.city || playerData.city.length === 0)
      delete playerData.city_error
      
    if (!playerData.zip || playerData.zip.length === 0)
      delete playerData.zip_error

    if (!playerData.street || playerData.street.length === 0)
      delete playerData.street_error

    const isError = checkError(playerData)
    if (isError) {
      setPlayerData({ ...playerData, [isError]: true })

      return
    }

    const isErrorForFields = checkTextFieldError(playerData, [
      'first_name',
    ])
    if (isErrorForFields) {
      setPlayerData({ ...playerData, [isErrorForFields]: true })

      return
    }

    const body = {
      ...playerData,
      birthday: moment(playerData.birthday, 'YYYY-MM-DD').isValid()
        ? formatDateFromServer(playerData.birthday)[0]
        : null,
        joining_date: moment(playerData.joining_date, 'YYYY-MM-DD').isValid()
        ? formatDateFromServer(playerData.joining_date)[0]
        : null,
    }
    delete body.skills;
    updatePlayer(playerData.id, body)
      .then(res => {
        const afterResponse = {
          ...playerData,
          avatar: res.response.data.avatar,
        }
        setPlayerData(afterResponse)
        handleClose()
      })
  }

  const handlePositionWithSubPosition=(e)=>{
    setPlayerData({
      ...playerData,
      position: e.target.value,
      sub_position: PLAYER_SUB_POSITIONS[e.target.value][0].value,
    })
  }

  const handleDialogClose = () => {
    setPlayerData({
      ...initialValues,
      position: tempPosition,
      sub_position: initialValues.sub_position
        ? initialValues.sub_position
        : PLAYER_SUB_POSITIONS[tempPosition][0].value,
    })
    handleClose()
  }

  return (
    <div>
      <Translate>
        {({ translate }) => (
          <Dialog
            open={open}
            onClose={handleDialogClose}
            aria-labelledby="form-dialog-title"
          >
            {isLoading &&
                <CircularSpinner loadingcircular={isLoading}/>
            }
            <DialogTitle>
              <Translate id="player.edit" />
            </DialogTitle>
            <DialogContent dividers>
              {isPlayerDashboard && (
                <Box className="profileContent">
                  <AvatarMuiEditor
                    data={playerData}
                    updateImage={updatePlayerAvatar}
                  />
                </Box>
              )}
              <Grid container spacing="2">
                <Grid item xs={6}>
                  <TextField
                    label={translate('form.first-name')}
                    variant="outlined"
                    autoFocus
                    id="first_name"
                    name="first_name"
                    size="small"
                    value={playerData.first_name}
                    fullWidth
                    onChange={(e) =>
                      handleTextField(e, playerData, setPlayerData)
                    }
                    error={playerData.first_name_error}
                    helperText={
                      playerData.first_name_error ? (
                        <Translate id="player.validation.first-name" />
                      ) : (
                        ''
                      )
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label={translate('form.last-name')}
                    variant="outlined"
                    autoFocus
                    id="last_name"
                    name="last_name"
                    size="small"
                    value={playerData.last_name}
                    fullWidth
                    onChange={(e) =>
                      handleTextField(e, playerData, setPlayerData)
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <DatePicker
                    disableFuture
                    disableToolbar={false}
                    required
                    label={<Translate id="event.birthday" />}
                    selectedValue={playerData.birthday}
                    onDateChange={(date) =>
                      handleDateChange(
                        date,
                        'birthday',
                        playerData,
                        setPlayerData
                      )
                    }
                    openTo="date"
                    margin='none'
                    isEditMode
                    format={dateFormat}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl variant="outlined">
                    <Select
                      labelId="gender"
                      id="gender"
                      name="gender"
                      value={playerData.gender || 'm'}
                      onChange={(e) =>
                        handleTextField(e, playerData, setPlayerData)
                      }
                    >
                      {GENDER_OPTIONS.map((gender) => (
                        <MenuItem value={gender.value} key={gender.value}>
                          {gender.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label={translate('form.e-mail-1')}
                    variant="outlined"
                    autoFocus
                    id="email_1"
                    name="email_1"
                    size="small"
                    value={playerData.email_1}
                    fullWidth
                    onChange={(e) =>
                      handleTextField(e, playerData, setPlayerData)
                    }
                    error={playerData.email_1_error_valid}
                    helperText={
                      playerData.email_1_error_valid ? (
                        <Translate id="player.validation.email-valid" />
                      ) : (
                        <></>
                      )
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    autoFocus
                    id="phone"
                    name="phone"
                    size="small"
                    label={translate('form.phone-number')}
                    type="tel"
                    value={playerData.phone}
                    fullWidth
                    onChange={(e) =>
                      handleTextField(e, playerData, setPlayerData)
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    autoFocus
                    id="street"
                    name="street"
                    size="small"
                    label={translate('form.street')}
                    value={playerData.street}
                    fullWidth
                    onChange={(e) =>
                      handleTextField(e, playerData, setPlayerData)
                    }
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    autoFocus
                    id="zip"
                    name="zip"
                    size="small"
                    label={translate('form.postal-code')}
                    value={playerData.zip}
                    fullWidth
                    onChange={(e) =>
                      handleTextField(e, playerData, setPlayerData)
                    }
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    autoFocus
                    id="city"
                    name="city"
                    label={translate('form.city')}
                    size="small"
                    value={playerData.city}
                    fullWidth
                    onChange={(e) =>
                      handleTextField(e, playerData, setPlayerData)
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <Translate id="player.player-info" />
                </Grid>

                <Grid item xs={6}>
                  <FormControl variant="outlined" size="small">
                    <Select
                      labelId="Position"
                      id="position"
                      name="position"
                      value={playerData.position || 'goalkeeper'}
                      onChange={(e) => handlePositionWithSubPosition(e)}
                    >
                      {PLAYER_POSITIONS.map((position) => (
                        <MenuItem value={position.value} key={position.value}>
                          {position.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <FormControl variant="outlined" size="small">
                    <Select
                      labelId="Sub position"
                      id="sub_position"
                      name="sub_position"
                      value={playerData.sub_position}
                      onChange={(e) =>
                        handleTextField(e, playerData, setPlayerData)
                      }
                    >
                      {SUP_POSITIONS.map((position) => (
                        <MenuItem value={position.value} key={position.value}>
                          {position.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    label={translate('form.number')}
                    variant="outlined"
                    autoFocus
                    id="number"
                    name="number"
                    size="small"
                    defaultValue={playerData.number}
                    fullWidth
                    type="number"
                    onChange={(e) =>
                      handleTextField(e, playerData, setPlayerData)
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl variant="outlined" size="small">
                    <Select
                      labelId="Foot"
                      name="foot"
                      id="foot"
                      value={playerData.foot}
                      onChange={(e) =>
                        handleTextField(e, playerData, setPlayerData)
                      }
                    >
                      {FOOT_OPTIONS.map((foot) => (
                        <MenuItem value={foot.value} key={foot.value}>
                          {foot.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl variant="outlined" size="small">
                    <Select
                      labelId="Tag"
                      name="tag"
                      id="tag"
                      value={playerData.tag}
                      onChange={(e) =>
                        handleTextField(e, playerData, setPlayerData)
                      }
                    >
                      {PLAYER_TAGS.map((tag) => (
                        <MenuItem value={tag.value} key={tag.value}>
                          {tag.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <DatePicker
                    disableFuture
                    disableToolbar={false}
                    required
                    label={<Translate id="player.joining-date" />}
                    selectedValue={playerData.joining_date}
                    onDateChange={(date) =>
                      handleDateChange(
                        date,
                        'joining_date',
                        playerData,
                        setPlayerData
                      )
                    }
                    openTo="date"
                    margin='none'
                    isEditMode
                    format={dateFormat}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={playerData.captain === 1 || playerData.captain}
                        onChange={(e) =>
                          handleCheckBox(e, playerData, setPlayerData)
                        }
                        name="captain"
                        id="captain"
                      />
                    }
                    label="Team Captain"
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={handleDialogClose}>
                <Translate id="button.cancel" />
              </Button>
              <Button color="primary" variant="contained" onClick={handleSave}>
                <Translate id="button.save" />
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Translate>
    </div>
  )
}

PlayerEditDialog.defaultProps = {
  isPlayerDashboard: false,
}

PlayerEditDialog.propTypes = {
  initialValues: PropTypes.shape().isRequired,
  handleClose: PropTypes.func.isRequired,
  updatePlayer: PropTypes.func.isRequired,
  updatePlayerAvatar: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  isPlayerDashboard: PropTypes.bool,
  dateFormat: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
}

export default connect(
  ({ auth,players }) => ({
    dateFormat: auth.user.datetime_format,
    isLoading:players.isLoading
  }),
  null
)(PlayerEditDialog)
