/* eslint-disable react/no-multi-comp */
import React, { useState, useRef } from 'react'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import {
  Card,
  Typography,
  Box,
  Tabs,
  Tab,
  Divider,
  Grid,
  Button,
  Hidden,
} from '@material-ui/core'
import styled from 'styled-components'
import { TextArea } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import hasSubscription from 'utils/hasSubscription'
import { players as playersModule } from 'redux/modules'
import { connect } from 'react-redux'
import AddEditPlayerStrengthWeakness from './AddEditPlayerStrengthWeakness'
import PlayerAnalysisList from './PlayerAnalysisList'

const CardMui = styled(Card)`
  height: 100%;
`
const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiGrid-grid-md-6': {
      maxWidth: '49.2%',
      flexBasis: '49.2%',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
        flexBasis: '100%',
      },
    },
  },
}))

const TabPanel = (props) => {
  // eslint-disable-next-line
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}
const PlayerDevelopmentTracking = ({
  onChange,
  development_tracking: developmentTracking,
  createPlayerAnalysis,
  updatePlayerAnalysis,
  deletePlayerAnalysis,
  fetchTeamPlayer,
  player,
  handleSave,
}) => {
  const [updateID, setUpdateID] = React.useState(0)
  const [note, setNote] = React.useState(null)
  const [open, setOpen] = useState(false)
  const [selectedValue, selectedValueChange] = useState('strength')
  const DBStrength = player.strengths_weaknesses
    .filter((s) => s.type === 'strength')
    .map((st) => {
      return { id: st.id, name: st.note }
    })
  const DBWeakness = player.strengths_weaknesses
    .filter((w) => w.type === 'weakness')
    .map((weak) => {
      return { id: weak.id, name: weak.note }
    })
  const [strengths, setStrengths] = useState(DBStrength)
  const [weaknesses, setWeaknesses] = useState(DBWeakness)
  const nameRef = useRef()
  const classes = useStyles()

  const onFieldChange = ({ target: { value } }) =>
    onChange('development_tracking', value)

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }
  const [value, setValue] = React.useState(
    hasSubscription() !== 'basic' ? 1 : 0
  )

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const handleClickOpenfor = (value1) => {
    selectedValueChange(value1)
    setNote('')
    setUpdateID(0)
    handleClickOpen()
  }
  const handleClickOpenEdit = (analysisType, analysisNote, id) => {
    setNote(analysisNote)
    selectedValueChange(analysisType)
    setUpdateID(id)
    handleClickOpen()
  }
  const deleteRecord = (id) => {
    const strengthItems = strengths.filter((strength) => strength.id !== id)
    const weaknessItems = weaknesses.filter((weakness) => weakness.id !== id)
    setStrengths(strengthItems)
    setWeaknesses(weaknessItems)
    deletePlayerAnalysis(id)
  }

  return (
    <>
      <CardMui className={classes.root}>
        <Tabs value={value} onChange={handleChange}>
          {hasSubscription() !== 'basic' && (
            <Tab
              label={<Translate id="team.development-tracking" />}
              {...a11yProps(0)}
            />
          )}
          <Tab
            label={<Translate id="team.player-analysis" />}
            {...a11yProps(hasSubscription() !== 'basic' ? 1 : 0)}
          />
        </Tabs>
        <Divider />
        {hasSubscription() !== 'basic' && (
          <TabPanel value={value} index={0}>
            <Grid container spacing={2}>
              <TextArea
                value={developmentTracking || ''}
                onChange={onFieldChange}
                className="player-development-tracking"
              />
              <Button
                variant="contained"
                color="primary"
                component={Box}
                my={1}
                onClick={handleSave}
              >
                <Translate id="button.save" />
              </Button>
            </Grid>
          </TabPanel>
        )}
        <TabPanel value={value} index={hasSubscription() !== 'basic' ? 1 : 0}>
          <AddEditPlayerStrengthWeakness
            open={open}
            selectedValue={selectedValue}
            onClose={handleClose}
            createPlayerAnalysis={createPlayerAnalysis}
            player={{ ...player }}
            nameRef={nameRef}
            updatePlayerAnalysis={updatePlayerAnalysis}
            fetchTeamPlayer={fetchTeamPlayer}
            setStrengths={setStrengths}
            setWeaknesses={setWeaknesses}
            updateID={updateID}
            note={note}
            setNote={setNote}
          />
          <Grid container spacing={2}>
            <PlayerAnalysisList
              entities={strengths}
              handleClickOpenEdit={handleClickOpenEdit}
              handleClickOpenfor={handleClickOpenfor}
              deleteRecord={deleteRecord}
              label={<Translate id="team.strengths" />}
              type="strength"
            />
            <Hidden smDown>
              <Divider
                orientation="vertical"
                flexItem
                style={{ marginLeft: 2, marginRight: 2 }}
              />
            </Hidden>
            <Hidden smUp>
              <Divider
                style={{ marginTop: 10, marginBottom: 10, width: '100%' }}
              />
            </Hidden>
            <PlayerAnalysisList
              entities={weaknesses}
              handleClickOpenEdit={handleClickOpenEdit}
              handleClickOpenfor={handleClickOpenfor}
              deleteRecord={deleteRecord}
              label={<Translate id="team.weaknesses" />}
              type="weakness"
            />
          </Grid>
        </TabPanel>
      </CardMui>
    </>
  )
}

PlayerDevelopmentTracking.defaultProps = {
  development_tracking: '',
  nameRef: '',
  handleAnalysisSave: () => 0,
}

PlayerDevelopmentTracking.propTypes = {
  onChange: PropTypes.func.isRequired,
  handleAnalysisSave: PropTypes.func,
  createPlayerAnalysis: PropTypes.func.isRequired,
  updatePlayerAnalysis: PropTypes.func.isRequired,
  deletePlayerAnalysis: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  development_tracking: PropTypes.string,
  nameRef: PropTypes.string,
  fetchTeamPlayer: PropTypes.func.isRequired,
  player: PropTypes.shape().isRequired,
}

export default connect(
  ({
    router: {
      location: { fromUrl, fromPathname },
    },
    players,
  }) => ({
    fromUrl,
    fromPathname,
    player: players.current,
  }),
  {
    createPlayerAnalysis: playersModule.createPlayerAnalysis,
    updatePlayerAnalysis: playersModule.updatePlayerAnalysis,
    deletePlayerAnalysis: playersModule.deletePlayerAnalysis,
  }
)(PlayerDevelopmentTracking)
