import React, { useState } from 'react'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import { DroppableArea } from 'components'
import PlayersList from './PlayersList'

const EmptyArea = styled(Grid)`
  > div {
    min-height: 80px;
    margin-bottom: 0;
    line-height: 17px;
    letter-spacing: 0.1px;
    font-weight: 300;
    color: var(--text-dark);
  }

  > div > div:first-child {
    margin-bottom: 5px;
    color: var(--secondary-green);
  }
`

const Container = styled(Grid)`
  flex-grow: 1;
  padding: 5px;
  border: 1px solid #ededed;
  background: #fff;
  border-radius: 8px;
  box-shadow: 14px 5px 35px 0 rgba(0, 0, 0, 0.08);

  > div {
    > div:nth-child(2) {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -5px;

      > div {
        flex: 0 0 25%;
        max-width: 25%;
        margin-top: -10px;
        padding: 0 5px;
      }
    }
  }
  @media (max-width: 640px) {
    > div {
      > div:nth-child(2) {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -5px;

        > div {
          flex: 0 0 100%;
          max-width: 100%;
          margin-top: -10px;
          padding: 0 5px;
        }
      }
    }
  }
`

const Summary = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;

  > span {
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.14px;
    color: var(--secondary-green);
  }

  > div {
    ${({ theme }) => theme.flexCenter};
    margin-right: 10px;
    text-align: center;
    width: 40px;
    height: 40px;
    line-height: 38px !important;
    border: 1px solid #ededed;
    border-radius: 50%;
    font-size: 19px;
    line-height: 17px;
    letter-spacing: 0.13px;
    color: var(--text-dark);
  }
`
const TrainingNotAvailable = styled.div`
  background: #ededed;
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  font-size: 1.1rem;
  text-align: center;
  color: #898989;
`
const NotAvailable = ({
  players,
  changeIsSelectedById,
  isAnyPlayerSelected,
}) => {
  const [isOpen, changeIsOpen] = useState(true)

  const toggleOpen = () => changeIsOpen(!isOpen)

  if (!players.length) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TrainingNotAvailable>
            <Translate id="training.not-available" />
          </TrainingNotAvailable>
        </Grid>

        <EmptyArea item xs={12}>
          <DroppableArea relation="notAvailable">
            <>
              <span>
                <Translate id="training.choose-player" />
              </span>
            </>
          </DroppableArea>
        </EmptyArea>
      </Grid>
    )
  }

  return (
    <Grid>
      <Container>
        <PlayersList
          relation="notAvailable"
          players={isOpen ? players : []}
          changeIsSelectedById={changeIsSelectedById}
          isAnyPlayerSelected={isAnyPlayerSelected}
        >
          <Summary onClick={toggleOpen} role="presentation">
            <div>{players.length}</div>
            <span>
              <Translate id="training.not-available" />
            </span>
          </Summary>
        </PlayersList>
      </Container>
    </Grid>
  )
}

NotAvailable.defaultProps = {
  players: [],
  isAnyPlayerSelected: false,
}

NotAvailable.propTypes = {
  players: PropTypes.arrayOf(PropTypes.object.isRequired),
  changeIsSelectedById: PropTypes.func.isRequired,
  isAnyPlayerSelected: PropTypes.bool,
}

export default NotAvailable
