import { combineActions, handleActions } from 'redux-actions'
import {
  actionsTypesGenerator,
  onRequest,
  onSuccess,
  onFailure,
} from 'utils/reduxHelpers'
import initialState from '../initialState'

const namespace = 'AGE_CLASSES'

const FETCH_ALL_AGE_CLASSES_OF_CLUB = actionsTypesGenerator(
  `FETCH_ALL_CLUB_WISE_${namespace}`
)

//@fixme: wrong implementation! what club will be used?
export const fetchAllAgeClasses = (params = {}) => ({
  types: FETCH_ALL_AGE_CLASSES_OF_CLUB,
  callAPI: {
    method: 'GET',
    entity: 'All Age Classes of Club',
    path: `/club/ageclasses`,
    params,
  },
})

export default handleActions(
  {
    [combineActions(FETCH_ALL_AGE_CLASSES_OF_CLUB.request)]: onRequest,
    [FETCH_ALL_AGE_CLASSES_OF_CLUB.success]: (
      state,
      { response: { data = [] } }
    ) =>
      onSuccess({
        ...state,
        items: data,
      }),
    [combineActions(FETCH_ALL_AGE_CLASSES_OF_CLUB.failure)]: onFailure,
  },
  initialState.ageClasses
)
