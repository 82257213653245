import React, { useState, useEffect } from 'react'
import { Translate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { SubLink } from 'containers'
import { Carousel } from 'components'
import styled from 'styled-components'
import { articles as articlesModule } from 'redux/modules'
import ArticleItem from './ArticleItem'
import PROP_TYPES from 'constants/propTypes'
import { ARTICLES_ALL_DASHBOARD_ROUTE } from 'constants/routes'

const SubLinkAllArticles = styled(SubLink)`
  margin: 40px 0 20px 0;
  & span {
    max-width: 150px;
    display: block;
    color: var(--secondary-green);
    border-bottom: 1px solid var(--secondary-green);
  }
`

const AllArticles = ({ activeLocale, favoritesArticles, fetchArticles }) => {
  const [articles, changeArticles] = useState([])

  useEffect(() => {
    fetchArticles({
      order_by: 'title',
      limit: 30,
      locale: activeLocale,
    }).then(({ response: { data = [] } }) => changeArticles(data))
  }, [activeLocale])

  const getIsFavorite = id => Boolean(favoritesArticles.findById(id))

  return (
    <>
      {articles.length > 0 && (
        <SubLinkAllArticles to={ARTICLES_ALL_DASHBOARD_ROUTE}>
          <span>
            <Translate id="articles.all-articles" />
          </span>
        </SubLinkAllArticles>
      )}
      <Carousel>
        {articles.map(article => (
          <ArticleItem
            key={article.id}
            article={article}
            isFavorite={getIsFavorite(article.id)}
          />
        ))}
      </Carousel>
    </>
  )
}

AllArticles.defaultProps = {
  favoritesArticles: [],
}

AllArticles.propTypes = {
  activeLocale: PROP_TYPES.string.isRequired,
  favoritesArticles: PROP_TYPES.arrayOfObjects,
  fetchArticles: PROP_TYPES.func.isRequired,
}

export default connect(
  ({ favorites }) => ({ favoritesArticles: favorites.items.articles }),
  { fetchArticles: articlesModule.fetchArticles }
)(AllArticles)
