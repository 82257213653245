import React, { useContext, useState, useEffect } from 'react'
import { Translate } from 'react-localize-redux'
import { connect } from 'react-redux'
import {
  Paper,
  Typography,
  CardHeader,
  Card,
  Grid,
  Link,
  CardContent,
  Divider,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import editIcon from 'assets/images/16px/edit@1x.svg'
import DescriptionIcon from '@material-ui/icons/Description'
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined'
import {
  formatDateFromServer,
  formatCostumDateMonthNoForUI,
} from 'utils/formatDate'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import EventIcon from '@material-ui/icons/Event'
import EventPopup from 'components/material/Calender/eventPopup'
import { generalEvents } from 'redux/modules'
import { Context } from '../EventStateContext'
import PROP_TYPES from 'constants/propTypes'
import { TRAININGS_GAME } from 'constants/calender'

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiSvgIcon-root': {
      marginLeft: '-3px',
      fontSize: '1.25rem',
      color: theme.palette.grey[400],
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '1rem',
    },
    '& .MuiCardContent-root': {
      paddingTop: '0',
      color: theme.palette.grey[600],
    },
  },
  marginLeft: {
    marginLeft: '0.5rem',
  },
  displayFlex:{
    display: 'flex'
  }
}))

const EventInfo = ({ dateFormat, updateEvent }) => {
  const {
    eventState: {
      id,
      location,
      date,
      additional_info: additionalInfo,
      lat,
      lng,
      short_address: shortAddress,
      description,
      end_time: endTime,
    },
    changeWholeContextState,
    setAnyChange,
  } = useContext(Context)
  const classes = useStyles()
  const [eventData, setEventData] = useState({
    id: '',
    date: '',
    location: '',
    additionalInfo: '',
    lat: '',
    lng: '',
    shortAddress: '',
    description: '',
    endTime: '',
  })

  useEffect(() => {
    setEventData({
      ...eventData,
      id,
      date,
      location,
      additionalInfo,
      lat,
      lng,
      shortAddress,
      description,
      endTime,
    })
  }, [
    location,
    date,
    lat,
    lng,
    shortAddress,
    additionalInfo,
    description,
    endTime,
  ])

  const [open, setOpen] = React.useState(false)

  const handleDialogOpen = () => {
    setOpen(true)
  }

  const handleDialogClose = () => {
    setOpen(false)
  }
  const handleSave = (eventId, data) => {
    updateEvent(eventId, data).then((res) => {
      handleDialogClose()
      changeWholeContextState(res.response.data)
      setAnyChange(false)
    })
  }

  return (
    <Paper className={classes.root}>
      <Card>
        <CardHeader
          className={classes.styledCardHeader}
          title={
            <Typography variant="h4" component="h4">
              <Translate id="training.overview" />
            </Typography>
          }
          action={
            <div className={classes.actionHeader}>
              <IconButton onClick={handleDialogOpen}>
                <img src={editIcon} alt={editIcon} />
              </IconButton>
            </div>
          }
        />
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={12} className={classes.displayFlex}>
              <EventIcon />
              <span className={classes.marginLeft}>
                {eventData.description}
              </span>
            </Grid>
            <Grid item xs={12} className={classes.displayFlex}>
              <CalendarTodayIcon />
              <span className={classes.marginLeft}>
                {formatCostumDateMonthNoForUI(eventData.date, dateFormat)}
              </span>
            </Grid>
            <Grid item xs={6} className={classes.displayFlex}>
              <AccessTimeIcon />
              <span className={classes.marginLeft}>
                {formatDateFromServer(eventData.date)[1]}
              </span>
            </Grid>
            <Grid item xs={6} className={classes.displayFlex}>
              <AccessTimeIcon />
              <span className={classes.marginLeft}>
                {eventData.endTime &&
                  formatDateFromServer(eventData.endTime)[1]}
              </span>
            </Grid>
            <Divider />
            <Grid item xs={12} className={classes.displayFlex}>
              <div>
                <DescriptionIcon />
                <span className={classes.marginLeft}>
                  {eventData.additionalInfo}
                </span>
              </div>
            </Grid>
            <Divider />
            <Grid item xs={12} className={classes.displayFlex}>
              <LocationOnOutlinedIcon />
              <span className={classes.marginLeft}>
                <Link
                  href={`https://maps.google.com/?q=${eventData.lat},${eventData.lng}`}
                  target="_blank"
                >
                  {eventData.shortAddress || location}
                </Link>
              </span>
            </Grid>
          </Grid>
        </CardContent>
        {open && (
          <EventPopup
            open={open}
            onClose={handleDialogClose}
            trainingAndGame={TRAININGS_GAME.event}
            calenderDateSelected={false}
            calenderDate={null}
            duplicateData={{ ...eventData, end_time: endTime }}
            selectedEventType="EVENT"
            updateEvent={handleSave}
          />
        )}
      </Card>
    </Paper>
  )
}

EventInfo.propTypes = {
  updateEvent: PROP_TYPES.func.isRequired,
  dateFormat: PROP_TYPES.string.isRequired,
}

export default connect(
  ({
    generalEvents: {
      current: { team = {} },
    },
    auth,
  }) => ({
    teamName: team.name,
    dateFormat: auth.user.datetime_format,
  }),
  {
    updateEvent: generalEvents.updateEvent,
  }
)(EventInfo)
