import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const CardTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 0.3px;
  color: #545454;
  line-height: 28px;

  a {
    color: #545454;
  }
`

const CardTitleIcon = styled.div`
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 10px;
  background: url(${({ icon }) => icon}) no-repeat center;
  background-size: contain;
`

const Card = ({ children, className, ...rest }) => (
  <div className={className} {...rest}>
    {children}
  </div>
)

Card.Title = CardTitle

Card.Title.Icon = CardTitleIcon

Card.defaultProps = {
  children: <div />,
}

Card.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element.isRequired,
    PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  ]),
  className: PropTypes.string.isRequired,
}

const StyledCard = styled(Card)`
  position: relative;
  display: flex;
  flex-direction: column;

  ${({ fullHeight }) => fullHeight && 'height: calc(100% - 20px)'};
`

export default StyledCard
