import { combineActions, handleActions } from 'redux-actions'
import {
  actionsTypesGenerator,
  onRequest,
  onSuccess,
  onFailure,
} from 'utils/reduxHelpers'
import initialState from '../initialState'

const namespace = 'POLAR'

const FETCH_POLAR_TEAM = actionsTypesGenerator(`FETCH_${namespace}_TEAM`)
const FETCH_POLAR_TRAININGS = actionsTypesGenerator(`FETCH_${namespace}_TRAININGS`)
const FETCH_POLAR_TRAINING_SUMMARY = actionsTypesGenerator(`FETCH_${namespace}_TRAININGS_SUMMARY`)
const CREATE_POLAR_TOKEN = actionsTypesGenerator(`CREATE_${namespace}_TOKEN`)


export const polarGenrateToken = (code,params) => ({
  types: CREATE_POLAR_TOKEN,
  callAPI: {
    method: 'GET',
    entity: 'Genrate Token',
    path: `/polar/genrate_token/${code}`,
    params,
  },
})

export const polarFetchTeam = (params) => ({
  types: FETCH_POLAR_TEAM,
  callAPI: {
    method: 'GET',
    entity: 'Team',
    path: `/polar/getTeam`,
    params,
  },
})
export const polarFetchTeamTrainings = (id,params) => ({
  types: FETCH_POLAR_TRAININGS,
  callAPI: {
    method: 'GET',
    entity: 'Trainings',
    path: `/polar/team/${id}/trainings`,
    params,
  },
})
export const polarFetchSessionAllSummary = (id,params) => ({
  types: FETCH_POLAR_TRAINING_SUMMARY,
  callAPI: {
    method: 'GET',
    entity: 'AllSummary',
    path: `/polar/team/trainings/${id}/all_summary`,
    params,
  },
})

export default handleActions(
  {
    [combineActions(
      FETCH_POLAR_TEAM.request,
      FETCH_POLAR_TRAININGS.request,
      FETCH_POLAR_TRAINING_SUMMARY.request,
      CREATE_POLAR_TOKEN.request,
    )]: onRequest,
    [combineActions(
      FETCH_POLAR_TEAM.success,
      FETCH_POLAR_TRAININGS.success,
      FETCH_POLAR_TRAINING_SUMMARY.success,
      CREATE_POLAR_TOKEN.success,
    )]: (state, { response: { data } }) => {
      return onSuccess({
        ...state,
        items: data,
        current: data,
      })
    },
    [combineActions(
      FETCH_POLAR_TEAM.failure,
      FETCH_POLAR_TRAININGS.failure,
      FETCH_POLAR_TRAINING_SUMMARY.failure,
      CREATE_POLAR_TOKEN.failure,
    )]: onFailure,
  },
  initialState.thirdParty
)
