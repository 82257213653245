import React from 'react'
import { TeamLoader } from 'loaders'
import HomeDashboardContainers from './HomeDashboardContainers'
import PROP_TYPES from 'constants/propTypes'

const HomeDashboard = ({
  match: {
    params: { teamId, seasonId },
  },
}) => (
  <TeamLoader
    teamId={teamId}
    seasonId={seasonId}
    additionalFields={['statistics', 'next_match', 'next_training', 'players']}
  >
    <HomeDashboardContainers />
  </TeamLoader>
)

HomeDashboard.propTypes = {
  match: PROP_TYPES.match.isRequired,
}

export default HomeDashboard
