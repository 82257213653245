import { ContentState, EditorState } from 'draft-js'
import htmlToDraft from 'html-to-draftjs'

export default value => {
  const contentBlock = htmlToDraft(value)

  if (contentBlock) {
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    )

    return EditorState.createWithContent(contentState)
  }

  return value
}
