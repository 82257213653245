/* eslint-disable no-nested-ternary */
import React, { useState } from 'react'
import {
  Typography,
  ListItem,
  List,
  ListItemAvatar,
  Divider,
  ListItemSecondaryAction,
  ListItemText,
  Dialog,
  Tooltip,
} from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import binIcon from 'assets/images/16px/bin@1x.svg'
import yellowCardIcon from 'assets/images/soccer-cards/yellow-card.svg'
import redCardIcon from 'assets/images/soccer-cards/red-card.svg'
import redYellowCardIcon from 'assets/images/soccer-cards/yellow-red-card.svg'
import SportsSoccerIcon from '@material-ui/icons/SportsSoccer'
import editIcon from 'assets/images/16px/edit@1x.svg'
import SwapHorizIcon from '@material-ui/icons/SwapHoriz'
import NoteIcon from '@material-ui/icons/Note'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Translate } from 'react-localize-redux'
import AlertDialog from '../../../../components/AlertDialog'
import MatchHighlightsDialog from './MatchHighlightsDialog'

const useStyles = makeStyles((theme) => ({
  root:{
    [theme.breakpoints.down('sm')]: {
      '& .MuiListItemSecondaryAction-root': {
        right:'0px'
      },
      '& .MuiListItem-gutters':{
        paddingLeft: '0',
      }
    },
    '& .MuiListItem-secondaryAction':{
      paddingRight:'65px'
    }
    
  },
  minute: {
    fontWeight: 'bold',
    marginRight: '1rem',
    minWidth: '25px',
    maxWidth: '25px',
  }
}))
const MatchHighlightsList = ({
  matchState,
  highlights,
  updateHighlight,
  deleteHighlight,
  playersSuggestions,
  setLoading,
  teamName,
}) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [deleteHighlightId, changeDeleteEvent] = useState(0)

  const handleDelete = () => {
    setLoading(true)
    setOpen(false)

    return deleteHighlight(deleteHighlightId).then(() => {
      setLoading(false)
    })
  }
  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const openAlertBox = (id) => {
    changeDeleteEvent(id)
    handleOpen()
  }
  const [existingData, setExistingData] = React.useState([])
  const handleUpdate = (id, data) => {
    updateHighlight(id, data)
  }
  const [openEdit, setEditOpen] = React.useState(false)
  const handleEditClose = () => {
    setEditOpen(false)
  }
  const handleEditClickOpen = (id) => {
    setExistingData(
      highlights.filter((highlight) => {
        return highlight.id === id
      })[0]
    )
    setEditOpen(true)
  }

  return (
    <Translate>
      {({ translate }) => (
        <>
          <Dialog open={openEdit} onClose={handleEditClose}>
            <MatchHighlightsDialog
              onClose={handleEditClose}
              handleUpdate={handleUpdate}
              existingData={existingData}
              playersSuggestions={playersSuggestions}
              matchState={matchState}
              teamName={teamName}
            />
          </Dialog>
          <AlertDialog
            open={open}
            onClose={handleClose}
            onSuccess={handleDelete}
            onCancel={handleClose}
            className={classes.root}
            title={<Translate id="button.delete" />}
            subTitle={<Translate id="events.are-you-sure-you-want-to-delete" />}
            submitButtonLabel={<Translate id="button.ok" />}
            cancelButtonLabel={<Translate id="button.close" />}
          />
          <List className={classes.root}>
            {highlights.map((highlight) => {
              return (
                <>
                  <ListItem key={highlight.id}>
                    <Typography
                      component="span"
                      variant="span"
                      className={classes.minute}
                      color="secondary"
                    >
                      {`${highlight.minute}'`}
                    </Typography>
                    <ListItemAvatar className="text-secondary align-items-center">
                      {(() => {
                        switch (highlight.type) {
                          case 'substitution':
                            return <SwapHorizIcon color="secondary" />
                          case 'goal':
                            return <SportsSoccerIcon color="secondary" />
                          case 'card':
                            return (
                              <>
                                {highlight.card_type === 'yellow' ? (
                                  <img
                                    src={yellowCardIcon}
                                    alt={yellowCardIcon}
                                  />
                                ) : highlight.card_type === 'red' ? (
                                  <img src={redCardIcon} alt={redCardIcon} />
                                ) : (
                                  <img
                                    src={redYellowCardIcon}
                                    alt={redYellowCardIcon}
                                  />
                                )}
                              </>
                            )
                          default:
                            return <NoteIcon color="secondary"/>
                        }
                      })()}
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <>
                          {(() => {
                            switch (highlight.type) {
                              case 'note':
                                return highlight.note
                              case 'substitution':
                                return (
                                  <>
                                    <Typography
                                      component="span"
                                      variant="span"
                                      className="mr-1"
                                    >
                                      {translate('matches.highlights.in')}:
                                    </Typography>
                                    {highlight.player2_name}
                                  </>
                                )

                              case 'goal':
                                return (
                                  <>
                                    <Typography
                                      component="span"
                                      variant="span"
                                      className="font-weight-bold mr-1"
                                      color="secondary"
                                    >
                                      {highlight.score.own_team} -{' '}
                                      <span style={{ color: 'red' }}>
                                        {highlight.score.opponent_team}
                                      </span>
                                    </Typography>
                                    {highlight.player1_name}
                                  </>
                                )
                              default:
                                return highlight.player1_name
                            }
                          })()}
                        </>
                      }
                      secondary={
                        <>
                          {(() => {
                            switch (highlight.type) {
                              case 'substitution':
                                return (
                                  <>
                                    {`${
                                      highlight.player1_name
                                        ? `${translate('matches.highlights.out')}: ${highlight.player1_name}`
                                        : ''
                                    }`}
                                    {highlight.reason &&
                                      ` - ${translate('matches.highlights.reason')}: ${translate(
                                        `matches.highlights.${highlight.reason}`
                                      )}`}
                                  </>
                                )
                              case 'card':
                                return (
                                  highlight.reason &&
                                  `${translate('matches.highlights.reason')}: ${translate(
                                    `matches.highlights.${highlight.reason}`
                                  )}`
                                )
                              case 'goal':
                                return highlight.goal_category &&
                                  highlight.goal_category === 'regular'
                                  ? highlight.player2_name
                                    ? ` Assist: ${highlight.player2_name}`
                                    : ''
                                  : `${translate(
                                      `matches.highlights.${highlight.goal_category}`
                                    )}${
                                      highlight.player2_name
                                        ? ` - Assist: ${highlight.player2_name}`
                                        : ''
                                    }`
                              default:
                                return ''
                            }
                          })()}
                        </>
                      }
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="edit"
                        onClick={() => handleEditClickOpen(highlight.id)}
                      >
                        <Tooltip
                          title={translate('button.edit')}
                          placement="top"
                          arrow
                        >
                          <img src={editIcon} alt={editIcon} />
                        </Tooltip>
                      </IconButton>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => openAlertBox(highlight.id)}
                      >
                        <Tooltip
                          title={translate('button.delete')}
                          placement="top"
                          arrow
                        >
                          <img src={binIcon} alt={binIcon} />
                        </Tooltip>
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                </>
              )
            })}
          </List>
        </>
      )}
    </Translate>
  )
}
MatchHighlightsList.defaultProps = {
  matchState: null,
  teamName: '',
}
MatchHighlightsList.propTypes = {
  highlights: PropTypes.shape().isRequired,
  updateHighlight: PropTypes.shape().isRequired,
  deleteHighlight: PropTypes.shape().isRequired,
  setLoading: PropTypes.bool.isRequired,
  playersSuggestions: PropTypes.shape().isRequired,
  matchState: PropTypes.shape(),
  teamName: PropTypes.string,
}
export default MatchHighlightsList
