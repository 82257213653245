import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import PlayerCard from './PlayerCard'

const StyleTeamContainer = styled.div`
  padding: 16px;
`

const PlayersList = ({ players, className, WrapperComponent, onSelect }) => (
  <StyleTeamContainer>
    <div className={className}>
      {players.map((player) => {
        return (
          <WrapperComponent key={player.id} playerId={player.id}>
            <PlayerCard {...player} onSelect={onSelect} />
          </WrapperComponent>
        )
      })}
    </div>
  </StyleTeamContainer>
)

PlayersList.defaultProps = {
  players: [],
  WrapperComponent: null,
  fromPathname: '',
  onPlayerChange: null,
  handleSave: null,
  hidePlayerChangePopover: false,
}

PlayersList.propTypes = {
  players: PropTypes.arrayOf(PropTypes.object.isRequired),
  className: PropTypes.string.isRequired,
  WrapperComponent: PropTypes.func,
  fromPathname: PropTypes.string,
  onPlayerChange: PropTypes.func,
  handleSave: PropTypes.func,
  hidePlayerChangePopover: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
}

export default styled(PlayersList)`
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
    div .playerIconItem {
      min-width: 80px !important;
    }
  }
`
