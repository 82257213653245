import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { generalEvents } from 'redux/modules'
import Loader from './Loader'
import PROP_TYPES from 'constants/propTypes'

const EventLoader = (props) => {
  const { children, eventId, fetchEvent, ...restProps } = props

  const fetchMethod = (params) => fetchEvent(eventId, params)
  const triggers = [eventId]

  return (
    <Loader fetchMethod={fetchMethod} triggers={triggers} {...restProps}>
      {children}
    </Loader>
  )
}

EventLoader.propTypes = {
  children: PROP_TYPES.children.isRequired,
  fetchEvent: PropTypes.func.isRequired,
  eventId: PropTypes.number.isRequired
}

export default connect(null, { fetchEvent: generalEvents.fetchEvent })(EventLoader)
