import React from 'react'
import { PlayerLoader } from 'loaders'
import PlayerEditContainer from './PlayerEditContainer'
import PROP_TYPES from 'constants/propTypes'

const PlayerEdit = ({
  match: {
    params: { playerId },
  },
  match,
  history,
}) => (
  <PlayerLoader playerId={+playerId} additionalFields={['avatar']}>
    <PlayerEditContainer match={match} history={history} />
  </PlayerLoader>
)

PlayerEdit.propTypes = {
  match: PROP_TYPES.match.isRequired,
  history: PROP_TYPES.history.isRequired,
}

export default PlayerEdit
