import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { drills } from 'redux/modules'
import { Dialog } from '@material-ui/core'
import DrillHead from './DrillHead'
import DrillBody from './DrillBody'
import DrillFooter from './DrillFooter'
import { useStyles } from './styleComponents'
import PROP_TYPES from 'constants/propTypes'

const DrillModal = ({
  drill,
  onHide,
  categoryType,
  plusButton,
  fetchDrill,
  open,
  handleMenuClick,
  handleMenuClose,
  anchorEl,
  classes = useStyles(),
}) => {
  const { id } = drill

  React.useEffect(() => {
    fetchDrill(id, {})
  }, [])

  return (
    <Dialog
      open={open}
      onClose={onHide}
      centered
      onHide={onHide}
      fullWidth
      maxWidth="xl"
    >
      <DrillHead
        drill={drill}
        handleMenuClick={handleMenuClick}
        handleMenuClose={handleMenuClose}
        anchorEl={anchorEl}
        plusButton={plusButton}
        categoryType={categoryType}
        onHide={onHide}
      />
      <DrillBody drill={drill} />

      <DrillFooter drill={drill} classes={classes} />
    </Dialog>
  )
}
DrillModal.defaultProps = {
  handleMenuClick: [],
  handleMenuClose: [],
  anchorEl: true,
  categoryType: [],
  plusButton: [],
  open: false,
}
DrillModal.propTypes = {
  drill: PropTypes.shape().isRequired,
  onHide: PropTypes.func.isRequired,
  classes: PropTypes.func.isRequired,
  fetchDrill: PropTypes.func.isRequired,
  categoryType: PROP_TYPES.string,
  plusButton: PROP_TYPES.string,
  open: PROP_TYPES.bool,
  handleMenuClick: PropTypes.func,
  handleMenuClose: PropTypes.func,
  anchorEl: PROP_TYPES.bool,
}

export default connect(null, {
  fetchDrill: drills.fetchDrill,
})(DrillModal)
