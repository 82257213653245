import React, { useMemo } from 'react'
import { withRouter } from 'react-router-dom'
import sortBy from 'lodash.sortby'
import { MiniCalendar } from 'containers'
import urlConstructor from 'utils/urlConstructor'
import { TRAINING_DASHBOARD_ROUTE } from 'constants/routes'
import PROP_TYPES from 'constants/propTypes'

const MiniCalendarTabs = ({
  trainingId,
  availableTrainings,
  match,
  history,
  // handleSave,
}) => {
  // const classes = useStyles()

  const {
    params: { teamId, seasonId },
  } = match

  const sortedTrainings = useMemo(
    () => sortBy(availableTrainings, ({ date }) => date),
    [availableTrainings]
  )

  const onEventSelected = id =>
    history.push(
      urlConstructor(
        teamId,
        seasonId,
        TRAINING_DASHBOARD_ROUTE.populateURLParams({ trainingId: id })
      )
    )

  return (
    <MiniCalendar
      currentEventId={trainingId}
      events={sortedTrainings}
      onEventSelected={onEventSelected}
    />
  )
}

MiniCalendarTabs.defaultProps = {
  availableTrainings: [],
}

MiniCalendarTabs.propTypes = {
  trainingId: PROP_TYPES.id.isRequired,
  availableTrainings: PROP_TYPES.arrayOfObjects,
  match: PROP_TYPES.match.isRequired,
  // handleSave: PROP_TYPES.func.isRequired,
  history: PROP_TYPES.history.isRequired,
}

export default withRouter(MiniCalendarTabs)
