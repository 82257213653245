import React from 'react'
import { Translate } from 'react-localize-redux'
import { SubLink, HasClubPermission } from 'containers'
import { getClub } from 'utils/localstorage'

import {
  Paper,
  Grid,
  Toolbar,
  Tabs,
  Tab,
  Box,
  Button,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import TeaserTagOverlay from 'components/Teaser/TeaserTagOverlay'
import {
  DRILLS_CATEGORIES,
  DRILL_CREATE_ROUTE,
  TRAINING_PACKAGE_CREATE_ROUTE
} from 'constants/routes';
import PERMISSIONS from 'constants/permissions'
import { PLUS_IN_CAPS } from 'constants/plans';

// eslint-disable-next-line import/prefer-default-export
export function DrillToolbar({
  value,
  trainingPackageValue,
  handleChange,
}) {

  const classes = makeStyles(theme => ({
    title: { flex: '1 1 0' },
    toolbar: { minHeight: 54 },
    button: { margin: theme.spacing(0, 1) },
    [theme.breakpoints.up('xl')]: {
      root: {
        '& .MuiGrid-grid-xl-2': {
          maxWidth: '25%',
          flexBasis: '25%',
        },
      },
    },
    root: {
      flexGrow: 1,
      margin: theme.spacing(0, 0, 0),
      padding: theme.spacing(3, 3, 3),
    },
    [theme.breakpoints.down('sm')]: {
      toolbar: {
        flexDirection: 'column',
      },
      buttonGroup: {
        padding: theme.spacing(2),
      }

    }
  }))()

  return (
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
      <Toolbar
        disableGutters="false"
        component={Paper}
        className={classes.toolbar}
      >
        <Box className={classes.title} component="div">
          <Tabs
            value={value}
            className={classes.title}
            onChange={handleChange}
            indicatorColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            {getClub().id > 1 && <Tab label={<>{`${getClub()?.name}`} <Translate id="drills.global-drills" /></>} />}
            <Tab label={<Translate id="drills.coachbetter-drills" />} />
            <Tab label={<Translate id="drills.my-drills" />} />
            <Tab label={<Translate id="navbar.training_packages" />} />
          </Tabs>
        </Box>
        <Box className={classes.buttonGroup}>
          {value === trainingPackageValue ?
            <TeaserTagOverlay tagLabel={PLUS_IN_CAPS}>
              <Button
                variant="contained"
                color="primary"
                component={SubLink}
                className={classes.button}
                to={TRAINING_PACKAGE_CREATE_ROUTE}
              >
                <Translate id="training.create-training-packages" />
              </Button></TeaserTagOverlay>

            : <>
              <HasClubPermission ability={PERMISSIONS.CREATE_DRILL_CATEGORY}>
                <Button
                  variant="contained"
                  color="primary"
                  component={SubLink}
                  className={classes.button}
                  to={DRILLS_CATEGORIES}
                >
                  <Translate id="drills.manage-categories" />
                </Button>
              </HasClubPermission>
              <Button
                variant="contained"
                color="primary"
                component={SubLink}
                className={classes.button}
                to={DRILL_CREATE_ROUTE}
              >
                <Translate id="drills.create-drill" />
              </Button>
            </>}
        </Box>

      </Toolbar>
    </Grid>
  )
}
