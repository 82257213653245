import React, { useContext, useEffect, useState } from 'react'
import CloseIcon from '@material-ui/icons/Close'
import {
  Button,
  Chip,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableContainer,
  Typography,
} from '@material-ui/core'
import { InnrHead } from 'containers'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import CircularSpinner from 'components/loader/CircularSpinner'
import { Translate } from 'react-localize-redux'
import { Context } from '../MatchStateContext'
import RatePlayerTableRow from './RatePlayerTableRow'
import { playerFilterCategories } from 'constants/player'
import PROP_TYPES from 'constants/propTypes'

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiDialog-paper': {
      maxWidth: '700px',
    },
    '& .MuiTableCell-root': {
      padding: '10px',
    },
    '& .MuiButtonBase-root':{
      marginRight: 8,
      marginRight: 8,
    }
  },
  rosterContainer:{
    marginTop: 20,
    marginBottom: 20,
  },
}))

const RatePlayersDialog = ({
  open,
  setOpen,
  onClose,
  handleSave,
  isLoading,
}) => {
  const { matchState, onFieldChange } = useContext(Context)
  const classes = useStyles()
  const [inRoster, changeInRoster] = useState()
  const [notInRoster, changeNotInRoster] = useState()
  const [playerfilter, setPlayerfilter] = useState('all')

  const setPlayersInLists = (filter) => {
    if (filter === 'all') {
      changeInRoster(
        matchState.matchPlayers.filter(
          (p) => p.participant && (p.position_x !== 0 || p.position_y !== 0)
        )
      )
      changeNotInRoster(
        matchState.matchPlayers.filter((p) => (p.in_roster === 'AVAILABLE' ||p.in_roster === 'NOT_RESPONDED') && !p.participant)
      )
    } else {
      changeInRoster(
        matchState.matchPlayers.filter(
          (p) =>
            p.participant &&
            (p.position_x !== 0 || p.position_y !== 0) &&
            p.position === filter
        )
      )
      changeNotInRoster(
        matchState.matchPlayers.filter(
          (p) => (p.in_roster === 'AVAILABLE' ||p.in_roster === 'NOT_RESPONDED') && !p.participant && p.position === filter
        )
      )
    }
  }

  const handleFilterClick = (value1) => {
    setPlayerfilter(value1)
    setPlayersInLists(value1)
  }
  const onChangeRating = (newRating, matchPlayerUuid) => {
    onFieldChange(
      'matchPlayers',
      matchState.matchPlayers.updateByUuid(matchPlayerUuid, {
        ...matchState.matchPlayers.findByUuid(matchPlayerUuid),
        rating: newRating,
      })
    )
  }
  const onChangePlayerTime = ({ target: { value: v } }, matchPlayerUuid) => {
    onFieldChange(
      'matchPlayers',
      matchState.matchPlayers.updateByUuid(matchPlayerUuid, {
        ...matchState.matchPlayers.findByUuid(matchPlayerUuid),
        playing_minutes: +v,
      })
    )
  }
  useEffect(() => {
    setPlayersInLists(playerfilter)
  }, [matchState.matchPlayers])

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      className={classes.root}
    >
      {isLoading && <CircularSpinner />}
      <InnrHead title={<Translate id="matches.rate-players.button" />}>
        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </InnrHead>
      <DialogContent>
        <Grid container >
          <Grid xs={12}>
            {playerFilterCategories.map((cat) => (
              <Chip
                key={cat.key}
                label={cat.displayValue}
                color="primary"
                variant={playerfilter === cat.key ? 'default' : 'outlined'}
                onClick={() => handleFilterClick(cat.key)}
                className={classes.ratings}
              />
            ))}
          </Grid>
          <Grid xs={12} className={classes.rosterContainer}>
            <Grid container alignItems="center">
              <Grid xs={8}>
                <Typography variant="h4" className={classes.rosterContainer}>
                  <Translate id="matches.rate-players.roster" />
                </Typography>
              </Grid>
              <Grid xs={4} align="right">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    handleSave().then(() => {
                      setOpen(false)
                    })
                  }}
                >
                  <Translate id="button.save" />
                </Button>
              </Grid>
            </Grid>
            {inRoster?.length > 0 ? (
              <TableContainer component={Paper}>
                <Table aria-label="roster-players-table">
                  <TableBody>
                    <RatePlayerTableRow
                      matchPlayers={inRoster}
                      onChangeRating={onChangeRating}
                      onChangePlayerTime={onChangePlayerTime}
                      handleSave={handleSave}
                    />
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography variant="h6">
                <Translate id="matches.rate-players.no-players" />
              </Typography>
            )}

            <Typography variant="h4" className={classes.rosterContainer}>
              <Translate id="matches.rate-players.substitute" />
            </Typography>
            {notInRoster?.length > 0 ? (
              <TableContainer component={Paper}>
                <Table aria-label="substitute-players-table">
                  <TableBody>
                    <RatePlayerTableRow
                      matchPlayers={notInRoster}
                      onChangeRating={onChangeRating}
                      onChangePlayerTime={onChangePlayerTime}
                      handleSave={handleSave}
                    />
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography variant="h6" className={classes.rosterContainer}>
                <Translate id="matches.rate-players.no-players" />
              </Typography>
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
RatePlayersDialog.propTypes = {
  open: PROP_TYPES.bool.isRequired,
  onClose: PROP_TYPES.func.isRequired,
  handleSave: PROP_TYPES.func.isRequired,
  isLoading: PROP_TYPES.bool.isRequired,
  setOpen: PROP_TYPES.func.isRequired,
}
export default compose(
  withRouter,
  connect(
    ({
      matches: {
        current: { lineups, sequences },
        isLoading,
      },
    }) => ({
      lineups,
      sequences,
      isLoading,
    }),
    {}
  )
)(RatePlayersDialog)
