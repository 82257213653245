import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { List, ListItem, Box, FormHelperText } from '@material-ui/core'
import { StyledCheckbox } from 'components'
import PROP_TYPES from 'constants/propTypes'

const MuiList = withStyles(theme => ({
  root: {
    width: '100%',
    display: 'inline-block',
    '& .MuiListItem-root .MuiBox-root': {
      display: 'flex',
    },
    '& .MuiListItem-root': {
      width: 210,
      float: 'left',
      padding: theme.spacing(0),
      alignItems: 'start',
      flexDirection: 'column',
      '& .MuiList-root': {
        paddingLeft: theme.spacing(1),
        '& .MuiListItem-root:first-child .MuiBox-root': {
          display: 'block',
        },
        '& .MuiListItem-root .MuiBox-root': {
          display: 'flex',
        },
        '& .MuiListItem-root': {
          width: '100%',
          float: 'none',
        },
      },
    },
  },
}))(List)

const AgeClassSelector = ({ input, meta, items }) => {
  const onChange = id => checked => {
    if (checked > 0) {
      input.onChange([...input.value, id])

      return
    }

    input.onChange(input.value.filter(value => value !== id))
  }

  const isInvalid = meta.touched && meta.error

  return (
    <Box>
      <MuiList>
        {items.map(age => (
          <ListItem key={age.id}>
            <StyledCheckbox
              input={{
                value: input.value.includes(age.id),
                onChange: onChange(age.id),
              }}
              label={age.name}
            />
          </ListItem>
        ))}
      </MuiList>
      {isInvalid && (
        <FormHelperText type="invalid" error>
          {isInvalid}
        </FormHelperText>
      )}
    </Box>
  )
}

AgeClassSelector.defaultProps = {
  items: [],
}

AgeClassSelector.propTypes = {
  input: PROP_TYPES.shape().isRequired,
  meta: PROP_TYPES.shape().isRequired,
  items: PROP_TYPES.arrayOfObjects,
}

export default AgeClassSelector
