import styled from 'styled-components'
// TODO: change with proper icon
import InfoImage from 'assets/images/drill-info-w.svg'

const CONTAINER_HEIGHT = 220
const MARGIN_HORIZONTAL = 6
const TIME_ICON_WIDTH = 15

export default styled.div`
  width: calc(265px - ${`${MARGIN_HORIZONTAL * 2}px`}) !important;
  margin-left: ${`${MARGIN_HORIZONTAL}px`};
  margin-right: ${`${MARGIN_HORIZONTAL}px`};
  margin-top: 15px;
  margin-bottom: 0;
  margin-top: 0px;
  border: 1px solid #ededed;
  border-radius: 12px;
  position: relative;
  background: #ddd;
  height: ${`${CONTAINER_HEIGHT}px`};

  background: url(${({ image }) => image}) no-repeat center;

  background-size: cover;
  .title {
    font-size: 0.875rem;
    margin: 0.5rem 0.9rem 0 0.9rem;
    display: inline-block;
    white-space: nowrap;
    width: calc(100% - 2rem);
    overflow: hidden !important;
    text-overflow: ellipsis;
    line-height: 1.3;
  }
  .packagetime {
    display: flex;
  }
  > div:first-child {
    // position: absolute
    // top: 40%;
    // width: 100%;
    // height: 60%;

    // border-radius: 12px;

    // padding: 5px;
    // overflow: hidden;
    // font-size: 80%;

    .time {
      margin: auto 7px 7px 7px;

      position: relative;
      padding-left: ${`${TIME_ICON_WIDTH + 5}px`};

      line-height: 1.2;

      &::before {
        content: ' ';
        position: absolute;
        left: 0px;
        vertical-align: middle;

        width: ${`${TIME_ICON_WIDTH}px`};
        height: ${`${TIME_ICON_WIDTH}px`};
        background: url(${InfoImage}) no-repeat center;
        background-size: contain;
      }

      &::after {
        content: 'min';
      }
    }
  }
  @media only screen and (max-width: 640px) {
  }
`
