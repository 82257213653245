/* eslint-disable */
import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, CardActions, Container } from '@material-ui/core'
import PropTypes from 'prop-types'
import Joi from 'joi'
import { connect } from 'react-redux'
import { Translate } from 'react-localize-redux'
import { subscription } from 'redux/modules'
import { reduxForm, Field } from 'redux-form'
import { ImageUploader } from 'containers'
import { Table } from 'react-bootstrap'
import styled from 'styled-components'
import Checkbox from '@material-ui/core/Checkbox'
import { ageClasses as ageClassesModule } from 'redux/modules'
import Dialog from '@material-ui/core/Dialog';

import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {
  OwnGrid,
  Input,
  RadioGroup,
  SaveButton,
  ReactSelect,
} from 'components'
import {
  setCountry as setCountryLocale,
  getCountry,
  getClub,
  getUserId,
} from 'utils/localstorage'
import createJoiValidator from 'utils/createJoiValidator'
import { plans, billingCycle } from 'constants/plans'
import countries from 'constants/countries'

const useStyles = makeStyles(theme => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
    },
    li: {
      listStyle: 'none',
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(0, 0, 4),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === 'dark'
        ? theme.palette.grey[700]
        : theme.palette.grey[200],
    margin: theme.spacing(0, 0, 2, 0),
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  switcher: {
    textAlign: 'center',
  },
  centeredButton: {
    justifyContent: 'center',
  },
}))

const GENDER_OPTIONS = [
  {
    value: 'm',
    title: 'Male',
  },
  {
    value: 'f',
    title: 'Female',
  },
]

const CancelSubscription = styled.button`
  float: right;
  background: transparent;
  border: none;
  outline: none;
  color: red;
  cursor: pointer;
`
const ResumeSubscription = styled.button`
  float: right;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
`
const EditCard = styled.a`
  float: right;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
`

const validationSchema = Joi.object().keys({
  name: Joi.string()
    .max(255)
    .required(),
  plan: Joi.object()
    .max(255)
    .required(),
  league: Joi.string()
    .max(255)
    .required(),
  age_class: Joi.required(),
  phone: Joi.string().max(255),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .max(255),
  address_1: Joi.string().max(255),
  address_2: Joi.string().max(255),
  city: Joi.string().max(255),
  country: Joi.string()
    .max(255),
    
  logo: Joi.any(),
  hide_global_drills: Joi.optional(),
  gender: Joi.string(),
})

const TeamCreateEditForm = ({
  
  handleClose,
  open,
  fromPath,
  upgradeView,
  editCard,
  handleSubmit,
  // reset,
  editable,
  change,
  initialValues,
  updateSubscription,
  deleteSubscription,
  resumeSubscription,
  fetchAllAgeClasses,
  goBack,
  isCountryValid,
  changeCountyValid,
}) => {
  const intiValuePlan = initialValues.plan ? initialValues.plan.split('_') : ''
  const intiValuePlanData = plans.filter(plan => {
    return plan.value === intiValuePlan[0]
  })

  const [ActivePlan, changeActivePlan] = useState(
    intiValuePlanData.length > 0
      ? {
          ...intiValuePlanData[0],
          selected: 'annual',
        }
      : {
          ...plans[2],
          selected: 'annual',
        }
  )

  const intiValueBillingCycle =
    intiValuePlan.length > 1
      ? {
          value: 'annual',
          label: 'Annual',
        }
      : {
          value: 'monthly',
          label: 'Monthly',
        }

  const [country, setCountry] = React.useState(
    initialValues.country
      ? countries[
          countries.findIndex(index => index.value === initialValues.country) >
          0
            ? countries.findIndex(
                index => index.value === initialValues.country
              )
            : 0
        ]
      : countries[0]
  )
  const [isGlobalDrill, setIsGlobalDrill] = React.useState(
    initialValues.hide_global_drills === 1
  )
  const [isGlobalTrainingPackages, setIsTrainingPackages] = React.useState(
    initialValues.hide_global_training_packages === 1
  )
  setCountryLocale(country.value)
  localStorage.setItem('isGlobalDrill', isGlobalDrill)
  localStorage.setItem('isGlobalTrainingPackages', isGlobalTrainingPackages)
  const changeCountry = value => {
    setCountry(value)
    setCountryLocale(value.value)
    if (!getCountry() || getCountry() === '') {
      changeCountyValid(true)
    } else {
      changeCountyValid(false)
    }
  }
  const changeGlobalDrill = value => {
    setIsGlobalDrill(value.target.checked)
    localStorage.setItem('isGlobalDrill', isGlobalDrill)
  }
  const changeGlobalTrainingPackages = value => {
    setIsTrainingPackages(value.target.checked)
    localStorage.setItem('isGlobalTrainingPackages', isGlobalTrainingPackages)
  }

  const [subscriptionPlan, editPlan] = useState({
    plan: intiValuePlanData[0],
    billingCycle: intiValueBillingCycle,
    billingCycleOptions:
      intiValuePlan[0] === 'champion'
        ? [
            {
              value: 'annual',
              label: 'Annual',
            },
          ]
        : billingCycle,
    changed: false,
  })

  const changePlan = (PlanId, type) => {
    changeActivePlan({ ...PlanId, selected: type })
    change('plan', PlanId)
  }

  const changebillingCycle = newPlans => {
    return editPlan({
      ...subscriptionPlan,
      billingCycle: newPlans,
      changed: true,
    })
  }

  const changeSubscriptionPlan = newPlans => {
    let SelectBillingCycle = subscriptionPlan.billingCycle
    let billingCycleOptions = billingCycle
    if (newPlans.monthly === null) {
      billingCycleOptions = [
        {
          value: 'annual',
          label: 'Annual',
        },
      ]
      SelectBillingCycle = {
        value: 'annual',
        label: 'Annual',
      }
    } else if (newPlans.annual === null) {
      billingCycleOptions = [
        {
          value: 'monthly',
          label: 'Monthly',
        },
      ]
      SelectBillingCycle = {
        value: 'monthly',
        label: 'Monthly',
      }
    } else {
      billingCycleOptions = billingCycle
    }

    return editPlan({
      plan: newPlans,
      billingCycle: SelectBillingCycle,
      billingCycleOptions,
      changed: true,
    })
  }

  const updateCheckout = () => {
    const planName =
      subscriptionPlan.billingCycle.value === 'annual'
        ? `${subscriptionPlan.plan.value}_annual`
        : subscriptionPlan.plan.value
    const data = {
      plan: planName,
      subscription_id: initialValues.subscription_id,
    }
    updateSubscription(data).then()
  }

  const cancelPlan = () => {
    deleteSubscription(initialValues.subscription_id).then(goBack)
  }

  const resumePlan = () => {
    resumeSubscription(initialValues.subscription_id).then(goBack)
  }

  change('plan', ActivePlan)

  const classes = useStyles()

  const [ageClasses, SetAgeClasses] = useState([])

  const [clubData, setClubData] = React.useState(getClub() || null)

  const getAgeClasses = () => {
    fetchAllAgeClasses({ club_id: clubData.id })
      .then(res => {
        SetAgeClasses(res.response.data)
      })
      .catch(e => {
        console.error(e)
      })
  }

  React.useEffect(() => {
    getAgeClasses()
  }, [])

  return (
    <Translate>
      {({ translate }) => (
        <>
          {/* {!upgradeView && (
            <Card.Title>
              <LinkBack to={fromPath.value} title={fromPath.title} />
            </Card.Title>
          )}
          {!upgradeView && <Card.Title>{title}</Card.Title>} */}
          
          {/* End hero unit */}
          <form>
            {!upgradeView && (
              <>
              <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Create Team</DialogTitle>
                <DialogContent dividers>
                <Field name="logo" component={ImageUploader} />
                <br/>
                <Field
                    name="name"
                    label={translate('form.team-name')}
                    component={Input}
                  />
                     <Field
                    name="age_class"
                    label={translate('form.age-class')}
                    component={RadioGroup}
                    options={ageClasses.map(p => ({
                      value: p.id,
                      title: p.name,
                    }))}
                  />
                  <Field
                    name="gender"
                    label={translate('form.gender')}
                    component={RadioGroup}
                    options={GENDER_OPTIONS}
                  />
                </DialogContent>
              <DialogActions>
              <Button onClick={handleClose} color="primary">
              <Translate id="button.cancel" />
              </Button>
              <Button onClick={handleSubmit} color="primary">
              <Translate id="button.save" />
              </Button>
            </DialogActions>
            </Dialog>
              <OwnGrid.Row >
                <OwnGrid.Col xs={12} md={3}>
               

                  {editable &&
                    (!initialValues.user_id ||
                      initialValues.user_id === getUserId()) &&
                    (subscriptionPlan.plan && (
                      <Table>
                        <tbody>
                          <tr>
                            <td>{initialValues.card_brand}</td>
                            <td>
                              **** {initialValues.card_last_four}
                              <EditCard href={editCard()}>Edit Card</EditCard>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={2} className="text-left">
                              {(initialValues.ends_at && initialValues.paid) ||
                              initialValues.cancelled_at ? (
                                <ResumeSubscription
                                  type="button"
                                  onClick={resumePlan}
                                >
                                  Resume Subscription
                                </ResumeSubscription>
                              ) : (
                                <CancelSubscription
                                  type="button"
                                  onClick={cancelPlan}
                                >
                                  Cancel Subscription
                                </CancelSubscription>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>Abo:</td>
                            <td>
                              <ReactSelect
                                placeholder={subscriptionPlan.value}
                                className="form-group"
                                suggestions={plans}
                                value={subscriptionPlan.plan}
                                isMulti={false}
                                onChange={changeSubscriptionPlan}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Billing cycle:</td>
                            <td>
                              <ReactSelect
                                value={subscriptionPlan.billingCycle}
                                onChange={changebillingCycle}
                                isMulti={false}
                                suggestions={
                                  subscriptionPlan.billingCycleOptions
                                }
                                className="form-group"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Costs(CHF):</td>
                            <td>
                              {subscriptionPlan.plan.currency}
                              {
                                subscriptionPlan.plan[
                                  subscriptionPlan.billingCycle.value
                                ]
                              }
                            </td>
                          </tr>
                          {subscriptionPlan.changed && (
                            <tr>
                              <td colSpan={2}>
                                <div className="d-flex justify-content-end pt-3">
                                  <SaveButton onClick={updateCheckout}>
                                    <Translate id="button.update" />
                                  </SaveButton>
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    ))}
                </OwnGrid.Col>
                <OwnGrid.Col md={12}>
               
                  {/* <Field
                    name="league"
                    placeholder={translate('form.league')}
                    component={Input}
                  />
                  <Field
                    class="form-group"
                    placeholder={translate('form.country')}
                    component={ReactSelect}
                    suggestions={countries}
                    isMulti={false}
                    isEveryOne={false}
                    props={{
                      value: country,
                      onChange: changeCountry,
                    }}
                  /> */}
                  {isCountryValid ? (
                    <React.Fragment>
                      <div
                        style={{
                          color: '#dc3545',
                          fontSize: '80%',
                          width: '100%',
                          marginTop: '.25rem',
                        }}
                      >
                        "country" is required
                      </div>
                      <br />
                    </React.Fragment>
                  ) : (
                    <br />
                  )}
               
                  {editable ? (
                    <>
                      <Field
                        name="hide_global_drills"
                        label="session planner"
                        component={() => (
                          <React.Fragment>
                            <label>
                              {' '}
                              {translate('form.session_planner_settings')}{' '}
                            </label>
                            <br />
                            <Checkbox
                              name="hide_global_drills"
                              id="hide_global_drills_chk"
                              checked={isGlobalDrill == 1 ? 1 : 0}
                              onChange={changeGlobalDrill}
                            />
                          </React.Fragment>
                        )}
                        inputProps={{ label: 'label' }}
                      />
                      <label for="hide_global_drills_chk">
                        {translate('form.hide_global_drills')}
                      </label>

                      <Field
                        name="hide_global_drills"
                        label="session planner"
                        component={() => (
                          <React.Fragment>
                            <label> </label>
                            <br />
                            <Checkbox
                              name="hide_global_training_packages"
                              id="hide_global_training_packages_chk"
                              checked={isGlobalTrainingPackages == 1 ? 1 : 0}
                              onChange={changeGlobalTrainingPackages}
                            />
                          </React.Fragment>
                        )}
                        inputProps={{ label: 'label' }}
                      />
                      <label for="hide_global_training_packages_chk">
                        {translate('form.hide_global_training_packages')}
                      </label>
                    </>
                  ) : null}

                  {/* <CardActions className="d-flex justify-content-end pt-3">
                    
                    <LinkBack to={fromPath.value} title={<Translate id="button.cancel" />} />
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit}
                    >
                      <Translate id="button.save" />
                    </Button>
                  </CardActions> */}
                </OwnGrid.Col>

                
              </OwnGrid.Row>
             
            </>
            )}
          </form>
          {/* Hero unit */}
          {!editable && (
            <Container
              maxWidth="md"
              component="main"
              className={classes.heroContent}
              style={{display: 'none'}}
            >
              <CardActions className={classes.centeredButton}>
                <Button
                  variant="outlined"
                  color="primary"
                  href="https://www.coachbetter.com/pricing/en/webinar-int/"
                  target="_blank"
                >
                  <Translate id="checkout.webinar" />
                </Button>
              </CardActions>
            </Container>
          )}
        </>
      )}
    </Translate>
  )
}

TeamCreateEditForm.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  form: PropTypes.string.isRequired, 
  initialValues: PropTypes.shape().isRequired,
  title: PropTypes.string.isRequired,
  fromPath: PropTypes.shape({
    value: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  goBack: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  editable: PropTypes.bool,
  upgradeView: PropTypes.bool,
  isCountryValid: PropTypes.bool.isRequired,
  change: PropTypes.func.isRequired,
  updateSubscription: PropTypes.func.isRequired,
  deleteSubscription: PropTypes.func.isRequired,
  resumeSubscription: PropTypes.func.isRequired,
  fetchAllAgeClasses: PropTypes.func.isRequired,
  changeCountyValid: PropTypes.func.isRequired,
  editCard: PropTypes.func.isRequired,
}

TeamCreateEditForm.defaultProps = {
  editable: false,
  upgradeView: false,
  open: false,
}

const TeamCreateEditFormConnect = connect(
  null,
  {
    updateSubscription: subscription.updateSubscription,
    deleteSubscription: subscription.deleteSubscription,
    resumeSubscription: subscription.resumeSubscription,
    fetchAllAgeClasses: ageClassesModule.fetchAllAgeClasses,
  }
)(TeamCreateEditForm)

export default reduxForm({
  enableReinitialize: true,
  validate: createJoiValidator(validationSchema),
})(TeamCreateEditFormConnect)
