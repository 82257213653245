import React from 'react'
import {
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  Tooltip
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded'

const useStyles = makeStyles((theme) => ({
  ulListSrcoll: {
    minHeight: 60,
    maxHeight: 250,
    width: '100%',
    margin: theme.spacing(0),
    padding: theme.spacing(0),
    overflowY: 'auto',
    '& .MuiListItemSecondaryAction-root': {
      right: 10,
      '& .MuiSvgIcon-root': {
        width: 20,
        height: 20,
      },
    },
    '& .MuiListItem-gutters': {
      paddingLeft: 0,
      paddingBottom: 0,
    },
    '& .MuiListItemIcon-root': {
      minWidth: 35,
    },
  },
}))

const PlayerAnalysisList = ({
  entities,
  handleClickOpenEdit,
  deleteRecord,
  handleClickOpenfor,
  label,
  type,
}) => {
  const classes = useStyles()

  return (
    <Grid item sm={12} xs={12} md={6}>
      <Typography variant="h4" component={Box}>
        {label} ({entities.length})
        <IconButton size="small" color="primary" style={{ float: 'right' }}>
          {' '}
          <AddCircleRoundedIcon
            onClick={() => handleClickOpenfor(type)}
            value={type}
          />{' '}
        </IconButton>
      </Typography>
      <List className={classes.ulListSrcoll}>

        {entities && entities.map((entity) => {
          return (
            <ListItem key={type + entity.id}>
              <ListItemIcon>
                {type === 'weakness' ? (
                  <RemoveIcon fontSize="small" />
                ) : (
                  <AddIcon fontSize="small" />
                )}
              </ListItemIcon>
              <ListItemText primary={entity.name} />
              <ListItemSecondaryAction>
                <Tooltip title="Edit" placement="top" arrow>
                  <IconButton edge="end" color="action" size="small">
                    <EditIcon
                      size="small"
                      onClick={() =>
                        handleClickOpenEdit(type, entity.name, entity.id)
                      }
                    />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete" placement="top" arrow>
                  <IconButton edge="end" color="action" size="small">
                    <DeleteIcon
                      size="small"
                      onClick={() => deleteRecord(entity.id)}
                    />
                  </IconButton>
                </Tooltip>
              </ListItemSecondaryAction>
            </ListItem>
          )
        })}
      </List>
    </Grid>
  )
}

PlayerAnalysisList.propTypes = {
  handleClickOpenEdit: PropTypes.func.isRequired,
  handleClickOpenfor: PropTypes.func.isRequired,
  deleteRecord: PropTypes.func.isRequired,
  entities: PropTypes.shape().isRequired,
  label:PropTypes.shape().isRequired,
  type:PropTypes.string.isRequired,
}
export default PlayerAnalysisList
