import React, { useState, useEffect, useMemo } from 'react'
import { withRouter, useParams, useLocation } from 'react-router-dom'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withLocalize, Translate } from 'react-localize-redux'
import { Grid, Box } from '@material-ui/core'
import useGeoLocation from 'react-ipgeolocation'
import {
  getLocale,
  setLocale,
  getSignupURL,
  setClub,
  setUserId
} from 'utils/localstorage'
import {
  auth as authModule,
  ageClasses as ageClassesModule,
  clubs as clubsModule,
  teams as teamsModule,
  seasons as seasonsModule
} from 'redux/modules'
import { identify } from 'utils/tracking'
import { Snackbars as Snackbar } from 'components'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { userlaneIdentify, userlaneInit } from 'utils/userlane'
import useStyles from '../styleComponents'
import SocialLogin from '../SocialLogin'
import urlConstructor from '../../utils/urlConstructor'
import { Header, SignupForm, UpdateForm, LegalText } from './components'
import languages from 'constants/languages'
import PROP_TYPES from 'constants/propTypes'

const DEFAULT_CLUB_ID = 1

const SignupPage = ({
  dispatchResetAuthState,
  authState,
  teamsState,
  dispatchSignup,
  ageClasses,
  dispatchUpdateTeam,
  setActiveLanguage,
  history,
  fetchClub,
  fetchAllAgeClasses,
  fetchSeasons,
  theme,
}) => {
  const classes = useStyles(theme)

  const { locale } = useParams()    // locale from URL (optional)
  const { search } = useLocation()  // URL query parameters
  const geoIP = useGeoLocation()

  const [ snackbar, setSnackbar ] = useState(false)
  const [ country, setCountry ] = useState('CH')

  const query = useMemo(() => {
    return new URLSearchParams(search)
  }, [ search ])

  // Get initial club ID from query parameter or from persistent storage. Otherwise, use default value.
  const [ clubId ] = useState(() => {
    if (query.get('club') && !Number.isNaN(query.get('club'))) {
      return parseInt(query.get('club'), 10)
    }

    const id =  authState.signupClubId ? authState.signupClubId : DEFAULT_CLUB_ID

    // @fixme: #storage this tweak needs to be fixed after fetchAllAgeClasses fix
    // Now, fetchAllAgeClasses automatically appends club ID in request which can be undefined
    // at that moment. Should be defined explicitly.
    localStorage.setItem('club', JSON.stringify({ id }))

    return id
  })

  /**
   * Get preferred language from URL :locale parameter or set it to default (EN)
   * and store value in local storage.
   */
  useEffect(() => {
    if (languages.some(lang => lang.code === locale)) {
      setLocale(locale)
      setActiveLanguage(locale)
    } else {
      setLocale('en')
      setActiveLanguage('en')
    }
  }, [ locale ])

  useEffect(() => {
    setCountry(geoIP.country)
  }, [ geoIP ])

  useEffect(() => {
    fetchClub(authState.signupClubId ? authState.signupClubId : clubId)
      .then(({ response }) => {
        // for backward compatibility
        setClub(response.data)
      })
  }, [ authState.signupClubId ])

  /**
   * Fetch club age classes.
   */
  useEffect(() => {
    if (clubId && authState.signupTeamId) {
      fetchAllAgeClasses({ team_id: authState.signupTeamId })
    }
  }, [ authState.signupTeamId ])

  /**
   * Handle steps navigation.
   */
  useEffect(() => {
    const { token, signupTeamId } = authState

    dispatchResetAuthState()

    if (token && signupTeamId && window.location.pathname.indexOf('/step2') < 0) {
      history.push(`${ getSignupURL() }/step2`)
    }

    if ((!token || !signupTeamId) && window.location.pathname.indexOf('/step2') > 0) {
      history.push(getSignupURL())
    }
  }, [])

  const handleLanguage = event => {
    // @todo: fix after LanguageDropdown - replace event with value

    setLocale(event.target.value)
    setActiveLanguage(event.target.value)

    const params = new URLSearchParams(window.location.search).toString()
    history.push(params ? `${getSignupURL()}?${params}`: getSignupURL())
  }

  const handleSignup = (email, password) => {
    setSnackbar(false)

    const params = {
      email,
      password,
      language: getLocale(),
      country,
      club_id: clubId,
      utm_source: query.get('utm_source'),
      utm_medium: query.get('utm_medium'),
      utm_campaign: query.get('utm_campaign'),
      utm_term: query.get('utm_term'),
      utm_content: query.get('utm_content'),
      time_zone:moment.tz.guess()
    }
    if(query.get('plan')){
      params.plan = query.get('plan')
    }

    dispatchSignup(params)
      .then(({ response }) => {
        // for backward compatibility
        setUserId(response.user_id)

        identify()
      })
      .then(() => {
        history.push(`${ getSignupURL() }/step2`)
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error(error)

        // @todo: get messages from back-end and handle every exception right way
        setSnackbar({
          message: <Translate id="login.wrong"/>,
          error: true,
        })
      })
  }

  const handleUpdate = (teamName, ageClass, phone) => {
    setSnackbar(false)

    dispatchUpdateTeam({
      id: authState.signupTeamId,
      name: teamName,
      league: teamName,
      hide_global_drills: 0,
      hide_global_training_packages: 0,
      country,
      phone,
      age_class: ageClass,
      no_details: true, // @todo: remove after API endpoint fix
    })
      .then(() => {
        return fetchSeasons(authState.signupTeamId)
      })
      .then(({ response }) => {
        const { data: seasons } = response
        const firstSeason = seasons.find(Boolean)
        const url = urlConstructor(firstSeason.team_id, firstSeason.id)

        userlaneIdentify(authState.userId, authState.locale, authState.signupClubId, authState.signupTeamId, false, country)
        userlaneInit()

        // navigate user to the team dashboard page
        history.push(url)
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error(error)
      })
  }

  return (
    <Grid container spacing={ 0 } className={ classes.root }>
      <Grid item xs={ false } md={ 5 }>
        <Box className={ classes.background }/>
      </Grid>
      <Grid item md={ 7 }>
        <Box className={ classes.centeredGridContent }>
          <Box className={ classes.loginBox }>
            <Header
              signedUp={ !!authState.signupTeamId }
              theme={ theme }
            />
            { authState.signupTeamId
              ?
              <UpdateForm
                ageClasses={ ageClasses.items }
                country={ country }
                onUpdate={ handleUpdate }
                inProgress={ ageClasses.isLoading || teamsState.isLoading }
                theme={ theme }
              />
              :
              <Box>
                { clubId === 1 && (
                  <SocialLogin
                    history={ history }
                    page="login"
                    theme={ theme }
                  />
                ) }
                <SignupForm
                  language={ getLocale() }
                  onLanguageChange={ handleLanguage }
                  onSignup={ handleSignup }
                  inProgress={ authState.isLoading }
                  theme={ theme }
                />
              </Box>
            }
            <LegalText theme={ theme } />
          </Box>
        </Box>
      </Grid>
      { snackbar && <Snackbar { ...snackbar } /> }
    </Grid>
  )
}

SignupPage.propTypes = {
  dispatchSignup: PROP_TYPES.func.isRequired,
  dispatchResetAuthState: PropTypes.func.isRequired,
  dispatchUpdateTeam: PropTypes.func.isRequired,
  fetchClub: PropTypes.func.isRequired,
  fetchAllAgeClasses: PROP_TYPES.func.isRequired,
  fetchSeasons: PropTypes.func.isRequired,
  history: PROP_TYPES.history.isRequired,
  setActiveLanguage: PROP_TYPES.func.isRequired,
  authState: PROP_TYPES.shape().isRequired,
  teamsState: PROP_TYPES.shape().isRequired,
  ageClasses: PROP_TYPES.shape().isRequired,
  theme: PropTypes.oneOfType([
    PropTypes.shape().isRequired,
    PropTypes.oneOf([ null ]).isRequired,
  ]).isRequired,
}

export default compose(
  withRouter,
  withLocalize,
  connect(
    ({ auth, ageClasses, teams, clubs }) => ({
      authState: auth,
      ageClasses,
      teamsState: teams,
      theme: clubs.current ? clubs.current.theme : {}
    }),
    {
      dispatchResetAuthState: authModule.reset,
      dispatchSignup: authModule.signup,
      fetchClub: clubsModule.fetchClub,
      fetchAllAgeClasses: ageClassesModule.fetchAllAgeClasses,
      dispatchUpdateTeam: teamsModule.updateTeam,
      fetchSeasons: seasonsModule.fetchSeasons
    }
  )
)(SignupPage)
