import React, { useState, useMemo } from 'react'
import { connect } from 'react-redux'
import { Translate } from 'react-localize-redux'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {
  Grid,
  Card,
  Box,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from '@material-ui/core'
import { HeartIcon } from 'containers'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import { TrainingPackageModal } from 'components'
import PROP_TYPES from 'constants/propTypes'
// import { TRAINING_PACKAGES_DASHBOARD_ROUTE } from 'constants/routes'

const MuiBox = withStyles(theme => ({
  root: {
    display: 'flex',

    '& .MuiBox-root': {
      margin: theme.spacing(0, 1.5, 0, 0),
      '& .MuiTypography-root': {
        fontWeight: 500,
        color: theme.palette.action,
        marginRight: theme.spacing(0.5),
      },
    },
  },
}))(Box)
const MuiCard = withStyles(theme => ({
  root: {
    border: '1px solid #e8e8e8',
    '& .MuiCardContent-root': {
      padding: theme.spacing(2, 2, 0),
    },
  },
  indicator: {
    backgroundColor: '#1890ff',
  },
}))(Card)

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    margin: theme.spacing(2, 0, 0),
    padding: theme.spacing(3),
  },
  toolbar: {
    minHeight: 38,
  },
  descriptionCell: {
    whiteSpace: 'nowrap',
    maxWidth: 'calc(100vw - 20px)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  media: {
    height: 140,
  },
  title: {
    flex: '1 1 0',
  },
  cardActions: {
    paddingLeft: theme.spacing(2),
  },
  fab: {
    position: 'absolute',
    top: theme.spacing(-3),
    right: theme.spacing(2),
  },
}))

const TrainingPackagesCarousel = ({ favoritesTrainingPackages }) => {
  const classes = useStyles()
  const [openedTrainingPackageId, changeOpenedTrainingPackageId] = useState(
    null
  )

  const openedTrainingPackage = useMemo(
    () => favoritesTrainingPackages.findById(openedTrainingPackageId),
    [openedTrainingPackageId, favoritesTrainingPackages]
  )

  const closeOpenedTrainingPackage = () => changeOpenedTrainingPackageId(null)

  return (
    <>
      {openedTrainingPackageId && (
        <TrainingPackageModal
          trainingPackage={openedTrainingPackage}
          onHide={closeOpenedTrainingPackage}
          open={openedTrainingPackageId}
        />
      )}
      {/* {favoritesTrainingPackages.length > 0 && (
        <SubLinkTrainingPackages to={TRAINING_PACKAGES_DASHBOARD_ROUTE}>
          <span>Training packages</span>
        </SubLinkTrainingPackages>
      )} */}
      <Grid container spacing={3}>
        {favoritesTrainingPackages.map(
          ({
            id: trainingPackageId,
            title: titleValue,
            image_url: imageUrl,
            duration: durationValue,
            number_of_players: playersNum,
          }) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              xl={2}
              key={trainingPackageId}
            >
              <MuiCard>
                <CardActionArea
                  onClick={() =>
                    changeOpenedTrainingPackageId(trainingPackageId)
                  }
                >
                  <CardMedia className={classes.media} image={imageUrl} />
                  <CardContent>
                    <Typography
                      gutterBottom
                      className={classes.descriptionCell}
                      variant="h4"
                      component="h4"
                    >
                      {titleValue}
                    </Typography>
                    <MuiBox>
                      <Box>
                        {' '}
                        <Typography variant="span" component="span">
                          {' '}
                          <Translate id="drills.players" /> :
                        </Typography>{' '}
                        {playersNum}
                      </Box>
                    </MuiBox>
                  </CardContent>
                </CardActionArea>
                <CardActions className={classes.cardActions}>
                  <Box className={classes.title}>
                    {' '}
                    <Typography variant="span" component="span">
                      <AccessTimeIcon />
                    </Typography>{' '}
                    {durationValue} min{' '}
                  </Box>
                  <HeartIcon
                    isActive
                    entity="trainingPackages"
                    entityId={trainingPackageId}
                  />
                </CardActions>
              </MuiCard>
            </Grid>
          )
        )}
      </Grid>
    </>
  )
}

TrainingPackagesCarousel.defaultProps = {
  favoritesTrainingPackages: [],
}

TrainingPackagesCarousel.propTypes = {
  favoritesTrainingPackages: PROP_TYPES.arrayOfObjects,
}

export default connect(({ favorites }) => ({
  favoritesTrainingPackages: favorites.items.trainingPackages,
}))(TrainingPackagesCarousel)
