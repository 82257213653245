import React from 'react'
import PropTypes from 'prop-types'
import { Draggable } from 'react-beautiful-dnd'
import styled from 'styled-components'
import { Box, Avatar, Checkbox, Typography } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import Tooltip from '@material-ui/core/Tooltip'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.625rem 0;
  padding: 0.425rem .5rem 0.425rem 1rem;
  border-radius: 8px;
  cursor: pointer;
  color: #151515;
  border: 1px solid #ededed;
  background: ${({ isActive }) => (isActive ? '#ffffff' : '#ededed')};
  box-shadow: ${({ isActive }) => (isActive ? 'none' : 'none')};
  .MuiAvatar-root {
    margin-right:10px;
  }
  .MuiBox-root{
    display: flex;
    align-items: center;
  }
`

const PlayerItem = ({
  name,
  isSelected,
  id,
  index,
  iconSrc,
  onSelect,
  isAnyPlayerSelected,
  notAvailableReason,

}) => {

  return (
    <Draggable
      key={id}
      draggableId={id}
      index={index}
      // @fixme: column header should not be draggable
      isDragDisabled={!isSelected && isAnyPlayerSelected}
    >
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          role="presentation"
        >
          {notAvailableReason ? (
            <Box component={Container} style={{ cursor: 'auto' }}>
              <Box>
                <Avatar src={iconSrc} />
                <Typography component="h5" variant="h5">{name}</Typography>
              </Box>
              <Tooltip title={notAvailableReason} placement="right">
                <InfoIcon
                  fontSize="small"
                  style={{ color: 'rgba(0, 0, 0, 0.26)' }}
                />
              </Tooltip>
            </Box>
          ) : (
            <Box onClick={onSelect}>
              <Box component={Container} isActive={isSelected || !isAnyPlayerSelected}>
                <Box>
                  <Avatar src={iconSrc} />
                  <Typography component="h5" variant="h5">{name}</Typography>
                </Box>
                <Checkbox defaultChecked={isSelected} />
              </Box>
            </Box>
          )}
        </div>
      )}
    </Draggable>
  )
}

PlayerItem.defaultProps = {
  isSelected: false,
  isAnyPlayerSelected: false,
  notAvailableReason: null,
}

PlayerItem.propTypes = {
  name: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  id: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]).isRequired,
  index: PropTypes.number.isRequired,
  iconSrc: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  isAnyPlayerSelected: PropTypes.bool,
  notAvailableReason: PropTypes.string,
}

export default PlayerItem
