import React from 'react'
// import { Translate } from 'react-localize-redux'
// import PropTypes from 'prop-types'
import { Paper } from '@material-ui/core'
import styled from 'styled-components'
// import { Card } from 'components'
import { SubLink } from 'containers'
// import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
// import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import GradeIcon from '@material-ui/icons/Grade'
import PROP_TYPES from 'constants/propTypes'

const StyledPaper = styled(Paper)`
  
  width: 100% 
  height: 100%;
  padding-bottom: 0;
  position: relative;
  .customList {
    margin: 0 10px 20px 16px;
    height: 189px;
    overflow: auto;
    .MuiListItem-container {
      list-style: none;
      &:nth-last-child(2) {
        margin-bottom: 2.5rem!important;
      }
    }
    .MuiListItem-gutters {
      padding-left: 4px;
      padding-top: 5px;
    padding-bottom: 5px;
    }
    .MuiAvatar-colorDefault {
      background-color: #255f64;
    }
    .MuiListItemSecondaryAction-root span{
      color: #255f64
    }
    .MuiListItemText-root{ 
      font-size: 14px;
      color: #062221;
      text-transform: capitalize
      p {
        margin: 0;
        font-size: 12px;
        color: #898989;
        text-transform: capitalize
      }
    }
  }
  .bottomOverlay{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    border-radius 0 0 8px 8px;
    background: linear-gradient(0deg, #ffffff, #ffffff00);
  }
`
const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px 16px;
  h4 {
    font-weight: 600;
    font-size: 16px;
    color: #062221;
    margin: 0;
  }
  span {
    color: #868686;
  }
`

const TrainingsPerformance = ({ players, title }) => {

  return (
    <StyledPaper>
      <CardHeader>
        <h4>{title}</h4>
        <span>{/* <MoreHorizIcon /> */}</span>
      </CardHeader>
      <div className="customList">
        {players.map(item => (
          <SubLink
            key={item.id}
            to={`/current-team/player/${item.id}/overview`}
          >
            <ListItem button>
              <ListItemAvatar>
                <Avatar
                  alt={item.first_name}
                  src={`/static/images/avatar/${item.id + 1}.jpg`}
                />
              </ListItemAvatar>
              <ListItemText id={1}>
                {item.first_name} <p>{item.position}</p>
              </ListItemText>
              {/* <ListItemText id={1} primary={item.first_name ? item.first_name : '' + ' ' + item.last_name ? item.last_name : ''} /> */}
              <ListItemSecondaryAction>
                <span>
                  <GradeIcon />
                </span>{' '}
                {item.rating ? item.rating.toFixed(2) : '0.0'}
              </ListItemSecondaryAction>
            </ListItem>
          </SubLink>
        ))}
        <div className="bottomOverlay" />
      </div>
    </StyledPaper>
  )
}

TrainingsPerformance.propTypes = {
  players: PROP_TYPES.shape().isRequired,
  title: PROP_TYPES.string.isRequired,
}

export default TrainingsPerformance
