import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { CardMedia, Box, Typography } from '@material-ui/core'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import { useDropzone } from 'react-dropzone'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Fab from '@material-ui/core/Fab'
import CloseIcon from '@material-ui/icons/Close'

const MuiFab = withStyles(theme => ({
  root: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
  },
}))(Fab)

const useStyles = makeStyles(theme => ({
  root: { width: '100%' },
  dropZone: {
    outline: 'none',
    display: 'flex',
    width: '100%',
    height: '250px',
    overflow: 'hidden',
    textAlign: 'center',
    position: 'relative',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    margin:'auto',
    padding: theme.spacing(5, 0),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#F4F6F8',
    border: '1px dashed rgba(0, 0, 0, 0.12)',
    '&:hover': {
      opacity: 0.72,
      cursor: 'pointer',
    },
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
      flexDirection: 'row',
    },
    [theme.breakpoints.down('sm')] :{
      marginBottom:'15px',
    }
  },
  dropZoneLessHeight:{
    width: 'calc(100% - 16px)',
    height: 'calc(100% - 16px)',
  },
  hasFile: {
    padding: '37% 0',
  },
  isDragActive: {
    opacity: 0.72,
  },
  isDragReject: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.light,
    backgroundColor: theme.palette.error.lighter,
  },
  isDragAccept: {},
  media: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 'calc(100% - 16px)',
    height: 'calc(100% - 16px)',
    top: 8,
    borderRadius: 8,
    objectFit: 'cover',
    position: 'absolute',
  },
  dropZoneText: {
    textAlign: 'center',
    padding: theme.spacing('30%', 0),
    marginLeft : 0,
  },
  dropZoneTextLessHeight: {
    padding: theme.spacing('5%', 0),
  },
  invalidHelperText:{
    display:'block',
    color:"#f44336"
  }
}))

const IMAGE_MAX_SIZE = 1024 * 1024 * 5
const ImageUploader = ({ input, meta = {}, isMulti }) => {
  const [preview, changePreview] = useState(null)
  const classes = useStyles()
  useEffect(() => {
    const newPreview =
      input.value &&
      (typeof input.value === 'string'
        ? input.value
        : URL.createObjectURL(input.value))

    changePreview(newPreview)

    return URL.revokeObjectURL(preview)
  }, [input.value])

  const removeImage = e => {
    e.stopPropagation()

    input.onChange('')
  }

  const onDrop = acceptedFiles => {
    acceptedFiles.forEach(file => {
      input.onChange(file)
    })
  }

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    isDragAccept,
  } = useDropzone({
    onDrop,
    maxSize: IMAGE_MAX_SIZE,
    accept: 'image/*',
    multiple: false,
  })

  const onDragMessage = isDragAccept ? (
    <Translate id="uploader.drop-or-select-file"/>
  ) : (
    <b>
       <Translate id="uploader.only-image-files-supports"/>
      <br />
      &#9888;
    </b>
  )

  const isInvalid = meta.touched && meta.error

  return (
    <>
    <div
      className={clsx(classes.dropZone, {
        [classes.isDragActive]: isDragActive,
        [classes.isDragAccept]: isDragAccept,
        [classes.isDragReject]: isDragReject,
        [classes.hasFile]: preview,
      },
      )}
    >
      <CardMedia
        image={preview}
        isInvalid={isInvalid}
        className={clsx(classes.media)}
        {...getRootProps()}
        sx={{
          top: 8,
          borderRadius: 1,
          objectFit: 'cover',
          position: 'absolute',
          width: 'calc(100% - 16px)',
          height: 'calc(100% - 16px)',
        }}
      >
        {!isMulti && <input {...getInputProps()} />}
        {!preview && (
          <>
            <Box sx={{ ml: { md: 5 } }} className={clsx(classes.dropZoneText, )}>
              {isDragActive ? (
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {onDragMessage}
                </Typography>
              ) : (
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  <CloudUploadIcon /> &nbsp; <Translate id="uploader.drop-or-select-file"/>
                </Typography>
              )}
            </Box>
          </>
        )}
        {preview && (
          <MuiFab size="small" color="secondary" onClick={removeImage}>
            <CloseIcon />{' '}
          </MuiFab>
        )}
      </CardMedia>

      {isMulti && (
        <Box>

          <input
            {...getInputProps({
              style: {
                display: 'block',
                visibility: 'hidden'
              },
            })}
          />
        </Box>
      )}
    </div>
      {isInvalid && (
        <Box type="invalid" className={classes.invalidHelperText}>
          {isInvalid}
        </Box>
      )}
      </>
  )
}

ImageUploader.defaultProps = {
  meta: {},
  isMulti: false,
}

ImageUploader.propTypes = {
  input: PropTypes.shape().isRequired,
  meta: PropTypes.shape(),
  isMulti: PropTypes.bool,
}

export default ImageUploader
