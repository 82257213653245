import React from 'react'
import styled from 'styled-components'
import StarIcon from './icons/StarIcon'
import PROP_TYPES from 'constants/propTypes'

const Stars = styled.div`
  display: flex;
  justify-content: flex-start;

  padding: 0 auto;
  font-size: 24px;
`

const RateRating = ({ rating, onChange }) => (
  <Stars>
    {[1, 2, 3, 4, 5].map(value => (
      <StarIcon
        key={value}
        active={rating >= value}
        onClick={() => onChange(value)}
      />
    ))}
  </Stars>
)

RateRating.defaultProps = {
  rating: null,
}

RateRating.propTypes = {
  rating: PROP_TYPES.number,
  onChange: PROP_TYPES.func.isRequired,
}

export default RateRating
