import React, { useMemo, useContext } from 'react'
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Button,
  Grid,
  Dialog,
} from '@material-ui/core'
import { connect } from 'react-redux'
import { matches } from 'redux/modules'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded'
import { makeStyles } from '@material-ui/styles'
import { Translate } from 'react-localize-redux'
import CircularSpinner from 'components/loader/CircularSpinner'
import { Context } from '../../MatchStateContext'
import MatchHighlightsDialog from './MatchHighlightsDialog'
import MatchHighlightsList from './MatchHighlightsList'

const useStyles = makeStyles(() => ({
  card: {
    marginBottom: '1.5rem',
  },
  cardContent:{
    maxHeight:"500px",
    overflowY:"auto",
    paddingTop:"0"
  }
}))

const MatchHighlights = ({
  createHighlight,
  deleteHighlight,
  updateHighlight,
  players,
  setLoading,
  teamName,
}) => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const [loadingcircular, setLoadingCircular] = React.useState(false)
  const { matchState, onFieldChange } = useContext(Context)
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const { highlights } = matchState
  const handleCreateHighlight = (data) => {
    setLoadingCircular(true)

    return createHighlight(data)
      .then((res) => {
        onFieldChange('highlights', res.response.data)
        setLoadingCircular(false)
      })
      .catch((err) => {
        console.error(err)
        setLoadingCircular(false)
      })
  }
  const handleDeleteHighlight = (id) => {
    return deleteHighlight(id)
      .then((res) => {
        onFieldChange('highlights', res.response.data)
      })
      .catch((err) => {
        console.error(err)
        setLoadingCircular(false)
      })
  }
  const handleUpdateHighlight = (id, data) => {
    setLoading(true)

    return updateHighlight(id, data)
      .then((res) => {
        onFieldChange('highlights', res.response.data)
        setLoading(false)
      })
      .catch((err) => {
        console.error(err)
        setLoadingCircular(false)
      })
  }
  const playersSuggestions = useMemo(
    () =>
      players
        .filter(
          (player) => player.first_name !== null || player.last_name !== null
        )
        .map((player) => ({
          value: player.id,
          title: `${player.first_name} ${player.last_name}`,
        })),
    [players]
  )

  return (
    <Translate>
      {({ translate }) => (
        <>
          <Dialog open={open} onClose={handleClose}>
            {loadingcircular && (
              <CircularSpinner loadingcircular={loadingcircular} />
            )}
            <MatchHighlightsDialog
              onClose={handleClose}
              handleSave={handleCreateHighlight}
              playersSuggestions={playersSuggestions}
              matchState={matchState}
              teamName={teamName}
            />
          </Dialog>
          <Card className={classes.card}>
            <CardHeader
              action={
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={handleClickOpen}
                  >
                    {translate('matches.highlights.add-highlight')}
                  </Button>
                </div>
              }
              title={
                <div>
                  <Grid container spacing={1}>
                    <Grid item xs="auto">
                      <Typography variant="h4" component="h4">
                        {translate('matches.highlights.match-progress')}
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              }
            />
            <CardContent className={classes.cardContent}>
              <Grid item xs={12} md={12}>
                {highlights && (
                  <MatchHighlightsList
                    matchState={matchState}
                    highlights={highlights}
                    deleteHighlight={handleDeleteHighlight}
                    playersSuggestions={playersSuggestions}
                    updateHighlight={handleUpdateHighlight}
                    setLoading={setLoading}
                    teamName={teamName}
                  />
                )}
                {!highlights.length > 0 && (
                  <Typography
                    variant="body2"
                    color="primary"
                    style={{ textAlign: 'center' }}
                    onClick={handleClickOpen}
                  >
                    <IconButton size="small" color="primary">
                      <AddCircleRoundedIcon />
                    </IconButton>
                    {translate(
                      'matches.highlights.start-adding-match-highlight'
                    )}
                  </Typography>
                )}
              </Grid>
            </CardContent>
          </Card>
        </>
      )}
    </Translate>
  )
}
MatchHighlights.defaultProps = {
  players: [],
  teamName: '',
}
MatchHighlights.propTypes = {
  createHighlight: PropTypes.func.isRequired,
  deleteHighlight: PropTypes.func.isRequired,
  updateHighlight: PropTypes.func.isRequired,
  players: PropTypes.shape(),
  setLoading: PropTypes.bool.isRequired,
  teamName: PropTypes.string,
}
export default connect(
  ({
    matches: {
      current: { players, team = {} },
    },
  }) => ({
    teamName: team.name,
    players,
  }),
  {
    createHighlight: matches.createMatchHighlight,
    deleteHighlight: matches.deleteMatchHighlight,
    updateHighlight: matches.updateMatchHighlight,
  }
)(MatchHighlights)
