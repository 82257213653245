import React from 'react'
import styled from 'styled-components'
import { Container, Row, Col } from 'react-bootstrap'
import PROP_TYPES from 'constants/propTypes'

const StyledContainer = styled(Container)`
  padding: 0 10px;
`

const OwnGrid = ({ children }) => (
  <StyledContainer fluid>{children}</StyledContainer>
)

OwnGrid.Row = styled(Row)`
  margin: 0 -10px;
`

OwnGrid.Col = styled(Col)`
  padding: 0 10px;
`

OwnGrid.propTypes = {
  children: PROP_TYPES.children.isRequired,
}

export default OwnGrid
