import React, { useEffect, useMemo, useState } from 'react'
import { withRouter, useParams, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Button, CircularProgress } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import {
  setUserId,
  getSignupURL,
  getLoginURL
} from 'utils/localstorage'
import { identify } from 'utils/tracking'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { auth as authModule } from '../redux/modules'
import PROP_TYPES from 'constants/propTypes'

// @todo: do not show Userline on this page

const LoginWithSocialSites = ({ authState, dispatchSocialLogin, dispatchResetAuthState, history }) => {
  const { provider } = useParams()
  const { search } = useLocation()

  const query = useMemo(() => {
    return new URLSearchParams(search)
  }, [ search ])

  const [ error, setError ] = useState(null)

  useEffect(() => {
    const providers = ['google', 'facebook']

    setError(null)
    dispatchResetAuthState()

    //@todo: add utms

    if (providers.includes(provider)) {
      dispatchSocialLogin({
        provider,
        locale: authState.locale,
        code: query.get('code'),
        scope: query.get('scope'),
      })
        .then(({ response }) => {
          // for backward compatibility
          setUserId(response.user_id)

          identify()

          if (response.signup_completed) {
            history.push('/')
          } else {
            history.push(`${getSignupURL()}/step2`)
          }
        })
        .catch(e => {
          setError(e.message)
        })
    } else {
      history.push('/')
    }
  }, [ provider ])

  if (error) {
    return (
      <div>
        <Alert severity="error">{ error }</Alert>
        <Button href={getLoginURL()}>Try again</Button>
      </div>
    )
  }

  return (
    <div>{ authState.isLoading ? <CircularProgress /> : 'Redirecting...' }</div>
  )
}

LoginWithSocialSites.propTypes = {
  history: PROP_TYPES.history.isRequired,
  dispatchSocialLogin: PropTypes.func.isRequired,
  dispatchResetAuthState: PropTypes.func.isRequired,
  authState: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    locale: PropTypes.string.isRequired,
  }).isRequired,
}

export default compose(
  withRouter,
  connect(
    ({ auth }) => ({
      authState: auth
    }),
    {
      dispatchSocialLogin: authModule.loginWithSocial,
      dispatchResetAuthState: authModule.reset
    }
  )
)(LoginWithSocialSites)
