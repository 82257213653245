import React from 'react'
import { Box, InputAdornment, TextField } from '@material-ui/core'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import { Translate } from 'react-localize-redux'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

const useStyles = makeStyles(() => ({
  timedots: {
    padding: '15px 8px',
    textAlign: 'center',
  },
}))

const TimeSection = ({
  existingData,
  setMinute,
  setErrorMinute,
  setSecond,
  setErrorSecond,
  errorMinute,
  errorSecond,
  minute,
  second
}) => {
  const classes = useStyles()
  const validateMinute = (e) => {
    if (e.target.value > 199 || e.target.value < 1) {
      return setErrorMinute(true)
    }
    setMinute(parseInt(e.target.value, 10))
    setErrorMinute(false)

    return true
  }
  const validateSecond = (e) => {
    if (e.target.value > 59 || e.target.value < 0) {
      return setErrorSecond(true)
    }
    setSecond(e.target.value > 0 ? parseInt(e.target.value, 10) : 0)
    setErrorSecond(false)

    return true
  }  

  return (
    <Box display="flex" justifyContent="flex-start">
      <Box>
        <TextField
          error={errorMinute}
          helperText={
            errorMinute ? (
              <Translate id="matches.highlights.please-enter-minute" />
            ) : (
              ''
            )
          }
          id="textbox-minute"
          label={<Translate id="time.minute" />}
          name="minute"
          variant="outlined"
          type="number"
          margin="dense"
          defaultValue={existingData ? existingData.minute : minute}
          onChange={validateMinute}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <AccessTimeIcon />
              </InputAdornment>
            ),
            inputProps: { min: 0}
          }}
        />
      </Box>
      <Box className={classes.timedots}>:</Box>
      <Box>
        <TextField
          error={errorSecond}
          helperText={
            errorSecond ? (
              <Translate id="matches.highlights.please-enter-second" />
            ) : (
              ''
            )
          }
          id="textbox-second"
          label={<Translate id="time.second" />}
          name="second"
          variant="outlined"
          type="number"
          margin="dense"
          defaultValue={existingData ? existingData.second : second}
          onChange={validateSecond}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <AccessTimeIcon />
              </InputAdornment>
            ),
            inputProps: { min: 0}
          }}
        />
      </Box>
    </Box>
  )
}
TimeSection.defaultProps = {
    existingData:null,
    // handleSave:() => 0,
    // handleUpdate:() => 0,
  }
TimeSection.propTypes = {
  existingData: PropTypes.shape(),
  errorMinute: PropTypes.bool.isRequired,
  errorSecond: PropTypes.bool.isRequired,
  minute: PropTypes.number.isRequired,
  second: PropTypes.number.isRequired,
  setErrorMinute: PropTypes.func.isRequired,
  setMinute: PropTypes.func.isRequired,
  setSecond: PropTypes.func.isRequired,
  setErrorSecond: PropTypes.func.isRequired,
}
export default TimeSection
