import { withRouter } from 'react-router-dom';
import ConnectSelector from 'containers/ConnectSelector';
import { LoadingSpinner } from 'components';


function Connect({history, location}) {

  const pathname = new URLSearchParams(window.location.search)
  const urlQuery = (pathname.has("error") && `?error=${pathname.get("error")}`
                  || pathname.has("code") && `?code=${pathname.get("code")}`);               

  return (
    <ConnectSelector redirectTo={`/connect${urlQuery}`}>
      <LoadingSpinner />
    </ConnectSelector>
  )
}

export default withRouter(Connect)
