import React from 'react'
import styled from 'styled-components'
import { Calendar } from 'containers'
import PropTypes from 'prop-types'

const MatchesCalendarLayout = ({ className, events }) => (
  <Calendar className={className} events={events} entity="matches" />
)

MatchesCalendarLayout.defaultProps = {
  events: [],
}

MatchesCalendarLayout.propTypes = {
  className: PropTypes.string.isRequired,
  events: PropTypes.arrayOf(PropTypes.object.isRequired),
}

export default styled(MatchesCalendarLayout)`
  .rbc-off-range {
    color: #ccc;
  }
`
