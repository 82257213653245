import styled from 'styled-components'

export default styled.div`
  padding: 4px 16px;
  margin: 0 5px 0 5px;
  ${({ minWidth }) => (minWidth ? `min-width: ${minWidth}px` : '')};
  background: ${({ background }) => background || 'transparent'};
  color: ${({ color }) => color || 'var(--secondary-green)'};

  border: ${({ color }) => color || 'var(--secondary-green)'} solid 1px;
  border-radius: 40px;

  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  cursor: pointer;

  font-size: ${({ fontSize }) => fontSize};

  #participantsField & {
    display: none;
  }

  ${({ isActive }) =>
    isActive &&
    'box-shadow: 0 3px 10px 1px rgba(64, 64, 64, 0.5); font-weight: bold; font-size: 100%'};
`
