import React, { useCallback, useEffect, useState } from "react";
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { withTheme } from 'styled-components/macro'
import { Card, CardHeader, Typography, Box } from '@material-ui/core'
import { rgba } from 'polished'
import { Radar } from 'react-chartjs-2'
import { SkillRatingsEditCreateWidget } from 'components'
import { Translate } from "react-localize-redux"
import { teams as teamsModule } from 'redux/modules'
import hasSubscription from 'utils/hasSubscription'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    margin: theme.spacing(0, 'auto', 2),
  },
}))

const RadarSkill = ({
  team,
  theme
}) => {

  const classes = useStyles()
  const firstRadarDataColor = rgba(theme.palette.primary.main, 0.3)
  const firstRadarDataBorderColor = rgba(theme.palette.primary.main, 1)

  const [radarSkills, changeRadarSkills] = useState([])
  const radarData = {

      labels: radarSkills.map(p => p.name),
      datasets: [
        {
          backgroundColor: firstRadarDataColor,
          borderColor: firstRadarDataBorderColor,
          pointBorderColor: firstRadarDataColor,
          pointBackgrounColor: firstRadarDataColor,
          pointRadius: 1,
          pointBorderWidth: 1,
          data: radarSkills.map(p => p.value)
        }
      ],
      options: {
        plugins: {
          legend: {
            display: false
          },
        },
        scales: {
          r: {
            beginAtZero: true,
            ticks: {
              stepSize: 10,
              display: false
            },
            pointLabels: {
              fontSize: 16,

              display: true
            }
          }
        }
      }
    }

  const labelsLength = useCallback(()=>radarSkills.length < 12,[radarSkills])

  useEffect(() => {
    const adjustChartLabels = (skill) => {

      const skillSplitArr = skill.split(' ')
      if (skillSplitArr.length > 2) {
        const maxWordCountEachRow = 2
        let count = 0
        const skillArr = []
        let tempArr = [];
        let tempStr = '';


        skillSplitArr.map((s, key) => {
          count += 1

          if (count <= maxWordCountEachRow) {
            tempArr.push(s)
          }
          if (count === maxWordCountEachRow || key === skillSplitArr.length - 1) {
            tempStr = tempArr.join(' ')
            skillArr.push(tempStr)
            count = 0
            tempArr = []
          }

          return true
        })

        return skillArr
      }

      return skill.charAt(0).toUpperCase() + skill.slice(1)
    }
    changeRadarSkills(team.skills_avg.map(skill => {
      return {
        ...skill,
        name: adjustChartLabels(skill.name)
      }
    }))
  }, [])

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" component="h4">
            <Translate id="team.overall-team-skills" />
          </Typography>
        }
        action={<>
           {hasSubscription() !== 'basic' && (
                    <SkillRatingsEditCreateWidget
                      changeRadarSkill={changeRadarSkills}
                      radarSkills={radarSkills}
                    />
                )}
        </>}
      />
      <Box className={classes.root} style={labelsLength ? { maxWidth: 350 } : { maxWidth: 500 }}>
        <Radar data={radarData} options={radarData.options} />
      </Box>
    </Card>
  )
}

RadarSkill.propTypes = {
  theme: PropTypes.string.isRequired,
  team: PropTypes.shape().isRequired
}

const RadarSkillWidget = withTheme(RadarSkill)

export default connect(
  null,
  {
      addSkill: teamsModule.createTeamSkill,
      updateSkill: teamsModule.updateTeamSkill,
      deleteSkill: teamsModule.deleteTeamSkill,
  }
)(RadarSkillWidget)
