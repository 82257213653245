import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-bootstrap'

const Radio = ({ input, type, meta, label, id }) => {
  const validType = meta.error ? 'invalid' : 'valid'

  return (
    <Form.Group>
      <Form.Check
        inline
        type={type}
        isValid={!meta.error}
        isInvalid={meta.error}
        checked={+input.value === id}
        {...input}
        value={id}
      />
      <Form.Check.Label>{label}</Form.Check.Label>
      <Form.Control.Feedback type={validType}>
        {meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  )
}

Radio.defaultProps = {
  type: 'radio',
  id: null,
}

Radio.propTypes = {
  input: PropTypes.shape().isRequired,
  type: PropTypes.string,
  meta: PropTypes.shape().isRequired,
  label: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default Radio
