import styled from 'styled-components'

export default styled.textarea`
  width: 100%;
  height: 100%;
  padding: 12px;
  border-radius: 8px;
  resize: none;
  background: transparent;
  border: 1px solid #d3d3d3;
  &.teamTextArea {
    height: 7rem;
  }
  &.player-development-tracking {
    min-height: 8.438rem;
  }
`
