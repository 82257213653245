import authReducer, * as auth from './auth'
import articlesReducer, * as articles from './articles'
import clubsReducer, * as clubs from './clubs'
import teamsReducer, * as teams from './teams'
import subscriptionReducer, * as subscription from './subscription'
import playersReducer, * as players from './players'
import staffReducer, * as staff from './staff'
import seasonsReducer, * as seasons from './seasons'
import matchesReducer, * as matches from './matches'
import trainingsReducer, * as trainings from './trainings'
import drillsReducer, * as drills from './drills'
import trainingPackagesReducer, * as trainingPackages from './trainingPackages'
import lineupsReducer, * as lineups from './lineups'
import videosReducer, * as videos from './videos'
import sequencesReducer, * as sequences from './sequences'
import favoritesReducer, * as favorites from './favorites'
import documentsReducer, * as documents from './documents'
import usersReducer, * as users from './users'
import eventsReducer, * as events from './events'
import ageClassesReducer, * as ageClasses from './age_classes'
import themesReducer, * as themes from './themes'
import thirdpartyReducer, * as thirdParty from './thirdParty'
import polarReducer, * as polar from './polar'
import taskGroupsReducer, * as taskGroups from './task_groups'
import generalEventsReducer, * as generalEvents from './general_events'

export {
  auth,
  articles,
  clubs,
  teams,
  subscription,
  players,
  staff,
  seasons,
  matches,
  trainings,
  drills,
  trainingPackages,
  lineups,
  videos,
  sequences,
  favorites,
  documents,
  users,
  events,
  ageClasses,
  themes,
  polar,
  thirdParty,
  taskGroups,
  generalEvents,
}

export default {
  auth: authReducer,
  articles: articlesReducer,
  clubs: clubsReducer,
  teams: teamsReducer,
  subscription: subscriptionReducer,
  players: playersReducer,
  staff: staffReducer,
  seasons: seasonsReducer,
  matches: matchesReducer,
  trainings: trainingsReducer,
  drills: drillsReducer,
  trainingPackages: trainingPackagesReducer,
  lineups: lineupsReducer,
  videos: videosReducer,
  sequences: sequencesReducer,
  favorites: favoritesReducer,
  documents: documentsReducer,
  users: usersReducer,
  ageClasses: ageClassesReducer,
  themes: themesReducer,
  events: eventsReducer,
  polar: polarReducer,
  thirdParty: thirdpartyReducer,
  taskGroups: taskGroupsReducer,
  generalEvents: generalEventsReducer,
}
