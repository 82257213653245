import React, { useContext, useState, useEffect } from 'react'
import { Translate } from 'react-localize-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Select, MenuItem, InputLabel, Box, Typography } from '@material-ui/core'
import { Modal } from 'components'
import { Context } from '../MatchStateContext'
import PROP_TYPES from 'constants/propTypes'

const useStyles = makeStyles(theme => ({
  lineupSelect: {
    width: 160,
    marginRight: 5
  },
  [theme.breakpoints.down('sm')]: {
    lineupSelectWrap: {
      marginTop: 0,
      marginBottom: 0,
    },
  },
}))
const Lineups = ({ lineups, availablePlayersCount }) => {
  const [lineupModalTitle, changeLineupModalTitle] = useState(null)

  const { matchState, changeMatchState } = useContext(Context)

  const activeLineupId = matchState.lineup_id

  const participants = matchState.matchPlayers.filter(p => p.participant === true)


  const changeActiveLineupId = id => {

    const activeLineup = lineups.findById(+id)

    const availablePlayers = matchState.matchPlayers
      .filter(p => p.in_roster === "AVAILABLE" || p.in_roster === "NOT_RESPONDED")
      .map(p => ({
        ...p,
        participant: participants.length > 0 ? p.participant : 0,
      }))

      const newPlayersOnField = []

    activeLineup.lineup.forEach(l => {
      const targetPlayer = availablePlayers
        .filter(p =>
          participants.length > 0
            ? !newPlayersOnField.findByUuid(p.uuid) && p.participant === true
            : !newPlayersOnField.findByUuid(p.uuid)
        )
        .find(p => p.position === l.player_position)

      if (targetPlayer) {
        newPlayersOnField.push({
          ...targetPlayer,
          participant: true,
          position_x: l.position_x,
          position_y: l.position_y,
        })
      }
    })

    changeMatchState({
      ...matchState,
      lineup_id: +id,
      matchPlayers: [
        ...matchState.matchPlayers.filter(p => p.in_roster==='NOT_AVAILABLE'),
        ...availablePlayers.filter(p => !newPlayersOnField.findById(p.id)),
        ...newPlayersOnField,
      ],
    })
  }

  const classes = useStyles()

  return (
    <>
      <Box display="flex">
        {lineupModalTitle && (
          <Modal
            title={`${lineupModalTitle}`}
            onHide={() => changeLineupModalTitle(null)}
            open={Boolean(lineupModalTitle)}
          />
        )}
        <Box display="flex" flexDirection="column">
          <InputLabel id="select-label">
            <Translate id="matches.line-ups-text" />
          </InputLabel>
          <Box display="flex" flexDirection="row">
          <Select
            value={activeLineupId}
            labelId="select-label"
            variant="outlined"
            onChange={e => changeActiveLineupId(e.target.value)}
            className={classes.lineupSelect}
            disabled={!availablePlayersCount &&  !participants.length}
          >
            {lineups.map(p => {
              return (
                <MenuItem value={p.id} key={p.id}>
                  {p.title}
                </MenuItem>
              )
            })}
          </Select>
          {!availablePlayersCount &&  !participants.length  && (
            <Typography variant="body2">
              <Translate id="matches.no-available-players-message" />
            </Typography>
          )}
          </Box>
        </Box>
      </Box>
    </>
  )
}

Lineups.defaultProps = {
  lineups: [],
}

Lineups.propTypes = {
  lineups: PROP_TYPES.arrayOfObjects,
}

export default Lineups

