import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Box, Link, Button, Typography } from '@material-ui/core'
import axios from 'axios'
import useStyles from './styleComponents'

window.REACT_APP_API_URL = process.env.REACT_APP_API_URL

const SocialLogin = ({ page, history, theme }) => {
  const classes = useStyles(theme)

  const [socialLoginLink, changeLoginLink] = React.useState({
    google: '',
    facebook: '',
  })

  const {
    location: { search },
  } = history

  if (search) {
    localStorage.setItem('utmtags', search)
  }

  // @todo: can't it be done on front-end via .env?
  const getSocalLinks = () => {
    axios
      .get(`${window.REACT_APP_API_ENDPOINT}/social/redirect/urls?page=${page}`)
      .then(res => {
        if (res.status === 200) {
          return res.data
        }
        throw Error
      })
      .then(res => {
        if (res) {
          changeLoginLink({
            google: res.google,
            facebook: res.facebook,
          })
        }
      })
      .catch(() => {})
  }

  React.useEffect(() => {
    getSocalLinks()

    return () => {
      localStorage.removeItem('utmtags')
    }
  }, [])

  return (
    <>
      <Grid container spacing={2} className="socialWrap">
        <Grid item xs={6}>
          <Button variant="outlined" size="large" href={socialLoginLink.google} className={classes.socialLogin}  component={Link}>
              <Box variant="span" component="span" className="color1">G</Box>
              <Box variant="span" component="span" className="color2">o</Box>
              <Box variant="span" component="span" className="color3">o</Box>
              <Box variant="span" component="span" className="color4">g</Box>
              <Box variant="span" component="span" className="color5">l</Box>
              <Box variant="span" component="span" className="color6">e</Box>
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button variant="outlined" size="large"  href={socialLoginLink.facebook} className={classes.socialLogin}  component={Link}>
            <Box variant="span" component="span" className="color7">facebook</Box>
         </Button>
        </Grid>
      </Grid>
    <Box mt={1} mb={2}> 
      <Grid container spacing={3}>
        <Grid item md={12} className="orDivider" >
          <hr />
          <Typography className="orDividerText">or</Typography>
        </Grid>
      </Grid>
      </Box>
    </>
  )
}

SocialLogin.propTypes = {
  page: PropTypes.string.isRequired,
  history: PropTypes.shape().isRequired,
}
export default SocialLogin
