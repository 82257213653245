import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import styled from 'styled-components'

const StyledDate = styled.span`
  ${({ isAvailable }) =>
    !isAvailable
      ? 'text-decoration: line-through; text-decoration-style: double'
      : ''};
`

export default currentSeason => {
  const DateHeader = ({ label, date }) => {
    const isAvailable =
      moment(date).format('YYYYMMDD') >=
        moment(currentSeason.start_date).format('YYYYMMDD') &&
      moment(date).format('YYYYMMDD') <=
        moment(currentSeason.end_date).format('YYYYMMDD')

    return <StyledDate isAvailable={isAvailable}>{+label}</StyledDate>
  }

  DateHeader.propTypes = {
    label: PropTypes.string.isRequired,
    date: PropTypes.shape().isRequired,
  }

  return DateHeader
}
