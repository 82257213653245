import styled from 'styled-components'

export const ItemsContainerStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const ItemStyled = styled.div`
  width: 160px;
  height: 125px;
  border-radius: 15px;
  background: #ddd;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);

  display: flex;
  justify-content: center;
  align-items: flex-start;

  margin: 0 25px 25px 0;
  position: relative;
  color: var(--text-dark);
  padding: 15px;

  > span {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
