import React from 'react'
import { Translate } from 'react-localize-redux'
import styled from 'styled-components'
import { MatchesLoader, TrainingsLoader } from 'loaders'
import { Card } from 'components'
import MatchesCalendarContainer from './MatchesCalendarContainer'

const StyledCard = styled(Card)`
  background: #f5f5f5;
  padding: 0;
  color: #888;
  box-shadow: #ddd 0 0 20px 7px;
`

const Title = styled.div`
  background: #f5f5f5;
  height: 60px;
  border-radius: 15px;
  box-shadow: #eee 0 10px 15px 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
`

const MatchesCalendar = () => (
  <MatchesLoader alwaysRenderChildren>
    <TrainingsLoader alwaysRenderChildren>
      <StyledCard>
        <Title>
          <Translate id="matches.choose-match" />
        </Title>
        <MatchesCalendarContainer />
      </StyledCard>
    </TrainingsLoader>
  </MatchesLoader>
)

export default MatchesCalendar
