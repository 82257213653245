/* eslint-disable no-param-reassign */
import React from 'react'
import { DraggablePlayer } from 'containers'
import { Translate } from 'react-localize-redux'
import { Box } from '@material-ui/core'
import { Heading, DraggableArea } from './styledComponents'
import PlayersList from './PlayersList'
import PROP_TYPES from 'constants/propTypes'

const AvailablePlayers = ({
  eventPlayers,
  fromPathname,
}) => {

  return (
    <>
      <Box display="flex" alignItems="center">
        <Heading variant="h4" component="h4">
          <Translate id="training.available" />
        </Heading>
      </Box>

      <DraggableArea>
        {eventPlayers.length === 0 ? (
          <Translate id="matches.drag-players" />
        ) : (
          <PlayersList
            players={eventPlayers}
            WrapperComponent={DraggablePlayer}
            fromPathname={fromPathname}
          />
        )}
      </DraggableArea>
    </>
  )
}

AvailablePlayers.defaultProps = {
  eventPlayers: [],
}

AvailablePlayers.propTypes = {
  eventPlayers: PROP_TYPES.arrayOfObjects,
  fromPathname: PROP_TYPES.string.isRequired,
}

export default AvailablePlayers
