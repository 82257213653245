import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { teams } from 'redux/modules'
import Loader from './Loader'
import PROP_TYPES from 'constants/propTypes'

const TeamsLoader = ({ children, fetchTeams, ...restProps }) => (
  <Loader fetchMethod={fetchTeams} {...restProps}>
    {children}
  </Loader>
)

TeamsLoader.propTypes = {
  children: PROP_TYPES.children.isRequired,
  fetchTeams: PropTypes.func.isRequired,
}

export default connect(
  null,
  { fetchTeams: teams.fetchTeams }
)(TeamsLoader)
