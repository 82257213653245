import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withTheme } from 'styled-components/macro'
import { Typography, Box } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import PROP_TYPES from 'constants/propTypes'

const Accordion = withStyles({
  root: {
    borderTop: '1px solid rgba(0, 0, 0, .125)',
    border: "unset",
    '&.MuiAccordion-root.Mui-expanded': {
      margin: "unset",
    },
    '&.MuiAccordion-rounded:first-child': {
      borderRadius: "unset",
    },
    '&:before': {
      backgroundColor: "unset"
    }
  },
})(MuiAccordion);

const Collapsible = ({
  children,
  title,
  className,
  defaultIsActive,
  titleRight,
  eventId
}) => {

  const [expanded, setExpanded] = useState(defaultIsActive)
  const onClick = () => setExpanded(!expanded)

  return (
    <Accordion className={className} expanded={expanded} id={eventId}>
      <AccordionSummary
        onClick={onClick}
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography variant='h4' component='h4'>
          {title}
        </Typography>
        {titleRight && <Box ml='auto'>{titleRight}</Box>}
      </AccordionSummary>
      <AccordionDetails>
        {children}
      </AccordionDetails>
    </Accordion>
  )
}

Collapsible.defaultProps = {
  title: '',
  className: 'trainingPackage',
  defaultIsActive: false,
  titleRight: '',
}

Collapsible.propTypes = {
  title: PropTypes.string,
  eventId: PropTypes.number.isRequired,
  className: PropTypes.string,
  children: PROP_TYPES.children.isRequired,
  defaultIsActive: PropTypes.bool,
  titleRight: PropTypes.string,
}

export default withTheme(Collapsible)
