import React from 'react'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { PlayerCreateEditForm } from 'containers'
import { players } from 'redux/modules'
import { createSubUrl } from 'utils/urlConstructor'
import moment from 'moment'
import { TEAM_DASHBOARD_ROUTE } from 'constants/routes'
import PROP_TYPES from 'constants/propTypes'
import { PLAYER_SUB_POSITIONS } from 'constants/player'

const PlayerCreate = ({
  history,
  match,
  location: { fromUrl, fromPathname = 'Team', position = 'midfield' },
  createPlayer,
}) => {
  const linkBack = fromUrl || createSubUrl(match, TEAM_DASHBOARD_ROUTE)

  const onSubmit = ({ number, ...val }) => {
    const birthdayInput = document.getElementById('birthday')
    const values = val
    values.joining_date = localStorage.getItem('joining_date')
      ? localStorage.getItem('joining_date')
      : null
    values.position = localStorage.getItem('position')
    values.sub_position = localStorage.getItem('subposition')
    values.birthday =
      moment(birthdayInput.value, 'DD/MM/YYYY').format('DD.MM.YY') !==
      'Invalid date'
        ? moment(birthdayInput.value, 'DD/MM/YYYY').format('YYYY-MM-DD')
        : null
    createPlayer({ ...values, number: +number }).then(() =>
      history.push(linkBack)
    )
  }
  const subPosition = PLAYER_SUB_POSITIONS[position]
    ? PLAYER_SUB_POSITIONS[position].find(Boolean).value
    : ''
  const initialValues = {
    position: position || 'midfield',
    sub_position: subPosition,
    foot: 'r',
    gender: 'm',
    tag: 'available',
    joining_date: '',
  }
  React.useEffect(() => {
    return function cleanup() {
      localStorage.removeItem('position')
      localStorage.removeItem('subposition')
    }
  }, [])

  return (
    <Translate>
      {({ translate }) => (
        <PlayerCreateEditForm
          form="playerCreate"
          initialValues={initialValues}
          caption={translate('team.create-player')}
          fromPath={{
            value: linkBack,
            title: fromPathname,
          }}
          onSubmit={onSubmit}
        />
      )}
    </Translate>
  )
}

PlayerCreate.propTypes = {
  history: PROP_TYPES.history.isRequired,
  match: PROP_TYPES.match.isRequired,
  location: PROP_TYPES.location.isRequired,
  createPlayer: PropTypes.func.isRequired,
}

export default connect(
  null,
  {
    createPlayer: players.createPlayer,
  }
)(PlayerCreate)
