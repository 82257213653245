import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Button, Link, Typography } from '@material-ui/core'
import MDInput from 'components/material/MDInput'
import { Translate } from 'react-localize-redux'
import { getLoginURL } from '../../../utils/localstorage'
import useStyles from '../../styleComponents'
import { LanguageDropdown } from '../../../components'

const SignupForm = ({ language, onLanguageChange, onSignup, inProgress, theme }) => {
  const classes = useStyles(theme)

  const [email, setEmail] = useState('')
  const [emailValidation, setEmailValidation] = useState({ valid: true, message: '' })
  const [password, setPassword] = useState('')
  const [passwordValidation, setPasswordValidation] = useState({ valid: true, message: '' })

  const handleEmail = (event) => {
    const pattern =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    setEmail(event.target.value)

    if (event.target.value.length === 0) {
      setEmailValidation({
        valid: false,
        message: <Translate id="login.required.email" />
      })
    } else if (!event.target.value.match(pattern)) {
      setEmailValidation({
        valid: false,
        message: <Translate id="login.valid.email" />
      })
    } else {
      setEmailValidation({ valid: true, message: '' })
    }
  }

  const handlePassword = (event) => {
    setPassword(event.target.value)

    if (event.target.value.length === 0) {
      setPasswordValidation({
        valid: false,
        message: <Translate id="login.required.password" />
      })
    } else if (event.target.value.length < 4) {
      setPasswordValidation({
        valid: false,
        message: <Translate id="signup.valid.password-length" />
      })
    } else {
      setPasswordValidation({ valid: true, message: '' })
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    if (email && emailValidation.valid && password && passwordValidation.valid) {
      onSignup(email, password)
    }
  }

  // @todo: implement password show/hide on component level
  return (
    <Box>
      <Box mb={1}>
        <MDInput
          value={email}
          onChange={handleEmail}
          disabled={inProgress}
          error={!emailValidation.valid}
          helperText={emailValidation.message}
          required
          fullWidth
          id="email"
          type="email"
          label="E-mail"
          variant="outlined"
          className={classes.formControl}
        />
      </Box>
      <Box mb={1}>
        <MDInput
          value={password}
          onChange={handlePassword}
          disabled={inProgress}
          error={!passwordValidation.valid}
          helperText={passwordValidation.message}
          required
          fullWidth
          id="password"
          type="password"
          label={<Translate id="signup.password" />}
          variant="outlined"
          className={classes.formControl}

          // type={signupData.showPassword ? "text" : "password"}
          // onClick={() =>
          //   changesignupData({
          //     ...signupData,
          //     showPassword: !signupData.showPassword,
          //   })
          // }
        />
      </Box>
      {/* @todo: fix classes inside the component; disable while in progress */}
      <Box mb={1}>
        <LanguageDropdown
          language={language}
          classes={useStyles}
          handleChange={onLanguageChange}
        />
      </Box>
      <Button
        onClick={handleSubmit}
        disabled={inProgress}
        fullWidth
        variant="contained"
        type="button"
        size="large"
        className={classes.button}
        mb={.5}
      >
        <Translate id="signup.signup-btn" />
      </Button>
      <Typography
        variant="body1"
        align="center"
        component="p"
        className={classes.typography}
      >
        <Translate id="signup.do-have-account" />&nbsp;
        <Link
          href={getLoginURL()}
          className={classes.link}
        >
          <Translate id="signup.login" />
        </Link>
      </Typography>
    </Box>
  )
}

SignupForm.propTypes = {
  language: PropTypes.string.isRequired,
  onLanguageChange: PropTypes.func.isRequired,
  onSignup: PropTypes.func.isRequired,
  inProgress: PropTypes.bool.isRequired,
}

export default SignupForm
