import React, { useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { DragLayer, DropTarget } from 'react-dnd'
import { compose } from 'redux'
import Grid from '@material-ui/core/Grid'
import { Card, CardContent, Box } from '@material-ui/core'
import { Context } from '../EventStateContext'
import AvailablePlayers from './AvailablePlayers'
import NotAvailablePlayers from './NotAvailablePlayers'
import NotRespondedPlayers from './NotRespondedPlayers'
import PROP_TYPES from 'constants/propTypes'

const LeftPart = ({ onDrop, connectDropTarget, eventPlayers }) => {
  const { eventState } = useContext(Context)

  const preparedEventPlayers = useMemo(() => {
    return eventState.eventPlayers.map((relatedPlayer) => {
      const id = relatedPlayer.uuid
      const { availability } = relatedPlayer
      const { avatar } = relatedPlayer.relationships.player?.relationships
      const { first_name } = relatedPlayer.relationships.player
      const { last_name } = relatedPlayer.relationships.player
      const { reason } = relatedPlayer

      return { id, availability, first_name, last_name, avatar, not_available_reason:reason}
    })
  }, [eventState.eventPlayers])

  const availableEventPlayers = useMemo(
    () =>
    preparedEventPlayers.filter(
        (eventPlayer) => eventPlayer.availability === 'AVAILABLE'
      ),
    [preparedEventPlayers]
  )

  const notAvailableEventPlayers = useMemo(
    () =>
    preparedEventPlayers.filter(
        (eventPlayer) => eventPlayer.availability === 'NOT_AVAILABLE'
      ),
    [preparedEventPlayers]
  )

  const notRespondedEventPlayers = useMemo(
    () =>
    preparedEventPlayers.filter(
        (eventPlayer) => eventPlayer.availability === 'NOT_RESPONDED'
      ),
    [preparedEventPlayers]
  )

  const fromPathname = 'Event'

  return (
    <Card component={Box} mb={3}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {connectDropTarget(
              <div>
                <AvailablePlayers
                  eventPlayers={availableEventPlayers}
                  fromPathname={fromPathname}
                />
              </div>
            )}
            <NotAvailablePlayers
              eventPlayers={notAvailableEventPlayers}
              onDrop={onDrop}
              fromPathname={fromPathname}
            />
            <NotRespondedPlayers
              eventPlayers={notRespondedEventPlayers}
              onDrop={onDrop}
              fromPathname={fromPathname}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

LeftPart.defaultProps = {
  eventPlayers: [],
}

LeftPart.propTypes = {
  onDrop: PropTypes.func.isRequired,
  connectDropTarget: PROP_TYPES.func.isRequired,
  eventPlayers: PROP_TYPES.arrayOfObjects,
}

const fieldTarget = {
  drop({ item: { playerId }, onDrop }) {
    onDrop(playerId)
  },
}

export default compose(
  DragLayer((monitor) => ({
    item: monitor.getItem(),
  })),
  DropTarget('player', fieldTarget, (targetConnect) => ({
    connectDropTarget: targetConnect.dropTarget(),
  })),
  connect(
    ({
      generalEvents: {
        current: { relatedPlayers },
      },
    }) => ({
      eventPlayers: relatedPlayers,
    })
  )
)(LeftPart)
