import React, { useState, useMemo, useCallback } from 'react'
import { connect } from 'react-redux'
import { DragDropContext } from 'react-beautiful-dnd'
import { Box, Paper } from '@material-ui/core'
import { videos as videosModule } from 'redux/modules'
import { InnrHead } from 'containers'
import { NotCompleted, Completed } from './components'
import PROP_TYPES from 'constants/propTypes'

const Videos = ({ videos, isVideosSaving, updateVideo, updateVideoLocal }) => {
  const [videoUploadProgress, changeVideoUploadProgress] = useState(0)

  const onDragEnd = useCallback(
    ({ destination, draggableId }) => {
      if (!destination) {
        return
      }

      const { droppableId: status } = destination

      const completed = +(status === 'completed')

      if (
        Boolean((videos.findById(draggableId) || {}).completed) !== completed
      ) {
        updateVideo(draggableId, { completed })
        updateVideoLocal({ id: draggableId, data: { completed } })
      }
    },
    [videos]
  )

  const notCompletedVideos = useMemo(
    () => videos.filter(({ completed }) => !completed),
    [videos]
  )

  const completedVideos = useMemo(
    () => videos.filter(({ completed }) => completed),
    [videos]
  )

  const title =
    isVideosSaving && videoUploadProgress
      ? `Videos (uploading ${videoUploadProgress}%)`
      : 'Videos'

  return (
    <Paper>
      <InnrHead title={title} />
      <Box p={3} pt={0}>
        <DragDropContext onDragEnd={onDragEnd}>
          <NotCompleted
            videos={notCompletedVideos}
            changeVideoUploadProgress={changeVideoUploadProgress}
          />
          <Completed videos={completedVideos} />
        </DragDropContext>
      </Box>
    </Paper>
  )
}

Videos.defaultProps = {
  videos: [],
  isVideosSaving: false,
}

Videos.propTypes = {
  videos: PROP_TYPES.arrayOfObjects,
  isVideosSaving: PROP_TYPES.bool,
  updateVideo: PROP_TYPES.func.isRequired,
  updateVideoLocal: PROP_TYPES.func.isRequired,
}

export default connect(
  ({ videos }) => ({
    videos: videos.items,
    isVideosSaving: videos.isSaving,
  }),
  {
    updateVideo: videosModule.updateVideo,
    updateVideoLocal: videosModule.updateVideoLocal,
  }
)(Videos)
