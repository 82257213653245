import React, { useMemo } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import sortBy from 'lodash.sortby'
import { MiniCalendar } from 'containers'
import urlConstructor from 'utils/urlConstructor'
import PROP_TYPES from 'constants/propTypes'

import { MATCH_DASHBOARD_ROUTE } from 'constants/routes'

const MiniCalendarWithActions = ({ availableMatches, match }) => {
  const {
    params: { teamId, seasonId, matchId },
  } = match

  // const classes = useStyles()
  const sortedMatches = useMemo(
    () => sortBy(availableMatches, ({ date }) => date),
    [availableMatches]
  )

  const onEventSelected = id => {
    window.location.href = urlConstructor(
      teamId,
      seasonId,
      MATCH_DASHBOARD_ROUTE.populateURLParams({ matchId: id })
    )
  }

  return (
    <>
      <MiniCalendar
        currentEventId={matchId}
        events={sortedMatches}
        onEventSelected={onEventSelected}
      />
    </>
  )
}

MiniCalendarWithActions.defaultProps = {
  availableMatches: [],
}

MiniCalendarWithActions.propTypes = {
  availableMatches: PROP_TYPES.arrayOfObjects,
  match: PROP_TYPES.match.isRequired,
}

export default compose(
  withRouter,
  connect(({ matches: { current } }) => ({
    availableMatches: current.matches,
  }))
)(MiniCalendarWithActions)
