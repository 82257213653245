import React from 'react'
import { withRouter } from 'react-router-dom';
import { DrillsLoader, FavoritesLoader } from 'loaders'
import DrillsWidgetContainer from './DrillsWidgetContainer'
import PROP_TYPES from 'constants/propTypes'

const DrillsWidget = ({
  ageClass,
  clubid,
  match: {
    params: { teamId, seasonId },
  }
}) => {

      return (
  <DrillsLoader
    params={{
      limit: 20,
      order_by: 'random',
      filter_by_age_class: 1,
      age_classes: [ageClass],
      team_id: teamId,
      season_id: seasonId
    }}
    alwaysRenderChildren
    passIsLoading
  >
    <FavoritesLoader entity="drills">
      <DrillsWidgetContainer clubid={clubid}/>
    </FavoritesLoader>
  </DrillsLoader>
)}
DrillsWidget.propTypes = {
  ageClass: PROP_TYPES.number.isRequired,
}

export default withRouter(DrillsWidget)
