// import * as types from 'src/constants'
import { THEMES } from '../constants'

const DEFAULT_PROPS = {
  isLoading: false,
  error: null,
}

export default {
  auth: {
    ...DEFAULT_PROPS,
    token: null,
    userId: null,
    locale: 'en',
    permissions: {
      clubs: [],
      teams: [],
    },
    signupClubId: null,
    signupTeamId: null,
    user:{
      id:null,
      first_name:null,
      last_name:null,
      email:null,
      phone:null,
      language:null,
      birthday:null,
      team_role:null,
      time_zone:null,
      datetime_format:null,
      avatar:null
    }
  },
  articles: {
    ...DEFAULT_PROPS,
    items: [],
    current: {},
  },
  clubs: {
    items: [],
    current: null
  },
  teams: {
    ...DEFAULT_PROPS,
    items: [],
    current: {},
  },
  subscription: {
    ...DEFAULT_PROPS,
    items: [],
    current: {},
  },
  players: {
    ...DEFAULT_PROPS,
    items: [],
  },
  staff: {
    ...DEFAULT_PROPS,
    items: [],
  },
  seasons: {
    ...DEFAULT_PROPS,
    items: {},
    attendance:[],
    current: {},
  },
  matches: {
    ...DEFAULT_PROPS,
    items: [],
    current: {},
  },
  trainings: {
    ...DEFAULT_PROPS,
    items: [],
    current: {},
  },
  generalEvents:{
    ...DEFAULT_PROPS,
    items: [],
    current: {},
  },
  drills: {
    ...DEFAULT_PROPS,
    items: [],
    itemsByCategory: {},
    current: {},
  },
  trainingPackages: {
    ...DEFAULT_PROPS,
    items: [],
    current: {},
  },
  lineups: {
    ...DEFAULT_PROPS,
    items: [],
    current: {},
  },
  videos: {
    ...DEFAULT_PROPS,
    isSaving: false,
    items: [],
    current: {},
  },
  sequences: {
    ...DEFAULT_PROPS,
    items: [],
  },
  favorites: {
    ...DEFAULT_PROPS,
    items: {},
  },
  documents: {
    ...DEFAULT_PROPS,
    currentFolder: {},
  },
  users: {
    ...DEFAULT_PROPS,
    items: [],
    current: {},
  },
  events: {
    ...DEFAULT_PROPS,
    items: [],
    old:[],
    current: {},
    recent: [],
    calendarEvents: [],
  },
  ageClasses: {
    ...DEFAULT_PROPS,
    items: [],
    current: {},
  },
  themes: {
    ...DEFAULT_PROPS,
    items: [],
    current: {
      currentTheme: THEMES.DEFAULT,
    },
  },
  polar: {
    ...DEFAULT_PROPS,
    items: [
      {
        name: "polar",
        img: "https://www.polar.com/accesslink-api/images/logo-edc17466.png",
        url: `https://auth.polar.com/oauth/authorize?response_type=code&scope=team_read&client_id=ec8340b6-7fe4-41eb-8dda-137d295305c6`,
        code: ""
      }
    ]
  },
  thirdParty: {
    ...DEFAULT_PROPS,
    items: []
  },
  taskGroups: {
    ...DEFAULT_PROPS,
    items: [],
    current: {},
  },
}
