import React from 'react'
import HttpsIcon from '@material-ui/icons/Https'
import hasSubscription from 'utils/hasSubscription'
import { SubLink } from 'containers'
import { makeStyles } from '@material-ui/core'
import { compose } from 'redux'
import { connect } from 'react-redux'
import {
  Teaser,
  TeaserLabel,
  TeaserIcon,
  Text,
  TeaserBox,
} from './styledComponents'
import PROP_TYPES from 'constants/propTypes'
import { TEAM_SUBSCRIPTION_UPGRADE_ROUTE } from 'constants/routes'
import { DEFAULT_CLUB_ID } from 'constants/club'
import { ELITE, PLUS_IN_CAPS, PRO_IN_CAPS } from 'constants/plans'

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%',
  },
  disableLink: {
    pointerEvents: 'none',
  },
}))

const TeaserTagOverlay = ({
  tagLabel,
  icon,
  isLabelTag,
  children,
  wrapperStyleObj,
  labelStyleObj,
  isSubscriptionPageRedirection,
  currentClubId,
}) => {
  const classes = useStyles()

  const isClubSolution = currentClubId !== DEFAULT_CLUB_ID
  const minimalTier = tagLabel.toUpperCase()

  // Normalize subscription name:
  // - convert old ELITE (previous name) to PRO (actual)
  // - format to uppercase case
  let currentTier = hasSubscription().toUpperCase();

  if (currentTier === ELITE.toUpperCase()) {
    currentTier = PRO_IN_CAPS
  }

  // Check if the current subscription is the same or higher than minimal required
  let hasMinimalTier;

  switch (minimalTier) {
    case PRO_IN_CAPS:
      hasMinimalTier = currentTier === PRO_IN_CAPS
      break;
    case PLUS_IN_CAPS:
      hasMinimalTier = currentTier === PLUS_IN_CAPS || currentTier === PRO_IN_CAPS
      break;
    default:
      hasMinimalTier = true;
      break;
  }

  // Display feature if has minimal subscription tier
  if (hasMinimalTier) {
    return children;
  }

  // Hide the feature on Club Solution without minimal subscription tier
  if (isClubSolution) {
    return null
  }

  // Show teaser for Default Club without minimal subscription tier
  return (
    <TeaserBox style={wrapperStyleObj}>
      <>
        <Teaser>
          <SubLink
            to={TEAM_SUBSCRIPTION_UPGRADE_ROUTE}
            className={
              !isSubscriptionPageRedirection
                ? classes.disableLink
                : classes.fullWidth
            }
            fullWidth
          >
            {isLabelTag && (
              <TeaserLabel style={labelStyleObj}>
                <Text>{tagLabel}</Text>
              </TeaserLabel>
            )}
            {icon && (
              <TeaserIcon>
                <HttpsIcon fontSize="small" />
              </TeaserIcon>
            )}
          </SubLink>
        </Teaser>
        {children}
      </>
    </TeaserBox>
  )
}

TeaserTagOverlay.defaultProps = {
  icon: false,
  isLabelTag: true,
  wrapperStyleObj: {},
  labelStyleObj: {},
  isSubscriptionPageRedirection: true,
}

TeaserTagOverlay.propTypes = {
  tagLabel: PROP_TYPES.string.isRequired,
  children: PROP_TYPES.children.isRequired,
  icon: PROP_TYPES.bool,
  isLabelTag: PROP_TYPES.bool,
  wrapperStyleObj: PROP_TYPES.shape(),
  labelStyleObj: PROP_TYPES.shape(),
  isSubscriptionPageRedirection: PROP_TYPES.bool,
  currentClubId: PROP_TYPES.number.isRequired,
}

export default compose(
  connect(
    ({ clubs }) => ({
      currentClubId: clubs.current.id,
    }),
    {}
  )
)(TeaserTagOverlay)
