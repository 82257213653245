import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Typography,
  Avatar,
  Checkbox,
  FormControlLabel,
  Tooltip,
  makeStyles,
} from '@material-ui/core'
import { Icon, NewForLineup } from 'containers/Player/components'
import InfoIcon from '@material-ui/icons/Info'
import { Context } from '../EventStateContext'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '0.2rem',
    padding: theme.spacing(1,1.6,1, 0),
    borderRadius: '8px',
    cursor: 'pointer',
    color: '#151515',
    border: '1px solid #ededed',
    background: '#ffffff',
  },
  typography: {
    padding: theme.spacing(0, 1)
  },
  infoIcon: {
    margin: '0 9px',
    color: theme.palette.grey[400],
  },
}))

const PlayerCard = (props) => {
  const classes = useStyles()

  const {
    first_name: firstName,
    last_name: lastName,
    avatar,
    id,
    not_available_reason: notAvailableReason,
  } = props
  const name = `${firstName} ${lastName}`

  const { eventState, onFieldChange } = useContext(Context)

  const onSelect = (event) => {
    onFieldChange(
      'eventPlayers',
      eventState.eventPlayers.updateByUuid(id, {
        isSelected: event.target.checked,
      })
    )
  }

  return (
    <Box className={classes.root}>
      <FormControlLabel
        control={
          notAvailableReason ? (
            <Tooltip title={notAvailableReason} placement="right">
              <InfoIcon fontSize="small" className={classes.infoIcon} />
            </Tooltip>
          ) : (
            <Checkbox onChange={onSelect} color="secondary" />
          )
        }
        label={
          <Box display="flex" alignItems="center">
            <Avatar src={avatar?.full_url} />
            <Typography component="h5" variant="h5" className={classes.typography}>
              {name}
            </Typography>
          </Box>
        }
        labelPlacement="start"
      />
    </Box>
  )
}

PlayerCard.defaultProps = {
  first_name: null,
  last_name: null,
  not_available_reason: '',
}

PlayerCard.propTypes = {
  last_name: PropTypes.string,
  first_name: PropTypes.string,
  avatar: PropTypes.shape().isRequired,
  id: PropTypes.number.isRequired,
  not_available_reason: PropTypes.string,
}

PlayerCard.NewForLineup = NewForLineup
PlayerCard.Icon = Icon

export default PlayerCard
