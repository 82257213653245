import { styled } from '@material-ui/core/styles'
import { Box, Typography } from '@material-ui/core'

export const Heading = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(1, 0),
  padding: theme.spacing(0.3, 1),
  fontSize: 16,
  fontWeight: 600,
}))

export const DraggableArea = styled(Box)(({ theme }) => ({
  margin: theme.spacing(0),
  border: '#e9ecef dashed 3px',
  minHeight: 192,
  borderRadius: 8,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))
