/* eslint-disable */
import React from "react";
import { withLocalize, Translate } from "react-localize-redux";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { AppBar, Toolbar, Box, Avatar, Tooltip, IconButton } from "@material-ui/core";
import { setLocale, getLocale } from "utils/localstorage";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { SubLink } from "containers";
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import Logo from "assets/images/logo-black.svg";
import { LanguageDropdown } from "components";
import { LOGOUT_ROUTE } from 'constants/routes'
import { themes as themesModule } from "redux/modules";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  link: {
      flex: '1 1 0',
  },
  Toolbar: {

  },
  Avatar: {
    width: 184,
  },
  content: {
    flexGrow: 1,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingTop: "4rem",
    width: 1,
    margin: "0 auto",
  },
}));

const TeamsHeader = ({
  children,
  teams,
  match,
  getClubTheme,
  setActiveLanguage,
}) => {
  const classes = useStyles();
  const {
    params: { locale, teamId },
  } = match;

  const [language, changeLanguage] = React.useState(getLocale("locale"));
  setLocale(language);

  const currentTeam = teams
    ? teams.find((team) => {
        return team.id == +teamId;
      })
    : [];
  const handleChange = (event) => {
    const lang = event.target.value;
    changeLanguage(lang);
    setActiveLanguage(lang);
    setLocale(lang);
  };
  React.useEffect(() => {
    if (currentTeam && currentTeam.club && currentTeam.club.theme) {
      getClubTheme(currentTeam.club.id);
    }
  }, []);
  return (
    <>
      <CssBaseline />
      <Box className={classes.root}>
        <AppBar position="fixed" color="" elevation={0}>
          <Toolbar variant="regular" className={classes.Toolbar}>
            <Box className={classes.link}> 
                <SubLink to="/" >
                  <Avatar
                    src={
                      currentTeam && currentTeam.club && currentTeam.club.theme
                        ? currentTeam.club.theme.logo
                        : Logo
                    }
                    variant="square"
                    className={classes.Avatar}
                    alt="Coachbetter"
                  />{" "}
                </SubLink>
                </Box>
                <Box display="flex" alignItems="center"> 
                <LanguageDropdown
                  classes={classes}
                  language={language}
                  margin="dense"
                  handleChange={handleChange}
                />
                <Box ml={.5}> 
                <SubLink to={LOGOUT_ROUTE} >
                <Tooltip title={<Translate id="navbar.logout"/>} arrow>
                <IconButton variant="contained" size="medium" color="primary">
                  <ExitToAppIcon/>
                  </IconButton>
                  </Tooltip>
                  </SubLink>
                </Box>
               
                </Box> 
          </Toolbar>
        </AppBar>
        <Box className={classes.content}>{children}</Box>
      </Box>
    </>
  );
};

TeamsHeader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element.isRequired,
    PropTypes.arrayOf(PropTypes.element.isRequired).isRequired,
  ]).isRequired,
  teams: PropTypes.arrayOf(PropTypes.object.isRequired),
  match: PropTypes.shape().isRequired,
  getClubTheme: PropTypes.func.isRequired,
  setActiveLanguage: PropTypes.func.isRequired,
};

export default compose(
  connect(null, {
    getClubTheme: themesModule.getClubTheme,
  }),
  withLocalize,
  withRouter
)(TeamsHeader);
