import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Typography } from '@material-ui/core'
// @todo: implement progress
// @link: https://codepen.io/shankarcabus/pen/GzAfb

const Circle = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 110px;
  border-bottom: 1px solid #f3f3f3;
  height: 60px;
  margin: 12px auto 12px;
  // border-radius: 50%;
  // border: 4px solid var(--primary-green);

  text-align: center;

  @media (max-width: 1024px) {
    width: 105px;
    height: 105px;
    .col-md-4 & {
      width: 95px;
      height: 95px;
    }
  }
`

const TypographyCustom = styled(Typography)`
  font-size: 1.5rem;
  line-height: 1.5;
`

const TotalValue = styled.div`
  margin: 0 10px;
  font-size: 1.5rem;
  line-height: 1.5;
  text-align: center;
`

const Title = styled.div`
  font-size: 16px;
  margin-top: 20px;
  letter-spacing: 0.3px;
  text-align: center;
`

const SummaryCircle = ({ value, maxValue, title, inverse }) => (
  <div>
    <Title inverse={inverse}>{title}</Title>
    <Circle>
      <TypographyCustom
        variant="h3"
        component="h3"
        inverse={inverse}
        isSingle={!maxValue}
      >
        {value}
      </TypographyCustom>
    </Circle>
    <TotalValue inverse={inverse}>{maxValue}</TotalValue>
  </div>
)

SummaryCircle.defaultProps = {
  maxValue: null,
  inverse: false,
}

SummaryCircle.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]).isRequired,
  maxValue: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]),
  title: PropTypes.string.isRequired,
  inverse: PropTypes.bool,
}

export default SummaryCircle
