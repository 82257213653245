import React, { useState } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import { Box, Button, Typography} from '@material-ui/core'
import { DataGrid } from '@mui/x-data-grid'
import { users as usersModule, staff as staffModule } from 'redux/modules'
import { Translate } from 'react-localize-redux'
import useStyles from '../styled'
import { staffColumns, SORT_NAME } from '../Constants'
import StaffCreateDialog from './StaffCreateDialog'
import PROP_TYPES from 'constants/propTypes'

const StaffAccess = ({ allStaff, handleClick, revokeUserRole, deleteStaff, theme }) => {

  const staffRows = allStaff.map((staff) => {
    return {
      id: staff.id,
      first_name: staff.first_name,
      last_name: staff.last_name,
      firstName: `${staff.first_name} ${staff.last_name}`,
      email: staff.assigned_user?.email,
      position: staff.position,
      userRoleId: staff.user_with_role?.id,
      deleteStaff,
      revokeUserRole,
      handleClick
    }
  })

  const [sortModel, setSortModel] = React.useState(SORT_NAME);

  useState(() => {
  }, [allStaff]);

  const classes = useStyles(theme)
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return <Box className={classes.root}>
    <Button variant="contained" size="small" color="primary" onClick={handleClickOpen} style={{marginBottom: '1.5rem'}}>
    <Typography variant="button">
          <Translate id="team.add-staff" />
        </Typography>
    </Button>
    <StaffCreateDialog
      open={open}
      onClose={handleClose}
    />
    <DataGrid
      className={classes.table}
      rows={staffRows}
      columns={staffColumns}
      pageSize={100}
      rowHeight={60}
      headerHeight={45}
      rowsPerPageOptions={[5]}
      disableSelectionOnClick
      disableColumnFilter
      hideFooter
      disableColumnSelector
      sortModel={sortModel}
      onSortModelChange={(model) => setSortModel(model)} />
  </Box>
}

StaffAccess.defaultProps = {
  allStaff: [],
}

StaffAccess.propTypes = {
  handleClick: PROP_TYPES.func.isRequired,
  allStaff: PROP_TYPES.arrayOfObjects,
  revokeUserRole: PROP_TYPES.func.isRequired,
  deleteStaff: PROP_TYPES.func.isRequired,
  theme: PROP_TYPES.theme.isRequired
}

export default compose(
  withRouter,
  connect(
    ({ teams }) => ({
      team: teams.current
    }),
    {
      revokeUserRole: usersModule.revokeUserRole,
      deleteStaff: staffModule.deleteStaff,
    }
  )
)(StaffAccess)
