import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { documents as documentsModule } from 'redux/modules'
import DocumentsContainer from './DocumentsContainer'
import PROP_TYPES from 'constants/propTypes'

const Documents = ({
  match: {
    params: { teamId: currentTeamId, folderId },
  },
  match,
  location,
  fetchRootFolderContent,
  fetchFolderContent,
}) => {
  useEffect(() => {
    if (!folderId) {
      fetchRootFolderContent(currentTeamId)
    } else {
      fetchFolderContent(currentTeamId, folderId)
    }
  }, [folderId])

  return <DocumentsContainer match={match} location={location} />
}

Documents.propTypes = {
  match: PROP_TYPES.match.isRequired,
  location: PROP_TYPES.location.isRequired,
  fetchRootFolderContent: PROP_TYPES.func.isRequired,
  fetchFolderContent: PROP_TYPES.func.isRequired,
}

export default connect(
  null,
  {
    fetchRootFolderContent: documentsModule.fetchRootFolderContent,
    fetchFolderContent: documentsModule.fetchFolderContent,
  }
)(Documents)
