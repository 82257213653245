import React from 'react'
import { Translate } from 'react-localize-redux'
import HomeIcon from '@material-ui/icons/Dashboard'
import TrainingIcon from '@material-ui/icons/EventAvailable'
import KnowledgeBaseIcon from '@material-ui/icons/School'
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter'
import TeamIcon from '@material-ui/icons/SportsSoccer'
import VideoAnalysisIcon from '@material-ui/icons/VideoLibrary'
import HelpIcon from '@material-ui/icons/Help'
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';

import {
  EVENT_ROUTE,
  TRAINING_DASHBOARD_ROUTE,
  ARTICLES_DASHBOARD_ROUTE,
  ARTICLE_ROUTE,
  TEAM_MENU_ROUTE,
  TEAM_DASHBOARD_ROUTE,
  TEAM_EDIT_ROUTE,
  TEAM_ACCESS_ROUTE,
  TEAM_ACCESS_CREATE_ROUTE,
  TEAM_ACCESS_EDIT_ROUTE,
  DOCUMENTS_ROUTE,
  TEAM_PLAYER_CREATE_ROUTE,
  TEAM_UPGRADE_ROUTE,
  TEAM_PLAYER_EDIT_ROUTE,
  TEAM_PLAYER_DASHBOARD_ROUTE,
  VIDEO_ANALYSIS_ROUTE,
  DRILLS_DASHBOARD_ROUTE,
  VIDEO_ROUTE,
  TASK_MANAGEMENT_ROUTE,
} from 'constants/routes'

export default [
  {
    id: "DASHBOARD_ROUTE",
    to: '/',
    title: <Translate id="menu.dashboard" />,
    icon: <HomeIcon />,
    rootPath: '',
    forRoutes: [''],
  },
  {
    id: "EVENT_ROUTE",
    to: EVENT_ROUTE,
    title: <Translate id="menu.event" />,
    icon: <TrainingIcon />,
    rootPath: EVENT_ROUTE,
    forRoutes: [EVENT_ROUTE, TRAINING_DASHBOARD_ROUTE],
  },
  {
    id: "TEAM_MENU_ROUTE",
    to: TEAM_MENU_ROUTE,
    title: <Translate id="menu.team" />,
    icon: <TeamIcon />,
    rootPath: TEAM_DASHBOARD_ROUTE,

    forRoutes: [
      TEAM_MENU_ROUTE,
      TEAM_DASHBOARD_ROUTE,
      TEAM_EDIT_ROUTE,
      TEAM_ACCESS_ROUTE,
      TEAM_ACCESS_CREATE_ROUTE,
      TEAM_ACCESS_EDIT_ROUTE,
      DOCUMENTS_ROUTE,
      TEAM_PLAYER_CREATE_ROUTE,
      TEAM_UPGRADE_ROUTE,
      TEAM_PLAYER_EDIT_ROUTE,
      TEAM_PLAYER_DASHBOARD_ROUTE,
    ],
  },
  {
    id: "DRILLS_DASHBOARD_ROUTE",
    to: DRILLS_DASHBOARD_ROUTE,
    title: <Translate id="menu.activities" />,
    icon: <FitnessCenterIcon />,
    rootPath: DRILLS_DASHBOARD_ROUTE,
  },
  {
    id: "ARTICLES_DASHBOARD_ROUTE",
    to: ARTICLES_DASHBOARD_ROUTE,
    title: <Translate id="menu.knowledge" />,
    icon: <KnowledgeBaseIcon />,
    rootPath: ARTICLES_DASHBOARD_ROUTE,
    forRoutes: [ARTICLES_DASHBOARD_ROUTE, ARTICLE_ROUTE],
  },
  {
    id: "TASK_MANAGEMENT_ROUTE",
    to: TASK_MANAGEMENT_ROUTE,
    title: <Translate id="menu.task" />,
    icon: <FormatListBulletedIcon />,
    rootPath: TASK_MANAGEMENT_ROUTE,
    forRoutes: [TASK_MANAGEMENT_ROUTE],
  },
  {
    id: "VIDEO_ANALYSIS_ROUTE",
    to: VIDEO_ANALYSIS_ROUTE,
    title: <Translate id="menu.video-analysis" />,
    icon: <VideoAnalysisIcon />,
    rootPath: VIDEO_ANALYSIS_ROUTE,
    forRoutes: [VIDEO_ANALYSIS_ROUTE, VIDEO_ROUTE],
  },
  {
    id: "FAQ_ROUTE",
    to: 'https://www.coachbetter.com/faqs',
    title: <Translate id="menu.help" />,
    icon: <HelpIcon />,
    isFullPath: true,
    target: 'blank',
    rootPath: 'https://www.coachbetter.com/faqs',
    forRoutes: [''],
  },
]
