import React from 'react'
import {
  DialogContentText,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import SaveUpdateButtons from './SaveUpdateButtons'
import PlayersAutocomplete from './PlayersAutocomplete'
import TeamSelection from './TeamSelection'
import { swapReason } from 'constants/match'

const AddEditReplacement = ({
  onClose,
  existingData,
  teamName,
  errorMinute,
  errorSecond,
  minute,
  second,
  setErrorMinute,
  playersSuggestions,
  matchState,
  handleSave,
  handleUpdate,
  resetCommonFields
}) => {
  const [scoredTeam, setScoredTeam] = React.useState(
    existingData && existingData.scored_team === null ? 0 : 1
  )
  const [p1value, setP1Value] = React.useState()
  const [p2value, setP2Value] = React.useState()
  const [reason, setReason] = React.useState(
    existingData ? existingData.reason : swapReason[0].key
  )
  const [resetPlayer, setResetPlayer] = React.useState(false)

  const resetForm = () => {
    resetCommonFields()
    setScoredTeam(1)
    setP1Value('')
    setP2Value('')
    setReason(swapReason[0].key)
    setResetPlayer(true)
  }
  const handleScoredTeam = (val) => {
    if (val === 0) {
      setP1Value('')
      setP2Value('')
    }
    setScoredTeam(val)
  }
  const save = (exit = false) => {
    if (errorMinute || errorSecond) return false

    if (!minute) {
      setErrorMinute(true)

      return false
    }
    const createData = {
      match_id: matchState.id,
      type: 'substitution',
      scored_team: scoredTeam,
      reason,
      minute,
      second,
      player_1: p1value,
      player_2: p2value,
    }
    handleSave(createData).then(() => {
      resetForm()
      if (exit === true) {
        onClose()
      }
    })

    return false
  }
  const update = (highLightId) => {
    if (errorMinute || errorSecond) return false

    const updateData = {
      _method: 'put',
      type: 'substitution',
      scored_team: scoredTeam,
      reason,
      minute,
      second,
      player_1: p1value,
      player_2: p2value,
    }
    handleUpdate(highLightId, updateData)
    onClose()

    return false
  }

  return (
    <>
      <DialogContentText>
        <Translate id="matches.highlights.swap-heading" />
      </DialogContentText>
      <Grid item style={{marginTop: '0.25rem'}}>
        <TeamSelection
          selectedTeam={scoredTeam}
          handleTeam={handleScoredTeam}
          ownTeam={teamName}
        />
      </Grid>
      <Grid item>
        <FormControl variant="outlined" margin="dense">
          <InputLabel id="demo-simple-select-label">
            <Translate id="matches.highlights.reason-for-substitution" />
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="match-type-selected-outlined"
            value={reason}
            onChange={(event) => {
              return setReason(event.target.value)
            }}
            label={<Translate id="matches.highlights.reason-for-substitution" />}
          >
            {swapReason.map((p) => {
              return (
                <MenuItem key={p.key} value={p.key}>
                  {p.displayValue}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </Grid>
      {scoredTeam !== 0 && (
        <>
          <Grid item>
            <PlayersAutocomplete
              setPValue={setP2Value}
              label={<Translate id="matches.highlights.new-player" />}
              savedPlayer={
                existingData && existingData.player_2
                  ? existingData.player_2
                  : 0
              }
              players={playersSuggestions}
              resetPlayer={resetPlayer}
              setResetPlayer={setResetPlayer}
            />
          </Grid>
          <Grid item>
            <PlayersAutocomplete
              setPValue={setP1Value}
              label={<Translate id="matches.highlights.player-replaced" />}
              savedPlayer={
                existingData && existingData.player_1
                  ? existingData.player_1
                  : 0
              }
              players={playersSuggestions}
              resetPlayer={resetPlayer}
              setResetPlayer={setResetPlayer}
            />
          </Grid>
        </>
      )}
      <SaveUpdateButtons
        save={save}
        existingData={existingData}
        update={update}
      />
    </>
  )
}
AddEditReplacement.defaultProps = {
  onClose: () => 0,
  matchState: null,
  teamName: '',
  existingData: null,
  handleSave: () => 0,
  handleUpdate: () => 0,
}
AddEditReplacement.propTypes = {
  onClose: PropTypes.func,
  handleSave: PropTypes.func,
  handleUpdate: PropTypes.func,
  playersSuggestions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  existingData: PropTypes.shape(),
  matchState: PropTypes.shape(),
  teamName: PropTypes.string,
  errorMinute: PropTypes.bool.isRequired,
  errorSecond: PropTypes.bool.isRequired,
  minute: PropTypes.number.isRequired,
  second: PropTypes.number.isRequired,
  setErrorMinute: PropTypes.func.isRequired,
  resetCommonFields: PropTypes.func.isRequired,
}
export default AddEditReplacement
