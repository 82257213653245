import React from 'react'
import hasSubscription from 'utils/hasSubscription'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Container = styled.div`
  position: relative;
`

const Stars = styled.div``

const RatingStars = styled.div`
  position: absolute;
  left: 0;
  top: 0;

  width: ${({ width }) => width || '100%'};
  overflow: hidden;
`

const Star = styled.span`
  :before {
    content: '\\2605';
  }

  #participantsField & {
    :nth-child(n + 2) {
      display: none;
    }

    presentation {
      display: none;
    }
  }

  color: ${({ color }) => color || 'lightgrey'};
`

const Rating = ({ rating }) =>
  hasSubscription() === 'basic' ? (
    ''
  ) : (
    <Container>
      <Stars>
        {[1, 2, 3, 4, 5].map(value => (
          <Star key={value} />
        ))}
      </Stars>
      <RatingStars width={`${(rating / 5) * 100}%`}>
        {[1, 2, 3, 4, 5].map(value =>
          value <= rating ? <Star key={value} color="gold" /> : ''
        )}
      </RatingStars>
    </Container>
  )

Rating.propTypes = {
  rating: PropTypes.number.isRequired,
}

export default Rating
