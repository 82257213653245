import React, { useState } from 'react'
import { Translate } from 'react-localize-redux'
import { makeStyles } from '@material-ui/core/styles'
import {
  Card,
  FormControlLabel,
  Switch,
  Typography,
  Box,
  Divider,
} from '@material-ui/core'
import { connect } from 'react-redux'
import { teams as teamsModule } from '../../redux/modules'
import PROP_TYPES from '../../constants/propTypes'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: 'auto',
    padding: theme.spacing(4),
  },
  titleWrapper: {
    marginBottom: theme.spacing(3),
  },
  title: {
    paddingBottom: theme.spacing(0.5),
  },
  detailsContainer: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  detailsContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(0.5),
    '& > p': {
      marginRight: theme.spacing(2),
    },
  },
}))

const PlayerProfilePrivacy = ({ team, patchTeam }) => {
  const classes = useStyles()

  const [privacy, setPrivacy] = useState({
    share_player_information: team.share_player_information === 1,
    share_player_statistics: team.share_player_statistics === 1,
    share_player_skills_with_team: team.share_player_skills_with_team === 1,
    share_player_skills_with_player: team.share_player_skills_with_player === 1,
  })

  const handlePrivacySwitchChange = (event) => {
    setPrivacy({ ...privacy, [event.target.name]: event.target.checked })
    patchTeam(team.uuid, {
      [event.target.name]: event.target.checked === true ? 1 : 0,
    })
  }

  return (
    <>
      <Card>
        <Box className={classes.root}>
          <Box className={classes.titleWrapper}>
            <Typography variant="h3" component="h3" className={classes.title}>
              <Translate id="player.profile-privacy.title" />
            </Typography>
            <Typography variant="h6" component="h6">
              <Translate id="player.profile-privacy.subtitle" />
            </Typography>
          </Box>
          <Box className={classes.detailsContainer}>
            <Typography variant="h4" component="h4">
              <Translate id="player.profile-privacy.player-information.title" />
            </Typography>
            <Box className={classes.detailsContent}>
              <Typography variant="body2">
                <Translate id="player.profile-privacy.player-information.description" />
              </Typography>
              <FormControlLabel
                control={
                  <Switch
                    checked={privacy.share_player_information}
                    onChange={handlePrivacySwitchChange}
                    name="share_player_information"
                    color="primary"
                  />
                }
              />
            </Box>
            <Divider />
          </Box>
          {/*@fixme: temporary disabled https://pixelplus.atlassian.net/browse/CB-491*/}
          {/*<Box className={classes.detailsContainer}>*/}
          {/*  <Typography variant="h4" component="h4">*/}
          {/*    <Translate id="player.profile-privacy.player-statistics.title" />*/}
          {/*  </Typography>*/}
          {/*  <Box className={classes.detailsContent}>*/}
          {/*    <Typography variant="body2">*/}
          {/*      <Translate id="player.profile-privacy.player-statistics.description" />*/}
          {/*    </Typography>*/}
          {/*    <FormControlLabel*/}
          {/*      control={*/}
          {/*        <Switch*/}
          {/*          checked={privacy.share_player_statistics}*/}
          {/*          onChange={handlePrivacySwitchChange}*/}
          {/*          name="share_player_statistics"*/}
          {/*          color="primary"*/}
          {/*        />*/}
          {/*      }*/}
          {/*    />*/}
          {/*  </Box>*/}
          {/*  <Divider />*/}
          {/*</Box>*/}
          <Box className={classes.detailsContainer}>
            <Typography variant="h4" component="h4">
              <Translate id="player.profile-privacy.skills-with-team.title" />
            </Typography>
            <Box className={classes.detailsContent}>
              <Typography variant="body2">
                <Translate id="player.profile-privacy.skills-with-team.description" />
              </Typography>
              <FormControlLabel
                control={
                  <Switch
                    checked={privacy.share_player_skills_with_team}
                    onChange={handlePrivacySwitchChange}
                    name="share_player_skills_with_team"
                    color="primary"
                  />
                }
              />
            </Box>
            <Divider />
          </Box>
          <Box className={classes.detailsContainer}>
            <Typography variant="h4" component="h4">
              <Translate id="player.profile-privacy.skills-with-individual.title" />
            </Typography>
            <Box className={classes.detailsContent}>
              <Typography variant="body2">
                <Translate id="player.profile-privacy.skills-with-individual.description" />
              </Typography>
              <FormControlLabel
                control={
                  <Switch
                    checked={privacy.share_player_skills_with_player}
                    onChange={handlePrivacySwitchChange}
                    name="share_player_skills_with_player"
                    color="primary"
                  />
                }
              />
            </Box>
          </Box>
        </Box>
      </Card>
    </>
  )
}

PlayerProfilePrivacy.propTypes = {
  patchTeam: PROP_TYPES.func.isRequired,
  team: PROP_TYPES.shape({
    uuid: PROP_TYPES.string.isRequired,
    share_player_information: PROP_TYPES.number.isRequired,
    share_player_statistics: PROP_TYPES.number.isRequired,
    share_player_skills_with_team: PROP_TYPES.number.isRequired,
    share_player_skills_with_player: PROP_TYPES.number.isRequired,
  }).isRequired,
}

export default connect(
  ({ teams }) => ({
    team: teams.current,
  }),
  {
    patchTeam: teamsModule.patchTeam,
  }
)(PlayerProfilePrivacy)
