import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Button, OverlayTrigger, Popover } from 'react-bootstrap'
import { AddButton, Input } from 'components'
import filterObject from 'utils/filterObject'
import { documents as documentsModule } from 'redux/modules'
import PROP_TYPES from 'constants/propTypes'

const Overlay = (newFolderName, changeNewFolderName, handleSave) => {
  const onChange = ({ currentTarget: { value } }) => changeNewFolderName(value)

  return (
    <Popover id="create-new-folder">
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: '0.18rem'}}>
        New folder name
        <div className="pt-2">
          <Input
            input={{
              value: newFolderName,
              onChange,
              autoFocus: true,
            }}
          />
        </div>
      </div>
      {newFolderName && (
        <div className="d-flex justify-content-end">
          <Button size="sm" onClick={handleSave}>
            Create
          </Button>
        </div>
      )}
    </Popover>
  )
}

const AddNewFolder = ({
  currentTeamId,
  parentIdForNewFolder,
  createNewFolder,
}) => {
  const [overlayRef, changeOverlayRef] = useState({})
  const [newFolderName, changeNewFolderName] = useState('')

  const ref = overlay => {
    changeOverlayRef(overlay)
  }

  const handleSave = () => {
    createNewFolder(
      currentTeamId,
      filterObject({
        title: newFolderName,
        parent_id: parentIdForNewFolder,
      })
    ).then(() => {
      changeNewFolderName('')

      overlayRef.hide()
    })
  }

  return (
    <OverlayTrigger
      ref={ref}
      rootClose
      trigger="click"
      placement="auto"
      overlay={Overlay(newFolderName, changeNewFolderName, handleSave)}
    >
      <AddButton>+</AddButton>
    </OverlayTrigger>
  )
}

AddNewFolder.defaultProps = {
  parentIdForNewFolder: null,
}

AddNewFolder.propTypes = {
  currentTeamId: PROP_TYPES.id.isRequired,
  parentIdForNewFolder: PROP_TYPES.id,
  createNewFolder: PROP_TYPES.func.isRequired,
}

export default connect(
  null,
  {
    createNewFolder: documentsModule.createNewFolder,
  }
)(AddNewFolder)
