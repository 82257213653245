/* eslint-disable no-unused-vars */
import React, { useState, useCallback, useEffect } from 'react'
import draftToHtml from 'draftjs-to-html'
import { convertToRaw } from 'draft-js'
import MUIEditor, {
  MUIEditorState,
  toolbarControlTypes,
} from 'react-mui-draft-wysiwyg'
import { Paper } from '@material-ui/core'
import PropTypes from 'prop-types'
import createDraftFromHtml from 'utils/createDraftFromHtml'
import PROP_TYPES from 'constants/propTypes'

const TextEditor = ({ input: { value, onChange }, meta = {} }) => {
  const editorConfig = {
    toolbar: {
      controls: [
        toolbarControlTypes.undo,
        toolbarControlTypes.redo,
        toolbarControlTypes.divider,
        toolbarControlTypes.bold,
        toolbarControlTypes.italic,
        toolbarControlTypes.underline,
        toolbarControlTypes.strikethrough,
        toolbarControlTypes.divider,
        toolbarControlTypes.fontColor,

        toolbarControlTypes.linkAdd,
        toolbarControlTypes.image,
        toolbarControlTypes.divider,
        toolbarControlTypes.blockType,
        toolbarControlTypes.fontSize,
        toolbarControlTypes.divider,
        toolbarControlTypes.textAlign,
        toolbarControlTypes.divider,
        toolbarControlTypes.unorderedList,
      ],
      style: {
        borderRadius: 0,
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        background: 'transparent',
        '& .MuiDialogContent-root': {
          width: '100%',
        },
      },
    },
    editor: {
      style: {
        margin: 0,
        padding: 20,
        borderRadius: 0,
        border: 'transparent',
        background: 'transparent',
        boxShadow: 'none',
        minHeight: 400,
      },
    },
  }

  const [editorState, setEditorState] = useState(
    MUIEditorState.createEmpty(editorConfig)
  )
  useEffect(() => {
    if (
      value &&
      editorState &&
      value === draftToHtml(convertToRaw(editorState.getCurrentContent()))
    ) {
      return
    }

    if (value) {
      setEditorState(createDraftFromHtml(value))
    } else {
      setEditorState(MUIEditorState.createEmpty())
    }
  }, [value])

  const onEditorStateChange = useCallback(newState => {
    setEditorState(newState)
    onChange(draftToHtml(convertToRaw(newState.getCurrentContent())))
  }, [])

  return (
    <>
      <Paper variant="outlined">
        <MUIEditor
          editorState={editorState}
          onChange={onEditorStateChange}
          config={editorConfig}
        />
      </Paper>
    </>
  )
}

TextEditor.defaultProps = {
  meta: {},
}

TextEditor.propTypes = {
  input: PROP_TYPES.shape().isRequired,
  meta: PropTypes.shape(),
}

export default TextEditor
