import { Button, Grid } from '@material-ui/core'
import React from 'react'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  root: {
    marginTop: 16,
    marginBottom: 16,
    '& .MuiButton-contained': {
      paddingTop: 8,
      paddingBottom: 8,
    },
  },
}))
const SaveUpdateButtons = ({ existingData, save, update }) => {
  const classes = useStyles()

  return (
    <>
      {!existingData ? (
        <>
          <Grid container spacing={2} className={classes.root}>
            <Grid item xs={12} md={6}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => save(false)}
                fullWidth
              >
                <Translate id="matches.highlights.save-n-add" />
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => save(true)}
                fullWidth
              >
                <Translate id="matches.highlights.add-n-exit" />
              </Button>
            </Grid>
          </Grid>
        </>
      ) : (
        <Grid item className="my-3">
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => update(existingData.id)}
              className="py-2"
              fullWidth
            >
              <Translate id="matches.highlights.update-highlight" />
            </Button>
          </div>
        </Grid>
      )}
    </>
  )
}
SaveUpdateButtons.defaultProps = {
  existingData:null
}
SaveUpdateButtons.propTypes = {
  save: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  existingData: PropTypes.shape(),
}
export default SaveUpdateButtons
