import { combineActions, handleActions } from 'redux-actions'
import {
  actionsTypesGenerator,
  onRequest,
  onSuccess,
  onFailure,
  updateItems,
} from 'utils/reduxHelpers'
import initialState from '../initialState'
import { setClub } from '../../utils/localstorage'

const FETCH_CLUB = actionsTypesGenerator('FETCH_CLUB')

export const fetchClub = (id) => ({
  types: FETCH_CLUB,
  callAPI: {
    method: 'GET',
    path: `/clubs/${id}`,
    data: {
      preventToastNotification: true,
    }
  }
})

export default handleActions(
  {
    [combineActions(
      FETCH_CLUB.request
    )]: onRequest,
    [FETCH_CLUB.success]: (state, { response: { data } }) => {
      // for backward compatibility
      setClub(data)

      return onSuccess({
        ...state,
        items: updateItems(state.items, data),
        current: data,
      })
    },
    [combineActions(
      FETCH_CLUB.failure
    )]: onFailure,
  },
  initialState.clubs
)
