import React from 'react'
import { Translate } from 'react-localize-redux'
import styled from 'styled-components'
import { Card, SummaryCircle } from 'components'
import PROP_TYPES from 'constants/propTypes'

const StyledCard = styled(Card)`
  background: #006064;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 40px 15px;
`

const TeamResults = ({ data }) => (
  <Translate>
    {({ translate }) => (
      <StyledCard>
        <SummaryCircle
          value={data.matches_played}
          maxValue=""
          title={translate('team.matches')}
        />
        <SummaryCircle
          value={data.goals_scored}
          maxValue={translate('team.goals')}
          title={translate('team.scored')}
        />
        <SummaryCircle
          value={data.goals_received}
          maxValue={translate('team.goals')}
          title={translate('team.received')}
        />
      </StyledCard>
    )}
  </Translate>
)

TeamResults.propTypes = {
  data: PROP_TYPES.shape().isRequired,
}

export default TeamResults
