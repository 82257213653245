import React from 'react'
import { Translate } from 'react-localize-redux'
import { DragLayer, DropTarget } from 'react-dnd'
import { DraggablePlayer } from 'containers'
import { PlayersList } from 'components'
import { Paper, Box } from '@material-ui/core'
import { compose } from 'redux'
import { Heading, DraggableArea } from './styledComponents'
import PROP_TYPES from 'constants/propTypes'

const NotInRoster = ({
  players,
  fromPathname,
  onPlayerChange,
  connectDropTarget,
  matchStatPlayers,
}) => {
  const updatedPlayers = players.map(p => {
    const player = p
    player.notes = matchStatPlayers.findById(player.id).notes
    player.rating = matchStatPlayers.findById(player.id).rating
    player.tag = matchStatPlayers.findById(player.id).tag
    player.participant = matchStatPlayers.findById(player.id).participant
    player.in_roster = matchStatPlayers.findById(player.id).in_roster

    return player
  })
  players = updatedPlayers // eslint-disable-line

  return (
    <Paper>
      <Heading>
        <Translate id="matches.not-in-roster" />
      </Heading>
      <Box>
        {connectDropTarget(
          <div>
            <DraggableArea>
              {players.length === 0 ? (
                <Translate id="matches.drag-players" />
              ) : (
                <PlayersList
                  players={players}
                  WrapperComponent={DraggablePlayer}
                  fromPathname={fromPathname}
                  onPlayerChange={onPlayerChange}
                  hidePlayerChangePopover
                />
              )}
            </DraggableArea>
          </div>
        )}
      </Box>
    </Paper>
  )
}

NotInRoster.defaultProps = {
  players: [],
  matchStatPlayers: [],
}

NotInRoster.propTypes = {
  players: PROP_TYPES.arrayOfObjects,
  onDrop: PROP_TYPES.func.isRequired, //eslint-disable-line
  fromPathname: PROP_TYPES.string.isRequired,
  onPlayerChange: PROP_TYPES.func.isRequired,
  connectDropTarget: PROP_TYPES.func.isRequired,
  matchStatPlayers: PROP_TYPES.arrayOfObjects,
}

const fieldTarget = {
  drop({ item: { playerId }, onDrop }) {
    onDrop(playerId, 'notInRoster')
  },
}

export default compose(
  DragLayer(monitor => ({
    item: monitor.getItem(),
  })),
  DropTarget('player', fieldTarget, connect => ({
    connectDropTarget: connect.dropTarget(),
  }))
)(NotInRoster)
