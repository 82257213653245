import React from 'react'
import { Button } from 'react-bootstrap'
import { withLocalize } from 'react-localize-redux'
import { setLocale } from 'utils/localstorage'
import HasClubPermission from './Permissions/HasClubPermission'
import PROP_TYPES from 'constants/propTypes'
import PERMISSIONS from 'constants/permissions'

const LanguageSelectorForAdmins = ({ languages, setActiveLanguage }) => (
  <HasClubPermission ability={PERMISSIONS.TRANSLATE}>
    {languages.map(({ code, name, active }) => (
      <Button
        key={code}
        className="ml-1"
        size="sm"
        variant="light"
        active={active}
        onClick={() => {
          setActiveLanguage(code)
          setLocale(code)
        }}
      >
        {name}
      </Button>
    ))}
  </HasClubPermission>
)

LanguageSelectorForAdmins.propTypes = {
  languages: PROP_TYPES.arrayOfObjects.isRequired,
  setActiveLanguage: PROP_TYPES.func.isRequired,
}

export default withLocalize(LanguageSelectorForAdmins)
