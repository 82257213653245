/* eslint-disable no-plusplus */

import clsx from 'clsx'
import DateFnsUtils from '@date-io/date-fns'
import format from 'date-fns/format'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import styled from 'styled-components'
import { formatCostumDate } from 'utils/formatDate'
import { IconButton, Box } from '@material-ui/core'
import PROP_TYPES from 'constants/propTypes'

const useStyles = makeStyles(theme => ({
  dayWrapper: {
    position: 'relative',
  },
  day: {
    width: 36,
    height: 36,
    fontSize: theme.typography.caption.fontSize,
    margin: '0 2px',
    color: 'inherit',
  },
  customDayHighlight: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: '2px',
    right: '2px',
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: '50%',
  },
  nonCurrentMonthDay: {
    color: theme.palette.text.disabled,
  },
  highlightBorder: {
    border: '1px solid !important',
    Bordercolor: theme.palette.primary.main,
    borderRadius: '50%',
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
  },
  highlightColor: {
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
    border: '1px solid transparent !important',
  },
  highlight: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,

    borderRadius: '50%',
  },
  firstHighlight: {
    extend: 'highlight',
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
  },
  endHighlight: {
    extend: 'highlight',
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  },
  matchDot: {
    width: 5,
    height: 5,
    borderRadius: 5,
    margin: theme.spacing(0, 0.2),
    backgroundColor: theme.Dataset.color_1,
  },
  trainingDot: {
    width: 5,
    height: 5,
    borderRadius: 5,
    margin: theme.spacing(0, 0.2),
    backgroundColor: theme.Dataset.color_2,
  },
}))

const MuiCalenderCustom = styled.div`
  .MuiPickersToolbar-toolbar {
    display: none;
  }
  .MuiPickersBasePicker-pickerView {
    max-width: 100%;
    min-width: 100%;
    margin-top: 8px;
    margin-bottom: 20px;
  }
  .MuiPickersCalendar-transitionContainer {
    margin-top: 10px;
    min-height: 290px;
  }
  .MuiIconButton-root{
    border: 1px solid transparent;
  }
  .MuiIconButton-root:hover {
    border: 1px solid ${props => props.theme.palette.primary.main};
    color: ${props => props.theme.palette.primary.main};
    background-color: transparent;
  }
`
const EventDots = styled.div`
  display: flex;
  justify-content: center;
  margin: 0.2rem 0;
  height: 0.3rem;
  .trainingDot,
  .generalEventDot,
  .matchDot {
    width: 0.3125rem;
    height: 0.3125rem;
    margin: 0 0.1rem;
    border-radius: 0.3125rem;
  }
  .trainingDot {
    background-color: #f48056;
  }
  .matchDot {
    background-color: #255f64;
  }
  .generalEventDot {
    background-color: #b5b5b5;
  }
`

const EventCalender = ({ date, changeDate, events, calenderDateSelected }) => {
  const classes = useStyles()

  const todayDate = formatCostumDate(new Date())

  const renderWrappedWeekDay = (
    currentDate,
    selectedDate,
    dayInCurrentMonth
  ) => {
    const seletedDateTmp = formatCostumDate(selectedDate)
    const calenderDate = formatCostumDate(currentDate)

    const wrapperClassName = clsx({})
    const isSelectedDay = seletedDateTmp === calenderDate

    const dayBox = clsx(classes.day, {
      [classes.highlight]: isSelectedDay && calenderDateSelected,
      [classes.highlightBorder]: todayDate === calenderDate || isSelectedDay,
      [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
      [classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth,
    })

    return (
      <>
        <div className={wrapperClassName}>
          <IconButton className={dayBox}>
            <span> {format(currentDate, 'd')} </span>
          </IconButton>
        </div>

        {dayInCurrentMonth && (
          <EventDots>
            {events.some(
              p => p.date.startsWith(calenderDate) && p.relation === 'match'
            ) && (
              <Box variant="div" component="div" className={classes.matchDot} />
            )}
            {events.some(
              p => p.date.startsWith(calenderDate) && p.relation === 'training'
            ) > 0 && (
              <Box
                variant="div"
                component="div"
                className={classes.trainingDot}
              />
            )}
            {events.some(
              p => p.date.startsWith(calenderDate) && p.relation === 'birthday'
            ) > 0 && (
              <Box variant="div" component="div" className={classes.matchDot} />
            ) > 0 && <Box variant="div" component="div" className="matchDot" />}
            {events.some(
              p => p.date.startsWith(calenderDate) && p.relation === 'event'
            ) > 0 && (
              <Box variant="div" component="div" className="generalEventDot" />
            )}
          </EventDots>
        )}
      </>
    )
  }

  return (
    <div>
      <MuiCalenderCustom>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            autoOk
            orientation="landscape"
            variant="static"
            value={date}
            onChange={changeDate}
            renderDay={renderWrappedWeekDay}
          />
        </MuiPickersUtilsProvider>
      </MuiCalenderCustom>
    </div>
  )
}

EventCalender.propTypes = {
  date: PROP_TYPES.date.isRequired,
  calenderDateSelected: PROP_TYPES.bool.isRequired,
  changeDate: PROP_TYPES.func.isRequired,
  events: PROP_TYPES.shape().isRequired,
}

export default EventCalender
