/* eslint-disable no-nested-ternary */
import React, { useState } from 'react'
import styled from 'styled-components'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import {
  teams as teamsModule,
  ageClasses as ageClassesModule,
} from 'redux/modules'
import IconButton from '@material-ui/core/IconButton'
import {
  Button,
  TextField,
  Dialog,
  FormGroup,
  DialogContent,
  FormControl,
  DialogActions,
  FormControlLabel,
  MenuItem,
  Select,
  Checkbox,
  Grid,
  InputLabel,
  Typography,
} from '@material-ui/core'
import {
  Close as CloseIcon,
  PhotoLibraryOutlined as PhotoLibraryOutlinedIcon,
  DeleteOutlineOutlined as DeleteOutlineOutlinedIcon,
} from '@material-ui/icons'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { Snackbars } from 'components'
import ImageUploading from 'react-images-uploading'
import EditIcon from '@material-ui/icons/Edit'
import BallIcon from 'assets/images/ballicon.svg'
import countries from 'constants/countries'

const styles = theme => ({
  root: {
    padding: theme.spacing(1, 1.4, 1, 3),
    display:'flex',
    alignItems:'center',
    '& .MuiButtonBase-root': {
      marginLeft:'auto'
    }
  },
})

const ImageContainer = styled.div`
  width: 100%;
  height: 120px;
  margin: auto;
  margin-top: 2rem;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          size='small'
          aria-label="close"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const TeamEditDialog = ({
  initialValues,
  open,
  handleClose,
  setInitialValues,
  updateTeam,
  fetchAllAgeClasses,
}) => {
  const [teamUpdate, setTeamUpdate] = React.useState({ ...initialValues })

  const [submitBtnDisabled, setSubmitBtnDisabled] = React.useState(false)

  const [Snackbar, changeSnackbar] = React.useState({
    show: false,
    message: '',
    error: false,
    info: false,
    warning: false,
  })

  const handleChange = event => {
    const {
      target: { name, value },
    } = event

    const isError = value === ''
    setTeamUpdate({
      ...teamUpdate,
      [name]: value,
      [`${name}_error`]: isError,
    })

    setSubmitBtnDisabled(isError)
  }

  const handleChangeCheckBox = event => {
    setTeamUpdate({
      ...teamUpdate,
      [event.target.name]: event.target.checked ? 1 : 0,
    })
  }
  const [images, setImages] = React.useState([])
  const maxNumber = 1
  const maxMbFileSize = 5 * 1024 * 1024 // 5Mb

  const onChange = imageList => setImages(imageList)

  const handleUpdateTeam = () => {
    changeSnackbar({
      show: false,
      message: '',
      error: false,
    })
    const body = JSON.parse(JSON.stringify(teamUpdate))
    delete body.club
    delete body.last_match
    delete body.matches
    delete body.matchesResults
    delete body.matchesStats
    delete body.players
    delete body.statistics
    delete body.trainings
    delete body.user
    delete body.tickers
    delete body.players_skill

    updateTeam({
      ...body,
      logo:
        images.length > 0
          ? images[0].file
          : teamUpdate.logo && teamUpdate.logo.full_url
          ? teamUpdate.logo.full_url
          : null,
      preventToastNotification: true,
    })
      .then(res => {
        handleClose()
        setInitialValues({ ...teamUpdate, logo: res.response.data.logo })
        setImages([])
        changeSnackbar({
          show: true,
          message: <Translate id="team.team-update" />,
        })
      })
      .catch(() => {
        changeSnackbar({
          show: true,
          message: <Translate id="team.team-not-update" />,
          error: true,
        })
      })
  }

  React.useEffect(() => {}, [initialValues, images, teamUpdate])
  const [ageClasses, SetAgeClasses] = useState([])

  const getAgeClasses = () => {
    fetchAllAgeClasses()
      .then(res => {
        SetAgeClasses(res.response.data)
      })
      .catch(e => {
        console.error(e)
      })
  }
    const handleDialogClose = () => {
      setTeamUpdate({ ...initialValues })
      handleClose()
    }
  React.useEffect(() => {
    getAgeClasses()
  }, [])

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleDialogClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="xs"
      >
         <DialogTitle onClose={handleDialogClose}>
            <Translate id="myteams.edit-team" />
          </DialogTitle>
        <DialogContent dividers>
          <div className="profileContent">
            
              <ImageContainer className="dialog-logo">
                {images.length === 0 && (
                  <img
                    alt="logo"
                    src={teamUpdate.logo ? teamUpdate.logo.full_url : BallIcon}
                  />
                )}
              </ImageContainer>
            
            <ImageUploading
              value={images}
              onChange={onChange}
              maxNumber={maxNumber}
              // multiple
              maxFileSize={maxMbFileSize}
              acceptType={['jpg', 'gif', 'png', 'jpeg', 'jfif','pjpeg','pjp']}
              // onError={onError}
              dataURLKey="data_url"
            >
              {({ imageList, onImageUpload, onImageUpdate, onImageRemove }) => (
                // write your building UI
                <div className="changeImgContent">
                  <div className="uploadImg">
                    {imageList.length === 0 && (
                      <IconButton onClick={onImageUpload}>
                        <EditIcon />
                      </IconButton>
                    )}
                  </div>

                  {imageList.map((image, index) => (
                    // eslint-disable-next-line
                    <div className="newImgUpload" key={index}>
                      <img alt="" src={image.data_url} />
                      <IconButton onClick={() => onImageUpdate(index)}>
                        <PhotoLibraryOutlinedIcon />
                      </IconButton>
                      <IconButton onClick={() => onImageRemove(index)}>
                        <DeleteOutlineOutlinedIcon />
                      </IconButton>
                    </div>
                  ))}
                </div>
              )}
            </ImageUploading>
          </div>
          <Typography variant="h4" component="h4" gutterBottom>
            <Translate id="team.general-info" />
          </Typography>
          <TextField
            label={<Translate id="team.team-name" />}
            variant="outlined"
            color="secondary" 
            autoFocus
            id="name"
            name="name"
            margin='dense'
            defaultValue={teamUpdate.name}
            value={teamUpdate.name}
            fullWidth
            onChange={handleChange}
            error={teamUpdate.name_error ? teamUpdate.name_error : false}
          />
          <FormControl variant="outlined" color="secondary" margin='dense'>
          <InputLabel id="country"><Translate id="team.country" /></InputLabel>
            <Select
            label={<Translate id="team.country" />}
              labelId="country"
              id="country"
              defaultValue={teamUpdate?.country?.toUpperCase()}
              value={teamUpdate?.country?.toUpperCase()}
              onChange={handleChange}
              name="country"
            >
              {countries
                .filter(p => p.value !== '')
                .map(({ value, label }) => (
                  <MenuItem value={value} key={value}>
                    {label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <TextField
            label={<Translate id="team.league" />}
            variant="outlined"
            color="secondary" 
            id="league"
            name="league"
            margin='dense'
            defaultValue={teamUpdate.league}
            value={teamUpdate.league}
            onChange={handleChange}
            fullWidth
            error={teamUpdate.league_error || false}
          />
          <Grid container spacing={1}>
            <Grid item md={6}>
              <FormControl variant="outlined" color="secondary" margin='dense'>
                <InputLabel id="teamLeague"><Translate id="team.age-class" /></InputLabel>
                <Select
                  label={<Translate id="team.age-class" />}
                  labelId="teamLeague"
                  id="teamLeague"
                  name="age_class"
                  defaultValue={teamUpdate.age_class}
                  value={teamUpdate.age_class}
                  onChange={handleChange}
                >
                  {ageClasses.length > 0 &&
                    ageClasses.map(ageClass => (
                      <MenuItem
                        key={ageClass.id.toString()}
                        value={ageClass.id}
                      >
                        {ageClass.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl variant="outlined" color="secondary" margin='dense'>
                <InputLabel id="gender"><Translate id="team.gender" /></InputLabel>
                <Select
                  label={<Translate id="team.gender" />}
                  labelId="gender"
                  id="gender"
                  name="gender"
                  defaultValue={teamUpdate.gender}
                  value={teamUpdate.gender}
                  onChange={handleChange}
                >
                  <MenuItem value="f">
                    <Translate id="form.female" />
                  </MenuItem>
                  <MenuItem value="m">
                    <Translate id="form.male" />
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <FormControl margin="dense">
          <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={teamUpdate.hide_global_drills === 1}
                onChange={handleChangeCheckBox}
                value={teamUpdate.hide_global_drills}
                name="hide_global_drills"
              />
            }
            label={<Translate id="form.hide_global_drills" />}
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={teamUpdate.hide_global_training_packages === 1}
                onChange={handleChangeCheckBox}
                value={teamUpdate.hide_global_training_packages}
                name="hide_global_training_packages"
              />
            }
            label={<Translate id="form.hide_global_training_packages" />}
          />
 </FormGroup>
 </FormControl>
        </DialogContent>
        
        <DialogActions>
        <Button onClick={handleDialogClose}  fullWidth>
              <Translate id="button.cancel" />
            </Button>
            <Button
               fullWidth
              disabled={submitBtnDisabled}
              onClick={handleUpdateTeam}
              variant="contained"
              color="primary"
            >
              <Translate id="button.save" />
            </Button>
        
          </DialogActions>
      </Dialog>
      {Snackbar.show && <Snackbars {...Snackbar} />}
    </div>
  )
}

TeamEditDialog.defaultProps = {}

TeamEditDialog.propTypes = {
  initialValues: PropTypes.shape().isRequired,
  handleClick: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  setInitialValues: PropTypes.func.isRequired,
  updateTeam: PropTypes.func.isRequired,
  fetchAllAgeClasses: PropTypes.func.isRequired,
}

export default connect(
  null,
  {
    updateTeam: teamsModule.updateTeam,
    fetchAllAgeClasses: ageClassesModule.fetchAllAgeClasses,
  }
)(TeamEditDialog)
