import React, { useMemo } from 'react'
import styled from 'styled-components'
import { ProgressBar } from 'react-bootstrap'
import PROP_TYPES from 'constants/propTypes'

const DURATION_LIMIT = 90
const DEFAULT_PROGRESS = 2

const DurationBar = styled.div`
  > div {
    margin-bottom: 20px;
    border-radius: 30px;
    height: 6px;
    background-color: ${({ isOverflow }) => (isOverflow ? '#ef6d6f' : '')};

    > div {
      background-color: ${props => props.theme.palette.primary.main};
      border-radius: 30px;
    }
  }
`

const DurationText = styled.div`
  font-size: 14px;
  letter-spacing: 0.1px;
  line-height: 1;
  margin-bottom: 6px;
  text-align: right;
  color: var(--text-dark);
`

const SessionDuration = ({ sessionDrills }) => {
  const duration = useMemo(() => {
    return sessionDrills.reduce((total, drill) => total + drill.duration, 0)
  }, [sessionDrills])

  const durationOverflow =
    duration > DURATION_LIMIT ? duration - DURATION_LIMIT : 0

  const relativeDuration =
    duration <= DURATION_LIMIT ? duration : DURATION_LIMIT

  return (
    <>
      <DurationText>
        {duration}/{DURATION_LIMIT}min
      </DurationText>
      <DurationBar isOverflow={Boolean(durationOverflow)}>
        <ProgressBar
          now={relativeDuration || DEFAULT_PROGRESS}
          max={DURATION_LIMIT + durationOverflow}
        />
      </DurationBar>
    </>
  )
}

SessionDuration.defaultProps = {
  sessionDrills: [],
}

SessionDuration.propTypes = {
  sessionDrills: PROP_TYPES.arrayOfObjects,
}

export default SessionDuration
