import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import { trainings, matches } from 'redux/modules'
import {
  Toolbar,
  TrainingHeader,
  MatchHeader,
  DateHeader,
  EventWrapper,
  OverviewView,
} from './components'
import CalendarStyled from './CalendarStyled'
import PROP_TYPES from 'constants/propTypes'
import { TRAININGS_ENTITY, MATCHES_ENTITY } from 'constants/entities'

const localizer = momentLocalizer(moment)

const PPCalendar = props => {
  const {
    events,
    entity,
    addNewTraining,
    addNewMatch,
    removeNewTrainings,
    removeNewMatches,
    match,
    selectable,
    changeSelectable,
    activePopoverId,
    changeActivePopoverId,
    activeView,
    changeActiveView,
    currentSeason,
  } = props

  const {
    params: { teamId, seasonId },
  } = match

  const isTraining = useMemo(() => entity === TRAININGS_ENTITY, [entity])

  const addNewEvent = isTraining ? addNewTraining : addNewMatch
  const removeNewEvents = isTraining ? removeNewTrainings : removeNewMatches

  const onSelectSlot = ({ start }) => {
    if (!selectable) {
      return
    }

    if (
      moment(start).format('YYYYMMDD') <
        moment(currentSeason.start_date).format('YYYYMMDD') ||
      moment(start).format('YYYYMMDD') >
        moment(currentSeason.end_date).format('YYYYMMDD')
    ) {
      return
    }

    const id = Date.now()

    addNewEvent({
      id,
      teamId,
      seasonId,
      date: start,
      status: 'draft',
      isNew: true,
    })

    changeSelectable(false)
    changeActivePopoverId((isTraining ? 'training' : 'match') + id)
  }

  const resetPopover = () => {
    changeActivePopoverId(null)
    changeSelectable(true)

    removeNewEvents()
  }

  return (
    <CalendarStyled>
      {activeView === 'month' ? (
        <PPCalendar
          localizer={localizer}
          events={events}
          views={['month']}
          components={{
            toolbar: Toolbar(resetPopover, currentSeason, changeActiveView),
            month: {
              header: isTraining ? TrainingHeader : MatchHeader,
              dateHeader: DateHeader(currentSeason),
              eventWrapper: EventWrapper({
                resetPopover,
                selectable,
                changeSelectable,
                activePopoverId,
                changeActivePopoverId,
                entity,
              }),
            },
          }}
          onSelectSlot={onSelectSlot}
          selectable={selectable}
        />
      ) : (
        <OverviewView
          events={events}
          changeActiveView={changeActiveView}
          currentSeason={currentSeason}
        />
      )}
    </CalendarStyled>
  )
}

PPCalendar.defaultProps = {
  events: [],
  selectable: false,
  activePopoverId: null,
  currentSeason: {},
}

PPCalendar.propTypes = {
  match: PROP_TYPES.match.isRequired,
  events: PROP_TYPES.arrayOfObjects,
  entity: PropTypes.oneOf([TRAININGS_ENTITY, MATCHES_ENTITY]).isRequired,
  addNewTraining: PropTypes.func.isRequired,
  addNewMatch: PropTypes.func.isRequired,
  removeNewTrainings: PropTypes.func.isRequired,
  removeNewMatches: PropTypes.func.isRequired,
  selectable: PropTypes.bool,
  changeSelectable: PropTypes.func.isRequired,
  activePopoverId: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]),
  changeActivePopoverId: PropTypes.func.isRequired,
  activeView: PropTypes.string.isRequired,
  changeActiveView: PropTypes.func.isRequired,
  currentSeason: PropTypes.shape(),
}

export default compose(
  withRouter,
  connect(
    (
      { seasons },
      {
        match: {
          params: { teamId, seasonId },
        },
      }
    ) => ({
      currentSeason:
        seasons.items[teamId] && seasons.items[teamId].findById(+seasonId),
    }),
    {
      addNewTraining: trainings.addNewTraining,
      addNewMatch: matches.addNewMatch,
      removeNewTrainings: trainings.removeNewTrainings,
      removeNewMatches: matches.removeNewMatches,
    }
  )
)(PPCalendar)
