import React, { useContext, useEffect, useState } from 'react'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { matches } from 'redux/modules'
import {
  Paper,
  Typography,
  CardHeader,
  Button,
  Card,
  Grid,
  TextareaAutosize,
  IconButton,
} from '@material-ui/core'
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined'
import CommentOutlinedIcon from '@material-ui/icons/CommentOutlined'
import { Snackbars, ReactSelect, Collapsible } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { formateDate } from 'utils/formatDate'
import { Context } from '../MatchStateContext'

const useStyles = makeStyles(theme => ({
  styledCardHeader: {
    borderRadius: '8px',
    minHeight: '30px',
    padding: '0.4rem',
    marginBottom: '1rem',
  },
  noteContent: {
    padding: '1rem',
  },
  actionHeader: {
    '& .MuiButtonBase-root': {
      marginLeft: '10px',
    },
  },
  customTextArea: {
    borderRadius: '8px',
    minHeight: '40px',
    padding: '0.5rem',
    width: '100%',
    marginBottom: '1rem',
    '&:focus': {
      borderColor: '#29a173',
    },
  },
  noteRow: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    '& .MuiButtonBase-root': {
      position: 'absolute',
      right: '5px',
      top: '5px',
      [theme.breakpoints.down('sm')]: {
        right: '1px',
        position: 'absolute',
        background: 'white',
      },
    },
  },
  noResNote: {
    position: 'absolute',
    width: '50%',
    height: '100%',
    padding: '0.5rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    transform: 'translate(50%, 0%)',
    '& p': {
      textAlign: 'center',
    },
  },
  customReactSelect: {
    minHeight: '20vh',
    overflow: 'auto',
  },
  noteContainer: {
    borderTop: '1px solid #e0e0e0',
    paddingTop: '1rem',
    '&:first-child': {
      border: 'none',
    },
  },
  noteBody: {
    display: 'flex',
    justifyContent: 'space-between',
    '& .notebody-select': {
      width: '33%',
    },
    '& .notebody-text': {
      width: '65%',
    },
  },
  notesWrapper:{
    marginBottom:"1.5rem"
  },
  collapsible:{
    borderTop:"unset",
    '& .MuiAccordionDetails-root':{
      display:'block'
    }
  },
  [theme.breakpoints.down('sm')]: {
    customReactSelect: {
      minHeight: 'unset',
      overflow: 'unset',
    },
    noteBody: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      '& .notebody-select': {
        width: '100%',
        marginBottom: '1rem',
      },
      '& .notebody-text': {
        width: '100%',
      },
    },
  },
}))

const MatchAllNotes = ({ deleteMatchNote }) => {
  const classes = useStyles()

  const {
    matchState: { normalizedPlayers, allnotes: notes },
    onFieldChange,
  } = useContext(Context)

  const playersSuggestions = normalizedPlayers
    .filter(player => player.name !== null)
    .map(player => ({
      value: player.id,
      label: `@${player.name}`,
    }))

  const [newNote, setNewNote] = useState([{ notes: '', id: null, players: [] }])

  const [Snackbar, changeSnackbar] = useState({
    show: false,
    message: '',
    error: false,
    info: false,
    warning: false,
  })
  const addNewNote = () => {
    changeSnackbar({
      show: false,
      message: '',
      error: false,
    })
    if (newNote.length > 0 && newNote[newNote.length - 1].notes === '') {
      changeSnackbar({
        show: true,
        message: 'Please fill the input',
        error: true,
      })

      return
    }
    const tempNewNotes = [...newNote]
    const tempNote = { notes: '', id: null, players: [] }
    tempNewNotes.push(tempNote)
    setNewNote(tempNewNotes)
    onFieldChange('allnotes', tempNewNotes)
  }
  const onChangeLoop = ({ target: { value } }, index) => {
    const tempNewNotes = [...newNote]
    tempNewNotes[index].notes = value
    setNewNote(tempNewNotes)
    onFieldChange('allnotes', tempNewNotes)
  }
  const removeNote = index => {
    const tempNewNotes = [...newNote]
    if (tempNewNotes.length === 1) {
      tempNewNotes[index].notes = ''
      tempNewNotes[index].players = []
      setNewNote(tempNewNotes)
      if (tempNewNotes[index].id && tempNewNotes[index].id > 0) {
        deleteMatchNote(
          tempNewNotes[index].match_id,
          tempNewNotes[index].id
        ).then(() => {
          tempNewNotes[index].id = null
          setNewNote(tempNewNotes)
          onFieldChange('allnotes', tempNewNotes)
        })
      } else {
        onFieldChange('allnotes', tempNewNotes)
      }
    } else if (tempNewNotes[index].id && tempNewNotes[index].id > 0) {
      deleteMatchNote(
        tempNewNotes[index].match_id,
        tempNewNotes[index].id
      ).then(() => {
        tempNewNotes.splice(index, 1)
        setNewNote(tempNewNotes)
        onFieldChange('allnotes', tempNewNotes)
      })
    } else {
      tempNewNotes.splice(index, 1)
      setNewNote(tempNewNotes)
      onFieldChange('allnotes', tempNewNotes)
    }
  }

  const onChangePlayers = (newPlayers, option, index) => {
    const tempNewNotes = [...newNote]
    const tempNote = { ...tempNewNotes[index] }

    // if (option && option.value === 'everyOne') {
    //   tempNote.players = [...new Set(playersSuggestions.map(p => +p.value))]
    // } else 
    if (option) {
      tempNote.players.push(+option.value)
      tempNote.players = [...new Set(tempNote.players)]
    } else if (!option && newPlayers) {
      tempNote.players = [...new Set(newPlayers.map(p => +p.value))]
    } else if (!option && !newPlayers) {
      tempNote.players = []
    }

    tempNewNotes[index] = tempNote

    onFieldChange('allnotes', tempNewNotes)

    setNewNote(tempNewNotes)

    return tempNote.players
  }

  useEffect(() => {
    if (notes.length > 0) setNewNote(notes)
  }, [notes])

  return (
    <Translate>
      {({ translate }) => (
        <Card className={classes.notesWrapper}>
        <Collapsible title={translate('matches.match-notes')} className={classes.collapsible}>
          <br />
          <Paper className={classes.noteContent} elevation={0}>
            <Card>
              <CardHeader
                className={classes.styledCardHeader}
                title={
                  <Typography variant="h4" component="h4">
                    <Translate id="videos.notes" />
                  </Typography>
                }
                action={
                  <div className={classes.actionHeader}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={addNewNote}
                    >
                      Add note
                    </Button>
                  </div>
                }
              />
              <br />
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <div>
                    {newNote.map((item, index) => {
                      const createdDate = formateDate(item.created_at)
                      const selectedPlayer = item.players.map(player => {
                        return {
                          ...playersSuggestions.find(p => +p.value === +player),
                        }
                      })

                      return (
                        <div className={classes.noteContainer} key={item.id}>
                          <div className="w-100">
                            {item.created_at && (
                              <small>{`${createdDate[4]} ${createdDate[3]}, ${
                                createdDate[0]
                              }, ${createdDate[5]}:${createdDate[6]}`}</small>
                            )}
                          </div>
                          <div className={classes.noteBody}>
                            <div className="notebody-select">
                              <div>
                                <div className={classes.customReactSelect}>
                                  <ReactSelect
                                    value={selectedPlayer}
                                    suggestions={playersSuggestions}
                                    onChange={onChangePlayers}
                                    placeholder={translate('videos.tag-person')}
                                    isEveryOne
                                    index={index}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="notebody-text">
                              <div className={classes.noteRow}>
                                <TextareaAutosize
                                  className={classes.customTextArea}
                                  aria-label="Match Note"
                                  rowsMin={6}
                                  defaultValue={item.notes}
                                  placeholder=""
                                  onChange={event => onChangeLoop(event, index)}
                                />
                                <IconButton
                                  size="small"
                                  onClick={() => removeNote(index)}
                                >
                                  <DeleteOutlineOutlinedIcon />
                                </IconButton>
                                {item.notes === '' && (
                                  <div className={classes.noResNote}>
                                    <CommentOutlinedIcon />
                                    <p>Keep all your game notes in one place</p>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </Grid>
              </Grid>
            </Card>
            {Snackbar.show && <Snackbars {...Snackbar} />}
          </Paper>
        </Collapsible>
        </Card>
      )}
    </Translate>
  )
}

MatchAllNotes.defaultProps = {}

MatchAllNotes.propTypes = {
  deleteMatchNote: PropTypes.func.isRequired,
}

export default connect(
  null,
  {
    deleteMatchNote: matches.deleteMatchNote,
  }
)(MatchAllNotes)
