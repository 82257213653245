import React from 'react'
import {
  VideosLoader,
  SequencesLoader,
  PlayersLoader,
  FavoritesLoader,
  TeamLoader,
} from 'loaders'
import { Grid } from '@material-ui/core'
import { Videos, Sequences } from './components'
import CheckNotProcessedVideos from './CheckNotProcessedVideos'
import PROP_TYPES from 'constants/propTypes'

const VideoAnalysis = ({
  match: {
    params: { teamId,seasonId },
  },
}) => (
  <TeamLoader teamId={teamId} seasonId={seasonId}>
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={5} lg={4}>
        <VideosLoader alwaysRenderChildren passIsLoading>
          <FavoritesLoader entity="videos">
            <CheckNotProcessedVideos>
              <Videos />
            </CheckNotProcessedVideos>
          </FavoritesLoader>
        </VideosLoader>
      </Grid>
      <Grid item xs={12} sm={12} md={7} lg={8}>
        <PlayersLoader teamId={teamId} alwaysRenderChildren passIsLoading>
          <FavoritesLoader
            entity="sequences"
            alwaysRenderChildren
            passIsLoading
          >
            <SequencesLoader>
              <Sequences />
            </SequencesLoader>
          </FavoritesLoader>
        </PlayersLoader>
      </Grid>
    </Grid>
  </TeamLoader>
)

VideoAnalysis.propTypes = {
  match: PROP_TYPES.match.isRequired,
}

export default VideoAnalysis
