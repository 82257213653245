import React, { useState } from "react"
import { IconButton, Tooltip } from "@material-ui/core"
import TrashIcon from "assets/images/16px/bin@1x.svg"
import EditIcon from "assets/images/16px/edit@1x.svg"
import PropTypes from "prop-types"
import DeletePlayerAlert from "./DeletePlayerAlert"
import EditDialog from "./EditDialog"
import MovePlayer from "./MovePlayer"

const GridActionsCell = ({
  row: {
    player,
    uuid,
    onUpdate,
    onDelete,
    showTransferOption,
  },
}) => {
  const [ deleteIsOpen, setDeleteIsOpen ] = useState(false)
  const [ editIsOpen, setEditIsOpen ] = useState(false)

  const handleDeleteClick = () => {
    setDeleteIsOpen(true)
  }

  const handleDeleteClose = () => {
    setDeleteIsOpen(false)
  }

  const handleDeleteConfirm = () => {
    setDeleteIsOpen(false)
    onDelete()
  }

  const handleEditClick = () => {
    setEditIsOpen(true)
  }

  const handleEditClose = () => {
    setEditIsOpen(false)
  }

  return (
    <>
      <DeletePlayerAlert
        open={ deleteIsOpen }
        onCancel={ handleDeleteClose }
        onConfirm={ handleDeleteConfirm }
      />

      <EditDialog
        open={ editIsOpen }
        onClose={ handleEditClose }
        player={ player }
        updatePlayer={ onUpdate }
      />

      <Tooltip title="Edit" placement="top" arrow>
        <IconButton onClick={ handleEditClick }>
          <img src={ EditIcon } alt=""/>
        </IconButton>
      </Tooltip>

      <Tooltip title="Delete" placement="top" arrow>
        <IconButton onClick={ handleDeleteClick }>
          <img src={ TrashIcon } alt=""/>
        </IconButton>
      </Tooltip>

      <MovePlayer playerUuid={ uuid } withTransfer={ showTransferOption }/>
    </>
  )
}

GridActionsCell.propTypes = {
  row: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    player: PropTypes.shape({}).isRequired,
    showTransferOption: PropTypes.bool.isRequired,
  }).isRequired,
}

export default GridActionsCell
