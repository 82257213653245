import React, { useState, useMemo, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { drills as drillsModule } from 'redux/modules';
import {
  Paper,
  Grid,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import {DrillModal} from 'components';
import LoadingSpinner from '../../components/loader/LoadingSpinner';
import { DrillToolbar } from './DrillToolbar';
import { DrillDeck } from './DrillDeck';
import { DrillCard } from './DrillCard';
import PROP_TYPES from 'constants/propTypes';
import { DRILL_EDIT_ROUTE } from 'constants/routes';
import { DEFAULT_CLUB_ID } from 'constants/club'

function DrillDashboard({
  history,
  drills,
  favoritesDrills,
  shareDrill,
  fetchDefaultClubDrills,
  createDuplicateDrill,
  match: {
    params: { teamId, seasonId },
  },
  club
}) {

  const classes = makeStyles(theme => ({
    [theme.breakpoints.up('xl')]: {
      root: {
        '& .MuiGrid-grid-xl-2': {
          maxWidth: '25%',
          flexBasis: '25%',
        },
      },
    },
    root: {
      flexGrow: 1,
      margin: theme.spacing(0, 0, 0),
      padding: theme.spacing(3, 3, 3),
    },
    searchBackdrop: {},
    searchBackdrop__active: {}
  }))()

  const [defaultClubDrills, setDefaultClubDrills] = useState()
  const [modalDrill, setModalDrill] = useState(null)
  const [inProgress, setInProgress] = useState(false)

  const [value, setValue] = useState()

  const openModalDrill = (drill) => { setModalDrill(drill) }
  const closeModalDrill = () => setModalDrill(null)

  const getIsFavorite = useMemo(() => {
    return (id) => Boolean(favoritesDrills.findById(id))
  }, [favoritesDrills])

  const duplicateDrill = id => {
    setInProgress(true)
    createDuplicateDrill(id).then(res => {
      const resData = res.response.data
      if (resData) {
        history.push(
          `/team/${teamId}/season/${seasonId}${DRILL_EDIT_ROUTE.populateURLParams(
            { drillId: resData.id }
          )}`
        )
      }
      setInProgress(false)
    }).catch(() => setInProgress(false))
  }

  const ownTeamDrills = useMemo(
    () => drills.filter(({ team_id:teamid }) => +teamid === +teamId),
    [drills]
  )

  const ownClubGlobalDrills = useMemo(
    () => drills.filter(({ global, club_id:clubId }) => global && clubId === club.id),
    [drills]
  )

  const getDefaultClubDrills = () => {
    fetchDefaultClubDrills().then(res => {
      setDefaultClubDrills(res.response.data)
      setValue(0)
    })
  }
  const trainingPackageValue = club.id > 1 ? 3 : 2
  const handleChange = ( _, newValue ) => {
    setValue(newValue)
  }

  useEffect(() => {
    getDefaultClubDrills()
  }, [])


  return (
    <>
      {!defaultClubDrills
        ? <LoadingSpinner />
        : <>
          {modalDrill && (
            <DrillModal
              drill={modalDrill} onHide={closeModalDrill}
              open={Boolean(modalDrill)}
            />
          )}
          <Grid container spacing={3}>
            <DrillToolbar value={value} handleChange={handleChange} trainingPackageValue={trainingPackageValue} />
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Paper className={classes.root}>
                <Grid container spacing={3}>
                  <DrillDeck
                    value={value}
                    drills={value
                      ? (value === (club.id === DEFAULT_CLUB_ID ? 1 : 2) ? ownTeamDrills : defaultClubDrills)
                      : ownClubGlobalDrills
                    }
                    trainingPackageValue={value === trainingPackageValue}
                  >
                    <DrillCard
                      duplicateDrill={duplicateDrill}
                      getIsFavorite={getIsFavorite}
                      handleOpenDrill={openModalDrill}
                      inProgress={inProgress}
                      shareDrill={shareDrill}
                      club={club}
                    />
                  </DrillDeck>

                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </>
      }
    </>
  )
}

DrillDashboard.defaultProps = {
  drills: [],
  favoritesDrills: [],
  team: null,
  club: null
}

DrillDashboard.propTypes = {
  drills: PROP_TYPES.arrayOfObjects,
  favoritesDrills: PROP_TYPES.arrayOfObjects,
  shareDrill: PROP_TYPES.func.isRequired,
  fetchDefaultClubDrills: PROP_TYPES.func.isRequired,
  createDuplicateDrill: PROP_TYPES.func.isRequired,
  match: PROP_TYPES.shape().isRequired,
  team: PROP_TYPES.shape(),
  club: PROP_TYPES.shape(),
}

export default compose(
  withRouter,
  connect(
    ({ drills, favorites, clubs, teams}) => ({
      drills: drills.items,
      favoritesDrills: favorites.items.drills,
      club: clubs.current,
      team: teams.current
    }),
    {
      shareDrill: drillsModule.shareDrill,
      fetchDefaultClubDrills: drillsModule.defaultClubDrills,
      createDuplicateDrill: drillsModule.createDuplicateDrill,
    }
  )
)(DrillDashboard)
