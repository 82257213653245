/* eslint-disable react/no-multi-comp */
import React, { useState, useMemo, useEffect } from 'react'
import { Translate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { Droppable, Draggable } from 'react-beautiful-dnd'

import {
  Drill,
  DrillModal,
  DrillsContainer,
  DrillsSnackbar,
  AddDrillMenu,
} from 'components'
import { drills as drillsModule } from 'redux/modules'
import {
  Hidden,
  Box,
  CardActions,
  Typography,
  Card,
  CardContent,
  CardMedia,
  MenuItem,
  IconButton,
} from '@material-ui/core'
import { HeartIcon } from 'containers'
import Pagination from '@material-ui/lab/Pagination'
import { makeStyles } from '@material-ui/core/styles'
import Skeleton from '@material-ui/lab/Skeleton'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MuiAlert from '@material-ui/lab/Alert'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { AccessTime, PeopleOutlined } from '@material-ui/icons'
import PROP_TYPES from 'constants/propTypes'

function Alert(props) {
  return <MuiAlert elevation={0} variant="filled" {...props} />
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    position: 'relative'
  },
  formControl: {
    width: 70,
    marginBottom: 0,
  },
  CardContentHeight: {
    height: 90,
  },
  title: {
    flex: '1 1 0',
  },
  boxShadow: {
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
  },
  Muicard: {
    maxWidth: 345,
    position: 'relative',
    height: '100%',
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
  },
  InfoIcon: {
    color: 'var(--primary-green)',
    fontSize: '1.8rem',
  },
  drillDetailDuration: {
    color: 'silver',
    marginRight: '4px',
    marginBottom: '2px',
  },
  drillDetailPlayer: {
    color: 'silver',
    marginRight: '4px',
    marginLeft: '10px',
    marginBottom: '2px',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  content: {
    flex: '1 0 auto',
    padding: '0',
    '& p': {
      padding: '0.5rem',
      lineHeight: 'inherit',
    },
  },
  cover: {
    width: 100,
    justifyContent: 'end',
  },

  snackbar: {
    [theme.breakpoints.down('xs')]: {
      bottom: 80,
    },
  },
  alert: {
    color: '#ffffff !important',
    backgroundColor: '#29a273 !important',
    height: 'auto !important',
    fill: '#ffffff',
    '& h4': {
      color: '#ffffff !important',
    },
    '& h6': {
      color: '#ffffff !important',
    },
  },
  drillListRow: {
    marginTop: '0',
    paddingBottom: '1rem',
    position: 'relative',
    '& h4': {
      fontSize: '14px',
      fontWeight: '600',
      marginLeft: '5px',
    },
  },

  [theme.breakpoints.between('sm', 'xl')]: {
    customDraggable: {
      '&:hover, :focus': {
        height: '210px',
        position: 'absolute',
        margin: '0 5px',
        zIndex: '10',
        transform: 'scale(1.1)',
        background: 'white',
        boxShadow: '16px 16px 32px 0 rgba(0,0,0,0.08)',
        width: 'calc(20% - 20px)',
        '& .extraInfo': {
          display: 'flex',
          position: 'inherit',
          width: '100%',
        },
        '&  .drillContent': {
          position: 'absolute',
          top: '105px',
          width: '100%',
        },
      },
    },
  },
  shareIcon: {
    position: 'inherit',
    width: '16px',
    height: '16px',
    marginRight: '5px',
  },
  customSkeleton: {
    height: '155px',
    display: 'flex',
    padding: '5px',
    width: '100%',
    margin: '5px',
    borderRadius: '8px',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    position: 'relative',
    border: '1px solid #cecece',
    '& .profileImgSkeleton': {
      width: '100%',
      height: '100px',
      borderRadius: '6px',
      margin: '0',
    },
    '& .titleSkeleton': {
      width: '110px',
      height: '25px',
      borderRadius: '6px',
    },
    '& .plusBtnSkeleton': {
      width: '30px',
      height: '30px',
      borderRadius: '50%',
      margin: 'auto',
      marginBottom: '0.5rem',
      position: 'absolute',
      top: '90px',
      right: '10px',
    },

    [theme.breakpoints.down('sm')]: {
      height: '5rem',
      padding: '0',
      width: 'calc(100% - 12px)',
      margin: '5px',
      borderRadius: '8px',
      justifyContent: 'flex-start',
      flexDirection: 'row',
      '& > div': {
        marginTop: '5px',
      },
      '& .profileImgSkeleton': {
        width: '5rem',
        height: '5rem',
        borderRadiusTopLeft: '6px',
        borderRadiusBottomLeft: '6px',
        margin: '0',
      },
      '& .titleSkeleton': {
        width: '90px',
        height: '25px',
        borderRadius: '6px',
        marginLeft: '10px',
        marginBottom: '0.5rem',
      },
      '& .contentSkeleton': {
        width: '80px',
        height: '25px',
        borderRadius: '6px',
        marginLeft: '10px',
        marginBottom: '0.5rem',
      },
      '& .plusBtnSkeleton': {
        width: '30px',
        height: '30px',
        borderRadius: '50%',
        margin: 'auto',
        marginBottom: '0.5rem',
        position: 'absolute',
        top: '5px',
        right: '10px',
      },
    },
  },
  drillPagination: {
    borderRadius: '6px',
    '& .MuiTablePagination-actions': {
      '& .MuiSvgIcon-root': {
        fill: 'inherit',
      },
      '& .MuiIconButton-root.Mui-disabled': {
        opacity: '0.5',
      },
    },
    [theme.breakpoints.down('sm')]: {
      '& .MuiTablePagination-selectRoot': {
        marginLeft: 0,
        marginRight: '20px',
      },
      '& .MuiTablePagination-toolbar': {
        '& p:first-child': {
          display: 'none',
        },
      },
    },
  },
  drillFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '1rem',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-end',
      '& .MuiFormControl-root': {
        marginTop: '0.5rem',
      },
    },
  },
  skeletonContent: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  showOnMobile: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  showOnDesktop: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}))

const DrillsList = ({
  sessionDrills,
  changeSessionDrills,
  categoryType,
  drills,
  favoritesDrills,
  // shareDrill,
  fetchDrill,
  searchedValue,
}) => {
  // const { isDragging } = useContext(Context)

  const [openedDrillId, changeOpenedDrillId] = useState(null)

  const classes = useStyles()

  const [open, setOpen] = React.useState(false)
  const [drillForNotify, setDrillNotify] = React.useState(null)

  const handleMenuClose = (e, drillId, sessionPartValue) => {
    onAddDrill(e, drillId, sessionPartValue) // eslint-disable-line
  }

  const handleClose = () => {
    setOpen(false)
  }

  const onAddDrill = (e, id, sessionPart = false) => {
    e.stopPropagation()

    const targetDrill = drills.findById(id)
    fetchDrill(id, { added: true, track: 'training_session' })

    const newSessionDrills = [
      {
        ...targetDrill,
        session_part: sessionPart,
        customized_duration: targetDrill.duration
      },
      ...sessionDrills,
    ]
    changeSessionDrills(newSessionDrills)
    setOpen(true)
    setDrillNotify(targetDrill)
  }

  const openedDrill = useMemo(() => drills.findById(openedDrillId), [
    openedDrillId,
    drills,
  ])
  const [smScreen, setSmScreen] = useState(0)
  const closeOpenedDrill = () => changeOpenedDrillId(null)
  const getIsFavorite = id => Boolean(favoritesDrills.findById(id))

  const checkMediaSc = () => {
    const sm = window.matchMedia('(max-width: 769px)')
    const md = window.matchMedia('(max-width: 1007px)')
    if (sm.matches) {
      setSmScreen(1)
    } else if (md.matches) {
      setSmScreen(0)
    } else {
      setSmScreen(0)
    }
  }
  const [drillsLength, changeDrillsLength] = useState(0)
  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(10)
  const [startIndex, setStart] = useState(0)
  const [endIndex, setEnd] = useState(pageCount)

  const setPaginationDrill = pageNo => {
    setStart((pageNo - 1) * pageCount)
    setEnd((pageNo - 1) * pageCount + pageCount)
  }
  const handleChange = (event, value) => {
    setPage(value)
    setPaginationDrill(value)
  }
  const setCardsLength = event => {
    setPageCount(+event.target.value)
    setPage(1)
    setStart(0)
    setEnd(+event.target.value)
  }
  const drillsForUI = useMemo(() => {
    let uiDrills = []
    setStart(0)
    setPageCount(10)
    setEnd(10)
    setPage(1)
    if (searchedValue.length > 0) {
      uiDrills = drills.filter(el => el.title && el.title.toLowerCase().includes(searchedValue.toLowerCase()))
    } else {
      uiDrills = drills
    }
    changeDrillsLength(uiDrills.length)

    return uiDrills

  }, [searchedValue, drills])

  const DrillItems = drillsForUI.slice(startIndex, endIndex > drillsLength ? drillsLength : endIndex).map((drill, index) => (
        <>
          <Drill
              key={drill.id}
              // image={drill.images.findEndGetProp(Boolean, 'full_url')}
              onClick={() => changeOpenedDrillId(drill.id)}
          >
            {/* <CarouselItem> */}

            <Hidden only={['xs', 'sm', 'md']}>
              <Draggable
                  draggableId={`${drill.id}$${categoryType}-list`}
                  index={index}
                  key={drill.id}
              >
                {provided => {
                  return (
                      <>
                        <Card
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={classes.Muicard}
                        >
                          <CardMedia
                              component="img"
                              alt=""
                              height="120"
                              image={drill.images.findEndGetProp(Boolean, 'full_url')}
                              title=""
                          />
                          <CardContent className={classes.CardContentHeight}>
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                component="p"
                                gutterBottom
                                style={{fontSize: '0.875rem', display: 'flex'}}
                            >
                              <AccessTime
                                  fontSize="small"
                                  className={classes.drillDetailDuration}
                              />
                              {drill.duration}min
                              <PeopleOutlined
                                  fontSize="small"
                                  className={classes.drillDetailPlayer}
                              />
                              {drill.min_players}+
                            </Typography>
                            <Typography
                                variant="body2"
                                color="textPrimary"
                                component="p"
                                gutterBottom
                                style={{fontSize: '0.875rem'}}
                            >
                              {drill.title}
                            </Typography>
                          </CardContent>
                          <CardActions>
                            <Box className={classes.title} display="flex">
                              <IconButton color="primary">
                                <InfoOutlinedIcon
                                    onClick={() => changeOpenedDrillId(drill.id)}
                                />
                              </IconButton>
                              <HeartIcon
                                  isActive={getIsFavorite(drill.id)}
                                  entity="drills"
                                  entityId={drill.id}
                                  isPositionRelative
                              />
                            </Box>
                            <AddDrillMenu
                                drillId={drill.id}
                                handleMenuClose={handleMenuClose}
                                categoryType={categoryType}
                                shortAddButton="true"
                            />
                          </CardActions>
                        </Card>
                      </>
                  )
                }}
              </Draggable>
            </Hidden>

            <Hidden only={['xl', 'lg']}>
              <Card className={classes.root}>
                <CardMedia
                    className={classes.cover}
                    image={drill.images.findEndGetProp(Boolean, 'full_url')}
                    title={drill.title}
                />
                <div className={classes.details}>
                  <CardContent className={classes.content}>
                    <Typography component="p" variant="p">
                      <strong>{drill.title}</strong>
                    </Typography>
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                        style={{color: 'silver'}}
                    >
                      <AccessTime
                          fontSize="small"
                          className={classes.drillDetailDuration}
                      />
                      {drill.duration}min
                      <PeopleOutlined
                          fontSize="small"
                          className={classes.drillDetailPlayer}
                      />
                      {drill.min_players}+
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <AddDrillMenu
                        drillId={drill.id}
                        handleMenuClose={handleMenuClose}
                        categoryType={categoryType}
                        shortAddButton="true"
                    />
                    <IconButton>
                      <InfoOutlinedIcon
                          className={classes.InfoIcon}
                          onClick={() => changeOpenedDrillId(drill.id)}
                      />
                    </IconButton>

                    <HeartIcon
                        isActive={getIsFavorite(drill.id)}
                        entity="drills"
                        entityId={drill.id}
                        isPositionRelative
                    />
                  </CardActions>
                </div>
              </Card>
            </Hidden>
          </Drill>
        </>
    ))


  useEffect(() => {
    checkMediaSc()
  }, [pageCount, startIndex, endIndex])

  return (
    <>
      {openedDrillId && (
        <DrillModal
          drill={openedDrill}
          onHide={closeOpenedDrill}
          categoryType={categoryType}
          sessionDrills={sessionDrills}
          changeSessionDrills={changeSessionDrills}
          plusButton="true"
          drillId={openedDrill.id}
          handleMenuClose={handleMenuClose}
          anchorEl={false}
          open={Boolean(openedDrillId)}
        />
      )}

      <Droppable droppableId={`${categoryType}-list`} isDropDisabled>
        {provided => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <div className={classes.drillListRow}>
              <DrillsContainer>
                {drills.length === 0 && (
                  <section className={classes.skeletonContent}>
                    <div
                      className={classes.customSkeleton}
                      style={{ position: 'relative' }}
                    >
                      <Skeleton variant="rect" className="profileImgSkeleton" />

                      <div>
                        <Skeleton variant="text" className="titleSkeleton" />
                        {smScreen === 1 && (
                          <Skeleton
                            variant="text"
                            className="contentSkeleton"
                          />
                        )}
                      </div>
                    </div>
                    <div
                      className={classes.customSkeleton}
                      style={{ position: 'relative' }}
                    >
                      <Skeleton variant="rect" className="profileImgSkeleton" />

                      <div>
                        <Skeleton variant="text" className="titleSkeleton" />
                        {smScreen === 1 && (
                          <Skeleton
                            variant="text"
                            className="contentSkeleton"
                          />
                        )}
                      </div>
                    </div>
                    <div
                      className={classes.customSkeleton}
                      style={{ position: 'relative' }}
                    >
                      <Skeleton variant="rect" className="profileImgSkeleton" />

                      <div>
                        <Skeleton variant="text" className="titleSkeleton" />
                        {smScreen === 1 && (
                          <Skeleton
                            variant="text"
                            className="contentSkeleton"
                          />
                        )}
                      </div>
                    </div>
                    <div
                      className={classes.customSkeleton}
                      style={{ position: 'relative' }}
                    >
                      <Skeleton variant="rect" className="profileImgSkeleton" />

                      <div>
                        <Skeleton variant="text" className="titleSkeleton" />
                        {smScreen === 1 && (
                          <Skeleton
                            variant="text"
                            className="contentSkeleton"
                          />
                        )}
                      </div>
                    </div>
                    <div
                      className={classes.customSkeleton}
                      style={{ position: 'relative' }}
                    >
                      <Skeleton variant="rect" className="profileImgSkeleton" />

                      <div>
                        <Skeleton variant="text" className="titleSkeleton" />
                        {smScreen === 1 && (
                          <Skeleton
                            variant="text"
                            className="contentSkeleton"
                          />
                        )}
                      </div>
                    </div>
                  </section>
                )}
                {DrillItems}
              </DrillsContainer>
              <div className={classes.drillFooter}>
                <FormControl className={classes.formControl} variant="outlined">
                  <Select value={pageCount || ' '} onChange={setCardsLength}>
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                  </Select>
                </FormControl>
                <Pagination
                  count={Math.ceil(drillsLength / pageCount)}
                  defaultPage={page}
                  onChange={handleChange}
                  size="small"
                />
              </div>
            </div>
            {provided.placeholder}
          </div>
        )}
      </Droppable>

      {drillForNotify && (
        <DrillsSnackbar
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
          className={classes.snackbar}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            className={classes.alert}
          >
            <Box className="d-flex" alignItems="center">
              <Box className="Snackbar-Box">
                <Typography variant="h4" component="h4" className="title">
                  <Translate id="snackbar.drill-added" />
                </Typography>
                <Typography variant="h6" component="h6">
                  {drillForNotify.title}
                </Typography>
              </Box>
            </Box>
          </Alert>
        </DrillsSnackbar>
      )}
    </>
  )
}

DrillsList.defaultProps = {
  sessionDrills: [],
  drills: [],
  favoritesDrills: [],
}

DrillsList.propTypes = {
  sessionDrills: PROP_TYPES.arrayOfObjects,
  changeSessionDrills: PROP_TYPES.func.isRequired,
  categoryType: PROP_TYPES.string.isRequired,
  drills: PROP_TYPES.arrayOfObjects,
  favoritesDrills: PROP_TYPES.arrayOfObjects,
  shareDrill: PROP_TYPES.func.isRequired,
  fetchDrill: PROP_TYPES.func.isRequired,
  searchedValue: PROP_TYPES.string.isRequired,
}

export default connect(
  ({ drills: { itemsByCategory }, favorites }, { categoryType }) => ({
    drills: itemsByCategory[categoryType],
    favoritesDrills: favorites.items.drills,
  }),
  {
    shareDrill: drillsModule.shareDrill,
    fetchDrill: drillsModule.fetchDrill,
  }
)(DrillsList)
