/* eslint-disable */
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { AppBar, Toolbar, Hidden } from '@material-ui/core'
import { SubLink } from 'containers'
import CssBaseline from '@material-ui/core/CssBaseline'
import Logo from 'assets/images/logo-black.svg'

import mobileLogo from 'assets/images/mobile-logo.png'
import PROP_TYPES from 'constants/propTypes'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  UserAppBar: {
    backgroundColor: '#fff',
    boxShadow: '14px 5px 35px 0 rgba(0,0,0,0.08)',
  },
  brand: {
    width: '155px',
  },
  title: {
    flex: '1 1 0',
  },
  [theme.breakpoints.down('sm')]: {
    brand: {
      height: '36px',
      width: 'auto',
    },
  },
}))

const UserHead = ({ children }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.UserAppBar}>
        <Toolbar>
          <SubLink to="/" className={classes.title}>
            <Hidden only={['xs', 'sm']}>
              {' '}
              <img
                src={Logo}
                alt="Coachbetter"
                className={classes.brand}
              />{' '}
            </Hidden>
            <Hidden only={['md', 'lg', 'xl']}>
              {' '}
              <img
                src={mobileLogo}
                alt="Coachbetter"
                className={classes.brand}
              />{' '}
            </Hidden>
          </SubLink>
          {children}
        </Toolbar>
      </AppBar>
    </div>
  )
}
UserHead.defaultProps = {
  children: <div />,
}

UserHead.propTypes = {
  children: PROP_TYPES.children,
}

export default UserHead
