import styled from 'styled-components'

export default styled.div`
  :before {
    content: '\\2605';
  }

  :hover {
    color: gold;
  }

  color: ${({ active }) => (active ? 'gold' : 'lightgrey')};
  cursor: pointer;
`
