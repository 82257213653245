import styled from 'styled-components'
import { Container, Navbar } from 'react-bootstrap'

export const Header = styled(Navbar)`
  display: flex;
  justify-content: space-between;
  z-index: 200;
  // background: #ffffff;
  user-select: none;

  > div {
    display: flex;
    align-items: center;
  }
`

// @todo: replace icon
export const OpenCloseMenuButton = styled.div`
  width: 76px;
  height: 60px;
  ${({ theme }) => theme.flexCenter};
  cursor: pointer;
  // color: #000000;
  font-size: 28px;
  padding-right: 18px;

  :before {
    content: ${({ ismenuopen }) => (ismenuopen ? '"\\2716"' : '"\\2630"')};
  }
`

// @todo: add media query to max-width
export const ContentContainer = styled(Container)`
  padding: 20px 0;

  @media (min-width: 1200px) {
    margin: 0 auto;
    max-width: 1185px;
  }
`

export const LogoImage = styled.img`
  height: 3rem;
`
