import React from 'react'
import { Form } from 'react-bootstrap'
// import { ListItem, ListItemIcon } from '@material-ui/core'
import PROP_TYPES from 'constants/propTypes'

const Checkbox = ({ id, label, checked, onChange }) => (
  <>
    {/* <List>
   <ListItem key={value} role={undefined} dense button onClick={handleToggle(value)}>
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={checked.indexOf(value) !== -1}
                tabIndex={-1}
                disableRipple
                inputProps={{ 'aria-labelledby': labelId }}
              />
            </ListItemIcon>
            <ListItemText id={labelId} primary={`Line item ${value + 1}`} />
            </ListItem>
   </List> */}
    <Form.Group>
      <Form.Check
        custom
        type="checkbox"
        id={id}
        label={label}
        checked={checked}
        onChange={onChange}
      />
    </Form.Group>
  </>
)

Checkbox.defaultProps = {
  checked: false,
}

Checkbox.propTypes = {
  id: PROP_TYPES.id.isRequired,
  label: PROP_TYPES.string.isRequired,
  checked: PROP_TYPES.bool,
  onChange: PROP_TYPES.func.isRequired,
}

export default Checkbox
