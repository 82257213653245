import React from 'react'
import { makeStyles, createTheme } from '@material-ui/core/styles'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { Card, CardHeader, CardContent, Typography } from '@material-ui/core'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import './PlayerFitnessMedical.scss'
import TeamCaptainLabel from 'assets/images/captain.svg'
import GoalkeeperLabel from 'assets/images/Goalkeeper.svg'
import RightfootedLabel from 'assets/images/right-footed.svg'
import hasSubscription from 'utils/hasSubscription'
import { PLAYER_TAGS, PLAYER_POSITIONS } from 'constants/player'

const CardMui = styled(Card)`
  height: 100%;
  position: relative;
  overflow: visible !important;
  .MuiList-root {
    display: flex;
    flex-wrap: wrap;
    .MuiListItem-root {
      width: 50%;
      padding-left: 2px;
      padding-right: 2px;
    }
  }
`
const CaptainLabel = styled.div`
  height: 100%;
  position: absolute;
  right: -12px;
  top: -11px;
`
const theme = createTheme({})
const useStyles = makeStyles({
  TotalPlayerTime: {
    paddingLeft: '.9rem',
    fontSize: '1.25rem',
    fontWeight: 'bold',
  },

  [theme.breakpoints.down('sm')]: {},
})

const PlayerDetails = ({ player, statistics }) => {
  const classes = useStyles()

  return (
    <CardMui>
      {(player.captain === 1 || player.captain === true) && (
        <CaptainLabel>
          <img src={TeamCaptainLabel} alt={TeamCaptainLabel} />
        </CaptainLabel>
      )}
      <CardHeader
        title={
          <Typography variant="h4" component="h4">
            <Translate id="player.player-info" />
          </Typography>
        }
      />
      <CardContent>
        <List component="ul">
          <ListItem>
            <ListItemIcon>
              <img src={GoalkeeperLabel} alt={GoalkeeperLabel} />
            </ListItemIcon>
            <ListItemText
              primary={PLAYER_TAGS.find((p) => p.value === player.tag).title}
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <img src={GoalkeeperLabel} alt={GoalkeeperLabel} />
            </ListItemIcon>
            <ListItemText
              primary={
                PLAYER_POSITIONS.find((p) => p.value === player.position).title
              }
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <img src={RightfootedLabel} alt={RightfootedLabel} />
            </ListItemIcon>
            <ListItemText
              primary={
                player.foot === 'r' ? (
                  <Translate id="player.r-foot" />
                ) : (
                  <Translate id="player.l-foot" />
                )
              }
            />
          </ListItem>
          <ListItem>
            <ListItemIcon className={classes.TotalPlayerTime}>
              {player.number}
            </ListItemIcon>
            <Translate>
              {({ translate }) => (
                <ListItemText primary={translate('form.number')} />
              )}
            </Translate>
          </ListItem>
          {hasSubscription() === 'basic' && (
            <ListItem className="w-100">
              <ListItemIcon className="h5 font-weight-bold">
                {Number.isNaN(
                  statistics.trainings_attended / statistics.trainings_total
                )
                  ? '0%'
                  : `${Math.round(
                      (statistics.trainings_attended /
                        statistics.trainings_total) *
                        100
                    )}%`}
              </ListItemIcon>
              <ListItemText
                primary={<Translate id="player.training-match-attendence" />}
              />
            </ListItem>
          )}
        </List>
      </CardContent>
    </CardMui>
  )
}

PlayerDetails.propTypes = {
  player: PropTypes.shape().isRequired,
  statistics: PropTypes.shape().isRequired,
}

export default PlayerDetails
