import React from 'react'
import { connect } from 'react-redux'
import { saveAs } from 'file-saver'
import styled from 'styled-components'
import { ActionWithPrompt } from 'components'
import DocumentsIcon from 'assets/images/icon-documents.svg'
import { documents as documentsModule } from 'redux/modules'
import { ItemsContainerStyled, ItemStyled } from '../styledComponents'
import AddNewFile from './AddNewFile'
import WrappedRemoveButton from './WrappedRemoveButton'
import PROP_TYPES from 'constants/propTypes'

const GetFileIcon = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 5px;

  position: absolute;
  left: calc(50% - 15px);
  top: calc(50% - 15px);

  background-color: var(--primary-green);
  cursor: pointer;

  > div {
    width: 30px;
    height: 30px;
    background: url(${DocumentsIcon}) no-repeat center;
    background-size: 30px 30px;
  }
`

const FilesList = ({
  files,
  currentTeamId,
  currentFolderParentId,
  currentFolderId,
  getFile,
  removeFile,
}) => {
  const handleGetFile = fileId =>
    getFile(currentTeamId, currentFolderId, fileId).then(
      ({
        response: {
          data: { download_url: downloadUrl },
        },
      }) => {
        saveAs(downloadUrl)
      }
    )

  return (
    <>
      {(currentFolderParentId || files.length > 0) && <p>Files</p>}
      <ItemsContainerStyled>
        {currentFolderParentId && (
          <AddNewFile
            currentTeamId={currentTeamId}
            currentFolderId={currentFolderId}
          />
        )}
        {files.map(item => (
          <ItemStyled key={item.id}>
            <span>{item.file_name}</span>
            <GetFileIcon onClick={() => handleGetFile(item.id)}>
              <div />
            </GetFileIcon>
            <ActionWithPrompt
              onSuccess={() =>
                removeFile(currentTeamId, currentFolderId, item.id)
              }
              message={`Are you sure you want to delete file ${
                item.file_name
              }?`}
            >
              <WrappedRemoveButton />
            </ActionWithPrompt>
          </ItemStyled>
        ))}
      </ItemsContainerStyled>
    </>
  )
}

FilesList.defaultProps = {
  files: [],
  currentFolderParentId: null,
}

FilesList.propTypes = {
  files: PROP_TYPES.arrayOfObjects,
  currentTeamId: PROP_TYPES.id.isRequired,
  currentFolderParentId: PROP_TYPES.id,
  currentFolderId: PROP_TYPES.id.isRequired,
  getFile: PROP_TYPES.func.isRequired,
  removeFile: PROP_TYPES.func.isRequired,
}

export default connect(
  null,
  {
    getFile: documentsModule.getFile,
    removeFile: documentsModule.removeFile,
  }
)(FilesList)
