import React from 'react'
import { Translate } from 'react-localize-redux'

export const goalType = [
    {key:"insidethebox", displayValue:<Translate id="matches.highlights.insidethebox" />},
    {key:"outsidethebox", displayValue: <Translate id="matches.highlights.outsidethebox" />},
    {key:"directfreekick", displayValue: <Translate id="matches.highlights.directfreekick" />},
    {key:"indirectfreekick", displayValue: <Translate id="matches.highlights.indirectfreekick" />},
    {key:"cornerkickright", displayValue: <Translate id="matches.highlights.cornerkickright" />},
    {key:"cornerkickleft", displayValue: <Translate id="matches.highlights.cornerkickleft" />},
    {key:"counterkick", displayValue: <Translate id="matches.highlights.counterkick" />},
    {key:"penalty", displayValue: <Translate id="matches.highlights.penalty" />},
    {key:"owngoal", displayValue: <Translate id="matches.highlights.owngoal" />},
    {key:"other", displayValue: <Translate id="matches.highlights.other" />},
]
export const cardType = [
  {key:"yellow", displayValue:<Translate id="matches.highlights.yellow" />},
  {key:"red", displayValue:<Translate id="matches.highlights.red" />},
  {key:"yellow-red", displayValue:<Translate id="matches.highlights.yellow-red" />},
]
export const cardReason = [
  {key:"foul", displayValue:<Translate id="matches.highlights.foul" />},
  {key:"unsporting-behaviour", displayValue:<Translate id="matches.highlights.unsporting-behaviour" />},
  {key:"violent-conduct", displayValue:<Translate id="matches.highlights.violent-conduct" />},
  {key:"tactical", displayValue:<Translate id="matches.highlights.tactical" />},
  {key:"other", displayValue:<Translate id="matches.highlights.other" />},
]
export const swapReason = [
  {key:"minimise-risk", displayValue:<Translate id="matches.highlights.minimise-risk" />},
  {key:"player-injured", displayValue:<Translate id="matches.highlights.player-injured" />},
  {key:"rest-player", displayValue:<Translate id="matches.highlights.rest-player" />},
  {key:"tactical-change", displayValue:<Translate id="matches.highlights.tactical-change" />},
  {key:"player-tired", displayValue:<Translate id="matches.highlights.player-tired" />},
  {key:"insuf-performance", displayValue:<Translate id="matches.highlights.insuf-performance" />},
  {key:"other", displayValue:<Translate id="matches.highlights.other" />},
]
export const matchTypes = [
  {type:"league", title:<Translate id="matches.league-match" />},
  {type:"friendly", title: <Translate id="matches.friendly-match" />},
  {type:"cup", title: <Translate id="matches.cup-match" />},
  {type:"non-conference", title: <Translate id="matches.non-conference" />},
  {type:"conference", title: <Translate id="matches.conference-game" />},
  {type:"playoff", title: <Translate id="matches.playoff-game" />},
  {type:"other", title: <Translate id="matches.other-match" />},
]