import styled from 'styled-components'

export default styled.div`
  width: 30px;
  height: 30px;
  border-radius: 15px;
  font-size: 1.5rem;
  position: absolute;
  left: calc(50% - 15px);
  top: calc(50% - 15px);
  background: var(--dark-green);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background: #255f64;
  }
  .trainingPackage & {
    top: calc(40% - 15px);
  }
`
