import React, { useContext } from 'react'
import { Translate } from 'react-localize-redux'
import { connect } from 'react-redux'
import {
  InputNumber,
} from 'components'
import { Card, CardContent, CardHeader, Typography,Grid, Paper } from '@material-ui/core'
import { makeStyles,styled } from '@material-ui/styles'
import { Context } from '../MatchStateContext'
import PROP_TYPES from 'constants/propTypes'

const useStyles = makeStyles(() => ({
  card: {
      marginBottom: "1rem",
    },
  cardContent:{
    paddingBottom: "16px",
  },
  vsBox:{
    paddingLeft:"5px",
    paddingRight:"5px"
  }
}))
const Item = styled(Paper)(() => ({
  textAlign: 'center',
  // color: theme.palette.text.secondary,
}));

const MatchResult = ({ teamName }) => {
  const classes = useStyles()
  const {
    matchState: {
      opponent,
      goals_scored: goalsScored = 0,
      goals_received: goalsReceived = 0,
    },
    onFieldChange,
  } = useContext(Context)

  const onChange = fieldName => ({ target: { value } }) =>
    onFieldChange(fieldName, value)

  return (
    <Translate>
      {({ translate }) => (
        <Card className={classes.card}>
          <CardHeader
              title={
                <Typography variant="h4" component="h4">
                    {translate('matches.final-score')}
                </Typography>
              }
          />
          <CardContent className={classes.cardContent}>
            <Grid
              container
              alignItems="center"
            >
              <Grid item xs={5}>
                <Item>
                {teamName || '{team name}'} 
                </Item>
              </Grid>
              <Grid item xs={2}>
                <Item>
                  <Translate id="matches.vs" variant="h4"/>
                </Item>
              </Grid>
              <Grid item xs={5}>
                <Item>
                  {opponent}
                </Item>
              </Grid>  
            </Grid>
            <Grid
              container
              alignItems="center"
            >
              <Grid item xs={5}>
              <Item>
                <InputNumber
                  minLimit= {0}
                  input={{
                    value: goalsScored,
                    onChange: onChange('goals_scored'),
                  }}
                />
                </Item>
              </Grid>
              <Grid item xs={2}/>
              <Grid item xs={5}>
              <Item>
                <InputNumber
                  minLimit= {0}
                  input={{
                    value: goalsReceived,
                    onChange: onChange('goals_received'),
                  }}
                />
                </Item>
              </Grid>
            </Grid>
          </CardContent>
      </Card>
      )}
    </Translate>
  )
}

MatchResult.defaultProps = {
  teamName: '',
}

MatchResult.propTypes = {
  teamName: PROP_TYPES.string,
}

export default connect(({ matches: { current: { team = {} } } }) => ({
  teamName: team.name,
}))(MatchResult)
