import React, {useCallback, useState} from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'

import { DrillsFilter, DrillsList } from './components'
import PROP_TYPES from 'constants/propTypes'

const getCategoryIdAndParentCategories = (categories, targetCategoryTitle) => {
  const targetCategoryId = (
    categories.find(category => category.title === targetCategoryTitle) || {}
  ).id

  return [
    targetCategoryId,
    categories.filter(category => category.parent_id === targetCategoryId),
  ]
}

const getCategoriesIdsForTargetCategory = (parentCategories, categories) => {
  const parentCategoriesIds = parentCategories.map(category => category.id)

  return [
    ...parentCategoriesIds,
    ...categories
      .filter(category => parentCategoriesIds.includes(category.parent_id))
      .map(({ id }) => id),
  ]
}

const DrillsByCategoryContainer = ({
  sessionDrills,
  changeSessionDrills,
  drillsCategories,
  fetchDrillsByCategory,
  locale,
  categoryType,
  categoryTitle,
  tabTitle,
  teams,
  match: {
    params: { teamId },
  },
}) => {
  const [categoryId, parentCategories] = getCategoryIdAndParentCategories(
    drillsCategories,
    categoryTitle
  )
  const currentTeam = teams.findById(+teamId)

  const categoriesIdsForTargetCategory = [
    categoryId,
    ...getCategoriesIdsForTargetCategory(parentCategories, drillsCategories),
  ]

  const fetchDrills = useCallback(
    (data = {}) => {
      fetchDrillsByCategory(categoryType, {
        filter_by_age_class: 1,
        filter_by_own: 0,
        filter_by_favorites: 0,
        categories: categoriesIdsForTargetCategory,
        locale,
        order_by: 'random',
        ...data,
        global_filter: 1,
        tabTitle,
        page: 'Training Dashboard',
        team_activity_filter:1, // If its active(1). It means we need team activities too
        age_classes: [currentTeam.age_class],
      })
    },
    [categoryType, locale]
  )

  const [searchedValue,changeSearchedValue] = useState('')

  return (
    <div>
      <DrillsFilter
        drillsCategories={drillsCategories}
        locale={locale}
        parentCategories={parentCategories}
        categoriesIdsForTargetCategory={categoriesIdsForTargetCategory}
        fetchDrills={fetchDrills}
        searchedValue={searchedValue}
        changeSearchedValue={changeSearchedValue}
      />
      
      <DrillsList
        sessionDrills={sessionDrills}
        changeSessionDrills={changeSessionDrills}
        categoryType={categoryType}
        searchedValue={searchedValue}
      />
    </div>
  )
}

DrillsByCategoryContainer.defaultProps = {
  sessionDrills: [],
}

DrillsByCategoryContainer.propTypes = {
  sessionDrills: PROP_TYPES.arrayOfObjects,
  changeSessionDrills: PROP_TYPES.func.isRequired,
  drillsCategories: PROP_TYPES.arrayOfObjects.isRequired,
  fetchDrillsByCategory: PROP_TYPES.func.isRequired,
  locale: PROP_TYPES.string.isRequired,
  tabTitle: PROP_TYPES.string.isRequired,
  categoryType: PROP_TYPES.string.isRequired,
  categoryTitle: PROP_TYPES.string.isRequired,
  teams: PROP_TYPES.shape().isRequired,
  match: PROP_TYPES.shape().isRequired,
}

export default compose(
  withRouter,
  connect(
    ({ teams }) => ({
      teams: teams.items,
    }),
    null
  )
)(DrillsByCategoryContainer)
