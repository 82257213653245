import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { connect } from 'react-redux'
import NavigationPrompt from 'react-router-navigation-prompt'
import { generalEvents } from 'redux/modules'
import CircularSpinner from 'components/loader/CircularSpinner'
import AlertDialog from 'components/AlertDialog'
import { Context } from './EventStateContext'
import EventDashboardLayout from './EventDashboardLayout'
import PROP_TYPES from 'constants/propTypes'

const EventDashboardContainer = ({ eventId, updateEvent, isLoading }) => {
  const { eventState, anyChange, changeWholeContextState, setAnyChange } =
    useContext(Context)
  let { eventPlayers } = eventState
  const [open, setOpen] = useState(true)

  const updateEventState = (eventPlayerUuid, available) => {
    eventPlayers = eventPlayers.map((eventPlayer) =>
      eventPlayer.uuid === eventPlayerUuid
        ? {
            ...eventPlayer,
            availability: available,
            isSelected: false,
          }
        : { ...eventPlayer }
    )
  }

  const onDrop = (eventPlayerUuid, target) => {
    const targetPlayer = eventState.eventPlayers.find(
      (eventPlayer) => eventPlayer.uuid === eventPlayerUuid
    )
    if (targetPlayer) {
      if (target === 'notAvailablePlayers') {
        updateEventState(eventPlayerUuid, 'NOT_AVAILABLE')
      } else if (target === 'notRespondedPlayers') {
        updateEventState(eventPlayerUuid, 'NOT_RESPONDED')
      } else {
        updateEventState(eventPlayerUuid, 'AVAILABLE')
      }
      const selectedPlayers = eventState.eventPlayers.filter(
        (eventPlayer) => eventPlayer.isSelected && eventPlayer.uuid !== eventPlayerUuid
      )
      if (selectedPlayers.length > 0) {
        selectedPlayers.forEach((eventPlayer) => {
          if (target === 'notAvailablePlayers') {
            updateEventState(eventPlayer.uuid, 'NOT_AVAILABLE')
          } else if (target === 'notRespondedPlayers') {
            updateEventState(eventPlayer.uuid, 'NOT_RESPONDED')
          } else {
            updateEventState(eventPlayer.uuid, 'AVAILABLE')
          }
        })
      }
      changeWholeContextState({
        ...eventState,
        eventPlayers,
      })
    }
  }

  const handleSave = () => {
    setOpen(false)

    const payload = {
      date: eventState.date,
      location: eventState.location,
      description: eventState.description,
      street: eventState.street,
      city: eventState.city,
      country: eventState.country,
      postal_code: eventState.postal_code,
      lat: eventState.lat,
      lng: eventState.lng,
      state: eventState.state,
      end_time: eventState.end_time,
      additional_info: eventState.additional_info,
      event_players: eventPlayers.map((eventPlayer) => ({
        uuid: eventPlayer.uuid,
        availability: eventPlayer.availability,
      })),
    }

    updateEvent(eventId, payload).then((res) => {
      changeWholeContextState(res.response.data)
      setAnyChange(false)
    })
  }

  return (
    <>
      {eventState.date && (
        <EventDashboardLayout
          onDrop={onDrop}
          handleSave={handleSave}
          tickers={eventState.tickers}
          matchPolarSelectedId={eventState.polar_event_id}
          eventId={eventId}
        />
      )}
      <NavigationPrompt when={anyChange}>
        {({ onConfirm, onCancel }) => {
          return (
            <AlertDialog
              open={open}
              onClose={onCancel}
              onSuccess={handleSave}
              onCancel={onConfirm}
              title={<Translate id="dialog.unsaved.title" />}
              subTitle={<Translate id="dialog.unsaved.content-event" />}
              submitButtonLabel={<Translate id="dialog.unsaved.save-event" />}
              cancelButtonLabel={<Translate id="dialog.unsaved.leave" />}
            />
          )
        }}
      </NavigationPrompt>
      {isLoading && <CircularSpinner loadingcircular={isLoading} />}
    </>
  )
}

EventDashboardContainer.propTypes = {
  eventId: PROP_TYPES.id.isRequired,
  updateEvent: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
}

export default connect(
  ({ generalEvents: events }) => ({
    isLoading: events.isLoading,
  }),
  {
    updateEvent: generalEvents.updateEvent,
  }
)(EventDashboardContainer)
