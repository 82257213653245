import { cloneElement } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter, matchPath } from 'react-router-dom'
import { seasons } from 'redux/modules'
import urlConstructor from 'utils/urlConstructor'
import PROP_TYPES from 'constants/propTypes'
import { INITIAL_ROUTE } from 'constants/routes'
import { setCurrentTeam } from 'utils/localstorage'


const createPathname = (newTeamId, newSeasonId, pathname) => {
  const match = matchPath(pathname, {
    path: INITIAL_ROUTE,
  })

  const startPositionOfPathWithoutTeamIdAndSeasonId = match
    ? match.url.length
    : 0

  const pathWithoutTeamIdAndSeasonId = pathname.slice(
    startPositionOfPathWithoutTeamIdAndSeasonId
  )

  return urlConstructor(newTeamId, newSeasonId, pathWithoutTeamIdAndSeasonId)
}

const getFirstSeasonIdForNewTeam = response => {
  const d = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate()
  )
  const activeSeason = response.data.filter(
    season => d >= new Date(season.start_date) && d <= new Date(season.end_date)
  )
  const firstSeason =
    activeSeason.length > 0
      ? activeSeason[0]
      : response.data[response.data.length - 1]
  
return firstSeason.id
}

const TeamSelector = props => {
  const {
    history,
    location: { pathname },
    fetchSeasons,
    children,
    redirectTo,
  } = props

  const onSelect = newTeamId => {
    setCurrentTeam(newTeamId)
    return fetchSeasons(newTeamId).then(({ response }) => {
      const newSeasonId = getFirstSeasonIdForNewTeam(response)

      const newPathname = createPathname(
        newTeamId,
        newSeasonId,
        redirectTo || pathname
      )

      history.push(newPathname)
    })
  }

  return cloneElement(children, { onSelect })
}

TeamSelector.defaultProps = {
  redirectTo: '',
}

TeamSelector.propTypes = {
  history: PROP_TYPES.history.isRequired,
  location: PROP_TYPES.location.isRequired,
  fetchSeasons: PropTypes.func.isRequired,
  children: PROP_TYPES.children.isRequired,
  redirectTo: PropTypes.string,
}

export default compose(
  withRouter,
  connect(
    null,
    {
      fetchSeasons: seasons.fetchSeasons,
    }
  )
)(TeamSelector)
