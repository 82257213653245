import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom';
import { removePolar } from 'utils/localstorage';
import { Collapsible, LoadingSpinner } from 'components';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { thirdParty } from 'redux/modules';

const PolarPlayers=({polarTeam,selectedPolarEevent,polarFetchSessionAllSummary,polarTrainning,setPolarToken,polarToken,trainingId,matchId})=> {
    const [polarAllSummary,setPolarAllSummary]=useState(null)
    const [isLoading,setLoading]=useState(false)

    const fetchTrainingPlayersSummary=(id,polarId)=>{
        setLoading(true)
        polarFetchSessionAllSummary(id,{polarId,trainingId,matchId})
        .then((res) =>res.response)
          .then(json=>{
            setLoading(false)
            if(json.error && json.error==='invalid_token'){
              removePolar()
              setPolarToken(null)

              return
            }
            const allPlayers=polarTeam.players.map(p=>p)
            json.participants.forEach(player => {
                const findPlayer=allPlayers.find(p=>p.player_id===player.player_id)
                if(findPlayer){
                    player.first_name=findPlayer.first_name;
                    player.last_name=findPlayer.last_name;
                    player.role=findPlayer.role;
                }
            });
            setPolarAllSummary(json)            
          })
          .catch((e) => {
            console.error(e);
            setLoading(false)
          });
      }
    useEffect(()=>{
      if(selectedPolarEevent && polarToken && polarToken.id){
        fetchTrainingPlayersSummary(selectedPolarEevent,polarToken.id)        
      }
    },[selectedPolarEevent])

  return (
      <Collapsible title='Polar players'>
       <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Players</TableCell>
            <TableCell align="right">Cardio Load</TableCell>
            <TableCell align="right">Muscle load</TableCell>
            <TableCell align="right">HR avg %</TableCell>
            <TableCell align="right">Distance</TableCell>
            <TableCell align="right">Calories</TableCell>
            <TableCell align="right">Sprints</TableCell>
            <TableCell align="right">Speed avg</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {isLoading && <LoadingSpinner />}
          {!isLoading && polarAllSummary && polarAllSummary.participants && polarAllSummary.participants.map((row) => (
            <TableRow
              key={row.player_id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {`${row.first_name} ${row.last_name}`}
              </TableCell>
              <TableCell align="right">{row.summary.cadence_max}</TableCell>
              <TableCell align="right">{row.summary.cadence_max}</TableCell>
              <TableCell align="right">{row.summary.heart_rate_avg_percent} %</TableCell>
              <TableCell align="right">{(+row.summary.distance_meters).toFixed()}</TableCell>
              <TableCell align="right">{row.summary.kilo_calories}</TableCell>
              <TableCell align="right">{row.summary.sprint_counter}</TableCell>
              <TableCell align="right">{(+row.summary.speed_avg_kmh).toFixed(1)} km/h</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      </TableContainer>
      </Collapsible>    
  );
}

PolarPlayers.defaultProps = {
}
PolarPlayers.propTypes = {
    polarTeam: PropTypes.shape().isRequired,
    selectedPolarEevent: PropTypes.number.isRequired,
    polarFetchSessionAllSummary: PropTypes.func.isRequired,
}
  
export default compose(
    withRouter,
    connect(
      null,
      {
        polarFetchSessionAllSummary: thirdParty.polarFetchSessionAllSummary,
      }
    )
  )(PolarPlayers)