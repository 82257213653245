import { combineActions, handleActions } from 'redux-actions'
import { Base64 } from 'js-base64'
import {
  actionsTypesGenerator,
  onRequest,
  onSuccess,
  onFailure,
} from 'utils/reduxHelpers'
import initialState from '../initialState'

const LOGIN_USER = actionsTypesGenerator('LOGIN_USER')
const LOGIN_WITH_SOCIAL = actionsTypesGenerator('LOGIN_WITH_SOCIAL')
const LOGOUT_USER = actionsTypesGenerator('LOGOUT_USER')
const SIGNUP_USER = actionsTypesGenerator('SIGNUP_USER')
const UPDATE_USER = actionsTypesGenerator('UPDATE_USER')
const FETCH_USER = actionsTypesGenerator('FETCH_USER')
const RESET_AUTH_STATE = 'RESET_AUTH_STATE'
const SYNC_PERMISSIONS = actionsTypesGenerator('SYNC_PERMISSIONS')

export const login = (email, password, locale) => ({
  types: LOGIN_USER,
  callAPI: {
    method: 'POST',
    path: '/login',
    params: {
      email,
      password: Base64.encode(password),
      locale
    },
    data: {
      preventToastNotification: true,
    }
  }
})

export const loginWithSocial = ({
  provider,
  locale,
  code,
  scope,
  utm_source,
  utm_medium,
  utm_campaign,
  utm_term,
  utm_content
}) => ({
  types: LOGIN_WITH_SOCIAL,
  callAPI: {
    method: 'POST',
    path: `/login/${provider}/${locale}/callback`,
    params: {
      code,
      scope,
      utm_source,
      utm_medium,
      utm_campaign,
      utm_term,
      utm_content
    },
    data: {
      preventToastNotification: true,
    }
  }
})

export const logout = () => ({
  types: LOGOUT_USER,
  callAPI: {
    method: 'POST',
    path: '/logout',
    data: {
      preventToastNotification: true,
    }
  }
})

export const signup = (params) => ({
  types: SIGNUP_USER,
  callAPI: {
    method: 'POST',
    path: '/signup',
    params,
    entity: 'User',
    data: {
      preventToastNotification: true,
    }
  }
})

export const updateUser = data => ({
  types: UPDATE_USER,
  callAPI: {
    method: 'PUT',
    entity: 'User',
    path: `/user/${data.id}`,
    data,
  },
})

export const fetchUser = () => ({
  types: FETCH_USER,
  callAPI: {
    method: 'GET',
    entity: 'User',
    path: `/user`,
  },
})

export const reset = () => ({
  type: RESET_AUTH_STATE
})

export const syncPermissions = () => ({
  types: SYNC_PERMISSIONS,
  callAPI: {
    method: 'GET',
    path: '/user/permissions',
    data: {
      preventToastNotification: true,
    }
  }
})

export default handleActions(
  {
    RESET_AUTH_STATE: (state) => {
      return {
        ...initialState.auth,
        token: state.token,
        user:state.user,
        signupClubId: state.signupClubId,
        signupTeamId: state.signupTeamId,
      }
    },
    [combineActions(
      LOGIN_USER.request,
      LOGIN_WITH_SOCIAL.request,
      LOGOUT_USER.request,
      SIGNUP_USER.request,
      SYNC_PERMISSIONS.request
    )]: onRequest,
    [combineActions(
      LOGIN_USER.success,
      LOGIN_WITH_SOCIAL.success,
      SIGNUP_USER.success
    )]: (state, { response }) => {
      return onSuccess({
        ...state,
        token: response.access_token,
        userId: response.user_id,
        locale: response.locale,
        permissions: response.permissions,
        user: response.user,
        signupClubId: !response.signup_completed ? response.signup_club_id : null,
        signupTeamId: !response.signup_completed ? response.signup_team_id : null,
      })
    },
    [LOGOUT_USER.success]: () => {
      return onSuccess({
        ...initialState.auth
      })
    },
    [combineActions(UPDATE_USER.success,FETCH_USER.success)]: (state, { response }) => {
      return onSuccess({
        ...state,
        user: response.data,
      })
    },
    [SYNC_PERMISSIONS.success]: (state, { response: permissions }) => {
      return onSuccess({
        ...state,
        permissions
      })
    },
    [combineActions(
      LOGIN_USER.failure,
      LOGIN_WITH_SOCIAL.failure,
      LOGOUT_USER.failure,
      SIGNUP_USER.failure,
      UPDATE_USER.failure,
      FETCH_USER.failure,
      SYNC_PERMISSIONS.failure
    )]: onFailure,
  },
  initialState.auth
)
