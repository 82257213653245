import React from 'react'
import { Translate } from 'react-localize-redux'
import styled from 'styled-components'
import { SequencesList, SubLink } from 'containers'
import {
  Card,
  Box,
  Typography,
  CardHeader,
  CardContent,
  // Button,
} from '@material-ui/core'
import videoIcon from 'assets/images/video@1x.svg'
import { withRouter } from 'react-router-dom'
import PROP_TYPES from 'constants/propTypes'

const CustomCard = styled(Card)`
  margin-bottom: 1.5rem;
`
const ConnectedSequences = ({ sequences, players, match: params }) => {
  const { teamId, seasonId } = params.params

  return (
    <CustomCard>
      <CardHeader
        title={
          <Typography variant="h4" component="h4">
            {' '}
            <Translate id="matches.connected-sequences" />
          </Typography>
        }
        // action={
        //   <Button variant="contained" size="small" color="primary">
        //     <Translate id="button.create-sequences" />
        //   </Button>
        // }
      />
      <CardContent>
        {sequences.length > 0 ? (
          <SequencesList sequences={sequences} players={players} />
        ) : (
          <SubLink to={`/team/${teamId}/season/${seasonId}/videos`} isFullPath>
            <Box width="100%" mr={1} textAlign="center" py={4} px={2}>
              <img src={videoIcon} alt={videoIcon} />
              <Typography variant="p" component="p">
                <Translate id="matches.no-sequences-text" />
              </Typography>
            </Box>
          </SubLink>
        )}
      </CardContent>
    </CustomCard>
  )
}

ConnectedSequences.defaultProps = {
  sequences: [],
  players: [],
}

ConnectedSequences.propTypes = {
  sequences: PROP_TYPES.arrayOfObjects,
  players: PROP_TYPES.arrayOfObjects,
  match: PROP_TYPES.shape().isRequired,
}

export default withRouter(ConnectedSequences)
