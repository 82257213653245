import { styled } from '@material-ui/core/styles'
import {
  Avatar as MuiAvatar,
  Box as MuiBox,
  TextField as MuiTextField,
  Chip as MuiChip,
  Table as MuiTable,
  TableContainer as MuiTableContainer,
  TableHead as MuiTableHead,
  TableBody as MuiTableBody,
} from '@material-ui/core'

export const Avatar = styled(MuiAvatar)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  marginRight: theme.spacing(1),
}))

export const Player = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  margin: theme.spacing(0),
}))
export const TextField = styled(MuiTextField)(({ theme }) => ({
  margin: theme.spacing(0),
}))

export const Chip = styled(MuiChip)(({ theme }) => ({
  margin: theme.spacing(0),
  width: 120,
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}))
export const TableContainer = styled(MuiTableContainer)(({ theme }) => ({
  maxHeight: 'calc(100vh - 235px)',
  [theme.breakpoints.down('sm')]: {
    maxHeight: 'calc(100vh - 215px)',
  },
}))
export const Table = styled(MuiTable)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    minWidth: 200,
  },
}))

export const TableHead = styled(MuiTableHead)(({ theme }) => ({
  '& .MuiTableCell-stickyHeader': {
    backgroundColor:'transparent !important'
  },
  [theme.breakpoints.down('sm')]: {
    '& .MuiTableRow-root': {
      display: 'none',
    },
  },
}))
export const TableBody = styled(MuiTableBody)(({ theme }) => ({
  '& .MuiChip-colorSecondary': {
    backgroundColor: theme.status.statuscolor_3,
  },
  [theme.breakpoints.down('sm')]: {
    '& .MuiTableRow-root': {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      margin: theme.spacing(3),
      border: '1px solid rgba(224, 224, 224, 1)',
      '& .MuiTableCell-root': {
        width: '100%',
      },
    },
  },
}))
