import { handleActions, combineActions } from 'redux-actions'
import {
  actionsTypesGenerator,
  onRequest,
  onSuccess,
  onFailure,
} from 'utils/reduxHelpers'
import initialState from '../initialState'

const namespace = 'DRILL'

const FETCH_DRILLS = actionsTypesGenerator(`FETCH_${namespace}S`)
const FETCH_DRILLS_BY_CATEGORY = actionsTypesGenerator(
  `FETCH_${namespace}S_BY_CATEGORY`
)
const FETCH_DRILL = actionsTypesGenerator(`FETCH_${namespace}`)
const FETCH_DEFAULT_CLUB_DRILL = actionsTypesGenerator(
  `FETCH_DEFAULT_CLUB_${namespace}`
)
const CREATE_DRILL = actionsTypesGenerator(`CREATE_${namespace}`)
const CREATE_DUPLICATE_DRILL = actionsTypesGenerator(
  `CREATE_DUPLICATE_${namespace}`
)
const UPDATE_DRILL = actionsTypesGenerator(`UPDATE_${namespace}`)
const SHARE_DRILL = actionsTypesGenerator(`SHARE_${namespace}`)
const DELETE_DRILL = actionsTypesGenerator(`DELETE_${namespace}`)
const FETCH_DRILLS_CATEGORIES = actionsTypesGenerator(
  `FETCH_${namespace}S_CATEGORIES`
)
const CREATE_DRILLS_CATEGORY = actionsTypesGenerator(
  `CREATE_${namespace}S_CATEGORY`
)
const UPDATE_DRILLS_CATEGORY = actionsTypesGenerator(
  `UPDATE_${namespace}S_CATEGORY`
)
const DELETE_DRILLS_CATEGORY = actionsTypesGenerator(
  `DELETE_${namespace}S_CATEGORY`
)

export const fetchDrills = params => ({
  types: FETCH_DRILLS,
  callAPI: {
    method: 'GET',
    entity: 'Drills',
    path: '/drills',
    params,
  },
})

export const defaultClubDrills = (params = {}) => ({
  types: FETCH_DEFAULT_CLUB_DRILL,
  callAPI: {
    method: 'GET',
    entity: 'Drills',
    path: '/default_club_drills',
    params,
  },
})

export const fetchDrillsByCategory = (category, params) => ({
  types: FETCH_DRILLS_BY_CATEGORY,
  callAPI: {
    method: 'GET',
    entity: 'Drills by categories',
    path: '/drills',
    params,
  },
  payload: { category },
})

export const fetchDrill = (drillId, params) => {
  // eslint-disable-next-line no-param-reassign
  params.fields = 'drill_categories'

  return {
    types: FETCH_DRILL,
    callAPI: {
      method: 'GET',
      entity: 'Drill',
      path: `/drills/${drillId}`,
      params,
    },
  }
}

export const createDrill = data => ({
  types: CREATE_DRILL,
  callAPI: {
    method: 'POST',
    entity: 'Drill',
    path: '/drills',
    data,
  },
})

export const createDuplicateDrill = (id, data = {}) => ({
  types: CREATE_DUPLICATE_DRILL,
  callAPI: {
    method: 'POST',
    entity: 'Drill',
    path: `/duplicate/drill/${id}`,
    data,
  },
})

export const updateDrill = (id, data) => ({
  types: UPDATE_DRILL,
  callAPI: {
    method: 'PUT',
    entity: 'Drill',
    path: `/drills/${id}`,
    data,
  },
})

export const shareDrill = (id, paramsString) => ({
  types: SHARE_DRILL,
  callAPI: {
    method: 'SHARE',
    entity: 'Drill',
    path: `/drills/${id}/share`,
    paramsString,
  },
})

export const deleteDrill = id => ({
  types: DELETE_DRILL,
  callAPI: {
    method: 'DELETE',
    entity: 'Drill',
    path: `/drills/${id}`,
  },
  payload: { id },
})

export const fetchDrillsCategories = params => ({
  types: FETCH_DRILLS_CATEGORIES,
  callAPI: {
    method: 'GET',
    entity: 'Drills categories',
    path: '/drill_categories',
    params,
  },
})

export const createDrillsCategory = data => ({
  types: CREATE_DRILLS_CATEGORY,
  callAPI: {
    method: 'POST',
    entity: 'Drills category',
    path: '/drill_categories',
    data,
  },
})

export const updateDrillsCategory = (id, data) => ({
  types: UPDATE_DRILLS_CATEGORY,
  callAPI: {
    method: 'PUT',
    entity: 'Drills category',
    path: `/drill_categories/${id}`,
    data,
  },
  payload: { id },
})

export const deleteDrillsCategory = id => ({
  types: DELETE_DRILLS_CATEGORY,
  callAPI: {
    method: 'DELETE',
    entity: 'Drills category',
    path: `/drill_categories/${id}`,
  },
  payload: { id },
})

export default handleActions(
  {
    [combineActions(
      FETCH_DRILLS.request,
      FETCH_DRILLS_BY_CATEGORY.request,
      FETCH_DEFAULT_CLUB_DRILL.request,
      FETCH_DRILL.request,
      CREATE_DRILL.request,
      CREATE_DUPLICATE_DRILL.request,
      UPDATE_DRILL.request,
      DELETE_DRILL.request,
      FETCH_DRILLS_CATEGORIES.request,
      CREATE_DRILLS_CATEGORY.request,
      UPDATE_DRILLS_CATEGORY.request,
      DELETE_DRILLS_CATEGORY.request
    )]: onRequest,
    [FETCH_DRILLS.success]: (state, { response: { data: items = [] } }) =>
      onSuccess({ ...state, items }),
    [FETCH_DRILLS_BY_CATEGORY.success]: (
      state,
      { response: { data: items = [] }, category }
    ) =>
      onSuccess({
        ...state,
        itemsByCategory: { ...state.itemsByCategory, [category]: items },
      }),
    [combineActions(FETCH_DRILL.success, UPDATE_DRILL.success)]: (
      state,
      { response: { data = {} } }
    ) => onSuccess({ ...state, current: { ...state.current, ...data } }),
    [CREATE_DRILL.success]: (state, { response: { data = {} } }) =>
      onSuccess({
        ...state,
        items: [data, ...state.items],
      }),
    [DELETE_DRILL.success]: (state, { id }) =>
      onSuccess({ ...state, items: state.items.filterById(id) }),
    [FETCH_DRILLS_CATEGORIES.success]: (
      state,
      { response: { data: drillsCategories = [] } }
    ) =>
      onSuccess({
        ...state,
        current: { ...state.current, drillsCategories },
      }),
    [CREATE_DRILLS_CATEGORY.success]: (
      state,
      { response: { data: drillsCategory = {} } }
    ) =>
      onSuccess({
        ...state,
        current: {
          ...state.current,
          drillsCategories: [...state.current.drillsCategories, drillsCategory],
        },
      }),
    [UPDATE_DRILLS_CATEGORY.success]: (
      state,
      { response: { data: drillsCategory = {} }, id }
    ) =>
      onSuccess({
        ...state,
        current: {
          ...state.current,
          drillsCategories: state.current.drillsCategories.updateById(
            +id,
            drillsCategory
          ),
        },
      }),
    [DELETE_DRILLS_CATEGORY.success]: (state, { id }) =>
      onSuccess({
        ...state,
        current: {
          ...state.current,
          drillsCategories: state.current.drillsCategories.filterById(+id),
        },
      }),
    [combineActions(
      FETCH_DRILLS.failure,
      FETCH_DEFAULT_CLUB_DRILL.failure,
      FETCH_DRILLS_BY_CATEGORY.failure,
      FETCH_DRILL.failure,
      CREATE_DRILL.failure,
      CREATE_DUPLICATE_DRILL.failure,
      UPDATE_DRILL.failure,
      DELETE_DRILL.failure,
      FETCH_DRILLS_CATEGORIES.failure,
      CREATE_DRILLS_CATEGORY.failure,
      UPDATE_DRILLS_CATEGORY.failure,
      DELETE_DRILLS_CATEGORY.failure
    )]: onFailure,
  },
  initialState.drills
)
