import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import styled from 'styled-components'
import ActiveViewSelector from './ActiveViewSelector'

const PrevNextButton = styled.span`
  padding: 0 10px 0;
  cursor: pointer;

  visibility: ${({ isShown }) => (isShown ? 'visible' : 'hidden')};
`

export default (resetPopover, currentSeason, changeActiveView) => {
  const Toolbar = ({ className, label, onNavigate, date }) => {
    const navigatePrev = () => {
      resetPopover()
      onNavigate('PREV')
    }

    const navigateNext = () => {
      resetPopover()
      onNavigate('NEXT')
    }

    const isPrevShown = useMemo(
      () =>
        moment(date).format('YYYYMM') >
        moment(currentSeason.start_date).format('YYYYMM'),
      [date, currentSeason.start_date]
    )

    const isNextShown = useMemo(
      () =>
        moment(date).format('YYYYMM') <
        moment(currentSeason.end_date).format('YYYYMM'),
      [date, currentSeason.end_date]
    )

    return (
      <div className={className}>
        <PrevNextButton isShown={isPrevShown} onClick={navigatePrev}>
          &#10094;
        </PrevNextButton>
        <div>{label}</div>
        <PrevNextButton isShown={isNextShown} onClick={navigateNext}>
          &#10095;
        </PrevNextButton>
        <ActiveViewSelector
          activeView="month"
          changeActiveView={changeActiveView}
        />
      </div>
    )
  }

  Toolbar.propTypes = {
    className: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onNavigate: PropTypes.func.isRequired,
    date: PropTypes.shape().isRequired,
  }

  return styled(Toolbar)`
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    position: relative;
  `
}
