import React, { useContext, useMemo } from 'react'
import { Translate } from 'react-localize-redux'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { DragLayer, DropTarget } from 'react-dnd'
import { compose } from 'redux'
import Grid from '@material-ui/core/Grid'
import { Tabs, Tab, Card, CardContent, Box, Divider } from '@material-ui/core'
import hasSubscription from 'utils/hasSubscription'
import { PolarPlayers } from 'components'
import { Context } from '../MatchStateContext'
import Field from './Field'
import Lineups from './Lineups'
import Roster from './Roster'
import NotInRoster from './NotInRoster'
import NotResponded from './NotResponded'
import ConnectedSequences from './ConnectedSequences'
import MatchAllNotes from './MatchAllNotes'
import MatchHighlights from './highlights'
import PROP_TYPES from 'constants/propTypes'

const useStyles = makeStyles(theme => ({
  addActivity: {
    position: 'absolute',
    top: '10px',
    right: '15px',
  },
  lineupSelect: {
    width: '300px',
  },
  [theme.breakpoints.down('sm')]: {},
}))

const LeftPart = ({
  onDrop,
  connectDropTarget,
  players,
  lineups,
  sequences,
  handleSave,
  setLoading,
  polarTeam,
  selectedPolarEevent,
  polarTrainning,
  setPolarToken,
  polarToken,
  matchId
}) => {
  const { matchState, onFieldChange } = useContext(Context)

  const preparedMatchPlayers = matchState.matchPlayers

  const inRoster = useMemo(() => preparedMatchPlayers.filter(
    matchPlayer => (matchPlayer.in_roster==='AVAILABLE' && !matchPlayer.participant)
    ),
    [preparedMatchPlayers, matchState.match_players]
  )

  const notInRoster = useMemo(() => preparedMatchPlayers.filter(
    matchPlayer => matchPlayer.in_roster==='NOT_AVAILABLE'
    ),
    [preparedMatchPlayers, matchState.match_players]
  )

  const notResponded = useMemo(() => preparedMatchPlayers.filter(
    matchPlayer => matchPlayer.in_roster==='NOT_RESPONDED'
    ),
    [preparedMatchPlayers, matchState.match_players]
  )

  const fromPathname = 'Match'
  const availablePlayersCount = inRoster.length > 0;

  /* eslint-disable */
  const onPlayerChange = playerId => fields => {
    fields.in_roster = []
    if (fields.tag === 'available') {
      fields.in_roster = 'AVAILABLE'
    } else {
      fields.in_roster = 'NOT_AVAILABLE'
    }

    if (fields.tag) {
      matchState.matchPlayers.filter((player) => {
        if (player.uuid === playerId) {
          player.tag = fields.tag
        }
      })
    }
    onFieldChange('matchPlayers', matchState.matchPlayers.updateByUuid(playerId, fields))
  }

  const [activeTabName, setActiveTabName] = React.useState("lineup")
  const handleTabChange = (event, newValue) => {
    setActiveTabName(newValue)
  }
  const classes = useStyles()

  return (
    <>
      <Card component={Box} mb={3}>
        <Translate>
          {({ translate }) => (
            <>
              <Tabs
                value={activeTabName}
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons="auto"
                indicatorColor="primary"
              >
                <Tab label={translate('matches.team-line-up')} value="lineup"/>
              </Tabs>
              <Box className={classes.addActivity} />
              <Divider />
            </>
          )}
        </Translate>
        <CardContent>
          {activeTabName === "lineup" && (
            <div>
              {' '}
              <Grid container spacing={2}>
                <Grid item md={6} xs={12} sm={12} lg={6}>
                  <Lineups lineups={lineups} availablePlayersCount={availablePlayersCount} />
                  <Field onDrop={onDrop} handleSave={handleSave} />
                </Grid>
                <Grid item md={6} xs={12} sm={12} lg={6}>
                  {connectDropTarget(
                    <div>
                      <Roster
                        players={inRoster}
                        fromPathname={fromPathname}
                        onPlayerChange={onPlayerChange}
                        handleSave={handleSave}
                        matchStatPlayers={preparedMatchPlayers}
                        availablePlayersCount={availablePlayersCount}
                      />
                    </div>
                  )}
                  <NotInRoster
                    players={notInRoster}
                    onDrop={onDrop}
                    fromPathname={fromPathname}
                    onPlayerChange={onPlayerChange}
                    handleSave={handleSave}
                    matchStatPlayers={preparedMatchPlayers}
                  />
                  <NotResponded
                    players={notResponded}
                    onDrop={onDrop}
                    fromPathname={fromPathname}
                    onPlayerChange={onPlayerChange}
                    handleSave={handleSave}
                    matchStatPlayers={preparedMatchPlayers}
                  />
                </Grid>
              </Grid>
            </div>
          )}
        </CardContent>
      </Card>
      <MatchHighlights setLoading={setLoading}/>
      <MatchAllNotes />
      {hasSubscription() === 'elite' && 
      <ConnectedSequences sequences={sequences} players={players} />
      }

      {polarTeam && selectedPolarEevent &&
      <PolarPlayers 
      polarTeam={polarTeam}
      selectedPolarEevent={selectedPolarEevent}
      polarTrainning={polarTrainning}
      setPolarToken={setPolarToken}
      polarToken={polarToken}
      matchId={matchId}
      />}
    </>
  )
}

LeftPart.defaultProps = {
  players: [],
  lineups: [],
  sequences: [],
  handleSave: null,
  polarTeam: null,
  selectedPolarEevent: null,
  polarTrainning: null,
}

LeftPart.propTypes = {
  onDrop: PropTypes.func.isRequired,
  connectDropTarget: PROP_TYPES.func.isRequired,
  players: PROP_TYPES.arrayOfObjects,
  lineups: PROP_TYPES.arrayOfObjects,
  sequences: PROP_TYPES.arrayOfObjects,
  handleSave: PROP_TYPES.func,
  setLoading: PROP_TYPES.bool.isRequired,
  polarTeam: PROP_TYPES.arrayOfObjects,
  selectedPolarEevent: PROP_TYPES.number,
  polarTrainning: PROP_TYPES.arrayOfObjects,
  setPolarToken: PROP_TYPES.func.isRequired,
  polarToken: PROP_TYPES.arrayOfObjects.isRequired,
  matchId: PROP_TYPES.number.isRequired,
}

const fieldTarget = {
  drop({ item: { playerId }, onDrop }) {
    onDrop(playerId)
  },
}

export default compose(
  DragLayer(monitor => ({
    item: monitor.getItem(),
  })),
  DropTarget('player', fieldTarget, targetConnect => ({
    connectDropTarget: targetConnect.dropTarget(),
  })),
  connect(({ matches: { current: { players, lineups, sequences } } }) => ({
    players,
    lineups,
    sequences,
  }))
)(LeftPart)
