import React from 'react'
import { MatchLoader } from 'loaders'
import MatchStateContext from './MatchStateContext'
import MatchDashboardContainer from './MatchDashboardContainer'
import PROP_TYPES from 'constants/propTypes'

const MatchDashboard = ({
  match: {
    params: { matchId },
  },
}) => (
  <MatchLoader
    matchId={matchId}
    additionalFields={[
      'players',
      'sequences',
      'lineups',
      'matches',
      'goals',
      'team',
    ]}
  >
    <MatchStateContext>
      <MatchDashboardContainer matchId={matchId} />
    </MatchStateContext>
  </MatchLoader>
)

MatchDashboard.propTypes = {
  match: PROP_TYPES.match.isRequired,
}

export default MatchDashboard
