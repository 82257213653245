import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import PROP_TYPES from 'constants/propTypes'
import SEQUENCE_TAGS from 'constants/sequenceTags'

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  align-self: center;

  position: relative;

  margin: 2px 2px 0;
  height: 15px;
  border-radius: 15px;
  background: var(--dark-grey);

  cursor: pointer;
`

const Seeked = styled.div.attrs(({ seeked }) => ({
  style: {
    width: `${seeked * 100}%`,
  },
}))`
  position: absolute;

  height: inherit;
  border-radius: inherit;
  background: var(--light-grey);
`

const Played = styled.div`
  position: absolute;

  width: ${({ played }) => played * 100}%;
  height: inherit;
  border-radius: inherit;
  background: green;
  opacity: 0.5;

  &:hover {
    z-index: 10;
  }
`

const Sequences = styled.div`
  position: absolute;

  width: 100%;
  height: inherit;
  border-radius: inherit;
  opacity: 0.5;
`

const SequenceRange = styled.div`
  position: absolute;

  left: ${({ left }) => left}%;
  width: ${({ width }) => width}%;
  height: inherit;
  border-radius: inherit;
  background: red;
`

const SequenceRangeBorder = styled.div`
  position: absolute;

  left: ${({ left }) => left}%;
  width: ${({ width }) => width}%;
  height: inherit;
  border-radius: inherit;
  border: ${({ color }) => color} solid 1px;
  background-color: ${({ color }) => color};

  &:hover {
    z-index: 10;
  }
`

const SequenceRangeLabel = styled.div`
  :before {
    content: '';
    display: block;
    width: 25px;
    height: 25px;
  }

  position: absolute;
  font-size: 200%;
  width: 25px;
  margin-left: -15px;
  text-align: center;
  margin-top: -30px;
  border-radius: 50% 50% 0;
  transform: rotate(45deg);

  background-color: ${({ color }) => color};
  color: ${({ color }) => color};
  left: ${({ left }) => left}%;
`

const ProgressRange = ({
  children,
  played,
  duration,
  onSeekChange,
  sequences,
  isNewSequence,
  id,
  changeProgressRangePosition,
}) => {
  const [position, changePosition] = useState({})
  useEffect(() => {
    const { left, width } = document.getElementById(id).getBoundingClientRect()

    changePosition({ left, width })
    changeProgressRangePosition({ left, width })
  }, [document.getElementById(id) && document.getElementById(id).offsetWidth])

  const [seeked, changeSeeked] = useState(0)

  const onMouseMove = e => {
    e.persist()

    if (isNewSequence) {
      return
    }

    setTimeout(() => {
      changeSeeked((e.pageX - position.left) / position.width)
    }, 50)
  }

  const resetSeeked = () => {
    setTimeout(() => {
      changeSeeked(0)
    }, 50)
  }

  return (
    <Container
      id={id}
      onClick={e => {
        onSeekChange((e.pageX - position.left) / position.width)
      }}
      onMouseMove={onMouseMove}
      onMouseOut={resetSeeked}
      onBlur={resetSeeked}
      onDrop={e => e.preventDefault()}
      onDragOver={e => {
        e.preventDefault()

        e.dataTransfer.dropEffect = 'move'
      }}
    >
      <Seeked seeked={seeked} />
      <Played played={played} />
      <Sequences>
        {sequences.map(({ id: sequenceId, from, to }) => (
          <SequenceRange
            key={sequenceId}
            left={(from / duration) * 100}
            width={((to - from) / duration) * 100}
          />
        ))}
      </Sequences>
      {sequences.map(({ id: sequenceId, from, to, tag }) => {
        const tagData =
          SEQUENCE_TAGS.find(({ value }) => value === tag) ||
          SEQUENCE_TAGS.find(({ value }) => value === 'others')

        return (
          <SequenceRangeBorder
            key={sequenceId}
            left={(from / duration) * 100}
            width={((to - from) / duration) * 100}
            color={tagData.background}
          />
        )
      })}
      {sequences.map(({ id: sequenceId, from, to, tag }) => {
        const tagData =
          SEQUENCE_TAGS.find(({ value }) => value === tag) ||
          SEQUENCE_TAGS.find(({ value }) => value === 'others')

        return (
          <SequenceRangeLabel
            key={sequenceId}
            left={((from + (to - from) / 2) / duration) * 100}
            color={tagData.background}
          />
        )
      })}
      {children}
    </Container>
  )
}

ProgressRange.defaultProps = {
  duration: 1,
  sequences: [],
  isNewSequence: false,
}

ProgressRange.propTypes = {
  children: PROP_TYPES.children.isRequired,
  played: PropTypes.number.isRequired,
  duration: PropTypes.number,
  onSeekChange: PropTypes.func.isRequired,
  sequences: PROP_TYPES.arrayOfObjects,
  isNewSequence: PropTypes.bool,
  id: PropTypes.string.isRequired,
  changeProgressRangePosition: PropTypes.func.isRequired,
}

export default ProgressRange
