import React from 'react'
import { connect } from 'react-redux'
import { Table } from 'react-bootstrap'
import { SubLink } from 'containers'
import { Card, Button, ActionWithPrompt } from 'components'
import { lineups as lineupsModule } from 'redux/modules'
import PROP_TYPES from 'constants/propTypes'
import { LINEUP_CREATE_ROUTE, LINEUP_EDIT_ROUTE } from 'constants/routes'

/**
 * @todo: #roles check permissions or move functionality
 *
 * Currently lineups are global, not assigned to clubs or teams. Can be managed only by "old root" users.
 * Lineups management disabled as temporary solution.
 */
const LineupsDashboardContainer = ({ lineups, deleteLineup }) => {

  const canCreate = false
  const canUpdate = false
  const canDelete = false

  return (
    <Card>
      <Card.Title className="d-flex justify-content-between">
        <span>Lineups</span>
        {canCreate && (
          <SubLink to={LINEUP_CREATE_ROUTE}>
            <Button secondary>Create new</Button>
          </SubLink>
        )}
      </Card.Title>
      <Table>
        <tbody>
        {lineups.map(lineup => (
          <tr key={lineup.id}>
            <td>{lineup.title}</td>
            <td>
              <div className="d-flex justify-content-end">
                {canUpdate && (
                  <SubLink
                    to={LINEUP_EDIT_ROUTE.populateURLParams({
                      lineupId: lineup.id,
                    })}
                  >
                    <Button>Edit</Button>
                  </SubLink>
                )}
                {canDelete && (
                  <ActionWithPrompt
                    className="text-danger border-danger"
                    onSuccess={() => deleteLineup(lineup.id)}
                    message="Are you sure you want to delete the Lineup?"
                    title="Delete"
                  />
                )}
              </div>
            </td>
          </tr>
        ))}
        </tbody>
      </Table>
    </Card>
  )
}

LineupsDashboardContainer.defaultProps = {
  lineups: [],
}

LineupsDashboardContainer.propTypes = {
  lineups: PROP_TYPES.arrayOfObjects,
  deleteLineup: PROP_TYPES.func.isRequired,
}

export default connect(
  ({ lineups }) => ({
    lineups: lineups.items,
  }),
  {
    deleteLineup: lineupsModule.deleteLineup,
  }
)(LineupsDashboardContainer)
