import React, { useState, useEffect } from 'react'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import SearchInput, { createFilter } from 'react-search-input'
import styled from 'styled-components'

const Container = styled.div`
  position: relative;

  input {
    padding: 0 5px;
    background: transparent;
    color: #888;

    border: #888 solid 1px;
    border-radius: 12px;
    height: 24px;
    margin: 0 5px;

    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    width: 95%;
    height: calc(2.25rem + 2px);

    ::placeholder {
      font-size: 80%;
    }
    .packageEditSearch & {
      margin-bottom: 10px;
    }
  }
`

const Suggestions = styled.div`
  position: absolute;
  bottom: -5px;
  width: 100%;
  max-height: 220px;
  border-radius: 4px;
  border: 1px solid #bbc1d0;
  overflow-y: auto;
  z-index: 10;
  background: white;
  transform: translate(0, 100%);

  div {
    padding: 15px;
    cursor: pointer;
  }

  div:hover {
    background: #eee;
  }
`

const SearchInputContainer = ({
  value: initialValue,
  suggestions,
  placeholder,
  onChange,
  isNew,
  isEveryOne,
  fetchDrill,
}) => {
  const [searchTerm, changeSearchTerm] = useState('')
  const [isShown, changeIsShown] = useState(false)

  useEffect(() => {
    const suggestion = suggestions.find(({ value }) => value === initialValue)

    if (suggestion) {
      changeSearchTerm(suggestion.title)
    }

    if (+initialValue === -1) {
      changeSearchTerm('@everyone tag everyone')
    }
  }, [initialValue])

  const updateSearchTerm = newSearchTerm => {
    changeSearchTerm(newSearchTerm)

    if (searchTerm !== newSearchTerm) {
      changeIsShown(Boolean(newSearchTerm))
    }

    if (!newSearchTerm) {
      onChange(null)
    }
  }

  const filteredSuggestions = suggestions
    .filter(createFilter(searchTerm, 'title'))
    .slice(0, 11)

  const onSelect = (newValue, title) => {
    onChange(newValue)

    changeIsShown(false)

    changeSearchTerm(isNew ? '' : title)
    fetchDrill(newValue, { added: true, track: 'training_package' })
  }

  return (
    <Container>
      <SearchInput
        value={searchTerm}
        onChange={updateSearchTerm}
        placeholder={placeholder}
      />
      {isShown && (filteredSuggestions.length > 0 || isEveryOne) && (
        <Suggestions>
          {isEveryOne && (
            <div
              onClick={() => {
                return onSelect(-1, <Translate id="videos.tag-everyone" />)
              }}
              role="presentation"
            >
              <Translate id="videos.tag-everyone" />
            </div>
          )}
          {filteredSuggestions.map(({ value, title }) => (
            <div
              key={value}
              onClick={() => onSelect(value, title)}
              role="presentation"
            >
              {title}
            </div>
          ))}
        </Suggestions>
      )}
    </Container>
  )
}

SearchInputContainer.defaultProps = {
  value: null,
  suggestions: [],
  placeholder: '',
  isNew: false,
  isEveryOne: false,
  fetchDrill: () => 0,
}

SearchInputContainer.propTypes = {
  value: PropTypes.number,
  suggestions: PropTypes.arrayOf(PropTypes.object.isRequired),
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  fetchDrill: PropTypes.func,
  isNew: PropTypes.bool,
  isEveryOne: PropTypes.bool,
}

export default SearchInputContainer
