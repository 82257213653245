import PlayerPlaceholder from 'assets/images/avatar.png'
import styled from 'styled-components'
import Card from '../Card'

export default styled(Card)`
  background: ${({ image }) =>
    `url(${image || PlayerPlaceholder}) no-repeat center`};
  background-size: cover;

  &.head-avatar {
    background: ${({ image }) =>
      `url(${image || PlayerPlaceholder}) no-repeat center`};
    background-size: cover;
  }

  &.circle {
    ${({ marginTop }) =>
      `margin-top: ${marginTop ? `${marginTop}px` : 'auto'}`};
    ${({ marginBottom }) =>
      `margin-bottom: ${marginBottom ? `${marginBottom}px` : 'auto'}`};
    ${({ width, height }) => {
      const dim = Math.min(width || height, height || width)

      return `width: ${dim}px; height: ${dim}px; border-radius: ${dim / 2}px`
    }};
  }
`
