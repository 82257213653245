import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Dropdown } from 'components'
import { articles as articlesModule } from 'redux/modules'
import { Paper, Box, Grid, Toolbar } from '@material-ui/core'
import { ArticleItem, FilterByCategories } from './components'
import orderByOptions from './orderByOptions'
import PROP_TYPES from 'constants/propTypes'

const ArticlesNewDashboardContainer = ({
  activeLocale,
  favoritesArticles,
  fetchArticles,
}) => {
  const [articles, changeArticles] = useState([])
  const [orderBy, changeOrderBy] = useState('title')
  const [activeCategories, changeActiveCategories] = useState([])

  useEffect(() => {
    fetchArticles({
      order_by: orderBy,
      limit: 30,
      categories: activeCategories
        .map(({ id }) => id)
        .filter(
          id => !activeCategories.find(category => category.parent_id === id)
        ),
      locale: activeLocale,
    }).then(({ response: { data = [] } }) => changeArticles(data))
  }, [orderBy, activeCategories, activeLocale])

  const getIsFavorite = id => Boolean(favoritesArticles.findById(id))

  return (
    <>
      <Paper component={Box} px={3} pb={3} mt={3}>
        <Toolbar display="flex">
          <FilterByCategories
            activeCategories={activeCategories}
            onChangeActiveCategories={changeActiveCategories}
          />

          <Dropdown
            options={orderByOptions}
            value={orderBy}
            title="Sort by"
            onSelect={changeOrderBy}
            alwaysShowTitle
          />
        </Toolbar>
        <Grid container spacing={3}>
          {articles.map(article => (
            <ArticleItem
              key={article.id}
              article={article}
              isFavorite={getIsFavorite(article.id)}
            />
          ))}
        </Grid>
      </Paper>
    </>
  )
}

ArticlesNewDashboardContainer.defaultProps = {
  favoritesArticles: [],
}

ArticlesNewDashboardContainer.propTypes = {
  activeLocale: PROP_TYPES.string.isRequired,
  favoritesArticles: PROP_TYPES.arrayOfObjects,
  fetchArticles: PROP_TYPES.func.isRequired,
}

export default connect(
  ({ favorites }) => ({ favoritesArticles: favorites.items.articles }),
  { fetchArticles: articlesModule.fetchArticles }
)(ArticlesNewDashboardContainer)
