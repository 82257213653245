import React from 'react'
import TeamsContainer from './TeamsContainer'
import TeamsLayout from './TeamsLayout'

const Teams = () => (
 
    <TeamsLayout />
)

export default Teams
