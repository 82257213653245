import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useLocation, withRouter } from 'react-router-dom'
import { thirdParty, auth as authModule } from 'redux/modules'
import urlConstructor from 'utils/urlConstructor'
import { getPolarToken, setPolarCode, setPolarErrorCode, setPolarToken } from 'utils/localstorage'
import { compose } from 'redux'
import { TEAMS_ROUTE } from '../../constants/routes'
import PERMISSIONS from '../../constants/permissions'
import PROP_TYPES from 'constants/propTypes'

// @todo: #polar move handler to dedicated route and component
const FirstEntryRouterContainer = ({
  history,
  teams,
  seasons,
  polarGenrateToken,
  fetchLoginUserDetails,
  authUser,
  permissions
}) => {

  const query = useLocation().search
  const code = (new URLSearchParams(query).get('code')||"")
  const error = (new URLSearchParams(query).get('error')||"")

  const firstTeam = teams.items.find(Boolean)
  const firstSeason = seasons.items[firstTeam.id].find(Boolean)

  const d = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate()
  )

  const genratePolarToken=()=>{

    const {id:polarId}={...getPolarToken()}

    const callApi=polarId?polarGenrateToken(code,{polarId}):polarGenrateToken(code)

    callApi.then(res=>res.response.data)
    .then(json=>{
      if(json.id){
        setPolarToken(json)
      }
      else{
        console.error(json);
      }
    })
  }

  const firstActiveSeason = seasons.items[firstTeam.id].filter(
    season => d >= new Date(season.start_date) && d <= new Date(season.end_date)
  ).find(Boolean)

  if (!firstActiveSeason && !firstSeason) {
    return <div />
  }

  React.useEffect(()=>{
    if(code && !error)
    {
      setPolarCode(code)
      genratePolarToken()
    }
    if(!code && error)
    {
      setPolarErrorCode(error)
    }

    if(!authUser || !authUser.first_name || !authUser.time_zone || !authUser.datetime_format){
      fetchLoginUserDetails()
    }

    if (teams.items.length > 1) {
      history.push(TEAMS_ROUTE)
    } else {
      const firstTeamPermissions = permissions.teams.find(item => item.id === firstTeam.id)?.permissions ?? []
      const canManageFirstTeam = firstTeamPermissions.includes(PERMISSIONS.UPDATE_TEAM)

      // Redirect to first team dashboard if the user has enough permissions,
      // otherwise redirect to teams selector screen.
      if (canManageFirstTeam) {
        history.push(urlConstructor(firstTeam.id, firstActiveSeason ? firstActiveSeason.id : firstSeason.id))
      } else {
        history.push(TEAMS_ROUTE)
      }
    }
  },[])

  return null
}

FirstEntryRouterContainer.propTypes = {
  history: PROP_TYPES.history.isRequired,
  teams: PropTypes.shape().isRequired,
  seasons: PropTypes.shape().isRequired,
  polarGenrateToken: PropTypes.func.isRequired,
  fetchLoginUserDetails: PropTypes.func.isRequired,
  authUser: PropTypes.shape().isRequired,
  permissions: PropTypes.array.isRequired
}

export default compose( 
  withRouter,
  connect(({ teams, seasons, auth }) => ({
    teams,
    seasons,
    authUser: auth.user,
    permissions: auth.permissions
  }),{
  polarGenrateToken:thirdParty.polarGenrateToken,
  fetchLoginUserDetails:authModule.fetchUser
})
)(FirstEntryRouterContainer)
