/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-plusplus */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-console */
/* eslint-disable no-multi-assign */
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import moment from 'moment'
import axios from 'axios'
import { getUserId } from 'utils/localstorage'
import { identify } from 'utils/tracking'
import 'moment/locale/en-gb'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import * as serviceWorker from './serviceWorker'
import App from './App'
import './utils'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'react-toastify/dist/ReactToastify.css'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { store, persistor } from './redux/store'

moment.locale('en-gb')

window.REACT_APP_API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT
window.REACT_APP_STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
window.REACT_APP_UNTIL_DATE = process.env.REACT_APP_UNTIL_DATE
window.SEGMENT_KEY = process.env.REACT_APP_SEGMENT_KEY
window.REACT_APP_PAYPAL_CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_ID
window.REACT_APP_PAYPAL_SECRET = process.env.REACT_APP_PAYPAL_SECRET
window.REACT_APP_PAYPAL_API_LINK = process.env.REACT_APP_PAYPAL_API_LINK

window.REACT_APP_POLAR_CLIENT_ID = process.env.REACT_APP_POLAR_CLIENT_ID
window.REACT_APP_POLAR_CLIENT_SECRET = process.env.REACT_APP_POLAR_CLIENT_SECRET
window.REACT_APP_BASE_IMG_URL = process.env.REACT_APP_BASE_IMG_URL

axios.defaults.baseURL = window.REACT_APP_API_ENDPOINT
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'

axios.interceptors.request.use(
  reqConfig => {
    const reqConfiglocal = reqConfig
    const state = store.getState()

    if (state.auth.token) {
      reqConfiglocal.headers.Authorization = `Bearer ${state.auth.token}`
    }

    return reqConfiglocal
  },
  error => {
    Promise.reject(error)
  }
)

axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    return Promise.reject(error)
  }
)

// start SEGMENT tracking
const analytics = (window.analytics = window.analytics || [])
if (!analytics.initialize) {
  if (analytics.invoked) {
    window.console &&
      console.error &&
      console.error('Segment snippet included twice.')
  } else {
    analytics.invoked = !0
    analytics.methods = [
      'trackSubmit',
      'trackClick',
      'trackLink',
      'trackForm',
      'pageview',
      'identify',
      'reset',
      'group',
      'track',
      'ready',
      'alias',
      'debug',
      'page',
      'once',
      'off',
      'on',
      'addSourceMiddleware',
      'addIntegrationMiddleware',
      'setAnonymousId',
      'addDestinationMiddleware',
    ]
    analytics.factory = e => {
      return () => {
        const t = []
        t.unshift(e)
        analytics.push(t)

        return analytics
      }
    }
    for (let e = 0; e < analytics.methods.length; e++) {
      const key = analytics.methods[e]
      analytics[key] = analytics.factory(key)
    }
    analytics.load = (key, e) => {
      const t = document.createElement('script')
      t.type = 'text/javascript'
      t.async = !0
      t.src = `https://cdn.segment.com/analytics.js/v1/${key}/analytics.min.js`
      const n = document.getElementsByTagName('script')[0]
      n.parentNode.insertBefore(t, n)
      analytics._loadOptions = e
    }
    analytics.SNIPPET_VERSION = '4.13.1'
    analytics.load(window.SEGMENT_KEY)
    const userId = getUserId()
    /* 
    Todo:
    it is for user already login so no need to re-login for tracking, after few days or 1 month we can remove this
    */
    const ajsUid = localStorage.getItem('ajs_user_id')
    if (userId && ajsUid && +userId !== +ajsUid) {
      identify()
    }
    // analytics.page();
  }
}
// end SEGMENT tracking

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
