import React from 'react'
import styled from 'styled-components'
import SubLink from '../containers/SubLink'
import AddButton from './buttons/AddButton'
import PROP_TYPES from 'constants/propTypes'

const Container = styled.div`
  position: relative;
`

const NextCalendarEventWidget = ({ children }) => (
  <Container>{children}</Container>
)

NextCalendarEventWidget.propTypes = {
  children: PROP_TYPES.children.isRequired,
}

NextCalendarEventWidget.Title = styled.h3`
  margin-bottom: 30px;
  font-size: 20px;
  text-align: center;
`

NextCalendarEventWidget.Placeholder = styled.p`
  text-align: center;
  width: 100%;
  height: 120px;
`

NextCalendarEventWidget.Calendar = styled(SubLink)`
  > div {
    margin: 0 auto 10px;
    width: 115px;
    text-align: center;
    box-shadow: -1px 1px 5px 2px rgba(128, 128, 128, 0.5);
    background: transparent;
  }
`

NextCalendarEventWidget.CalendarTop = styled.div`
  border: 6px solid var(--primary-green);
  padding: 13px 15px;
`

NextCalendarEventWidget.CalendarBottom = styled.div`
  font-size: 15px;
  padding: 5px;
  line-height: 1.1;
  color: black;
`
// TODO: it used in other widgets on dashboard
NextCalendarEventWidget.CircleNumber = styled.div`
  font-size: 30px;
  border-bottom: 1px solid;
  border-color: ${props => props.color || 'black'};
  line-height: 1;
  text-shadow: 0 0 5px rgba(128, 128, 128, 0.7);
  color: ${props => props.color || 'black'};
`

NextCalendarEventWidget.CircleTitle = styled.div`
  font-size: 15px;
  line-height: 2;
  color: ${props => props.color || 'black'};
`

NextCalendarEventWidget.AddButton = styled(AddButton)`
  position: absolute;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  left: 85%;
  top: 85%;

  box-shadow: 2px 2px 7px 1px rgba(0, 0, 0, 0.5);
`

export default NextCalendarEventWidget
