import React, { useState, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { drills as drillsModule } from 'redux/modules'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import OwlCarousel from 'react-slick'
import { DrillsSnackbar, AddButton, TrainingPackageModal } from 'components'

import {
  CardContent,
  Card,
  CardHeader,
  Typography,
  Box,
  Button,
  Tooltip,
  CardMedia,
  Hidden,
  IconButton,
} from '@material-ui/core'
import playerIcon from 'assets/images/16px/player@1x.svg'
import timeIcon from 'assets/images/16px/time@1x.svg'
import IconLeft from 'assets/images/16px/chevron-left@1x.svg'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import IconRight from 'assets/images/16px/chevron-right@1x.svg'
import MuiAlert from '@material-ui/lab/Alert'
import { HeartIcon } from '../../../../containers'

import StyledTrainingPackage from './StyledTrainingPackage'
import PROP_TYPES from 'constants/propTypes'

const SESSION_CATEGORIES = ['warmup', 'main_part', 'final_part']

function Alert(props) {
  return <MuiAlert elevation={0} variant="filled" {...props} />
}
const useStyles = makeStyles(theme => ({
  addButton: {
    top: '-16px',
    left: '81%',
  },
  PlayerNum: {
    margin: '0 .8rem',
    display: 'flex',
    '& img': {
      marginRight: '.6rem',
    },
    '& :hover': {
      backgroundColor: 'transparent',
    },
  },
  CustomCardMedia: {
    height: '7.5rem',
    borderRadius: '0.5rem 0.5rem 0 0',
  },
  AddButton: {
    color: theme.palette.primary.main,
    fontSize: '1.8rem',
  },
  [theme.breakpoints.down('sm')]: {
    addPackage: {
      display: 'none',
    },
  },
}))

const CustomIconButton = styled.div``
const StyledPopover = styled(Popover)`
  padding: 24px;
  border-radius: 8px;
  border: 1px solid #ededed;
  box-shadow: 14px 5px 35px 0 rgba(0, 0, 0, 0.08);
  .bs-popover-auto[x-placement^='right'] .arrow::after,
  .bs-popover-right .arrow::after {
    background: #ededed;
  }
  p {
    color: #a6a6a6;
  }
`
const SlickSlider = styled(OwlCarousel)`
  .slick-prev,
  .slick-next {
    top: -17%;
    width: 30px;
    height: 30px;
  }
  .slick-prev {
    right: 42px;
    left: inherit;
  }
  .slick-next {
    right: 4px;
  }
  .slick-prev:before,
  .slick-next:before {
    content: '';
    width: 30px;
    border-radius: 8px;
    position: absolute;
    left: 0;
    height: 30px;
    background-size: 24px !important;
    background: #f3f3f3;
    top: 0;
  }
  .slick-prev:before {
    background: url(${IconLeft}) no-repeat center #ededed;
  }
  .slick-next:before {
    background: url(${IconRight}) no-repeat center #ededed;
  }
  @media (max-width: 640px) {
    margin-bottom: 2.1875rem;
    .slick-prev,
    .slick-next {
      top: inherit;
      bottom: -3.625rem;
    }
    .slick-prev:before,
    .slick-next:before {
      left: 0;
    }

    .slick-prev {
      left: -5px;
    }
    .slick-next {
      right: -5px;
    }
  }
`
const CustomCard = styled(Card)`
  margin-bottom: 24px;
  .MuiCardHeader-action {
    margin-top: 0;
    margin-right: 0;
  }
  .packagetime img {
    margin-right: 7px;
  }
`
const Overlay = (trainingPackage, changeOpenedPackageId) => (
  <StyledPopover>
    <p>
      <b>
        <Translate id="training.warmup" />
      </b>
      <br />
      {trainingPackage.warmup_drills.map(({ title }) => title)}
    </p>
    <p>
      <b>
        <Translate id="training.main_part" />
      </b>
      <br />
      {trainingPackage.main_part_drills.map(({ title }) => title)}
    </p>
    <p>
      <b>
        <Translate id="training.final_part" />
      </b>
      <br />
      {trainingPackage.final_part_drills.map(({ title }) => title)}
    </p>
    <Button
      fullWidth
      variant="contained"
      color="primary"
      size="large"
      onClick={() => changeOpenedPackageId(trainingPackage.id)}
    >
      <Translate id="button.show-more" />
    </Button>
  </StyledPopover>
)

const TrainingPackages = ({
  sessionDrills,
  changeSessionDrills,
  trainingPackages,
  favoritesTrainingPackages,
  fetchDrill,
}) => {
  const [openedPackageId, changeOpenedPackageId] = useState(null)
  const [open, setOpen] = React.useState(false)
  const [trainingPackageForNotify, setTrainingPackageNotify] = React.useState(
    null
  )

  const handleClose = () => {
    setOpen(false)
  }
  const onAddPackage = (e, id) => {
    e.stopPropagation()

    const targetPackage = trainingPackages.findById(id)

    const newSessionDrills = [...sessionDrills]

    SESSION_CATEGORIES.forEach(category => {
      newSessionDrills.unshift(
        ...targetPackage[`${category}_drills`].map(drill => {
          fetchDrill(drill.id, { added: true, track: 'training_session' })

          return {
            ...drill,
            session_part: category,
            customized_duration: drill.duration,
          }
        })
      )
    })

    changeSessionDrills(newSessionDrills)
    setTrainingPackageNotify(targetPackage)
    setOpen(true)
  }

  const openedPackage = useMemo(
    () => trainingPackages.findById(openedPackageId),
    [openedPackageId, trainingPackages]
  )

  const closeOpenedPackage = () => changeOpenedPackageId(null)

  const getIsFavorite = id => Boolean(favoritesTrainingPackages.findById(id))
  const classes = useStyles()

  const SETTINGS = {
    className: 'slider variable-width',
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    variableWidth: true,
  }

  return (
    <>
      {openedPackageId && (
        <TrainingPackageModal
          trainingPackage={openedPackage}
          onHide={closeOpenedPackage}
          open={openedPackageId}
        />
      )}
      {/* <Translate>
        {({ translate }) => ( */}
      <CustomCard className="training-package">
        <CardHeader
          title={
            <Typography variant="h4" component="h4">
              <Translate id="training.featured-training-packages" />
            </Typography>
          }
        />
        <CardContent>
          {/* events={events} */}
          {/* <PackagesContainer> */}
          <SlickSlider {...SETTINGS}>
            {/* <StyledTrainingPackage>
              <CardMedia
                component="div"
                className={classes.CustomCardMedia}
                image={IconImage}
              />
              <div style={{ position: 'relative', paddingTop: '.8rem' }}>
                <div className="title">
                  <Translate id="training.create-own-package" />
                </div>
                <SubLink to={TRAINING_PACKAGE_CREATE_ROUTE}>
                  <AddButton className={classes.addButton}>
                    + {/* <img src={PlusIcon} alt={PlusIcon} /> 
                  </AddButton>
                </SubLink>
              </div>
            </StyledTrainingPackage> */}

            {trainingPackages.map(trainingPackage => (
              <OverlayTrigger
                key={trainingPackage.id}
                rootClose
                trigger="click"
                placement="right"
                overlay={Overlay(trainingPackage, changeOpenedPackageId)}
              >
                <StyledTrainingPackage>
                  <CardMedia
                    component="div"
                    className={classes.CustomCardMedia}
                    image={trainingPackage.images.findEndGetProp(
                      Boolean,
                      'full_url'
                    )}
                  />

                  <div style={{ position: 'relative', paddingTop: '.8rem' }}>
                    <div className="title">{trainingPackage.title}</div>
                    <div
                      style={{
                        display: 'flex',
                        padding: '0 12px 6px',
                        alignItems: 'center',
                      }}
                    >
                      <div className="packagetime">
                        <img src={timeIcon} alt={timeIcon} />{' '}
                        <Typography variant="body2" color="textSecondary" component="p" style={{ fontSize: '0.875rem' }}>
                          {trainingPackage.duration}min
                        </Typography>
                      </div>
                      <div className="player">
                        <Tooltip title="Player" placement="top" arrow>
                          <CustomIconButton className={classes.PlayerNum}>
                            {' '}
                            <img src={playerIcon} alt={playerIcon} />{' '}
                            <Typography variant="body2" color="textSecondary" component="p" style={{ fontSize: '0.875rem' }}>
                              {trainingPackage.number_of_players}
                            </Typography>
                          </CustomIconButton>
                        </Tooltip>
                      </div>
                      <div style={{ marginLeft: 'auto' }}>
                        <HeartIcon
                          isActive={getIsFavorite(trainingPackage.id)}
                          entity="trainingPackages"
                          entityId={trainingPackage.id}
                        />
                      </div>
                      <IconButton>
          <AddCircleIcon className={classes.AddButton} onClick={e => onAddPackage(e, trainingPackage.id)} />
        </IconButton>
                    </div>
    
                  </div>
                </StyledTrainingPackage>
              </OverlayTrigger>
            ))}
          </SlickSlider>
          {/* </PackagesContainer> */}
        </CardContent>
      </CustomCard>
      <Hidden only={['md', 'lg', 'xl']}>
        {trainingPackageForNotify && (
          <DrillsSnackbar
            open={open}
            autoHideDuration={13000}
            onClose={handleClose}
            className={classes.snackbar}
          >
            <Alert
              onClose={handleClose}
              severity="success"
              className={classes.alert}
            >
              <Box className="d-flex" alignItems="center">
                <Box className="Snackbar-Box">
                  <Typography variant="h4" component="h4" className="title">
                    <Translate id="snackbar.package-added" />
                  </Typography>
                  <Typography variant="h6" component="h6">
                    {trainingPackageForNotify.title}
                  </Typography>
                </Box>
              </Box>
            </Alert>
          </DrillsSnackbar>
        )}
      </Hidden>
      {/* )}
      </Translate> */}
    </>
  )
}

TrainingPackages.defaultProps = {
  sessionDrills: [],
  trainingPackages: [],
  favoritesTrainingPackages: [],
}

TrainingPackages.propTypes = {
  sessionDrills: PROP_TYPES.arrayOfObjects,
  changeSessionDrills: PropTypes.func.isRequired,
  fetchDrill: PropTypes.func.isRequired,
  trainingPackages: PROP_TYPES.arrayOfObjects,
  favoritesTrainingPackages: PROP_TYPES.arrayOfObjects,
}

export default connect(
  ({ trainingPackages, favorites }) => ({
    trainingPackages: trainingPackages.items,
    favoritesTrainingPackages: favorites.items.trainingPackages,
  }),
  {
    fetchDrill: drillsModule.fetchDrill,
  }
)(TrainingPackages)
