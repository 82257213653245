import React from 'react'
import { connect } from 'react-redux'
import { lineups } from 'redux/modules'
import Loader from './Loader'
import PROP_TYPES from 'constants/propTypes'

const LineupLoader = ({ children, lineupId, fetchLineup, ...restProps }) => (
  <Loader fetchMethod={() => fetchLineup(lineupId)} {...restProps}>
    {children}
  </Loader>
)

LineupLoader.propTypes = {
  children: PROP_TYPES.children.isRequired,
  lineupId: PROP_TYPES.id.isRequired,
  fetchLineup: PROP_TYPES.func.isRequired,
}

export default connect(
  null,
  { fetchLineup: lineups.fetchLineup }
)(LineupLoader)
