import React from 'react'
// import React, { useContext } from 'react'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
// import styled from 'styled-components'
// import { Collapsible } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined'
import CommentOutlinedIcon from '@material-ui/icons/CommentOutlined'
import {
  Paper,
  Typography,
  CardHeader,
  Button,
  Card,
  Grid,
  TextareaAutosize,
  IconButton,
} from '@material-ui/core'
import { Snackbars } from 'components'

// import { Context } from '../MatchStateContext'

const useStyles = makeStyles({
  styledCardHeader: {
    padding: '0',
  },
  noteContent: {
    padding: '1rem',
  },
  actionHeader: {
    '& .MuiButtonBase-root': {
      marginLeft: '10px',
    },
  },
  customTextArea: {
    borderRadius: '8px',
    minHeight: '30px',
    padding: '0.4rem',
    marginBottom: '1rem',
  },
  noteRow: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    '& .MuiButtonBase-root': {
      position: 'absolute',
      right: '5px',
      top: '5px',
    },
  },
  noResNote: {
    position: 'absolute',
    width: '50%',
    height: '100%',
    padding: '0.5rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    transform: 'translate(50%, 0%)',
    '& p': {
      textAlign: 'center',
    },
  },
})

// const StyledTextarea = styled.textarea`
//   width: 100%;
//   height: 100px;
//   border-radius: 10px;
//   resize: none;
//   background: transparent;
// `

//  eslint-disable-next-line
const MatchNotes = ({ savedNotes }) => {
  // const {
  //   matchState: { notes },
  //   onFieldChange,
  // } = useContext(Context)

  // const onChange = ({ target: { value } }) => onFieldChange('notes', value)
  const classes = useStyles()
  const [newNote, setNewNote] = React.useState([{ noteNew: savedNotes, id: 1 }])
  const [Snackbar, changeSnackbar] = React.useState({
    show: false,
    message: '',
    error: false,
    info: false,
    warning: false,
  })
  const addNewNote = () => {
    changeSnackbar({
      show: false,
      message: '',
      error: false,
    })
    if (newNote.length > 0 && newNote[newNote.length - 1].noteNew === '') {
      changeSnackbar({
        show: true,
        message: 'Please fill the input',
        error: true,
      })

      return
    }
    setNewNote([...newNote, { noteNew: '', id: newNote.length + 1 }])
  }
  const onChangeLoop = ({ target: { value } }, index) => {
    const newArray = [...newNote]
    newArray[index].noteNew = value
    setNewNote(newArray)
  }
  const removeNote = index => {
    const newArray = [...newNote]
    if (newNote[index].noteNew === '') {
      newArray.splice(index, 1)
      setNewNote(newArray)
    } else {
      newArray[index].noteNew = ''
      setNewNote(newArray)
    }
  }

  return (
    <Paper className={classes.noteContent} elevation={0}>
      <Card>
        <CardHeader
          className={classes.styledCardHeader}
          title={
            <Typography variant="h4" component="h4">
              <Translate id="videos.notes" />
            </Typography>
          }
          action={
            <div className={classes.actionHeader}>
              {/* <Button variant="contained" color="primary" size="small">
                          Draw on board
                      </Button>  */}
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={addNewNote}
              >
                <Translate id="matches.add-note" />
              </Button>
            </div>
          }
        />
        <br />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div className={classes.noteRow}>
              {/* {notes &&
                <small>18 Jun, 2020</small>
              } */}
              {/* <TextareaAutosize
                className={classes.customTextArea}
                aria-label="Match Note"
                rowsMin={notes ? 1 : 6}
                value={notes || ''}
                placeholder=""
                onChange={onChange}
              /> */}

              {/* {notes &&
                <IconButton
                  size="small"
                  onClick={() => onFieldChange('notes', '')}
                >
                  <DeleteOutlineOutlinedIcon />
                </IconButton>} */}
            </div>
            <div className={classes.noteRow}>
              {newNote.map((item, index) => {
                return (
                  <div key={item.id}>
                    {item.noteNew && <small>18 Jun, 2020</small>}
                    <div className={classes.noteRow}>
                      <TextareaAutosize
                        className={classes.customTextArea}
                        aria-label="Match Note"
                        rowsMin={item.noteNew ? 1 : 6}
                        value={item.noteNew || ''}
                        // placeholder="Write Something here"
                        placeholder=""
                        onChange={event => onChangeLoop(event, index)}
                      />
                      {/* {item.noteNew && */}
                      {/* {(item.noteNew && !newNote.length === 1) && */}
                      <IconButton
                        size="small"
                        onClick={() => removeNote(index)}
                      >
                        <DeleteOutlineOutlinedIcon />
                      </IconButton>
                      {/* } */}
                    </div>
                    {!item.noteNew && newNote.length === 1 && (
                      <div className={classes.noResNote}>
                        <CommentOutlinedIcon />
                        <p>
                          <Translate id="matches.empty-notes" />
                        </p>
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
          </Grid>
        </Grid>
      </Card>
      {Snackbar.show && <Snackbars {...Snackbar} />}
    </Paper>
  )
}

MatchNotes.defaultProps = {
  savedNotes: null,
}

MatchNotes.propTypes = {
  savedNotes: PropTypes.string,
}

export default connect(({ matches: { current: { notes: savedNotes } } }) => ({
  savedNotes,
}))(MatchNotes)
