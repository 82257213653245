import React, { createContext, useState, useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import filterObject from 'utils/filterObject'
import sortBy from 'lodash.sortby'
import PROP_TYPES from 'constants/propTypes'

const createNormalizedPlayers = players => {
  const normalizedPlayers = players.map(item => ({
    id: item.id,
    name: `${item.first_name} ${item.last_name ? item.last_name : ''}`,
    notAvailableReason: item.reason,
  }))

  return sortBy(normalizedPlayers, ({ relation }) => relation)
}

const STATE_FIELDS = [
  'id',
  'date',
  'tickers',
  'location',
  'street',
  'city',
  'state',
  'country',
  'postal_code',
  'lat',
  'lng',
  'short_address',
  'additional_info',
  'start_time',
  'end_time',
  'description',
  'event_players'
]

const PLAYERS_FIELDS = [
  'uuid',
  'available',
  'first_name',
  'last_name',
  'avatar'
]

export const Context = createContext({})

const EventStateContext = ({ children, currentEvent }) => {
  const [eventState, changeEventState] = useState({})
  const [anyChange, setAnyChange] = useState(false)

  useEffect(() => {
    const newEventState = {
      ...filterObject(
        currentEvent,
        () => true,
        key => STATE_FIELDS.includes(key)
      ),
      players: currentEvent.players.map(player =>
        filterObject(player, () => true, key => PLAYERS_FIELDS.includes(key))
      ),
      eventPlayers: currentEvent.event_players,
      normalizedPlayers: createNormalizedPlayers(currentEvent.players),
      tickers:currentEvent.tickers
    }
    changeEventState(newEventState)
  }, [currentEvent.id])

  const onFieldChange = useCallback(
    (fieldName, newValue) => {
      changeEventState({ ...eventState, [fieldName]: newValue })
      setAnyChange(true)
    },
    [eventState]
  )
  const changeWholeContextState = useCallback(
    (data) => {
      changeEventState({ ...eventState, ...data })
      setAnyChange(true)
    },
    [eventState]
  )

  return (
    <Context.Provider
      value={{
        eventState,
        onFieldChange,
        changeEventState,
        setAnyChange,
        anyChange,
        changeWholeContextState,
        currentEvent
      }}
    >
      {children}
    </Context.Provider>
  )
}

EventStateContext.propTypes = {
  children: PROP_TYPES.children.isRequired,
  currentEvent: PROP_TYPES.shape().isRequired,
}

export default connect(({ generalEvents: { current } }) => ({
  currentEvent: current,
}))(EventStateContext)
