import {
  Box,
  CardHeader,
  Divider,
  List,
  ListItem,
  Paper,
  Typography,
} from '@material-ui/core'
import { SubLink } from 'containers'
import React from 'react'
import { Translate } from 'react-localize-redux'
import { formateDate, getHourMinute } from 'utils/formatDate'
import hasSubscription from 'utils/hasSubscription'
import PROP_TYPES from 'constants/propTypes'
import { EVENT_ROUTE } from 'constants/routes'

const RecentEventsWidget = ({ pastEvents }) => {
  return (
    pastEvents.length > 0 && (
      <Box mt={3}>
        <Paper>
          <CardHeader
            title={
              <Typography variant="h4" component="h4">
                <Translate id="events.recent-events" />
              </Typography>
            }
          />
          <List>
            {pastEvents &&
              pastEvents.map(
                (pastEvent, index) =>
                  index <= 4 && (
                    <>
                      <Divider />
                      <ListItem>
                        <Box
                          width="100%"
                          display="flex"
                          justifyContent="between"
                          alignItems="center"
                          textAlign="left"
                        >
                          <Box component="div" mr={2}>
                            <Typography variant="h4" component="h4">
                              {getHourMinute(pastEvent.date)}
                            </Typography>
                            <Typography variant="h6" component="h6">
                              {`${formateDate(pastEvent.date)[4]} ${
                                formateDate(pastEvent.date)[3]
                              }`}
                            </Typography>
                          </Box>
                          <Box component="div">
                            <SubLink
                              to={
                                (pastEvent.relation === 'training'
                                  ? `/trainings/${pastEvent.id}`
                                  : hasSubscription() === 'basic'
                                  ? EVENT_ROUTE
                                  : `/matches/${pastEvent.id}`) 
                              }
                            >
                              <Typography variant="h4" component="h4">
                                {pastEvent.relation === 'training'
                                  ? `Training`
                                  : pastEvent.opponent}
                              </Typography>
                              <Typography
                                variant="h6"
                                fontSize={14}
                                component="h6"
                              >
                                {pastEvent.relation === 'training'
                                  ? pastEvent.short_address || ''
                                  : pastEvent.home === 0
                                  ? 'Away'
                                  : 'Home'}
                              </Typography>
                            </SubLink>
                          </Box>
                          <Box component="div" ml="auto" textAlign="right">
                            <Typography variant="h4" component="h4">
                              {`${pastEvent.available_players}/${pastEvent.total_players}`}
                            </Typography>
                            <Typography
                              variant="h6"
                              fontSize={14}
                              component="h6"
                            >
                              <Translate id="home.available" />
                            </Typography>
                          </Box>
                        </Box>
                      </ListItem>
                    </>
                  )
              )}
          </List>
        </Paper>
      </Box>
    )
  )
}

RecentEventsWidget.propTypes = {
  pastEvents: PROP_TYPES.arrayOf(PROP_TYPES.shape().isRequired).isRequired,
}
export default RecentEventsWidget
