import React, {useMemo} from 'react'
import PropTypes from 'prop-types'
import requiredIf from 'react-required-if'
import { withLocalize } from 'react-localize-redux'
import { withRouter } from 'react-router-dom'
import {
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core'
import { Avatar } from 'containers'
import { setLocale, getLocale } from 'utils/localstorage'
import { teams as teammodules } from 'redux/modules'
import { compose } from 'redux'
import { connect } from 'react-redux'
import Constants from './Constants'
import LanguageToggle from './components/LanguageToggle'
import { StyledMenu } from './styledComponents'

const Dropdown = ({
  options,
  firstTeam,
  club,
}) => {

 const MENU_ITEMS = useMemo(Constants, [])

  setLocale(getLocale())
  const [anchorEl, setAnchorEl] = React.useState(null)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
   <>
        {firstTeam && (
                <IconButton size="medium" onClick={handleClick}>
                  { options.length > 0 && firstTeam.logo ?
                    <Avatar
                    src={`${firstTeam.logo.full_url}`}
                    alt={firstTeam.title}
                  /> : <Avatar >{firstTeam.title.charAt(0)}</Avatar>
                }
                </IconButton>
              )}

              <StyledMenu
                id="Profile-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
              <LanguageToggle />
              {MENU_ITEMS.map(({ LinkComponent, linkTo, icon, text, divider, target, isFullPath }) => (
                !club?.display_billing && linkTo ==='/current-team/edit' ? "": (
                  <>
                  {divider}
                  <ListItem button onClick={handleClose} component={LinkComponent} to={linkTo} key={linkTo} target={target} isFullPath={isFullPath}>
                        <ListItemIcon>{icon}</ListItemIcon>
                        <ListItemText primary={text} />
                  </ListItem>

                  </>
                )
            ))}
            </StyledMenu>
        
        </>
    
  )
}

Dropdown.defaultProps = {
  options: [],
}

Dropdown.propTypes = {
  options: PropTypes.array,
  firstTeam: requiredIf(PropTypes.shape(), props => props.isTeam),
  updateLocale: PropTypes.func,
}

export default compose(
  connect(
    ({ clubs }) => ({
      club: clubs.current
    }),
    {
      updateLocale: teammodules.updateLocale,
    }
  ),
  withLocalize,
  withRouter
)(Dropdown)
