import { combineActions, handleActions } from 'redux-actions'
import {
  actionsTypesGenerator,
  onRequest,
  onSuccess,
  onFailure,
} from 'utils/reduxHelpers'
import initialState from '../initialState'

const namespace = 'FOLDER'

const FETCH_ROOT_FOLDER_CONTENT = actionsTypesGenerator(
  `FETCH_ROOT_${namespace}_CONTENT`
)
const FETCH_FOLDER_CONTENT = actionsTypesGenerator(`FETCH_${namespace}_CONTENT`)
const CREATE_NEW_FOLDER = actionsTypesGenerator(`CREATE_NEW_${namespace}`)
const REMOVE_FOLDER = actionsTypesGenerator(`REMOVE_${namespace}`)
const UPLOAD_FILE_TO_FOLDER = actionsTypesGenerator(
  `UPLOAD_FILE_TO_${namespace}`
)
const GET_FILE = actionsTypesGenerator('GET_FILE')
const REMOVE_FILE = actionsTypesGenerator('REMOVE_FILE')

export const fetchRootFolderContent = teamId => ({
  types: FETCH_ROOT_FOLDER_CONTENT,
  callAPI: {
    method: 'GET',
    entity: 'Root Folder Content',
    path: `/teams/${teamId}/documents`,
  },
})

export const fetchFolderContent = (teamId, folderId) => ({
  types: FETCH_FOLDER_CONTENT,
  callAPI: {
    method: 'GET',
    entity: 'Folder Content',
    path: `/teams/${teamId}/documents/${folderId}`,
  },
})

export const createNewFolder = (teamId, data) => ({
  types: CREATE_NEW_FOLDER,
  callAPI: {
    method: 'POST',
    entity: 'New Folder',
    path: `/teams/${teamId}/documents`,
    data,
  },
})

export const removeFolder = (teamId, folderId) => ({
  types: REMOVE_FOLDER,
  callAPI: {
    method: 'DELETE',
    entity: 'Folder',
    path: `/teams/${teamId}/documents/${folderId}`,
  },
  payload: { folderId },
})

export const uploadFileToFolder = (teamId, folderId, data) => ({
  types: UPLOAD_FILE_TO_FOLDER,
  callAPI: {
    method: 'POST',
    entity: 'File',
    path: `/teams/${teamId}/documents/${folderId}/files`,
    data,
  },
})

export const getFile = (teamId, folderId, fileId) => ({
  types: GET_FILE,
  callAPI: {
    method: 'GET',
    entity: 'File',
    path: `/teams/${teamId}/documents/${folderId}/files/${fileId}`,
  },
})

export const removeFile = (teamId, folderId, fileId) => ({
  types: REMOVE_FILE,
  callAPI: {
    method: 'DELETE',
    entity: 'File',
    path: `/teams/${teamId}/documents/${folderId}/files/${fileId}`,
  },
  payload: { fileId },
})

export default handleActions(
  {
    [combineActions(
      FETCH_ROOT_FOLDER_CONTENT.request,
      FETCH_FOLDER_CONTENT.request,
      CREATE_NEW_FOLDER.request,
      REMOVE_FOLDER.request,
      UPLOAD_FILE_TO_FOLDER.request,
      REMOVE_FILE.request
    )]: onRequest,
    [combineActions(
      FETCH_ROOT_FOLDER_CONTENT.success,
      FETCH_FOLDER_CONTENT.success
    )]: (state, { response: { data: currentFolder = {} } }) =>
      onSuccess({
        ...state,
        currentFolder,
      }),
    [CREATE_NEW_FOLDER.success]: (state, { response: { data = {} } }) =>
      onSuccess({
        ...state,
        currentFolder: {
          ...state.currentFolder,
          children: [...state.currentFolder.children, data],
        },
      }),
    [REMOVE_FOLDER.success]: (state, { folderId }) =>
      onSuccess({
        ...state,
        currentFolder: {
          ...state.currentFolder,
          children: state.currentFolder.children.filterById(+folderId),
        },
      }),
    [UPLOAD_FILE_TO_FOLDER.success]: (state, { response: { data = {} } }) =>
      onSuccess({
        ...state,
        currentFolder: {
          ...state.currentFolder,
          files: [...state.currentFolder.files, data],
        },
      }),
    [REMOVE_FILE.success]: (state, { fileId }) =>
      onSuccess({
        ...state,
        currentFolder: {
          ...state.currentFolder,
          files: state.currentFolder.files.filterById(+fileId),
        },
      }),
    [combineActions(
      FETCH_ROOT_FOLDER_CONTENT.failure,
      FETCH_FOLDER_CONTENT.failure,
      CREATE_NEW_FOLDER.failure,
      REMOVE_FOLDER.failure,
      UPLOAD_FILE_TO_FOLDER.failure,
      REMOVE_FILE.failure
    )]: onFailure,
  },
  initialState.documents
)
