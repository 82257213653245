import React from 'react'
import PropTypes from 'prop-types'
// import { makeStyles } from '@material-ui/core/styles'
import { 
    // DialogActions, 
    Typography, 
    Box
 } from '@material-ui/core'

const TabPanel = props => {
    const { children, value, index, ...other } = props
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    )
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node.isRequired,
    index: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }
  

export default TabPanel
