import React from 'react'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { SummaryCircle } from 'components'
import { Card } from '@material-ui/core'

const CardMui = styled(Card)`
  height: 100%;
  box-shadow: unset;
`
const CirclesWrapper = styled.div`
  display: flex;
  justify-content: space-around;
`

const PlayerStatisticsWidget = ({ statistics, player }) => (
  <Translate>
    {({ translate }) => (
      <CardMui>
        <CirclesWrapper>
          <SummaryCircle
            title={translate('team.sessions')}
            value={
              Number.isNaN(
                statistics.trainings_attended / statistics.trainings_total
              )
                ? '0%'
                : `${Math.round(
                    (statistics.trainings_attended /
                      statistics.trainings_total) *
                      100
                  )}%`
            }
            ratio={statistics.trainings_attended / statistics.trainings_total}
          />
               <SummaryCircle
              title={translate('player.playing-time')}
              value={player.totalMinutesPlayed}
             
            />
            <SummaryCircle
              title={translate('team.goals')}
              value={statistics.goals_scored}
              maxValue={statistics.goals_total}
              ratio={statistics.goals_scored / statistics.goals_total}
            />
            <SummaryCircle
              title={translate('team.assisted')}
              value={statistics.goals_assisted}
              maxValue={statistics.goals_assisted_total}
              ratio={
                statistics.goals_assisted / statistics.goals_assisted_total
              }
            />
        </CirclesWrapper>
      </CardMui>
    )}
  </Translate>
)

PlayerStatisticsWidget.propTypes = {
  statistics: PropTypes.shape().isRequired,
  player: PropTypes.shape().isRequired
}

export default PlayerStatisticsWidget
