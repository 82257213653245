import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Button,
  IconButton,
  TextField,
  makeStyles,
  Box
} from "@material-ui/core";
import PropTypes from "prop-types";
import { PopperTooltip } from "components";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import { Translate } from "react-localize-redux";
import { toast } from "react-toastify";
import { teams as teamsModule } from "redux/modules";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import PROP_TYPES from "../constants/propTypes";

const useStyles = makeStyles(theme => {
  const color = theme.palette.background.default;

  return {
    listRoot: {
      backgroundColor: color,
      maxHeight: 160,
      overflowY: "auto",
      overflowX: "hidden"
    },
    list: {
      backgroundColor: color,
      maxWidth: 276,
      margin: theme.spacing(.3)
    },
    listInput: {
      marginBottom: theme.spacing(0)
    },
    btn: {
      marginLeft: theme.spacing(1)
    }

  };
});
const SkillRatingsEditCreateWidget = ({
    match: {
      params: { teamId }
    },
    radarSkills,
    changeRadarSkill,
    fetchSkills,
    addSkill,
    updateSkill,
    deleteSkill,
    arrow
  }) => {

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [skills, setSkills] = useState([]);

  const removeElementFromSkill = index => {
    const temp = [...skills];
    const deletedSkill = temp[index];

    const radarSkillsIndex = radarSkills.findIndex(rd => rd.name === deletedSkill.name);
    const radarSkillsTmp = [...radarSkills];
    radarSkillsTmp.splice(radarSkillsIndex, 1)
    changeRadarSkill(radarSkillsTmp);

    temp.splice(index, 1);
    setSkills(temp);
  };

  const [newLabel, changeLabel] = useState("");
  const handleChangeLabel = event => {
    changeLabel(event.target.value);
  };

  const [isEdit, changeEdit] = useState(false);
  const [editSkill, changeEditSkill] = useState(null);
  const addTeamSkill = () => {

    // checking validation
    if (
      newLabel === undefined ||
      newLabel === "" ||
      newLabel.length < 3
    ) {
      toast("Please enter valid label");

      return;
    }

    // calling API
    addSkill(teamId, { name: newLabel })
      .then(res => {
        const newSkill = res.response.data;
        setSkills([...skills, newSkill]);
        changeRadarSkill([...radarSkills, { id: newSkill.id, name: newSkill.name, value: 0.0 }]);
        changeLabel("");
      })
      .catch(e => {
        toast(e.error);
      });
  };

  const editModeOpen = (id) => {
    const findSkill = skills.find(p => p.id === id);
    if (findSkill) {
      changeEdit(true);
      changeLabel(findSkill.name);
      changeEditSkill(findSkill);
    } else {
      toast("Skill not found for edit");
    }
  };

  const editModeClose = () => {
    changeEdit(false);
    changeLabel("");
    changeEditSkill(null);
  };

  const updateTeamSkill = () => {

    // checking validation for name
    if (
      newLabel === undefined ||
      newLabel === "" ||
      newLabel.length < 3
    ) {
      toast("Please enter valid label");

      return;
    }
    if (!editSkill) {
      toast("Skill not found for Edit");

      return;
    }

    if (editSkill.name === newLabel) return;

    // calling update API
    updateSkill(editSkill.id, { name: newLabel })
      .then(res => {
        const updatedSkill = res.response.data;

        setSkills(skills.map(p => {
          const skill = { ...p };
          if (skill.id === updatedSkill.id) {
            skill.name = updatedSkill.name;
          }

          return skill;
        }));

        const radarSkillsIndex = radarSkills.findIndex(rd => rd.name === editSkill.name);
        const radarSkillsTmp = [...radarSkills];
        radarSkillsTmp[radarSkillsIndex].name = updatedSkill.name;
        changeRadarSkill(radarSkillsTmp);

        changeLabel("");
        changeEdit(false);
        changeEditSkill(null);
      })
      .catch(e => {
        toast(e.error);
      });
  };

  const deleteTeamSkill = (id, index) => {
    deleteSkill(id)
      .then(() => {
        removeElementFromSkill(index);
      })
      .catch(e => {
        toast(e.error);
      });
  };

  useEffect(() => {
    fetchSkills(teamId).then(res => {
      setSkills(res.response.data);
    });
  }, []);

  return (
    <PopperTooltip
      open={open}
      onClose={() => setOpen(false)}
      arrow={arrow}
      placement="bottom"
      content={<>
        <List dense className={classes.listRoot}>
          {skills.map((skill, index) => (
            <>
              <ListItem key={skill.id} className={classes.list}>
                <ListItemText>{skill.name}</ListItemText>
                <ListItemSecondaryAction>
                  <IconButton
                    onClick={() => editModeOpen(skill.id)}
                    size="small">
                    <EditIcon fontSize="small" />
                  </IconButton>
                  <IconButton
                    onClick={() => deleteTeamSkill(skill.id, index)}
                    size="small">
                    <DeleteOutlineIcon fontSize="small" />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            </>
          ))}
        </List>
        <Box px={2} pt={1.5} pb={1.5} display="flex">
          <TextField
            label="New Label"
            value={newLabel}
            variant="outlined"
            className={classes.listInput}
            size="small"
            onChange={handleChangeLabel}
          />
          <Button
            variant="contained" size="medium"
            color="primary"
            className={classes.btn}
            mr={2}
            onClick={isEdit ? updateTeamSkill : addTeamSkill}
          >
            {isEdit ? <Translate id="button.update" /> :
              <Translate id="button.add" />}
          </Button>
          {isEdit && <Button variant="contained" size="medium" className={classes.btn}
                             onClick={editModeClose}><Translate
            id="button.cancel" /></Button>}
        </Box>
      </>}
    >
      <Button variant="contained" size="small" color="primary" onClick={() => setOpen(!open)}>
        <Translate id="team.manage-skills" />
      </Button>

    </PopperTooltip>

  );
};

SkillRatingsEditCreateWidget.defaultProps = {};

SkillRatingsEditCreateWidget.propTypes = {
  arrow: PropTypes.bool.isRequired,
  match: PROP_TYPES.match.isRequired,
  radarSkills: PropTypes.shape().isRequired,
  fetchSkills: PropTypes.func.isRequired,
  addSkill: PropTypes.func.isRequired,
  changeRadarSkill: PropTypes.func.isRequired,
  updateSkill: PropTypes.func.isRequired,
  deleteSkill: PropTypes.func.isRequired

};

export default compose(
  withRouter,
  connect(
    null,
    {
      fetchSkills: teamsModule.teamSkills,
      addSkill: teamsModule.createTeamSkill,
      updateSkill: teamsModule.updateTeamSkill,
      deleteSkill: teamsModule.deleteTeamSkill
    }
  ))(SkillRatingsEditCreateWidget);
