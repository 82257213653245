import React, { useState, useEffect, Fragment } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withLocalize } from 'react-localize-redux'
import { Table } from 'react-bootstrap'
import { Card, CloseButton } from 'components'
import {
  SubLink,
  CategoryForManageCategories,
  LanguageSelectorForAdmins,
} from 'containers'
import { drills } from 'redux/modules'
import { createSubUrl } from 'utils/urlConstructor'
import { getClub } from 'utils/localstorage'
import PROP_TYPES from 'constants/propTypes'
import { DRILLS_DASHBOARD_ROUTE } from 'constants/routes'

const MAX_DEPTH = 2


// @todo: #roles protect route or move functionality outside
const DrillsCategoriesContainer = ({
  location: { fromUrl },
  match,
  drillsCategories,
  createDrillsCategory,
  updateDrillsCategory,
  deleteDrillsCategory,
  activeLanguage,
}) => {
  const [drillsCategoriesState, changeDrillsCategoriesState] = useState([])

  useEffect(() => {
    changeDrillsCategoriesState(drillsCategories)
  }, [drillsCategories])

  const linkBack = fromUrl || createSubUrl(match, DRILLS_DASHBOARD_ROUTE)

  const addNewCategory = parentId => title => {
    createDrillsCategory({
      title,
      parent_id: parentId,
    })
  }

  const Categories = ({ depth, parentId }) => (
    <>
      {drillsCategoriesState
        .filter(category => category.parent_id === parentId && category.club_id === getClub().id)
        .map(category => (
          <Fragment key={category.id}>
            <CategoryForManageCategories
              depth={depth}
              category={category}
              activeLocale={activeLanguage.code}
              updateCategory={updateDrillsCategory}
              deleteCategory={deleteDrillsCategory}
            />
            {depth < MAX_DEPTH && (
              <Categories depth={depth + 1} parentId={category.id} />
            )}
          </Fragment>
        ))}
      {/* {depth > 0 && ( */}
      <CategoryForManageCategories.New
        depth={depth}
        addNewCategory={addNewCategory(parentId)}
      />
      {/* )} */}
    </>
  )

  Categories.defaultProps = {
    parentId: null,
  }

  Categories.propTypes = {
    depth: PROP_TYPES.number.isRequired,
    parentId: PROP_TYPES.id,
  }

  return (
    <Card>
      <Card.Title className="d-flex justify-content-between">
        <div>
          Manage Categories
          <LanguageSelectorForAdmins />
        </div>
        <SubLink to={linkBack} isFullPath>
          <CloseButton />
        </SubLink>
      </Card.Title>
      <Table bordered>
        <tbody>
          <Categories depth={0} parentId={null} />
        </tbody>
      </Table>
    </Card>
  )
}

DrillsCategoriesContainer.defaultProps = {
  drillsCategories: [],
}

DrillsCategoriesContainer.propTypes = {
  location: PROP_TYPES.location.isRequired,
  match: PROP_TYPES.match.isRequired,
  drillsCategories: PROP_TYPES.arrayOfObjects,
  createDrillsCategory: PROP_TYPES.func.isRequired,
  updateDrillsCategory: PROP_TYPES.func.isRequired,
  deleteDrillsCategory: PROP_TYPES.func.isRequired,
  activeLanguage: PROP_TYPES.shape().isRequired,
}

export default compose(
  connect(
    ({
      drills: {
        current: { drillsCategories },
      },
    }) => ({
      drillsCategories,
    }),
    {
      createDrillsCategory: drills.createDrillsCategory,
      updateDrillsCategory: drills.updateDrillsCategory,
      deleteDrillsCategory: drills.deleteDrillsCategory,
    }
  ),
  withLocalize
)(DrillsCategoriesContainer)
