import React from 'react'
import PropTypes from 'prop-types'
import Joi from 'joi'
import { reduxForm, Field } from 'redux-form'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {
  HasClubPermission,
  ImageUploader,
  LanguageSelectorForAdmins,
} from 'containers'
import {
  Paper,
  Grid,
  Box,
  Button,
  Typography,
  Toolbar,
  Divider,
} from '@material-ui/core'
import {
  Input,
  LinkBack,
  Checkbox,
  ActionWithPrompt,
  FroalaTextEditor,
  CategoriesSelector,
  InputNumber,
} from 'components'
import createJoiValidator from 'utils/createJoiValidator'
import { Translate } from 'react-localize-redux'
import PROP_TYPES from 'constants/propTypes'
import PERMISSIONS from 'constants/permissions'

const MuiBox = withStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 0),
    '& .MuiBox-root': {
      margin: theme.spacing(0, 1.5, 0, 0),
      '& .MuiTypography-root': {
        fontWeight: 500,
        color: theme.palette.action,
        marginRight: theme.spacing(0.5),
      },
    },
  },
}))(Box)

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    margin: theme.spacing(2, 0, 0),
    padding: theme.spacing(3),
  },
  toolbar: {
    minHeight: 56,
    padding: theme.spacing(0, 2),
  },
  descriptionCell: {
    whiteSpace: 'nowrap',
    maxWidth: 'calc(100vw - 20px)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  media: {
    height: 140,
  },
  title: {
    flex: '1 1 0',
  },
  fab: {
    position: 'absolute',
    top: theme.spacing(-3),
    right: theme.spacing(2),
  },
  spacing: {
    marginRight: theme.spacing(2),
  },
}))

const validationSchema = Joi.object().keys({
  title: Joi.string()
    .max(255)
    .required(),
  reading_time: Joi.optional(),
  body: Joi.string().required(),
  global: Joi.any().allow(0, 1),
  player_access: Joi.any().allow(0, 1),
  image: Joi.any().required(),
  categories: Joi.array().has(Joi.number()),
})

const ArticleCreateEditForm = ({
  caption,
  fromPath,
  articlesCategories,
  isEditForm,
  showDeleteButton,
  handleDelete,
  handleSubmit,
  // reset,
}) => {
  const classes = useStyles()

  return (

    <Translate>
      {({ translate }) => (<>
        <Toolbar className={classes.toolbar} component={Paper}>
          <Typography variant="h4" component="h4" className={classes.title}>
            {caption} {isEditForm && <LanguageSelectorForAdmins />}
          </Typography>

          {/* <Button onClick={reset}><Translate id="button.reset"/></Button> */}
          <LinkBack
            to={fromPath.value}
            className={classes.spacing}
            title={translate('button.cancel')}
            isFullPath
          />
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            {translate('button.save')}
          </Button>
        </Toolbar>
        <form>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={7} lg={8} xl={8}>
              <Paper className={classes.root}>
                <Box pb={2}>
                  <Field
                    name="title"
                    label={translate('articles.title')}
                    component={Input}
                    variant="outlined"
                    margin="dense"
                  />
                </Box>
                <Field
                  name="body"
                  placeholder={translate('froala.type-something')}
                  component={FroalaTextEditor}
                />
              </Paper>

              <Box py={2}>
                <Typography
                  variant="h4"
                  component="h4"
                  className={classes.title}
                >
                  {translate('drills.categories')}
                </Typography>
                <Field
                  name="categories"
                  component={CategoriesSelector}
                  items={articlesCategories}
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={5} lg={4} xl={4}>
              <Paper className={classes.root}>
                <Field name="image" component={ImageUploader} />
                <Box pt={2}>
                  <HasClubPermission ability={PERMISSIONS.CREATE_GLOBAL_ARTICLE}>
                    <Field name="global" label={translate('form.global-checkbox-text')} component={Checkbox} />
                  </HasClubPermission>
                </Box>
                <Box>
                  <Field name="player_access" label={translate('articles.visible-for-players')} component={Checkbox} />
                </Box>
                <MuiBox>
                  <Box>
                    <Typography
                      variant="h4"
                      component="h4"
                      className={classes.title}
                    >
                      {translate('time.minutes')}
                    </Typography>
                  </Box>
                  <Box className={classes.title}>
                    <Field
                      id="reading_time"
                      label={translate('time.reading-time')}
                      component={InputNumber}
                      name="reading_time"
                      minLimit={0}
                    />
                  </Box>
                </MuiBox>
                <Divider />

              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              {showDeleteButton && (
                <ActionWithPrompt
                  className="text-danger border-danger"
                  onSuccess={handleDelete}
                  message={translate('articles.are-you-sure-you-want-to-delete-the-article')}
                  title="Delete"
                />
              )}
            </Grid>
          </Grid>
        </form>
      </>)}
    </Translate>
  )
}

ArticleCreateEditForm.defaultProps = {
  showDeleteButton: false,
  handleDelete: () => 0,
  articlesCategories: [],
  isEditForm: false,
}

ArticleCreateEditForm.propTypes = {
  form: PropTypes.string.isRequired, // eslint-disable-line
  initialValues: PropTypes.shape(), // eslint-disable-line
  caption: PropTypes.string.isRequired,
  fromPath: PropTypes.shape({
    value: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired, // eslint-disable-line
  articlesCategories: PROP_TYPES.arrayOfObjects,
  isEditForm: PROP_TYPES.bool,
  showDeleteButton: PROP_TYPES.bool,
  handleDelete: PROP_TYPES.func,
  handleSubmit: PropTypes.func.isRequired,
  // reset: PropTypes.func.isRequired,
}

export default reduxForm({
  enableReinitialize: true,
  validate: createJoiValidator(validationSchema),
})(ArticleCreateEditForm)
