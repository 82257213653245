import React, { useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {
  Button,
  CardMedia,
  Dialog,
  Grid,
  Paper,
  TextField,
} from '@material-ui/core';


const styles = makeStyles(theme => ({
  loginContainer: {
    height: 386,
    width: 313
  },
  loginItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1rem',
    height: '100%',
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '1rem',
    width: '100%'
  },
  button: { marginBottom: '0.5rem', width: '100%' },
}))



export default function ConnectParty() {

  const classes = styles()
  const emailRef = useRef(null)
  const passwordRef = useRef(null)

  const [isOpen, setIsOpen] = useState(false)

  function ConnectForm({img}) {
    return (
      <Dialog open={isOpen}>
        <Paper className={classes.loginContainer}>
          <Grid item sm={12} md={12} lg={12} xl={12} className={classes.loginItem}>
            <CardMedia
              className={classes.media}
              image={img}
            />

            <div className={classes.itemContainer}>
              <TextField
                inputRef={emailRef}
                variant="outlined"
                label="Login / E-mail"
              />
              <TextField
                inputRef={passwordRef}
                variant="outlined"
                label="Password"
              />
            </div>

            <div className={classes.itemContainer}>
              <Button className={classes.button}
                size="large" variant="contained" color="primary"
                onClick={() => { }}
              >Authenticate</Button>

              <Button className={classes.button}
                size="large" variant="text" color="primary"
                onClick={() => setIsOpen(false)}
              >Close</Button>
            </div>

          </Grid>
        </Paper>
      </Dialog>
    )
  }

  return [ConnectForm, setIsOpen]
}

