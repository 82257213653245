import React from 'react'
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'

const TeamSelection = ({ selectedTeam, handleTeam, ownTeam }) => {
  return (
    <FormControl variant="outlined" margin="dense">
      <InputLabel id="demo-simple-select-label">
        <Translate id="team.team" />
      </InputLabel>
      <Select
        labelId="demo-simple-select-outlined-label"
        id="match-type-selected-outlined"
        value={selectedTeam}
        onChange={(event) => handleTeam(event.target.value)}
        label={<Translate id="team.team" />}
      >
        <MenuItem value={1}>{ownTeam}</MenuItem>
        <MenuItem value={0}>
          <Translate id="team.opponent" />
        </MenuItem>
      </Select>
    </FormControl>
  )
}
TeamSelection.defaultProps = {
  ownTeam: '',
  selectedTeam: 0,
  handleTeam: () => 0,
}

TeamSelection.propTypes = {
  handleTeam: PropTypes.func,
  selectedTeam: PropTypes.number,
  ownTeam: PropTypes.string,
}
export default TeamSelection
