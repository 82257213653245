import React, { useMemo } from 'react'
import { withRouter, Switch, Route } from 'react-router-dom'
import { TeamsLoader, SeasonsLoader, ClubLoader, TeamLoader, PlayersLoader } from 'loaders'
import { NavBar } from 'containers'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { diffDate } from 'utils/formatDate'
import urlConstructor from 'utils/urlConstructor'
import {
  Article,
  DrillCreate,
  DrillEdit,
  DrillsDashboard,
  DrillsCategories,
  TrainingPackageCreate,
  TrainingPackageEdit,
  TrainingPackagesDashboard,
  Favorites,
  HomeDashboard,
  ArticlesDashboard,
  ArticleCreate,
  ArticleEdit,
  ArticlesCategories,
  ArticlesCategoriesView,
  MatchDashboard,
  MatchesCalendar,
  NoMatch,
  PlayerCreate,
  PlayerDashboard,
  PlayerEdit,
  TeamAccess,
  StaffForm,
  TeamDashboard,
  TeamEdit,
  TeamMenu,
  Teams,
  Seasons,
  TrainingDashboard,
  TrainingsCalendar,
  Video,
  VideoAnalysis,
  LineupsDashboard,
  LineupCreate,
  LineupEdit,
  Documents,
  UpdateCard,
  User,
  Invoice,
  ConnectDashboard,
  TaskGroups,
  EventDashboard
} from 'routes'
import TaskGroup from 'routes/TaskManagement/TaskGroup'
import {
  ARTICLE_ROUTE,
  DRILL_CREATE_ROUTE,
  DRILL_EDIT_ROUTE,
  DRILLS_DASHBOARD_ROUTE,
  DRILLS_CATEGORIES,
  TRAINING_PACKAGE_CREATE_ROUTE,
  TRAINING_PACKAGE_EDIT_ROUTE,
  TRAINING_PACKAGES_DASHBOARD_ROUTE,
  FAVORITES_ROUTE,
  ARTICLES_DASHBOARD_ROUTE,
  ARTICLE_CREATE_ROUTE,
  ARTICLE_EDIT_ROUTE,
  ARTICLES_CATEGORIES,
  ARTICLES_CATEGORIES_VIEW,
  MATCH_DASHBOARD_ROUTE,
  MATCHES_CALENDAR_ROUTE,
  TEAM_ACCESS_ROUTE,
  TEAM_ACCESS_CREATE_ROUTE,
  TEAM_ACCESS_EDIT_ROUTE,
  TEAM_DASHBOARD_ROUTE,
  TEAM_EDIT_ROUTE,
  TEAM_MENU_ROUTE,
  TEAM_PLAYER_CREATE_ROUTE,
  TEAM_UPGRADE_ROUTE,
  TEAM_PLAYER_DASHBOARD_ROUTE,
  TEAM_PLAYER_EDIT_ROUTE,
  TEAMS_ROUTE,
  SEASONS_ROUTE,
  TRAINING_DASHBOARD_ROUTE,
  EVENT_ROUTE,
  VIDEO_ANALYSIS_ROUTE,
  VIDEO_ROUTE,
  LINEUPS_DASHBOARD_ROUTE,
  LINEUP_CREATE_ROUTE,
  LINEUP_EDIT_ROUTE,
  DOCUMENTS_ROUTE,
  DOCUMENTS_FOLDER_ROUTE,
  UPDATE_CARD_DATA,
  USER_ROUTE,
  SUBSCRIPTION_INVOICE,
  CONNECT_THIRDPARTY_ROUTE,
  TASK_MANAGEMENT_ROUTE,
  TASK_ROUTE,
  EVENT_DASHBOARD_ROUTE
} from 'constants/routes'
import PROP_TYPES from 'constants/propTypes'

const RouteWithSubRoutes = ({
  match: {
    path: parentPath,
    params: { teamId, seasonId },
  },
  history,
  teams,
}) => {

  const routes = useMemo(
    () => [
      {
        path: '',
        exact: true,
        component: HomeDashboard,
      },
      {
        path: EVENT_ROUTE,
        exact: true,
        component: TrainingsCalendar,
      },
      {
        path: USER_ROUTE,
        component: User,
      },
      {
        path: TRAINING_DASHBOARD_ROUTE,
        component: TrainingDashboard,
      },
      {
        path: MATCHES_CALENDAR_ROUTE,
        exact: true,
        component: MatchesCalendar,
      },
      {
        path: MATCH_DASHBOARD_ROUTE,
        component: MatchDashboard,
      },
      {
        path: EVENT_DASHBOARD_ROUTE,
        component: EventDashboard,
      },
      {
        path: ARTICLES_DASHBOARD_ROUTE,
        exact: true,
        component: ArticlesDashboard,
      },
      {
        path: `${ARTICLES_DASHBOARD_ROUTE}/:type(all|new)`,
        exact: true,
        component: ArticlesDashboard,
      },
      {
        path: `${ARTICLES_DASHBOARD_ROUTE}/:type(all|new)/:category`,
        component: ArticlesDashboard,
      },
      {
        path: ARTICLE_CREATE_ROUTE,
        component: ArticleCreate,
      },
      {
        path: ARTICLE_ROUTE,
        exact: true,
        component: Article,
      },
      {
        path: ARTICLE_EDIT_ROUTE,
        component: ArticleEdit,
      },
      {
        path: ARTICLES_CATEGORIES,
        component: ArticlesCategories,
      },
      {
        path: `${ARTICLES_CATEGORIES_VIEW}/:id`,
        component: ArticlesCategoriesView,
      },
      {
        path: TEAMS_ROUTE,
        exact: true,
        component: Teams,
        withoutSubscription: true,
      },
      {
        path: TEAM_EDIT_ROUTE,
        component: TeamEdit,
        withoutSubscription: true,
      },
      {
        path: TEAM_UPGRADE_ROUTE,
        component: TeamEdit,
        withoutSubscription: true,
      },
      {
        path: SEASONS_ROUTE,
        component: Seasons,
      },
      {
        path: TEAM_MENU_ROUTE,
        component: TeamMenu,
      },
      {
        path: TEAM_DASHBOARD_ROUTE,
        exact: true,
        component: TeamDashboard,
      },
      {
        path: TEAM_ACCESS_ROUTE,
        exact: true,
        component: TeamAccess,
      },
      {
        path: TEAM_ACCESS_CREATE_ROUTE,
        component: StaffForm,
      },
      {
        path: TEAM_ACCESS_EDIT_ROUTE,
        component: StaffForm,
      },
      {
        path: TEAM_PLAYER_DASHBOARD_ROUTE,
        component: PlayerDashboard,
      },
      {
        path: TEAM_PLAYER_CREATE_ROUTE,
        component: PlayerCreate,
      },
      {
        path: TEAM_PLAYER_EDIT_ROUTE,
        component: PlayerEdit,
      },
      {
        path: DRILLS_DASHBOARD_ROUTE,
        exact: true,
        component: DrillsDashboard,
      },
      {
        path: DRILL_CREATE_ROUTE,
        component: DrillCreate,
      },
      {
        path: DRILL_EDIT_ROUTE,
        component: DrillEdit,
      },
      {
        path: DRILLS_CATEGORIES,
        component: DrillsCategories,
      },
      {
        path: TRAINING_PACKAGES_DASHBOARD_ROUTE,
        exact: true,
        component: TrainingPackagesDashboard,
      },
      {
        path: TRAINING_PACKAGE_CREATE_ROUTE,
        component: TrainingPackageCreate,
      },
      {
        path: TRAINING_PACKAGE_EDIT_ROUTE,
        component: TrainingPackageEdit,
      },
      {
        path: FAVORITES_ROUTE,
        component: Favorites,
      },
      {
        path: VIDEO_ANALYSIS_ROUTE,
        exact: true,
        component: VideoAnalysis,
      },
      {
        path: VIDEO_ROUTE,
        component: Video,
      },
      {
        path: LINEUPS_DASHBOARD_ROUTE,
        exact: true,
        component: LineupsDashboard,
      },
      {
        path: LINEUP_CREATE_ROUTE,
        component: LineupCreate,
      },
      {
        path: LINEUP_EDIT_ROUTE,
        component: LineupEdit,
      },
      {
        path: DOCUMENTS_ROUTE,
        exact: true,
        component: Documents,
      },
      {
        path: DOCUMENTS_FOLDER_ROUTE,
        component: Documents,
      },
      {
        path: UPDATE_CARD_DATA,
        component: UpdateCard,
      },
      {
        path: SUBSCRIPTION_INVOICE,
        exact: true,
        component: Invoice,
        withoutSubscription: true,
      },
      {
        path: CONNECT_THIRDPARTY_ROUTE,
        component: ConnectDashboard
      },
      {
        path: TASK_MANAGEMENT_ROUTE,
        component: TaskGroups
      },
      {
        path: TASK_ROUTE,
        component: TaskGroup,
      },
    ],
    []
  )

  let isMenuHidden = false
  teams.map(team => {
    const endTime = team.endsAt ? diffDate(team.endsAt, new Date()) : 1

    const trialEnds =
      team.trialEndsAt !== null
        ? team.trialEndsAt
        : new Date(window.REACT_APP_UNTIL_DATE) - new Date()
    if (team.id === parseInt(teamId, 10)) {
      if (endTime < 0 || trialEnds < 0) {
        isMenuHidden = true
        if (
          window.location.pathname !==
            urlConstructor(teamId, seasonId, TEAMS_ROUTE) &&
          (window.location.pathname.indexOf('edit') < 0 &&
            window.location.pathname.indexOf('create') < 0 &&
            window.location.pathname.indexOf('invoice') < 0)
        ) {
          history.push(TEAMS_ROUTE)
        }
      }
    }

    return team
  })

  // set the team plan
  teams.map(team => {
    if (team.id === parseInt(teamId, 10)) {
      localStorage.setItem('plan', team.plan)
    }

    return team.plan
  })

  return (
    <TeamsLoader>
      <ClubLoader>
        <SeasonsLoader alwaysRenderChildren>
          <TeamLoader teamId={teamId} seasonId={seasonId}>
            <PlayersLoader teamId={teamId}>
              <NavBar isMenuHidden={isMenuHidden} teams={teams}>
                <Switch>
                  {routes.map(
                    ({ path, withoutSubscription = false, ...rest }) => {
                      return (
                        <Route key={path} path={parentPath + path} {...rest} />
                      )
                    }
                  )}
                  <Route component={NoMatch} />
                </Switch>
              </NavBar>
            </PlayersLoader>
          </TeamLoader>
        </SeasonsLoader>
      </ClubLoader>
    </TeamsLoader>
  )
}

RouteWithSubRoutes.propTypes = {
  history: PROP_TYPES.history.isRequired,
  match: PROP_TYPES.match.isRequired,
  teams: PropTypes.arrayOf(PropTypes.object.isRequired),
}
RouteWithSubRoutes.defaultProps = {
  teams: [],
}

export default withRouter(
  compose(
    withRouter,
    connect(({ teams }) => ({ teams: teams.items }))
  )(RouteWithSubRoutes)
)
