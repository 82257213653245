import styled from 'styled-components'

// @todo: replace with translation string
const placeholder = 'Processing...'

export default styled.div`
  &:before {
    content: '${props => props.placeholder || placeholder}';
  }

  font-size: 80%;
  ${({ theme }) => theme.flexCenter};
`
