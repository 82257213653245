/* eslint-disable no-param-reassign */
import React, { useState } from 'react'
import hasSubscription from 'utils/hasSubscription'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { connect } from 'react-redux'
import {
  Grid,
  Card,
  CardContent,
  Tabs,
  Tab,
  Divider,
  Typography,
  Button,
  IconButton,
  Dialog,
} from '@material-ui/core'
import NavigationPrompt from 'react-router-navigation-prompt'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import TeaserHeader from 'components/Teaser/TeaserHeader'
import styled from 'styled-components'
import { PlayerStatisticsWidget, SkillRatingsWidget } from 'containers'
import { PerformanceWidget, Snackbars } from 'components'
import CloseIcon from '@material-ui/icons/Close'
import { players as playersModule } from 'redux/modules'
import { createSubUrl } from 'utils/urlConstructor'

import {
  PlayerFitnessMedical,
  PlayerDevelopmentTracking,
  Sequences,
  MatchDayJournal,
  TopBar,
  PlayerSummary,
  PlayerDetails,
} from './components'
import useEditablePlayer from './useEditablePlayerHook'
import PROP_TYPES from 'constants/propTypes'
import { TEAM_DASHBOARD_ROUTE } from 'constants/routes'

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(0.5),
    color: theme.palette.grey[500],
  },
})
const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h4">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

const useStyles = makeStyles(theme => ({
  PerformanceWrap: {
    '& .performance-chart': {
      width: '100% !important',
    },
    '& .performance-widget-style': {
      paddingBottom: '0 !important',
      marginBottom: '.5rem !important',
      height: '100% !important',
    },
  },
  wBoardButton: {
    display: 'block',
    margin: '1.2rem auto',
    width: '14rem',
  },
  [theme.breakpoints.down('sm')]: {},
}))

const CardMui = styled(Card)`
  height: 100%;
`
const PlayerDashboardContainer = ({
  playerId,
  history,
  match,
  fromUrl,
  player,
  updatePlayer,
  updatePlayerAvatar,
  sharePlayer,
  deletePlayer,
  fetchTeamPlayer,
}) => {

  const [editablePlayer, changeEditablePlayer] = useEditablePlayer(player, {})
  const {
    strengths,
    weaknesses,
    development_tracking: developmentTracking,
  } = editablePlayer

  const [anyChange, setAnyChange] = useState(false)
  const [Snackbar, changeSnackbar] = React.useState({
    show: false,
    message: '',
    error: false,
    info: false,
    warning: false,
  })

  const onPlayerEdit = (fieldName, newValue) => {
    if (fieldName in editablePlayer) {
      changeEditablePlayer({ ...editablePlayer, [fieldName]: newValue })
    }
    setAnyChange(true)
  }

  const handleSave = () => {
    setAnyChange(false)
    const editablePlayerTemp = {
      ...editablePlayer,
    }

    const response = updatePlayer(
      playerId,
      editablePlayerTemp
    )
    response.then(() => {
      setAnyChange(false)
    })
    
return response
  }

  const linkBack = fromUrl || createSubUrl(match, TEAM_DASHBOARD_ROUTE)

  const handleDelete = () =>
    deletePlayer(+playerId).then(() => {
      history.push(linkBack)
    })
  const matchesPerformance = player.statistics.matches_performance.filter(
    p => p.rating > 0
  )

  const [value, setValue] = React.useState(0)
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  const classes = useStyles()

  React.useEffect(() => {}, [editablePlayer])

  return (
    <>
      <NavigationPrompt when={anyChange}>
        {({ onConfirm, onCancel }) => {
          return (
            <>
              <Dialog aria-labelledby="dialog-player" open>
                <DialogTitle id="dialog-player" onClose={onCancel}>
                  <Translate id="dialog.unsaved.title" />
                </DialogTitle>
                <DialogContent dividers>
                  <Typography variant="p" component="p">
                    <Translate id="dialog.unsaved.content-player" />
                  </Typography>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => handleSave().then(onCancel)}
                    size="large"
                    variant="contained"
                    className={classes.wBoardButton}
                    color="primary"
                  >
                    <Translate id="dialog.unsaved.save-player" />
                  </Button>
                  <Button onClick={onConfirm} size="large">
                    <Translate id="dialog.unsaved.leave" />
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          )
        }}
      </NavigationPrompt>
    
      <Grid item xs={12} md={12} lg={12}>
        {hasSubscription() !== 'elite' && <TeaserHeader />}
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <TopBar
            handleSave={handleSave}
            sharePlayer={sharePlayer}
            id={playerId}
            deletePlayer={handleDelete}
          />
        </Grid>

        <Grid item xs={12} md={12} lg={3}>
          <PlayerSummary
            player={{ ...player }}
            handleDelete={handleDelete}
            updatePlayer={updatePlayer}
            updatePlayerAvatar={updatePlayerAvatar}
            handleSave={handleSave}
            changeEditablePlayer={changeEditablePlayer}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={3}>
          <PlayerDetails player={{ ...player }} statistics={player.statistics}/>
        </Grid>
        {hasSubscription() !== 'basic' && 
        <Grid item xs={12} md={12} lg={6}>
          <CardMui>
            <Translate>
              {({ translate }) => (
                <>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    <Tab label={translate('team.main-stats')} />
                  </Tabs>
                  <Divider />
                </>
              )}
            </Translate>
            <CardContent>
              {value === 0 && (
                <PlayerStatisticsWidget statistics={player.statistics} player={{ ...player }} />
              )}
            </CardContent>
          </CardMui>
        </Grid>
        }
        
        <Grid item xs={12} md={12} lg={6}>
          <SkillRatingsWidget skills={player.skills} />
        </Grid>
        {hasSubscription() !== 'basic' && (
          <Grid item xs={12} md={12} lg={6} className={classes.PerformanceWrap}>
            <Translate>
              {({ translate }) => (
                <PerformanceWidget
                  title={translate('team.player-match-performance')}
                  match={player.statistics}
                  data={matchesPerformance}
                />
              )}
            </Translate>
          </Grid>
        )}
        {hasSubscription() !== 'basic' && hasSubscription() !== 'plus' && (
        <Grid item xs={12} md={12} lg={6}>
          <PlayerFitnessMedical
            onChange={onPlayerEdit}
            fitnessNmedicalRecords={player.fitnessNmedicalRecords}
            physicalAge={player.physique_age}
            physicalHeight={player.physique_height}
            physicalWeight={player.physique_weight}
            playerId={player.id}
            changeSnackbar={changeSnackbar}
          />
        </Grid>
        )}
        <Grid item xs={12} md={12} lg={hasSubscription() !== 'basic' && hasSubscription() !== 'plus' ? 6 : 12}>
          <PlayerDevelopmentTracking
            onChange={onPlayerEdit}
            fetchTeamPlayer={fetchTeamPlayer}
            changeSnackbar={changeSnackbar}
            player={{ ...player }}
            handleSave={handleSave}
            {...{
              development_tracking: developmentTracking,
              strengths,
              weaknesses,
            }}
          />
        </Grid>

        {hasSubscription() !== 'basic' && hasSubscription() !== 'plus' && (
          <Grid item xs={12} md={12} lg={12}>
            <Sequences sequences={player.sequences} />
          </Grid>
        )}

        {hasSubscription() !== 'basic' && (
          <Grid item xs={12} md={12} lg={12}>
            <MatchDayJournal journal={player.journal} />
          </Grid>
        )}
      </Grid>
      {Snackbar.show && <Snackbars {...Snackbar} />}
    </>
  )
}

PlayerDashboardContainer.defaultProps = {
  fromUrl: '',
  fromPathname: '',
  fetchTeamPlayer: () => 0,
}

PlayerDashboardContainer.propTypes = {
  playerId: PROP_TYPES.id.isRequired,
  first_name: PropTypes.string.isRequired,
  last_name: PropTypes.string.isRequired,
  history: PROP_TYPES.history.isRequired,
  match: PROP_TYPES.match.isRequired,
  fromUrl: PropTypes.string,
  fromPathname: PropTypes.string,
  player: PropTypes.shape().isRequired,
  updatePlayer: PropTypes.func.isRequired,
  sharePlayer: PropTypes.func.isRequired,
  deletePlayer: PropTypes.func.isRequired,
  updatePlayerAvatar: PropTypes.func.isRequired,
  fetchTeamPlayer: PropTypes.func,
}

export default connect(
  ({
    router: {
      location: { fromUrl, fromPathname },
    },
    players,
  }) => ({
    fromUrl,
    fromPathname,
    player: players.current,
  }),
  {
    updatePlayer: playersModule.updatePlayer,
    sharePlayer: playersModule.sharePlayer,
    deletePlayer: playersModule.deletePlayer,
    updatePlayerAvatar: playersModule.updatePlayerAvatar,
    fetchTeamPlayer: playersModule.fetchTeamPlayer,
  }
)(PlayerDashboardContainer)
