import React from 'react'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { DrillCreateEditForm } from 'containers'
import { drills } from 'redux/modules'
import { createSubUrl } from 'utils/urlConstructor'
import { DRILLS_DASHBOARD_ROUTE } from 'constants/routes'
import PROP_TYPES from 'constants/propTypes'

const DrillCreateContainer = ({
  history,
  match,
  location: { fromUrl, fromPathname },
  drillsCategories,
  createDrill,
}) => {
  const linkBack = fromUrl || createSubUrl(match, DRILLS_DASHBOARD_ROUTE)

  const goBack = () => history.push(linkBack)

  const onSubmit = ({
    duration,
    min_players: minPlayers,
    max_players: maxPlayers,
    images,
    ...values
  }) => {
    const data = {
      ...values,
      duration: +duration,
      min_players: +minPlayers,
      max_players: +maxPlayers,
    }

    if (images && images.length) {
      images.forEach((image, index) => {
        data[`images[${index}]`] = image
      })
    }

    createDrill(data).then(goBack)
  }

  return (
    <Translate>
      {({ translate }) => (
        <DrillCreateEditForm
          form="drillCreate"
          initialValues={{
            age_class: [],
            categories: [],
            description: '',
            duration: '',
            min_players: '',
            max_players: '',
          }}
          caption={translate('drills.create-drill')}
          fromPath={{
            value: linkBack,
            title: fromPathname || translate('drills.own-drills'),
          }}
          onSubmit={onSubmit}
          drillsCategories={drillsCategories}
        />
      )}
    </Translate>
  )
}

DrillCreateContainer.defaultProps = {
  drillsCategories: [],
}

DrillCreateContainer.propTypes = {
  history: PROP_TYPES.history.isRequired,
  match: PROP_TYPES.match.isRequired,
  location: PROP_TYPES.location.isRequired,
  createDrill: PropTypes.func.isRequired,
  drillsCategories: PROP_TYPES.arrayOfObjects,
}

export default connect(
  ({
    drills: {
      current: { drillsCategories },
    },
  }) => ({ drillsCategories }),
  {
    createDrill: drills.createDrill,
  }
)(DrillCreateContainer)
