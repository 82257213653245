import { useState, cloneElement } from 'react'

const CalendarContainer = ({ children }) => {
  const [selectable, changeSelectable] = useState(true)
  const [activePopoverId, changeActivePopoverId] = useState(null)
  const [activeView, changeActiveView] = useState('month')

  return cloneElement(children, {
    selectable,
    changeSelectable,
    activePopoverId,
    changeActivePopoverId,
    activeView,
    changeActiveView,
  })
}

export default CalendarContainer
