/* eslint-disable camelcase */
import React, { useEffect, useState, useContext } from 'react'
import { Translate } from 'react-localize-redux'
import { withRouter } from 'react-router-dom'
import { Card, DatePicker, TimePicker } from 'components'
import {
  formatDateFromServer,
  formatDateTimeToServer,
  formatCostumDateMonthNoForUI,
  getHourMinute
} from 'utils/formatDate'
import styled from 'styled-components'
import ScheduleIcon from '@material-ui/icons/Schedule'
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined'
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined'
import CloseIcon from '@material-ui/icons/Close'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import editIcon from 'assets/images/16px/edit@1x.svg'
import Description from '@material-ui/icons/Description'
import Rating from '@material-ui/lab/Rating'
import {
  DialogContent,
  InputAdornment,
  Button,
  IconButton,
  Dialog,
  Grid,
  Link,
  TextField,
  Typography
} from '@material-ui/core'

import PlacesSearchField from 'components/googlemaps/PlacesSearchField'
import { connect } from 'react-redux'
import { compose } from 'redux'
import FilterCenterFocusIcon from '@material-ui/icons/FilterCenterFocus';
import OfflineBolt from '@material-ui/icons/OfflineBolt';
import { Context } from '../TrainingStateContext'
import PROP_TYPES from 'constants/propTypes'

const TrainingInfoSection = styled.div`
  .training-info {
    color: #a6a6a6;
    margin-bottom: 0.5rem;
    font-size: 14px;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    span {
      margin-left: 1rem;
      margin-right: 0.5rem;
      svg {
        font-size: 19px;
      }
    }
    
    span:first-child {
      margin-left: 0;
    }
    span:last-child {
      margin-right: 0.3rem;
    }
    .MuiRating-root {
      position: relative;
      height: 30px;
      span {
        margin-left: 0;
        margin-right: 0;
        svg {
          font-size: 24px;
        }
      }
  }
  }
`
const SummaryCard = styled(Card)`
  padding: 1rem;
  margin-bottom: 1.5rem;
  .form-group {
    margin-bottom: 0px;
    min-height: inherit;
  }

  .date-selector {
    width: 100%;
    font-size: 16px;
    color: #888;
    border-radius: 15px;

    box-shadow: inset 0px 0 30px 2px rgba(190, 190, 190, 0.7);
    background: #fff;

    &:active,
    &:focus {
      border-color: #000000;
      box-shadow: inset 0px 0 30px 2px rgba(190, 190, 190, 0.5);
    }
  }

  .time-selector,
  .place-selector {
    margin: 0 10%;
    width: 80%;
    font-size: 16px;
    color: #888;
    border-radius: 15px;
    text-transform: capitalize;
    box-shadow: inset 0px 0 30px 2px rgba(190, 190, 190, 0.7);
    background: #fff;

    height: 40px;
    padding: 6px 12px;
    font-weight: 400;
    line-height: 1.5;

    border-color: var(--dark-silver);
    border-width: 1.2px;

    &:active,
    &:focus {
      border-color: #000000;
      box-shadow: inset 0px 0 30px 2px rgba(190, 190, 190, 0.5);
    }
  }

  .place-selector {
    text-transform: capitalize;
  }

  @media only screen and (max-width: 640px) {
    .date-selector,
    .time-selector,
    .place-selector {
      margin: 0;
      text-transform: capitalize;
      width: 100%;
    }
  }
`
const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  h4 {
    font-weight: 600;
    font-size: 16px;
    color: #062221;
    margin: 0;
  }
  span {
    color: #868686;
  }
`
const StyledDialog = styled(Dialog)`
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  .MuiDialog-paper {
    width: 100%;
    .MuiDialogContent-root {
      padding: 1rem;
    }
  }
`
const StyledDialogContent = styled(DialogContent)`
  width: 100%;
  padding: 1rem;
  .MuiRating-root {
   .MuiRating-label {
    bottom:2px;
   }
}
  .inputMultiline {
    margin-top:8px;
    .MuiInputBase-root {
      height:144px
    }
  }
  .dialog-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h4 {
      font-size: 1rem;
      font-weight: 600;
    }
    .closeDialog {
      // position: absolute;
      // top: 10px;
      // right: 10px;
      // z-index: 10;
    }
    .MuiFormControl-root {
      width: 100%;
      .MuiInputAdornment-root {
        margin-right: -12px;
      }
    }
  }
  .form-group {
    margin: 0;
    min-height: auto;
  }
  .time-selector,
  .place-selector {
    width: 100%;
    margin: 0;
    text-align: left;
  }
  .dialog-action {
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
    .MuiButtonBase-root {
      width: 47%;
    }
    .MuiButtonBase-root:first-child {
      margin-right: 1.1875rem;
    }
  }
`

const Summary = ({ handleSave, dateFormat }) => {
  const [eventData, setEventData] = useState({
    date: '',
    end_time: '',
    location: '',
    street: '',
    city: '',
    country: '',
    postalCode: '',
    lat: '',
    lng: '',
    short_address: '',
  })

  const {
    trainingState: {
      date,
      location,
      street,
      city,
      country,
      postalCode,
      lat,
      lng,
      short_address,
      training_focus: trainingFocus,
      end_time,
      intensity,
      additional_info,
    },
    onFieldChange,
    allFieldChange,
  } = useContext(Context)
  const [intens, changeIntens] = useState(intensity)

  const onChangeRating = newRating => {
    changeIntens(newRating)
    onFieldChange('intensity', newRating)
  }
  const [dateDay, time] = formatDateFromServer(date)

  const [open, setOpen] = useState(false)

  const [eventTempData, setEventTempData] = useState([])

  const handleClickOpen = () => {
    setOpen(true)
    setEventTempData(eventData)
  }

  const handleClose = () => {
    setOpen(false)
  }
  const handleCancel = () => {
    setOpen(false)
    allFieldChange(eventTempData)
    setEventData(eventTempData)
  }
  const handleTrainingFocus = (event) => {
    const {
      target: { value },
    } = { ...event }
    onFieldChange('training_focus', value)
  }
  const onDateChange = (value) => {
    setEventData({ ...eventData, date: value })
    onFieldChange('date', formatDateTimeToServer(value))
  }
  const onEndDateChange = (value) => {
    setEventData({ ...eventData, end_time: value })
    onFieldChange('end_time', formatDateTimeToServer(value))
  }
  const handleAdditionalInfo = (event) => {
    const {
      target: { value },
    } = { ...event }
    onFieldChange('additional_info', value)
  }

  useEffect(() => {
    setEventData({
      ...eventData,
      date,
      location,
      street,
      city,
      country,
      postalCode,
      lat,
      lng,
      short_address,
      trainingFocus,
      end_time,
      intensity,
      additional_info
    })
  }, [
    date,
    location,
    street,
    city,
    country,
    postalCode,
    lat,
    lng,
    short_address,
    trainingFocus,
    end_time,
    intensity,
    additional_info
  ])

  return (
    <SummaryCard>
      <CardHeader>
        <h4>
          <Translate id="training.overview" />
        </h4>
        <span>
          <IconButton onClick={handleClickOpen}>
            <img src={editIcon} alt={editIcon} />
          </IconButton>
        </span>
      </CardHeader>
      <StyledDialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <StyledDialogContent>
          <div className="dialog-header">
            <h4>
              <Translate id="button.edit-training" />
            </h4>
            <div className="closeDialog">
              <IconButton onClick={handleCancel}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </div>
          </div>
          <Grid alignItems="center">
            <Grid md={12}>
              <Grid md={12}>
                <DatePicker
                  required
                  label={<Translate id="training.date" />}
                  selectedValue={date}
                  onDateChange={onDateChange}
                  openTo="date"
                  isEditMode
                  format={dateFormat}
                />
              </Grid>
              <Grid md={12}>
                <TimePicker
                  selectedValue={date}
                  onDateChange={onDateChange}
                  label={<Translate id="training.time" />}
                  isEditMode
                />
              </Grid>
            </Grid>
            <Grid md={12}>
              <Translate>
                {({ translate }) => (
                  <PlacesSearchField
                    error={eventData.error_location}
                    helperText={
                      eventData.error_location
                        ? translate('events.validation.location')
                        : ''
                    }
                    defaultValue={eventData.location}
                    savedValue={eventData.location}
                    onFieldChange={allFieldChange}
                    changeFormData={setEventData}
                    eventData={eventData}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <LocationOnIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              </Translate>
            </Grid>
            <Grid md={12}>
              <Translate>
                {({ translate }) => (
                  <TextField
                    defaultValue={trainingFocus}
                    label={translate('form.training-focus')}
                    name="trainingFocus"
                    margin="dense"
                    variant="outlined"
                    onChange={handleTrainingFocus}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <FilterCenterFocusIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              </Translate>
            </Grid>
            <>
              <Grid md={12}>
                <TimePicker
                  selectedValue={end_time}
                  onDateChange={onEndDateChange}
                  label={<Translate id="matches.end-time" />}
                />
              </Grid>
              <Grid md={12}>
                <Translate>
                  {({ translate }) => (
                    <TextField
                      multiline
                      className='inputMultiline'
                      rows={5}
                      maxRows={5}
                      minRows={5}
                      defaultValue={additional_info}
                      label={translate('matches.additional-info')}
                      name="trainingFocus"
                      variant="outlined"
                      onChange={handleAdditionalInfo}
                    />
                  )}
                </Translate>
              </Grid>
              <Grid item xs={12}>
                <Typography component="legend" className='pt-2'>
                  <Translate id="training.intensity" />
                </Typography>
                <Rating
                  size="medium"
                  value={intens}
                  precision={0.5}
                  onChange={(e, v) => onChangeRating(v)}
                />
                {intens > 0 && (
                  <Button color="secondary" size="small" style={{ float: "right" }} variant="outlined"
                    onClick={() => onChangeRating(0)}
                  >
                    Reset <Translate id="training.intensity" />
                  </Button>
                )}

              </Grid>
            </>
            <Grid md={12}>
              <div className="dialog-action">
                <Button onClick={handleCancel} size="large" color="primary">
                  <Translate id="button.cancel" />
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => handleSave().then(handleClose)}
                >
                  <Translate id="button.save" />
                </Button>
              </div>
            </Grid>
          </Grid>
        </StyledDialogContent>
      </StyledDialog>
      <TrainingInfoSection>
        <div className="training-info">
          <Grid container spacing={1}>
            <Grid item xs={6} style={{display:"flex"}}>
              <span>
                <CalendarTodayOutlinedIcon />
              </span>
              {formatCostumDateMonthNoForUI(dateDay, dateFormat)}
            </Grid>
            <Grid item xs={6} style={{display:"flex"}}>
              <span>
                <ScheduleIcon />
              </span>
              {time}
            </Grid>
            <Grid item xs={6}>
              <span>
                <ScheduleIcon />
              </span>
              {end_time && getHourMinute(end_time)}
            </Grid>
            <Grid item xs={6}>
              <span>
                <FilterCenterFocusIcon />
              </span>
              {trainingFocus}
            </Grid>
            <Grid item xs={6}>
              <span>
                <LocationOnOutlinedIcon />
              </span>
              <Link
                href={`https://maps.google.com/?q=${lat},${lng}`}
                target="_blank"
              >
                {short_address || ''}
              </Link>
            </Grid>
            <Grid item xs={12}>
              <span>
                <Description />
              </span>
              {additional_info}
            </Grid>
            <Grid item xs={12} style={{display:"flex"}}>
              <span style={{display:"flex"}}>
                <OfflineBolt fontSize="small" style={{ color: "gray" }} /> <Translate id="training.intensity" />
              </span>
              <Rating
                size="small"
                value={intensity}
                precision={0.5}
                readOnly
              />
            </Grid>
          </Grid>
        </div>
      </TrainingInfoSection>
    </SummaryCard>
  )
}

Summary.defaultProps = {}

Summary.propTypes = {
  handleSave: PROP_TYPES.func.isRequired,
  dateFormat: PROP_TYPES.string.isRequired,
}

export default compose(
  withRouter,
  connect(
    ({ users, auth }) => ({
      users,
      dateFormat: auth.user.datetime_format,
    }),
    null
  )
)(Summary)
