import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { formateDate } from 'utils/formatDate'
import {
  CardHeader,
  LinearProgress,
  Typography,
  Button,
  List,
  ListItem,
  Divider,
  Box,
  Card,
  CardContent,
  Paper,
  Grid,
} from '@material-ui/core'
import hasSubscription from 'utils/hasSubscription'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { connect } from 'react-redux'
import eventsIcon from 'assets/images/events@1x.svg'
import { SubLink } from 'containers'
import { getClub } from 'utils/localstorage'
import ArticlesWidget from 'containers/material/ArticlesWidget'
import DrillsWidget from 'containers/material/DrillsWidget'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import EventPopup from 'components/material/Calender/eventPopup'
import { trainings, matches, generalEvents } from 'redux/modules'
import { Ticker } from 'components'
import TaskGroupsWidget from 'containers/TaskManagement/TaskGroupsWidget'
import AllPlayers from '../TeamDashboard/components/AllPlayers'
import { EVENT_ROUTE } from 'constants/routes'
import { DEFAULT_CLUB_ID } from 'constants/club'
import { TRAININGS_GAME } from 'constants/calender'
import PERMISSIONS from 'constants/permissions'
import PROP_TYPES from 'constants/propTypes'

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    '& .MuiButton-root': {
      fontWeight: 600,
    },
  },
  paper: {
    height: '100%',
  },
  sublink: {
    marginRight: '15px',
  },
  customPrimary: {},
  freeTrialCard: {
    height: '257px',
    textAlign: 'center',
  },

  LinearProgress: {
    height: '8px;',
    borderRadius: '8px',
  },
  nextEventList: {
    height: '174px',

    padding: '0 ',
    '& .MuiListItem-root': {
      margin: '10px 0',
      borderLeft: '3px solid ',
    },
    '& .MuiListItem-root:last-child': {
      marginBottom: '0',
      border: '0',
    },
  },

}))

const HomeDashboardContainers = ({
  teams: { items = [] },
  team,
  createTraining,
  createMatch,
  createEvent,
  scheduleTrainings,
  match,
  drills,
  articles,
  teamPermissions,
  history
}) => {
  // @todo: #tasks remove
  const canManageTaskGroups = teamPermissions.includes(PERMISSIONS.MANAGE_TASK_GROUPS)

  const {
    params: { teamId, seasonId },
  } = match
  const teamTrialDays = items.find(t => {
    /* eslint-disable-next-line */
    return t.id == teamId
  })

  if (teamId && !teamTrialDays) {
    history.push('/teams')
  }
  const {
    statistics,
    next_match: nextMatch,
    next_training: nextTraining,
    subscription_id: subscriptionId,
    trialEndsAt,
    players,
    age_class: ageClass,
    tickers,
  } = teamTrialDays

  const [isTrail] = React.useState(
    // add condition and data on corresponding place if you want to show some promotion when freemium version
    false
  )

  // const matchesPerformance = statistics.matches_performance

  localStorage.setItem('subscriptionId', subscriptionId)
  const classes = useStyles()
  const nextMatchDateFormate = nextMatch ? formateDate(nextMatch.date) : null
  const nextTrainingDateFormate = nextTraining
    ? formateDate(nextTraining.date)
    : null

  const [open, setOpen] = useState(false)
  const [trainingAndGame, setTrainingAndGame] = useState('')

  const handleClose = () => {
    setOpen(false)
  }

  const newCreateTraining = (data) => {
    createTraining(0, data).then((res) => {
      const { id } = {
        ...res.response.data,
      }
      handleClose()
      history.push(`/team/${+teamId}/season/${+seasonId}/trainings/${id}`)
    })
  }

  const newCreateMatch = (data) => {
    createMatch(0, data).then((res) => {
      const { id } = {
        ...res.response.data,
      }
      handleClose()
      if (hasSubscription() === 'basic') {
        history.push(`/team/${+teamId}/season/${+seasonId}/events`)
      } else {
        history.push(`/team/${+teamId}/season/${+seasonId}/matches/${id}`)
      }
    })
  }
  const newCreateEvent = (data) => {
    createEvent(0, data).then((res) => {
      const { id } = {
        ...res.response.data,
      }
      handleClose()
      if (hasSubscription() === 'basic') {
        history.push(`/team/${+teamId}/season/${+seasonId}/events`)
      } else {
        history.push(`/team/${+teamId}/season/${+seasonId}/general_events/${id}`)
      }
    })
  }
  const newScheduleTrainings = (data) => {
    scheduleTrainings(data).then(() => {
      handleClose()
      history.push(`/team/${+teamId}/season/${+seasonId}/events`)
    })
  }
  const isOwnClubArticles = articles.filter((art) => {
    return art.club_id === getClub()?.id
  })
  const isOwnClubDrills = drills.filter((drl) => {
    return drl.club_id === getClub()?.id
  })
  const splitDrills =
    getClub().id !== DEFAULT_CLUB_ID && getClub().display_coachbetter_drills
  const splitArticles =
    getClub().id !== DEFAULT_CLUB_ID && getClub().display_coachbetter_articles

  const handleEventCreate = (gameType) => {
    setTrainingAndGame(gameType)
    setOpen(true)
  }

  return (
    <>
      {open &&
        <EventPopup
          open={open}
          onClose={handleClose}
          trainingAndGame={trainingAndGame}
          createTraining={newCreateTraining}
          createMatch={newCreateMatch}
          scheduleTrainings={newScheduleTrainings}
          createEvent={newCreateEvent}
          calenderDateSelected={false}
          calenderDate={null}
          history={history}
        />
      }

      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3} xl={3}>
            <Paper className={classes.paper}>
              <CardHeader
                title={
                  <Typography variant="h4" component="h4">
                    <Translate id="home.next-event" />
                  </Typography>
                }
                action={
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={() => handleEventCreate(TRAININGS_GAME.training)}
                  >
                    <Translate id="button.plan-event" />
                  </Button>
                }
              />
              <List className={classes.nextEventList}>
                <ListItem>
                  {nextTraining ? (
                    <Box
                      width="100%"
                      display="flex"
                      justifyContent="between"
                      alignItems="center"
                      textAlign="left"
                    >
                      <Box component="div" mr={3}>
                        <Typography variant="h4" component="h4">
                          {`${nextTrainingDateFormate[5]}:${nextTrainingDateFormate[6]}`}
                        </Typography>
                        <Typography variant="h6" fontSize={14} component="h6">
                          {`${nextTrainingDateFormate[4]} ${nextTrainingDateFormate[3]}`}
                        </Typography>
                      </Box>
                      <Box component="div">
                        <SubLink to={`/trainings/${nextTraining.id}`}>
                          <Typography variant="h4" component="h4">
                            <Translate id="home.trainings" />
                          </Typography>

                          <Typography variant="caption" component="h6">
                            {`${nextTraining.short_address
                                ? nextTraining.short_address
                                : //  nextTraining.location
                                //     .charAt(0)
                                //     .toUpperCase() +
                                //   nextTraining.location.slice(1)
                                ''
                              }`}
                          </Typography>
                        </SubLink>
                      </Box>
                      <Box
                        component="div"
                        ml="auto"
                        textAlign="right"
                        color="text.primary"
                      >
                        <Typography variant="h4" component="h4">
                          {`${nextTraining.players_available}/${statistics.players_total}`}
                        </Typography>
                        <Typography variant="h6" fontSize={14} component="h6">
                          <Translate id="home.available" />{' '}
                        </Typography>
                      </Box>
                    </Box>
                  ) : (
                    <SubLink to={EVENT_ROUTE}>
                      <Box
                        width="100%"
                        mr={1}
                        display="flex"
                        alignItems="center"
                        textAlign="left"
                        onClick={() => localStorage.setItem('training', 1)}
                      >
                        <img src={eventsIcon} alt={eventsIcon} />
                        <Typography variant="p" component="p">
                          <Translate id="home.no-training-events-planned" />
                        </Typography>
                      </Box>
                    </SubLink>
                  )}
                </ListItem>
                <Divider />
                <ListItem>
                  {nextMatch ? (
                    <Box
                      width="100%"
                      display="flex"
                      justifyContent="between"
                      alignItems="center"
                      textAlign="left"
                    >
                      <Box component="div" mr={3}>
                        <Typography variant="h4" component="h4">
                          {`${nextMatchDateFormate[5]}:${nextMatchDateFormate[6]}`}
                        </Typography>
                        <Typography variant="h6" fontSize={14} component="h6">
                          {`${nextMatchDateFormate[4]} ${nextMatchDateFormate[3]}`}
                        </Typography>
                      </Box>
                      <Box component="div">
                        <SubLink
                          to={
                            hasSubscription() === 'basic'
                              ? EVENT_ROUTE
                              : `/matches/${nextMatch.id}`
                          }
                        >
                          <Typography variant="h4" component="h4">
                            {`${nextMatch.opponent
                              .charAt(0)
                              .toUpperCase()}${nextMatch.opponent.slice(1)}`}
                          </Typography>
                          <Typography variant="caption" component="h6">
                            {`${nextMatch.short_address
                                ? `${nextMatch.home === 0 ? 'Away' : 'Home'
                                } - ${nextMatch.short_address}`
                                : `${nextMatch.home === 0 ? 'Away' : 'Home'}`
                              }`}
                          </Typography>
                        </SubLink>
                      </Box>
                      <Box
                        component="div"
                        ml="auto"
                        textAlign="right"
                        color="text.primary"
                      >
                        <Typography variant="h4" component="h4">
                          {`${nextMatch.players_available}/${statistics.players_total}`}
                        </Typography>
                        <Typography variant="h6" fontSize={14} component="h6">
                          <Translate id="player.available" />{' '}
                        </Typography>
                      </Box>
                    </Box>
                  ) : (
                    <SubLink to={EVENT_ROUTE}>
                      <Box
                        width="100%"
                        mr={1}
                        display="flex"
                        alignItems="center"
                        textAlign="left"
                        onClick={() => localStorage.setItem('training', 2)}
                      >
                        <img src={eventsIcon} alt={eventsIcon} />
                        <Typography variant="p" component="p">
                          <Translate id="home.no-match-events-planned" />
                        </Typography>
                      </Box>
                    </SubLink>
                  )}
                </ListItem>
              </List>
            </Paper>
          </Grid>
          {!!team.add_players_enabled && (
            <Grid item xs={12} md={6} lg={3} xl={3}>
              <Translate>
                {({ translate }) => (
                  <AllPlayers
                    players={players.filter(player => player.status === 'ACTIVE')}
                    title={translate('team.whole-team')}
                  />
                )}
              </Translate>
            </Grid>
          )}
          {canManageTaskGroups && hasSubscription() !== 'basic' && (
            <Grid item xs={12} md={6} lg={3} xl={3}>
              <TaskGroupsWidget />
            </Grid>
          )}
          {isTrail && (
            <Grid item xs={12} md={6} lg={3} xl={3}>
              <Paper className={classes.paper}>
                <Card component="div" className={classes.freeTrialCard}>
                  <CardHeader />
                  <CardContent>
                    <Typography variant="h3">
                      {Math.round(trialEndsAt)} <Translate id="home.days" />
                    </Typography>
                    <Translate id="home.left-free-trial" />
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      flexDirection="column"
                      pt={4}
                      textAlign="right"
                    >
                      <Box minWidth={35}>
                        <Typography variant="body2" color="textSecondary">
                          - {14 - +Math.round(trialEndsAt)}{' '}
                          <Translate id="home.days" />
                        </Typography>
                      </Box>
                      <Box width="100%" mr={1}>
                        <LinearProgress
                          variant="determinate"
                          className={classes.LinearProgress}
                          value={(+Math.round(trialEndsAt) * 100) / 14}
                        />
                      </Box>
                    </Box>
                  </CardContent>
                  <Box
                    component="div"
                    display="flex"
                    borderTop={1}
                    borderColor="grey.200"
                    px={2}
                    py={2}
                    color="success.main"
                  >
                    <Divider />
                    <Box
                      component="span"
                      ml="auto"
                      onClick={() => localStorage.setItem('isBillTab', true)}
                    >
                      <SubLink to="/current-team/menu">
                        <Button size="large" fontWeight="600" color="primary">
                          <Translate id="home.upgrade-now" />
                        </Button>
                      </SubLink>
                    </Box>
                  </Box>
                </Card>
              </Paper>
            </Grid>
          )}
          {tickers && tickers.length > 0 && (
            <Grid item xs={12} md={6} lg={3} xl={3}>
              <Ticker tickers={tickers} />
            </Grid>
          )}
          {/* <Grid item xs={12} md={6} lg={3} xl={3}>
            <PolarConnect/>
        </Grid> */}
        </Grid>

        <Grid container spacing={3}>
          {splitDrills ? (
            <>
              {isOwnClubDrills.length > 0 && (
                <Grid item xs={12} md={12} lg={6} xl={6}>
                  <Paper className={classes.paper}>
                    <DrillsWidget ageClass={ageClass} clubid={getClub()?.id} />
                  </Paper>
                </Grid>
              )}
              <Grid
                item
                xs={12}
                md={12}
                lg={isOwnClubDrills.length > 0 ? 6 : 12}
                xl={isOwnClubDrills.length > 0 ? 6 : 12}
              >
                <Paper className={classes.paper}>
                  <DrillsWidget ageClass={ageClass} clubid={DEFAULT_CLUB_ID} />
                </Paper>
              </Grid>
            </>
          ) : (
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <Paper className={classes.paper}>
                <DrillsWidget ageClass={ageClass} clubid={getClub()?.id} />
              </Paper>
            </Grid>
          )}
          {splitArticles ? (
            <>
              {isOwnClubArticles.length > 0 && (
                <Grid item xs={12} md={12} lg={6} xl={6}>
                  <Paper className={classes.paper}>
                    <ArticlesWidget clubid={getClub()?.id} />
                  </Paper>
                </Grid>
              )}
              <Grid
                item
                xs={12}
                md={12}
                lg={isOwnClubArticles.length > 0 ? 6 : 12}
                xl={isOwnClubArticles.length > 0 ? 6 : 12}
              >
                <Paper className={classes.paper}>
                  <ArticlesWidget clubid={DEFAULT_CLUB_ID} />
                </Paper>
              </Grid>
            </>
          ) : (
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <Paper className={classes.paper}>
                <ArticlesWidget clubid={getClub()?.id} />
              </Paper>
            </Grid>
          )}
        </Grid>
      </div>
    </>
  )
}

HomeDashboardContainers.propTypes = {
  history: PROP_TYPES.history.isRequired,
  teams: PropTypes.shape().isRequired,
  match: PropTypes.shape().isRequired,
  createTraining: PropTypes.func.isRequired,
  createMatch: PropTypes.func.isRequired,
  scheduleTrainings: PropTypes.func.isRequired,
  createEvent: PropTypes.func.isRequired,
  teamPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  team: PropTypes.shape({
    add_players_enabled: PropTypes.bool.isRequired
  }).isRequired
}

export default compose(
  withRouter,
  connect(
    ({ auth, drills, articles, teams }) => ({
      drills: drills.items,
      articles: articles.items,
      teams,
      teamPermissions: auth.permissions.teams.find(item => item.id === teams.current.id)?.permissions ?? [],
      team: teams.current
    }),
    {
      createTraining: trainings.createTraining,
      createMatch: matches.createMatch,
      scheduleTrainings: trainings.scheduleOnlyTrainings,
      createEvent: generalEvents.createEvent,
    }
  )
)(HomeDashboardContainers)
