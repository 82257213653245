import styled from 'styled-components'
import PlayIconSVG from 'assets/images/video-play.svg'
import Fast from '../../assets/images/fast-forward.png'
import Muted from '../../assets/images/muted.png'
import PauseButton from '../../assets/images/pause-button.png'
import PlayButton from '../../assets/images/play-button.png'
import Speaker from '../../assets/images/speaker.png'

const Button = styled.div`
  font-size: 16px;
  cursor: pointer;
  margin: 0 2px;

  width: 20px;
  height: 20px;
  border: var(--dark-grey-solid) 1px;
  border-radius: 10px;

  ${({ theme }) => theme.flexCenter};
`

export const VideoButton = styled(Button)`
  color: var(--dark);
  position: relative;
  border: 1px solid var(--dark);
  .play {
    width: 12px;
    height: 12px;
    background-image: url(${PlayButton});
    background-size: 12px;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 4px;
    background-repeat: no-repeat;
  }
  .pause {
    width: 12px;
    height: 12px;
    background: url(${PauseButton});
    position: absolute;
    background-size: 12px;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 4px;
    background-repeat: no-repeat;
  }
`

export const SpeedButton = styled(Button)`
  color: var(--dark);
  border: 1px solid var(--dark);
  font-size: 12px;
  // position: relative;
  text-align: center;
  width: 36px;
  .fast {
    width: 10px;
    height: 10px;
    margin-left: 2px;
    background: url(${Fast});
    // position: absolute;
    // left: 0;
    // right: 0;
    // margin: 0 auto;
    top: 4px;
    background-size: 10px;
    background-repeat: no-repeat;
  }
`

export const MutedButton = styled(Button)`
  color: var(--dark);
  border: 1px solid var(--dark);
  position: relative;
  .speaker{
    width:14px;
    height: 14px;
    background: url(${Speaker});
    position: absolute;
    background-size: 14px;
    background-repeat: no-repeat;
    image-rendering: auto;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 3px;
}
  }
  .muted{
    width:13px;
    height: 13px;
    background: url(${Muted});
    position: absolute;
    background-size: 13px;
    background-repeat: no-repeat;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 3px;
  }
`

export const Tag = styled.div.attrs(({ positionLeft }) => ({
  style: {
    left: `${positionLeft}%`,
  },
}))`
  :before {
    content: '\\261F';
    font-size: 24px;
  }

  position: absolute;
  top: -30px;
  color: ${({ color }) => color || 'green'};

  ${({ isNewSequence }) => (isNewSequence ? 'cursor: e-resize' : '')};
`

export const PlayIcon = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -75px 0 0 -75px;
  z-index: 1;

  width: 150px;
  height: 150px;
  background: url(${PlayIconSVG}) no-repeat center;
  background-size: contain;

  cursor: pointer;
  ${({ theme }) => theme.flexCenter};

  .sequences-player & {
    width: 80px;
    height: 80px;
    margin: -40px 0 0 -40px;
  }
`

export const TagButton = styled.div`
  position: absolute;
  left: 20px;
  top: 20px;
  z-index: 1;

  font-weight: bold;

  height: 24px;
  padding: 0 5px;
  border-radius: 12px;

  cursor: pointer;
  ${({ theme }) => theme.flexCenter};
`

export const AddTag = styled(TagButton)`
  :before {
    width: 12px;
    height: 12px;
    background-color: var(--secondary-green);
    border-radius: 50% 50% 0;
    transform: rotate(45deg);
    content: '';
    margin: -5px 5px 0 0;
  }

  :after {
    content: 'Start Tag';
  }

  color: var(--secondary-green);
  border: var(--secondary-green) solid 2px;
`

export const StopTag = styled(TagButton)`
  :before {
    width: 12px;
    height: 12px;
    background-color: salmon;
    border-radius: 50% 50% 0;
    transform: rotate(45deg);
    content: '';
    margin: -5px 5px 0 0;
  }

  :after {
    content: 'Stop Tag';
  }

  color: salmon;
  background: rgba(250, 128, 114, 0.3);
  border: salmon solid 2px;
`
