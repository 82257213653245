import React from 'react'
import hasSubscription from 'utils/hasSubscription'
import { Translate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { Grid, Hidden } from '@material-ui/core'
import { RadarSkillWidget } from 'containers'
import { PerformanceWidget } from 'components'
import { teams as teamsModule } from 'redux/modules'
import AllPlayers from './components/AllPlayers'
import { TeamSummary, TeamPlayersGrid} from './components'
import PROP_TYPES from 'constants/propTypes'
import { TEAMS_ROUTE } from 'constants/routes'

const TeamDashboardContainer = ({
  history,
  team: { players, last_match: lastMatch, ...team },
  deleteTeam,
}) => {
  const handleDelete = () => {
    deleteTeam(team.id)
      .then(() => history.push(TEAMS_ROUTE))
      .catch(() => {})
  }

  const matchesPerformance = team.statistics.matches_performance

  React.useEffect(() => {}, [team])

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
        <Grid container spacing={2} direction="column">
        <Grid item xs={12} >
        <TeamSummary team={{ ...team }} handleDelete={handleDelete} />
          </Grid>
          { !!team.add_players_enabled && (
            <Hidden only={ [ 'md', 'lg', 'xl' ] }>
              <Grid item xs={ 12 }>
                <Translate>
                  { ({ translate }) => (
                    <AllPlayers
                      players={ players }
                      title={ translate('team.whole-team') }
                    />
                  ) }
                </Translate>
              </Grid>
            </Hidden>
          ) }
        <Grid item xs={12}>
          <RadarSkillWidget team={team} />
        </Grid>
         </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
        <Grid container spacing={2} direction="column">
          { !!team.add_players_enabled && (
            <Grid item xs={ 12 }>
              <TeamPlayersGrid teamPlayers={ players } teamId={ team.id }/>
            </Grid>
          ) }
        <Grid item xs={12}>
          {hasSubscription() === 'basic' ? (
            ''
          ) : (
            <Translate>
              {({ translate }) => (
                <PerformanceWidget
                  title={translate('team.match-performance')}
                  match={team}
                  isTeam
                  data={matchesPerformance}
                />
              )}
            </Translate>
          )}
        </Grid>
        </Grid>
        </Grid>
      </Grid>
    </>
  )
}

TeamDashboardContainer.propTypes = {
  history: PROP_TYPES.history.isRequired,
  deleteTeam: PROP_TYPES.func.isRequired,
  team: PROP_TYPES.shape({
    players: PROP_TYPES.arrayOfObjects,
    last_match: PROP_TYPES.shape().isRequired,
  }).isRequired,
}

export default connect(
  ({ teams }) => ({
    team: teams.current,
  }),
  {
    deleteTeam: teamsModule.deleteTeam,
  }
)(TeamDashboardContainer)
