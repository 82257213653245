import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { SubLink } from 'containers'
import { DataGrid } from '@mui/x-data-grid'
import DataGridExportToolbar from 'components/DataGridExportToolbar'
import { Avatar, Player } from './styledComponents'
import { PLAYER_POSITIONS } from 'constants/player'
import { TEAM_DASHBOARD_ROUTE } from 'constants/routes'

const SkillTable = ({team: {skills_avg:skills }, players, updatePlayer}) => {
  const [rows, setRows] = React.useState([...players])
  const [skillHeaders, setSkillHeaders] = React.useState([])
  const headersTemp = [...skillHeaders]
  const avatarImage = (params) => (
    <Player
      component={SubLink}
      to={`${TEAM_DASHBOARD_ROUTE}/player/${
        params.row.id
      }/overview`}
    >
      {params.row.avatar?.full_url !== null ? (
        <Avatar
          alt={params.row.first_name}
          src={params.row.avatar?.full_url}
        />
      ) : (
        <Avatar alt={params.row.first_name} />
      )}
      <div>
        <Typography variant="h4">
          {' '}
          {`${params.row.first_name} ${params.row.last_name}`}
        </Typography>
        <Typography variant="subtitle2" noWrap>
          {
            PLAYER_POSITIONS.find(
              p => p.value === params.row.position
            ).title
          }
        </Typography>
      </div>
    </Player>
  );

  const column = [{
      field: 'id',
      headerName: 'ID',
      headerAlign: "left",
      headerClassName: 'primary',
      align: "left",
      width: 120,
      hide:true,
      editable: true,
  },{
    field: 'avatar',
    headerName: 'Player',
    width: 300,
    renderCell: avatarImage,
    editable: false,
    sortable: false,
    headerAlign: "left",
    align: "left",
  },{
    field: 'first_name',
    headerName: 'First Name',
    width: 300,
    editable: false,
    sortable: false,
    headerAlign: "left",
    align: "left",
    hide:true
  },{
    field: 'last_name',
    headerName: 'Last Name',
    width: 300,
    editable: false,
    sortable: false,
    headerAlign: "left",
    align: "left",
    hide:true
  }];
  const skillsColumns = skillHeaders.map( head =>{
    return {
    field: head.name ? head.name : head.id,
    headerName: head.name ? head.name : head.id(),
    headerAlign: "left",
    headerClassName: 'primary',
    align: "left",
    width: 150,
    type: 'number',
    editable: true,
  }});
  const handleEditRowsModelChange = React.useCallback((model) => {
    const updatedSkill = skills.filter(skill => skill.name ===model.field)[0]
    const updateData = [{
      "team_skill_id":updatedSkill.id,
      "value":model.value}]
    updatePlayer(model.id, {skills:updateData}).then(res => {
      return res
    })
  }, []);
  useEffect(() => {
    skills.forEach(skill => {
      headersTemp.push({
        id: skill.id,
        name: `${skill.name}`,
        disablePadding: false,
        numeric: true,
      })
    })
    const headRowsTemp = [...rows]
    headRowsTemp.forEach(player => {
      player.skills?.map(skill => {
        // eslint-disable-next-line no-param-reassign
        player[skill.name] = skill.value

        return player
      })
    })
    setSkillHeaders([...headersTemp])
    setRows([...headRowsTemp])
  }, [])
  const skillsFieldsName = skillsColumns.map(skl=>{return skl.field})
  skillsFieldsName.unshift('first_name', 'last_name')

  return (
    <div style={{ height: "auto", width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={[...column, ...skillsColumns]}
        pageSize={100}
        rowsPerPageOptions={[5]}
        checkboxSelection={false}
        disableSelectionOnClick
        disableColumnFilter
        disableColumnMenu
        autoHeight
        onCellEditCommit={(model) => handleEditRowsModelChange(model)}
        components={{
          Toolbar: DataGridExportToolbar,
        }}
        componentsProps={{ toolbar: { csvOptions: { fields: skillsFieldsName } } }}
        initialState={{
          sorting: {
            sortModel: [{ field: 'first_name', sort: 'asc' }],
          },
        }}
      />
    </div>
  )
}

SkillTable.defaultProps = {}

SkillTable.propTypes = {
  team: PropTypes.shape().isRequired,
  players: PropTypes.shape().isRequired,
  updatePlayer: PropTypes.shape().isRequired
}

export default SkillTable
