import { Translate } from 'react-localize-redux';
import { makeStyles } from '@material-ui/core/styles';

import { SubLink } from 'containers';

import {
  Button,
  Card,
  CardContent,
  Typography
} from '@material-ui/core';
import { CONNECT_THIRDPARTY_ROUTE } from 'constants/routes'



const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    height: 253,
    paddingTop: theme.spacing(0),
    backgroundColor: theme.palette.background.paper,
    '& .MuiTypography-root': {
      color: 'gray',
      marginBottom: '1rem',
      textAlign: 'center',
      [theme.breakpoints.only('md')]:{
        fontSize: theme.typography.pxToRem(13),
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(12),
      },
    },
  },
  button: {
    width: '14rem'
  }
}))

export default function PolarConnect({}) {
  const classes = useStyles()

  return (
    <Card>
      <CardContent className={classes.root}>
        <Typography variant="p" component="body1">
          <div> <Translate id="polar.3rd-party-title" /></div>
        </Typography>
        <SubLink to={CONNECT_THIRDPARTY_ROUTE}>
          <Button
            className={classes.button}
            fullWidth="false"
            variant="contained"
            size="large"
            color="primary"
          >
            <Translate id="polar.button.connect-btn"/>
          </Button>
        </SubLink>
      </CardContent>
    </Card>

  )
}
