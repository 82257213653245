import React, { useState } from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { OverlayTrigger, Popover, Button } from 'react-bootstrap'
import { Input, Select } from 'components'
import { trainings } from 'redux/modules'
import PROP_TYPES from 'constants/propTypes'

const TYPE_OPTIONS = [
  {
    value: 'all',
    title: 'All',
  },
  {
    value: 'goalkeeper',
    title: 'Goalkeeper Training',
  },
]

const WeekLabel = styled.div`
  cursor: pointer;
  width: fit-content;
`

const Overlay = (
  trainingState,
  changeTrainingState,
  dayOfWeekLabel,
  handleSave
) => {
  const { time, location, type } = trainingState

  const onFieldChange = fieldName => newValue =>
    changeTrainingState({ ...trainingState, [fieldName]: newValue })

  const onChange = fieldName => ({ target: { value } }) =>
    onFieldChange(fieldName)(value)

  return (
    <Popover id="schedule-trainings" className="pt-2">
      <Input
        input={{ defaultValue: dayOfWeekLabel, readOnly: true }}
        label="Weekday"
      />
      <Input
        input={{ value: time, onChange: onChange('time') }}
        type="time"
        label="Time"
      />
      <Input
        input={{
          value: location,
          onChange: onChange('location'),
        }}
        label="Location"
      />
      <Select
        value={type}
        onChange={onFieldChange('type')}
        label="Type"
        options={TYPE_OPTIONS}
      />
      <div className="d-flex justify-content-end pt-2">
        <Button size="sm" onClick={handleSave}>
          Ok
        </Button>
      </div>
    </Popover>
  )
}

const TrainingHeader = ({ date, label, scheduleTrainings }) => {
  const [overlayRef, changeOverlayRef] = useState({})

  const [trainingState, changeTrainingState] = useState({
    day_of_week: +moment(date).format('d'),
    time: '00:00',
    location: '',
    type: 'all',
  })

  const dayOfWeekLabel = moment(date).format('dddd')

  const handleSave = () => {
    scheduleTrainings({
      ...trainingState,
      time: `${trainingState.time}:00`,
    }).then(() => overlayRef.hide())
  }

  const ref = overlay => {
    changeOverlayRef(overlay)
  }

  return (
    <OverlayTrigger
      ref={ref}
      rootClose
      trigger="click"
      placement="bottom"
      overlay={Overlay(
        trainingState,
        changeTrainingState,
        dayOfWeekLabel,
        handleSave
      )}
    >
      <WeekLabel>{label}</WeekLabel>
    </OverlayTrigger>
  )
}

TrainingHeader.propTypes = {
  date: PROP_TYPES.date.isRequired,
  label: PROP_TYPES.string.isRequired,
  scheduleTrainings: PROP_TYPES.func.isRequired,
}

export default connect(
  null,
  { scheduleTrainings: trainings.scheduleTrainings }
)(TrainingHeader)
