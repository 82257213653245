import React, { useState, useEffect } from 'react'
import hasSubscription from 'utils/hasSubscription'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import { Translate } from 'react-localize-redux'
import styled from 'styled-components'
import {
  Modal,
  InputLikeButton,
  OwnGrid,
  Button,
  BorderedGreenButton,
  ActionWithPrompt,
  TextArea,
  ReactSelect,
} from 'components'
import SequencesImage from 'assets/images/video-sequences.svg'
import { videos } from 'redux/modules'
import filterObject from 'utils/filterObject'
import VideoPlayer from './VideoPlayer'
import SEQUENCE_TAGS from 'constants/sequenceTags'
import { Grid } from '@material-ui/core'

const StyledInput = styled(InputLikeButton)`
  width: 100%;
  margin: 0;
`

const Actions = styled(OwnGrid.Col)`
  display: flex;
  justify-content: end;
  align-items: flex-end;
`

const SequenceModal = ({
  sequence,
  onHide,
  createVideoSequence,
  removeNewVideoSequence,
  updateSequence,
  deleteSequence,
  players: availablePlayers,
  matches: availableMatches,
  open,
}) => {
  const [sequenceState, changeSequenceState] = useState({})
  // for matches suggestions
  let matchesSuggestions = []
  if (availableMatches) {
    matchesSuggestions = availableMatches
      .filter(match => match.status === 'published')
      .map(match => ({
        value: match.id,
        label: `@VS ${match.opponent} | at ${match.location} | on ${
          match.date
        }`,
      }))
  } else {
    matchesSuggestions = []
  }
  const playersSuggestions = availablePlayers
    .filter(player => player.first_name !== null || player.last_name !== null)
    .map(player => ({
      value: player.id,
      label: `@${player.first_name} ${player.last_name}`,
    }))

  useEffect(() => {
    changeSequenceState({
      ...sequence,
      title: sequence.title || '',
      notes: sequence.notes || '',
      tag: sequence.tag || 'others',
      players: playersSuggestions.filter(({ value }) =>
        (sequence.players || []).includes(value)
      ),
      matches: matchesSuggestions.filter(({ value }) =>
        (sequence.matches || []).includes(value)
      ),
    })
  }, [sequence])

  const {
    id,
    title,
    video_url: videoUrl,
    video_id: videoId,
    from,
    to,
    tag,
    duration,
    notes,
    players,
    matches,
  } = sequenceState

  const handleHide = () => {
    onHide()

    if (id === 'new') {
      removeNewVideoSequence()
    }
  }

  const handleSave = () => {
    createVideoSequence(videoId, {
      video_id: videoId,
      from: Math.floor(from),
      to: Math.floor(to),
      title,
      notes,
      tag,
      players: players.map(({ value }) => value),
      matches: matches.map(({ value }) => value),
    })
      .then(() => {
        handleHide()
        window.location.reload()
      })
      .catch(({ errors }) => {
        Object.entries(errors).forEach(([, value]) => {
          const errorMessage = value.find(Boolean) || ''

          toast(errorMessage)
        })
      })
  }

  const handleUpdate = () => {
    updateSequence(
      id,
      filterObject(
        {
          title,
          notes,
          tag,
          players: players ? players.map(({ value }) => value) : [],
          matches: matches ? matches.map(({ value }) => value) : [],
        },
        Boolean
      )
    ).then(handleHide)
  }

  const handleDelete = () => {
    deleteSequence(id).then(handleHide)
  }

  const onChangeTitle = ({ target: { value } }) =>
    changeSequenceState({ ...sequenceState, title: value })

  const onChangeNotes = ({ target: { value } }) =>
    changeSequenceState({ ...sequenceState, notes: value })

  const onChangeTag = newTag =>
    changeSequenceState({ ...sequenceState, tag: newTag })

  const onChangePlayers = (newPlayers, { option }) => {
    if (option && option.value === 'everyOne') {
      return changeSequenceState({
        ...sequenceState,
        players: playersSuggestions,
      })
    }

    return changeSequenceState({ ...sequenceState, players: newPlayers })
  }
  // for matches onchange
  const onChangeMatches = (newMatches, { option }) => {
    if (option && option.value === 'everyOne') {
      return changeSequenceState({
        ...sequenceState,
        matches: matchesSuggestions,
      })
    }

    return changeSequenceState({ ...sequenceState, matches: newMatches })
  }

  const updateFromTo = ({ start, end }) => {
    changeSequenceState({
      ...sequenceState,
      from: (start / 100) * duration,
      to: (end / 100) * duration,
    })
  }

  return (
    <Translate>
    {({ translate }) => (
      <Modal
        title={translate('videos.sequence')}
        onHide={handleHide}
        icon={SequencesImage}
        open={open}
      >
        <Grid container spacing={2}>
          <Grid item md={8}>
            <StyledInput
              value={title || ''}
              onChange={onChangeTitle}
              placeholder={translate('videos.name')}
              style={{ padding: '.7rem' }}
            />
          </Grid>
          {hasSubscription() === 'basic' || hasSubscription() === 'plus' ? (
            ''
          ) : (
            <Grid item md={4}>
              <ReactSelect
                value={players}
                suggestions={playersSuggestions}
                onChange={onChangePlayers}
                placeholder={translate('videos.tag-person')}
                isEveryOne
              />
              <ReactSelect
                value={matches}
                suggestions={matchesSuggestions}
                onChange={onChangeMatches}
                placeholder={translate('videos.tag-match')}
                isEveryOne
              />
            </Grid>
          )}
        </Grid>
        <Grid container className="padding-vertical-15 sequences-player" spacing={2}>
          <Grid item md={8}>
            {id && (
              <VideoPlayer
                video={{ id, url: videoUrl, from, to, duration }}
                isSequence
                updateFromTo={updateFromTo}
              />
            )}
          </Grid>
          <Grid item md={4}>
            <TextArea
              value={notes || ''}
              onChange={onChangeNotes}
              placeholder={translate('videos.notes')}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={8} className="d-flex flex-wrap" style={{ display: 'flex', flexWrap: 'wrap' }}>
            <Translate id="videos.tags" />
            {SEQUENCE_TAGS.map(({ value, title: tagTitle, background, color }) => (
              <BorderedGreenButton
                key={value}
                background={background}
                color={color}
                isActive={tag === value}
                onClick={() => onChangeTag(value)}
              >
                {tagTitle}
              </BorderedGreenButton>
            ))}
          </Grid>
          <Grid item md={4}>
            <Actions>
                <ActionWithPrompt
                  className="flex-grow-1 text-danger border-danger"
                  onSuccess={id === 'new' ? handleHide : handleDelete}
                  message="Are you sure you want to delete the Sequence?"
                  title={id === 'new' ? translate('button.cancel') : translate('button.delete')}
                  style={{flexGrow: 1}}

                />
                <Button
                  block
                  success
                  className="flex-grow-1"
                  style={{flexGrow: 1}}
                  onClick={id === 'new' ? handleSave : handleUpdate}
                >
                  <Translate id="button.save" />
                </Button>
            </Actions>
          </Grid>

        </Grid>
      </Modal>
    )}
  </Translate>
  
  )
}

SequenceModal.defaultProps = {
  players: [],
  matches: [],
}

SequenceModal.propTypes = {
  sequence: PropTypes.shape().isRequired,
  onHide: PropTypes.func.isRequired,
  createVideoSequence: PropTypes.func.isRequired,
  removeNewVideoSequence: PropTypes.func.isRequired,
  updateSequence: PropTypes.func.isRequired,
  deleteSequence: PropTypes.func.isRequired,
  players: PropTypes.arrayOf(PropTypes.object.isRequired),
  matches: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool.isRequired,
}

export default connect(
  null,
  {
    createVideoSequence: videos.createVideoSequence,
    removeNewVideoSequence: videos.removeNewVideoSequence,
  }
)(SequenceModal)
