import styled from 'styled-components'

export default styled.div`
  :before {
    content: '\\2715';
  }

  font-size: 16px;

  width: 20px;
  height: 20px;

  ${({ theme }) => theme.flexCenter};
`
