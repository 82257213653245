import React from 'react'
import { TrainingPackageLoader, AgeClassesLoader } from 'loaders'
import TrainingPackageEditContainer from './TrainingPackageEditContainer'
import PROP_TYPES from 'constants/propTypes'

const TrainingPackageEdit = ({
  history,
  match: {
    params: { trainingPackageId },
  },
  match,
  location,
}) => (
  <TrainingPackageLoader
    trainingPackageId={trainingPackageId}
    additionalFields={['drills']}
  >
    <AgeClassesLoader>
      <TrainingPackageEditContainer
        history={history}
        match={match}
        location={location}
      />
    </AgeClassesLoader>
  </TrainingPackageLoader>
)

TrainingPackageEdit.propTypes = {
  history: PROP_TYPES.history.isRequired,
  match: PROP_TYPES.match.isRequired,
  location: PROP_TYPES.location.isRequired,
}

export default TrainingPackageEdit
