import React from 'react'
import { Translate } from 'react-localize-redux'

export default [
  {
    value: 'wing-play',
    title: <Translate id="tagsequence.wing-play" />,
    background: '#8cba80',
    color: 'white',
  },
  {
    value: 'transition-play',
    title: <Translate id="tagsequence.transition-play" />,
    background: '#658e9c',
    color: 'white',
  },
  {
    value: 'loss-of-possession',
    title: <Translate id="tagsequence.loss-of-possession" />,
    background: '#4d5382',
    color: 'white',
  },
  {
    value: 'pressing',
    title: <Translate id="tagsequence.pressing" />,
    background: '#514663',
    color: 'white',
  },
  {
    value: 'shifting',
    title: <Translate id="tagsequence.shifting" />,
    background: '#e0d784',
    color: 'white',
  },
  {
    value: 'build-up-play',
    title: <Translate id="tagsequence.build-up-play" />,
    background: '#79addc',
    color: 'white',
  },
  {
    value: 'duels',
    title: <Translate id="tagsequence.duels" />,
    background: '#2a3b6d',
    color: 'white',
  },
  {
    value: 'defending',
    title: <Translate id="tagsequence.defending" />,
    background: '#ffc09f',
    color: 'white',
  },
  {
    value: 'creating-chances',
    title: <Translate id="tagsequence.creating-chances" />,
    background: '#a8d0c1',
    color: 'white',
  },
  {
    value: 'pre-orientation',
    title: <Translate id="tagsequence.pre-orientation" />,
    background: '#ff005a',
    color: 'white',
  },
  {
    value: 'set-pieces',
    title: <Translate id="tagsequence.set-pieces" />,
    background: '#0e7c7b',
    color: 'white',
  },
  {
    value: 'others',
    title: <Translate id="tagsequence.others" />,
    background: '#4b1d3f',
    color: 'white',
  },
]
