import React, { useContext, useState } from 'react'
import PROP_TYPES from 'prop-types'
import { Translate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { makeStyles, createTheme } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'
import { matches } from 'redux/modules'
import styled from 'styled-components'
import {
  Button,
  IconButton,
  Paper,
  Tooltip,
  Grid,
  Hidden,
} from '@material-ui/core'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import { ShareMuiIcon, SubLink } from 'containers'
import EventPopup from 'components/material/Calender/eventPopup'
import { Context } from '../MatchStateContext'
import MiniCalendarWithActions from './MiniCalendarWithActions'
import { TRAININGS_GAME } from 'constants/calender'

const theme = createTheme({})
const useStyles = makeStyles({
  paper: {
    width: '100%',
    display: 'flex',
    padding: '.6rem',
  },
  Cancel: {
    margin: '0 .8rem',
  },
  PlayerNum: {
    margin: '0 .8rem',
    '& img': {
      marginRight: '.6rem',
    },
    '& :hover': {
      backgroundColor: 'transparent',
    },
  },
  newSessionDrill: {
    backgroundColor: 'white',
    padding: '0 1rem',
    borderRadius: '6px',
  },
  [theme.breakpoints.down('sm')]: {
    paper: {
      display: 'flex',
      flexDirection: 'column',
    },
    Cancel: {
      padding: '8px 28px',
    },
    Save: {
      padding: '8px 28px',
    },
    MobileSectionDialog: {
      '& .MuiDialog-paperWidthSm': {
        width: '100%',
      },
    },
    DialogClose: {
      position: 'absolute',
      top: '4px',
      right: '52px',
      zIndex: '10',
    },
    MobileSectionDialogBtn: {
      textAlign: 'center',
      display: 'flex',
      height: '4.375rem',
      position: 'fixed',
      boxShadow: '14px 5px 35px 0 rgba(0,0,0,0.08)',
      padding: '0.875rem 1.125rem',
      bottom: '0',
      left: '0',
      borderTop: '1px solid var( --light-grey)',
      background: 'var(--white)',
      width: '100%',
      zIndex: '10',
      '& a': {
        width: '50%',
      },
      '& .MuiButton-root': {
        width: '100%',
      },
    },
  },
})

const CustomheadRight = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  .MuiDialog-paperWidthSm {
    width: 100%;
  }
  @media (max-width: 640px) {
    position: fixed;
    bottom: 0;
    left: 0;
    background: #fff;
    padding: 0.7rem;
    z-index: 9;
    justify-content: center;
    box-shadow: 14px 5px 35px 0 rgba(0, 0, 0, 0.08);
  }
`

const TopBar = ({
  handleSave,
  setLoading,
  createMatch,
  deleteMatch,
  shareMatch,
  history,
  match: { params },
}) => {
  const classes = useStyles()
  const { teamId, seasonId, matchId } = params

  const { matchState } = useContext(Context)
  const [open, setOpen] = useState(false)
  const [trainingAndGame, setTrainingAndGame] = useState('')
  const [isAlertBox, changeAlertBox] = useState(false)
  const [eventDelete, changeDeleteEvent] = useState(0)
  const [duplicateData, changeDuplicateData] = useState(null)
  const [isDuplicate, setIsDuplicate] = React.useState(false)

  const handleClose = () => {
    setOpen(false)
    changeAlertBox(false)
    changeDuplicateData(null)
    setIsDuplicate(false)
  }
  const handleMatchSave = () => {
    setLoading(true)
    handleSave()
  }
  const handleOpen = (text = '') => {
    setTrainingAndGame(text)
    setOpen(true)
  }

  const newCreateMatch = data => {
    createMatch(0, data).then(res => {
      const { id } = {
        ...res.response.data,
      }
      handleClose()
      history.push(`/team/${+teamId}/season/${+seasonId}/matches/${id}`)
      window.location.reload()
    })
  }

  const onOK = () => {
    deleteMatch(eventDelete).then(() => {
      history.push(`/team/${+teamId}/season/${+seasonId}/events`)
      handleClose()
    })
  }

  const openAlertBox = id => {
    changeAlertBox(true)
    changeDeleteEvent(id)
    handleOpen()
  }

  const openDuplicateBox = () => {
    changeDuplicateData(matchState)
    handleOpen(TRAININGS_GAME.game)
    setIsDuplicate(true)
  }

  return (
    <>
      {open &&
          <EventPopup
            open={open}
            onClose={handleClose}
            onOK={onOK}
            duplicateData={duplicateData}
            isAlertBox={isAlertBox}
            trainingAndGame={trainingAndGame}
            createTraining={() => 0}
            createMatch={newCreateMatch}
            calenderDateSelected={false}
            calenderDate={null}
            history={history}
            selectedEventType={trainingAndGame}
            isDuplicate={isDuplicate}
          />
      }
      <Paper className={classes.paper}>
        <Grid item lg={6} md={6} sm={12} xs={12} align="left" display="flex">
          <MiniCalendarWithActions
            // lineups={lineups}
            handleSave={handleSave}
          />
        </Grid>

        <Grid item lg={6} md={6} sm={12} xs={12} align="right" display="flex">
          <CustomheadRight>
            <Tooltip title="Share" placement="top-start" arrow>
              <ShareMuiIcon
                share={shareMatch}
                id={+matchId}
                shareBy="Match"
                className="share-button"
                placement="top-start"
              />
            </Tooltip>

            <Hidden only={['xs', 'sm']}>
              <Tooltip title="Duplicate" placement="top" arrow>
                <IconButton onClick={openDuplicateBox}>
                  <FileCopyIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title="Delete" placement="top" arrow>
                <IconButton onClick={() => openAlertBox(+matchId)}>
                  <DeleteOutlineIcon />
                </IconButton>
              </Tooltip>
            </Hidden>

            <SubLink
              to={`/team/${+teamId}/season/${+seasonId}/events`}
              isFullPath
            >
              <Button color="primary" size="large" className={classes.Cancel}>
                <Translate id="button.cancel" />
              </Button>
            </SubLink>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={handleMatchSave}
              className={classes.Save}
            >
              <Translate id="button.save" />
            </Button>
          </CustomheadRight>
        </Grid>
      </Paper>
    </>
  )
}

TopBar.defaultProps = {}

TopBar.propTypes = {
  handleSave: PROP_TYPES.func.isRequired,
  setLoading: PROP_TYPES.bool.isRequired,
  createMatch: PROP_TYPES.func.isRequired,
  deleteMatch: PROP_TYPES.func.isRequired,
  shareMatch: PROP_TYPES.func.isRequired,
  history: PROP_TYPES.shape().isRequired,
  match: PROP_TYPES.shape().isRequired,
}

export default compose(
  connect(
    /* ({
      matches: {
        current: { players, lineups, sequences },
      },
    }) => ({
      players,
      lineups,
      sequences,
    }) */ null,
    {
      createMatch: matches.createMatch,
      deleteMatch: matches.deleteMatch,
      shareMatch: matches.shareMatch,
    }
  ),
  withRouter
)(TopBar)
