import React from 'react'
import withDndContext from 'withDndContext'
import { Box, Grid, Hidden } from '@material-ui/core'
import { Ticker } from 'components'
import { LeftPart, EventInfo, TopBar } from './components'
import PROP_TYPES from 'constants/propTypes'

const EventDashboardLayout = ({ onDrop, handleSave, tickers, eventId }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12} lg={12}>
        <TopBar handleSave={handleSave} />
        <Hidden only={['lg', 'xl']}>
          <EventInfo />
        </Hidden>
      </Grid>
      <Grid item xs={12} md={12} lg={9}>
        <LeftPart onDrop={onDrop} handleSave={handleSave} eventId={eventId} />
      </Grid>

      <Grid item xs={12} md={12} lg={3}>
        <Hidden only={['xs', 'sm', 'md']}>
          <EventInfo />
        </Hidden>
        {tickers && tickers.length > 0 && (
          <Box style={{marginTop: '0.20rem'}}>
            <Ticker tickers={tickers} />
          </Box>
        )}
      </Grid>
    </Grid>
  )
}

EventDashboardLayout.propTypes = {
  onDrop: PROP_TYPES.func.isRequired,
  handleSave: PROP_TYPES.func.isRequired,
  tickers: PROP_TYPES.arrayOfObjects.isRequired,
  eventId: PROP_TYPES.number.isRequired,
}

export default withDndContext(EventDashboardLayout)
