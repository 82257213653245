import { getLocale, getUserId } from 'utils/localstorage'

export const track = (event, data) => {
  if (!event || event === '') {
    throw new Error('Event cant be blank')
  }
  window.analytics.track(event, {
    ...data,
    language: getLocale(),
  })
}

export const page = () => {}

export const identify = (data = {}) => {
  window.analytics.identify(getUserId(), { ...data })
}
