import React from "react";
import PropTypes from 'prop-types'
import {
  TableHead,
  TableCell,
  TableRow,
  TableSortLabel,
} from '@material-ui/core'
import { StickyTableCell } from './styled'
import { ATTENDANCE_COLUMNS } from 'constants/player'

const EnhancedTableHead = props => {
  const { classes, order, orderBy, onRequestSort, headCells, isSticky } = props
  const createSortHandler = property => event => {
    onRequestSort(event, property)
  }
  const StickyColumns = ATTENDANCE_COLUMNS

  return (
    <TableHead>
      <TableRow>
        {isSticky && (
          <StickyTableCell className={classes.headCellFs}>
            {StickyColumns.map(StickyColumn => (
              <TableCell
                key={StickyColumn.id}
                className={classes.head}
                align={StickyColumn.numeric ? 'left' : 'left'}
                sortDirection={orderBy === StickyColumn.id ? order : false}
              >
                {orderBy.includes(StickyColumn.id) ? <TableSortLabel
                  active={orderBy === StickyColumn.id}
                  direction={orderBy === StickyColumn.id ? order : 'asc'}
                  onClick={createSortHandler(StickyColumn.id)}
                >
                  {StickyColumn.label}
                  {orderBy === StickyColumn.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel> : StickyColumn.label
                }
              </TableCell>
            ))}
          </StickyTableCell>

        )}

        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            className={classes.head}
            align={headCell.numeric ? 'left' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {orderBy.includes(headCell.id) ? <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel> : headCell.label
            }
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.shape().isRequired,
  headCells: PropTypes.shape().isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.shape().isRequired,
  isSticky: PropTypes.bool.isRequired
}

export default EnhancedTableHead
