import React, { useState } from 'react'
import { Button, Input } from 'components'
import Avatar from '@material-ui/core/Avatar'
import AddPhotoAlternateTwoToneIcon from '@material-ui/icons/AddPhotoAlternateTwoTone'
import PROP_TYPES from 'constants/propTypes'

const getTargetTranslationTitle = (translations, activeLocale) =>
  translations.findEndGetProp(({ locale }) => locale === activeLocale, 'title')

const CategoryForManageCategories = ({
  depth,
  category,
  activeLocale,
  updateCategory,
  deleteCategory,
  disableChange,
}) => {

  // @todo: #roles temporary always true because non-root users should not have access to this component/route
  const can = true

  const [title, changeTitle] = useState(
    can
      ? getTargetTranslationTitle(category.translations, activeLocale)
      : category.title
  )

  const onChange = ({ target: { value } }) => changeTitle(value)
  const [image, changeImage] = useState(null)
  const onFileChange = event => {
    changeImage(event.target.files[0])
  }

  return (
    <tr>
      {depth > 0 && <td>{'=>'}</td>}
      {depth > 1 && <td>{'=>'}</td>}
      <td colSpan={3 - depth}>
        <Avatar
          variant="square"
          src={
            typeof category.media !== 'undefined' && category.media.length
              ? category.media[0].full_url
              : ''
          }
        />
        <Input
          input={{
            value: title,
            onChange,
          }}
          meta={{
            touched: true,
            error: !title && 'Required',
          }}
          placeholder={category.title}
        />
      </td>
      <td>
        {!disableChange && (
          <>
            {title && (
              <>
                {/* eslint-disable-next-line */}
                <label htmlFor={`image_${category.id}`}>
                  <AddPhotoAlternateTwoToneIcon fontSize="large" />
                </label>
                <input
                  style={{ display: 'none' }}
                  id={`image_${category.id}`}
                  type="file"
                  onChange={onFileChange}
                />
                <Button
                  onClick={() =>
                    updateCategory(category.id, {
                      title,
                      locale: activeLocale,
                      image,
                    })
                  }
                >
                  Update
                </Button>
              </>
            )}
            <Button onClick={() => deleteCategory(category.id)}>Delete</Button>
          </>
        )}
      </td>
    </tr>
  )
}

CategoryForManageCategories.defaultProps = {
  disableChange: false,
}

CategoryForManageCategories.propTypes = {
  depth: PROP_TYPES.number.isRequired,
  category: PROP_TYPES.shape().isRequired,
  activeLocale: PROP_TYPES.string.isRequired,
  updateCategory: PROP_TYPES.func.isRequired,
  deleteCategory: PROP_TYPES.func.isRequired,
  disableChange: PROP_TYPES.bool,
}

const NewCategory = ({ depth, addNewCategory }) => {
  const [title, changeTitle] = useState('')
  const [image, changeImage] = useState(null)
  const onFileChange = event => {
    changeImage(event.target.files[0])
  }
  const onChange = ({ target: { value } }) => changeTitle(value)
  const random = (Math.random() + 1).toString(36).substring(7)

  return (
    <tr>
      {depth > 0 && <td>{'=>'}</td>}
      {depth > 1 && <td>{'=>'}</td>}
      <td colSpan={3 - depth}>
        <Input input={{ value: title, onChange }} />
      </td>
      <td>
        {title && (
          <>
            {/* eslint-disable-next-line */}
            <label htmlFor={`image_${random}`}>
              <AddPhotoAlternateTwoToneIcon fontSize="large" />
            </label>
            <input
              style={{ display: 'none' }}
              id={`image_${random}`}
              type="file"
              onChange={onFileChange}
            />
            <Button onClick={() => addNewCategory(title, image)} secondary>
              Add
            </Button>
          </>
        )}
      </td>
    </tr>
  )
}

NewCategory.propTypes = {
  depth: PROP_TYPES.number.isRequired,
  addNewCategory: PROP_TYPES.func.isRequired,
}

CategoryForManageCategories.New = NewCategory

export default CategoryForManageCategories
