import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Joi from 'joi'
import { compose } from 'redux'
import { connect } from 'react-redux'
import DateFnsUtils from '@date-io/date-fns'
import { reduxForm, Field } from 'redux-form'
import { Translate } from 'react-localize-redux'
import { makeStyles } from '@material-ui/core/styles'
import { ImageUploader } from 'containers'
import {
  Paper,
  Grid,
  Button,
  Typography,
  Toolbar,
  IconButton,
  Divider,
  Box,
} from '@material-ui/core'

import {
  Input,
  DatePicker,
  RadioGroup,
  Checkbox,
  LinkBack,
  SelectMD,
  PlayerTags,
} from 'components'
import createJoiValidator from 'utils/createJoiValidator'
import RotateLeftIcon from '@material-ui/icons/RotateLeft'
import { handleDateChange } from 'utils/validate'
import { formatDateFromServer, EUR_STANDARD } from 'utils/formatDate'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import moment from 'moment'
import { FOOT_OPTIONS, GENDER_OPTIONS } from './constants'
import mapStateToProps from './mapStateToProps'
import { PLAYER_POSITIONS, PLAYER_SUB_POSITIONS } from 'constants/player'

const useStyles = makeStyles(theme => ({
  [theme.breakpoints.up('xl')]: {
    root: {
      '& .MuiGrid-grid-xl-2': {
        maxWidth: '19.966667%',
        flexBasis: '19.966667%',
      },
    },
  },
  root: {
    flexGrow: 1,
    margin: theme.spacing(2, 0, 0),
    padding: theme.spacing(3, 3, 3),
  },

  toolbar: {
    minHeight: 56,
    padding: theme.spacing(0, 2),
  },
  button: {
    margin: theme.spacing(0, 1),
  },
  descriptionCell: {
    whiteSpace: 'nowrap',
    maxWidth: 'calc(100vw - 20px)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  media: {
    height: 140,
  },
  title: {
    flex: '1 1 0',
  },
  fab: {
    position: 'absolute',
    top: theme.spacing(-3),
    right: theme.spacing(2),
  },
  spacing: {
    marginRight: theme.spacing(2),
  },
}))

const validationSchema = Joi.object().keys({
  first_name: Joi.string()
    .max(255)
    .label('First Name')
    .required(),
  last_name: Joi.string()
    .label('Last Name')
    .required(),
  birthday: Joi.any(),
  joining_date: Joi.any(),
  email_1: Joi.string()
    .email({ tlds: { allow: false } })
    .label('E-mail'),
  email_2: Joi.any(),
  phone: Joi.any(),
  avatar: Joi.any(),
  captain: Joi.any().allow(0, 1),
  gender: Joi.string().required(),
  number: Joi.number()
    .min(1)
    .max(9999)
    .label('Uniform Number')
    .required(),
  street: Joi.any(),
  zip: Joi.any(),
  city: Joi.any(),
  position: Joi.string()
    .max(255)
    .required(),
  sub_position: Joi.any().required(),
  foot: Joi.string()
    .max(255)
    .required(),
  tag: Joi.string().required(),
})

const PlayerCreateEditForm = ({
  caption,
  fromPath,
  handleSubmit,
  reset,
  initialValues,
}) => {
  const updateInitialValuesPosition = () => {
    switch (initialValues.position) {
      case 'midfielders':
        // eslint-disable-next-line
        initialValues.position = 'midfield'
        break
      case 'goalkeepers':
        // eslint-disable-next-line
        initialValues.position = 'goalkeeper'
        break
      case 'defenders':
        // eslint-disable-next-line
        initialValues.position = 'defender'
        break
      case 'strikers':
        // eslint-disable-next-line
        initialValues.position = 'striker'
        break
      default:
        break
    }
  }
  const [playerPosition, changePosition] = useState(
    initialValues.position || 'goalkeeper'
  )

  const updatePosition = () => {
    switch (playerPosition) {
      case 'midfielders':
        changePosition('midfield')
        break
      case 'goalkeepers':
        changePosition('goalkeeper')
        break
      case 'defenders':
        changePosition('defender')
        break
      case 'strikers':
        changePosition('striker')
        break
      default:
        break
    }
  }
  updatePosition()
  updateInitialValuesPosition()
  const [playerSubPosition, changeSubPosition] = useState(
    initialValues.sub_position && initialValues.position
      ? initialValues.sub_position
      : PLAYER_SUB_POSITIONS[initialValues.position][0].value
  )

  const [subPositionOptions, changeSubPositionOptions] = useState(
    // eslint-disable-next-line
    initialValues.position === 'goalkeeper'
      ? // eslint-disable-next-line
        PLAYER_SUB_POSITIONS.goalkeeper
      : // eslint-disable-next-line
      initialValues.position === 'midfield'
      ? PLAYER_SUB_POSITIONS.midfield
      : initialValues.position === 'defender'
      ? PLAYER_SUB_POSITIONS.defender
      : PLAYER_SUB_POSITIONS.striker
  )
  localStorage.setItem('position', playerPosition)
  localStorage.setItem('subposition', playerSubPosition)

  const handleSubPosition = value => {
    changeSubPosition(value)
    localStorage.setItem('subposition', value)
  }
  const handlePosition = value => {
    changePosition(value)
    switch (value) {
      case 'midfield':
        handleSubPosition(PLAYER_SUB_POSITIONS.midfield[0].value)
        changeSubPositionOptions(PLAYER_SUB_POSITIONS.midfield)
        break
      case 'goalkeeper':
        handleSubPosition(PLAYER_SUB_POSITIONS.goalkeeper[0].value)
        changeSubPositionOptions(PLAYER_SUB_POSITIONS.goalkeeper)
        break
      case 'defender':
        handleSubPosition(PLAYER_SUB_POSITIONS.defender[0].value)
        changeSubPositionOptions(PLAYER_SUB_POSITIONS.defender)
        break
      case 'striker':
        handleSubPosition(PLAYER_SUB_POSITIONS.striker[0].value)
        changeSubPositionOptions(PLAYER_SUB_POSITIONS.striker)
        break
      default:
        break
    }
    localStorage.setItem('position', value)
  }

  const [joining, setJoining] = useState(
    moment(initialValues.joining_date, 'YYYY-MM-DD').isValid()
      ? formatDateFromServer(initialValues.joining_date)[0]
      : null
  )

  const setJoiningDate = date => {
    setJoining(date.joining_date)
    localStorage.setItem('joining_date', date.joining_date)
  }
  const classes = useStyles()

  React.useEffect(() => {}, [playerPosition, playerSubPosition])

  return (
    <Translate>
      {({ translate }) => (
        <>
          <Toolbar component={Paper} className={classes.toolbar}>
            <div className={classes.title}>
              <Typography variant="h4" component="h4">
                {caption}
              </Typography>
            </div>

            <IconButton className={classes.button} onClick={reset} size="small">
              <RotateLeftIcon />
            </IconButton>
            <LinkBack
              to={fromPath.value}
              className={classes.button}
              title={<Translate id="button.cancel" />}
              isFullPath
            />
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              <Translate id="button.save" />
            </Button>
          </Toolbar>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={7} lg={8} xl={8}>
              <Paper className={classes.root}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Field
                      name="first_name"
                      label={translate('form.first-name')}
                      className={classes.spacing}
                      component={Input}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      name="last_name"
                      label={translate('form.last-name')}
                      component={Input}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      label={translate('event.birthday')}
                      name="birthday"
                      component={DatePicker}
                      id="birthday"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      name="gender"
                      label={translate('form.gender')}
                      component={RadioGroup}
                      options={GENDER_OPTIONS}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      name="email_1"
                      label={translate('form.e-mail-1')}
                      component={Input}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      name="phone"
                      label={translate('form.phone-number')}
                      component={Input}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Field
                      name="street"
                      label={translate('form.street')}
                      className={classes.spacing}
                      component={Input}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      name="city"
                      label={translate('form.city')}
                      component={Input}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      name="zip"
                      label={translate('form.postal-code')}
                      className={classes.spacing}
                      component={Input}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    component={Box}
                    display="flex"
                    alignItems="center"
                  >
                    <Translate id="player.player-info" />
                    <Divider fullWidth light />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Field
                      name="captain"
                      label={translate('form.team-captain')}
                      component={Checkbox}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      name="position"
                      props={{
                        label: translate('form.position'),
                        value: playerPosition,
                        onChange: handlePosition,
                      }}
                      component={SelectMD}
                      options={PLAYER_POSITIONS}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      name="sub_position"
                      props={{
                        label: translate('form.sub_position'),
                        value: playerSubPosition,
                        onChange: handleSubPosition,
                      }}
                      component={SelectMD}
                      options={subPositionOptions}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      name="tag"
                      label={translate('form.tags')}
                      component={PlayerTags}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      name="number"
                      type="number"
                      label={translate('form.number')}
                      min={1}
                      max={9999}
                      component={Input}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      name="foot"
                      label={translate('form.preferred-foot')}
                      component={RadioGroup}
                      options={FOOT_OPTIONS}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        label={translate('player.joining-date')}
                        // disableToolbar
                        autoOk
                        variant="outlined"
                        inputVariant="outlined"
                        format={EUR_STANDARD}
                        size="small"
                        id="joining_date"
                        name="joining_date"
                        value={joining}
                        maxDate={new Date()}
                        // invalidDateMessage=""
                        onChange={date =>
                          handleDateChange(
                            date,
                            'joining_date',
                            initialValues,
                            setJoiningDate
                          )
                        }
                        showTodayButton
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={4} xl={4}>
              <Paper className={classes.root}>
                <Field name="avatar" component={ImageUploader} />
              </Paper>
            </Grid>
          </Grid>
        </>
      )}
    </Translate>
  )
}

PlayerCreateEditForm.defaultProps = {
  initialValues: '',
}

PlayerCreateEditForm.propTypes = {
  initialValues: PropTypes.shape(),
  caption: PropTypes.string.isRequired,
  fromPath: PropTypes.shape({
    value: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
}

export default compose(
  reduxForm({
    enableReinitialize: true,
    validate: createJoiValidator(validationSchema),
  }),
  connect(mapStateToProps)
)(PlayerCreateEditForm)
