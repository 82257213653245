import React from 'react'
import { Field } from 'redux-form'
import { Input, DateInput } from 'components'
import styled from 'styled-components'

const InputContainer = styled.div`
  select {
    @media (min-width: 1024px) {
      height: 40px;
    }
    width: 100%;
    margin: 0;
  }
`

const TrainingFormFields = () => (
  <>
    <InputContainer>
      <Field name="dateDay" label="Date" component={DateInput} />
      <Field name="time" type="time" label="Time" component={Input} />
      <Field name="location" label="Location" component={Input} />
    </InputContainer>
  </>
)

export default TrainingFormFields
