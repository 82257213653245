import { handleActions, combineActions } from 'redux-actions'
import {
  actionsTypesGenerator,
  onRequest,
  onSuccess,
  onFailure,
} from 'utils/reduxHelpers'
import initialState from '../initialState'

const namespace = 'GENERAL_EVENT'

const CREATE_EVENT = actionsTypesGenerator(`CREATE_${namespace}`)
const UPDATE_EVENT = actionsTypesGenerator(`UPDATE_${namespace}`)
const FETCH_EVENT = actionsTypesGenerator(`FETCH_${namespace}`)
const DELETE_EVENT = actionsTypesGenerator(`DELETE_${namespace}`)

export const createEvent = (id, data) => ({
  types: CREATE_EVENT,
  callAPI: {
    method: 'POST',
    entity: 'Event',
    path: '/general_events',
    data,
  },
  payload: { id },
})

export const updateEvent = (id, data) => ({
  types: UPDATE_EVENT,
  callAPI: {
    method: 'PUT',
    entity: 'Event',
    path: `/general_events/${id}`,
    data,
  },
  payload: { id },
})
export const fetchEvent = (eventId, params) => ({
  types: FETCH_EVENT,
  callAPI: {
    method: 'GET',
    entity: 'Event',
    path: `/general_events/${eventId}`,
    params,
  },
  payload: { eventId },
})

export const deleteEvent = (id) => ({
  types: DELETE_EVENT,
  callAPI: {
    method: 'DELETE',
    entity: 'Event',
    path: `/general_events/${id}`,
  },
  payload: { id },
})

export default handleActions(
  {
    [combineActions(
      CREATE_EVENT.request,
      UPDATE_EVENT.request,
      FETCH_EVENT.request
    )]: onRequest,
    [combineActions(FETCH_EVENT.success)]: (
      state,
      { response: { data: current = {} } }
    ) => onSuccess({ ...state, current }),
    [combineActions(CREATE_EVENT.success, UPDATE_EVENT.success)]: (
      state,
      { response: { data = {} }, id }
    ) => {
      const items = state.items.updateById(id, { ...data, isNew: false })

      return onSuccess({
        ...state,
        items,
        current: { ...state.current, ...data },
      })
    },
    [combineActions(
      CREATE_EVENT.failure,
      UPDATE_EVENT.failure,
      FETCH_EVENT.failure
    )]: onFailure,
  },
  initialState.generalEvents
)
