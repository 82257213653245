import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { TextField } from '@material-ui/core'

const NumberField = styled(TextField)`
  &.is-invalid {
    padding-right: 16px;
    background-position-x: right;
  }
`

const InputNumber = ({
  input,
  meta = {},
  label,
  className,
  minLimit,
  maxLimit,
  textAlign,
  ...rest
}) => {
  const isInvalid = meta.touched && meta.error

  return (
    <NumberField
      className={className}
      label={label && label}
      type="number"
      isInvalid={isInvalid}
      variant="outlined"
      margin="dense"
      InputProps={{
        inputProps: { min: minLimit, max: maxLimit, style: { textAlign } },
      }}
      // error = {isInvalid && true}
      {...input}
      helperText={isInvalid && isInvalid}
      {...rest}
    />
  )
}

InputNumber.defaultProps = {
  meta: {},
  label: '',
  className: '',
  minLimit: '',
  maxLimit: '',
  textAlign: 'center',
}

InputNumber.propTypes = {
  input: PropTypes.shape().isRequired,
  meta: PropTypes.shape(),
  label: PropTypes.string,
  className: PropTypes.string,
  minLimit: PropTypes.number,
  maxLimit: PropTypes.number,
  textAlign: PropTypes.string,
}

export default InputNumber
