import React, { useEffect, useState } from "react";
import { Translate } from 'react-localize-redux'
import {
  Typography,
  Grid,
  Box,
  FormControl,
  InputLabel,
  Select,
  IconButton,
  MenuItem,
  Tooltip
} from '@material-ui/core'
import DatePicker from 'components/inputes/DatePicker'
import { connect } from "react-redux";
import ClearIcon from '@material-ui/icons/Clear'
import PropTypes from "prop-types";
import { handleDateChange } from "utils/validate";
import { teams, seasons } from 'redux/modules'
import { checkIsStartDateBeforeEndDate } from "utils/formatDate";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { matchTypes } from "constants/match"
import PROP_TYPES from "constants/propTypes";

const StatisticsFilter = ({
      dateFormat,
      fetchTeam,
      muiSM1,
      muiSM2,
      muiMD1,
      isAttendance,
      tab,
      fetchPlayersAttendance,setRows,setLoading,
      match: {
        params: { teamId, seasonId }
      }
}) => {

  const [matchType, setMatchType] = useState('select')
  const [filterDate, setFilterDate] = useState({
    'startDate':null,
    'endDate':null
  })

  const matchTypeChange = (event) => {
    setMatchType(event.target.value)
  }

  const param = {
    fields: "statistics,players,attendance"
  };

  const clearAll = ()=>{
    setMatchType("select");
    setFilterDate({
      "startDate": null,
      "endDate": null
    });
  }

  useEffect(() => {

    if ((matchType && matchType !== 'select') || (filterDate.startDate && filterDate.endDate && checkIsStartDateBeforeEndDate(filterDate.startDate, filterDate.endDate))) {

      if (matchType && matchType !== 'select') {
        param.match_type = matchType;
      }
      if (filterDate.startDate && filterDate.endDate) {
        param.start_date = filterDate.startDate;
        param.end_date = filterDate.endDate;
      }

    }

    if(isAttendance) {
      setLoading(true)
      setRows([])
      fetchPlayersAttendance(seasonId, {...param, event_type:tab }).then((res)=>{
        setLoading(false)
        setRows(res.response.data)
      },()=>{
        setLoading(false)
        setRows([])
      })
    } else {
      fetchTeam(teamId, param);
    }

  }, [tab, matchType, filterDate]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={muiMD1}>
          <Grid container spacing={1} alignItems='center'>
            <Grid item xs={12} sm={tab && tab === 'MATCH' ? muiSM1 : '11'}>
              <Box display='flex' alignItems='center'>
              <Box mx={2}>
                <Typography variant="span" gutterBottom>
                <Translate id="statisticsfilter.filter" />
                </Typography>
              </Box>
                <DatePicker
                  required
                  label={<Translate id="statisticsfilter.start-date" />}
                  disableToolbar={false}
                  selectedValue={filterDate.startDate}
                  onDateChange={(date) =>
                    handleDateChange(
                      date,
                      'startDate',
                      filterDate,
                      setFilterDate
                    )
                  }
                  openTo="date"
                  isEditMode
                  format={dateFormat}
                />
                <Box mx={2}><Translate id="statisticsfilter.to" /></Box>
                <DatePicker
                  required
                  label={<Translate id="statisticsfilter.end-date" />}
                  disableToolbar={false}
                  selectedValue={filterDate.endDate}
                  onDateChange={(date) =>{
                    handleDateChange(
                      date,
                      'endDate',
                      filterDate,
                      setFilterDate
                    )
                  }
                  }
                  openTo="date"
                  isEditMode
                  format={dateFormat}
                />
              </Box>
            </Grid>
            {tab && tab === 'MATCH' && <Grid item xs={12} sm={muiSM2}>
              <FormControl variant="outlined" margin="dense">
                <InputLabel id="match-type-label">
                  <Translate id="matches.match-type" />
                </InputLabel>
                <Select
                  labelId="match-type-label"
                  id="match-type"
                  value={matchType}
                  onChange={matchTypeChange}
                  label={<Translate id="matches.match-type" />}
                >
                  <MenuItem  value="select">
                    <Translate id="statisticsfilter.select-match-type" />
                  </MenuItem>
                  {matchTypes.map(element =>
                    <MenuItem key={element.type} value={element.type}>
                      {element.title}
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>}
            <Grid item style={{marginLeft:'auto'}} sm={1}><Box textAlign='right' p={1.6}>
         <Tooltip title="Clear All" arrow>
          <IconButton onClick={clearAll} color="primary" size="small" ><ClearIcon fontSize="small"/></IconButton>
          </Tooltip>
        </Box> </Grid>
          </Grid>

        </Grid>
      </Grid>
    </>
  );
}
StatisticsFilter.defaultProps = {
  muiSM1: 5,
  muiMD1: 12,
  muiSM2: 2,
  isAttendance : false,
  tab:null,
  setRows:()=>0,
  setLoading : ()=>0

}

StatisticsFilter.propTypes = {
  dateFormat: PropTypes.string.isRequired,
  fetchTeam: PropTypes.func.isRequired,
  match: PROP_TYPES.match.isRequired,
  muiSM1: PropTypes.number,
  muiSM2: PropTypes.number,
  muiMD1: PropTypes.number,
  isAttendance: PropTypes.bool,
  tab: PropTypes.string,
  fetchPlayersAttendance: PropTypes.func.isRequired,
  setRows: PropTypes.func,
  setLoading: PropTypes.func
}

export default compose(
  withRouter,
  connect(
    ({ auth }) => ({
      dateFormat: auth.user.datetime_format
    }),
    { fetchTeam: teams.fetchTeam, fetchPlayersAttendance: seasons.fetchPlayersAttendance }
  )
)(StatisticsFilter);
