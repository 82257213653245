/* eslint-disable no-restricted-globals */
/* eslint-disable no-param-reassign */
import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { SubLink } from 'containers'
import { formatCostumDateForUI, formatDateForServer } from 'utils/formatDate'
import { DataGrid } from '@mui/x-data-grid'
import DataGridExportToolbar from 'components/DataGridExportToolbar'
import { Avatar, Player } from './styledComponents'
import { PLAYER_POSITIONS } from 'constants/player'
import { TEAM_DASHBOARD_ROUTE } from 'constants/routes'

const PlayerLists = ({ players, updatePlayer }) => {

  const avatarImage = (params) => (
    <Player
      component={SubLink}
      to={`${TEAM_DASHBOARD_ROUTE}/player/${
        params.row.id
      }/overview`}
    >
      {params.row.avatar?.full_url !== null ? (
        <Avatar
          alt={params.row.first_name}
          src={params.row.avatar?.full_url}
        />
      ) : (
        <Avatar alt={params.row.first_name} />
      )}
      <div>
        <Typography variant="h4">
          {' '}
          {`${params.row.first_name} ${params.row.last_name}`}
        </Typography>
        <Typography variant="subtitle2" noWrap>
          {
            PLAYER_POSITIONS.find(
              p => p.value === params.row.position
            ).title
          }
        </Typography>
      </div>
    </Player>
  );

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      headerAlign: "left",
      headerClassName: 'primary',
      align: "left",
      width: 120,
      hide:true,
      editable: true,
    },
    {
      field: 'avatar',
      headerName: 'Player',
      width: 300,
      renderCell: avatarImage,
      editable: false,
      sortable: false,
      headerAlign: "left",
      align: "left",
    },
    {
      field: 'number',
      headerName: 'Uniform #',
      type: 'number',
      width: 110,
      editable: true,
    },
    {
      field: 'first_name',
      headerName: 'First Name',
      width: 150,
      hide: true,
      editable: false,
    },
    {
      field: 'last_name',
      headerName: 'Last Name',
      width: 150,
      hide: true,
      editable: false,
    },
    {
      field: 'birthday',
      headerName: 'Birthday',
      type: 'date',
      valueFormatter: params => params.value ? formatCostumDateForUI(params.value) : "",
      width: 110,
      editable: true,
    },
    {
      field: 'email_1',
      headerName: 'Email',
      headerClassName: 'secondary',
      width: 300,
      editable: true
    },
    {
      field: 'phone',
      headerName: 'Phone',
      width: 200,
      editable: true,
    },
    {
      field: 'street',
      headerName: 'Street',
      width: 300,
      editable: true,
    },
    {
      field: 'zip',
      headerName: 'Postal',
      width: 200,
      editable: true,
    },
    {
      field: 'city',
      headerName: 'City',
      width: 200,
      editable: true,
    }
  ];
const [sortModel, setSortModel] = React.useState([
  {
    field: 'first_name',
    sort: 'asc',
  },
]);

const handleEditCellModelChange = React.useCallback((model) => {
  const updateData = {
    birthday: model.row.birthday ? formatDateForServer(model.row.birthday) : null,
    city: model.row.city,
    number: model.row.number,
    phone: model.row.phone,
    street: model.row.street,
    zip: model.row.zip,
  }
  updatePlayer(model.row.id, updateData).then(res => {
    return res
  })
}, []);

  return (
    <div style={{ height: "auto", width: '100%' }}>
      <DataGrid
        rows={players}
        columns={columns}
        pageSize={100}
        rowsPerPageOptions={[5]}
        checkboxSelection={false}
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)}
        disableSelectionOnClick
        disableColumnFilter
        disableColumnMenu
        autoHeight
        editMode='row'
        rowsLoadingMode="server"
        onRowEditStop={(model) => handleEditCellModelChange(model)}
        components={{
          Toolbar: DataGridExportToolbar,
        }}
        componentsProps={{ toolbar: { csvOptions: {fields:['first_name', 'last_name', 'number', 'birthday', 'email_1', 'phone', 'street', 'zip', 'city']} } }}
        />
    </div>
  )
}

PlayerLists.defaultProps = {}

PlayerLists.propTypes = {
  players: PropTypes.shape().isRequired,
  updatePlayer: PropTypes.shape().isRequired,
}

export default PlayerLists
