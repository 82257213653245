import React from "react";
import PropTypes from 'prop-types'
import {
  Typography,
  TableBody,
  TableCell,
  TableRow,
  Box,
} from '@material-ui/core'
import { SubLink } from 'containers'
import hasSubscription from 'utils/hasSubscription'
import { useStyles } from './styled';
import EnhancedTableHead from './EnhancedTableHead'
import StatisticsFilter from './StatisticsFilter/index'
import { Avatar, Player, Table, TableContainer } from './styledComponents'
import { PLAYER_POSITIONS, BASIC_STAT_COLUMNS, PAID_STAT_COLUMNS } from 'constants/player'
import { TEAM_DASHBOARD_ROUTE } from 'constants/routes'


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }

  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order

    return a[1] - b[1]
  })

  return stabilizedThis.map(el => el[0])
}
const PlayerPerformance = ({
  rows,
  theme
}) => {

  const [value] = React.useState(0)

  const [order, setOrder] = React.useState('asc')
  const [orderBy, setOrderBy] = React.useState('first_name')

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }
  const headCells = hasSubscription() !== 'basic' ? PAID_STAT_COLUMNS : BASIC_STAT_COLUMNS
  const classes = useStyles(theme)

  return (
    <>
      <Box my={1}><StatisticsFilter tab="MATCH" /></Box>
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          stickyHeader
          aria-label="sticky table"
        >
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={['first_name']}
            headCells={headCells}
            onRequestSort={handleRequestSort}
          />
          {value === 0 && (
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy)).map(
                (row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`

                  return (
                    <TableRow hover tabIndex={-1} key={row.id}>
                      <TableCell component="th" id={labelId} scope="row">
                        <Player
                          component={SubLink}
                          to={`${TEAM_DASHBOARD_ROUTE}/player/${row.id
                            }/overview`}
                        >
                          {row.avatar_image !== null ? (
                            <Avatar
                              alt={row.first_name}
                              src={row.avatar_image}
                            />
                          ) : (
                            <Avatar alt={row.first_name} />
                          )}
                          <div>
                            <Typography variant="h4">
                              {' '}
                              {`${row.first_name} ${row.last_name}`}
                            </Typography>
                            <Typography variant="subtitle" noWrap>
                              {
                                PLAYER_POSITIONS.find(
                                  p => p.value === row.position
                                ).title
                              }
                            </Typography>
                          </div>
                        </Player>
                      </TableCell>
                      <TableCell align="left">{row.number}</TableCell>
                      <TableCell align="left">{row.trainings_total}</TableCell>
                      <TableCell align="left">
                        {row.trainings_attended} (
                        {Math.round(
                          (row.trainings_attended * 100) / row.trainings_total
                        )}
                        %)
                      </TableCell>
                      {hasSubscription() !== 'basic' && <>
                        <TableCell align="left">{row.total_matches}</TableCell>
                        <TableCell align="left">
                          {row.match_participation} (
                          {Math.round(
                            (row.match_participation * 100) / row.total_matches
                          )}
                          %)
                        </TableCell>
                        <TableCell align="left">
                          {row.total_minute_played} min
                        </TableCell>
                        <TableCell align="left">{row.goals_scored}</TableCell>
                        <TableCell align="left">{row.goals_assisted}</TableCell>

                        <TableCell align="left">{row.ycard}</TableCell>
                        <TableCell align="left">{row.yrcard}</TableCell>
                        <TableCell align="left">{row.rcard}</TableCell>

                      </>}
                    </TableRow>
                  )
                }
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </>
  )
}

PlayerPerformance.defaultProps = {}

PlayerPerformance.propTypes = {
  rows: PropTypes.shape().isRequired,
  theme: PropTypes.oneOfType([
    PropTypes.shape().isRequired,
    PropTypes.oneOf([null]).isRequired,
  ]).isRequired,
}

export default PlayerPerformance
