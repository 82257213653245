import React, { useMemo } from 'react'
import { Translate } from 'react-localize-redux'
import moment from 'moment'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'
// import { makeStyles } from '@material-ui/core/styles'
import CardHeader from '@material-ui/core/CardHeader'
import Card from '@material-ui/core/Card'
// import Button from '@material-ui/core/Button'
import TimelineIcon from '@material-ui/icons/Timeline'
import Typography from '@material-ui/core/Typography'
// import IconImage from 'assets/images/icon-performance.svg'
import { NextCalendarEventWidget } from 'components'
import styled from 'styled-components'
import { Row, Col } from 'react-bootstrap'
import PROP_TYPES from 'constants/propTypes'

// const useStyles = makeStyles({
//   buttonsRight: {
//     textAlign: 'right',
//   },
//   media: {
//     height: '148px',
//     paddingTop: '56.25%', // 16:9
//     borderRadius: '10px 10px 0 0',
//     margin: '-1px',
//   },

//   chevronLeftStyle: {
//     border: '1px solid var(--light-grey)',
//     borderRadius: '10px 0 0 10px',
//     color: 'var(--light-grey)',
//     fontSize: '1.125rem',
//   },

//   chevronRightStyle: {
//     border: '1px solid var(--light-grey)',
//     borderRadius: '0 10px 10px 0',
//     color: 'var(--light-grey)',
//     fontSize: '1.125rem',
//   },
// })
// const CardIcon = styled.div`
//   display: inline-block;
//   width: 35px;
//   height: 35px;
//   margin-right: 10px;
//   background: url(${IconImage}) no-repeat center;
//   background-size: contain;
// `

// @todo: calculate somehow
const ChartWrapper = styled.div`
  height: 254px;
  svg {
    padding: 0;
    max-height: 95%;
  }
`
export const CustomCardContent = styled.div`
  padding: 0 16px 16px;
`
const Title = styled(NextCalendarEventWidget.Title)`
  color: white;
  font-size: 16px;
  line-height: inherit;
`

const PerformanceWidget = ({ title, data }) => {
  const dataWithFormattedDate = useMemo(
    () =>
      data.map(({ date, ...rest }) => ({
        date: moment(date).format('DD.MM.'),
        ...rest,
      })),
    [data]
  )

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" component="h4">
            {title}
          </Typography>
        }
      />
      <CustomCardContent>
        <ChartWrapper>
          {dataWithFormattedDate.length > 0 ? (
            <ResponsiveContainer height="100%" width="100%">
              <LineChart
                data={dataWithFormattedDate}
                margin={{ left: -45, right: 15, top: 15 }}
              >
                <XAxis dataKey="date" interval={0} />
                <YAxis domain={[1, 5]} interval={0} padding={{ bottom: 10 }} />
                <Tooltip />
                <Line
                  type="linear"
                  dataKey="rating"
                  stroke="#29a373"
                  strokeWidth={2}
                  activeDot
                  isAnimationActive
                  animationDuration={2400}
                  connectNulls
                />
              </LineChart>
            </ResponsiveContainer>
          ) : (
            <React.Fragment>
              <Row md={12}>
                <Col md={12}>
                  <TimelineIcon
                    style={{
                      fontSize: 100,
                      color: 'var(--primary-green)',
                      float: 'left',
                    }}
                  />
                  <Title
                    style={{
                      fontSize: 20,
                      color: 'var(--primary-green)',
                      height: '100px',
                      verticalAlign: 'middle',
                      display: 'table-cell',
                      maxWidth: '400px',
                    }}
                  >
                    <Translate id="home.no-game-performance" />
                  </Title>
                </Col>
              </Row>
            </React.Fragment>
          )}
        </ChartWrapper>
      </CustomCardContent>
    </Card>
  )
}

PerformanceWidget.defaultProps = {
  data: [],
}

PerformanceWidget.propTypes = {
  title: PROP_TYPES.string.isRequired,
  data: PROP_TYPES.arrayOfObjects,
}

export default PerformanceWidget
