import React from 'react'
import { Translate } from 'react-localize-redux';
import { SubLink, HeartIcon, ShareMuiIcon, HasClubPermission } from 'containers'
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import PROP_TYPES from 'constants/propTypes';
import { DRILL_EDIT_ROUTE } from 'constants/routes';
import { DEFAULT_CLUB_ID } from 'constants/club'
import PERMISSIONS from 'constants/permissions'

export function DrillCard({
  drill,
  duplicateDrill,
  getIsFavorite,
  handleOpenDrill,
  inProgress,
  shareDrill,
  club
}) {
  const {
    id: drillId,
    title: titleValue,
    duration: durationValue,
    max_players: maxPlayers,
    min_players: minPlayers,
    image,
  } = drill

  const classes = makeStyles(theme => ({
    media: { height: 140 },
    descriptionCell: {
      whiteSpace: 'nowrap',
      maxWidth: 'calc(100vw - 20px)',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    title: { flex: '1 1 0' },
    button: { marginLeft: theme.spacing(0.8) },
    tableItem: { padding: '10px !important' },
    actions: { height: '64px' }
  }))()

  const MuiCard = withStyles(theme => ({
    root: {
      border: '1px solid #e8e8e8',
      '& .MuiCardContent-root': {
        padding: theme.spacing(2, 2, 0),
      },
      '& .MuiButton-root': {
        minWidth:'46px',
      },
    },
  }))(Card)

  const MuiBox = withStyles(theme => ({
    root: {
      '& .MuiBox-root': {
        margin: theme.spacing(0, 1.5, 0, 0),
        '& .MuiTypography-root': {
          fontWeight: 500,
          color: theme.palette.action,
          marginRight: theme.spacing(0.5),
        },
      },
    },
  }))(Box)

  return (
    <>
      <Grid
        item
        className={classes.tableItem}
        xs={12} sm={6} md={4} lg={3} xl={2}
      >
        <MuiCard>
          <CardMedia className={classes.media} image={image} />
          <CardContent>
            <Typography
              gutterBottom
              className={classes.descriptionCell}
              variant="h4"
              component="h4"
            >
              {titleValue}
            </Typography>
            <MuiBox>
              <Box>
                {' '}
                <Typography variant="span" component="span">
                  <Translate id="drills.duration" />:
                </Typography>{' '}
                {durationValue} min{' '}
              </Box>
              <Box>
                {' '}
                <Typography variant="span" component="span">
                  <Translate id="drills.players" />:
                </Typography>{' '}
                {`${minPlayers} - ${maxPlayers}`}{' '}
              </Box>
            </MuiBox>
          </CardContent>
          <CardActions className={classes.actions}>
            <div className={classes.title}>
              {(
                <HasClubPermission ability={PERMISSIONS.UPDATE_DRILL} or={!drill.global}>
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    component={SubLink}
                    to={DRILL_EDIT_ROUTE.populateURLParams({ drillId })}
                  >
                    <Translate id="button.edit" />
                  </Button>
                </HasClubPermission>
              )}
              <Button
                size="small"
                variant="contained"
                color="primary"
                className={classes.button}
                disabled={inProgress}
                onClick={() => duplicateDrill(drillId)}
              >
                <Translate id="button.duplicate" />
              </Button>
              <Button
                size="small"
                variant="contained"
                className={classes.button}
                onClick={() => handleOpenDrill(drill)}
              >
                <Translate id="button.view" />
              </Button>
            </div>
            {(
              <ShareMuiIcon share={shareDrill} id={drillId} shareBy="Drill" />
            )}
            {(
              <HeartIcon
                isActive={getIsFavorite(drillId)}
                entity="drills"
                entityId={drillId}
                isPositionRelative
              />
            )}
          </CardActions>
        </MuiCard>
      </Grid>
    </>
  )
}

DrillCard.propTypes = {
  drill: PROP_TYPES.shape().isRequired,
  getIsFavorite: PROP_TYPES.func,
  handleOpenDrill: PROP_TYPES.func,
  inProgress: PROP_TYPES.bool,
  shareDrill: PROP_TYPES.func,
}
