// import React from 'react'
import styled from 'styled-components'
// import AddButton from './buttons/AddButton'

const Drill = styled.div`
  .MuiSnackbar-root {
    .MuiAlert-root {
      .drillImg {
        margin-right: 0.7rem;
      }
      .title {
        margin-left: 0;
      }
      .extraAction {
        display: flex;

        .time-content {
          display: flex;
          margin-left: 0.3rem;
          align-items: center;
          .MuiCardMedia-img {
            width: 1rem;
            height: 1rem;
            margin-right: 0.3rem;
          }
        }
      }
    }
  }

  &:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25);
  }

  /* &:hover{
    border: 2px solid var(--primary-green);
    outline-offset: 3px;
  } */
`

// Drill.Duration = styled.div`
//   position: absolute;
//   left: 0;
//   top: 0;

//   display: flex;
//   color: rgba(211, 211, 211, 0.8);

//   > p {
//     padding: 10px;
//     margin-left: 5px;
//   }
// `

// Drill.CreateNew = styled(({ className }) => (
//   <div className={className}>
//     <AddButton>+</AddButton>
//   </div>
// // ))`
//   border: #aaa solid 1px;
//   border-radius: 12px;
//   position: relative;
//   height: ${`${CONTAINER_HEIGHT}px`};

//   ${({ theme }) => theme.flexCenter};
// `

export default Drill
