import { handleActions, combineActions, createAction } from 'redux-actions'
import {
  actionsTypesGenerator,
  onRequest,
  onSuccess,
  onFailure,
} from 'utils/reduxHelpers'
import initialState from '../initialState'

const namespace = 'TRAINING'

const FETCH_SEASON_TRAININGS = actionsTypesGenerator(
  `FETCH_SEASON_${namespace}S`
)
const FETCH_TRAINING = actionsTypesGenerator(`FETCH_${namespace}`)
const CREATE_TRAINING = actionsTypesGenerator(`CREATE_${namespace}`)
const UPDATE_TRAINING = actionsTypesGenerator(`UPDATE_${namespace}`)
const SHARE_TRAINING = actionsTypesGenerator(`SHARE_${namespace}`)
const DELETE_TRAINING = actionsTypesGenerator(`DELETE_${namespace}`)
const DELETE_TRAINING_NOTE = actionsTypesGenerator(`DELETE_${namespace}_NOTE`)
const SCHEDULE_TRAININGS = actionsTypesGenerator(`SCHEDULE_${namespace}S`)

const ADD_NEW_TRAINING = `ADD_NEW_${namespace}`
const REMOVE_NEW_TRAININGS = `REMOVE_NEW_${namespace}S`

export const fetchSeasonTrainings = seasonId => ({
  types: FETCH_SEASON_TRAININGS,
  callAPI: {
    method: 'GET',
    entity: 'Trainings',
    path: `/seasons/${seasonId}/trainings`,
  },
})

export const fetchTraining = (id, params) => ({
  types: FETCH_TRAINING,
  callAPI: {
    method: 'GET',
    entity: 'Training',
    path: `/trainings/${id}`,
    params,
  },
})

export const createTraining = (id, data) => ({
  types: CREATE_TRAINING,
  callAPI: {
    method: 'POST',
    entity: 'Training',
    path: '/trainings',
    data,
  },
  payload: { id },
})

export const updateTraining = (id, data) => ({
  types: UPDATE_TRAINING,
  callAPI: {
    method: 'PUT',
    entity: 'Training',
    path: `/trainings/${id}`,
    data,
  },
})

export const shareTraining = (id, paramsString) => ({
  types: SHARE_TRAINING,
  callAPI: {
    method: 'SHARE',
    entity: 'Training',
    path: `/trainings/${id}/share`,
    paramsString,
  },
})

export const deleteTraining = id => ({
  types: DELETE_TRAINING,
  callAPI: {
    method: 'DELETE',
    entity: 'Training',
    path: `/trainings/${id}`,
  },
  payload: { id },
})

export const deleteTrainingNote = (tid, nid) => ({
  types: DELETE_TRAINING_NOTE,
  callAPI: {
    method: 'DELETE',
    entity: 'Training Note',
    path: `/trainings/${tid}/notes/${nid}`,
  },
  payload: { nid },
})

export const scheduleTrainings = data => ({
  types: SCHEDULE_TRAININGS,
  callAPI: {
    method: 'POST',
    entity: 'Training schedule',
    path: '/trainings/schedule',
    data,
  },
})
export const scheduleOnlyTrainings = data => ({
  types: SCHEDULE_TRAININGS,
  callAPI: {
    method: 'POST',
    entity: 'Training schedule',
    path: '/trainings/scheduleTraining',
    data,
  },
})

export const addNewTraining = createAction(ADD_NEW_TRAINING)
export const removeNewTrainings = createAction(REMOVE_NEW_TRAININGS)

export default handleActions(
  {
    [combineActions(
      FETCH_SEASON_TRAININGS.request,
      FETCH_TRAINING.request,
      CREATE_TRAINING.request,
      UPDATE_TRAINING.request,
      DELETE_TRAINING.request,
      DELETE_TRAINING_NOTE.request,
      SCHEDULE_TRAININGS.request
    )]: onRequest,
    [FETCH_SEASON_TRAININGS.success]: (
      state,
      { response: { data: items = [], team_data: teamData = {} } }
    ) => onSuccess({ ...state, items, teamData }),
    [combineActions(
      FETCH_TRAINING.success,
      CREATE_TRAINING.success,
      UPDATE_TRAINING.success
    )]: (state, { response: { data = {}, team_data: teamData = {} }, id }) => {
      const items = state.items.updateById(id, { ...data, isNew: false })

      return onSuccess({
        ...state,
        items,
        current: { ...state.current, ...data },
        teamData,
      })
    },
    [DELETE_TRAINING.success]: (state, { id }) =>
      onSuccess({ ...state, items: state.items.filterById(id) }),
    [SCHEDULE_TRAININGS.success]: (state, { response: { data: items = [] } }) =>
      onSuccess({ ...state, items: [...state.items, ...items] }),
    [addNewTraining]: (state, { payload }) => {
      const { id, teamId, seasonId, date, status, isNew } = payload

      const newTraining = {
        id,
        team_id: +teamId,
        season_id: +seasonId,
        date,
        status,
        isNew,
      }

      return {
        ...state,
        items: [...state.items, newTraining],
      }
    },
    [removeNewTrainings]: state => {
      const items = state.items.filter(({ isNew }) => !isNew)

      return {
        ...state,
        items,
      }
    },
    [combineActions(
      FETCH_SEASON_TRAININGS.failure,
      FETCH_TRAINING.failure,
      CREATE_TRAINING.failure,
      UPDATE_TRAINING.failure,
      DELETE_TRAINING.failure,
      DELETE_TRAINING_NOTE.failure,
      SCHEDULE_TRAININGS.failure
    )]: onFailure,
  },
  initialState.trainings
)
