import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import { PlayerStaffItem as PlayerItem, Button, Select } from 'components'
import { PLAYER_POSITIONS } from 'constants/player'

const Overlay = (playerPosition, handleChangePosition, handleDelete) => (
  <Popover id="new-field-player">
    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      <Select
        value={playerPosition}
        onChange={handleChangePosition}
        options={PLAYER_POSITIONS}
      />
      <Button onClick={handleDelete}>Delete</Button>
    </div>
  </Popover>
)

const NewForLineup = ({
  number,
  playerPosition,
  onChangePosition,
  onDelete,
}) => {
  const [overlayRef, changeOverlayRef] = useState({})

  const ref = overlay => {
    changeOverlayRef(overlay)
  }

  const handleDelete = () => {
    overlayRef.hide()

    onDelete()
  }

  const handleChangePosition = newPosition => {
    overlayRef.hide()

    onChangePosition(newPosition)
  }

  return (
    <PlayerItem.Container>
      <PlayerItem.PlayerDummyBody>
        <PlayerItem.Number>{number}</PlayerItem.Number>
        <OverlayTrigger
          ref={ref}
          rootClose
          trigger="click"
          placement="auto"
          overlay={Overlay(playerPosition, handleChangePosition, handleDelete)}
        >
          <div>
            <Button>Change</Button>
          </div>
        </OverlayTrigger>
        <PlayerItem.FullName>{playerPosition}</PlayerItem.FullName>
      </PlayerItem.PlayerDummyBody>
    </PlayerItem.Container>
  )
}

NewForLineup.propTypes = {
  number: PropTypes.number.isRequired,
  playerPosition: PropTypes.string.isRequired,
  onChangePosition: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}

export default NewForLineup
