import React from 'react'
import { TeamLoader } from 'loaders'
import ClubStateContext from 'routes/ClubStateContext'
import TeamDashboardContainer from './TeamDashboardContainer'
import PROP_TYPES from 'constants/propTypes'

const TeamDashboard = ({
  history,
  match: {
    params: { teamId },
  },
  changeTab,
}) => (
  <TeamLoader
    teamId={+teamId}
    additionalFields={['statistics', 'players', 'logo', 'last_match']}
  >
    <ClubStateContext>
      <TeamDashboardContainer history={history} changeTab={changeTab} />
    </ClubStateContext>
  </TeamLoader>
)

TeamDashboard.propTypes = {
  history: PROP_TYPES.history.isRequired,
  match: PROP_TYPES.match.isRequired,
  changeTab: PROP_TYPES.func.isRequired,
}

export default TeamDashboard
