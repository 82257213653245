import React, { useContext, useState } from 'react'
import PROP_TYPES from 'prop-types'
import { Translate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { makeStyles, createTheme } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'
import { generalEvents } from 'redux/modules'
import styled from 'styled-components'
import {
  Button,
  IconButton,
  Paper,
  Tooltip,
  Grid,
  Hidden,
  Typography,
  Box,
} from '@material-ui/core'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import { SubLink } from 'containers'
import EventPopup from 'components/material/Calender/eventPopup'
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline'
import { Context } from '../EventStateContext'
import MiniCalendarWithActions from './MiniCalendarWithActions'
import { TRAININGS_GAME } from 'constants/calender'

const theme = createTheme({})
const useStyles = makeStyles({
  paper: {
    width: '100%',
    display: 'flex',
    padding: '.6rem',
  },
  Cancel: {
    margin: '0 .8rem',
  },
  [theme.breakpoints.down('sm')]: {
    paper: {
      display: 'flex',
      flexDirection: 'column',
    },
    Cancel: {
      padding: '8px 28px',
    },
    Save: {
      padding: '8px 28px',
    },
  },
  icon:{
    marginRight: '0.5rem'
  }
})

const CustomheadRight = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  .MuiDialog-paperWidthSm {
    width: 100%;
  }
  @media (max-width: 640px) {
    position: fixed;
    bottom: 0;
    left: 0;
    background: #fff;
    padding: 0.7rem;
    z-index: 9;
    justify-content: center;
    box-shadow: 14px 5px 35px 0 rgba(0, 0, 0, 0.08);
  }
`

const TopBar = ({
  handleSave,
  createEvent,
  deleteEvent,
  history,
  match: { params },
}) => {
  const classes = useStyles()
  const { teamId, seasonId, eventId } = params

  const { eventState } = useContext(Context)
  const [open, setOpen] = useState(false)
  const [trainingAndGame, setTrainingAndGame] = useState('')
  const [isAlertBox, changeAlertBox] = useState(false)
  const [eventDelete, changeDeleteEvent] = useState(0)
  const [duplicateData, changeDuplicateData] = useState(null)
  const [isDuplicate, setIsDuplicate] = React.useState(false)

  const handleClose = () => {
    setOpen(false)
    changeAlertBox(false)
    changeDuplicateData(null)
    setIsDuplicate(false)
  }
  const handleMatchSave = () => {
    handleSave()
  }
  const handleDialogOpen = (text = '') => {
    setTrainingAndGame(text)
    setOpen(true)
  }

  const newCreateEvent = (data) => {
    createEvent(0, data).then((res) => {
      const { id } = {
        ...res.response.data,
      }
      handleClose()
      history.push(`/team/${+teamId}/season/${+seasonId}/general_events/${id}`)
      window.location.reload()
    })
  }

  const onOK = () => {
    deleteEvent(eventDelete).then(() => {
      history.push(`/team/${+teamId}/season/${+seasonId}/events`)
      handleClose()
    })
  }

  const openAlertDialog = (id) => {
    changeAlertBox(true)
    changeDeleteEvent(id)
    handleDialogOpen()
  }

  const openDuplicateDialog = () => {
    changeDuplicateData(eventState)
    handleDialogOpen(TRAININGS_GAME.event)
    setIsDuplicate(true)
  }

  return (
    <>
      {open && (
        <EventPopup
          open={open}
          onClose={handleClose}
          onOK={onOK}
          duplicateData={duplicateData}
          isAlertBox={isAlertBox}
          trainingAndGame={trainingAndGame}
          createTraining={() => 0}
          createEvent={newCreateEvent}
          calenderDateSelected={false}
          calenderDate={null}
          history={history}
          selectedEventType={trainingAndGame}
          isDuplicate={isDuplicate}
        />
      )}
      <Paper className={classes.paper}>
        <Grid item md={6} xs={12} align="left" display="flex">
          <MiniCalendarWithActions handleSave={handleSave} />
        </Grid>

        <Grid item md={6} xs={12} align="right" display="flex">
          <CustomheadRight>
            <Hidden only={['xs', 'sm']}>
              <Tooltip
                title={<Translate id="events.table-player" />}
                placement="top"
                arrow
              >
                <Box display="flex" alignItems="center" className="mx-3">
                  <PeopleOutlineIcon fontSize="small" className={classes.icon}/>
                  <Typography variant="h6" component="h6">
                    {`${
                      eventState?.event_players?.filter((p) => p.availability === 'AVAILABLE')
                        .length
                    }/${eventState?.event_players?.length}`}
                  </Typography>
                </Box>
              </Tooltip>
              <Tooltip title="Duplicate" placement="top" arrow>
                <IconButton onClick={openDuplicateDialog}>
                  <FileCopyIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title="Delete" placement="top" arrow>
                <IconButton onClick={() => openAlertDialog(+eventId)}>
                  <DeleteOutlineIcon />
                </IconButton>
              </Tooltip>
            </Hidden>

            <SubLink
              to={`/team/${+teamId}/season/${+seasonId}/events`}
              isFullPath
            >
              <Button color="primary" size="large" className={classes.Cancel}>
                <Translate id="button.cancel" />
              </Button>
            </SubLink>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={handleMatchSave}
              className={classes.Save}
            >
              <Translate id="button.save" />
            </Button>
          </CustomheadRight>
        </Grid>
      </Paper>
    </>
  )
}

TopBar.defaultProps = {}

TopBar.propTypes = {
  handleSave: PROP_TYPES.func.isRequired,
  createEvent: PROP_TYPES.func.isRequired,
  deleteEvent: PROP_TYPES.func.isRequired,
  history: PROP_TYPES.shape().isRequired,
  match: PROP_TYPES.shape().isRequired,
}

export default compose(
  connect(null, {
    createEvent: generalEvents.createEvent,
    deleteEvent: generalEvents.deleteEvent,
  }),
  withRouter
)(TopBar)
