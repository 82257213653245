import React, { useState, useEffect } from 'react'
import withDndContext from 'withDndContext'
import {
  Button,
  Grid,
  Hidden,
  makeStyles,
  Paper,
  Typography,
  Box
} from '@material-ui/core'
import { Ticker } from 'components'
import PolarSummary from 'components/PolarSummary'
import { getPolarToken } from 'utils/localstorage'
import { Translate } from 'react-localize-redux'
import TaskGroupsWidget from "../../containers/TaskManagement/TaskGroupsWidget";
import { LeftPart, MatchInfo, TopBar, MatchResult } from './components'
import RatePlayersDialog from './components/RatePlayersDialog'
import PROP_TYPES from 'constants/propTypes'

const useStyles = makeStyles((theme) => ({
  rateHeader: {
    padding: '1.5rem',
    backgroundColor: theme.palette.secondary.main,
  },
  TaskGroupsWidget: {
    marginBottom: theme.spacing(2)
  },
  headerText:{
    color: theme.palette.common.white
  }
}))

const MatchDashboardLayout = ({
  onDrop,
  handleSave,
  setLoading,
  tickers,
  matchPolarSelectedId,
  matchId,
}) => {
  const classes = useStyles()
  const [polarTeam, setPolarTeam] = useState(null)
  const [selectedPolarEevent, setSelectedPolarEvent] =
    useState(matchPolarSelectedId)
  const [polarTrainning, setPolarTrainning] = useState(null)
  const [polarToken, setPolarToken] = useState(getPolarToken())
  const [openRatePlayers, setOpenRatePlayers] = useState(false)

  const handleClose = () => {
    setOpenRatePlayers(false)
  }
  useEffect(() => {
    setSelectedPolarEvent(matchPolarSelectedId)
  }, [])

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12} lg={12}>
        <TopBar handleSave={handleSave} setLoading={setLoading} />
        <Hidden only={['lg', 'xl']}>
          <MatchInfo handleSave={handleSave} />
        </Hidden>
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <Paper elevation={3} className={classes.rateHeader}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={6}>
              <Typography variant="h4" className={classes.headerText}>
                <Translate id="matches.rate-players.title" />
              </Typography>
            </Grid>
            <Grid item xs={6} align="right">
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setOpenRatePlayers(true)
                }}
              >
                <Translate id="matches.rate-players.button" />
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      {openRatePlayers && (
        <RatePlayersDialog
          open={openRatePlayers}
          setOpen={setOpenRatePlayers}
          onClose={handleClose}
          handleSave={handleSave}
        />
      )}
      <Grid item xs={12} md={12} lg={9}>
        <LeftPart
          onDrop={onDrop}
          handleSave={handleSave}
          setLoading={setLoading}
          polarTeam={polarTeam}
          selectedPolarEevent={selectedPolarEevent}
          polarTrainning={polarTrainning}
          setPolarToken={setPolarToken}
          polarToken={polarToken}
          matchId={matchId}
        />
      </Grid>

      <Grid item xs={12} md={12} lg={3}>
        <Hidden only={['xs', 'sm', 'md']}>
          <MatchInfo handleSave={handleSave} />
        </Hidden>
        <MatchResult />
        {polarToken && (
          <PolarSummary
            setPolarToken={setPolarToken}
            polarToken={polarToken}
            polarTeam={polarTeam}
            setPolarTeam={setPolarTeam}
            selectedPolarEevent={selectedPolarEevent}
            setSelectedPolarEvent={setSelectedPolarEvent}
            polarTrainning={polarTrainning}
            setPolarTrainning={setPolarTrainning}
            isGame
          />
        )}

        <Box className={classes.TaskGroupsWidget}>
          <TaskGroupsWidget isMatchEvent eventId={matchId} />
        </Box>

        {tickers && tickers.length > 0 && <Ticker tickers={tickers} />}
      </Grid>
    </Grid>
  )
}

MatchDashboardLayout.propTypes = {
  onDrop: PROP_TYPES.func.isRequired,
  handleSave: PROP_TYPES.func.isRequired,
  setLoading: PROP_TYPES.bool.isRequired,
  tickers: PROP_TYPES.arrayOfObjects.isRequired,
  matchPolarSelectedId: PROP_TYPES.number.isRequired,
  matchId: PROP_TYPES.number.isRequired,
}

export default withDndContext(MatchDashboardLayout)
