import React, { useContext, useState, useEffect } from 'react'
import { Translate } from 'react-localize-redux'
import { connect } from 'react-redux'
import {
  Paper,
  Typography,
  CardHeader,
  Card,
  Grid,
  TextField,
  InputAdornment,
  Button,
  Dialog,
  Hidden,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  InputLabel,
  Select,
  MenuItem,
  Link,
} from '@material-ui/core'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import {
  makeStyles,
  withStyles,
  createTheme,
} from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import editIcon from 'assets/images/16px/edit@1x.svg'
import calendarIcon from 'assets/images/16px/calender@1x.svg'
import timeIcon from 'assets/images/16px/time@1x.svg'
import CloseIcon from '@material-ui/icons/Close'
import DescriptionIcon from '@material-ui/icons/Description'
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined'
import {
  formatDateFromServer,
  formatDateTimeToServer,
  formatCostumDateMonthNoForUI,
  display24HrTime
} from 'utils/formatDate'
import styled from 'styled-components'
import PlacesSearchField from 'components/googlemaps/PlacesSearchField'
import { DatePicker,TimePicker } from 'components'
import { Context } from '../MatchStateContext'
import PROP_TYPES from 'constants/propTypes'
import { matchTypes } from 'constants/match'

const theme = createTheme({})
const useStyles = makeStyles({
  noteContent: {
    marginBottom: '1rem',
    '& .MuiFormControl-root': {
      width: '100%',
    },
  },
  matchInfoHeader: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  matchTypeClass: {
    width: 'calc(100% - 2rem)',
    height: '35px',
    borderRadius: '6px',
    color: '#a7a7a7',
    fontSize: '13px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 1rem',
    '& strong': {
      textTransform: 'capitalize',
    },
  },
  matchPlace: {
    width: 'calc(100% - 2rem)',
    height: '35px',
    background: '#f1f1f1',
    borderRadius: '6px',
    color: '#a7a7a7',
    fontSize: '13px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 1rem',
    '& strong': {
      textTransform: 'capitalize',
    },
  },
  matchContent: {
    margin: '1rem',
    display: 'flex',
    flexWrap: 'wrap',
    color: '#9d9d9d',
    '& > div': {
      marginBottom: '0.5rem',
      width: '49%',
    },
  },
  EditmatchContent: {
    margin: '0rem 1rem 1rem 1rem',
    color: '#9d9d9d',
  },
  matchLocation: {
    '& .MuiSvgIcon-root': {
      height: '0.9em',
      width: '0.9em',
      marginLeft: '-3px',
    },
    '& .MuiInputAdornment-root': {
      '& .MuiSvgIcon-root': {
        marginRight: '-3px',
      },
    },
  },
  matchText: {
    marginLeft: '0.8rem',
    color: '#111',
  },
  matchVs: {
    display: 'flex',
    '& .MuiInputAdornment-root': {
      color: '#bababa',
    },
  },
  vsIcon: {
    marginRight: '3px',
  },
  customHr: {
    width: '80%',
    height: '1px',
    background: '#ededed',
    margin: '1rem auto',
  },
  actionEdit: {
    '& .MuiButtonBase-root': {
      width: '100%',
      height: '40px',
      marginTop:'1rem'
    },
    '& .alignRight': {
      textAlign: 'right',
    },
  },
  matchButtons: {
    '& .MuiFormGroup-root': {
      paddingTop: '0',
      paddingBottom: '0',
      '& .MuiFormControlLabel-root': {
        marginBottom: '0',
      },
    },
    '& .MuiRadio-colorPrimary.Mui-checked': {
      '& .MuiSvgIcon-root': {
        fill: '#29a273',
      },
    },
    padding: '0 1rem',
    '& .MuiToggleButtonGroup-root': {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  addOpponentImg: {
    border: '2px dashed #bdbdbd',
    borderRadius: '50%',
    width: '3em',
    height: '3em',
    display: 'flex',
    justifyContent: ' center',
    alignItems: 'center',
  },
  dateTime: {
    '& .MuiIconButton-root': {
      padding: 0,
    },
  },
  CustomDialogUiStyle: {
    '& .MuiInputBase-input': {
      minWidth: '83% !important',
    },
    matchButtons: {
      padding: '0!important',
    },
    matchContent: {
      margin: '1rem 0',
      display: 'block',
      '& div': {
        width: '100%',
      },
    },
  },
  [theme.breakpoints.down('sm')]: {
    matchButtons: {
      padding: '0',
      marginBottom: '.6rem',
    },
    noteContent: {
      marginTop: '1rem',
      marginBottom: '0',
      '& .MuiFormControl-root': {
        width: '100%',
        marginTop: '2px',
      },
    },
  },
  [theme.breakpoints.down('md')]: {
    noteContent: {
      marginTop: '1rem',
    }
  },
  additionalInfo:{
    '& .MuiSvgIcon-root': {
      height: '0.9em',
      width: '0.9em',
      marginLeft: '-3px',
    },
    minWidth:'100%'
  },
  textarea: {
    '& .MuiInputBase-root': {
      height: 'unset',
      paddingTop: 'unset',
      paddingBottom: 'unset',
    },
  },
})

const styles = () => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

const CustomDialog = styled(Dialog)`
  hr {
    width: 100%;
  }

  .MuiFormControl-root {
    min-width: 100%;
    // margin-bottom: 0.6rem;
  }
  .matchcontent: {
    margin: 1rem 0;
    display: block;
    div {
      width: 100%;
    }
  }
`

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

// eslint-disable-next-line no-shadow
const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const MatchInfo = ({ handleSave,dateFormat }) => {
  const {
    // eslint-disable-next-line
    matchState: { opponent, location, date, home, type,street,city,country,postal_code,lat,lng,short_address,state,additional_info,meeting_time, end_time },
    onFieldChange,
    allFieldChange
  } = useContext(Context)
  const classes = useStyles()
  const [matchData, setMatchData] = useState({
    home: 1,
    date: '',
    type: '',
    location: '',
    opponent: '',
    street:'',
    city:'',
    country:'',
    postal_code:'',
    lat:'',
    lng:'',
    short_address:'',
    state:'',
    additional_info:'',
    meeting_time:'',
    end_time:''
  })
  const [editMode, setEditMode] = useState(false)

  const handleMatchStatus = newMatchStatus => {
    const temp = parseInt(newMatchStatus, 10)
    setMatchData({ ...matchData, home: temp })
    onFieldChange('home', temp)
  }
  const handleTextfield = event => {
    onFieldChange(event.target.name, event.target.value)
    setMatchData({ ...matchData, [event.target.name]: event.target.value })
  }

  const handleDateChange = uiDate => {
    setMatchData({ ...matchData, date: uiDate })
    onFieldChange('date', formatDateTimeToServer(uiDate))
  }
  const handleMeetingTimeChange = uiDate => {
    setMatchData({ ...matchData, meeting_time: uiDate })
    onFieldChange('meeting_time', formatDateTimeToServer(uiDate))
  }
  const handleEndTimeChange = uiDate => {
    setMatchData({ ...matchData, end_time: uiDate })
    onFieldChange('end_time', formatDateTimeToServer(uiDate))
  }
  const [matchTempData, setMatchTempData] = useState([])
  const handleEditMode = () => {
    setMatchTempData(matchData)
    if (editMode) {
      allFieldChange(matchTempData)
      setMatchData(matchTempData)
    }
    setEditMode(!editMode)
  }

  useEffect(() => {
    setMatchData({
      ...matchData,
      home,
      date,
      location,
      opponent,
      type,
      street,city,country,postal_code,lat,lng,short_address,state,
      additional_info,
      meeting_time,
      end_time
    })
    // eslint-disable-next-line
  }, [opponent, location, date, home, type,street,city,country,postal_code,lat,lng,short_address,state,additional_info, meeting_time, end_time])

  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
    setMatchTempData(matchData)
  }

  const handleClose = () => {
    setOpen(false)
  }
  const handleCancel = () => {
    setOpen(false)
    setEditMode(false)
    allFieldChange(matchTempData)
    setMatchData(matchTempData)
  }

  const [matchType, setMatchType] = React.useState(matchData.type) //eslint-disable-line

  const matchTypeChange = event => {
    setMatchData({ ...matchData, type: event.target.value })
    onFieldChange('type', event.target.value)
    setMatchType(event.target.value)
  }

  return (
    <div>
      <Paper className={classes.noteContent} elevation={0}>
        <Card>
          <CardHeader
            className={classes.styledCardHeader}
            title={
              <Typography variant="h4" component="h4">
                <Translate id="matches.match-info" />
              </Typography>
            }
            action={
              <div className={classes.actionHeader}>
                <Hidden only={['xs', 'sm']}>
                  {' '}
                  <IconButton onClick={handleEditMode}>
                    <img src={editIcon} alt={editIcon} />
                  </IconButton>
                </Hidden>
                <Hidden only={['md', 'lg', 'xl']}>
                  {' '}
                  <IconButton onClick={handleClickOpen}>
                    <img src={editIcon} alt={editIcon} />
                  </IconButton>
                </Hidden>
              </div>
            }
          />
          {/* <br /> */}
          {!editMode && (
            <Grid container>
              <Grid item xs={12}>
                <div className={classes.matchPlace}>
                  <strong>
                    {matchData.home === 1 ? (
                      <Translate id="matches.home-game" />
                    ) : (
                      <Translate id="matches.away-game" />
                    )}
                  </strong>
                </div>
              </Grid>
              <Grid item xs={12}>
                <br />
                <div className={classes.matchTypeClass}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="match-type-label">
                      <Translate id="matches.match-type" />
                    </InputLabel>
                    <Select
                      labelId="match-type-label"
                      id="match-type"
                      value={matchData.type}
                      onChange={() => {}}
                      inputProps={{ readOnly: true }}
                      label={<Translate id="matches.match-type" />}
                    >
                      {matchTypes.map(element=>
                        <MenuItem key={element.type} value={element.type}>
                          {element.title}
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.matchContent}>
                  <div className={classes.matchVs}>
                    <div className={classes.vsIcon}>
                      <Translate id="matches.vs" />
                    </div>
                    <span className={classes.matchText}>
                      {matchData.opponent}
                    </span>
                  </div>
                  <div className={classes.matchDate}>
                    <img src={calendarIcon} alt={calendarIcon} />
                    <span className={classes.matchText}>
                      {formatCostumDateMonthNoForUI(matchData.date,dateFormat)}
                    </span>
                  </div>
                  <div className={classes.matchTime}>
                    <img src={timeIcon} alt={timeIcon} />
                    <span className={classes.matchText}>
                      {formatDateFromServer(matchData.date)[1]} (<span style={{"fontSize":"10px"}}><Translate id="matches.kickoff-time" /></span>)
                    </span>
                  </div>
                  <div className={classes.matchLocation}>
                    <LocationOnOutlinedIcon />
                    <span className={classes.matchText}>
                      <Link
                        href={`https://maps.google.com/?q=${matchData.lat},${matchData.lng}`}
                        target="_blank"
                      >
                        {matchData.short_address}
                      </Link>
                    </span>
                  </div>
                  <>
                    <div className={classes.matchVs}>
                      <div className={classes.vsIcon}>
                      <img src={timeIcon} alt={timeIcon} />
                      </div>
                      <span className={classes.matchText}>
                        {matchData.meeting_time && display24HrTime(matchData.meeting_time)} (<span style={{"fontSize":"10px"}}><Translate id="matches.meeting-time" /></span>)
                      </span>
                    </div>
                    <div className={classes.matchVs}>
                      <div className={classes.vsIcon}>
                      <img src={timeIcon} alt={timeIcon} />
                      </div>
                      <span className={classes.matchText}>
                        {matchData.end_time && display24HrTime(matchData.end_time)} (<span style={{"fontSize":"10px"}}><Translate id="matches.end-time" /></span>)
                      </span>
                    </div>
                  </>
                  {
                    matchData.additional_info && 
                    <div className={classes.additionalInfo}>
                    <DescriptionIcon />
                      <span className={classes.matchText}>
                          {matchData.additional_info}
                      </span>
                    </div>
                  }
                </div>
              </Grid>
            </Grid>
          )}
          {editMode && (
            <Grid container>
              <Grid item xs={12}>
                <div className={classes.matchButtons}>
                  <FormControl component="fieldset">
                    <Translate>
                      {({ translate }) => (
                        <RadioGroup
                          row
                          aria-label="MatchStatus"
                          onChange={(e) => handleMatchStatus(e.target.value)}
                          name="MatchStatus"
                          value={home}
                        >
                          <FormControlLabel
                            value={1}
                            control={<Radio color="primary" />}
                            label={translate('matches.home-game')}
                          />
                          <FormControlLabel
                            value={0}
                            control={<Radio color="primary" />}
                            label={translate('matches.away-game')}
                          />
                        </RadioGroup>
                      )}
                    </Translate>
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.EditmatchContent}>
                    <FormControl variant="outlined" margin="dense">
                      <InputLabel id="match-type-label">
                        <Translate id="matches.match-type" />
                      </InputLabel>
                      <Select
                        labelId="match-type-label"
                        id="match-type"
                        value={matchData.type}
                        onChange={matchTypeChange}
                        label={<Translate id="matches.match-type" />}
                      >
                        {matchTypes.map(element=>
                          <MenuItem key={element.type} value={element.type}>
                            {element.title}
                          </MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  <TextField
                    id="input-with-icon-textfield1"
                    variant="outlined"
                    value={matchData.opponent}
                    name="opponent"
                    onChange={handleTextfield}
                    margin="dense"
                    label={<Translate id="team.opponent" />}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <div>
                            <Translate id="matches.vs" />
                          </div>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <DatePicker
                    required
                    label={<Translate id="training.date" />}
                    selectedValue={matchData.date}
                    onDateChange={handleDateChange}
                    isEditMode
                    format={dateFormat}
                  />
                  <TimePicker
                    selectedValue={matchData.date}
                    onDateChange={handleDateChange}
                    label={<Translate id="matches.kickoff-time" />}
                    isEditMode
                  />
                  <>
                    <TimePicker
                      selectedValue={matchData.meeting_time}
                      onDateChange={handleMeetingTimeChange}
                      label={<Translate id="matches.meeting-time" />}
                      isEditMode={!!matchData.meeting_time}
                    />
                    <TimePicker
                      selectedValue={matchData.end_time}
                      onDateChange={handleEndTimeChange}
                      label={<Translate id="matches.end-time" />}
                      isEditMode={!!matchData.end_time}
                    />
                  </>
                  <PlacesSearchField
                    defaultValue={matchData.location}
                    id="input-with-icon-textfield4"
                    variant="outlined"
                    savedValue={matchData.location}
                    name="location"
                    onFieldChange={allFieldChange}
                    changeFormData={setMatchData}
                    eventData={matchData}
                  />
                  <TextField
                    name="additional_info"
                    variant="outlined"
                    margin="dense"
                    value={matchData.additional_info}
                    multiline
                    rows={4}
                    label={<Translate id="matches.additional-info" />}
                    onChange={handleTextfield}
                    className={classes.textarea}
                  />
                  <div className={classes.actionEdit}>
                    <Grid container>
                      <Grid item lg={6} md={6} xs={6}>
                        <Button color="primary" onClick={handleCancel}>
                          <Translate id="button.cancel" />
                        </Button>
                      </Grid>
                      <Grid item lg={6} md={6} xs={6} className="alignRight">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            handleSave().then(() => setEditMode(false))
                          }
                        >
                          <Translate id="button.save" />
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </Grid>
            </Grid>
          )}

          <CustomDialog
            open={open}
            onClose={handleCancel}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="customized-dialog-title" onClose={handleCancel}>
              <Translate id="matches.match-defails" />
            </DialogTitle>

            <DialogContent className={classes.CustomDialogUiStyle}>
              <Grid container>
                <Grid item xs={12}>
                  <div className={classes.matchButtons}>
                    <FormControl component="fieldset">
                      <Translate>
                        {({ translate }) => (
                          <RadioGroup
                            row
                            aria-label="MatchStatus"
                            onChange={(e) => handleMatchStatus(e.target.value)}
                            name="MatchStatus"
                            value={home}
                          >
                            <FormControlLabel
                              value={1}
                              control={<Radio color="primary" />}
                              label={translate('matches.home-game')}
                            />
                            <FormControlLabel
                              value={0}
                              control={<Radio color="primary" />}
                              label={translate('matches.away-game')}
                            />
                          </RadioGroup>
                        )}
                      </Translate>
                    </FormControl>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="matchContent">
                    <div className={classes.matchVs}>
                      <FormControl variant="outlined" margin="dense">
                        <InputLabel id="match-type-label">
                          <Translate id="matches.match-type" />
                        </InputLabel>
                        <Select
                          labelId="match-type-label"
                          id="match-type"
                          value={matchData.type}
                          onChange={matchTypeChange}
                          label={<Translate id="matches.match-type" />}
                        >
                          {matchTypes.map(element=>
                            <MenuItem key={element.type} value={element.type}>
                              {element.title}
                            </MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </div>
                    <div className={classes.matchVs}>
                      <TextField
                        id="input-with-icon-textfield1"
                        variant="outlined"
                        value={matchData.opponent}
                        name="opponent"
                        onChange={handleTextfield}
                        margin="dense"
                        label={<Translate id="team.opponent" />}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <div>
                                <Translate id="matches.vs" />
                              </div>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <div className={classes.dateTime}>
                      <DatePicker
                        required
                        label={<Translate id="training.date" />}
                        selectedValue={matchData.date}
                        onDateChange={handleDateChange}
                        isEditMode
                        format={dateFormat}
                      />
                      <TimePicker
                        selectedValue={matchData.date}
                        onDateChange={handleDateChange}
                        label={<Translate id="training.time" />}
                        isEditMode
                      />
                    </div>
                    <TimePicker
                      selectedValue={matchData.meeting_time}
                      onDateChange={handleMeetingTimeChange}
                      label={<Translate id="matches.meeting-time" />}
                      isEditMode={!!matchData.meeting_time}
                    />
                    <TimePicker
                      selectedValue={matchData.end_time}
                      onDateChange={handleEndTimeChange}
                      label={<Translate id="matches.end-time" />}
                      isEditMode={!!matchData.end_time}
                    />
                    <div className={classes.matchLocation}>
                      <PlacesSearchField
                        defaultValue={matchData.location}
                        id="input-with-icon-textfield4"
                        variant="outlined"
                        savedValue={matchData.location}
                        name="location"
                        onFieldChange={allFieldChange}
                        changeFormData={setMatchData}
                        eventData={matchData}
                      />
                    </div>
                    <TextField
                      name="additional_info"
                      variant="outlined"
                      margin="dense"
                      value={matchData.additional_info}
                      multiline
                      rows={4}
                      label={<Translate id="matches.additional-info" />}
                      onChange={handleTextfield}
                      className={classes.textarea}
                    />
                    <div className={classes.actionEdit}>
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => handleSave().then(handleClose)}
                      >
                        <Translate id="button.save" />
                      </Button>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </DialogContent>
          </CustomDialog>
        </Card>
      </Paper>
    </div>
  )
}

MatchInfo.propTypes = {
  handleSave: PROP_TYPES.func.isRequired,
  dateFormat: PROP_TYPES.string.isRequired,
}

export default connect(({ matches: { current: { team = {} } },auth }) => ({
  teamName: team.name,
  dateFormat: auth.user.datetime_format,
}))(MatchInfo)
