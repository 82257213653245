import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import Joi from 'joi'
import { Translate } from 'react-localize-redux'
import { reduxForm, Field, FieldArray } from 'redux-form'
import { makeStyles } from '@material-ui/core/styles'
import {
  HasClubPermission,
  ImagesUploader,
  LanguageSelectorForAdmins,
} from 'containers'
import { Button, Grid, Typography, Box, Toolbar, Paper } from '@material-ui/core'
import {
  Input,
  InputNumber,
  LinkBack,
  FroalaTextEditor,
  CategoriesSelector,
  ActionWithPrompt,
  Checkbox,
  AgeClassSelector,
} from 'components'
import createJoiValidator from 'utils/createJoiValidator'
import PROP_TYPES from 'constants/propTypes'
import PERMISSIONS from 'constants/permissions'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    margin: theme.spacing(2, 0, 0),
    padding: theme.spacing(3),
    '& .MuiTypography-h5': {
      marginTop:theme.spacing(1.4 ),
      marginBottom:theme.spacing(0.9),
    }
  },
  toolbar: {
    minHeight: 56,
    padding: theme.spacing(0, 2),
  },
  title: {
    flex: '1 1 0',
  },
  spacing: {
    marginRight: theme.spacing(2),
  },
  btnContainer:{
    display: 'flex',
    justifyContent: 'end',
    paddingTop: '1rem'
  },
  deleteBtn:{
    border: 0,
    color: "#dc3545"
  }
}))

const validationSchema = Joi.object().keys({
  title: Joi.string()
    .max(255)
    .required(),
  description: Joi.any(),
  drill_flow: Joi.any(),
  organization: Joi.any(),
  coaching_points: Joi.any(),
  variations: Joi.any(),
  video_url: Joi.any(),
  images: Joi.array().has(Joi.any()),
  global: Joi.any().allow(0, 1),
  duration: Joi.any(),
  age_class: Joi.array().has(Joi.number()),
  min_players: Joi.any(),
  max_players: Joi.any(),
  categories: Joi.array().has(Joi.number()),
})
// TODO: form works slow
const DrillCreateEditForm = ({
  caption,
  fromPath,
  drillsCategories,
  isEditForm,
  showDeleteButton,
  handleDelete,
  handleSubmit,
  // reset,
  ageClasses,
}) => {

  const movesboard = 'https://movesboard.app/#/'
  const classes = useStyles()

  return (
    <Translate>
      {({ translate }) => (
        <>
          <Toolbar className={classes.toolbar} component={Paper}>
            <Typography variant="h4" component="h4" className={classes.title}>
              {caption} {isEditForm && <LanguageSelectorForAdmins />}
            </Typography>

            {/* <Button onClick={reset}><Translate id="button.reset"/></Button> */}
            <LinkBack
              to={fromPath.value}
              className={classes.spacing}
              title={translate('button.cancel')}
              isFullPath
            />
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              {translate('button.save')}
            </Button>
          </Toolbar>
          <form>
            <Paper className={classes.root}>
              <Grid container spacing={2}>
                <Grid item md={8}>
                  <Box>
                    <Typography variant="h5"><Translate id="drills.name" /></Typography>
                    <Field
                      name="title"
                      placeholder={translate('drills.name')}
                      component={Input}
                    />
                  </Box>
                  <Box>
                    <Typography variant="h5">
                      <Translate id="drills.description" />
                    </Typography>
                    <Field
                      name="description"
                      placeholder={translate('drills.description')}
                      component={Input}
                    />
                  </Box>
                  <Box>
                    <Typography variant="h5">
                      <Translate id="drills.drill-flow" />
                    </Typography>
                  </Box>
                  <Box>
                    <Field
                      name="drill_flow"
                      placeholder={translate('drills.drill-flow')}
                      component={FroalaTextEditor}
                    />
                  </Box>
                  <Box>
                    <Typography variant="h5">
                      <Translate id="drills.organization" />
                    </Typography>
                  </Box>
                  <Box>
                    <Field
                      name="organization"
                      placeholder={translate('drills.organization')}
                      component={FroalaTextEditor}
                    />
                  </Box>
                  <Box>
                    <Typography variant="h5">
                      <Translate id="drills.coaching-points" />
                    </Typography>
                  </Box>
                  <Box>
                    <Field
                      name="coaching_points"
                      placeholder={translate('drills.coaching-points')}
                      component={FroalaTextEditor}
                    />
                  </Box>
                  <Box>
                    <Typography variant="h5">
                      <Translate id="drills.variations" />
                    </Typography>
                  </Box>
                  <Box>
                    <Field
                      name="variations"
                      placeholder={translate('drills.variations')}
                      component={FroalaTextEditor}
                    />
                  </Box>
                </Grid>
                <Grid item md={4}>
                  <FieldArray name="images" component={ImagesUploader} />
                  <Box>
                    <Typography variant="h5"> <Translate id="drills.video-url" /></Typography>
                    <Field
                      name="video_url"
                      label={translate('drills.activity-video')}
                      component={Input}
                    />
                  </Box>
                  <HasClubPermission ability={PERMISSIONS.CREATE_GLOBAL_DRILL}>
                    <Field name="global" label={translate('form.global-checkbox-text')} component={Checkbox} />
                  </HasClubPermission>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <Typography variant="h6">
                        <Translate id="drills.duration" />
                      </Typography>
                      <Field
                        name="duration"
                        minLimit={0}
                        maxLimit={90}
                        label={translate('time.minute')}
                        component={InputNumber}
                      />
                    </Grid>
                  </Grid>
                  <Box>
                    <Typography variant="h5">
                      <Translate id="form.age" />
                    </Typography>
                    <Field
                      name="age_class"
                      component={AgeClassSelector}
                      items={ageClasses}
                    />
                  </Box>
                  <Box>
                    <Typography variant="h6">
                      <Translate id="drills.number-of-players" />
                    </Typography>
                  </Box>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <Field
                        name="min_players"
                        label={`Min ${translate('drills.players')}`}
                        minLimit={1}
                        component={InputNumber}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        name="max_players"
                        label={`Max ${translate('drills.players')}`}
                        minLimit={1}
                        component={InputNumber}
                      />
                    </Grid>
                  </Grid>
                  <Box mt={2}>
                  <Button color="primary" variant="contained" href={movesboard} target="_blank">
                  <Translate id="button.draw-activity" />
                </Button>
                </Box>
                </Grid>
                <Grid item md={12}>
                  <Box>
                    <Typography variant="h5">
                      <Translate id="drills.categories" />
                    </Typography>
                    <Field
                      name="categories"
                      component={CategoriesSelector}
                      items={drillsCategories}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Box className={classes.btnContainer}>
                {showDeleteButton && (
                  <ActionWithPrompt
                    onSuccess={handleDelete}
                    message={translate('message.delete')}
                    title={translate('button.delete')}
                    className={classes.deleteBtn}
                  />
                )}
                <LinkBack to={fromPath.value} title={translate('button.cancel')} isFullPath />
                <Button color="primary" variant="contained" onClick={handleSubmit}>
                  <Translate id="button.save" />
                </Button>
              </Box>
            </Paper>
          </form>
        </>
      )}
    </Translate>
  )
}

DrillCreateEditForm.defaultProps = {
  drillsCategories: [],
  isEditForm: false,
  showDeleteButton: false,
  handleDelete: () => 0,
  ageClasses: [],
}

DrillCreateEditForm.propTypes = {
  form: PropTypes.string.isRequired, // eslint-disable-line
  initialValues: PropTypes.shape(), // eslint-disable-line
  caption: PropTypes.string.isRequired,
  fromPath: PropTypes.shape({
    value: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired, // eslint-disable-line
  drillsCategories: PROP_TYPES.arrayOfObjects,
  ageClasses: PROP_TYPES.arrayOfObjects,
  isEditForm: PROP_TYPES.bool,
  showDeleteButton: PROP_TYPES.bool,
  handleDelete: PROP_TYPES.func,
  handleSubmit: PropTypes.func.isRequired,
  // reset: PropTypes.func.isRequired,
}

export default compose(
  connect(
    ({ ageClasses }) => ({
      ageClasses: ageClasses.items,
    }),
    {}
  ),
  reduxForm({
    enableReinitialize: true,
    validate: createJoiValidator(validationSchema),
  })
)(DrillCreateEditForm)
