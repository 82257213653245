import React from 'react'
import { RemoveButton } from 'components'
import PROP_TYPES from 'constants/propTypes'

const WrappedRemoveButton = ({ onClick }) => <RemoveButton onClick={onClick} />

WrappedRemoveButton.defaultProps = {
  onClick: () => 0,
}

WrappedRemoveButton.propTypes = {
  onClick: PROP_TYPES.func,
}

export default WrappedRemoveButton
