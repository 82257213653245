import styled from 'styled-components'
import UploadImage from 'assets/images/16px/droppable-plus@1x.svg'

export const SessionPartWrapper = styled.div`
  font-size: 14px;
  letter-spacing: 0.1px;
  line-height: 1;
  color: #000;

  > div {
    margin-top: 10px;
    margin-bottom: 20px;
  }
`
export const DrillsList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const DrillsListItem = styled.div`
  margin: 2px 0;
  line-height: 15px;
  font-size: 12px;
  letter-spacing: 0.09px;
  color: #000;

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
  }
`

export const UploadIcon = styled.div`
  display: block;

  width: 50px;
  height: 50px;
  background: url(${UploadImage}) no-repeat center;
  background-size: contain;
`
