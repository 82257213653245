/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import {
  formatCostumDateForUI,
} from 'utils/formatDate'

import { thirdParty } from 'redux/modules'
import {
  Paper,
  CardContent,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Typography,
  Button
} from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh';
import { removePolar } from 'utils/localstorage'
import RotateRightIcon from '@material-ui/icons/RotateRight';
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Translate } from 'react-localize-redux'
import PROP_TYPES from 'constants/propTypes'
import { ALL_ITEMS } from 'constants/thirdParty'

const PolarSummary = ({
  setPolarTeam,
  selectedPolarEevent,
  setSelectedPolarEvent,
  setPolarTrainning,
  polarFetchTeam,
  polarFetchTeamTrainings,
  setPolarToken,
  polarToken,
  isGame
}) => {

  const thirdPartyArray=[...ALL_ITEMS]
  const [polarTrainings,setPolarTrainings]=useState([])
  const [teamId,setTeamId]=useState()
  const fetchPolarTeamTrainnings=(polarTeamId,polarId,polarNewData=0)=>{

    polarFetchTeamTrainings(polarTeamId,{polarId,polarNewData})
    .then((res) =>res.response)
    .then(json=>{
      if(json.error && json.error==='invalid_token'){
        removePolar()
        setPolarTrainings(null)
        setPolarTeam(null)
        setPolarToken(null)

        return
      }
      if(isGame){
        json.data=json.data.filter(p=>p.type==='GAME')
      }
      else{
        json.data=json.data.filter(p=>p.type==='TRAINING')
      }
      setPolarTrainings(json)
    })
    .catch((e) => {
      console.error(e);
    });
     
  }
  const fetchPolarTeam=(polarId)=>{
    polarFetchTeam({polarId})
    .then((res) => res.response)
    .then(json=>{
      if(json.error && json.error==='invalid_token'){
        removePolar()
        setPolarTeam(null)
        setPolarTrainings(null)
        setPolarToken(null)
        setTeamId(null)

        return
      }
      fetchPolarTeamTrainnings(json[0].id,polarId)
      setPolarTeam(json[0])
      setTeamId(json[0].id)
    })
    .catch((e) => {
      console.error(e);
    });
      
  }
  useEffect(() => {
    if(polarToken && polarToken.id){
      fetchPolarTeam(polarToken.id)
    }
  }, []);

  return (
<Paper component={Box} mb={3}>
    <Box px={2} pt={2}> 
    <Typography variant='h3' component='h3'> {(polarToken && polarToken.id)?<Translate id="polar.polar-connected" />:<Translate id="polar.polar-not-connected" />}</Typography>
  
    </Box>
    <CardContent component={Box} px={3} pt={3} style={{paddingBottom:15}} > 
    {(!polarToken) && <Button
                          size="small"
                          variant="contained"
                          color="primary"                          
                          onClick={() => {
                            window.location.href = thirdPartyArray[0].url
                          }}><Translate id="polar.button.connect-btn"/></Button>}
      {polarToken && polarTrainings && polarTrainings.data && polarTrainings.data.length>0 ? 
      <Box display="flex" alignItems="center">
        <FormControl fullWidth variant='outlined' size='small' style={{marginBottom:0, marginRight:10}}>
        <InputLabel id="demo-simple-select-label"><Translate id="polar.polar-events" /></InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedPolarEevent}
            label="Polar Trannings"
            onChange={(e)=>{
              setSelectedPolarEvent(e.target.value)
              setPolarTrainning(polarTrainings.data.find(p=>p.id===e.target.value))
            }}
          >
            {polarTrainings.data.map(trainng=>(<MenuItem key={trainng.id} value={trainng.id}>{formatCostumDateForUI(new Date(trainng.start_time.replace('T',' ')),"dddd, MMMM Do YYYY, h:mm:ss a")} {trainng.is_sync===1 && <IconButton size="small"><RotateRightIcon /></IconButton>}</MenuItem>))}
          </Select>
         </FormControl>
         <Button variant="contained" color="primary">
         <RefreshIcon onClick={()=>fetchPolarTeamTrainnings(teamId,polarToken.id,1)} />
         </Button>
        <IconButton size="small"> </IconButton>
        </Box>:<h4>No events</h4>
      }
      </CardContent>
      </Paper>

  )
}
PolarSummary.defaultProps = {
  isGame:false
}

PolarSummary.propTypes = {
  polarFetchTeam: PROP_TYPES.func.isRequired,
  polarFetchTeamTrainings: PROP_TYPES.func.isRequired,
  setPolarToken: PROP_TYPES.func.isRequired,
  setPolarTeam: PROP_TYPES.func.isRequired,
  setPolarTrainning: PROP_TYPES.func.isRequired,
  setSelectedPolarEvent: PROP_TYPES.func.isRequired,
  polarToken: PROP_TYPES.shape().isRequired,
  selectedPolarEevent: PROP_TYPES.number.isRequired,
  isGame: PROP_TYPES.bool,
}

export default compose(
  withRouter,
  connect(
    ({ users }) => ({
      users,
    }),
    {
      polarFetchTeam: thirdParty.polarFetchTeam,
      polarFetchTeamTrainings: thirdParty.polarFetchTeamTrainings,
    }
  )
)(PolarSummary)
