import { createActions, handleActions, combineActions } from 'redux-actions'
import initialState from '../initialState'


export const { setcode } = createActions({
  SETCODE: item => ({ item })
})

export default handleActions(
  {
    [combineActions(setcode)]: (
      state,
      {payload: { item }}
    ) => {
      return { ...state, items: state.items.map(el => {
        if(item.name === el.name){
          return item
        }

        return el
      })}
    }
  },
  initialState.polar
)
