import styled from 'styled-components'

export default styled.div`
  display: ${({ block }) => (block ? 'block' : 'inline-block')};
  padding: 5px 10px;
  min-width: ${({ minWidth }) => minWidth}px;
  margin: 0 5px;
  font-size: 14px;
  line-height: 1.5;
  color: ${({ secondary }) => (secondary ? 'var(--secondary-green)' : '#fff')};
  text-align: center;
  cursor: pointer;
  border-radius: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ secondary }) =>
    secondary ? 'var(--secondary-green)' : '#0e4b4d'};
  background: ${({ secondary }) => (secondary ? 'transparent' : '#0e4b4d')};
  box-shadow: ${({ secondary }) =>
    secondary ? 'none' : '0px 2px 7px 0 rgba(0, 0, 0, 0.5)'};
`
