import React, { useContext, useMemo } from 'react'
import { Translate } from 'react-localize-redux'
import { withRouter } from 'react-router-dom'
import sortBy from 'lodash.sortby'
import { MiniCalendar } from 'containers'
import { Card } from 'components'
import urlConstructor from 'utils/urlConstructor'
import { formatDateFromServer, formatDateTimeToServer } from 'utils/formatDate'
import styled from 'styled-components'
import ScheduleIcon from '@material-ui/icons/Schedule'
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined'
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined'
import CloseIcon from '@material-ui/icons/Close'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import editIcon from 'assets/images/16px/edit@1x.svg'
import {
  DialogContent,
  InputAdornment,
  Button,
  IconButton,
  Dialog,
  TextField,
  Grid,
} from '@material-ui/core'
import moment from 'moment'
import DateFnsUtils from '@date-io/date-fns'
import { makeStyles } from '@material-ui/core/styles'

import {
  TimePicker,
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import { Context } from '../TrainingStateContext'
import { TRAINING_DASHBOARD_ROUTE } from 'constants/routes'
import PROP_TYPES from 'constants/propTypes'

const useStyles = makeStyles({
  dateContent: {
    width: '100%',
    marginBottom: '0.5rem',
    '& .MuiButtonBase-root': {
      marginRight: '-14px',
    },
  },
})

const TrainingInfoSection = styled.div`
  .training-info {
    color: #a6a6a6;
    margin-bottom: 0.5rem;
    font-size: 14px;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    span {
      margin-right: 0.8rem;
      svg {
        font-size: 19px;
      }
    }
  }
`
const SummaryCard = styled(Card)`
  padding: 1rem;
  .form-group {
    margin-bottom: 0px;
    min-height: inherit;
  }

  .date-selector {
    width: 100%;
    font-size: 16px;
    color: #888;
    border-radius: 15px;

    box-shadow: inset 0px 0 30px 2px rgba(190, 190, 190, 0.7);
    background: #fff;

    &:active,
    &:focus {
      border-color: #000000;
      box-shadow: inset 0px 0 30px 2px rgba(190, 190, 190, 0.5);
    }
  }

  .time-selector,
  .place-selector {
    margin: 0 10%;
    width: 80%;
    font-size: 16px;
    color: #888;
    border-radius: 15px;
    text-transform: capitalize;
    box-shadow: inset 0px 0 30px 2px rgba(190, 190, 190, 0.7);
    background: #fff;

    height: 40px;
    padding: 6px 12px;
    font-weight: 400;
    line-height: 1.5;

    border-color: var(--dark-silver);
    border-width: 1.2px;

    &:active,
    &:focus {
      border-color: #000000;
      box-shadow: inset 0px 0 30px 2px rgba(190, 190, 190, 0.5);
    }
  }

  .place-selector {
    text-transform: capitalize;
  }

  @media only screen and (max-width: 640px) {
    .date-selector,
    .time-selector,
    .place-selector {
      margin: 0;
      text-transform: capitalize;
      width: 100%;
    }
  }
`
const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  h4 {
    font-weight: 600;
    font-size: 16px;
    color: #062221;
    margin: 0;
  }
  span {
    color: #868686;
  }
`
const StyledDialog = styled(Dialog)`
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  .MuiDialog-paper {
    width: 100%;
    .MuiDialogContent-root {
      padding: 1rem;
    }
  }
`
const StyledDialogContent = styled(DialogContent)`
  width: 100%;
  padding: 1rem;
  .dialog-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h4 {
      font-size: 1rem;
      font-weight: 600;
    }
    .closeDialog {
      // position: absolute;
      // top: 10px;
      // right: 10px;
      // z-index: 10;
    }
    .MuiFormControl-root {
      width: 100%;
      .MuiInputAdornment-root {
        margin-right: -12px;
      }
    }
  }
  .form-group {
    margin: 0;
    min-height: auto;
  }
  .time-selector,
  .place-selector {
    width: 100%;
    margin: 0;
    text-align: left;
  }
  .dialog-action {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    .submit-btn {
      background: #3ea372;
      border-radius: 8px;
      font-size: 16px;
      color: #ffffff;
      font-weight: 600;
      text-transform: capitalize;
    }
    .cancel-btn {
      background: transparent;
      font-size: 16px;
      font-weight: 600;
      color: #37a67b;
      text-transform: capitalize;
    }
  }
`

const MiniCalendarAndSummary = ({
  trainingId,
  availableTrainings,
  match,
  history,
  handleSave,
}) => {
  const classes = useStyles()

  const {
    params: { teamId, seasonId },
  } = match

  const sortedTrainings = useMemo(
    () => sortBy(availableTrainings, ({ date }) => date),
    [availableTrainings]
  )

  const onEventSelected = id =>
    history.push(
      urlConstructor(
        teamId,
        seasonId,
        TRAINING_DASHBOARD_ROUTE.populateURLParams({ trainingId: id })
      )
    )

  const {
    trainingState: { date, location },
    onFieldChange,
  } = useContext(Context)
  const [dateDay, time] = formatDateFromServer(date)

  const [open, setOpen] = React.useState(false)
  const [locationModal, setLocationModal] = React.useState(location)
  const [dateModal, setDateModal] = React.useState(new Date(date))

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }
  const onLocationChange = value => {
    setLocationModal(value)
    onFieldChange('location', value)
  }

  const onDateChange = value => {
    setDateModal(value)
    onFieldChange('date', formatDateTimeToServer(value))
  }

  return (
    <Grid container spacing={3}>
      <Grid item md={9} xs={12}>
        <MiniCalendar
          currentEventId={trainingId}
          events={sortedTrainings}
          onEventSelected={onEventSelected}
        />
      </Grid>
      <Grid item md={3} xs={12}>
        <SummaryCard>
          <CardHeader>
            <h4>Training - Team</h4>
            <span>
              <IconButton onClick={handleClickOpen}>
                <img src={editIcon} alt={editIcon} />
              </IconButton>
            </span>
          </CardHeader>
          <StyledDialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <StyledDialogContent>
              <div className="dialog-header">
                <h4>Edit Training</h4>
                <div className="closeDialog">
                  <IconButton onClick={handleClose}>
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </div>
              </div>
              <Grid>
                <Grid md={12}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid md={12}>
                      <KeyboardDatePicker
                        disableToolbar
                        autoOk
                        variant="outlined"
                        inputVariant="outlined"
                        format="MM/dd/yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        value={dateModal}
                        onChange={onDateChange}
                        showTodayButton
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        className={classes.dateContent}
                      />
                    </Grid>
                    <Grid md={12}>
                      <TimePicker
                        id="time-picker"
                        inputVariant="outlined"
                        clearable
                        ampm={false}
                        type="time"
                        error={false}
                        helperText={null}
                        value={dateModal}
                        minutesStep={15}
                        className={classes.dateContent}
                        onChange={onDateChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <AccessTimeIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid md={12}>
                  <Translate>
                    {({ translate }) => (
                      <TextField
                        error={locationModal === '' || !locationModal}
                        fullWidth
                        defaultValue={locationModal}
                        variant="outlined"
                        placeholder={translate('events.location')}
                        name="location"
                        size="small"
                        onChange={e => onLocationChange(e.target.value)}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <LocationOnIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  </Translate>
                </Grid>
                <Grid md={12}>
                  <div className="dialog-action">
                    <Button
                      className="submit-btn"
                      onClick={() => handleSave().then(handleClose)}
                    >
                      <Translate id="button.save" />
                    </Button>
                    <Button onClick={handleClose} className="cancel-btn">
                      <Translate id="button.cancel" />
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </StyledDialogContent>
          </StyledDialog>
          {/* <Translate id="training.time" /> */}
          <TrainingInfoSection>
            <div className="training-info">
              <span>
                <CalendarTodayOutlinedIcon />
              </span>
              {moment(dateDay).format('DD MMM, YYYY')}
            </div>
            <div className="training-info">
              <span>
                <ScheduleIcon />
              </span>
              {time}
            </div>
            <div className="training-info">
              <span>
                <LocationOnOutlinedIcon />
              </span>
              {location || ''}
            </div>
          </TrainingInfoSection>
        </SummaryCard>
      </Grid>
    </Grid>
  )
}

MiniCalendarAndSummary.defaultProps = {
  availableTrainings: [],
}

MiniCalendarAndSummary.propTypes = {
  trainingId: PROP_TYPES.id.isRequired,
  availableTrainings: PROP_TYPES.arrayOfObjects,
  match: PROP_TYPES.match.isRequired,
  handleSave: PROP_TYPES.func.isRequired,
  history: PROP_TYPES.history.isRequired,
}

export default withRouter(MiniCalendarAndSummary)
