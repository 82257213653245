import React from 'react'
import styled from 'styled-components'
import AddButton from './buttons/AddButton'

const CONTAINER_HEIGHT = 120

const Drill = styled.div`
  > div {
    border: #aaa solid 1px;
    border-radius: 12px;
    position: relative;
    background: #ddd;
    height: ${`${CONTAINER_HEIGHT}px`};

    background: url(${({ image }) => image}) no-repeat center;
    background-size: cover;

    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.5);

    > div:first-child {
      position: absolute;
      top: 50%;
      width: 100%;
      height: ${`${CONTAINER_HEIGHT / 2}px`};
      background: rgba(16, 16, 16, 0.7);
      border-radius: 12px;
      color: rgba(211, 211, 211, 0.8);
      padding: 5px;
      overflow: hidden;
      font-size: 80%;

      // Title
      font-size: 12px;
      font-weight: 200;
      padding: 20px 12px 15px 12px;
      line-height: 1.3;
    }
  }

  .time {
    position: relative;
    line-height: 3;
    font-size: 12px;
    display: inline-block;

    &::after {
      content: 'min';
    }
  }
`

Drill.Duration = styled.div`
  position: absolute;
  left: 0;
  top: 0;

  display: flex;
  color: rgba(211, 211, 211, 0.8);

  > p {
    padding: 10px;
    margin-left: 5px;
  }
`

Drill.CreateNew = styled(({ className }) => (
  <div className={className}>
    <AddButton>+</AddButton>
  </div>
))`
  border: #aaa solid 1px;
  border-radius: 12px;
  position: relative;
  height: ${`${CONTAINER_HEIGHT}px`};

  ${({ theme }) => theme.flexCenter};
`

export default Drill
