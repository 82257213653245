import React, { useState } from 'react'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { TeamCreateEditForm } from 'containers'
import { LoadingSpinner } from 'components'
import { teams } from 'redux/modules'
import { getClub } from 'utils/localstorage'
import { toast } from 'react-toastify'
import { TEAMS_ROUTE } from 'constants/routes'
import PROP_TYPES from 'constants/propTypes'
import { DEFAULT_CLUB_ID } from '../../../constants/club'

const TeamCreateForm = ({open, handleClose, createTeam, history } ) => {

    const fromPath = {
        value: TEAMS_ROUTE,
        title: 'Teams',
      }

    const goBack = () => history.push(fromPath.value)
    const [loader, changeLoader] = useState(false)

    const onSubmit = val => {
        const values = val
        // values.country = getCountry()
        // if (!values.country || values.country === '') {
        //   handalCountyValid(true)
    
        //   return
        // }
        changeLoader(true)
        values.hide_global_drills =
          localStorage.getItem('isGlobalDrill') === 'true' ? 1 : 0
        values.hide_global_training_packages =
          localStorage.getItem('isGlobalTrainingPackages') === 'true' ? 1 : 0
        values.plan = 'basic'
        // handalCountyValid(false)
    
        createTeam(values)
          .then(res => {
            const teamData = res.response.data
            changeLoader(false)
    
            history.push(
              `/team/${teamData.id}/season/${
                teamData.season_id
              }/current-team/true/edit`
            )
          })
          .catch(error => {
            changeLoader(false)
            toast(error.error)
          })
      }
    
      React.useEffect(() => {
        return function cleanup() {
          localStorage.removeItem('isGlobalDrill')
          localStorage.removeItem('country')
          localStorage.removeItem('isGlobalTrainingPackages')
          localStorage.removeItem('selectedCard')
        }
      }, [])

  // @fixme: teams should be grouped by clubs
  const club = getClub();

  return (
     <div>
        {/* <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Create Team</DialogTitle>
       */}
        <Translate>
      {({ translate }) =>
        loader ? (
          <LoadingSpinner />
        ) : (
          <TeamCreateEditForm
            form="teamCreate"
            initialValues={{
              club_id: club ? club.id : DEFAULT_CLUB_ID,
              gender: 'm',
            }}
            title={translate('myteams.create-team')}
            fromPath={fromPath}
            open={open} onClose={handleClose}
            handleClose={handleClose}
            onSubmit={onSubmit}
            goBack={goBack}
          />
        )
      }
    </Translate>
        {/* </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClose} color="primary">
            Subscribe
          </Button>
        </DialogActions> */}
     
    </div>
  )
}

TeamCreateForm.defaultProps = {
    open: false,
}

TeamCreateForm.propTypes = {
    handleClose: PROP_TYPES.func.isRequired,
    open: PROP_TYPES.bool,
    history: PROP_TYPES.history.isRequired,
    createTeam: PropTypes.func.isRequired,
}

export default connect(
    null,
    {
      createTeam: teams.createTeam,
    }
  )(TeamCreateForm)