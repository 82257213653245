import React from 'react'
import TaskGroupLoader from 'loaders/TaskGroupLoader'
import { TeamLoader } from 'loaders'
import TaskGroupContainer from './TaskGroupContainer'
import PROP_TYPES from 'constants/propTypes'

const Task = ({
  match: {
    params: { teamId, taskGroupId },
  },
}) => {
  return (
    <TeamLoader teamId={+teamId} additionalFields={['players']}>
      <TaskGroupLoader taskGroupId={+taskGroupId}>
        <TaskGroupContainer />
      </TaskGroupLoader>
    </TeamLoader>
  )
}

Task.propTypes = {
  match: PROP_TYPES.match.isRequired,
}
export default Task
