import React, { useState, useMemo, useCallback } from 'react'
import hasSubscription from 'utils/hasSubscription'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import moment from 'moment'
import { connect } from 'react-redux'
import { Card, NextCalendarEventWidget, RateRating } from 'components'
import { matches } from 'redux/modules'
import { MATCH_DASHBOARD_ROUTE } from 'constants/routes'

const RateLastMatchWidget = ({
  lastMatch: { id, date, location },
  readOnly,
  updateMatch,
  playerid,
  rating,
}) => {
  const [ratingState, changeRatingState] = useState(rating)

  const momentDate = useMemo(() => moment(date), [date])

  const rateLastMatch = useCallback(
    newRating => {
      if (readOnly) {
        return
      }

      updateMatch(id, { rating: newRating, player_id: playerid, match_id: id })

      changeRatingState(newRating)
    },
    [id]
  )

  return hasSubscription() === 'basic' ? (
    ''
  ) : (
    <Card fullHeight>
      <NextCalendarEventWidget>
        <Translate>
          {({ translate }) => (
            <NextCalendarEventWidget.Title>
              {translate('team.rate-last-match')}
            </NextCalendarEventWidget.Title>
          )}
        </Translate>
        <NextCalendarEventWidget.Calendar
          to={MATCH_DASHBOARD_ROUTE.populateURLParams({
            matchId: id,
          })}
        >
          <div>
            <NextCalendarEventWidget.CalendarTop>
              <NextCalendarEventWidget.CircleNumber>
                {momentDate.format('DD')}
              </NextCalendarEventWidget.CircleNumber>
              <NextCalendarEventWidget.CircleTitle>
                {momentDate.format('MMMM')}
              </NextCalendarEventWidget.CircleTitle>
            </NextCalendarEventWidget.CalendarTop>
            <NextCalendarEventWidget.CalendarBottom>
              {location}
            </NextCalendarEventWidget.CalendarBottom>
          </div>
        </NextCalendarEventWidget.Calendar>
        <RateRating rating={ratingState} onChange={rateLastMatch} />
      </NextCalendarEventWidget>
    </Card>
  )
}

RateLastMatchWidget.defaultProps = {
  readOnly: false,
  playerid: null,
  rating: null,
}

RateLastMatchWidget.propTypes = {
  lastMatch: PropTypes.shape().isRequired,
  readOnly: PropTypes.bool,
  updateMatch: PropTypes.func.isRequired,
  playerid: PropTypes.number,
  rating: PropTypes.number,
}

export default connect(
  null,
  { updateMatch: matches.updateMatch }
)(RateLastMatchWidget)
