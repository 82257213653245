import React, { useState, Children } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import PROP_TYPES from 'constants/propTypes'

const StyledAccordion = styled.div`
  // border-top-left-radius: 15px;
  // border-top-right-radius: 15px;
  // box-shadow: -2px -2px 4px 0 rgba(176, 176, 176, 0.5);

  > div:first-child {
    color: var(--secondary-green);
    text-align: center;
    ${({ active }) => (!active ? 'cursor: pointer' : '')};
  }
`

const Accordion = ({ children, titles }) => {
  const [activeCardIndex, changeActiveCardIndex] = useState(4)

  return (
    <>
      {Children.map(children, (child, index) =>
        index === activeCardIndex ? (
          <StyledAccordion active>
            <div>{titles[index] || ''}</div>
            {child}
          </StyledAccordion>
        ) : (
          <StyledAccordion onClick={() => changeActiveCardIndex(index)}>
            <div>{titles[index] || ''}</div>
          </StyledAccordion>
        )
      )}
    </>
  )
}

Accordion.defaultProps = {
  titles: [],
}

Accordion.propTypes = {
  children: PROP_TYPES.children.isRequired,
  titles: PropTypes.arrayOf(PropTypes.string.isRequired),
}

export default Accordion
