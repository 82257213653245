import React from 'react'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Card, OwnGrid } from 'components'
import groupPlayersByPosition from 'utils/groupPlayersByPosition'
import GoalkeeperIcon from 'assets/images/goalkeeper-circle.svg'
import DefenderIcon from 'assets/images/defender-circle.svg'
import MidfieldIcon from 'assets/images/midfield-circle.svg'
import StrikerIcon from 'assets/images/striker-circle.svg'
// import BallIcon from 'assets/images/coachbetter-ball.svg'

const CountersCard = styled(Card)`
  box-shadow: none;
  padding: 2rem;
  background: #255f64;
  color: white;
`

const Counter = styled(OwnGrid.Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
`

// const Title = styled.h3`
//   font-size: 20px;
//   margin-left: 1rem;
//   font-weight: 400;
// `

// const CardTitleStyles = {
//   color: '#fff',
//   marginBottom: '25px',
// }

const TeamPlayersCounters = ({ players }) => {
  const {
    goalkeepers,
    defenders,
    midfielders,
    strikers,
  } = groupPlayersByPosition(players)

  return (
    <CountersCard>
      <Translate>
        {({ translate }) => (
          <OwnGrid.Row>
            <Counter md={3}>
              <img src={GoalkeeperIcon} alt="" />
              {goalkeepers.length} {translate('team.goalies')}
            </Counter>
            <Counter md={3}>
              <img src={DefenderIcon} alt="" />
              {defenders.length} {translate('team.defender')}
            </Counter>
            <Counter md={3}>
              <img src={MidfieldIcon} alt="" />
              {midfielders.length} {translate('team.midfield')}
            </Counter>
            <Counter md={3}>
              <img src={StrikerIcon} alt="" />
              {strikers.length} {translate('team.striker')}
            </Counter>
          </OwnGrid.Row>
        )}
      </Translate>
    </CountersCard>
  )
}

TeamPlayersCounters.propTypes = {
  // team: PropTypes.shape().isRequired,
  players: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
}

export default TeamPlayersCounters
