import React, { useContext, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { connect } from 'react-redux'
import NavigationPrompt from 'react-router-navigation-prompt'
import { matches } from 'redux/modules'
import filterObject from 'utils/filterObject'
import CircularSpinner from 'components/loader/CircularSpinner'
import { formatDateTimeToServer } from 'utils/formatDate'
import AlertDialog from 'components/AlertDialog'
import { Context } from './MatchStateContext'
import MatchDashboardLayout from './MatchDashboardLayout'
import PROP_TYPES from 'constants/propTypes'

const calculateRelativePosition = (position, fieldPosition) => ({
  position_x:
    Math.round(((position.x - fieldPosition.x) / fieldPosition.width) * 10000) /
    100,
  position_y:
    Math.round(
      ((position.y - fieldPosition.y) / fieldPosition.height) * 10000
    ) / 100,
})

const MatchDashboardContainer = ({ matchId, updateMatch }) => {

  const { matchState, onFieldChange, anyChange, setAnyChange } =
    useContext(Context)

  const [loading, setLoading] = React.useState(false)

  const onDrop = useCallback(
    (playerId, target, newPosition) => {
      const targetPlayer = (matchState.matchPlayers || []).find((mp)=>mp.uuid===playerId)
      if (targetPlayer) {
        if (target === 'notResponded') {
          onFieldChange(
            'matchPlayers',
            matchState.matchPlayers.updateByUuid(playerId, {
              in_roster: 'NOT_RESPONDED',
              participant: false,
              tag: 'not_available'
            })
          )

          return
        }

        if (target === 'notInRoster') {
          if (targetPlayer.in_roster==='AVAILABLE' || targetPlayer.in_roster==='NOT_RESPONDED' || targetPlayer.participant) {
            onFieldChange(
              'matchPlayers',
              matchState.matchPlayers.updateByUuid(playerId, {
                in_roster: 'NOT_AVAILABLE',
                participant: false,
                tag: 'not_available',
              })
            )
          }

          return
        }
        if (targetPlayer.in_roster==='NOT_AVAILABLE' || !targetPlayer.participant) {
          onFieldChange(
            'matchPlayers',
            matchState.matchPlayers.updateByUuid(playerId, {
              in_roster:
                matchState.matchPlayers.filter((player) => player.participant)
                  .length === 11
                  ? matchState.matchPlayers.findByUuid(playerId).in_roster
                  : 'NOT_AVAILABLE',
              participant: false,
              tag: 'available',
            })
          )
        }

        if (!target && !newPosition) {
          onFieldChange(
            'matchPlayers',
            matchState.matchPlayers.updateByUuid(playerId, {
              in_roster: 'AVAILABLE',
              participant: false,
              tag: 'available',
            })
          )

          return
        }

        if (
          matchState.matchPlayers.filter((matchPlayer) => matchPlayer.participant).length ===
            11 &&
          !targetPlayer.participant
        ) {
          return
        }

        const participantPosition = calculateRelativePosition(
          newPosition,
          document.getElementById('participantsField').getBoundingClientRect()
        )

        if (
          participantPosition.position_x < 0 ||
          participantPosition.position_y < 0 ||
          participantPosition.position_x > 100 ||
          participantPosition.position_y > 100
        ) {
          return
        }

        if (target === 'field') {
          if (!targetPlayer.in_roster || !targetPlayer.participant) {
            onFieldChange(
              'matchPlayers',
              matchState.matchPlayers.updateByUuid(playerId, {
                in_roster: 'AVAILABLE',
                participant: true,
                ...participantPosition,
                tag: 'available',
              })
            )

            return
          }
        }

        onFieldChange(
          'matchPlayers',
          matchState.matchPlayers.updateByUuid(playerId, {
            in_roster: 'AVAILABLE',
            participant: true,
            reason: null,
            ...participantPosition,
          })
        )
      }
    },
    [matchState]
  )

  const createNotesForServer = (notes) => {
    return notes.map((note) => {
      return {
        id: note.id,
        notes: note.notes,
        players: note.players,
      }
    })
  }

  const handleSave = useCallback(
    (e = null) => {
      const { goals, allnotes, normalizedPlayers, tickers, matchPlayers, ...restMatchState } =
        matchState

      const hideToastNotification = !!(
        e !== null && e.nativeEvent.type === 'dragend'
      )

      const filteredGoals = goals.filter(
        (goal) => goal.scored_by && goal.minute >= 0
      )

      setAnyChange(false)

      return updateMatch(
        matchId,
        filterObject(
          {
            ...restMatchState,
            highlights: [],
            date:formatDateTimeToServer(restMatchState.date),
            start_time: restMatchState.meeting_time ? formatDateTimeToServer(restMatchState.meeting_time) : null,
            end_time: restMatchState.end_time ? formatDateTimeToServer(restMatchState.end_time) : null,
            goals: filteredGoals,
            status: 'published',
            allnotes: createNotesForServer(allnotes),
            match_players: matchPlayers.map((matchPlayer) => ({
              uuid: matchPlayer.uuid,
              in_roster: matchPlayer.in_roster,
              participant: matchPlayer.participant === true ? 1 : 0,
              position_x: matchPlayer.position_x,
              position_y: matchPlayer.position_y,
              rating: matchPlayer.rating ? matchPlayer.rating : null,
              notes: matchPlayer.notes,
              playing_minutes: matchPlayer.playing_minutes,
              tag: matchPlayer.tag,
              reason: matchPlayer.reason
            })),
            preventToastNotification: hideToastNotification,
          },
          Boolean
        )
      ).then((res) => {
        onFieldChange('allnotes', res.response.data.allnote)
        onFieldChange('tickers', res.response.data.tickers)
        setAnyChange(false)
        setLoading(false)
      })
    },
    [matchId, matchState, anyChange]
  )

  return (
    <>
      {matchState.date && (
        <MatchDashboardLayout
          onDrop={onDrop}
          handleSave={handleSave}
          setLoading={setLoading}
          tickers={matchState.tickers}
          matchPolarSelectedId={matchState.polar_event_id}
          matchId={matchId}
        />
      )}
      <NavigationPrompt when={anyChange}>
        {({ onConfirm, onCancel }) => {
          return (
            <>
              <AlertDialog
                open="true"
                onClose={onCancel}
                onSuccess={() => handleSave().then(onCancel)}
                onCancel={onConfirm}
                title={<Translate id="dialog.unsaved.title" />}
                subTitle={<Translate id="dialog.unsaved.content-match" />}
                submitButtonLabel={<Translate id="dialog.unsaved.save-match" />}
                cancelButtonLabel={<Translate id="dialog.unsaved.leave" />}
              />
            </>
          )
        }}
      </NavigationPrompt>
      {loading && <CircularSpinner loadingcircular={loading} />}
    </>
  )
}

MatchDashboardContainer.propTypes = {
  matchId: PROP_TYPES.id.isRequired,
  updateMatch: PropTypes.func.isRequired,
}

export default connect(null, {
  updateMatch: matches.updateMatch,
})(MatchDashboardContainer)
