import React from 'react'
import { connect } from 'react-redux'
import { Translate } from 'react-localize-redux'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {
  Paper,
  Box,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  Typography,
  Toolbar,
  CardActionArea,
} from '@material-ui/core'
import { SubLink, HeartIcon } from 'containers'
import { Carousel } from 'components'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import CarouselItem from 'components/material/CarouselItem'
import PlaceholderImage from 'assets/images/carousel-item-placeholder.jpg'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import { getClub } from 'utils/localstorage'
import PROP_TYPES from 'constants/propTypes'
import { ARTICLE_ROUTE, ARTICLES_DASHBOARD_ROUTE } from 'constants/routes'
import { DEFAULT_CLUB_ID } from 'constants/club'

const MuiBox = withStyles(theme => ({
  root: {
    display: 'flex',

    '& .MuiBox-root': {
      margin: theme.spacing(0, 1.5, 0, 0),
      display: 'flex',
      alignItems: 'center',
      '& .MuiTypography-root': {
        display: 'flex',
        fontWeight: 500,
        color: theme.palette.action,
        marginRight: theme.spacing(1),
      },
    },
  },
}))(Box)

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(0, 3, 3),
  },
  toolbar: {
    minHeight: 54,
  },
  descriptionCell: {
    height: 50,
  },
  media: {
    height: 140,
    backgroundPosition: 'top',
  },
  title: {
    flex: '1 1 0',
  },
  cardActions: {
    paddingLeft: theme.spacing(2),
  },
  fab: {
    position: 'absolute',
    top: theme.spacing(-3),
    right: theme.spacing(2),
  },
}))

// constCustomCardContent  = styled.div`
//   padding: 0 16px 16px;
// `
const ArticlesWidgetContainer = ({
  articles,
  favoritesArticles,
  clubid,
}) => {
  const getIsFavorite = id => Boolean(favoritesArticles.findById(id))
  const classes = useStyles()

  return (
    <Paper className={classes.root}>
      <Toolbar disableGutters="false" className={classes.toolbar}>
        <Typography variant="h4" component="h4" className={classes.title}>
        {clubid === DEFAULT_CLUB_ID ? <Translate id="home.coachbetter_articles" /> : <>{`${getClub()?.name}`} <Translate id="home.articles" /></>}
        </Typography>
        <Button
          color="primary"
          component={SubLink}
          to={ARTICLES_DASHBOARD_ROUTE}
          endIcon={<ChevronRightIcon />}
        >
          <Translate id="home.read-more" />
        </Button>
      </Toolbar>

      <Carousel>
        {articles.map(
          ({
            id: articleId,
            title: titleValue,
            image_url: imageUrl,
            reading_time: readingTime,
            club_id: clubID,
          }) => {
            return (
              clubid===clubID &&
              <CarouselItem key={articleId}>
                <CardActionArea
                  component={SubLink}
                  to={ARTICLE_ROUTE.populateURLParams({ articleId })}
                >
                  <CardMedia
                    className={classes.media}
                    image={imageUrl || PlaceholderImage}
                    title="placeholder"
                  />
                  <CardContent className={classes.CardContent}>
                    <Typography
                      gutterBottom
                      className={classes.descriptionCell}
                      variant="h4"
                      component="h4"
                    >
                      {titleValue}
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions className={classes.cardActions}>
                  <div className={classes.title}>
                    <MuiBox>
                      {readingTime && (
                        <Box>
                          {' '}
                          <Typography variant="span" component="span">
                            <AccessTimeIcon color="disabled" />
                          </Typography>{' '}
                          {`${readingTime}`} min
                        </Box>
                      )}
                    </MuiBox>
                  </div>
                  <HeartIcon
                    isActive={getIsFavorite(articleId)}
                    entity="articles"
                    entityId={articleId}
                    isLike
                    isPositionRelative
                  />
                </CardActions>
              </CarouselItem>
            )
          }
        )}
      </Carousel>
    </Paper>
  )
}

ArticlesWidgetContainer.defaultProps = {
  articles: [],
  favoritesArticles: [],
  clubid: null
}

ArticlesWidgetContainer.propTypes = {
  articles: PROP_TYPES.arrayOfObjects,
  favoritesArticles: PROP_TYPES.arrayOfObjects,
  clubid: PROP_TYPES.arrayOfObjects
}

export default connect(({ articles, favorites }) => ({
  articles: articles.items,
  favoritesArticles: favorites.items.articles,
}))(ArticlesWidgetContainer)
