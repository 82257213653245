import React, { useState, useContext } from 'react'
import { Translate } from 'react-localize-redux'
import { makeStyles } from '@material-ui/core/styles'
import DescriptionIcon from '@material-ui/icons/Description'

import {
  Paper,
  Popover,
  Grid,
  Hidden,
  IconButton,
  TextField,
  Tooltip,
  Button,
} from '@material-ui/core'

import { Context } from 'routes/MatchDashboard/MatchStateContext'
import PROP_TYPES from 'constants/propTypes'

const useStyles = makeStyles((theme) => ({
  CustomPopper: {
    '& .MuiPopover-paper': {
      padding: theme.spacing(2),
    },
  },
  textarea: {
    '& .MuiInputBase-root': {
      height: 'unset',
      paddingTop: 'unset',
      paddingBottom: 'unset',
    },
  },
}))

const RatingNotePlayerPopover = ({ notes, playerUuid, handleMatchSave }) => {
  const { matchState, onFieldChange } = useContext(Context)

  const [anchorEl, setAnchorEl] = useState(null)
  const [open, setOpen] = useState(false)

  const onChangeNotes = ({ target: { value } }) => {
    onFieldChange(
      'matchPlayers',
      matchState.matchPlayers.updateByUuid(playerUuid, {
        ...matchState.matchPlayers.findByUuid(playerUuid),
        notes: value,
      })
    )
  }
  const classes = useStyles()

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget)
    setOpen(true)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setOpen(false)
  }
  const handleSave = () => {
    setAnchorEl(null)
    setOpen(false)
    handleMatchSave()
  }

  return (
    <>
      <Popover
        id={playerUuid}
        open={open}
        anchorEl={anchorEl}
        className={classes.CustomPopper}
        transition
        onClose={handleClose}
        disablePortal={false}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
      >
        <Paper>
          <Grid item>
            <TextField
              name="additinalInfo"
              variant="outlined"
              margin="dense"
              className={classes.textarea}
              multiline
              rows={4}
              label={<Translate id="matches.match-notes" />}
              value={notes}
              onChange={onChangeNotes}
              maxLength="5000"
            />
          </Grid>
          <Grid container spacing={2} className="my-2">
            <Grid item sm={6}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                fullWidth
                onClick={handleSave}
              >
                <Translate id="button.add" />
              </Button>
            </Grid>
            <Grid item sm={6}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                fullWidth
                onClick={handleClose}
              >
                <Translate id="button.close" />
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Popover>

      <Hidden only={['xs', 'sm']}>
        <Tooltip
          title={<Translate id="matches.add-note" />}
          placement="top"
          arrow
        >
          <IconButton size="small" onClick={handleClick}>
            <DescriptionIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Hidden>
    </>
  )
}

RatingNotePlayerPopover.defaultProps = {
  notes: '',
}

RatingNotePlayerPopover.propTypes = {
  playerUuid: PROP_TYPES.number.isRequired,
  notes: PROP_TYPES.string,
  handleMatchSave: PROP_TYPES.func.isRequired,
}

export default RatingNotePlayerPopover
