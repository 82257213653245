import React, { useState } from 'react'
import ArrowDownImage from 'assets/images/arrow-down.svg'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import PROP_TYPES from 'constants/propTypes'

const ArrowDownIcon = styled.div`
  display: block;
  width: 15px;
  height: 10px;
  background: url(${ArrowDownImage}) no-repeat center;
  background-size: contain;

  ${({ isActive }) =>
    isActive ? 'transform: rotate(180deg)' : 'transform: rotate(0deg)'};
`

const Container = styled.div`
  > div:first-child {
    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
  }
`

const ToggleButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
`

const CollapsibleOnlyHide = ({ children, className, defaultIsActive }) => {
  const [isActive, changeIsActive] = useState(defaultIsActive)

  const onClick = () => changeIsActive(!isActive)

  return (
    <Container className={className} isActive={isActive}>
      <ToggleButton onClick={onClick} role="presentation">
        <ArrowDownIcon isActive={isActive} />
      </ToggleButton>
      {isActive && children}
    </Container>
  )
}

CollapsibleOnlyHide.defaultProps = {
  className: '',
  defaultIsActive: false,
}

CollapsibleOnlyHide.propTypes = {
  className: PropTypes.string,
  children: PROP_TYPES.children.isRequired,
  defaultIsActive: PropTypes.bool,
}

export default CollapsibleOnlyHide
