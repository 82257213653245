import React from 'react'
import { EventLoader } from 'loaders'
import EventStateContext from './EventStateContext'
import EventDashboardContainer from './EventDashboardContainer'
import PROP_TYPES from 'constants/propTypes'

const EventDashboard = ({
  match: {
    params: { eventId },
  },
}) => (
  <EventLoader
    eventId={eventId}
    additionalFields={[
      'events',
    ]}
  >
    <EventStateContext>
      <EventDashboardContainer eventId={eventId} />
    </EventStateContext>
  </EventLoader>
)

EventDashboard.propTypes = {
  match: PROP_TYPES.match.isRequired,
}

export default EventDashboard
