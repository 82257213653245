import { useState, useEffect, cloneElement, useMemo } from 'react';
import SearchBar from 'material-ui-search-bar'


import { makeStyles } from '@material-ui/core/styles';

import {
  Grid,
  TablePagination
} from '@material-ui/core';
import TrainingPackagesDashboard from '../TrainingPackagesDashboard'


function debounce(func, wait) {
  var timeout
  return function executedFunction(...args) {
    var later = function () {
      clearTimeout(timeout)
      func(...args)
    }
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
  }
}

const DrillItem = ({ result, children }) => {
  return result.map(drill =>
    cloneElement(
      children,
      { key: drill.id, drill: drill }
    ))
}

const searchDrills = (search, d, setter, page, rowsPerPage) => {
  if (!search) {
    setter(d, page, rowsPerPage)
  } else {
    const drills = d.filter(
      el => el.title
        && el.title.toLowerCase().includes(search.toLowerCase())
    )
    setter(drills, page, rowsPerPage)
  }
}

const debouncedSearch = debounce(searchDrills, 800)

export function DrillDeck({
  value,
  drills,
  trainingPackageValue,
  children
}) {

  const classes = makeStyles(theme => ({
    featureBar: { display: 'flex', justifyContent: 'space-between', width: '100%' },
    searchBar: { border: '1px solid #e8e8e8' },
    table: { display: 'flex', flexWrap: 'wrap' },
  }))()

  const [searchResult, setSearchResult] = useState(drills)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(20)

  const handleSearchResult = (drills, page, rowsPerPage) => {
    setSearchResult(drills)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  useEffect(() => {
    setPage(0)
    setRowsPerPage(20)
    handleSearchResult(drills, page, rowsPerPage)
  }, [value, drills])

  useEffect(() => {
    handleSearchResult(searchResult, page, rowsPerPage)
  }, [page, rowsPerPage])

  return (
    <>
      {trainingPackageValue &&
        <Grid
          item
          className={classes.tableItem}
          xs={12}
        >
          <TrainingPackagesDashboard />
        </Grid>
      }
      {!trainingPackageValue &&
        <>
          <div className={classes.featureBar}>
            <SearchBar
              className={classes.searchBar}
              onChange={value => debouncedSearch(value, drills, handleSearchResult, page, rowsPerPage)}
              onCancelSearch={() => { debouncedSearch("", drills, handleSearchResult, page, rowsPerPage) }}
              cancelOnEscape={true}
            />

            <TablePagination
              rowsPerPageOptions={[20, 30]}
              component="div"
              count={searchResult.length}
              page={page}
              onChangePage={handleChangePage}
              rowsPerPage={rowsPerPage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </div>

          <Grid className={classes.table}
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
          >
            <DrillItem
              type="global"
              result={
                searchResult.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )}
            >
              {children}
            </DrillItem>
          </Grid>
        </>
      }
    </>
  )
}
