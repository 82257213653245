import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withLocalize } from 'react-localize-redux'
import { trainings } from 'redux/modules'
import Loader from './Loader'
import PROP_TYPES from 'constants/propTypes'

const TrainingLoader = props => {
  const {
    children,
    trainingId,
    fetchTraining,
    activeLanguage: { code: locale },
    ...restProps
  } = props

  const fetchMethod = params => fetchTraining(trainingId, { ...params, locale })
  const triggers = [trainingId, locale]

  return (
    <Loader fetchMethod={fetchMethod} triggers={triggers} {...restProps}>
      {children}
    </Loader>
  )
}

TrainingLoader.propTypes = {
  children: PROP_TYPES.children.isRequired,
  trainingId: PROP_TYPES.string.isRequired,
  fetchTraining: PROP_TYPES.func.isRequired,
  activeLanguage: PROP_TYPES.shape().isRequired,
}

export default compose(
  connect(
    null,
    { fetchTraining: trainings.fetchTraining }
  ),
  withLocalize
)(TrainingLoader)
