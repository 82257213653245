import { store } from '../redux/store'

// @todo: #storage replace localStorage usages with Redux where possible

export const getPlan = () => localStorage.getItem('plan') || ''
export const getUserId = () =>
  localStorage.getItem('userId') ? +localStorage.getItem('userId') : 0

export const getToken = () => {
  const state = store.getState()
  const { token } = state.auth

  return token
}

export const getCountry = () => localStorage.getItem('country') || null
export const getLocale = () => localStorage.getItem('locale') || 'en'
export const getName = (isFullName = false) => {
  const name = localStorage.getItem('name')
  if (isFullName) {
    return name
  }
  if (name) {
    return name[0].toUpperCase()
  }

  return 'C'
}

/**
 * Get login URL based on current locale.
 *
 * @returns string
 */
export const getLoginURL = () => {
  const state = store.getState()
  const { locale } = state.auth

  return locale === 'en' ? '/login' : `/${locale}/login`
}

/**
 * Get signup URL based on current locale.
 *
 * @returns string
 */
export const getSignupURL = () => {
  const state = store.getState()
  const { locale } = state.auth

  return locale === 'en' ? '/signup' : `/${locale}/signup`
}

export const getSubscriptionId = () =>
  localStorage.getItem('subscriptionId')
    ? +localStorage.getItem('subscriptionId')
    : 0

export const setUserId = userId => localStorage.setItem('userId', userId)

export const setCountry = country => localStorage.setItem('country', country)
export const setLocale = locale => localStorage.setItem('locale', locale)
export const setName = name => localStorage.setItem('name', name)
export const setClub = data =>
  localStorage.setItem('club', JSON.stringify(data))
export const getClub = () => JSON.parse(localStorage.getItem('club'))

export const setFirstTeam = () => localStorage.setItem('isTeamCreated', 'false')
export const isFirstTeam = () => {
  const firstTeam = localStorage.getItem('isTeamCreated')
  if (!firstTeam) return false

  if (firstTeam && (firstTeam === 'false' || firstTeam === 'False')) {
    return true
  }

  return false
}
export const setCurrentTeam = teamId => localStorage.setItem('currentTeam', teamId)
export const getCurrentTeam = () => localStorage.getItem('currentTeam') || ''

export const setPolarToken = (data) =>  {
  data.token=JSON.parse(data.token)
  localStorage.setItem('polar_token', JSON.stringify(data))
}
export const getPolarToken = () => {
  const polarToken=localStorage.getItem('polar_token')
  if (!polarToken) return undefined

  return JSON.parse(polarToken)
}
export const removePolar = () => {
  localStorage.removeItem('polar_token')
  localStorage.removeItem('polar_code')
  localStorage.removeItem('polar_error_code')
}

export const setPolarCode = (code) =>  localStorage.setItem('polar_code', code)
export const getPolarCode = () => localStorage.getItem('polar_code') || undefined
export const removePolarCode = () => localStorage.removeItem('polar_code')
export const setPolarErrorCode = (code) =>  localStorage.setItem('polar_error_code', code)
export const getPolarErrorCode = () => localStorage.getItem('polar_error_code') || undefined
export const removePolarErrorCode = () => localStorage.removeItem('polar_error_code')
