import React, { useEffect, useState } from 'react'
import hasSubscription from 'utils/hasSubscription'
import { withRouter } from 'react-router-dom'
import SubLink from 'containers/SubLink'
import { ListItem, ListItemIcon, ListItemText, Box } from '@material-ui/core'
import urlConstructor from 'utils/urlConstructor'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withLocalize } from 'react-localize-redux'
import { List } from './styledComponents'
import mainMenu from './constants/mainMenu'
import mainMenuBasic from './constants/mainMenuBasic'
import mainMenuPro from './constants/mainMenuPro'
import PROP_TYPES from 'constants/propTypes'
import PERMISSIONS from 'constants/permissions'
import { DEFAULT_CLUB_ID } from 'constants/club'

const Sidebar = ({
  match: {
    params: { teamId, seasonId },
  },
  location: { pathname },
  onClose,
  activeLanguage,
  teamPermissions,
  currentClubId
}) => {
  const [mainMenuOption, setMainMenuOption] = useState(hasSubscription() === 'basic'
    ? mainMenuBasic
    : hasSubscription() === 'plus'
      ? mainMenuPro
      : mainMenu
  )
  const mainPagePathname = urlConstructor(teamId, seasonId)

  useEffect(() => {
    setMainMenuOption(mainMenuOption.map(option => {
      if (option.id === 'FAQ_ROUTE') {
        option.to = activeLanguage.code === 'de' ? "https://www.coachbetter.com/de/hilfe-center" : "https://www.coachbetter.com/faqs";
      }
      return option
    }))
  }, [activeLanguage])

  const canManageTaskGroups = teamPermissions.includes(PERMISSIONS.MANAGE_TASK_GROUPS)

  return (
    <>
      <List component="nav">
        {// eslint-disable-next-line
          mainMenuOption.map(({ to, title, icon, teaser, isFullPath, rootPath, target, id,showOnlyForDefaultClub }) => {

            // @todo: #tasks remove
            const visible = id !== 'TASK_MANAGEMENT_ROUTE' || canManageTaskGroups

            return visible && (
              <Box style={{ position: 'relative', overflow: 'hidden' }}
                id={id}
                key={to}
                hidden={showOnlyForDefaultClub && currentClubId!==DEFAULT_CLUB_ID}
                >
                {teaser}
                <ListItem
                  to={to}
                  button
                  component={SubLink}
                  isFullPath={isFullPath}
                  target={target}
                  isActive={!rootPath && pathname === mainPagePathname}
                  className={
                    (!rootPath && pathname === mainPagePathname) ||
                      (rootPath && pathname.includes(rootPath))
                      ? 'selected'
                      : ''
                  }
                >
                  <ListItemIcon onClick={onClose}>{icon}</ListItemIcon>
                  <ListItemText onClick={onClose} primary={title} />
                </ListItem>
              </Box>
            )
          })}
      </List>

    </>
  )
}

Sidebar.defaultProps = {}

Sidebar.propTypes = {
  match: PROP_TYPES.match.isRequired,
  location: PROP_TYPES.location.isRequired,
  onClose: PROP_TYPES.location.isRequired,
  activeLanguage: PROP_TYPES.string.isRequired,
  teamPermissions: PROP_TYPES.arrayOf(PROP_TYPES.string).isRequired,
  currentClubId: PROP_TYPES.number.isRequired
}

export default compose(
  connect(
    ({ auth, teams,clubs }) => ({
      teamPermissions: auth.permissions.teams.find(item => item.id === teams.current.id)?.permissions ?? [],
      currentClubId: clubs.current.id,
    }),
    null
  ),
  withLocalize,
  withRouter
)(Sidebar)