/* eslint-disable no-nested-ternary */
import React from 'react'
import PropTypes from 'prop-types'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}))
const Snackbars = ({ error, warning, info, hideDuration, message }) => {
  const classes = useStyles()

  const [open, setOpen] = React.useState(true)

  const Alert = props => {
    return <MuiAlert elevation={6} variant="filled" {...props} />
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  React.useEffect(() => {}, [])

  return (
    <div className={classes.root}>
      <Snackbar
        open={open}
        autoHideDuration={hideDuration}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        {error ? (
          <Alert severity="error" onClose={handleClose}>
            {message}
          </Alert>
        ) : warning ? (
          <Alert severity="warning" onClose={handleClose}>
            {message}
          </Alert>
        ) : info ? (
          <Alert severity="info" onClose={handleClose}>
            {message}
          </Alert>
        ) : (
          <Alert severity="success" onClose={handleClose}>
            {message}
          </Alert>
        )}
      </Snackbar>
    </div>
  )
}
Snackbars.defaultProps = {
  error: false,
  warning: false,
  info: false,
  // success: false,
  hideDuration: 5000,
}

Snackbars.propTypes = {
  message: PropTypes.string.isRequired,
  error: PropTypes.bool,
  warning: PropTypes.bool,
  info: PropTypes.bool,
  // success: PropTypes.bool,
  hideDuration: PropTypes.number,
}

export default Snackbars
