/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Joi from 'joi'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Translate } from 'react-localize-redux'
import { reduxForm, Field } from 'redux-form'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import { subscription } from 'redux/modules'
import {
  OwnGrid,
  Card,
  LinkBack,
  Input,
  ReactSelect,
  LoadingSpinner,
} from 'components'
import createJoiValidator from 'utils/createJoiValidator'
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
} from 'utils/formatCard'
import { setCountry, getCountry } from 'utils/localstorage'
import { Table } from 'react-bootstrap'
import countries from 'constants/countries'

const validationSchema = Joi.object().keys({
  cardnumber: Joi.string()
    .max(24)
    .required(),
  expiry: Joi.string()
    .max(10)
    .required(),
  cvc: Joi.number().required(),
  city: Joi.string().required(),
  country: Joi.string().required(),
  address_1: Joi.string().required(),
  first_name: Joi.string().required(),
  last_name: Joi.string().required(),
})

const loadScript = (src, apiKey) => {
  if (!window.document.getElementById('stripe-script')) {
    const s = window.document.createElement('script')
    s.id = 'stripe-script'
    s.type = 'text/javascript'
    s.src = src
    s.onload = () => {
      window.Stripe.setPublishableKey(apiKey)
    }
    window.document.body.appendChild(s)
  }
}

const ensureCcNumberFormatting = value => formatCreditCardNumber(value)
const ensureCcExpiryFormatting = value => formatExpirationDate(value)
const ensureCcCvcFormatting = value => formatCVC(value)

const EditCardForm = ({
  title,
  fromPath,
  handleSubmit,
  apiKey,
  isCountryValid,
  changeCountyValid,
  updateDefaultCard,
  getAllCards,
}) => {
  const [stripeLoaded, setStripeLoaded] = useState(false)

  const useStyles = makeStyles(theme => ({
    paper: {
      padding: theme.spacing(2),
      textAlign: 'left',
      color: theme.palette.text.secondary,
      width: '100%',
      margin: theme.spacing(0, 0, 2, 0),
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
  }))
  const [country, setCountryValue] = React.useState(countries[0])
  setCountry(country.value)
  const [loader, changeLoader] = useState(false)
  const changeCountry = value => {
    setCountryValue(value)
    setCountry(value.value)
    if (!getCountry() || getCountry() === '') {
      changeCountyValid(true)
    } else {
      changeCountyValid(false)
    }
  }
  const classes = useStyles()
  const [cards, setCards] = useState([])
  const [defaultCard, setDefaultCard] = useState()

  const getAllcardsAPI = () => {
    changeLoader(!loader)
    getAllCards()
      .then(result => {
        setCards(result.response.data.cards)
        setDefaultCard(result.response.data.default_card)
        changeLoader(false)
      })
      .catch(() => {
        changeLoader(false)
      })
  }

  const setDefaultCardAPI = cardId => {
    changeLoader(!loader)
    updateDefaultCard({ token: cardId })
      .then(getAllcardsAPI)
      .catch(() => {
        changeLoader(false)
      })
  }
  useEffect(() => {
    getAllcardsAPI()
    const fetchData = () => {
      loadScript('https://js.stripe.com/v2/', apiKey)
      setStripeLoaded(true)
    }
    fetchData()
  }, [])

  return (
    <Translate>
      {({ translate }) =>
        stripeLoaded && (
          <Card>
            <Card.Title>
              {fromPath && (
                <LinkBack to={fromPath.value} title={fromPath.title} />
              )}
            </Card.Title>

            <form>
              <Container maxWidth="lg">
                <Card.Title>{title}</Card.Title>
                <Grid container spacing={2}>
                  <Grid container item md={6}>
                    <Paper className={classes.paper}>
                      <p>
                        <strong>
                          <Translate id="checkout.billing-details" />
                        </strong>
                      </p>

                      <Field
                        name="first_name"
                        placeholder={translate('form.first-name')}
                        component={Input}
                      />
                      <Field
                        name="last_name"
                        placeholder={translate('form.last-name')}
                        component={Input}
                      />
                      <Field
                        name="address_1"
                        placeholder={translate('form.address-name')}
                        component={Input}
                      />
                      <Field
                        name="city"
                        placeholder={translate('form.city')}
                        component={Input}
                      />
                      <Field
                        class="form-group"
                        placeholder={translate('form.country')}
                        component={ReactSelect}
                        suggestions={countries}
                        isMulti={false}
                        isEveryOne={false}
                        props={{
                          value: country,
                          onChange: changeCountry,
                        }}
                      />
                      {isCountryValid ? (
                        <React.Fragment>
                          <div
                            style={{
                              color: '#dc3545',
                              fontSize: '80%',
                              width: '100%',
                              marginTop: '.25rem',
                            }}
                          >
                            {'"country" is required'}
                          </div>
                          <br />
                        </React.Fragment>
                      ) : (
                        ''
                      )}
                    </Paper>
                  </Grid>
                  <Grid container item xs={6}>
                    <Grid>
                      <Paper className={classes.paper}>
                        <p>
                          <strong>
                            <Translate id="checkout.card-details" />
                          </strong>
                        </p>
                        <Table>
                          <tbody>
                            <tr>
                              <th>S.N.</th>
                              <th>
                                <Translate id="checkout.creditcard" />
                              </th>
                              <th>Brand</th>
                              <th>
                                <Translate id="checkout.month-year" />
                              </th>
                              <th>
                                <Translate id="checkout.set-default" />
                              </th>
                            </tr>

                            {loader ? (
                              <LoadingSpinner />
                            ) : cards.length === 0 ? (
                              <tr>No cards</tr>
                            ) : (
                              cards.map((card, index) => (
                                <tr key={card.id}>
                                  <td>{index + 1}</td>
                                  <td>XXXX XXX XXXX {card.last4}</td>
                                  <td>{card.brand}</td>
                                  <td>
                                    {card.exp_month}/{card.exp_year}
                                  </td>
                                  <td>
                                    {card.id !== defaultCard ? (
                                      <Button
                                        fullWidth
                                        onClick={() =>
                                          setDefaultCardAPI(card.id)
                                        }
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                      >
                                        <Translate id="checkout.set-default" />
                                      </Button>
                                    ) : (
                                      <Button
                                        fullWidth
                                        variant="contained"
                                        color="secondary"
                                        size="small"
                                        disabled
                                      >
                                        Selected Default
                                      </Button>
                                    )}
                                  </td>
                                </tr>
                              ))
                            )}
                          </tbody>
                        </Table>
                      </Paper>
                    </Grid>
                    <Paper className={classes.paper}>
                      <p>
                        <strong>
                          <Translate id="checkout.payment-details" />
                        </strong>
                      </p>
                      {stripeLoaded && (
                        <OwnGrid.Row>
                          <OwnGrid.Col xs={12}>
                            <Field
                              label={translate('checkout.creditcard')}
                              component={Input}
                              name="cardnumber"
                              normalize={ensureCcNumberFormatting}
                              placeholder="xxxx xxxx xxxx xxxx"
                              type="tel"
                            />
                          </OwnGrid.Col>
                          <OwnGrid.Col xs={12} md={6}>
                            <Field
                              label={translate('checkout.month-year')}
                              name="expiry"
                              normalize={ensureCcExpiryFormatting}
                              placeholder={translate('checkout.mm-yy')}
                              type="tel"
                              component={Input}
                            />
                          </OwnGrid.Col>
                          <OwnGrid.Col xs={12} md={6}>
                            <Field
                              label="CVV"
                              name="cvc"
                              normalize={ensureCcCvcFormatting}
                              type="tel"
                              placeholder="xxx"
                              component={Input}
                            />
                          </OwnGrid.Col>
                        </OwnGrid.Row>
                      )}
                      <Button
                        fullWidth
                        onClick={handleSubmit}
                        variant="contained"
                        color="primary"
                      >
                        <Translate id="button.save" />
                      </Button>
                    </Paper>
                  </Grid>
                </Grid>
              </Container>
            </form>
          </Card>
        )
      }
    </Translate>
  )
}

EditCardForm.propTypes = {
  form: PropTypes.string.isRequired, // eslint-disable-line
  title: PropTypes.string.isRequired,
  apiKey: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired, // eslint-disable-line
  handleSubmit: PropTypes.func.isRequired,
  updateDefaultCard: PropTypes.func.isRequired,
  getAllCards: PropTypes.func.isRequired,
  fromPath: PropTypes.shape({
    value: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  isCountryValid: PropTypes.bool.isRequired,
  changeCountyValid: PropTypes.func.isRequired,
}

export default compose(
  connect(
    null,
    {
      updateDefaultCard: subscription.updateDefaultCard,
      getAllCards: subscription.fetchAllCards,
    }
  ),
  reduxForm({
    enableReinitialize: true,
    validate: createJoiValidator(validationSchema),
  })
)(EditCardForm)
