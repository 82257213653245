import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid, TextField } from '@material-ui/core'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import SaveUpdateButtons from './SaveUpdateButtons'

const useStyles = makeStyles(() => ({
  textarea: {
    '& .MuiInputBase-root': {
      height: 'unset',
      paddingTop: 'unset',
      paddingBottom: 'unset',
    },
  },
}))

const AddEditSimpleNote = ({
  onClose,
  handleSave,
  handleUpdate,
  existingData,
  matchState,
  errorMinute,
  errorSecond,
  minute,
  second,
  setErrorMinute,
  resetCommonFields
}) => {
  const classes = useStyles()
  const [simpleNote, setSimpleNote] = React.useState(
    existingData ? existingData.note : ''
  )
  const [errorSimpleNote, setErrorSimpleNote] = React.useState(false)

  const resetForm = () => {
    resetCommonFields()
    setSimpleNote('')
  }

  const save = (exit = false) => {
    if (errorMinute || errorSecond || errorSimpleNote) return false

    if (!minute) {
      setErrorMinute(true)

      return false
    }
    if(!simpleNote){
      setErrorSimpleNote(true)

      return false
    }
    const createData = {
      match_id: matchState.id,
      scored_team: '',
      type: 'note',
      note: simpleNote,
      minute,
      second,
    }
    handleSave(createData).then(() => {
      resetForm()
      if (exit === true) {
        onClose()
      }
    })

    return false
  }
  const update = (highLightId) => {
    if (errorMinute || errorSecond || errorSimpleNote) return false

    const updateData = {
      _method: 'put',
      type: 'note',
      note: simpleNote,
      minute,
      second,
    }
    handleUpdate(highLightId, updateData)
    onClose()

    return false
  }

  return (
    <>
      <Grid item>
        <TextField
          className={classes.textarea}
          label={<Translate id="matches.highlights.add-game-note" />}
          name="opponent"
          variant="outlined"
          margin="dense"
          multiline
          rows={4}
          value={simpleNote}
          onChange={(event) => {
            setSimpleNote(event.target.value)
            if (event.target.value ==="") {
              setErrorSimpleNote(true);
            }else{
              setErrorSimpleNote(false);
            }
          }}
          error={errorSimpleNote}
          required
          helperText={
            errorSimpleNote && (
              <Translate id="matches.highlights.add-game-note" />
            )
          }
        />
      </Grid>
      <SaveUpdateButtons
        save={save}
        existingData={existingData}
        update={update}
      />
    </>
  )
}
AddEditSimpleNote.defaultProps = {
  onClose: () => 0,
  matchState: null,
  existingData: null,
  handleSave: () => 0,
  handleUpdate: () => 0,
}
AddEditSimpleNote.propTypes = {
  onClose: PropTypes.func,
  handleSave: PropTypes.func,
  handleUpdate: PropTypes.func,
  existingData: PropTypes.shape(),
  matchState: PropTypes.shape(),
  errorMinute: PropTypes.bool.isRequired,
  errorSecond: PropTypes.bool.isRequired,
  minute: PropTypes.number.isRequired,
  second: PropTypes.number.isRequired,
  setErrorMinute: PropTypes.func.isRequired,
  resetCommonFields: PropTypes.func.isRequired,
}
export default AddEditSimpleNote
