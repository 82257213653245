import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { ReactComponent as PlayerLogo } from 'assets/images/player-no-color.svg'

const PlayerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 10px;
  width: 35px;
`

const PlayerImage = styled(PlayerLogo)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
`

const IconPlayerNumber = styled.div`
  color: #fff;
  // color: ${props => props.color};
  font-size: 18px;
  text-shadow: -1px 1px 3px rgba(0, 0, 0, 0.5);
  font-size: 11px;

  width: 35px;
  text-align: center;
  position: absolute;
`

const Icon = ({ to, color, number }) => (
  <PlayerContainer to={to}>
    <PlayerImage stroke={color} />
    <IconPlayerNumber color={color}>{number}</IconPlayerNumber>
  </PlayerContainer>
)

Icon.defaultProps = {
  to: '',
  color: '#fff',
  number: '',
}

Icon.propTypes = {
  to: PropTypes.string,
  color: PropTypes.string,
  number: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default Icon
