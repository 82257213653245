import React, { useState, useMemo } from 'react'
import { Translate } from 'react-localize-redux'
import { connect } from 'react-redux'
import DrillsImage from 'assets/images/icon-drills.svg'
import { HeartIcon } from 'containers'
import { Card, Carousel, CarouselItem, DrillModal } from 'components'
import PROP_TYPES from 'constants/propTypes'

const DrillsWidgetContainer = ({ drills, favoritesDrills }) => {
  const [openedDrillId, changeOpenedDrillId] = useState(null)

  const openedDrill = useMemo(() => drills.findById(openedDrillId), [
    openedDrillId,
    drills,
  ])

  const closeOpenedDrill = () => changeOpenedDrillId(null)

  const getIsFavorite = id => Boolean(favoritesDrills.findById(id))

  return (
    <>
      {openedDrillId && (
        <DrillModal
          drill={openedDrill}
          onHide={closeOpenedDrill}
          open={Boolean(openedDrillId)}
        />
      )}
      <Card>
        <Card.Title>
          <Card.Title.Icon icon={DrillsImage} />
          <Translate id="home.drills" />
        </Card.Title>
        <Carousel>
          {drills.map(({ id: drillId, title, images }) => (
            <div
              key={drillId}
              className="cursor-pointer"
              onClick={() => changeOpenedDrillId(drillId)}
              role="presentation"
            >
              <CarouselItem image={images.findEndGetProp(Boolean, 'full_url')}>
                <HeartIcon
                  isActive={getIsFavorite(drillId)}
                  entity="drills"
                  entityId={drillId}
                />
                <CarouselItem.Title>{title}</CarouselItem.Title>
              </CarouselItem>
            </div>
          ))}
        </Carousel>
      </Card>
    </>
  )
}

DrillsWidgetContainer.defaultProps = {
  drills: [],
  favoritesDrills: [],
}

DrillsWidgetContainer.propTypes = {
  drills: PROP_TYPES.arrayOfObjects,
  favoritesDrills: PROP_TYPES.arrayOfObjects,
}

export default connect(({ drills, favorites }) => ({
  drills: drills.items,
  favoritesDrills: favorites.items.drills,
}))(DrillsWidgetContainer)
