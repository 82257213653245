/* eslint-disable no-nested-ternary */
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import { Translate } from 'react-localize-redux'
import {
  Dialog,
  Typography,
  Button,
  Box,
  CardHeader,
  AppBar,
  Toolbar,
  Grid,
  IconButton,
  CardContent,
  Card,
  Hidden,
} from '@material-ui/core'
import { connect } from 'react-redux'
import { trainings, matches,events as eventsModule,generalEvents } from 'redux/modules'
import { compose } from 'redux'
import {
  formatCostumDateMonthNoForUI,
  formateDate,
} from 'utils/formatDate'
import CloseIcon from '@material-ui/icons/Close'
import hasSubscription from 'utils/hasSubscription'
import moment from 'moment'
import EventTable from './eventTable'
import EventPopup from './eventPopup'
import EventCalender from './EventCalender'
import RecentEventsWidget from './RecentEventsWidget'
import PROP_TYPES from 'constants/propTypes'
import { TRAININGS_GAME } from 'constants/calender'

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiDialog-paper': {
      minWidth: '600px',
    },
  },
  dashboardHeaderbuttonsRight: {
    textAlign: 'right',
    '& > *': {
      margin: theme.spacing(0, 1),
    },
  },
  [theme.breakpoints.down('sm')]: {
    dashboardHeaderbuttonsRight: {
      textAlign: 'center',
      display: 'flex',
      position: 'fixed',
      bottom: '0',
      left: '0',
      borderTop: '1px solid var( --light-grey)',
      background: 'var(--white)',
      width: '100%',
      zIndex: '10',
      '& a': {
        width: '50%',
      },
      '& .MuiButton-root': {
        width: '100%',
      },
    },
    title: {
      color: '#ffffff',
    },
    close: {
      marginLeft: 'auto',
      padding: '0',
    },
  },
}))

const EventCalenderContainers = ({
  
  createTraining,
  updateTraining,
  scheduleTrainings,
  deleteTraining,
  createMatch,
  updateMatch,
  deleteMatch,
  createEvent,
  updateEvent,
  deleteEvent,
  history,
  events,
  isLoading,
  isPast,
  beforeSliceEventCount,
  filterBy,
  match: {
    params: { teamId, seasonId },
  },
  fetchSeasonRecentEvents,
  fetchCalendarEvents,
  recentEvents,
  calendarEvents,
  fetchSeasonEvents
}) => {
  const classes = useStyles()
  const allEvents = events.map(
      ({
        id,
        date,
        location,
        status,
        logo,
        available_players: availablePlayers,
        not_available_players: notAvailablePlayers,
        not_in_roster_players: notInRosterPlayers,
        total_players: totalPlayers,
        total_team_players: totalTeamPlayers,
        isNew,
        opponent,
        home,
        title,
        type,
        relation,
        description,
        short_address:shortAddress,
        first_name: firstName,
        last_name: lastName,
        street,
        city,
        country,
        postal_code,
        lat,
        lng,
        state,
        training_focus : trainingFocus,
        additional_info : additionalInfo,
        duration,
        intensity,
        meeting_time,
        start_time,
        end_time,
      }) => ({
        id,
        teamId,
        seasonId,
        status,
        location,
        title,
        start: +moment(date).format('x'),
        end: +moment(date).format('x'),
        logo,
        availablePlayers,
        notAvailablePlayers,
        notInRosterPlayers,
        totalPlayers,
        totalTeamPlayers,
        isNew,
        date,
        dateFormate: formateDate(date),
        opponent,
        home,
        relation,
        description,
        shortAddress,
        firstName,
        lastName,
        type,
        street,
        city,
        country,
        postal_code,
        lat,
        lng,
        state,
        trainingFocus,
        additionalInfo,
        duration,
        intensity,
        start_time: start_time || null,
        meeting_time: meeting_time,
        end_time: end_time || null,
      })
    );

  const [open, setOpen] = React.useState(false )
  const [trainingAndGame, setTrainingAndGame] = React.useState('')
  const [isAlertBox, changeAlertBox] = React.useState(false)
  const [showCalender, changeCalender] = React.useState(false)
  const [calenderDate, setCalenderDate] = React.useState(null)
  const [calenderDateSelected, changeCalenderDateSelected] = React.useState(
    false
  )
  const [eventDelete, changeDeleteEvent] = React.useState({
    id: 0,
    relation: '',
  })
  const [duplicateData, changeDuplicateData] = React.useState(null)
  const [selectedEventType, setSelectedEventType] = React.useState(null)
  const [isDuplicate, setIsDuplicate] = React.useState(false)

  const handleClose = () => {
    setOpen(false)
    changeAlertBox(false)
    changeDuplicateData(null)
    changeCalender(false)
  }

  const handleOpen = (text = '', isVisiableCalander, generalEventData = []) => {
    if (Object.keys(generalEventData).length > 0) {
      // seed selected event data in popup to edit
      changeDuplicateData(generalEventData)
      setIsDuplicate(false)
    }
    setTrainingAndGame(text)
    setOpen(true)
    changeCalender(isVisiableCalander)
    setSelectedEventType(text)
  }
  const handleCalenderDate = date => {
    if(calenderDate && date.getTime()===calenderDate.getTime()){
      setCalenderDate(null)
      changeCalenderDateSelected(false)
    }
    else{
      setCalenderDate(date)
      changeCalenderDateSelected(true)
    }
  }

  const newCreateTraining = data => {
    createTraining(0, data).then(res => {
      const { id } = {
        ...res.response.data,
      }
      handleClose()
      history.push(`/team/${+teamId}/season/${+seasonId}/trainings/${id}`)
    })
  }

  const newCreateMatch = data => {
    createMatch(0, data).then(res => {
      const { id } = {
        ...res.response.data,
      }
      handleClose()
      if (hasSubscription() === 'basic') {
        fetchSeasonEvents(seasonId)
      }else{
        history.push(`/team/${+teamId}/season/${+seasonId}/matches/${id}`)
      }
    })
  }

  const newCreateEvent = data => {
    createEvent(0, data).then((res) => {
      const { id } = {
        ...res.response.data,
      }
      handleClose()
      if (hasSubscription() === 'basic') {
        fetchSeasonEvents(seasonId)
      }else{
        history.push(`/team/${+teamId}/season/${+seasonId}/general_events/${id}`)
      }
    })
  }

  const updateExistingEvent = (id, data) => {
    updateEvent(id, data).then(() => {
      handleClose()
      fetchSeasonEvents(seasonId)
    })
  }
  const updateExistingMatch = (id, data) => {
    updateMatch(id, data).then(() => {
      handleClose()
      fetchSeasonEvents(seasonId)
    })
  }
  const updateExistingTraining = (id, data) => {
    updateTraining(id, data).then(() => {
      handleClose()
      fetchSeasonEvents(seasonId)
    })
  }

  const onOK = () => {
    const { id, relation } = eventDelete
    handleClose()
    
    if (relation === 'training') {
      deleteTraining(id).then(()=>{
        fetchSeasonEvents(seasonId)
      })
    } else if (relation === 'match') {
      deleteMatch(id).then(()=>{
        fetchSeasonEvents(seasonId)
      })
    } else if (relation === 'event') {
      deleteEvent(id).then(()=>{
        fetchSeasonEvents(seasonId)
      })
    }    
  }

  const openAlertBox = (id, relation) => {
    changeAlertBox(true)
    changeDeleteEvent({ id, relation })
    handleOpen('', false)
  }

  const openDuplicateBox = (id, relation,isDup) => {
    setIsDuplicate(isDup)
    changeDuplicateData(
      allEvents.find(p => p.id === id && p.relation === relation) || null
    )
    let rel = '';
    if (relation === "training") rel = TRAININGS_GAME.training
    if(relation === "match") rel = TRAININGS_GAME.game
    if (relation === "event") rel = TRAININGS_GAME.event

    handleOpen(rel,false)
  }

  const pastEvents = recentEvents.filter(p => p.relation === 'training' || p.relation === 'match').sort((a, b) =>{
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);

    return dateB - dateA;
  })
  const newScheduleTrainings = data => {
    scheduleTrainings(data).then(() => {
      handleClose()
      fetchSeasonEvents(seasonId)
    })
  }
  

  React.useEffect(() => {
    fetchSeasonRecentEvents(seasonId)
    fetchCalendarEvents(seasonId)
  }, [])

  return (
    <div>
      <Hidden only={['xs', 'sm']}>
        {open && 
          <EventPopup
            open={open}
            onClose={handleClose}
            onOK={onOK}
            duplicateData={duplicateData}
            isAlertBox={isAlertBox}
            trainingAndGame={trainingAndGame}
            createTraining={newCreateTraining}
            createMatch={newCreateMatch}
            updateMatch={updateExistingMatch}
            updateTraining={updateExistingTraining}
            createEvent={newCreateEvent}
            updateEvent={updateExistingEvent}
            calenderDateSelected={calenderDateSelected}
            calenderDate={calenderDate}
            scheduleTrainings={newScheduleTrainings}
            history={history}
            selectedEventType={selectedEventType}
            isDuplicate={isDuplicate}
          />
        }
      </Hidden>
      <Grid container spacing={3}>
        <Hidden only={['xs', 'sm']}>
          {' '}
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Typography variant="h4">
              <Translate id="events.event-scheduler-title" />
            </Typography>
            <Typography variant="h6" mt={1}>
              <Translate id="events.event-scheduler-subtitle" />
            </Typography>
          </Grid>
        </Hidden>
        
        <Grid
          item
          xs={12}
          sm={12}
          md={8}
          lg={8}
          xl={8}
          className={classes.dashboardHeaderbuttonsRight}
        >
          <Hidden only={['md','lg','xl']}>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            onClick={() => handleOpen( '', false)}
          >
            <Translate id="button.plan-event" />
          </Button>
          </Hidden>
        </Grid>
        <Grid item lg={8} sm={6} xs={12} md={8} xl={9}>
          <Card>
            <CardHeader
              title={
                <Typography variant="h4" component="h4">
                  <Translate id="events.next-events" />
                </Typography>
              }
              action={
                <>
                  <Hidden only={['lg', 'xl','sm','md']}>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="large"
                      onClick={() => handleOpen('', true)}
                    >
                      {`${formatCostumDateMonthNoForUI(calenderDate || new Date())}`}
                    </Button>
                  </Hidden>
                  <Hidden only={['xs','sm']}>
                    <Button
                      variant="contained"
                      size="medium"
                      color="primary"
                      onClick={() => handleOpen('', false)}
                    >
                      <Translate id="button.plan-event" />
                    </Button>
                  </Hidden>
                </>
              }
            />

           <CardContent>
              <EventTable
                events={allEvents}
                openAlertBox={openAlertBox}
                handleOpen={handleOpen}
                openDuplicateBox={openDuplicateBox}
                calenderDate={calenderDate}
                calenderDateSelected={calenderDateSelected}
                isPast={isPast}
                filterBy={filterBy}
                beforeSliceEventCount={beforeSliceEventCount}
                pastEvents={pastEvents}
                isLoading={isLoading}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={3} >
          <Hidden only={['md', 'lg', 'xl']}>
            <Card>
                {!showCalender && (
                  <EventPopup
                    open={open}
                    onClose={handleClose}
                    onOK={onOK}
                    duplicateData={duplicateData}
                    isAlertBox={isAlertBox}
                    trainingAndGame={trainingAndGame}
                    createTraining={newCreateTraining}
                    createMatch={newCreateMatch}
                    createEvent={newCreateEvent}
                    updateEvent={updateExistingEvent}
                    updateMatch={updateExistingMatch}
                    updateTraining={updateExistingTraining}
                    calenderDateSelected={calenderDateSelected}
                    calenderDate={calenderDate}
                    scheduleTrainings={newScheduleTrainings}
                    selectedEventType={selectedEventType}
                    isDuplicate={isDuplicate}
                  />
                )}
                {showCalender && (
                  <Dialog
                    open={open}
                    onClose={handleClose}
                  >
                  <Box>
                    <AppBar className={classes.appBar} position="static">
                      <Toolbar>
                        <Typography
                          variant="h4"
                          className={classes.title}
                          component="h4"
                        >
                          Calendar
                        </Typography>
                        <IconButton
                          edge="End"
                          className={classes.close}
                          color="inherit"
                          onClick={handleClose}
                          aria-label="close"
                        >
                          <CloseIcon />
                        </IconButton>
                      </Toolbar>
                    </AppBar>
                    <EventCalender
                      date={calenderDate}
                      changeDate={handleCalenderDate}
                      events={calendarEvents}
                      calenderDateSelected={calenderDateSelected}
                    />
                  </Box>
                  </Dialog>
                )}
            </Card>
          </Hidden>
          <Hidden only={['xs']}>
            <Card>
              <EventCalender
                date={calenderDate}
                changeDate={handleCalenderDate}
                events={calendarEvents}
                calenderDateSelected={calenderDateSelected}
              />
            </Card>
          </Hidden>
          <RecentEventsWidget pastEvents={pastEvents}/>
        </Grid>
      </Grid>
    </div>
  )
}

EventCalenderContainers.defaultProps = {
  events: [],
  recentEvents: [],
  calendarEvents: [],
  isLoading: true,
  isPast: 0,
  beforeSliceEventCount: 0,
  filterBy: null,
}
EventCalenderContainers.propTypes = {
  createTraining: PROP_TYPES.func.isRequired,
  scheduleTrainings: PROP_TYPES.func.isRequired,
  deleteTraining: PROP_TYPES.func.isRequired,
  createMatch: PROP_TYPES.func.isRequired,
  createEvent: PROP_TYPES.func.isRequired,
  updateEvent: PROP_TYPES.func.isRequired,
  updateMatch: PROP_TYPES.func.isRequired,
  updateTraining: PROP_TYPES.func.isRequired,
  deleteEvent: PROP_TYPES.func.isRequired,
  deleteMatch: PROP_TYPES.func.isRequired,
  fetchSeasonRecentEvents: PROP_TYPES.func.isRequired,
  fetchCalendarEvents: PROP_TYPES.func.isRequired,
  fetchSeasonEvents: PROP_TYPES.func.isRequired,
  history: PROP_TYPES.shape().isRequired,
  events: PROP_TYPES.arrayOfObjects,
  recentEvents: PROP_TYPES.arrayOfObjects,
  calendarEvents: PROP_TYPES.arrayOfObjects,
  match: PROP_TYPES.match.isRequired,
  isLoading: PROP_TYPES.bool,
  isPast: PROP_TYPES.number,
  beforeSliceEventCount: PROP_TYPES.number,
  filterBy: PROP_TYPES.string,
}

export default compose(
  connect(
    ({ events }) => ({
      isLoading: events.isLoading,
      events: events.items.events,
      isPast: events.items.isPast,
      filterBy: events.items.filterBy,
      beforeSliceEventCount: events.items.before_slice_events_count,
      recentEvents: events.recent.events,
      calendarEvents: events.calendarEvents.events,
    }),
    {
      createTraining: trainings.createTraining,
      scheduleTrainings: trainings.scheduleOnlyTrainings,
      updateTraining: trainings.updateTraining,
      deleteTraining: trainings.deleteTraining,
      createMatch: matches.createMatch,
      updateMatch: matches.updateMatch,
      createEvent: generalEvents.createEvent,
      updateEvent: generalEvents.updateEvent,
      deleteMatch: matches.deleteMatch,
      deleteEvent: generalEvents.deleteEvent,
      fetchSeasonRecentEvents: eventsModule.fetchSeasonRecentEvents,
      fetchCalendarEvents: eventsModule.fetchCalendarEvents,
      fetchSeasonEvents: eventsModule.fetchSeasonEvents,
    }
  ),
  withRouter
)(EventCalenderContainers)
