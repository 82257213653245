import React, { useMemo } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import sortBy from 'lodash.sortby'
import { MiniCalendar } from 'containers'
import urlConstructor from 'utils/urlConstructor'
import PROP_TYPES from 'constants/propTypes'

import { EVENT_DASHBOARD_ROUTE } from 'constants/routes'

const MiniCalendarWithActions = ({ availableEvents, match }) => {
  const {
    params: { teamId, seasonId, eventId },
  } = match

  const sortedMatches = useMemo(
    () => sortBy(availableEvents, ({ date }) => date),
    [availableEvents]
  )

  const onEventSelected = (id) => {
    window.location.href = urlConstructor(
      teamId,
      seasonId,
      EVENT_DASHBOARD_ROUTE.populateURLParams({ eventId: id })
    )
  }

  return (
    <MiniCalendar
      currentEventId={eventId}
      events={sortedMatches}
      onEventSelected={onEventSelected}
    />
  )
}

MiniCalendarWithActions.defaultProps = {
  availableEvents: [],
}

MiniCalendarWithActions.propTypes = {
  availableEvents: PROP_TYPES.arrayOfObjects,
  match: PROP_TYPES.match.isRequired,
}

export default compose(
  withRouter,
  connect(({ generalEvents: { current } }) => ({
    availableEvents: current.events,
  }))
)(MiniCalendarWithActions)
