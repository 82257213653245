import React from 'react'
import {
  DialogContentText,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import PlayersAutocomplete from './PlayersAutocomplete'
import TeamSelection from './TeamSelection'
import SaveUpdateButtons from './SaveUpdateButtons'
import { goalType } from 'constants/match'

const AddEditGoal = ({
  onClose,
  handleSave,
  handleUpdate,
  existingData,
  playersSuggestions,
  matchState,
  teamName,
  errorMinute,
  errorSecond,
  minute,
  second,
  setErrorMinute,
  resetCommonFields
}) => {
  const [scoredTeam, setScoredTeam] = React.useState(
    existingData && existingData.scored_team === null ? 0 : 1
  )
  const [p1value, setP1Value] = React.useState()
  const [p2value, setP2Value] = React.useState()
  const [goalCategory, setGoalCategory] = React.useState(
    existingData ? existingData.goal_category : goalType[0].key
  )
  const [errorGoalCategory, setErrorGoalCategory] = React.useState(false)
  const [resetPlayer, setResetPlayer] = React.useState(false)

  const resetForm = () => {
    resetCommonFields()
    setScoredTeam(1)
    setP1Value('')
    setP2Value('')
    setGoalCategory(goalType[0].key)
    setResetPlayer(true)
  }

  const save = (exit = false) => {
    if (errorMinute || errorGoalCategory || errorSecond) return false

    if (!minute) {
      setErrorMinute(true)

      return false
    }
    if (!goalCategory) {
      setErrorGoalCategory(true)

      return false
    }
    const createData = {
      match_id: matchState.id,
      type: 'goal',
      scored_team: scoredTeam,
      goal_category: goalCategory,
      minute,
      second,
      player_1: p1value,
      player_2: p2value,
    }
    handleSave(createData).then(() => {
      resetForm()
      if (exit === true) {
        onClose()
      }
    })

    return false
  }
  const update = (highLightId) => {
    if (errorMinute || errorGoalCategory || errorSecond) return false

    if (!goalCategory) {
      return false
    }
    const updateData = {
      _method: 'put',
      type: 'goal',
      scored_team: scoredTeam,
      goal_category: goalCategory,
      minute,
      second,
      player_1: p1value,
      player_2: p2value,
    }
    handleUpdate(highLightId, updateData)
    onClose()

    return false
  }

  const handleScoredTeam = (val) => {
    if (val === 0) {
      setP1Value('')
      setP2Value('')
    }
    setScoredTeam(val)
  }

  return (
    <>
      <DialogContentText>
        <Translate id="matches.highlights.enter-goal-details" />
      </DialogContentText>
      <Grid item style={{marginTop: '0.25rem'}}>
        <TeamSelection
          selectedTeam={scoredTeam}
          handleTeam={handleScoredTeam}
          ownTeam={teamName}
        />
      </Grid>
      <Grid item>
        <FormControl
          variant="outlined"
          error={errorGoalCategory}
          margin="dense"
        >
          <InputLabel id="goal-type-select-label">
            <Translate id="matches.highlights.goal-type" />
          </InputLabel>
          <Select
            labelId="goal-type-select-label"
            id="selectbox-goal-catgory"
            value={goalCategory}
            onChange={(event) => {
              if (!(event.target.value !== '')) {
                return setErrorGoalCategory(true)
              }
              setErrorGoalCategory(false)
              setGoalCategory(event.target.value)

              return true
            }}
            margin="dense"
            label={<Translate id="matches.highlights.goal-type" />}
          >
            {goalType.map((p) => {
              return (
                <MenuItem key={p.key} value={p.key}>
                  {p.displayValue}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </Grid>
      {scoredTeam !== 0 && (
        <>
          <Grid item>
            <PlayersAutocomplete
              setPValue={setP1Value}
              label={<Translate id="matches.highlights.scored-by" />}
              savedPlayer={
                existingData && existingData.player_1
                  ? existingData.player_1
                  : 0
              }
              players={playersSuggestions}
              resetPlayer={resetPlayer}
              setResetPlayer={setResetPlayer}
            />
          </Grid>
          <Grid item>
            <PlayersAutocomplete
              setPValue={setP2Value}
              label={<Translate id="matches.highlights.assisted-by" />}
              savedPlayer={
                existingData && existingData.player_2
                  ? existingData.player_2
                  : 0
              }
              players={playersSuggestions}
              resetPlayer={resetPlayer}
              setResetPlayer={setResetPlayer}
            />
          </Grid>
        </>
      )}
      <SaveUpdateButtons
        save={save}
        existingData={existingData}
        update={update}
      />
    </>
  )
}
AddEditGoal.defaultProps = {
  onClose: () => 0,
  matchState: null,
  teamName: '',
  existingData: null,
  handleSave: () => 0,
  handleUpdate: () => 0,
}

AddEditGoal.propTypes = {
  onClose: PropTypes.func,
  handleSave: PropTypes.func,
  handleUpdate: PropTypes.func,
  playersSuggestions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  existingData: PropTypes.shape(),
  matchState: PropTypes.shape(),
  teamName: PropTypes.string,
  errorMinute: PropTypes.bool.isRequired,
  errorSecond: PropTypes.bool.isRequired,
  minute: PropTypes.number.isRequired,
  second: PropTypes.number.isRequired,
  setErrorMinute: PropTypes.func.isRequired,
  resetCommonFields: PropTypes.func.isRequired,
}
export default AddEditGoal
