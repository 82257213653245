import React, { useState } from 'react'
import hasSubscription from 'utils/hasSubscription'
import PROP_TYPES from 'prop-types'
import { Translate } from 'react-localize-redux'
import { makeStyles, createTheme } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import {
  Button,
  IconButton,
  Paper,
  Tabs,
  Tab,
  Tooltip,
  Grid,
  Hidden,
} from '@material-ui/core'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import { ShareMuiIcon, SubLink } from 'containers'
import EventPopup from 'components/material/Calender/eventPopup'
import { connect } from 'react-redux'
import { compose } from 'redux'
import PERMISSIONS from '../../../constants/permissions'
import InviteDialog from './InviteDialog'
import { PRO_SHARE_OPTIONS, ELITE_SHARE_OPTIONS } from 'constants/player'
import { PRO, ELITE } from 'constants/plans'

const theme = createTheme({})
const useStyles = makeStyles({
  paper: {
    width: '100%',
    display: 'flex',
    padding: '.6rem',
  },
  Cancel: {
    margin: '0 .8rem',
  },
  PlayerNum: {
    margin: '0 .8rem',
    '& img': {
      marginRight: '.6rem',
    },
    '& :hover': {
      backgroundColor: 'transparent',
    },
  },
  newSessionDrill: {
    backgroundColor: 'white',
    padding: '0 1rem',
    borderRadius: '6px',
  },
  [theme.breakpoints.down('sm')]: {
    paper: {
      display: 'flex',
      flexDirection: 'column',
    },
    Cancel: {
      padding: '8px 28px',
    },
    Save: {
      padding: '8px 28px',
    },
    MobileSectionDialog: {
      '& .MuiDialog-paperWidthSm': {
        width: '100%',
      },
    },
    DialogClose: {
      position: 'absolute',
      top: '4px',
      right: '52px',
      zIndex: '10',
    },
    MobileSectionDialogBtn: {
      textAlign: 'center',
      display: 'flex',
      height: '4.375rem',
      position: 'fixed',
      boxShadow: '14px 5px 35px 0 rgba(0,0,0,0.08)',
      padding: '0.875rem 1.125rem',
      bottom: '0',
      left: '0',
      borderTop: '1px solid var( --light-grey)',
      background: 'var(--white)',
      width: '100%',
      zIndex: '10',
      '& a': {
        width: '50%',
      },
      '& .MuiButton-root': {
        width: '100%',
      },
    },
  },
})

const CustomheadRight = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  .MuiDialog-paperWidthSm {
    width: 100%;
  }
  @media (max-width: 640px) {
    position: fixed;
    bottom: 0;
    left: 0;
    background: #fff;
    padding: 0.7rem;
    z-index: 9;
    justify-content: center;
    box-shadow: 14px 5px 35px 0 rgba(0, 0, 0, 0.08);
  }
`

const TopBar = ({
  handleSave,
  deletePlayer,
  sharePlayer,
  history,
  match: { params },
  teamPermissions
}) => {
  const classes = useStyles()
  const { teamId, seasonId, playerId } = params

  const [open, setOpen] = useState(false)
  const [openInvite, setOpenInvite] = useState(false)
  const [trainingAndGame, setTrainingAndGame] = useState('')
  const [isAlertBox, changeAlertBox] = useState(false)
  const [duplicateData, changeDuplicateData] = useState(null)

  const canInviteUsers = teamPermissions.includes(PERMISSIONS.INVITE_PLAYER_USERS)

  const handleOpenInvite = () => {
    setOpenInvite(true)
  }

  const handleClose = () => {
    setOpen(false)
    setOpenInvite(false)
    changeAlertBox(false)
    changeDuplicateData(null)
  }

  const handleOpen = (text = '') => {
    setTrainingAndGame(text)
    setOpen(true)
  }

  const onOK = () => deletePlayer()

  const openAlertBox = () => {
    changeAlertBox(true)
    handleOpen()
  }

  const [value, setValue] = React.useState(0)
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  let selectionCheckboxOptions = []
  if (hasSubscription() === ELITE)
    selectionCheckboxOptions = ELITE_SHARE_OPTIONS

  if (hasSubscription() === PRO) selectionCheckboxOptions = PRO_SHARE_OPTIONS

  return (
    <>
      <InviteDialog open={openInvite} onClose={handleClose}/>
      {open &&
        <EventPopup
          open={open}
          onClose={handleClose}
          onOK={onOK}
          duplicateData={duplicateData}
          isAlertBox={isAlertBox}
          trainingAndGame={trainingAndGame}
          createTraining={() => 0}
          createMatch={() => 0}
          calenderDateSelected={false}
          calenderDate={null}
          history={history}
        />
      }
      <Paper className={classes.paper}>
        <Grid item lg={6} md={6} sm={12} xs={12} align="left" display="flex">
          <Translate>
            {({ translate }) => (
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab label={translate('team.coachbetter-profile')} />
              </Tabs>
            )}
          </Translate>
        </Grid>

        <Grid item lg={6} md={6} sm={12} xs={12} align="right" display="flex">
          <CustomheadRight>
            {canInviteUsers && (
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={handleOpenInvite}
              >
                <Translate id="player.invite-user.title" />
              </Button>
            )}
            <Tooltip title="Share" placement="top-start" arrow>
              <ShareMuiIcon
                share={sharePlayer}
                id={+playerId}
                shareBy="Player"
                className="share-button"
                checkBoxList={selectionCheckboxOptions}
                isPlayer
              />
            </Tooltip>

            <Hidden only={['xs', 'sm']}>
              <Tooltip title="Delete" placement="top" arrow>
                <IconButton onClick={openAlertBox}>
                  <DeleteOutlineIcon />
                </IconButton>
              </Tooltip>
            </Hidden>

            <SubLink
              to={`/team/${+teamId}/season/${+seasonId}/current-team/menu`}
              isFullPath
            >
              <Button color="primary" size="large" className={classes.Cancel}>
                <Translate id="button.cancel" />
              </Button>
            </SubLink>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={handleSave}
              className={classes.Save}
            >
              <Translate id="button.save" />
            </Button>
          </CustomheadRight>
        </Grid>
      </Paper>
    </>
  )
}

TopBar.propTypes = {
  handleSave: PROP_TYPES.func.isRequired,
  deletePlayer: PROP_TYPES.func.isRequired,
  sharePlayer: PROP_TYPES.func.isRequired,
  history: PROP_TYPES.shape().isRequired,
  match: PROP_TYPES.shape().isRequired,
  teamPermissions: PROP_TYPES.arrayOf(PROP_TYPES.string).isRequired
}

export default compose(
  withRouter,
  connect(({ players, auth }) => ({
    teamPermissions: auth.permissions.teams.find(item => item.id === players.current.team_id)?.permissions ?? []
  }), {})
)(TopBar)
