// import React from 'react'
import styled from 'styled-components'
// import AddButton from './buttons/AddButton'

const DrillsContainer = styled.div`
  // .MuiCardMedia-img {
  //   width: 1rem;
  //   height: 1rem;
  //   margin-right: 0.3rem;
  // }
  display: flex;
  flex-flow: row wrap;
  padding: 0.5rem 0;

  > div {
    width: calc(20% - 12px);
    margin: 6px;
    border: 1px solid #ededed;
    border-radius: 8px;

    @media only screen and (max-width: 1279px) {
      width: calc(50% - 12px);
    }

    @media only screen and (max-width: 767px) {
      width: 100%;
    }
  }
  > div {
    // position: inherit !important;
  }
  .drillImg {
    width: 100%;
    height: 6.25rem;
    position: inherit;
    margin: 0;
    padding: 0;
    border-radius: 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background: white;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
    @media only screen and (max-width: 769px) {
      width: 5rem;
      height: 5rem;
    }
  }
  .title {
    display: inline-block;
    white-space: nowrap;
    width: calc(100% - 2rem);
    overflow: hidden !important;
    text-overflow: ellipsis;
    line-height: 1.3;
    text-overflow: ellipsis;
  }
  .rec-carousel-wrapper {
    width: 100% !important;
  }

  @media only screen and (max-width: 769px) {
    .MuiAlert-root {
      width: 100%;
      height: 7rem;
      background-color: #fff !important;
      align-items: flex-start;
      border: #ededed solid 1px;
      box-shadow: 0 0px 36px 0 rgba(0, 0, 0, 0.05);
      .MuiAlert-message {
        width: calc(100% - 34px);
      }
      .Snackbar-Box {
        width: calc(100% - 100px);
      }
      .MuiAlert-icon {
        display: none;
      }
    }
    .drillContent {
      width: calc(100% - 126px);
      display: flex;
      flex-direction: column;
      justify-items: inherit;
      justify-content: center;
      margin-left: 0.7rem;

      .extraAction {
        display: flex;

        .time-content {
          display: flex;
          margin-left: 0.3rem;
          align-items: center;
          .MuiCardMedia-img {
            width: 1rem;
            height: 1rem;
            margin-right: 0.3rem;
          }
        }
      }
    }
  }
`

export default DrillsContainer
