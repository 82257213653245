import React, { useContext, useCallback, useState, useMemo } from 'react'
import { makeStyles, createTheme } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { DragDropContext } from 'react-beautiful-dnd'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import {
  drills as drillsModule,
  trainings as trainingsModule,
} from 'redux/modules'
import Tour from 'reactour'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { Translate } from 'react-localize-redux'
import {
  Hidden,
  Button,
  Box,
  Badge,
  Dialog,
  IconButton,
  Paper,
  Tooltip,
  Typography,
  Divider,
  Card,
} from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import CloseIcon from '@material-ui/icons/Close'
import EventPopup from 'components/material/Calender/eventPopup'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import { ShareMuiIcon, SubLink } from 'containers'
import playerIcon from 'assets/images/16px/player@1x.svg'
import { compose } from 'redux'
import { PolarPlayers, PolarSummary, Ticker } from 'components'
import hasSubscription from 'utils/hasSubscription'
import { getPolarToken } from 'utils/localstorage'
import TaskGroupsWidget from "../../containers/TaskManagement/TaskGroupsWidget";
import { Context } from './TrainingStateContext'
import {
  Summary,
  MiniCalendar,
  TrainingPackages,
  Drills,
  Session,
  GroupsAndParticipation,
  TrainingNotes,
} from './components'
import { TRAININGS_GAME } from 'constants/calender'
import PROP_TYPES from 'constants/propTypes'

const theme = createTheme({})
const useStyles = makeStyles({
  paper: {
    width: '100%',
    display: 'flex',
    padding: '.6rem',
  },
  Cancel: {
    margin: '0 .8rem',
  },
  PlayerNum: {
    margin: '0 .8rem',
    '& img': {
      marginRight: '.6rem',
    },
    '& :hover': {
      backgroundColor: 'transparent',
    },
  },
  groupsParticipation:{
    marginBottom:"1.5rem"
  },
  [theme.breakpoints.down('sm')]: {
    paper: {
      display: 'flex',
      flexDirection: 'column',
    },

    MobileSectionDialog: {
      '& .MuiDialog-paperWidthSm': {
        width: '100%',
      },
    },
    DialogClose: {
      position: 'absolute',
      top: '4px',
      right: '52px',
      zIndex: '10',
    },
    MobileSectionDialogBtn: {
      textAlign: 'center',
      display: 'flex',
      height: '4.375rem',
      position: 'fixed',
      boxShadow: '14px 5px 35px 0 rgba(0,0,0,0.08)',
      padding: '0.875rem 1.125rem',
      bottom: '0',
      left: '0',
      borderTop: '1px solid var( --light-grey)',
      background: 'var(--white)',
      width: '100%',
      zIndex: '10',
      '& a': {
        width: '50%',
      },
      '& .MuiButton-root': {
        width: '100%',
      },
      '& .MuiBadge-badge': {
        top: '2px',
        left: '6px',
        position: 'relative',
        transform: 'inherit',
      },
    },
  },
})

const CustomDivider = styled(Divider)`
  margin: 13px 0 !important;
`
const SESSION_CATEGORIES = ['warmup', 'main_part', 'final_part']
const SESSION_DRILLS_FIELD_NAME = 'sessionDrills'

const disableBody = target => disableBodyScroll(target)
const enableBody = target => enableBodyScroll(target)


const TrainingDashboardLayout = ({
  trainingId,
  handleSave,
  availableDrills,
  availableTrainings,
  fetchDrill,
  createTraining,
  deleteTraining,
  history,
  shareTraining,
  changeSnackbar,
  setLoading,
  match: { params },
}) => {
  const { teamId, seasonId } = params
  const {
    trainingState: { sessionDrills, normalizedPlayers, tickers, polar_event_id: trainingPolarSelectedId, training_players },
    onFieldChange,
    resetState,
    setDragging,
  } = useContext(Context)

  const durationMemo = useMemo(() => {
    return sessionDrills.reduce((total, drill) => total + drill.customized_duration, 0)
  }, [sessionDrills])

  const [totalDuration, setTotalDuration] = useState(sessionDrills.reduce((total, drill) => total + drill.customized_duration, 0))

  const calculateDuration = () => {
    setTotalDuration(sessionDrills.reduce((total, drill) => total + drill.customized_duration, 0))
  }

  const [open, setOpen] = useState(false)
  const [open2, setOpen2] = useState(false)
  const [trainingAndGame, setTrainingAndGame] = useState('')
  const [isAlertBox, changeAlertBox] = useState(false)
  const [eventDelete, changeDeleteEvent] = useState(0)
  const [duplicateData, changeDuplicateData] = useState(null)
  const [isDuplicate, setIsDuplicate] = React.useState(false)

  const [polarTeam, setPolarTeam] = useState(null)
  const [selectedPolarEevent, setSelectedPolarEevent] = useState(trainingPolarSelectedId)
  const [polarTrainning, setPolarTrainning] = useState(null)
  const [polarToken, setPolarToken] = useState(getPolarToken());

  const onDragEnd = useCallback(
    ({ destination, draggableId, source }) => {
      setDragging({ dragging: false, destination: null })
      if (!destination) {
        return
      }
      const { droppableId: category, index } = destination

      const [normalizedId] = draggableId.split('$')

      const newDrill = SESSION_CATEGORIES.includes(source.droppableId)
        ? sessionDrills.findById(+normalizedId)
        : availableDrills.findById(+normalizedId)

      const drillsForTargetCategory = sessionDrills.filter(
        drill =>
          drill.session_part === category &&
          (drill.id !== +normalizedId || source.droppableId !== category)
      )

      drillsForTargetCategory.splice(index, 0, {
        ...newDrill,
        session_part: category,
        customized_duration:newDrill.duration
      })

      onFieldChange(SESSION_DRILLS_FIELD_NAME, [
        ...drillsForTargetCategory,
        ...sessionDrills.filter(
          drill =>
            drill.session_part !== category &&
            (drill.id !== +normalizedId ||
              drill.session_part !== source.droppableId)
        ),
      ])
      fetchDrill(+normalizedId, {})
    },
    [sessionDrills, availableDrills]
  )
  const handleClose2 = () => {
    setOpen2(false)
    changeAlertBox(false)
    changeDuplicateData(null)
    setIsDuplicate(false)
  }

  const handleOpen2 = (text = '') => {
    setTrainingAndGame(text)
    setOpen2(true)
  }

  const newCreateTraining = data => {
    createTraining(0, data).then(res => {
      const { id } = {
        ...res.response.data,
      }
      handleClose2()
      window.location.href = `/team/${+teamId}/season/${+seasonId}/trainings/${id}`
    })
  }

  const onOK = () => {
    deleteTraining(eventDelete).then(() => {
      window.location.href = `/team/${+teamId}/season/${+seasonId}/events`
      handleClose2()
    })
  }

  const openAlertBox = id => {
    changeAlertBox(true)
    changeDeleteEvent(id)
    handleOpen2()
  }

  const openDuplicateBox = id => {
    changeDuplicateData(availableTrainings.find(p => p.id === id) || null)
    handleOpen2(TRAININGS_GAME.training)
    setIsDuplicate(true)
  }
  const onChange = fieldName => newValue => onFieldChange(fieldName, newValue)

  const [isTourOpen, setIsTourOpen] = useState(false)

  const closeTour = () => {
    setIsTourOpen(false)
  }
  const CustomIconButton = styled.div`
    display: flex;
  `
  const CustomheadRight = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    .MuiDialog-paperWidthSm {
      width: 100%;
    }
  `

  const accentColor = '#29a373'
  const tourConfig = [
    {
      selector: '.session-drills',
      content: <Translate id="tour.training.session-drills" />,
    },
    {
      selector: '.training-packages',
      content: <Translate id="tour.training.training-packages" />,
    },
    {
      selector: '.groups-participation',
      content: <Translate id="tour.training.groups-participation" />,
    },
    {
      selector: '.share-button',
      content: <Translate id="tour.training.share-button" />,
    },
  ]
  const classes = useStyles()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  React.useEffect(()=>{
    setTotalDuration(durationMemo)
  },[durationMemo])

  return (
    <>
      {open2 &&
        <EventPopup
          open={open2}
          onClose={handleClose2}
          onOK={onOK}
          duplicateData={duplicateData}
          isAlertBox={isAlertBox}
          trainingAndGame={trainingAndGame}
          createTraining={newCreateTraining}
          createMatch={() => 0}
          calenderDateSelected={false}
          calenderDate={null}
          history={history}
          selectedEventType={trainingAndGame}
          isDuplicate={isDuplicate}
        />
      }

      <Grid container spacing={3}>
        <Hidden only={['xs', 'sm']}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Grid item md={6} xs={12} align="left" display="flex">
                <MiniCalendar
                  availableTrainings={availableTrainings}
                  trainingId={trainingId}
                />
              </Grid>
              <Hidden only={['md', 'lg', 'xl']}>
                <CustomDivider />
              </Hidden>
              <Grid item md={6} xs={12} align="right" display="flex">
                <CustomheadRight>
                  <Tooltip title="Player" placement="top" arrow>
                    <CustomIconButton className={classes.PlayerNum}>
                      {' '}
                      <img src={playerIcon} alt={playerIcon} />{' '}
                      <Typography variant="h6" component="h6">
                        {`${
                          normalizedPlayers.filter(p => p.available === 1)
                            .length
                        }/${normalizedPlayers.length}`}
                      </Typography>
                    </CustomIconButton>
                  </Tooltip>
                  <Tooltip title="Share" placement="top" arrow>
                    <ShareMuiIcon
                      share={shareTraining}
                      id={trainingId}
                      shareBy="Training"
                      className="share-button"
                    />
                  </Tooltip>
                  <Tooltip title="Duplicate" placement="top" arrow>
                    <IconButton onClick={() => openDuplicateBox(+trainingId)}>
                      <FileCopyIcon />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Delete" placement="top" arrow>
                    <IconButton onClick={() => openAlertBox(+trainingId)}>
                      <DeleteOutlineIcon />
                    </IconButton>
                  </Tooltip>
                  <SubLink
                    to={`/team/${+teamId}/season/${+seasonId}/events`}
                    isFullPath
                  >
                    <Button
                      color="primary"
                      size="large"
                      className={classes.Cancel}
                    >
                      <Translate id="button.cancel" />
                    </Button>
                  </SubLink>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={handleSave}
                  >
                    <Translate id="button.save" />
                  </Button>
                </CustomheadRight>
              </Grid>
            </Paper>
          </Grid>
        </Hidden>
        <DragDropContext
          onDragEnd={onDragEnd}
          onDragStart={({ draggableId }) => {
            setDragging({ dragging: true, destination: draggableId })
          }}
        >
          <Grid item md={8} lg={9} xs={12}>
            <Hidden only={['md', 'lg', 'xl']}>
              {' '}
              <Summary
                trainingId={trainingId}
                availableTrainings={availableTrainings}
                handleSave={handleSave}
              />
                 {polarToken && <PolarSummary
                 setPolarToken={setPolarToken}
                 polarToken={polarToken}
                polarTeam={polarTeam}
                setPolarTeam={setPolarTeam}
                selectedPolarEevent={selectedPolarEevent}
                setSelectedPolarEevent={setSelectedPolarEevent}
                polarTrainning={polarTrainning}
                setPolarTrainning={setPolarTrainning}
              />}
             
            </Hidden>

            <Drills
              className="session-drills"
              sessionDrills={sessionDrills}
              changeSessionDrills={onChange(SESSION_DRILLS_FIELD_NAME)}
            />

            <div className="training-packages">
              <TrainingPackages
                sessionDrills={sessionDrills} changeSessionDrills={onChange(SESSION_DRILLS_FIELD_NAME)}
              />
            </div>
            <Card className={`${classes.groupsParticipation  } groups-participation`}>
              <GroupsAndParticipation
                className="groups-participation"
                players={normalizedPlayers}
                training_players={training_players}
                changePlayers={onChange('training_players')}
              />
            </Card>
            {hasSubscription() !== 'basic' && 
            <Card>
              <TrainingNotes />
            </Card>
            }
            {polarTeam && selectedPolarEevent &&
              <PolarPlayers 
              polarTeam={polarTeam}
              selectedPolarEevent={selectedPolarEevent}
              polarTrainning={polarTrainning}
              setPolarToken={setPolarToken}
              polarToken={polarToken}
              trainingId={trainingId}
              />}
          </Grid>
         
          <Grid item md={4} lg={3} xs={12}>
            <Hidden only={['xs', 'sm']}>
              {' '}
              <Summary
                trainingId={trainingId}
                availableTrainings={availableTrainings}
                handleSave={handleSave}
         
              />
                 {polarToken && <PolarSummary
                   setPolarToken={setPolarToken}
                   polarToken={polarToken}
                  polarTeam={polarTeam}
                  setPolarTeam={setPolarTeam}
                  selectedPolarEevent={selectedPolarEevent}
                  setSelectedPolarEvent={setSelectedPolarEevent}
                  polarTrainning={polarTrainning}
                  setPolarTrainning={setPolarTrainning}
              />}
            </Hidden>
            <Box style={{position:'sticky', top:'130px'}}>
            <Hidden only={['xs', 'sm']}>
              <Session
                sessionDrills={sessionDrills}
                changeSessionDrills={onChange(SESSION_DRILLS_FIELD_NAME)}
                handleSave={handleSave}
                calculateDuration={calculateDuration}
                totalDuration={totalDuration}
                resetState={resetState}
                changeSnackbar={changeSnackbar}
                setLoading={setLoading}
              />
            </Hidden>
         
            <Hidden only={['md', 'lg', 'xl']}>
              <Box className={classes.MobileSectionDialogBtn}>
                <Button
                  fullWidth
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={handleClickOpen}
                >
                  <Translate id="button.view-training" />
                  <Badge
                    badgeContent={`${totalDuration}'`}
                    color="secondary"
                    className={classes.CustomBadge}
                  />
                </Button>
                {totalDuration > 0 && <Badge color="error" badgeContent="!" />}
              </Box>

              <Dialog
                open={open}
                onClose={handleClose}
                className={classes.MobileSectionDialog}
              >
                <IconButton
                  edge="start"
                  className={classes.DialogClose}
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Session
                  sessionDrills={sessionDrills}
                  changeSessionDrills={onChange(SESSION_DRILLS_FIELD_NAME)}
                  handleSave={handleSave}
                  calculateDuration={calculateDuration}
                  totalDuration={totalDuration}
                  resetState={resetState}
                  changeSnackbar={changeSnackbar}
                  setLoading={setLoading}
                />
              </Dialog>
            </Hidden>
              <Box mt={3}> <TaskGroupsWidget isTrainingEvent eventId={trainingId} /> </Box>
              {tickers && tickers.length>0 && <Box mt={3}><Ticker tickers={tickers}/></Box>}
            </Box>
          </Grid>
         
        </DragDropContext >
      </Grid>

      <Tour
        onRequestClose={closeTour}
        steps={tourConfig}
        isOpen={isTourOpen}
        rounded={5}
        accentColor={accentColor}
        onAfterOpen={disableBody}
        onBeforeClose={enableBody}
      />
    </>
  )
}

TrainingDashboardLayout.defaultProps = {
  availableDrills: [],
  availableTrainings: [],
}

TrainingDashboardLayout.propTypes = {
  trainingId: PROP_TYPES.id.isRequired,
  handleSave: PropTypes.func.isRequired,
  fetchDrill: PropTypes.func.isRequired,
  createTraining: PropTypes.func.isRequired,
  deleteTraining: PropTypes.func.isRequired,
  shareTraining: PropTypes.func.isRequired,
  changeSnackbar: PropTypes.string.isRequired,
  setLoading: PropTypes.string.isRequired,
  availableDrills: PROP_TYPES.arrayOfObjects,
  availableTrainings: PROP_TYPES.arrayOfObjects,
  history: PROP_TYPES.shape().isRequired,
  match: PROP_TYPES.shape().isRequired,
}

export default compose(
  connect(
    ({ trainings, drills }) => ({
      availableDrills: Object.values(drills.itemsByCategory).flat(1),
      availableTrainings: trainings.current.trainings,
    }),
    {
      fetchDrill: drillsModule.fetchDrill,
      createTraining: trainingsModule.createTraining,
      deleteTraining: trainingsModule.deleteTraining,
      shareTraining: trainingsModule.shareTraining,
    }
  ),
  withRouter
)(TrainingDashboardLayout)
