import { matchPath } from 'react-router-dom'
import { toast } from 'react-toastify'
import { createUrlWithParamsFromObject } from 'utils/reduxHelpers'
import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
  patchRequest
} from 'utils/request'
import { getLocale, getClub } from 'utils/localstorage'
import filterObject from 'utils/filterObject'
import normalizeDataForServer from 'utils/normalizeDataForServer'
import { INITIAL_ROUTE } from 'constants/routes'

const METHOD_NAME_TO_METHOD_MAPPER = {
  GET: getRequest,
  POST: postRequest,
  PUT: putRequest,
  PATCH: patchRequest,
  SHARE: postRequest,
  DELETE: deleteRequest,
}

const METHOD_NAME_TO_MESSAGE_MAPPER = {
  GET: 'loaded',
  POST: 'created',
  PUT: 'updated',
  PATCH: 'updated',
  SHARE: 'shared',
  DELETE: 'deleted',
}

export default ({ dispatch, getState }) => {
  return next => action => {

    const { types, callAPI, shouldCallAPI = () => true, payload = {} } = action

    if (!types) {
      return next(action)
    }

    if (!types.request || !types.success || !types.failure) {
      throw new Error('Expected an object of three string types.')
    }

    if (!shouldCallAPI(getState())) {
      return false
    }

    const method = METHOD_NAME_TO_METHOD_MAPPER[callAPI.method]

    if (typeof method !== 'function') {
      throw new Error('Expected callAPI to be a function.')
    }

    const {
      request: requestType,
      success: successType,
      failure: failureType,
    } = types

    dispatch(
      Object.assign({}, payload, {
        type: requestType,
      })
    )

    const {
      params: { teamId = '', seasonId = '' },
    } = matchPath(getState().router.location.pathname, {
      path: INITIAL_ROUTE,
    }) || { params: {} }

    const {team_id, season_id, ...rest } = normalizeDataForServer(callAPI.params)

    // @fixme: move callAPI.data.preventToastNotification to callAPI.preventToastNotification, because data is always sent to server
    const hideToast = callAPI.data
      ? callAPI.data.preventToastNotification === true
      : false

    return method(
      createUrlWithParamsFromObject(
        callAPI.path,
        filterObject(
          {
            ...rest,
            team_id: (teamId||team_id),
            season_id: (seasonId||season_id),
            locale: getLocale(),
            club_id: getClub() && getClub().id ? getClub().id : 0,
          },
          Boolean
        )
      ) + (callAPI.paramsString || ''),
      callAPI.data,
      callAPI.onProgress
    )
      .then(response => {
        if (callAPI.method !== 'GET') {
          if (!hideToast)
            toast(
              `${callAPI.entity} successfully ${
                METHOD_NAME_TO_MESSAGE_MAPPER[callAPI.method]
              }`
            )
        }

        return dispatch(
          Object.assign({}, payload, {
            response,
            type: successType,
          })
        )
      })
      .catch(error => {
        if (!hideToast)
          toast(
            `${callAPI.entity} not ${
              METHOD_NAME_TO_MESSAGE_MAPPER[callAPI.method]
            }`
          )

        dispatch(
          Object.assign({}, payload, {
            error,
            type: failureType,
          })
        )

        throw error
      })
  }
}
