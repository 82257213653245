import React from 'react'
import { saveAs } from 'file-saver'
import { compose } from 'redux'
import { withLocalize, Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import { Button } from '@material-ui/core'
import PROP_TYPES from 'constants/propTypes'

const ShareIconPdf = ({ share, id, data, isData, handleSave, setLoading }) => {
  let downloadLink = ''
  const shareWrapped = e => {
    e.preventDefault()
    e.stopPropagation()
    const shareStr = `&shared_by=pdf`
    handleSave()
    setLoading(true)
    if (downloadLink !== '') {
      saveAs(downloadLink)
    } else if (isData) {
      share(id, shareStr, data).then(({ response: { data: downloadUrl } }) => {
        downloadLink = downloadUrl
        saveAs(downloadUrl)
        setLoading(false)
      })
    } else {
      share(id, shareStr).then(({ response: { data: downloadUrl } }) => {
        downloadLink = downloadUrl
        saveAs(downloadUrl)
        setLoading(false)
      })
    }

    return 1
  }

  return (
    <>
      <Button
        fullWidth
        color="primary"
        variant="outlined"
        size="large"
        onClick={shareWrapped}
      >
        <Translate id="button.download-as-pdf" />
      </Button>
    </>
  )
}

ShareIconPdf.defaultProps = {
  data: '',
  isData: false,
}

ShareIconPdf.propTypes = {
  isData: PROP_TYPES.bool,
  id: PROP_TYPES.string.isRequired,
  share: PROP_TYPES.func.isRequired,
  data: PROP_TYPES.shape(),
  handleSave: PropTypes.func.isRequired,
  setLoading: PropTypes.string.isRequired,
}

export default compose(withLocalize)(ShareIconPdf)
