import urlConstructor from 'utils/urlConstructor'

export const REGISTER_ROUTE = '/:locale?/signup'
export const LOGIN_ROUTE = '/:locale?/login'
export const SOCIAL_LOGIN_ROUTE = '/auth/:provider'
export const LOGOUT_ROUTE = '/logout'
export const USER_ROUTE = '/coach-profile'
export const PLAYER_ROUTE = '/player'
export const WRONG_PERMISSION_ROUTE = '/wrong-permission'
export const NO_MATCH_ROUTE = '/no-match'
export const INITIAL_ROUTE = urlConstructor(':teamId', ':seasonId')
export const HELP_ROUTE = 'https://www.coachbetter.com/faqs'
export const TEAMS_ROUTE = '/teams'

export const UPDATE_CARD_DATA = '/card/update'

export const SEASONS_ROUTE = '/seasons'

export const TRAININGS_CALENDAR_ROUTE = '/trainings'
export const TRAINING_DASHBOARD_ROUTE = '/trainings/:trainingId'

export const MATCHES_CALENDAR_ROUTE = '/matches'
export const MATCH_DASHBOARD_ROUTE = '/matches/:matchId'
export const EVENT_DASHBOARD_ROUTE = '/general_events/:eventId'

export const SUBSCRIPTION_INVOICE = '/invoice'

export const TEAM_MENU_ROUTE = '/current-team/menu'
export const TEAM_DASHBOARD_ROUTE = '/current-team'
export const TEAM_EDIT_ROUTE = '/current-team/edit'
export const TEAM_UPGRADE_ROUTE = '/current-team/:upgradeView/edit'
export const TEAM_SUBSCRIPTION_UPGRADE_ROUTE = '/current-team/edit'
export const TEAM_PLAYER_CREATE_ROUTE = '/current-team/player/create'
export const TEAM_PLAYER_EDIT_ROUTE = '/current-team/player/:playerId/edit'
export const TEAM_PLAYER_DASHBOARD_ROUTE =
  '/current-team/player/:playerId/overview'

export const TEAM_ACCESS_ROUTE = '/team-access'
export const TEAM_ACCESS_CREATE_ROUTE = '/team-access/create'
export const TEAM_ACCESS_EDIT_ROUTE = '/team-access/:staffId/edit'

export const DOCUMENTS_ROUTE = '/current-team/documents'
export const DOCUMENTS_FOLDER_ROUTE = '/current-team/documents/:folderId'

export const DRILLS_DASHBOARD_ROUTE = '/drills'
export const DRILL_CREATE_ROUTE = '/drills/create'
export const EVENT_ROUTE = '/events'
export const DRILL_EDIT_ROUTE = '/drills/:drillId/edit'
export const DRILLS_CATEGORIES = '/drills-categories'

export const TRAINING_PACKAGES_DASHBOARD_ROUTE = '/training-packages'
export const TRAINING_PACKAGE_CREATE_ROUTE = '/training-packages/create'
export const TRAINING_PACKAGE_EDIT_ROUTE =
  '/training-packages/:trainingPackageId/edit'

export const LINEUPS_DASHBOARD_ROUTE = '/lineups'
export const LINEUP_CREATE_ROUTE = '/lineups/create'
export const LINEUP_EDIT_ROUTE = '/lineups/:lineupId/edit'

export const ARTICLES_DASHBOARD_ROUTE = '/articles'
export const ARTICLES_ALL_DASHBOARD_ROUTE = '/articles/all'
export const ARTICLES_NEW_DASHBOARD_ROUTE = '/articles/new'
export const ARTICLE_ROUTE = '/articles/:articleId'
export const ARTICLE_CREATE_ROUTE = '/articles/create'
export const ARTICLE_EDIT_ROUTE = '/articles/:articleId/edit'
export const ARTICLES_CATEGORIES = '/articles-categories'
export const ARTICLES_CATEGORIES_VIEW = '/articles/categories'

export const FAVORITES_ROUTE = '/favorites'

export const VIDEO_ANALYSIS_ROUTE = '/videos'
export const VIDEO_ROUTE = '/videos/:videoId'
export const BASE_IMG_URL = window.REACT_APP_BASE_IMG_URL

export const CONNECT_THIRDPARTY_ROUTE = '/connect'

export const TASK_MANAGEMENT_ROUTE = '/task-management'
export const TASK_ROUTE = '/task-group/:taskGroupId'