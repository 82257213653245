import React from 'react'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import { Translate } from 'react-localize-redux'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import {
  Grid,
  Button,
  Divider,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  CardActionArea,
  Typography,
} from '@material-ui/core'
import LogoImage from 'assets/images/coachbetter-ball.svg'
import Alert from '@material-ui/lab/Alert'
import { diffDate } from 'utils/formatDate'
import { getUserId } from 'utils/localstorage'
import PropTypes from 'prop-types'
import GooglePlayLogo from 'assets/images/logos/google_play.svg'
import AppStoreLogo from 'assets/images/logos/app_store_black.svg'
import PERMISSIONS from 'constants/permissions'

const useStyles = makeStyles(theme => ({
  card: {
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    '& .MuiTypography-h6': {
      marginTop: '1.2rem',
      fontWeight: 600,
    },
  },
  CardActionArea: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: 180,
  },
  media: {
    height: 140,
    width: 130,
    backgroundSize: 100,
  },
  alert: {
    padding: '5px',
    '& .MuiTypography-body1': {
      fontSize: '0.8rem',
    },
    '& .MuiAlert-icon': {
      marginRight: '6px',
    },
  },
  appStoreWrapper: {
    display: 'flex'
  },
  appStoreLink: {
    display: 'block',
    marginRight: theme.spacing(1)
  },
  appStoreImg: {
    height: '40px',
    width: 'auto'
  }
}))

const editTeam = (id, seasonId) => {
  return `/team/${id}/season/${seasonId}/current-team/menu`
}
const dashboard = (id, seasonId) => {
  return `/team/${id}/season/${seasonId}`
}

const editSubscription = (id, seasonId) => {
  return `/team/${id}/season/${seasonId}/current-team/true/edit`
}

const PlayerCardActions = () => {
  const classes = useStyles()

  return (
    <div className={classes.appStoreWrapper}>
      <a
        className={classes.appStoreLink}
        href="https://apps.apple.com/us/app/coachbetter-player-app/id1568995326"
      >
        <img className={classes.appStoreImg} src={AppStoreLogo} alt="Download on the App Store" />
      </a>
      <a
        className={classes.appStoreLink}
        href="https://play.google.com/store/apps/details?id=com.coachbetter.coachbetter"
      >
        <img className={classes.appStoreImg} src={GooglePlayLogo} alt="Get it on GooglePlay" />
      </a>
    </div>
  )
}

const ManagerCardActions = ({
  id,
  seasonid,
  isPlanlExpire,
  userId,
  club,
  isPaid,
  endTime
}) => {
  return (
    <>
      { !isPlanlExpire && (!userId || userId === getUserId()) ? (
        // Coach which created the team
        <>
          <Button
            href={ dashboard(id, seasonid) }
            color="primary"
            variant="outlined"
            style={ {
              color: club.theme.secondarycolor,
              borderColor: club.theme.secondarycolor,
            } }
          >
            <Translate id="menu.dashboard"/>
          </Button>
          <Button
            href={ editTeam(id, seasonid) }
            color="primary"
            variant="outlined"
            style={ {
              color: club.theme.secondarycolor,
              borderColor: club.theme.secondarycolor,
            } }
          >
            <Translate id="menu.team"/>
          </Button>
          <Button
            href={ editSubscription(id, seasonid) }
            color="primary"
            variant="contained"
            style={ {
              background: club.theme.secondarycolor,
              color: club.theme.backgroundcolor,
            } }
          >
            <Translate id="myteams.subscription"/>
          </Button>
        </>
      ) : (
        // Staff user access
        <>
          {/* Check if paid or has no team id or end date is in the past */ }
          { isPaid === 0 || id === null || endTime < 0 ? (
            <Button
              href={ editSubscription(id, seasonid) }
              color="primary"
              variant="contained"
              style={ {
                background: club.theme.secondarycolor,
                color: club.theme.backgroundcolor,
              } }
            >
              <Translate id="myteams.subscription"/>
            </Button>
          ) : (
            <Button
              href={ editTeam(id, seasonid) }
              color="primary"
              variant="outlined"
              style={ {
                color: club.theme.secondarycolor,
                borderColor: club.theme.secondarycolor,
              } }
            >
              <Translate id="myteams.show-team"/>
            </Button>
          ) }
        </>
      ) }
    </>
  )
}

const TeamsList = ({
  authState,
  teams,
  onSelect,
}) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        {teams.map(
          ({
            name,
            id,
            endsAt,
            logo,
            subscription_id: subscriptionId,
            paid,
            seasons,
            user_id: userId,
            club,
          }) => {
            const seasonid = seasons && seasons.length > 0 ? seasons[0].id : 1
            const endTime = endsAt ? diffDate(endsAt, new Date()) : 1
            const isPlanlExpire =
              endsAt == null && subscriptionId !== null
                ? false
                : !(new Date(endsAt) >= new Date())
                

            // @todo: #roles replace with helper and/or maybe check club level permissions as well
            const teamPermissions = authState.permissions.teams.find(item => item.id === id)?.permissions ?? []
            const canManageTeam = teamPermissions.includes(PERMISSIONS.UPDATE_TEAM)

            return (
              <Grid item key={id} xs={12} sm={6} md={3}>
                <Card
                  className={classes.card}
                  onClick={() => canManageTeam && endTime >= 0 && onSelect(id)}
                  style={{
                    backgroundColor: club.theme.backgroundcolor,
                  }}
                >
                  <CardActionArea className={classes.CardActionArea}>
                    { !isPlanlExpire && (
                      <CardMedia
                        className={ classes.media }
                        image={logo ? logo.full_url : LogoImage}
                        title={ name }
                      />
                    ) }

                    <CardContent>
                      <Typography variant="h6" component="h2" gutterBottom>
                        {name}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="span"
                      >
                        {isPlanlExpire ? (
                          <>
                            <Alert severity="error" className={classes.alert}>
                              <Typography variant="h4">
                                <Translate id="myteams.ended-subscription" />
                              </Typography>
                              <Typography gutterBottom>
                                {' '}
                                <Translate id="myteams.please-enter-cc" />
                              </Typography>
                            </Alert>
                          </>
                        ) : (
                          <>
                            Team ID: {id}
                          </>
                        )}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <Divider />
                  <CardActions>
                    { canManageTeam
                      ?
                      <ManagerCardActions
                        id={ id }
                        seasonid={ seasonid }
                        isPlanlExpire={ isPlanlExpire }
                        userId={ userId }
                        club={ club }
                        isPaid={ !!paid }
                        endTime={ endTime }
                      />
                      :
                      <PlayerCardActions />
                    }
                  </CardActions>
                </Card>
              </Grid>
            )
          }
        )}
      </Grid>
    </div>
  )
}

TeamsList.defaultProps = {
  teams: [],
  onSelect: () => 0,
}

TeamsList.propTypes = {
  teams: PropTypes.arrayOf(PropTypes.object.isRequired),
  onSelect: PropTypes.func,
}

export default compose(
  withRouter,
  connect(
    ({ auth }) => ({
      authState: auth,
    }),
    {})
)(TeamsList)
