import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import PlaceholderImage from 'assets/images/carousel-item-placeholder.jpg'

// @todo: replace placeholder image
const StyledCarouselItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 0 15px 15px 0;
  width: 240px;
  height: 165px;
  border-radius: 8px;
  z-index: 0;

  :after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    border-radius: 8px;
    background: url(${({ image }) => image || PlaceholderImage}) no-repeat
      center;
    background-size: cover;
  }
`

const CarouselItem = ({ image, children, className }) => (
  <StyledCarouselItem image={image} className={className}>
    {children}
  </StyledCarouselItem>
)

CarouselItem.defaultProps = {
  image: '',
  children: <div />,
  className: '',
}

CarouselItem.propTypes = {
  image: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element.isRequired,
    PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  ]),
  className: PropTypes.string,
}

// @todo: 2 lines max
CarouselItem.Title = styled.div`
  display: block;
  display: -webkit-box;
  width: 100%;
  max-width: 100%;
  height: 70px;
  max-height: 70px;
  padding: 10px 20px;
  overflow: hidden;
  border-radius: 8px;
  background: #000;
  color: #fff;
  font-size: 16px;
  letter-spacing: 0.2px;
  line-height: 19px;
  opacity: 0.7;
`

export default CarouselItem
