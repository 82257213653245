import React from 'react'
import { Translate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { makeStyles, createTheme } from '@material-ui/core/styles'
import { SubLink, HeartIcon, ShareMuiIcon, InnrHead, HasClubPermission } from 'containers'
import { getUserId } from 'utils/localstorage'
import {
  Paper,
  Chip,
  Box,
  Divider,
  IconButton,
  Button,
  Grid,
  Typography,
} from '@material-ui/core'
import timeIcon from 'assets/images/time@1x.svg'
import KnowledgeBaseIcon from '@material-ui/icons/School'
import { ActionWithPrompt } from 'components'
import { articles as articlesModule } from 'redux/modules'
import { createSubUrl } from 'utils/urlConstructor'
import { formatCostumDateMonthNoForUI } from 'utils/formatDate'
import { Categories } from '../ArticlesCategoriesView/components'
import PROP_TYPES from 'constants/propTypes'
import { ARTICLE_EDIT_ROUTE, ARTICLES_DASHBOARD_ROUTE } from 'constants/routes'
import PERMISSIONS from 'constants/permissions'

const theme = createTheme({})
const useStyles = makeStyles({
  articleTag: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '.6rem 0',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  paper: {
    margin: 'auto',
    maxWidth: 900,
  },
  button: {
    margin: theme.spacing(0, 1),
  },
  timingBox:{
    marginLeft: 'auto',
  },
  divider:{
    marginBottom: '1rem'
  },
  paper:{
    padding: '1rem'
  },
  StyledImage: {
    background: '#ccc',
    width: '100%',
    borderRadius: '12px',
  },
  [theme.breakpoints.down('sm')]: {},
})

const ArticleContainer = ({
  history,
  location: { fromUrl },
  match: {
    params: { articleId },
  },
  match,
  article: {
    title,
    image_url: imageUrl,
    body,
    categories,
    created_at: createdAt,
    reading_time: readingTime,
    user_id: userId,
  },
  favoritesArticles,
  articlesCategories,
  shareArticle,
  deleteArticle
}) => {
  const linkBack = fromUrl || createSubUrl(match, ARTICLES_DASHBOARD_ROUTE)
  const handleDelete = () =>
    deleteArticle(+articleId).then(() => history.push(linkBack))

  const getIsFavorite = id => Boolean(favoritesArticles.findById(id))
  const classes = useStyles()

  return (
    <>
      <InnrHead
        title={
          <IconButton component={SubLink} to={linkBack} isFullPath>
            <KnowledgeBaseIcon fontSize="small" />
          </IconButton>
        }
      >
        <HasClubPermission ability={ PERMISSIONS.DELETE_ARTICLE } or={userId === getUserId()}>
          <ActionWithPrompt
            className={classes.button}
            onSuccess={handleDelete}
            message="Are you sure you want to delete the Article?"
            title={<Translate id="button.delete" />}
          />
        </HasClubPermission>
        <HasClubPermission ability={ PERMISSIONS.UPDATE_ARTICLE } or={userId === getUserId()}>
          <Button
            variant="contained"
            color="primary"
            component={SubLink}
            to={ARTICLE_EDIT_ROUTE.populateURLParams({ articleId })}
          >
            <Translate id="button.edit" />
          </Button>
        </HasClubPermission>
      </InnrHead>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={7} lg={9} xl={9}>
          <Paper component={Box} p={3}>
            <Box className={classes.paper}>
              <Typography variant="h3">{title}</Typography>
              <Box className={classes.articleTag}>
                {articlesCategories &&
                  articlesCategories.length > 0 &&
                  categories.map(p => {
                    const { title: catTitle } = articlesCategories.findById(p)

                    return <Chip label={catTitle} key={p} variant="outlined" />
                  })}
              </Box>

              <Box display="flex" my={2}>
                {/* <Box component="span">
                  <Box component="div" color="text.secondary">
                    {formatCostumDateMonthNoForUI(createdAt)}
                  </Box>
                </Box> */}
                {readingTime && (
                  <Box
                    component="span"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    className={classes.timingBox}
                  >
                    <img src={timeIcon} alt="" />
                    <Box component="span" ml={0.5} color="text.secondary">
                      {`${readingTime}`} <Translate id="articles.min-read" />
                    </Box>
                  </Box>
                )}
              </Box>
              <Box display="flex" my={1}>
                <HeartIcon
                  isActive={getIsFavorite(+articleId)}
                  entity="articles"
                  entityId={+articleId}
                  isPositionRelative
                />
                <ShareMuiIcon
                  share={shareArticle}
                  id={articleId}
                  shareBy="Article"
                />
              </Box>
              <img src={imageUrl} alt="" className={classes.StyledImage} />

              {/* <StyledImage src= /> */}
              <Box component="div" dangerouslySetInnerHTML={{ __html: body }} />

              <Box display="flex" my={1}>
                <HeartIcon
                  isActive={getIsFavorite(+articleId)}
                  entity="articles"
                  entityId={+articleId}
                  isPositionRelative
                />
                <ShareMuiIcon
                  share={shareArticle}
                  id={articleId}
                  shareBy="Article"
                />
              </Box>

              <Divider className={classes.divider} />
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={3} xl={3}>
          <Paper component={Box} className={classes.paper}>
            <Categories
              selectedCategory={categories}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}

ArticleContainer.defaultProps = {
  favoritesArticles: [],
  articlesCategories: [],
}

ArticleContainer.propTypes = {
  history: PROP_TYPES.history.isRequired,
  location: PROP_TYPES.location.isRequired,
  match: PROP_TYPES.match.isRequired,
  article: PROP_TYPES.shape().isRequired,
  favoritesArticles: PROP_TYPES.arrayOfObjects,
  articlesCategories: PROP_TYPES.shape(),
  shareArticle: PROP_TYPES.func.isRequired,
  deleteArticle: PROP_TYPES.func.isRequired,
}

export default connect(
  ({ articles, favorites }) => ({
    article: articles.current,
    articlesCategories: articles.current.articlesCategories,
    favoritesArticles: favorites.items.articles
  }),
  {
    shareArticle: articlesModule.shareArticle,
    deleteArticle: articlesModule.deleteArticle,
  }
)(ArticleContainer)
