import React from 'react'
import { UserLoader } from 'loaders'
import PlayerDashboard from './PlayerDashboard'

const Player = () => (
  <UserLoader>
    <PlayerDashboard />
  </UserLoader>
)

Player.propTypes = {}

export default Player
