import React from "react"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core"
import PropTypes from "prop-types"
import { Translate } from "react-localize-redux"

const DeletePlayerAlert = ({
  open,
  onCancel,
  onConfirm,
}) => {
  const handleClose = () => {
    onCancel()
  }

  const handleConfirm = () => {
    onConfirm()
  }

  return (
    <Dialog open={ open } onClose={ handleClose }>
      <DialogTitle>
        <Translate id="button.delete"/>
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          <Translate id="events.are-you-sure-you-want-to-delete"/>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={ handleClose } variant="contained">
          <Translate id="button.close"/>
        </Button>
        <Button onClick={ handleConfirm } color="primary" variant="contained" autoFocus>
          <Translate id="button.ok"/>
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DeletePlayerAlert.defaultProps = {
  open: false,
}

DeletePlayerAlert.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
}

export default DeletePlayerAlert
