import { combineActions, handleActions } from 'redux-actions'
import {
  actionsTypesGenerator,
  updateItems,
  onRequest,
  onSuccess,
  onFailure,
} from 'utils/reduxHelpers'
import initialState from '../initialState'

const namespace = 'TEAM'

const FETCH_TEAMS = actionsTypesGenerator(`FETCH_${namespace}S`)
const FETCH_TEAM = actionsTypesGenerator(`FETCH_${namespace}`)
const CREATE_TEAM = actionsTypesGenerator(`CREATE_${namespace}`)
const UPDATE_TEAM = actionsTypesGenerator(`UPDATE_${namespace}`)
const UPDATE_LOCALE = actionsTypesGenerator('UPDATE_LOCALE')
const DELETE_TEAM = actionsTypesGenerator(`DELETE_${namespace}`)
const UPGRADE_TEAM = actionsTypesGenerator(`UPGRADE_${namespace}`)
const FETCH_TEAM_SKILL = actionsTypesGenerator(`FETCH_${namespace}_SKILL`)
const ADD_TEAM_SKILL = actionsTypesGenerator(`ADD_${namespace}_SKILL`)
const DELETE_TEAM_SKILL = actionsTypesGenerator(`DELETE_${namespace}_SKILL`)
const IMPORT_PLAYERS = actionsTypesGenerator('IMPORT_PLAYERS')
const PATCH_TEAM = actionsTypesGenerator(`PATCH_${namespace}`)
const FETCH_TEAM_INVITE_URL = actionsTypesGenerator(`FETCH_TEAM_INVITE_URL`)

export const fetchTeams = () => ({
  types: FETCH_TEAMS,
  callAPI: {
    method: 'GET',
    entity: 'Teams',
    path: '/user/teams',
  },
})

export const updateLocale = () => ({
  types: UPDATE_LOCALE,
  callAPI: {
    method: 'GET',
    entity: 'Language',
    path: `/user/locale/${localStorage.getItem('locale') || 'en'}`,
  },
})

export const fetchTeam = (id, params) => ({
  types: FETCH_TEAM,
  callAPI: {
    method: 'GET',
    entity: 'Team',
    path: `/teams/${id}`,
    params,
  },
})

export const createTeam = data => ({
  types: CREATE_TEAM,
  callAPI: {
    method: 'POST',
    entity: 'Team',
    path: '/teams',
    data,
  },
})

export const updateTeam = data => ({
  types: UPDATE_TEAM,
  callAPI: {
    method: 'PUT',
    entity: 'Team',
    path: `/teams/${data.id}`,
    data,
  },
})

export const createTeamSkill = (teamId, data, params = {}) => ({
  types: ADD_TEAM_SKILL,
  callAPI: {
    method: 'POST',
    entity: 'Skill',
    path: `/teams/${teamId}/skills`,
    data,
    params,
  },
})
export const teamSkills = (teamId, params = {}) => ({
  types: FETCH_TEAM_SKILL,
  callAPI: {
    method: 'GET',
    entity: 'Skills',
    path: `/teams/${teamId}/skills`,
    params,
  },
})

export const updateTeamSkill = (skillId, data, params = {}) => ({
  types: ADD_TEAM_SKILL,
  callAPI: {
    method: 'PUT',
    entity: 'Skill',
    path: `/skills/${skillId}`,
    data,
    params,
  },
})

export const upgradeTeam = data => ({
  types: UPGRADE_TEAM,
  callAPI: {
    method: 'POST',
    entity: 'Team',
    path: `/teams/upgrade`,
    data,
  },
})

export const deleteTeam = id => ({
  types: DELETE_TEAM,
  callAPI: {
    method: 'DELETE',
    entity: 'Team',
    path: `/teams/${id}`,
  },
  payload: { id },
})

export const deleteTeamSkill = id => ({
  types: DELETE_TEAM_SKILL,
  callAPI: {
    method: 'DELETE',
    entity: 'Skill',
    path: `/skills/${id}`,
  }
})

export const importPlayers = (id, data) => ({
  types: IMPORT_PLAYERS,
  callAPI: {
    method: 'POST',
    entity: 'Players Import',
    path: `/teams/${id}/import_players`,
    data,
  },
})

export const patchTeam = (uuid, data) => ({
  types: PATCH_TEAM,
  callAPI: {
    method: 'PATCH',
    entity: 'Team',
    path: `/v2/teams/${uuid}/`,
    data,
  },
})

//@fixme: remove old implementation
//@link https://pixelplus.atlassian.net/browse/CB-632
const enableNewLink = process.env.REACT_APP_ENABLE_NEW_INVITE_LINK === 'true' || false;

export const fetchInviteUrl = (teamUuid) => ({
  types: FETCH_TEAM_INVITE_URL,
  callAPI: {
    method: 'GET',
    entity: 'Invites URL',
    path: enableNewLink
      ? `/v2/teams/${teamUuid}/invite-url`
      : `/v2/teams/${teamUuid}/old-invite-link`,
  },
})

export default handleActions(
  {
    [combineActions(
      FETCH_TEAMS.request,
      FETCH_TEAM.request,
      CREATE_TEAM.request,
      UPDATE_TEAM.request,
      UPDATE_LOCALE.request,
      DELETE_TEAM.request,
      UPGRADE_TEAM.request,
      FETCH_TEAM_SKILL.request,
      ADD_TEAM_SKILL.request,
      DELETE_TEAM_SKILL.request,
      IMPORT_PLAYERS.request,
      PATCH_TEAM.request,
      FETCH_TEAM_INVITE_URL.request,
    )]: onRequest,
    [FETCH_TEAMS.success]: (state, { response: { data = [] } }) => {
      return onSuccess({
        ...state,
        items: data,
      })
    },
    [PATCH_TEAM.success]: (state, { response: { data } }) => {
      return onSuccess({
        ...state,
        current: {
          ...state.current,
          share_player_information: data.share_player_information,
          share_player_skills_with_player: data.share_player_skills_with_player,
          share_player_skills_with_team: data.share_player_skills_with_team,
          share_player_statistics: data.share_player_statistics,
          default_player_attendance_status: data.default_player_attendance_status,
        }
      });
    },
    [combineActions(
      FETCH_TEAM.success,
      CREATE_TEAM.success,
      UPDATE_TEAM.success
    )]: (state, { response: { data } }) => {
      return onSuccess({
        ...state,
        items: updateItems(state.items, data),
        current: data,
      })
    },
    [combineActions(DELETE_TEAM.success, DELETE_TEAM_SKILL.success)]: (
      state,
      { id }
    ) => onSuccess({ ...state, items: state.items.filterById(id) }),
    [combineActions(
      FETCH_TEAMS.failure,
      FETCH_TEAM.failure,
      CREATE_TEAM.failure,
      UPGRADE_TEAM.failure,
      UPDATE_TEAM.failure,
      DELETE_TEAM.failure,
      FETCH_TEAM_SKILL.failure,
      ADD_TEAM_SKILL.failure,
      DELETE_TEAM_SKILL.failure,
      IMPORT_PLAYERS.failure,
      PATCH_TEAM.failure,
      FETCH_TEAM_INVITE_URL.failure,
    )]: onFailure,
  },
  initialState.teams
)
