import styled from 'styled-components'
import AddImage from 'assets/images/icon-add.svg'
import PlayerImage from 'assets/images/player.svg'
import DummyPlayerImage from 'assets/images/player-dummy.svg'

const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 52px;
  height: 50px;

  .dashboard-team-player & {
    height: 80px;
    width: 85px;
  }
  @media (max-width: 640px) {
    width: 35px;
    height: 34px;
  }
`

const DummyBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 52px;
  height: 50px;

  .dashboard-team-player & {
    height: 80px;
    width: 85px;
  }

  margin: 24px auto;
`

export const PlayerBody = styled(Body)`
  background: url(${PlayerImage}) no-repeat center;
  background-size: cover;
`

export const StaffBody = styled(Body)`
  background: url(${PlayerImage}) no-repeat center;
  background-size: cover;
`

export const PlayerDummyBody = styled(DummyBody)`
  background: url(${DummyPlayerImage}) no-repeat center;
  background-size: cover;
`

export const StaffDummyBody = styled(DummyBody)`
  background: url(${DummyPlayerImage}) no-repeat center;
  background-size: cover;
`

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 10px 10px;
  // width: 80px;

  // .dashboard-team-player & {
  //   // width: 85px;
  //   margin: 8px 4px;
  //   @media (max-width: 767px) {
  //     margin: 8px 0;
  //   }
  // }
`

export const Number = styled.div`
  color: #fff;
  font-size: 14px;
  @media (max-width: 767px) {
    font-size: 12px;
  }
`

export const SubPosition = styled.div`
  position: absolute;
  left: -3px;
  top: 18px;
  color: #999999;
  font-size: 12px;
  font-weight: bold;
`

export const FullName = styled.div`
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.09px;
  text-align: center;
  color: #7a7a7a;
`

export const Text = styled.div`
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.09px;
  text-align: center;
  color: #999999;
  &.expired {
    color: red;
  }
`

export const AddIcon = styled.div`
  width: 30px;
  height: 30px;
  background: url(${AddImage}) no-repeat center;
`

export const EditAboButton = styled.a`
  width: 100%;
  padding: 0 8px;
  background: #0e4b4d;
  border-radius: 8px;
  color: white;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  max-height: 25px;
  margin: 0 5px 10px 5px;
  font-size: 12px;
`
