import React from 'react'
import { TeamLoader } from 'loaders'
import TeamEditContainer from './TeamEditContainer'
import PROP_TYPES from 'constants/propTypes'

const TeamEdit = ({
  match: {
    params: { teamId },
  },
  match,
  history,
}) => (
  <TeamLoader teamId={+teamId} additionalFields={['logo']}>
    <TeamEditContainer match={match} history={history} />
  </TeamLoader>
)

TeamEdit.propTypes = {
  match: PROP_TYPES.match.isRequired,
  history: PROP_TYPES.history.isRequired,
}

export default TeamEdit
