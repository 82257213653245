import React from 'react'
import { Translate } from 'react-localize-redux'
import { SequencesList } from 'containers'
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Box,
} from '@material-ui/core'
import videoIcon from 'assets/images/video@1x.svg'
// import {SequencesIcon } from 'components'
import PROP_TYPES from 'constants/propTypes'

const Sequences = ({ sequences }) => (
  <Card>
    <CardHeader
      title={
        <Typography variant="h4" component="h4">
          <Translate id="videos.sequences" />
        </Typography>
      }
    />
    <CardContent>
      {sequences.length > 0 ? (
        <SequencesList sequences={sequences} />
      ) : (
        <Box
          width="100%"
          mr={1}
          display="flex"
          alignItems="center"
          justifyContent="center"
          textAlign="left"
        >
          <img src={videoIcon} alt={videoIcon} />

          <Typography variant="p" component="p">
            <Translate id="matches.no-sequences-text" />
          </Typography>
        </Box>
      )}
    </CardContent>
  </Card>
)

Sequences.defaultProps = {
  sequences: [],
}

Sequences.propTypes = {
  sequences: PROP_TYPES.arrayOfObjects,
}

export default Sequences
