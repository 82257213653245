/* eslint-disable no-param-reassign */
import React, { useContext } from 'react'
import { DraggablePlayer } from 'containers'
import { Translate } from 'react-localize-redux'
import { PlayersList } from 'components'
import { TextField, MenuItem, Box, Typography } from '@material-ui/core'
import groupPlayersByPosition from 'utils/groupPlayersByPosition'
import { Heading, DraggableArea } from './styledComponents'
import PROP_TYPES from 'constants/propTypes'
import { Context } from '../MatchStateContext'

const filterValue = [
  {
    value: 'players',
    label: <Translate id="matches.all-players" />,
  },
  {
    value: 'goalkeeper',
    label: <Translate id="matches.goalkeeper" />,
  },
  {
    value: 'defender',
    label: <Translate id="matches.defender" />,
  },
  {
    value: 'midfield',
    label: <Translate id="matches.midfield" />,
  },
  {
    value: 'striker',
    label: <Translate id="matches.striker" />,
  },
]

const Roster = ({
  players,
  fromPathname,
  onPlayerChange,
  handleSave,
  matchStatPlayers,
  availablePlayersCount
}) => {
  const {
    goalkeepers,
    defenders,
    midfielders,
    strikers,
  } = groupPlayersByPosition(players)

  const [filterRoster, setFilterRoster] = React.useState('players')

  const { matchState } = useContext(Context)
  const participants = matchState.matchPlayers.filter(p => p.participant === true)

  const handleSelectChange = event => {
    setFilterRoster(event.target.value)
  }
  const updatedPlayers = players.map(p => {
    const player = p
    player.notes = matchStatPlayers.findById(player.id).notes
    player.rating = matchStatPlayers.findById(player.id).rating
    player.tag = matchStatPlayers.findById(player.id).tag
    player.participant = matchStatPlayers.findById(player.id).participant
    player.in_roster = matchStatPlayers.findById(player.id).in_roster

    return player
  })
  players = updatedPlayers

  return (
    <>
      <Box display="flex" alignItems="center">
        <Heading variant="h4" component="h4">
          <Translate id="matches.roster" />
        </Heading>
        <TextField
          component={Box}
          id="standard-select-currency"
          select
          label="Filter"
          variant="outlined"
          value={filterRoster}
          onChange={handleSelectChange}
          style={{ width: 180, marginLeft: 'auto' }}
        >
          {filterValue.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      {!availablePlayersCount && !participants.length && (
        <Typography variant="body1">
          <Translate id="matches.no-available-players-message" />
        </Typography>
      )}
      <DraggableArea>
        {filterRoster === 'players' && (
          <>
            {players.length === 0 ? (
              <Translate id="matches.drag-players" />
            ) : (
              <PlayersList
                players={players}
                WrapperComponent={DraggablePlayer}
                position="midfield"
                fromPathname={fromPathname}
                onPlayerChange={onPlayerChange}
                ratingEditable
                handleSave={handleSave}
              />
            )}
          </>
        )}
        {filterRoster === 'goalkeeper' && (
          <>
            {goalkeepers.length === 0 ? (
              <Translate id="matches.drag-players" />
            ) : (
              <PlayersList
                players={goalkeepers}
                WrapperComponent={DraggablePlayer}
                position="goalkeeper"
                fromPathname={fromPathname}
                onPlayerChange={onPlayerChange}
                ratingEditable
                handleSave={handleSave}
              />
            )}
          </>
        )}

        {filterRoster === 'defender' && (
          <>
            {defenders.length === 0 ? (
              <Translate id="matches.drag-players" />
            ) : (
              <PlayersList
                players={defenders}
                WrapperComponent={DraggablePlayer}
                position="defender"
                fromPathname={fromPathname}
                onPlayerChange={onPlayerChange}
                ratingEditable
                handleSave={handleSave}
              />
            )}
          </>
        )}

        {filterRoster === 'midfield' && (
          <>
            {midfielders.length === 0 ? (
              <Translate id="matches.drag-players" />
            ) : (
              <PlayersList
                players={midfielders}
                WrapperComponent={DraggablePlayer}
                position="midfield"
                fromPathname={fromPathname}
                onPlayerChange={onPlayerChange}
                ratingEditable
                handleSave={handleSave}
              />
            )}
          </>
        )}
        {filterRoster === 'striker' && (
          <>
            {strikers.length === 0 ? (
              <Translate id="matches.drag-players" />
            ) : (
              <PlayersList
                players={strikers}
                WrapperComponent={DraggablePlayer}
                position="striker"
                fromPathname={fromPathname}
                onPlayerChange={onPlayerChange}
                ratingEditable
                handleSave={handleSave}
              />
            )}
          </>
        )}
      </DraggableArea>
    </>
  )
}

Roster.defaultProps = {
  players: [],
  handleSave: null,
  matchStatPlayers: [],
}

Roster.propTypes = {
  players: PROP_TYPES.arrayOfObjects,
  fromPathname: PROP_TYPES.string.isRequired,
  onPlayerChange: PROP_TYPES.func.isRequired,
  handleSave: PROP_TYPES.function,
  matchStatPlayers: PROP_TYPES.arrayOfObjects,
}

export default Roster
