import React from 'react'
import { TaskGroupsLoader, TeamLoader } from 'loaders'
import TaskGroupsDashboard from './TaskGroupsDashboard'
import PROP_TYPES from 'constants/propTypes'

const TaskGroups = ({
  match: {
    params: { teamId },
  },
}) => (
  <TeamLoader teamId={teamId} alwaysRenderChildren passIsLoading additionalFields={['players']}>
    <TaskGroupsLoader teamId={teamId} >
      <TaskGroupsDashboard />
    </TaskGroupsLoader>
  </TeamLoader>
)

TaskGroups.propTypes = {
  match: PROP_TYPES.match.isRequired,
}

export default TaskGroups
