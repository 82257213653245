import React from 'react'
import PlayerEditDialog from '../../../PlayerDashboard/components/PlayerEditDialog'

const EditDialog = (props) => {
    const { onClose, open, player, updatePlayer } = props;
  
    const handleClose = () => {
      onClose();
    };
  
    const handleOpen = () => {
      onClose(false);
    };
  
    return (
      <>
      {open && <PlayerEditDialog
            open={open}
            handleOpen={handleOpen}
            handleClose={handleClose}
            initialValues={{ ...player }}
            setInitialValues={()=>{}}
            updatePlayer={updatePlayer}
            changeEditablePlayer={()=>{}}
          />}
      </>
    );
  }

export default EditDialog


