import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withLocalize, Translate } from 'react-localize-redux'
import { drills as drillsModule } from 'redux/modules'
import { Tabs, Tab, Divider, Paper, Box } from '@material-ui/core'
import DrillsByCategoryContainer from './DrillsByCategoryContainer'
import PROP_TYPES from 'constants/propTypes'

const Drills = ({
  sessionDrills,
  changeSessionDrills,
  drillsCategories,
  fetchDrillsByCategory,
  activeLanguage: { code: locale },
}) => {
  // const Drills = ({
  //   sessionDrills,
  //   changeSessionDrills,
  //   drillsCategories,
  //   fetchDrillsByCategory,
  //   activeLanguage: { code: locale },
  // }) => (
  const [value, setValue] = React.useState(0)
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const parentCategoryTitles = drillsCategories.filter(
    category => category.parent_id === null
  )

  return (
    <Paper component={Box} mb={3}>
      <Translate>
        {(
          { translate } // eslint-disable-line
        ) => (
          <>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              variant="scrollable"
              scrollButtons="auto"
            >
              {drillsCategories
                .filter(category => category.parent_id === null)
                .map(drillCategory => (
                  <Tab
                    key={
                      drillCategory.title !== null
                        ? drillCategory.title.toLowerCase()
                        : ''
                    }
                    label={drillCategory.title}
                  />
                ))}
            </Tabs>
            <Divider />
          </>
        )}
      </Translate>
      <Box px={2} pt={2} pb={0}>
        {parentCategoryTitles.map(
          (drillCategory, index) =>
            value === index && (
              <div>
                {' '}
                <DrillsByCategoryContainer
                  sessionDrills={sessionDrills}
                  changeSessionDrills={changeSessionDrills}
                  drillsCategories={drillsCategories}
                  fetchDrillsByCategory={fetchDrillsByCategory}
                  locale={locale}
                  tabTitle={drillCategory.title}
                  categoryType={['warmup', 'main_part', 'final_part']}
                  categoryTitle={parentCategoryTitles[index].title} // "Warmup"
                />
              </div>
            )
        )}
      </Box>
      {/* {value === 0 && (
        <div>
          {' '}
          <DrillsByCategoryContainer
            sessionDrills={sessionDrills}
            changeSessionDrills={changeSessionDrills}
            drillsCategories={drillsCategories}
            fetchDrillsByCategory={fetchDrillsByCategory}
            locale={locale}
            categoryType="warmup"
            categoryTitle={parentCategoryTitles[0].title}//"Warmup"
          />
        </div>
      )}
      {value === 1 && (
        <div>
          {' '}
          <DrillsByCategoryContainer
            sessionDrills={sessionDrills}
            changeSessionDrills={changeSessionDrills}
            drillsCategories={drillsCategories}
            fetchDrillsByCategory={fetchDrillsByCategory}
            locale={locale}
            categoryType="main_part"
            categoryTitle={parentCategoryTitles[1].title}//"Main Part"
          />
        </div>
      )}
      {value === 2 && (
        <div>
          {' '}
          <DrillsByCategoryContainer
            sessionDrills={sessionDrills}
            changeSessionDrills={changeSessionDrills}
            drillsCategories={drillsCategories}
            fetchDrillsByCategory={fetchDrillsByCategory}
            locale={locale}
            categoryType="final_part"
            categoryTitle={parentCategoryTitles[2].title}//"Final Part"
          />
        </div>
      )} */}
    </Paper>
  )
}

Drills.defaultProps = {
  sessionDrills: [],
}

Drills.propTypes = {
  sessionDrills: PROP_TYPES.arrayOfObjects,
  changeSessionDrills: PROP_TYPES.func.isRequired,
  drillsCategories: PROP_TYPES.arrayOfObjects.isRequired,
  fetchDrillsByCategory: PROP_TYPES.func.isRequired,
  activeLanguage: PROP_TYPES.shape().isRequired,
}

export default compose(
  connect(
    ({ trainings: { current } }) => ({
      drillsCategories: current.drill_categories,
    }),
    {
      fetchDrillsByCategory: drillsModule.fetchDrillsByCategory,
    }
  ),
  withLocalize
)(Drills)
