import React, { useState, useEffect, Fragment } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withLocalize } from 'react-localize-redux'
import { Table } from 'react-bootstrap'
import { Card, CloseButton } from 'components'
import {
  SubLink,
  CategoryForManageCategories,
  LanguageSelectorForAdmins,
} from 'containers'
import { articles } from 'redux/modules'
import { createSubUrl } from 'utils/urlConstructor'
import { getClub } from 'utils/localstorage'
import PROP_TYPES from 'constants/propTypes'
import { ARTICLES_DASHBOARD_ROUTE } from 'constants/routes'

const MAX_DEPTH = 2

// @todo: #roles protect route or move functionality outside
const ArticlesCategoriesContainer = ({
  location: { fromUrl },
  match,
  articlesCategories,
  createArticlesCategory,
  updateArticlesCategory,
  deleteArticlesCategory,
  activeLanguage,
}) => {
  const [articlesCategoriesState, changeArticlesCategoriesState] = useState([])

  useEffect(() => {
    changeArticlesCategoriesState(articlesCategories)
  }, [articlesCategories])

  const linkBack = fromUrl || createSubUrl(match, ARTICLES_DASHBOARD_ROUTE)

  const addNewCategory = parentId => (title, image) => {
    createArticlesCategory({
      title,
      image,
      parent_id: parentId,
    })
  }

  const Categories = ({ depth, parentId }) => (
    <>
      {articlesCategoriesState
        .filter(category => category.parent_id === parentId && category.club_id === getClub().id)
        .map(category => (
          <Fragment key={category.id}>
            <CategoryForManageCategories
              depth={depth}
              category={category}
              activeLocale={activeLanguage.code}
              updateCategory={updateArticlesCategory}
              deleteCategory={deleteArticlesCategory}
            />
            {depth < MAX_DEPTH && (
              <Categories depth={depth + 1} parentId={category.id} />
            )}
          </Fragment>
        ))}
      <CategoryForManageCategories.New
        depth={depth}
        addNewCategory={addNewCategory(parentId)}
      />
    </>
  )

  Categories.defaultProps = {
    parentId: null,
  }

  Categories.propTypes = {
    depth: PROP_TYPES.number.isRequired,
    parentId: PROP_TYPES.id,
  }

  return (
    <Card>
      <Card.Title className="d-flex justify-content-between">
        <div>
          Manage Categories
          <LanguageSelectorForAdmins />
        </div>
        <SubLink to={linkBack} isFullPath>
          <CloseButton />
        </SubLink>
      </Card.Title>
      <Table bordered>
        <tbody>
          <Categories depth={0} parentId={null} />
        </tbody>
      </Table>
    </Card>
  )
}

ArticlesCategoriesContainer.defaultProps = {
  articlesCategories: [],
}

ArticlesCategoriesContainer.propTypes = {
  location: PROP_TYPES.location.isRequired,
  match: PROP_TYPES.match.isRequired,
  articlesCategories: PROP_TYPES.arrayOfObjects,
  createArticlesCategory: PROP_TYPES.func.isRequired,
  updateArticlesCategory: PROP_TYPES.func.isRequired,
  deleteArticlesCategory: PROP_TYPES.func.isRequired,
  activeLanguage: PROP_TYPES.shape().isRequired,
}

export default compose(
  connect(
    ({
      articles: {
        current: { articlesCategories },
      },
    }) => ({
      articlesCategories,
    }),
    {
      createArticlesCategory: articles.createArticlesCategory,
      updateArticlesCategory: articles.updateArticlesCategory,
      deleteArticlesCategory: articles.deleteArticlesCategory,
    }
  ),
  withLocalize
)(ArticlesCategoriesContainer)
