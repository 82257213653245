import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@material-ui/core'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'

const SequencesOrder = ({ orderState }) => (
  <div>
    <Button
      size="small"
      variant="outlined"
      onClick={() =>
        orderState.changeOrder(orderState.order === 'desc' ? 'asc' : 'desc')
      }
    >
      Date
      {orderState.order === 'desc' ? (
        <ArrowDownwardIcon fontSize="inherit" />
      ) : (
        <ArrowUpwardIcon fontSize="inherit" />
      )}
    </Button>
  </div>
)

SequencesOrder.propTypes = {
  orderState: PropTypes.shape().isRequired,
}

export default SequencesOrder
