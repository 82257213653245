import React, { useContext, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { DropTarget, DragLayer } from 'react-dnd'
import styled from 'styled-components'
import { Player, DraggablePlayer } from 'containers'
import { Paper } from '@material-ui/core'
import { matches as matchesModule } from 'redux/modules'
import FieldBackground from 'assets/images/field.svg'
import { Context } from '../MatchStateContext'

const StyledField = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: url(${FieldBackground}) no-repeat center;
  background-size: contain;
  margin: 0 auto;
  }
`

const StyledPlayer = styled.div`
  position: absolute;
  left: ${({ positionX }) => positionX}%;
  top: ${({ positionY }) => positionY}%;
  transform: translate(-50%, -50%);
`

const Field = ({ handleSave, connectDropTarget, players }) => {
  const { matchState, onFieldChange } = useContext(Context)

  useEffect(() => {
    const { width } = document
      .getElementById('participantsField')
      .getBoundingClientRect()

    document.getElementById('participantsField').style.height = `${width *
      1.613}px`
  }, [])

  const participants = useMemo(() => matchState.matchPlayers.filter(
    matchPlayer => matchPlayer.participant && (matchPlayer.position_x !== 0 || matchPlayer.position_y !== 0)
    ),
    [matchState.matchPlayers]
  )

  const onPlayerChange = playerId => fields => {
    onFieldChange('players', matchState.players.updateById(playerId, fields))
  }

  return (
    <Paper>
      {connectDropTarget(
        <div id="participantsField">
          <StyledField>
            {participants.map(player => (
              <StyledPlayer
                key={player.id}
                positionX={player.position_x}
                positionY={player.position_y}
              >
                <DraggablePlayer
                  key={player.id}
                  playerId={player.id}
                  handleSave={handleSave}
                >
                  <Player
                    fromPathname="Match"
                    ratingEditable
                    onChange={onPlayerChange(player.id)}
                    handleSave={handleSave}
                    {...player}
                    hideAvailablityButton
                  />
                </DraggablePlayer>
              </StyledPlayer>
            ))}
          </StyledField>
        </div>
      )}
    </Paper>
  )
}

Field.defaultProps = {
  players: [],
}

Field.propTypes = {
  onDrop: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  connectDropTarget: PropTypes.func.isRequired,
  players: PropTypes.arrayOf(PropTypes.object.isRequired),
}

const fieldTarget = {
  drop({ item: { playerId }, clientOffset, onDrop }) {
    onDrop(playerId, 'field', clientOffset)
  },
}

export default compose(
  DragLayer(monitor => ({
    item: monitor.getItem(),
    clientOffset: monitor.getClientOffset(),
  })),
  DropTarget('player', fieldTarget, targetConnect => ({
    connectDropTarget: targetConnect.dropTarget(),
  })),
  withRouter,
  connect(
    ({
      matches: {
        current: { players, team = {} },
      },
    }) => ({
      teamName: team.name || '',
      players,
    }),
    {
      shareMatch: matchesModule.shareMatch,
      deleteMatch: matchesModule.deleteMatch,
    }
  )
)(Field)
