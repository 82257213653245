import React from 'react'
import { Translate } from 'react-localize-redux'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { push } from 'connected-react-router'
import styled from 'styled-components'
import { Popover, Button } from 'react-bootstrap'
import { SubLink } from 'containers'
import { getToken } from 'utils/localstorage'
import { trainings, matches } from 'redux/modules'
import EditIcon from 'assets/images/pencil.svg'
import { formatDateFromServer, formatDateToServer } from 'utils/formatDate'
import filterObject from 'utils/filterObject'
import { ActionWithPrompt } from 'components'
import DeleteIcon from '@material-ui/icons/Delete'
import { toast } from 'react-toastify'
import TrainingFormFields from './TrainingFormFields'
import MatchFormFields from './MatchFormFields'
import {
  TRAINING_DASHBOARD_ROUTE,
  MATCH_DASHBOARD_ROUTE,
} from 'constants/routes'
import { TRAININGS_ENTITY } from 'constants/entities'

const StyledButtons = styled.div`
  margin: 15px 0 0 0;
  button {
    width: 100%;
    margin: 0 auto 15px auto;
  }
`
/* eslint-disable */
export default (
  { id, team_id, season_id, status, start, location, opponent, type, isNew },
  entity,
  onExit
) => {
  const [dateDay, time] = formatDateFromServer(start)

  const isTrainings = entity === TRAININGS_ENTITY

  const handleDelete = () => {
    const token = getToken()
    const headers = {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    }
    const url = isTrainings ? '/trainings/' : '/matches/'
    const eventEntity = isTrainings ? 'Training' : 'Match'
    fetch(
      encodeURI(
        `${
          window.REACT_APP_API_ENDPOINT
        }${url}${id}?team_id=${team_id}&season_id=${season_id}`
      ),
      {
        method: 'DELETE',
        headers,
      }
    )
      .then(res => {
        if (res.status === 200) {
          toast(eventEntity + ' Successfully Deleted')
          window.location.reload()
          return res.json()
        }
        throw Error
      })
      .catch(e => {
        /* eslint-disable-next-line */
        toast(eventEntity + ' Not Deleted')
      })
  }

  if (status !== 'draft') {
    const params = {
      trainingId: id,
      matchId: id,
    }

    const linkTo = (isTrainings
      ? TRAINING_DASHBOARD_ROUTE
      : MATCH_DASHBOARD_ROUTE
    ).populateURLParams(params)

    return (
      <Popover id={`${entity}-event-popover-${id}`}>
        {`${dateDay}, ${time}`}
        {status !== 'onTraining' && status !== 'onMatch' && (
          <>
            <SubLink to={linkTo}>
              <img src={EditIcon} alt="Training dashboard" />
            </SubLink>
            <Translate>
              {({ translate }) => (
                <ActionWithPrompt
                  onSuccess={handleDelete}
                  message={translate('message.delete')}
                  title={translate('button.delete')}
                >
                  <DeleteIcon />
                </ActionWithPrompt>
              )}
            </Translate>
          </>
        )}
      </Popover>
    )
  }

  const createEvent = isTrainings
    ? trainings.createTraining
    : matches.createMatch
  const updateEvent = isTrainings
    ? trainings.updateTraining
    : matches.updateMatch

  const form = `${entity}-add-event-${id}`

  const initialValues = { dateDay, time, location, opponent, type }

  const onSubmit = (isRedirect = true) => (
    { dateDay, time, location, opponent, type },
    dispatch,
    { pathname }
  ) => {
    const date = formatDateToServer(dateDay, time)

    const data = {
      team_id,
      season_id,
      date,
      location,
      status,
    }

    if (isTrainings) {
      data.type = type
    } else {
      data.opponent = opponent
    }

    const action = isNew ? createEvent : updateEvent

    dispatch(action(id, filterObject(data, Boolean))).then(
      ({ response: { data } }) => {
        onExit()

        if (isRedirect && data && data.id) {
          dispatch(push(`${pathname}/${data.id}`))
        }
      }
    )
  }

  const Form = compose(
    connect(({ router: { location: { pathname } } }) => ({ pathname })),
    reduxForm({
      // TODO: destroy temp form in redux
      form,
      initialValues,
      destroyOnUnmount: false,
      enableReinitialize: true,
    })
  )(({ handleSubmit }) => (
    <form>
      {isTrainings ? <TrainingFormFields /> : <MatchFormFields />}
      <StyledButtons>
        {!isTrainings ? (
          <Button size="sm" onClick={handleSubmit(onSubmit())}>
            <Translate id="matches.create-match-day" />
          </Button>
        ) : (
          <div />
        )}
        <Button size="sm" onClick={handleSubmit(onSubmit(isTrainings))}>
          <Translate id="matches.plan" />
        </Button>
      </StyledButtons>
    </form>
  ))

  return (
    <Popover id={`${entity}-event-popover-${id}`}>
      <Form />
    </Popover>
  )
}
