import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Form } from 'react-bootstrap'
import PROP_TYPES from 'constants/propTypes'

const StyledSelect = styled(Form.Control)`
  border-radius: 15px;
  padding: 0 15px;
  background: #fafafa;
  color: var(--dark-green);
  height: 30px;
  margin: 0 5px;

  :disabled {
    background: #eeeeee;
  }
`

const Select = ({ options, value, onChange, disabled }) => (
  <StyledSelect
    as="select"
    value={value}
    onChange={({ currentTarget }) => onChange(currentTarget.value)}
    disabled={disabled}
  >
    {options.map(option => (
      <option key={option.value} value={option.value}>
        {option.title}
      </option>
    ))}
  </StyledSelect>
)

Select.defaultProps = {
  value: '',
  onChange: () => 0,
  disabled: false,
}

Select.propTypes = {
  options: PROP_TYPES.options.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
}

export default Select
