import React from 'react'
import { IconButton, Tooltip } from '@material-ui/core'
import binIcon from 'assets/images/16px/bin@1x.svg'
import editIcon from 'assets/images/16px/edit@1x.svg'
import duplicateIcon from 'assets/images/16px/duplicate@1x.svg'
import PROP_TYPES from 'constants/propTypes'
import { TRAININGS_GAME } from 'constants/calender'

const Actions=({event, onOpen, onDuplicateBox, onDeleteEvent}) => {

    const getEventType = (relation) => {

      if(relation === 'match') return TRAININGS_GAME.game
      if(relation === 'training') return TRAININGS_GAME.training

      return TRAININGS_GAME.event
    }

    return (<>
        <Tooltip title="Duplicate" placement="top" arrow>
          <IconButton onClick={() => onDuplicateBox(event.id, event.relation,true)}>
            <img src={duplicateIcon} alt={duplicateIcon} />
          </IconButton>
        </Tooltip> 

        <Tooltip title="Edit" placement="top" arrow>
          <IconButton onClick={() =>onOpen(getEventType(event.relation), false, event)}>
            <img src={editIcon} alt={editIcon} />
          </IconButton>
        </Tooltip>

        <Tooltip title="Delete" placement="top" arrow>
          <IconButton onClick={() => onDeleteEvent(event.id, event.relation)}>
            <img src={binIcon} alt={binIcon} />
          </IconButton>
        </Tooltip>
      </>)
}

Actions.propTypes = {
    onDeleteEvent: PROP_TYPES.func.isRequired,
    onOpen: PROP_TYPES.func.isRequired,
    onDuplicateBox: PROP_TYPES.func.isRequired,
    event:PROP_TYPES.shape().isRequired,
}

export default Actions