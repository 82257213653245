import React from 'react'
import { FormControlLabel, Checkbox as MuiCheckbox } from '@material-ui/core'
import PROP_TYPES from 'constants/propTypes'

const Checkbox = ({ id, label, checked, onChange, ...props }) => {

  return(

  <FormControlLabel
         control={
              <MuiCheckbox
                id={id}
                checked={checked}
                onChange={onChange}
                value={label}
                name="hide_global_training_packages"
                {...props}
              />
            }
            label={label}
          />
)}

Checkbox.defaultProps = {
  checked: false,
  props: {},
}

Checkbox.propTypes = {
  id: PROP_TYPES.id.isRequired,
  label: PROP_TYPES.string.isRequired,
  checked: PROP_TYPES.bool,
  onChange: PROP_TYPES.func.isRequired,
  props: PROP_TYPES.shape(),
}

export default Checkbox
