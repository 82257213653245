import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button } from 'react-bootstrap'

const PlanTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0.3px;
  color: #545454;
  line-height: 28px;
`
const PlanPrice = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: center;
  margin-bottom: 5px;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.3px;
  color: #111;
  line-height: 28px;
  span {
    font-size: 12px;
  }
  .radio-inp {
    width: 16px;
    height: 16px;
    margin-right: 6px;
  }
`
const PlanList = styled.ul`
  align-items: center;
  border-top: 1px solid #d7d4d4;
  width: 100%;
  padding: 10px 0 0;
  flex-wrap: wrap;
  margin: 0 0 5px 0;
  list-style: none;
`
const listItem = styled.li`
  margin: 5px 0;  
  font-size: 12px;
  font-weight: 400;
  flex: 1;
  letter-spacing: 0.3px;
  color: #545454;
  line-height: 20px;
  margin-bottom: 8px;
  padding-left: 18px;
  position: relative;
  :before{
    content: "x";
    position: absolute;
    top: 1px;
    left: 0;
    font-size: 14px;
    margin-right: 5px;
  }
  &.active{
    color: #111;
    font-weight: 500;
    :before{
      content: "\\2713";=
    }
  }`

const PlanButton = styled(Button)`
  border-radius: 20px;
  margin-bottom: 0;
  display: flex;
  padding: 0;
  justify-self: flex-end;
  background-color: #0e4b4d !important;
  border-color: #0e4b4d !important;
  justify-content: center;
  margin-top: auto;
  &.active {
    background-color: #fff !important;
    color: #0e4b4d !important;
  }
`

const Plan = ({ children, title, className, ...rest }) => (
  <div className={className} {...rest}>
    <PlanTitle>{title}</PlanTitle>
    {children}
  </div>
)

Plan.Price = PlanPrice
Plan.Monthly = PlanPrice
Plan.Year = PlanPrice
Plan.List = PlanList
Plan.ListItem = listItem
Plan.Button = PlanButton

Plan.defaultProps = {
  children: <div />,
}

Plan.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element.isRequired,
    PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  ]),
  className: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

const StyledPlan = styled(Plan)`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  background: #f5f5f5;
  border-radius: 8px;
  box-shadow: -2px 2px 27px 0 rgba(176, 176, 176, 0.5);
  padding: 20px;
  height: 100%;
`

export default StyledPlan
