import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
`
export const Navigation = styled.div`
  .video-mini-calendar & {
    font-size: 16px;
  }
`
export const NavPrev = styled.div`
  height: 20px;
  width: 20px;
  margin: 13px 0;
  cursor: pointer;

  img {
    width: 100%;
  }
  &:after {
    margin-left: 1.5rem;
    font-size: 1rem;
    font-weight: 400;
  }
`
export const NavNext = styled.div`
  height: 20px;
  width: 20px;
  margin: 13px 0;
  cursor: pointer;

  img {
    width: 100%;
  }
  &:before {
    margin-left: -1.8rem;
    font-size: 1rem;
    font-weight: 400;
  }
`
export const Items = styled.div`
  display: flex;
  align-items: center;
  padding: 0 10px;
  @media (max-width: 640px) {
    padding: 0 2px;
  }
`
export const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 68px;
  height: 45px;
  margin: 0 7px;
  padding: 0 7px;
  border-width: 0;
  border-style: ${({ status }) => (status === 'draft' ? 'dashed' : 'solid')};
  border-color: ${({ isAnotherMonth }) =>
    isAnotherMonth
      ? 'rgba(255, 255, 255, 0.38)'
      : `${props => props.theme.palette.primary.main}`};
  color: ${({ isAnotherMonth }) =>
    isAnotherMonth ? 'rgba(0,0,0,0.78)' : '#333'};
  font-weight: 600;
  letter-spacing: 0.1px;
  cursor: pointer;
  background-color: transparent;
  border-bottom: ${({ isToday }) =>
    isToday ? `2px solid ${props => props.theme.palette.primary.main}` : ''};
  font-weight: ${({ isToday }) => (isToday ? `bold` : '')};
  border-bottom: ${({ isCurrent }) =>
    isCurrent ? `2px solid ${props => props.theme.palette.primary.main}` : ''};
  font-weight: ${({ isCurrent }) => (isCurrent ? `bold` : '')};
  .video-mini-calendar & {
    width: 30px;
    height: 30px;
  }
  @media (max-width: 640px) {
    margin: 0 3px;
    font-size: 0.8rem;
    padding: 0 3px;
    width: 50px;
  }
`
