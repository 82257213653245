import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import { ShareMuiIcon, ShareIconPdf } from 'containers'
import { trainings as trainingsModule } from 'redux/modules'
import { makeStyles } from '@material-ui/core/styles'
import {
  CardContent,
  Card,
  CardHeader,
  Typography,
  Button,
  Hidden
} from '@material-ui/core'
import { SessionCategory, SessionCategoryMobile } from './components'
import { SessionPartWrapper } from './styledComponents'
import PROP_TYPES from 'constants/propTypes'

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1, 0),
  },
  header:{
    paddingBottom: 0
  },
  duration:{
    marginBottom: 2,
    fontSize: 16
  }
}))

const StyledCard = styled(Card)`
`
const SessionWrapScroll = styled.div`
  max-height: 500px;
  overflow: auto;
  @media (max-width: 640px) {
    max-height: 320px;
  }
  @media (max-width: 360px) {
    max-height: 276px;
    `
const Session = ({
  handleSave,
  sessionDrills,
  changeSessionDrills,
  changeSnackbar,
  calculateDuration,
  setLoading,
  totalDuration,
  match: {
    params: { trainingId },
  },
  shareTraining,
}) => {

  const filterDrillsByCategory = category =>
    sessionDrills.filter(drill => drill.session_part === category)

  const removeItem = useCallback(
    removedId => {
      const targetItemIndex = sessionDrills.findIndex(
        ({ id }) => id === removedId
      )

      changeSessionDrills(
        sessionDrills
          .map((item, index) => (index !== targetItemIndex ? item : null))
          .filter(Boolean)
      )
    },
    [sessionDrills]
  )

  const classes = useStyles()

  return (
    <StyledCard>
      <CardHeader className={classes.header}
        title={
          <Typography variant="h4" component="h4">
            <Translate id="training.session-summary" />
          </Typography>
        }
        action={
          <ShareMuiIcon
            share={shareTraining}
            id={trainingId}
            shareBy="Training"
            className="share-button"
            placement="left"
          />
        }
      />
      <CardContent>
        <SessionWrapScroll>
          <SessionPartWrapper>
            <Translate id="training.warmup" />
            <Hidden only={['xs', 'sm']}> 
            <SessionCategory
              relation="warmup"
              items={filterDrillsByCategory('warmup')}
              removeItem={removeItem}
              calculateDuration={calculateDuration}
            />
            </Hidden>
            <Hidden only={['xl', 'lg', 'md']}> 
               <SessionCategoryMobile
              relation="warmup"
              items={filterDrillsByCategory('warmup')}
              removeItem={removeItem}
              calculateDuration={calculateDuration}
            />
             </Hidden>
          </SessionPartWrapper>
          <SessionPartWrapper>
            <Translate id="training.main_part" />
            <Hidden only={['xs', 'sm']}> 
            <SessionCategory
              relation="main_part"
              items={filterDrillsByCategory('main_part')}
              removeItem={removeItem}
              calculateDuration={calculateDuration}
            />
            </Hidden>
            <Hidden only={['xl', 'lg', 'md']}> 
               <SessionCategoryMobile
              relation="main_part"
              items={filterDrillsByCategory('main_part')}
              removeItem={removeItem}
              calculateDuration={calculateDuration}
            />
             </Hidden>
          </SessionPartWrapper>
          <SessionPartWrapper>
            <Translate id="training.final_part" />
            <Hidden only={['xs', 'sm']}> 
            <SessionCategory
              relation="final_part"
              items={filterDrillsByCategory('final_part')}
              removeItem={removeItem}
              calculateDuration={calculateDuration}
            />
            </Hidden>
            <Hidden only={['xl', 'lg', 'md']}> 
               <SessionCategoryMobile
              relation="final_part"
              items={filterDrillsByCategory('final_part')}
              removeItem={removeItem}
              calculateDuration={calculateDuration}
            />
             </Hidden>
          </SessionPartWrapper>
        </SessionWrapScroll>
        <Typography className={classes.duration}>
          <Translate id="training.training-duration"/>: <b>{totalDuration}'</b>
        </Typography>
        <Button
          fullWidth
          color="primary"
          variant="contained"
          onClick={handleSave}
          className={classes.button}
          size="large"
        >
          <Translate id="button.save" />
        </Button>
        <ShareIconPdf
          handleSave={handleSave}
          share={shareTraining}
          id={trainingId}
          shareBy="Training"
          sessionDrillsLength={sessionDrills.length}
          changeSnackbar={changeSnackbar}
          setLoading={setLoading}
        />
      </CardContent>
    </StyledCard>
  )
}

Session.defaultProps = {
  sessionDrills: [],
  calculateDuration:null
}

Session.propTypes = {
  handleSave: PropTypes.func.isRequired,
  sessionDrills: PROP_TYPES.arrayOfObjects,
  changeSessionDrills: PropTypes.func.isRequired,
  match: PROP_TYPES.match.isRequired,
  shareTraining: PROP_TYPES.func.isRequired,
  totalDuration:PropTypes.string.isRequired,
  changeSnackbar: PropTypes.string.isRequired,
  setLoading: PropTypes.string.isRequired,
  calculateDuration:PropTypes.func
}

export default compose(
  withRouter,
  connect(
    null,
    {
      shareTraining: trainingsModule.shareTraining,
      deleteTraining: trainingsModule.deleteTraining,
    }
  )
)(Session)
