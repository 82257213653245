import React from 'react'
import { connect } from 'react-redux'
import { Translate } from 'react-localize-redux'
import styled from 'styled-components'
import { SubLink, HeartIcon, ShareMuiIcon } from 'containers'
import { Card, Carousel, CarouselItem } from 'components'
import { articles as articlesModule } from 'redux/modules'
import ArticlesImage from 'assets/images/icon-article.svg'
import PROP_TYPES from 'constants/propTypes'
import { ARTICLE_ROUTE } from 'constants/routes'

const IconsContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;

  display: flex;
`

const ArticlesWidgetContainer = ({
  articles,
  favoritesArticles,
  shareArticle,
}) => {
  const getIsFavorite = id => Boolean(favoritesArticles.findById(id))

  return (
    <Card>
      <Card.Title>
        <Card.Title.Icon icon={ArticlesImage} />
        <Translate id="home.articles" />
      </Card.Title>
      <Carousel>
        {articles.map(({ id: articleId, title, image_url: imageUrl }) => (
          <SubLink
            key={articleId}
            to={ARTICLE_ROUTE.populateURLParams({ articleId })}
          >
            <CarouselItem image={imageUrl}>
              <IconsContainer>
                <HeartIcon
                  isActive={getIsFavorite(articleId)}
                  entity="articles"
                  entityId={articleId}
                  isPositionRelative
                />
                <ShareMuiIcon
                  share={shareArticle}
                  id={articleId}
                  shareBy="Article"
                />
              </IconsContainer>
              <CarouselItem.Title>{title}</CarouselItem.Title>
            </CarouselItem>
          </SubLink>
        ))}
      </Carousel>
    </Card>
  )
}

ArticlesWidgetContainer.defaultProps = {
  articles: [],
  favoritesArticles: [],
}

ArticlesWidgetContainer.propTypes = {
  articles: PROP_TYPES.arrayOfObjects,
  favoritesArticles: PROP_TYPES.arrayOfObjects,
  shareArticle: PROP_TYPES.func.isRequired,
}

export default connect(
  ({ articles, favorites }) => ({
    articles: articles.items,
    favoritesArticles: favorites.items.articles,
  }),
  {
    shareArticle: articlesModule.shareArticle,
  }
)(ArticlesWidgetContainer)
