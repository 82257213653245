import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { drills } from 'redux/modules'
import { AddDrillMenu } from 'components'
import {
  Grid,
  Box,
  Typography,
  Breadcrumbs,
  Hidden,
  Toolbar,
  Divider,
} from '@material-ui/core'
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline'
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import ImagesSlider from '../ImagesSlider'
import { DialogTitle } from './Functions'
import { useStyles } from './styleComponents'
import PROP_TYPES from 'constants/propTypes'

const DrillHead = ({
  drill,
  onHide,
  categoryType,
  plusButton,
  handleMenuClick,
  handleMenuClose,
  anchorEl,
  classes = useStyles(),
}) => {
  const {
    title,
    images,
    age_class: ageClass,
    min_players: minPlayers,
    max_players: maxPlayers,
    duration,
  } = drill

  let addButton
  if (plusButton === 'true') {
    addButton = (
      <>
        <AddDrillMenu
          drillId={drill.id}
          handleMenuClick={handleMenuClick}
          handleMenuClose={handleMenuClose}
          categoryType={categoryType}
          anchorEl={anchorEl}
          shortAddButton="false"
        />
      </>
    )
  } else {
    addButton = ''
  }

  return (
    <>
      <DialogTitle id="dialog-title" onClose={onHide}>
        <Typography variant="h4" component="h4">
          {' '}
          {title}
        </Typography>
      </DialogTitle>
      <Divider />
      <Toolbar className={classes.toolbar}>
        <Grid container spacing={0}>
          <Hidden only={['xs', 'sm']}>
            <Grid item md={6} alignItems="center">
              <Breadcrumbs
                aria-label="breadcrumb"
                className={classes.breadcrumb}
              >
                <Typography color="inherit" className={classes.link}>
                  <PersonAddOutlinedIcon className={classes.icon} />
                  {ageClass}
                </Typography>
                <Typography color="inherit" className={classes.link}>
                  <PeopleOutlineIcon className={classes.icon} />
                  {maxPlayers > '0'
                    ? `${minPlayers} - ${maxPlayers}`
                    : `min ${minPlayers}`}
                </Typography>
                <Typography color="inherit" style={{display: 'flex'}}>
                  <AccessTimeIcon className={classes.icon} />
                  {duration}min
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Hidden>
          <Grid item md={6}>
            <Box> {addButton}</Box>
          </Grid>
          <Hidden only={['xl', 'lg', 'md']}>
            <Grid item xs={12} spacing={0}>
              {images.length > 0 && <ImagesSlider images={images} />}
            </Grid>
          </Hidden>
        </Grid>
      </Toolbar>
    </>
  )
}
DrillHead.defaultProps = {
  handleMenuClick: [],
  handleMenuClose: [],
  anchorEl: true,
  categoryType: [],
  plusButton: [],
}
DrillHead.propTypes = {
  drill: PropTypes.shape().isRequired,
  images: PropTypes.shape().isRequired,
  onHide: PropTypes.func.isRequired,
  classes: PropTypes.func.isRequired,
  title: PROP_TYPES.string.isRequired,
  categoryType: PROP_TYPES.string,
  plusButton: PROP_TYPES.string,
  handleMenuClick: PropTypes.func,
  handleMenuClose: PropTypes.func,
  anchorEl: PROP_TYPES.bool,
}

export default connect(null, {
  fetchDrill: drills.fetchDrill,
})(DrillHead)
