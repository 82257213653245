import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { TeamSelector } from 'containers'
import urlConstructor from 'utils/urlConstructor'
import { diffDate } from 'utils/formatDate'
import Dropdown from './Dropdown'
import PROP_TYPES from 'constants/propTypes'

const AVAILABLE_PATH = ['', '/teams', '/teams/create']

const TeamsDropdown = props => {
  const {
    location: { pathname },
    match: {
      params: { teamId, seasonId },
    },
    teams: { items = [] },
  } = props

  const isAvailable = AVAILABLE_PATH.map(path =>
    urlConstructor(teamId, seasonId, path)
  ).includes(pathname)

  const normalizedTeams = items.map(
    ({ name: title, id: value, endsAt: time, plan, imgUrl, seasons, logo}) => {
      const endTime = time ? diffDate(time, new Date()) : 1

      return {
        value,
        title,
        disabled: !isAvailable || endTime < 1,
        imgUrl,
        logo,
        plan,
        seasons,
      }
    }
  )
  const firstTeam = normalizedTeams.find(tm => tm.value === +teamId)

  return (
    <TeamSelector>
      <Dropdown isTeam options={normalizedTeams} firstTeam={firstTeam} />
    </TeamSelector>
  )
}

TeamsDropdown.propTypes = {
  location: PROP_TYPES.location.isRequired,
  match: PROP_TYPES.match.isRequired,
  teams: PropTypes.shape().isRequired,
}

export default compose(
  withRouter,
  connect(({ teams }) => ({ teams }))
)(TeamsDropdown)
