import React from 'react'
import Carousel from 'react-slick'
import styled from 'styled-components'
import PROP_TYPES from 'constants/propTypes'

const SETTINGS = {
  className: 'slider variable-width',
  slidesToShow: 1,
  slidesToScroll: 1,
  infinite: false,
  variableWidth: true,
}

const StyledCarousel = styled(Carousel)`
  > button {
    z-index: 1;
    margin-top: -15px;
    width: 50px;
    height: 50px;
    :before {
      font-size: 36px;
      text-shadow: 0px 0px 20px #000000;
    }
  }

  > button:first-child {
    :before {
      font-family: unset;
      content: '\\276E';
    }
  }

  > button:last-child {
    :before {
      font-family: unset;
      content: '\\276F';
    }
  }
`

const OwnCarousel = ({ children }) => (
  <StyledCarousel {...SETTINGS}>{children}</StyledCarousel>
)

OwnCarousel.propTypes = {
  children: PROP_TYPES.children.isRequired,
}

export default OwnCarousel
