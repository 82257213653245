import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { users as usersModule, players as playersModule } from 'redux/modules'
import moment from 'moment'
import { formateDate } from 'utils/formatDate'
import {
  Paper,
  Typography,
  Button,
  Grid,
  TextField,
  InputAdornment,
  Box,
  Toolbar,
} from '@material-ui/core'
import {
  handleTextField,
  checkError,
  filterDataWithoutError,
  checkTextFieldError,
} from 'utils/validate'

import EventTable from 'components/material/Calender/eventTable'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { SubLink, UserHead } from 'containers'
import { getName, setName } from 'utils/localstorage'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import { Translate } from 'react-localize-redux'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone'
import PersonIcon from '@material-ui/icons/Person'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    margin: theme.spacing(10, 2, 2),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(9),
    },
  },
  toolbar: {
    minHeight: 56,
    padding: theme.spacing(0, 2),
    margin: theme.spacing(0, 0, 2),
  },
  title: {
    flex: '1 1 0',
  },
}))

const PlayerDashboard = ({
  users: { current = {} },
  updateUser,
  fetchPlayerEvents,
  updatePlayerAvailability,
}) => {
  const [userData, setUserData] = React.useState({
    id: null,
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
    language: '',
    role: '',
  })

  const classes = useStyles()

  const [events, setEvents] = React.useState([])

  const submit = () => {
    const isError = checkError(userData)
    const isErrorForFields = checkTextFieldError(userData, [
      'first_name',
      'last_name',
    ])
    if (isError) {
      setUserData({ ...userData, [isError]: true })

      return
    }

    if (isErrorForFields) {
      setUserData({ ...userData, [isErrorForFields]: true })

      return
    }
    updateUser(filterDataWithoutError(userData))
    setName(userData.first_name)
  }

  React.useEffect(() => {
    setUserData(current)
  }, [current])

  const fetchEvents = () => {
    fetchPlayerEvents().then(res =>
      setEvents(
        res.response.data.map(
          ({
            id,
            date,
            opponent,
            home,
            logo,
            available_players: availablePlayers,
            total_players: totalPlayers,
            location,
            status,
            relation,
            teamId,
            seasonId,
            team_name: teamName,
            available,
            player_id: playerId,
            tag,
            description,
          }) => ({
            id,
            status,
            opponent,
            title: opponent ? `${teamName} Vs ${opponent}` : `Training`,
            start: +moment(date).format('x'),
            end: +moment(date).format('x'),
            home,
            logo,
            availablePlayers,
            totalPlayers,
            date,
            relation,
            teamId,
            seasonId,
            location,
            dateFormate: formateDate(date),
            available,
            playerId,
            tag,
            description,
          })
        )
      )
    )
  }

  const submitAvailability = (
    eventId,
    type,
    playerId,
    availability,
    data = {}
  ) => {
    updatePlayerAvailability(eventId, type, playerId, availability, data).then(
      fetchEvents
    )
  }

  React.useEffect(() => {
    fetchEvents()
  }, [])

  return (
    <>
      <UserHead>
        <Button
          variant="outlined"
          size="small"
          component={SubLink}
          to="/logout"
          isFullPath
          color="primary"
        >
          <Translate id="navbar.logout" />
        </Button>
      </UserHead>
      <div className={classes.root}>
        <Toolbar className={classes.toolbar} component={Paper}>
          <Typography variant="h4" component={Box} className={classes.title}>
            <Translate id="home.hello" /> {getName(true)}
          </Typography>
          {false && (
            <Button
              variant="contained"
              size="large"
              color="primary"
              onClick={submit}
            >
              <Translate id="button.save" />
            </Button>
          )}
        </Toolbar>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper component={Box} p={2}>
              <Typography variant="h4" component={Box} pb={2}>
                {' '}
                <Translate id="player.general-info" />
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    required
                    id="first_name"
                    label="First Name"
                    type="text"
                    name="first_name"
                    variant="outlined"
                    margin="none"
                    disabled
                    value={userData.first_name}
                    onChange={e => handleTextField(e, userData, setUserData)}
                    error={userData.first_name_error}
                    helperText={
                      userData.first_name_error ? 'First name required' : ''
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <PersonIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    required
                    id="last_name"
                    label="Last Name"
                    type="text"
                    name="last_name"
                    variant="outlined"
                    margin="none"
                    disabled
                    value={userData.last_name}
                    error={userData.last_name_error}
                    onChange={e => handleTextField(e, userData, setUserData)}
                    helperText={
                      userData.last_name_error ? 'Last name required' : ''
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <PersonIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    fullWidth
                    required
                    id="email"
                    label="Email"
                    type="email"
                    name="email"
                    variant="outlined"
                    margin="none"
                    value={userData.email}
                    onChange={e => handleTextField(e, userData, setUserData)}
                    disabled
                    error={userData.email_error}
                    helperText={userData.email_error ? 'Email is required' : ''}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <MailOutlineIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    id="role"
                    label="Role"
                    type="text"
                    name="role"
                    variant="outlined"
                    margin="none"
                    disabled
                    value={userData.role}
                    onChange={e => handleTextField(e, userData, setUserData)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <PhoneIphoneIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    id="phone"
                    label="Phone no"
                    type="tel"
                    name="phone"
                    variant="outlined"
                    margin="none"
                    disabled
                    value={userData.phone}
                    onChange={e => handleTextField(e, userData, setUserData)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <PhoneIphoneIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper component={Box} mb={6} p={2}>
              <Typography variant="h4" component={Box} pb={2}>
                <Translate id="events.next-events" />
              </Typography>

              <EventTable
                events={events}
                isPlayerEvents
                submitPlayerAvailability={submitAvailability}
              />
            </Paper>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

PlayerDashboard.propTypes = {
  users: PropTypes.shape().isRequired,
  updateUser: PropTypes.func.isRequired,
  fetchPlayerEvents: PropTypes.func.isRequired,
  updatePlayerAvailability: PropTypes.func.isRequired,
}

export default compose(
  withRouter,
  connect(
    ({ users }) => ({
      users,
    }),
    {
      updateUser: usersModule.updateUser,
      fetchPlayerEvents: playersModule.fetchPlayerEvents,
      updatePlayerAvailability: playersModule.updatePlayerAvailability,
    }
  )
)(PlayerDashboard)
