import React, { createContext, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import PROP_TYPES from 'constants/propTypes'

export const Context = createContext({})

const ClubStateContext = ({ children, currentTeam }) => {
  const [clubState, changeClubState] = useState(null)

  useEffect(() => {
    changeClubState(currentTeam.club)
  }, [clubState])

  return (
    <Context.Provider
      value={{
        clubState,
        changeClubState,
      }}
    >
      {children}
    </Context.Provider>
  )
}

ClubStateContext.propTypes = {
  children: PROP_TYPES.children.isRequired,
  currentTeam: PROP_TYPES.shape().isRequired,
}

export default connect(({ teams: { current } }) => ({
  currentTeam: current,
}))(ClubStateContext)
