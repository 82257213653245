import React, { useState, useMemo } from 'react'
import moment from 'moment'
import groupBy from 'lodash.groupby'
import sortBy from 'lodash.sortby'
import styled from 'styled-components'
import { Row, Col } from 'react-bootstrap'
import { SubLink } from 'containers'
import OverviewToolbar from './components/OverviewToolbar'
import PROP_TYPES from 'constants/propTypes'
import {
  TRAINING_DASHBOARD_ROUTE,
  MATCH_DASHBOARD_ROUTE,
} from 'constants/routes'

const ListContainer = styled.div`
  height: calc(100% - 40px);
  overflow-y: scroll;
`

const RowStyled = styled(Row)`
  border-bottom: white solid 1px;
`

const RowEvent = styled(Row)`
  border-radius: 10px;
  margin: 2px;

  background: ${({ relation }) =>
    relation === 'training' ? 'var(--secondary-green)' : 'white'};

  color: ${({ relation }) => (relation === 'match' ? 'black' : 'white')};
`

const OverviewView = ({ events, changeActiveView, currentSeason }) => {
  const [activeYear, changeActiveYear] = useState(moment().format('YYYY'))

  const groupedEvents = useMemo(
    () =>
      groupBy(
        events.filter(
          event => +moment(event.start).format('YYYY') === +activeYear
        ),
        event => moment(event.start).format('YYYY-MM-DD')
      ),
    [events, activeYear]
  )

  const groupedAndSortedEvents = useMemo(
    () => sortBy(Object.entries(groupedEvents), ([key]) => key),
    [groupedEvents]
  )

  return (
    <>
      <OverviewToolbar
        changeActiveView={changeActiveView}
        currentSeason={currentSeason}
        activeYear={activeYear}
        changeActiveYear={changeActiveYear}
      />
      <RowStyled>
        <Col md={3}>Date</Col>
        <Col md={3}>Time</Col>
        <Col md={3}>Opponent (Location)</Col>
        <Col md={3}>What</Col>
      </RowStyled>
      <ListContainer>
        {groupedAndSortedEvents.map(([key, value]) => (
          <RowStyled key={key}>
            <Col md={3}>{moment(key).format('DD. MMMM Y')}</Col>
            <Col md={9}>
              {value.map(event => (
                <SubLink
                  key={event.id}
                  to={
                    event.relation === 'training'
                      ? TRAINING_DASHBOARD_ROUTE.populateURLParams({
                          trainingId: event.id,
                        })
                      : MATCH_DASHBOARD_ROUTE.populateURLParams({
                          matchId: event.id,
                        })
                  }
                >
                  <RowEvent relation={event.relation}>
                    <Col md={4}>{moment(event.start).format('h a')}</Col>
                    <Col md={4}>
                      {event.opponent}{' '}
                      {event.location ? `(${event.location})` : ''}
                    </Col>
                    <Col md={4}>
                      {event.relation === 'training' ? 'Training' : 'Match'}
                    </Col>
                  </RowEvent>
                </SubLink>
              ))}
            </Col>
          </RowStyled>
        ))}
      </ListContainer>
    </>
  )
}

OverviewView.defaultProps = {
  events: [],
  currentSeason: {},
}

OverviewView.propTypes = {
  events: PROP_TYPES.arrayOfObjects,
  changeActiveView: PROP_TYPES.func.isRequired,
  currentSeason: PROP_TYPES.shape(),
}

export default OverviewView
