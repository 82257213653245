import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Card,
  Typography,
  Box,
  Divider,
  TextField,
  Grid,
} from '@material-ui/core'
import { Translate } from 'react-localize-redux'
import EventAvailableOutlinedIcon from '@material-ui/icons/EventAvailableOutlined'
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined'
import EventBusyOutlinedIcon from '@material-ui/icons/EventBusyOutlined'
import ThreeStateRadioGroup from 'components/ThreeStateRadioGroup'
import { connect } from 'react-redux'
import { teams as teamsModule } from '../../redux/modules'
import PROP_TYPES from '../../constants/propTypes'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: 'auto',
    padding: theme.spacing(4),
  },
  titleWrapper: {
    marginBottom: theme.spacing(3),
  },
  title: {
    paddingBottom: theme.spacing(0.5),
  },
  section: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(3),
    // paddingBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      marginRight: 0,
      marginLeft: 0,
      '& > :nth-child(1)': {
        paddingBottom: theme.spacing(2),
      },
    },
  },
  inputContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& .MuiFormControl-root': {
      marginBottom: 0,
    },
    '& .MuiInputBase-input': {
      textAlign: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
}))

const PlayerAttendanceResponse = ({ team, patchTeam }) => {
  const classes = useStyles()
  const [defaultResponse, setDefaultResponse] = useState(
    team.default_player_attendance_status
  )

  const handleDefaultResponseChange = (value) => {
    setDefaultResponse(value)
    patchTeam(team.uuid, { default_player_attendance_status: value })
  }

  return (
    <>
      <Card className={classes.root}>
        <Box className={classes.titleWrapper}>
          <Typography variant="h3" component="h3" className={classes.title}>
            <Translate id="player.attendance-response.title" />
          </Typography>
          <Typography variant="h6" component="h6">
            <Translate id="player.attendance-response.select-default" />
          </Typography>
        </Box>
        <Box className={classes.section}>
          <Typography variant="h4" component="h4">
            <Translate id="player.attendance-response.default-player" />
          </Typography>
          <ThreeStateRadioGroup
            value={defaultResponse}
            options={[
              {
                icon: <EventAvailableOutlinedIcon fontSize="small" />,
                value: 'AVAILABLE',
              },
              {
                icon: <DateRangeOutlinedIcon fontSize="small" />,
                value: 'NOT_RESPONDED',
              },
              {
                icon: <EventBusyOutlinedIcon fontSize="small" />,
                value: 'NOT_AVAILABLE',
              },
            ]}
            onChange={handleDefaultResponseChange}
          />
        </Box>
        {/* <Divider /> */}
        {/* <Box>
        <Typography variant="h6" component="h6">
          <Translate id="player.attendance-response.select-submission-time" />
        </Typography>
        <Box className={classes.section}>
          <Typography variant="h4" component="h4">
            <Translate id="player.attendance-response.training" />
          </Typography>
          <Box className={classes.inputContainer}>
            <Grid item xs={8}>
              <TextField
                label="Hours"
                variant="outlined"
                autoFocus
                id="training-hours"
                name="training-hours"
                size="small"
                value="0"
              />
            </Grid>
          </Box>
        </Box>
        <Divider />
        <Box className={classes.section}>
          <Typography variant="h4" component="h4">
            <Translate id="player.attendance-response.matchday" />
          </Typography>
          <Box className={classes.inputContainer}>
            <Grid item xs={8}>
              <TextField
                label="Hours"
                variant="outlined"
                autoFocus
                id="matchday-hours"
                name="matchday-hours"
                size="small"
                value="0"
              />
            </Grid>
          </Box>
        </Box>
        <Divider />
      </Box> */}
      </Card>
    </>
  )
}

PlayerAttendanceResponse.propTypes = {
  patchTeam: PROP_TYPES.func.isRequired,
  team: PROP_TYPES.shape({
    uuid: PROP_TYPES.string.isRequired,
    default_player_attendance_status: PROP_TYPES.string.isRequired,
  }).isRequired,
}

export default connect(
  ({ teams }) => ({
    team: teams.current,
  }),
  {
    patchTeam: teamsModule.patchTeam,
  }
)(PlayerAttendanceResponse)
